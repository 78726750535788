<old-dg-modal #modal size="md" title="Meta" [afterClose]="afterClose">
    <div dg-content>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Nome<span class="fc-red">*</span></label>
            <input type="text" class="form-control" min="0" formControlName="nomeMeta" id="nomeMeta">


          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <label class="form-label">Descrição <span class="fc-red">*</span></label>
            <textarea type="text" class="form-control" formControlName="descricaoMeta" id="descricaoMeta"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div dg-footer>
      <button class="btn btn-success m-1" (click)="salvar()" dg-desable-by-period>Adicionar</button>
      <button (click)="modal.close()" class="btn btn-secondary m-1">Fechar</button>
    </div>
  </old-dg-modal>
