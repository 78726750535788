import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ratio-star',
  templateUrl: './ratio-star.component.html',
  styleUrls: ['./ratio-star.component.scss']
})
export class RatioStarComponent implements OnInit {

  @Input('pontos') pontos: number | undefined;
  @Input('editar') editar: boolean = false;
  // @Input('desabilitar') desabilitar: boolean = false;
  @Output('salve') salve = new EventEmitter<number>()

  constructor() { }

  ngOnInit(): void {
  }

  vefiry(ponto: number): boolean {
    if (!ponto) {
      return false;
    }

    return Number(this.pontos) >= ponto;
  }

  alterStar(ponto: number) {
    if(this.editar){
      this.salve.emit(ponto);
      this.pontos = ponto
    }
  }
}
