<div id="missoes-cupom" class="container">
    <div class="row" style="justify-content: center;">
        <div class="row mb-2">
            <h3 class="fc-green" style="justify-content: center; display: flex;">VÔCE RECEBEU O CUPOM!</h3>
        </div>
        <div class="col-4">
            <card class="d-flex missoes">
                <div class="missoesHeader">
                    <h1 class="titleMiss">{{data?.nome}}</h1>
                </div>
                <input type="file" style="display: none" accept="image/png, image/jpeg, image/jpg">
                <img class="image-material" src="assets/img/default.jpg" alt="Imagem logo">
                <div class="card-body ">
                    <span [innerHTML]="data?.descricao || ''"></span>
                </div>
                <div class="d-flex" style="justify-content: center;">
                    <button class="btn btn-success" style="margin: 1em;" (click)="resgatarCupom()">
                        RESGATAR CUPOM
                    </button>
                </div>
            </card>
        </div>
    </div>
</div>
