import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ArquivosClientesEntity} from "../../../_clientes/_entitys/arquivos-clientes.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {ArquivoEntity} from "../../../../../shared/entitys/arquivo.entity";

@Injectable({
  providedIn: 'root'
})
export class ArquivoNotaFiscalService extends AbstractHttpService<ArquivosClientesEntity> {

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'arquivo-notafiscal');

    this.dictionary.findAll = (val: ArquivoEntity) => {
      return {
        id: val.id,
        arquivo: val
      }
    }
  }
}
