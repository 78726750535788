<div id="page-tutorial" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Tutorial</h4>
        </div>

        <div class="card-body">
          <ul class="tutorial-list">
            <li
              *ngFor="let tutor of listTutorial; let indexTutor = index"
            >
              <p
                class="item-title"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-tutorial-'+indexTutor"
                aria-expanded="false"
                [attr.aria-controls]="'collapse-tutorial-'+indexTutor"
              >
                {{tutor.title}}
              </p>

              <div class="collapse container" id="collapse-tutorial-{{indexTutor}}">
                <div
                  *ngFor="let itemTutor of tutor.list; let indexItemTutor = index"
                  class="row"
                >
                  <div class="col-4">
                    <img src="{{itemTutor.img}}" alt="" class="w-100 mt-1 mb-1">
                  </div>
                  <div class="col-8">
                    <p *ngFor="let passos of itemTutor.passos; let indexPassos = index">
                      <span class="span-count">{{numberStep(
                        tutor, indexItemTutor, indexPassos
                      )}}</span>
                      {{passos}}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </card>
    </div>
  </div>
</div>
