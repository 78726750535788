<old-dg-modal
  #modalDocumentos
  size="md"
  title="Documentos de Comprovação de Embalagens"
  [afterClose]="close"
>
  <input
    #docInput
    type="file"
    name="docName"
    id="clienteCreditoId"
    accept=".pdf"
    style="display: none;"
    [(ngModel)]="docFile"
    (change)="uploadDocumento($event)"
  >

  <form dg-content [formGroup]="form">

    <div class="row">
      <div class="col-12">
        <!-- <div  class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-btn">
          <label class="form-label w-100">
            nome do arquivo
          </label>
          <button (click)="clickDownload(documentoId)" class="btn btn-secondary w-100">Download</button>
        </div> -->
      </div>
      <div class="col-12" *ngIf="!edit">
        <label class="form-label col-xl-12 ">ESCOLHA O DOCUMENTO <span class="fc-red">*</span></label>
        <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-btn" id="fileUpload">
          <button (click)="docInput.click()" class="btn btn-success w-100">Escolher</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label for="produtoId" class="form-label">PRODUTO <span class="fc-red">*</span></label>
        <select formControlName="produtoId" class="form-select" id="produtoId">
          <option value="" disabled>Selecione</option>
          <option *ngFor="let produt of listProduto" value="{{produt.id}}">{{produt.nome}}</option>
        </select>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label for="quantidade" class="form-label">QUANTIDADE(TON) <span class="fc-red">*</span></label>
        <input min="0" formControlName="quantidade" class="form-control" id="quantidade">
      </div>
    </div>

  </form>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modalDocumentos.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>


<!-- <old-dg-modal
  title="Excluir Produto"
  size="md"
  #modalClear>
  <div dg-footer>
    <div class="col-sm-12 my-4">
      <button class="btn btn-success ">SIM</button>
      <button class="btn btn-success ">NÃO</button>
    </div>
  </div>
</old-dg-modal> -->
