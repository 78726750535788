import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministracaoMainComponent } from './administracao-main/administracao-main.component';
import { UiModule } from 'src/app/shared/ui/ui.module';



@NgModule({
  declarations: [
    AdministracaoMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule
  ]
})
export class AdministracaoModule { }
