import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
import { IndicadoresEntity } from '../indicadores.entity';
import { IndicadoresService } from '../service/indicadores.service';
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'app-indicadores-insert-edit',
  templateUrl: './indicadores-insert-edit.component.html',
  styleUrls: ['./indicadores-insert-edit.component.scss'],
})
export class IndicadoresInsertEditComponent
  extends AbstractInsertEdit<IndicadoresEntity>
  implements OnInit {
  public form = new FormGroup({
    valorIndicador: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
    anoReferencia: new FormControl(''),
    linkReferencia: new FormControl(''),
    reciclagemEstado: new FormControl(''),
  });

  estados: IndicadoresEntity[] = [];

  constructor(
    public servic: IndicadoresService,
    public conf: ConfigDgCrudService,
    public serviceCliente: ClientesService
  ) {
    super(conf, servic);

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['reciclagemEstado'])
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.serviceCliente.get('indicador/localizacoes').subscribe((resp) => {
      this.estados = resp.data
    })
  }

  initNewEntity(): void {
    this.entity = new IndicadoresEntity();
  }

  override afterFetchEntity(): void {
    this.entity.reciclagemEstado = InputHelpers.initInputDecimal(this.entity.reciclagemEstado || '') || this.entity.reciclagemEstado

    this.form.patchValue({
      ...this.entity,
      uf: this.entity.estado?.uf

    });
    this.form.controls['uf'].disable()
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { reciclagemEstado, estado, uf, ...form } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      reciclagemEstado: InputHelpers.ajusteMoneyBrToEua(reciclagemEstado),
      estado: (!!uf ? uf : this.entity.estado?.uf),
    };

    return true;
  }
}
