<div id="newsletters-insert-edit">



    <form [formGroup]="form">
        <div class="container-data">
            <div class="row">
                <div class="col-12">
                    <card>
                        <div class="card-header">
                            <h4 class="card-title">Dados</h4>
                            <hr>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="status"
                                        class="form-label"
                                    >
                                        STATUS DA INSCRIÇÃO
                                        <span class="fc-red">*</span>
                                    </label>
                                    <select
                                        class="form-select"
                                        formControlName="status"
                                        id="status"
                                    >
                                        <option disabled>Selecione</option>
                                        <option value="ATIVO">Ativo</option>
                                        <option value="INATIVO">Inativo</option>
                                    </select>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="nome"
                                        class="form-label"
                                    >
                                        NOME
                                        <span class="fc-red">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="nome"
                                        class="form-control"
                                        id="nome"
                                    >
                                </div>



                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="nome"
                                        class="form-label"
                                    >
                                        E-MAIL
                                        <span class="fc-red">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        formControlName="email"
                                        class="form-control"
                                        id="email"
                                    >
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="telefone"
                                        class="form-label"
                                    >
                                        TELEFONE (DDD)
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="telefone"
                                        placeholder="(00) 00000-0000"
                                        mask="(00) 0000-0000||(00) 00000-0000"
                                        formControlName="telefone"
                                    >
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label class="form-label">
                                        GERADOR
                                        <span class="fc-red">*</span>
                                    </label>
                                    <input-auto-complete
                                        [baseValue]="entity?.nomeCliente"
                                        propertValue="nome"
                                        controlName="nomeCliente"
                                        urlServer="clientes/nomes"
                                        (outValue)="this.entity.nomeCliente = $event"
                                    >
                                    </input-auto-complete>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </form>
</div>



<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
