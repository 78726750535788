import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {filtersInterface} from "../../dashboard-main/dashboard-main.component";
import {DetalhamentoUnidadeGeradoraEntity} from "../../entitys/detalhamento-unidade-geradora.entity";
import {DashboardColetaService} from "../dashboard-graph/service/dashboard-coleta.service";
import {DASHBOARD} from "../../../../../core/config/endpoint-list";

@Component({
  selector: 'subComponent-dashboard-estoque',
  templateUrl: './dashboard-estoque.component.html',
  styleUrls: ['./dashboard-estoque.component.scss']
})
export class DashboardEstoqueComponent implements OnInit, OnChanges {

  @Input() dataFilter: filtersInterface = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };

  public contAtualizacao = 0;
  public print = false;

  public listGraph: any[] = [];

  public entity = new DetalhamentoUnidadeGeradoraEntity()

  constructor(
    public service: DashboardColetaService
  ) {
    if ('matchMedia' in window) {
      // Chrome, Firefox, and IE 10 support mediaMatch listeners
      window.matchMedia('print').addListener((media) => {
        if (media.matches) {
          this.generate_pdf_target()
        } else {
          // Fires immediately, so wait for the first mouse movement
        }
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['dataFilter'].currentValue) {
      this.dataFilter = changes['dataFilter'].currentValue;
    }

    this.getGraph();
    window.dispatchEvent(new CustomEvent('dg-table-atualizar-event'))
  }

  getGraph() {
    const params = this.getParamsFind()

    this.service.get(DASHBOARD, { params }).subscribe(
      resp => {
        this.contAtualizacao++;
        this.listGraph = resp.data?.charts || [];
      }
    )
  }

  public generate_pdf_target(): void {
    //alert(window.location.href);
    const newWindow = window.open('/user/dashboard-print?unidadeGeradora=409', '_blank');
    newWindow?.focus();
    return;
  }

  getParamsFind() {
    const params: {[key: string]: any} = {}

    if (this.dataFilter.dataInicio) params['dataInicio'] = this.dataFilter.dataInicio

    if (this.dataFilter.dataFim) params['dataFim'] = this.dataFilter.dataFim

    if (this.dataFilter.unidades.length > 0) params['unidades'] = this.dataFilter.unidades

    if (this.dataFilter.estados.length > 0) params['estados'] = this.dataFilter.estados

    if (this.dataFilter.galpao.length > 0) params['galpao'] = this.dataFilter.galpao

    if (!!this.dataFilter.ativo) params['ativo'] = this.dataFilter.ativo

    return params;
  }

  criarId(i: number): string {
    return 'chartid-coletas-' + String(i) + '-' + this.contAtualizacao;
  }

}
