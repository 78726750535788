import {
  AbstractEntity,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn,
} from '@datagrupo/dg-crud';

const pontos = (val: number) => {
  return "<span style=\"padding: .6rem 1rem; background: rgba(148,159,57,.8); color: white; font-size: 14pt; border-radius: 25px\">"+
    val +
  "</span>"
}

const posicao = (val: number | string) => {
  return '<span style="font-size: 13pt; font-weight: bolder; color: rgba(148,159,57,.8)">'+val+'</span>'
}


export class RankingColetasEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    periodo?: string,
    porAno?: boolean,
    posicao?: number | string,
    nomeCliente?: string,
    pontosPeriodo?: number | string
  ) {
    super();
    this.id = id;
    this.periodo = periodo;
    this.porAno = porAno;
    this.posicao = posicao;
    this.nomeCliente = nomeCliente;
    this.pontosPeriodo = pontosPeriodo;

    this.setPagination({sortField: 'nomeCliente'});
  }

  @DgFilter_input('date', { name: 'Período Início', nameParamFind: 'periodoInicio' })
  public periodo: string | undefined;

  @DgFilter_input('date', { name: 'Período Fim', nameParamFind: 'periodoFim' })
  public periodoF: string | undefined;

  public porAno: boolean | undefined;

  @DgTableColumn({ columnName: 'POSIÇÃO', resource: (val: string) => !!val ? posicao(val) : '--' })
  public posicao: number | string | undefined;

  @DgTableColumn({ columnName: 'NOME', resource: (val: string) => !!val ? val : '--' })
  public nomeCliente: string | undefined;

  @DgTableColumn({ columnName: 'PONTUAÇÃO', resource: (val: number) => !!val ? pontos(val) : '--' })
  public pontosPeriodo: number | string | undefined;
}
