<!-- LINHA DE SELEÇÃO DE FILTROS -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <button
        class="btn btn-outline dashboard-buttons"
        type="button"
        (click)="canvas.toggleOffcanvas()"
      >
        <mat-icon>search</mat-icon>
        Filtros
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr class="fc-grey">
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="view = 'lixo-zero'">
          <h4 class="nav-link card-title" [class.active]="view == 'lixo-zero'">Taxa de lixo zero</h4>
        </li>
        <li class="nav-item" (click)="view = 'reciclagem'">
          <h4 class="nav-link card-title" [class.active]="view == 'reciclagem'">Ranking taxa de reciclagem</h4>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <div [class.d-none]="view != 'lixo-zero'" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <h4 class="card-title">Taxa De Aterro Zero</h4>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-inline-flex justify-content-end">
              <div class="btn-group">
                <button
                  class="btn btn-outline dashboard-buttons dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                  [disabled]="!verifyEnablePrint('lixoZero')"
                >
                  <mat-icon>settings</mat-icon>
                  Opções
                </button>

                <ul class="dropdown-menu dropdown-menu-lg-end">
                  <li>
                    <a
                      id="link-print-lixoZero" hidden
                      routerLink="/private/impressao/dadhboard/ranking/lixo-zero"
                      target="_blank" [queryParams]="dataDashboard.queryPrintLixoZero"
                    ></a>
                    <span (click)="print('lixoZero')" class="dropdown-item" type="button">
                      <mat-icon class="icon-pre">print</mat-icon>
                      <span>Imprimir</span>
                    </span>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button" (click)="downloadExcel('lixoZero')">
                      <mat-icon class="icon-pre">download</mat-icon>
                      <span>Excel</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p class="mat-card-subtitle">
            Caso nenhum período seja selecionado, serão apresentados os registros dos ultimos 30 dias
          </p>
        </div>
        <div class="card-body">
          <dg-table classes="default-table" dg-dynamic-table [dynamicTable]="tableLixoZero"></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableLixoZero"></dg-paginator>
        </div>
      </div>
    </div>
  </div>

  <div [class.d-none]="view != 'reciclagem'" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <h4 class="card-title">Ranking Das Taxas de Reciclagem</h4>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-inline-flex justify-content-end">
              <div class="btn-group">
                <button
                  class="btn btn-outline dashboard-buttons dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                  [disabled]="!verifyEnablePrint('taxaReciclagem')"
                >
                  <mat-icon>settings</mat-icon>
                  Opções
                </button>

                <ul class="dropdown-menu dropdown-menu-lg-end">
                  <li>
                    <a
                      id="link-print-taxaReciclagem" hidden
                      routerLink="/private/impressao/dadhboard/ranking/taxa-reciclagem"
                      target="_blank"
                      [queryParams]="dataDashboard.queryPrintTaxaReciclagem"
                    ></a>
                    <span class="dropdown-item" type="button" (click)="print('taxaReciclagem')">
                      <mat-icon class="icon-pre">print</mat-icon>
                      <span>Imprimir</span>
                    </span>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button" (click)="downloadExcel('taxaReciclagem')">
                      <mat-icon class="icon-pre">download</mat-icon>
                      <span>Excel</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p class="mat-card-subtitle">
            Caso nenhum período seja selecionado, serão apresentados os registros dos ultimos 30 dias
          </p>
        </div>
        <div class="card-body">
          <dg-table classes="default-table" dg-dynamic-table [dynamicTable]="tableReciclagem"></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableReciclagem"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>


<canvas-side-filter
  #canvas
  (clear)="clearFilters()"
  (closed)="initLoaderData()"
  (uploadeFavoritoList)="$event == 'ranking' ? loaderFavoritos() : null"
>
  <form [formGroup]="formFilter">
    <div class="row">
      <div class="col-12">
        <label class="form-label form-label-sm">Favoritos</label>
        <dg-autocomplete3
          #favoritos
          key="id" label="nome"
          classes="form-control" formControlName="favoritos" [data]="controlles.filtersOptions['favoritos']"
          dg-dynamic-group="estoque" paramName="favoritos" typeComunication="change" (change)="propagarFavorito()"
        ></dg-autocomplete3>
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Tipo de prfil</label>
        <select class="form-select form-select-sm" formControlName="perfilUnidade" (change)="changeFilters('perfilUnidade')">
          <option value="" disabled>Selecione um tipo de perfil</option>
          <option value="EMPRESA">Empresa</option>
          <option value="CONDOMINIO">Condomínio</option>
          <option value="EVENTO">Evento</option>
          <option value="CASA">Casa</option>
        </select>
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Geradores</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control form-control-sm" formControlName="geradores" [data]="controlles.filtersOptions['geradores']"
          dg-dynamic-group="estoque" paramName="unidades" typeComunication="change" (change)="changeFilters('geradores')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Data Início</label>
        <input type="date" formControlName="dataInicio" class="form-control form-control-sm" (change)="changeFilters('dataInicio')">
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Data Fim</label>
        <input type="date" formControlName="dataFim" class="form-control form-control-sm" (change)="changeFilters('dataFim')">
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Tipos de Resíduo</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('tipoResiduos')"
          classes="form-control" formControlName="residuos" [data]="controlles.filtersOptions['tipoResiduos']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12">
        <label class="form-label form-label-sm">Estados</label>
        <dg-autocomplete3
          key="uf" label="name" [multiple]="true"
          classes="form-control" formControlName="estados" [data]="controlles.filtersOptions['estados']"
          dg-dynamic-group="estoque" paramName="estados" typeComunication="change" (change)="changeFilters('estados')"
        ></dg-autocomplete3>
      </div>
    </div>
  </form>
</canvas-side-filter>
