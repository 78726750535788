import { Injectable } from '@angular/core';
import { AbstractHttpService, HttpClient } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { GRUPO_COMUNICACAO } from 'src/app/core/config/endpoint-list';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { GrupoComunicacaoEntity } from '../grupo-comunicacao.entity';

@Injectable({
  providedIn: 'root'
})
export class GrupoComunicacaoService extends AbstractHttpService<GrupoComunicacaoEntity> {
  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService,
  ) {
    super(conf, environment.apiUrl, GRUPO_COMUNICACAO)
  }

}
