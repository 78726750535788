<div
    id="cadastre-plano-insert"
    class="container"
>
    <div class="row">
        <div class="container-data ">
            <card>
                <div class="card-header">
                    <div class="row">
                        <div
                            class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-inline-flex"
                            style="text-align: center;"
                        >
                            <button
                                class="btn buttonHeader mt-2"
                                (click)="entity.tipoPlanoEnum = 'PERIODICO'"
                                [class.btn-success]="entity?.tipoPlanoEnum == 'PERIODICO'"
                                [class.btn-outline-success]="entity?.tipoPlanoEnum != 'PERIODICO'"
                                [disabled]="entity?.id"
                            >
                                Planos Periódicos
                            </button>
                            <button
                                class="btn buttonHeader mt-2 fc-green"
                                (click)="entity.tipoPlanoEnum = 'PONTUAL'"
                                [class.btn-success]="entity?.tipoPlanoEnum == 'PONTUAL'"
                                [class.btn-outline-success]="entity?.tipoPlanoEnum != 'PONTUAL'"
                                [disabled]="entity?.id"
                            >
                                Plano Pontual
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <card>
                        <div *ngIf="(entity?.tipoPlanoEnum == 'PERIODICO' && !!entity)">
                            <plano-periodico
                                (salvar)="disparaSalvar($event)"
                                [entity]="entity"
                            ></plano-periodico>
                        </div>
                        <div *ngIf="(entity?.tipoPlanoEnum == 'PONTUAL' && !!entity)">
                            <plano-pontual
                                (salvar)="disparaSalvar($event)"
                                [entity]="entity"
                            ></plano-pontual>
                        </div>
                    </card>
                </div>
            </card>
        </div>
    </div>
</div>



<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        (click)="VerifySubComponentPlano()"
        dg-desable-by-period
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        (click)="VerifySubComponentPlano(true)"
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
