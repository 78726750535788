import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalBlogInsertEditComponent} from './modal-blog-insert-edit/modal-blog-insert-edit.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {CardPostComponent} from './card-post/card-post.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {FilterBlogComponent} from './filter-blog/filter-blog.component';
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BlogMainComponent} from './blog-main/blog-main.component';
import {DirectivesModule} from "../../../core/directives/directives.module";


@NgModule({
  declarations: [
    ModalBlogInsertEditComponent,
    CardPostComponent,
    FilterBlogComponent,
    BlogMainComponent,
  ],
  exports: [
    ModalBlogInsertEditComponent,
    CardPostComponent,
    FilterBlogComponent,
    BlogMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatTooltipModule,
    FormsModule,
    DirectivesModule
  ]
})
export class BlogModule {
}
