import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndustriaDestinoMainComponent } from './industria-destino-main/industria-destino-main.component';
import { IndustriaDestinoInsertEditComponent } from './industria-destino-insert-edit/industria-destino-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IndustriaArquivosMainComponent } from './pages/industria-arquivos-main/industria-arquivos-main.component';
import {CdkDynamicGroupModule, CdkDynamicTableModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import { ModalIndustriaArquivosComponent } from './sub-components/modal-industria-arquivos/modal-industria-arquivos.component';
import {DirectivesModule} from "../../../core/directives/directives.module";
import {CustomAutocompleteModule} from "../../../shared/custom-autocomplete/custom-autocomplete.module";



@NgModule({
  declarations: [
    IndustriaDestinoMainComponent,
    IndustriaDestinoInsertEditComponent,
    IndustriaArquivosMainComponent,
    ModalIndustriaArquivosComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        DgCrudModule,
        ReactiveFormsModule,
        DgTableModule,
        DgPaginatorModule,
        CdkDynamicTableModule,
        FormsModule,
        DirectivesModule,
        CustomAutocompleteModule,
        CdkDynamicGroupModule
    ]
})
export class IndustriaDestinoModule { }
