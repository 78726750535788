<card>
  <div class="card-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <h4 class="card-title">Informações dos Planos Pontuais</h4>
        </div>
        <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <button *ngIf="!session.checkPerfil('PARCEIRO')" (click)="openModal()" class="btn btn-success btn-card-header">
            <mat-icon>add</mat-icon>
            Selecionar Plano
          </button>
        </card-header-buttons>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="crud-table">
      <thead>
      <tr>
        <th>Data da Contratação</th>
        <th>Nome do Plano</th>
        <th>Valor</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="listPlanos.length <= 0">
        <td style="text-align: center" colspan="3">Nenhum registro encontrado</td>
      </tr>
      <tr *ngFor="let plano of listPlanos; let index = index">
        <td>{{formata_data_utc_br(plano.dataInclusao)}}</td>
        <td>{{plano.nomePlano}}</td>
        <td>{{fotmataDinheiro(plano.valor)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</card>

<old-dg-modal #modalPlanoPontual title="Selecione um plano Pontual" [size]="'xl'" id="selecionar-plano-pontual-modal"
          [afterClose]="beforeClose">

  <div dg-content class="h-100">
    <div *ngIf="loader" class="d-flex justify-content-center align-items-center h-100">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loader" class="row">
      <div class="col-12">
        <card class="card">
          <div class="row">
            <div class="col-xs-8 col-lg-8 col-md-6 col-sm-6">
              <div class="row">
                <div
                  *ngFor="let item of listaPlanoModal; let i = index;"
                  class="planos card col-sm-6 col-lg-8 col-xs-12 col-md-6"
                  [style.display]="verificaContratado(item.id) ? 'none' : 'block'"
                >
                  <input hidden type="checkbox" [value]="false" #checkBtnCard>
                  <div [hidden]="checkBtnCard.checked">
                    <div class="card-body contemText">
                      <div class="center">
                        <h1 class="tittle" style="margin-top: 1em;">Plano</h1>
                      </div>
                      <h1 class="center nameP1 fc-green">{{item.nome}}</h1>
                      <h1 style="text-transform: uppercase; margin-top: 1em;" class="fs-11">{{periodoTabela}}</h1>
                      <h1 class="center fc-green"><span class="valor">R$</span> {{fotmataDinheiro(item.valor)}}</h1>
                      <h1 class="fc-green fs-12">/mês</h1>
                      <button class="btn btn-fill btn-wd btn-success btn-sm mb-3"
                              (click)="selecionarPlano(i, item.valor)" [disabled]="verificaSelecionado(item.id)">
                        {{verificaSelecionado(item.id) ? 'Selecionado' : 'Selecionar'}}
                      </button>
                    </div>

                    <card>
                      <table class="crud-table">
                        <tr *ngFor="let item of listaPlanoModal[i].items; let indexItem = index"
                            [hidden]="indexItem > 2" class="card-itens">
                          <td>{{item}}</td>
                        </tr>
                      </table>
                    </card>

                    <div class="d-flex" style="justify-content: center; margin: 1em;">
                      <button class="btn btn-success d-flex" style="width: 5%; justify-content: center;"
                              (click)="checkBtnCard.click()">
                        <mat-icon>question_mark</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div>
                    <div [hidden]="!checkBtnCard.checked">
                      <div class="card-body card-description-plano">
                        <div class="contemText">
                          <div class="d-flex" style="justify-content: end;">
                            <button class="btn btn-success d-flex"
                                    style="width: 10%; justify-content: center; margin: 1em;"
                                    (click)="checkBtnCard.click()">
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                          <div class="center">
                            <h1 class="tittle fc-green" style="margin-top: 1em;">ESTE PLANO
                              PERMITE
                            </h1>
                          </div>
                          <card>
                            <table class="crud-table">
                              <tr *ngFor="let item of listaPlanoModal[i].items; let indexItem = index"
                                  [hidden]="indexItem <= 2" class="card-itens">
                                <td>{{item}}</td>
                              </tr>
                            </table>
                          </card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xs-4 col-lg-4 col-md-6 col-sm-6">
              <div class="metodo-tittle">
                <h4>Método de Pagamento</h4>
              </div>
              <div class="row pt-3 mb-3 ml-1">
                <div class="col-6">
                  <input type="radio" name="cartaoPontual" id="cartao" value="CARTAO" [(ngModel)]="metodoPagamento">

                  <label style="margin-left: 0.5em;" for="cartao">Cartão</label>
                </div>
                <div class="col-6 ">
                  <input type="radio" name="boletoPontual" id="boleto" value="BOLETO" [(ngModel)]="metodoPagamento">

                  <label style="margin-left: 0.5em;" for="boleto">Boleto</label>
                </div>
              </div>
              <div class="metodo-tittle">
                <h4>Carrinho</h4>
              </div>
              <div class="content">
                <table id="table-carrinho" class="crud-table">
                  <thead>
                  <tr>
                    <th class="ajusteNome">Nome</th>
                    <th class="ajusteValor">Valor(R$)</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of listaPlanoSelecionados; let i = index">
                    <td class="ajusteNomeSelecionado">{{item.nome}}</td>
                    <td class="ajusteValorSelecionado">R${{fotmataDinheiro(item.valor)}}</td>
                    <td class="ajusteBotao">
                      <mat-icon class="fc-red" style="margin-right: 0.5em;" (click)="removerPlano(i, item.valor)">


                        cancel
                      </mat-icon>
                    </td>
                  </tr>
                  <div *ngIf="listaPlanoSelecionados.length <= 0">
                    <span class="fs-10 ajusteNome">Nenhum plano selecionado</span>
                  </div>
                  </tbody>
                </table>
                <div class="content-data" style="border-top: 1px solid rgba(0, 128, 0, 0.26); margin-top: 1em;">
                </div>
                <div class="row">
                  <div class="col-6">
                    <div *ngIf="listaPlanoSelecionados.length <= 0">
                      <div style="padding-left: 1em; margin-top: 0.5em">
                        <label>Valor total R$0,00</label>
                      </div>
                    </div>
                    <div *ngIf="listaPlanoSelecionados.length > 0">
                      <div style="padding-left: 1em; margin-top: 0.5em">
                        <label>Valor total R$</label>
                        {{fotmataDinheiro(valorSelecionadoSomado)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="listaPlanoSelecionados.length <= 0">
                      <!-- <div style="margin-top: 0.5em">
                        <button style="width: 100%" class="btn btn-success fs-9">Alterar periodicidade</button>
                      </div> -->
                    </div>
                    <div *ngIf="listaPlanoSelecionados.length > 0">

                      <div style="margin-top: 0.5em">
                        <button style="width: 100%;" class="btn btn-success fs-10" (click)="contratar()">Contratar

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div dg-footer>
    <button (click)="contratar()" [disabled]="listaPlanoSelecionados.length <= 0" class="btn btn-success m-1">
      Contratar
    </button>
    <button (click)="modalPlanoPontual.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
