import { AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { autoCompleteUnidadeMedidaOutPut } from "src/app/helpers/auto-complete/unidade-medida-autocomplete.helper";
import { environment } from "../../../../environments/environment";

export interface interfFaixa {
  id?: number | string,
  valor: number | string
}

export const TipoUnidadeMedida = [

  { key: "QUANTIDADE", value: "Quantidade" },
  { key: "PESO", value: "Peso" },
  { key: "PORCENTAGEM", value: "Porcentagem" },
  { key: "VOLUME", value: "Volume" }
]

@DataServer({
  path: environment.apiUrl,
  context: 'unidade-medida'
})
export class UnidadeMedidaEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    fatorConversao?: number,
    nome?: string,
    categoriaUnidadeMedida?: string,
    faixas?: interfFaixa[]
  ) {
    super();
    this.id = id;
    this.fatorConversao = fatorConversao;
    this.nome = nome
    this.categoriaUnidadeMedida = categoriaUnidadeMedida;
    this.faixas = Array.isArray(faixas) ? faixas : [];
  }

  @DgFilter_autoComplete({}, {
    getUrl: environment.apiUrl + 'unidade-medida/nomes',
    getServerData: "all",
    nameParamFilter: 'nome',
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'idUnidade',
    outputFormat: autoCompleteUnidadeMedidaOutPut
  })
  @DgTableColumn({ columnName: 'NOME', width: '30rem', sort: true })
  nome: string | undefined
  fatorConversao: number | string | undefined

  @DgFilter_select({ colum: '4', data: TipoUnidadeMedida, propertKey: 'key', propertValue: 'value' })
  @DgTableColumn({ columnName: 'UNIDADE DE MEDIDA', width: '30rem', sort: true })
  categoriaUnidadeMedida: string | undefined
  faixas: interfFaixa[] = []
}
