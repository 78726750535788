<div
    id="relatorio-missoes-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Relatório das Missões</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        [table]="tableRelatorioMissoes"
                        tableEventId="relatorioMissoesTable"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableRelatorioMissoes
                        [entityMap]="mapEntity"
                        [service]="service"
                        [showPagination]="false"
                        [showDefaultAction]="false"
                        [customDblclick]="true"
                        tableEventId="relatorioMissoesTable"
                        [register]="true"
                    ></crud-data-table>
                </div>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableRelatorioMissoes.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>



<page-footer>
    <button
        *ngIf="session.checkPerfil('ADMINISTRADOR')"
        (click)="baixarPlanilha()"
        class="btn btn-success"
    >
        Baixar planilha
    </button>
</page-footer>
