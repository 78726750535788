<div class="autocomplete-component" id="content-input-autocomplete-{{uniqId}}">
  <input
    #inputAutoComplete
    id="input-autocomplete-{{uniqId}}"
    [formControlName]="controlName"
    (ngModelChange)="onChangeSearch($event)"
    (keyup)="_autoComleteKeyUp()"
    (focus)="focusIn()"
    (click)="elementsClass.openSelectList()"
    class="form-control inputAutocomplete-component"
    type="text"
    autocomplete="off"
    placeholder="{{placeholder}}"
    [class.ng-invalid]="verify()"
  >
  <div style="position: relative" *ngIf="showList">
    <input tabindex="-1" class="form-control inputAutocomplete-component">
  </div>
</div>

<div
  class="autocomplete-pelicula"
  [class.show]="showList"
  [style.z-index]="zIndex"
  (click)="elementsClass.closeSelectList()"
>
  <div
    id="divPelicula-autocomplete-{{uniqId}}"
    class="pelicula-content"
  >
    <div
      #divOptions
      id="divList-autocomplete-{{uniqId}}"
      class="div-element-options"
    >
      <ul id="list-autocomplete-{{uniqId}}">
        <li class="disabled">selecione</li>
        <li
          *ngFor="let option of list; let i = index"
          (click)="selectEvent(i)"
          [value]="option.value"
        >
          <span>
            {{_getPropertValue(i)}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
