import { ClientesEntity } from 'src/app/pages/_user/_clientes/clientes/clientes.entity';
import {AbstractEntity, DgFilter_autoComplete, DgFilter_select, DgTableColumn} from "@datagrupo/dg-crud";
import {ArquivoEntity} from "src/app/shared/entitys/arquivo.entity";
import {environment} from "src/environments/environment";
import {CupomEntity} from "../cupom/cupom.entity";
import {ParceiroEntity} from "../parceiro/parceiro.entity";
import {missaoAutocompleteOutput} from "../../../helpers/auto-complete/missao-autocomplete.helper";

export class LocalizacoesInterface {
  estado: {
    "name": string,
    "uf": string
  } | undefined;
  cidades: {
    cidade: string
    id: number | undefined
  }[] | undefined
}

const tipoMissao = [
  {k: 'CONDOMINIO', v: 'Missão da Gerador'},
  {k: 'MORADOR', v: 'Missão do usuário'}
]
const concluida = [
  {k: 'all', v: 'Todos'},
  {k: 'true', v: 'Sim'},
  {k: 'false', v: 'Não'}
]

export class MissaoEntity extends AbstractEntity {

  constructor(
    id?: number,
    imagem?: ArquivoEntity,
    arquivoImagemId?: number,
    arquivoSeloId?: number,
    nome?: string,
    cupom?: CupomEntity,
    descricao?: string,
    concluida?: boolean,
    limiteClientesAceitarMissao?: number,
    link?: string,
    metaComunicacao?: number,
    metaEconomia?: number,
    numeroDeColetas?: number,
    parceiro?: ParceiroEntity,
    parcentualDoContainer?: string,
    pontosMissao?: number,
    qtdMoradores?: number,
    restringiuMissao?: boolean,
    tipoLixoEnum?: string,
    tipoMissao?: string,
    localizacoes?: LocalizacoesInterface[],
    selo?: ArquivoEntity,
    cliente?: ClientesEntity
  ) {
    super();
    this.id = id;
    this.imagem = imagem;
    this.arquivoImagemId = arquivoImagemId;
    this.arquivoSeloId = arquivoSeloId;
    this.nome = nome;
    this.cupom = cupom;
    this.descricao = descricao;
    this.concluida = concluida;
    this.limiteClientesAceitarMissao = limiteClientesAceitarMissao;
    this.link = link;
    this.metaComunicacao = metaComunicacao;
    this.metaEconomia = metaEconomia;
    this.numeroDeColetas = numeroDeColetas;
    this.parceiro = parceiro;
    this.parcentualDoContainer = parcentualDoContainer;
    this.pontosMissao = pontosMissao;
    this.qtdMoradores = qtdMoradores;
    this.restringiuMissao = restringiuMissao;
    this.tipoLixoEnum = tipoLixoEnum;
    this.tipoMissao = tipoMissao;
    this.localizacoes = localizacoes
    this.selo = selo;
    this.cliente = cliente

  }

  @DgTableColumn({columnName: 'NOME', sort: true})
  @DgFilter_autoComplete({name: 'NOME'},
    {
      getUrl: environment.apiUrl + 'missao/nomes',
      getServerData: "all",
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFind: 'missaoId',
      nameParamFilter: 'nome',
      outputFormat: missaoAutocompleteOutput
    })
  public nome: string | undefined;

  //@DgTableColumn({ columnName: 'FINALIZADA', sort: true, resource: (val: boolean) => val ? 'Sim' : 'Não' })
  //@DgFilter_select({ data: ['TODAS', 'SIM', 'NÃO'], name: 'CONCLUIDA', defaultData: 'TODAS' })
  @DgFilter_select({data: concluida, name: "FINALIZADA", propertKey: 'k', propertValue: 'v', outputFormat: (val1: any, val2: any) => {
    if (val1 == 'all') {
      return;
    }

    return { concluida: val1 }
    // console.log(val1, val2);
  }})
  @DgTableColumn({columnName: 'FINALIZADA', sort: true, resource: (val: boolean, row: any ) => {
      return !!row.finalizada ? 'Sim' : 'Não'
    }})
  public concluida: boolean | undefined;


  @DgFilter_select({
    data: tipoMissao,
    name: "TIPO MISSÃO",
    defaultData: 'CONDOMINIO',
    propertKey: 'k',
    propertValue: 'v',
    nameParamFilter: 'USUÁRIO'
  })
  public tipoMissao: string | undefined;

  public imagem: ArquivoEntity | undefined;
  public arquivoImagemId: number | undefined;
  public arquivoSeloId: number | undefined;
  public cupom: CupomEntity | undefined;
  public descricao: string | undefined;
  public limiteClientesAceitarMissao: number | undefined;
  public link: string | undefined;
  public metaComunicacao: number | undefined;
  public metaEconomia: number | undefined;
  public numeroDeColetas: number | undefined;
  public parceiro: ParceiroEntity | undefined;
  public parcentualDoContainer: string | undefined;
  public pontosMissao: number | undefined;
  public qtdMoradores: number | undefined;
  public restringiuMissao: boolean | undefined;
  public tipoLixoEnum: string | undefined;
  public localizacoes: LocalizacoesInterface[] | undefined;
  public selo: ArquivoEntity | undefined;
  public cliente: ClientesEntity | undefined;

}
