import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {GalpaoMotoristaVeiculoEntity} from "../galpao-motorista-veiculo.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GalpaoMotoristaVeiculoService extends AbstractHttpService<GalpaoMotoristaVeiculoEntity> {

  constructor(
    public config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, 'veiculos')
  }
}
