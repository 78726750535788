<old-dg-modal
    #modalCliente
    size="md"
    title="Geradores Logística Reversa"
    [afterClose]="close"
>
    <form
        dg-content
        [formGroup]="formModal"
    >
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">
                    CLIENTE
                    <span class="fc-red">*</span>
                </label>
                <input-auto-complete
                    baseValue=" "
                    propertValue="nome"
                    controlName="clientes"
                    urlServer="cliente-credito-logistica-reversa"
                    [service]="clientService"
                    (outValue)="alterClienteModal($event)"
                ></input-auto-complete>
            </div>



            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label
                    for="quantidadeVendida"
                    class="form-label"
                >
                    QUANTIDADE VENDIDA (TON)
                    <span class="fc-red">*</span>
                </label>
                <input
                    [class.ng-invalid]="isError"
                    class="form-control"
                    formControlName="quantidadeVendida"
                    id="quantidadeVendida"
                >
            </div>
        </div>
    </form>



    <div
        dg-content
        class="modal-vendas-logistica-clientes mt-2"
    >
        <div class="row">
            <div class="col-12">
                <card>
                    <div class="card-header">
                        <h4 class="card-title">Produtos Compensados</h4>
                    </div>
                    <div class="card-body">
                        <table class="crud-table">
                            <thead>
                                <tr>
                                    <th>Produtos</th>
                                    <th>Quantidade (ton)</th>
                                    <th>Data Inclusão</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="produtosAssoc?.length == 0">
                                    <td [colSpan]="3">Nenhum registro encontrado</td>
                                </tr>
                                <tr *ngFor="let prodA of produtosAssoc">
                                    <td>{{prodA?.produto?.nome || '--'}}</td>
                                    <td>{{format(prodA.qtdProdutoGerado)}}</td>
                                    <td>{{formatDate(prodA?.dataInclusao)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </div>
        </div>
    </div>




    <div
        dg-content
        class="modal-vendas-logistica-clientes mt-2"
    >
        <div class="row">
            <div class="col-12">
                <card>
                    <div class="card-header">
                        <h4 class="card-title">Pedidos</h4>
                    </div>
                    <div class="card-body">
                        <table class="crud-table">
                            <thead>
                                <tr>
                                    <th>Nome do Produto</th>
                                    <th>Quantidade Vendida</th>
                                    <th>Data da Venda</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="produtosComp?.length == 0">
                                    <td [colSpan]="3">Nenhum registro encontrado</td>
                                </tr>
                                <tr *ngFor="let prodC of produtosComp">
                                    <td>{{prodC.nome}}</td>
                                    <td>{{prodC.quantidadeVendida}}</td>
                                    <td>{{formatDate(prodC.dataInclusao)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </div>
        </div>
    </div>



    <div dg-footer>
        <button
            (click)="salvarCliente()"
            class="btn btn-success m-1"
            dg-desable-by-period
        >
            Salvar
        </button>
        <button
            (click)="modalCliente.close()"
            class="btn btn-secondary m-1"
        >
            fechar
        </button>
    </div>
</old-dg-modal>
