import { Component, OnInit } from '@angular/core';
import { IndicadoresOdsEntity } from '../indicadores-ods.entity';
import { IndicadoresOdsService } from '../service/indicadores-ods.service';

@Component({
  selector: 'app-indicadores-ods-main',
  templateUrl: './indicadores-ods-main.component.html',
  styleUrls: ['./indicadores-ods-main.component.scss']
})
export class IndicadoresOdsMainComponent implements OnInit {
  public mapEntity = new IndicadoresOdsEntity();

  constructor(public service: IndicadoresOdsService) {}

  ngOnInit(): void {
  }
}
