import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardMainComponent} from './dashboard-main/dashboard-main.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BlogModule} from "../blog/blog.module";
import {MissoesModule} from "../missoes/missoes.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatStepperModule} from "@angular/material/stepper";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  DashboardGraphComponent
} from './subComponents/dashboard-graph/component-dashboard-graph/dashboard-graph.component';
import {MatInputModule} from "@angular/material/input";
import {DashboardEstoqueComponent} from './subComponents/dashboard-estoque/dashboard-estoque.component';
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";
import {DirectivesModule} from "../../../core/directives/directives.module";
import {
  DashboardPrintColetasComponent
} from './subComponents/dashboard-print-coletas/dashboard-print-coletas.component';
import {PrintModule} from "../../../shared/print/print.module";
import {CardImpactoAmbientalComponent} from './subComponents/card-impacto-ambiental/card-impacto-ambiental.component';
import { CardGraficoAterroZeroComponent } from './subComponents/card-grafico-aterro-zero/card-grafico-aterro-zero.component';
import {SideFilterModule} from "../../../shared/side-filter/side-filter.module";
import { SubComponentDashboardColetasComponent } from './subComponents/sub-component-dashboard-coletas/sub-component-dashboard-coletas.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { CardOdsComponent } from './subComponents/card-ods/card-ods.component';
import { SubComponentDashboardEstoqueComponent } from './subComponents/sub-component-dashboard-estoque/sub-component-dashboard-estoque.component';
import { SubComponentDashboardRankingAterroZeroComponent } from './subComponents/sub-component-dashboard-ranking-aterro-zero/sub-component-dashboard-ranking-aterro-zero.component';
import { SubComponentDashboardRankingTaxaReciclagemComponent } from './subComponents/sub-component-dashboard-ranking-taxa-reciclagem/sub-component-dashboard-ranking-taxa-reciclagem.component';
import { DashboardPrintRankingAterroZeroComponent } from './subComponents/dashboard-print-ranking-aterro-zero/dashboard-print-ranking-aterro-zero.component';
import { DashboardPrintRankingTaxaReciclagemComponent } from './subComponents/dashboard-print-ranking-taxa-reciclagem/dashboard-print-ranking-taxa-reciclagem.component';
import { DashboardPrintEstoqueComponent } from './subComponents/dashboard-print-estoque/dashboard-print-estoque.component';


@NgModule({
  declarations: [
    DashboardMainComponent,
    DashboardGraphComponent,
    DashboardEstoqueComponent,
    DashboardPrintColetasComponent,
    CardImpactoAmbientalComponent,
    CardGraficoAterroZeroComponent,
    SubComponentDashboardColetasComponent,
    CardOdsComponent,
    SubComponentDashboardEstoqueComponent,
    SubComponentDashboardRankingAterroZeroComponent,
    SubComponentDashboardRankingTaxaReciclagemComponent,
    DashboardPrintRankingAterroZeroComponent,
    DashboardPrintRankingTaxaReciclagemComponent,
    DashboardPrintEstoqueComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        AngularEditorModule,
        ReactiveFormsModule,
        BlogModule,
        MissoesModule,
        MatFormFieldModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        DgCrudModule,
        MatMenuModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatInputModule,
        DgAutocompleteModule,
        DirectivesModule,
        PrintModule,
        SideFilterModule,
        MatProgressBarModule
    ],
    exports: [
        DashboardGraphComponent,
        SubComponentDashboardColetasComponent,
    ]
})
export class DashboardModule {
}
