<div id="dashboard">

  <div class="container-fluid">
    <div class="row">
      <h2>Dashboard</h2>
    </div>
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-center">
        <ul class="nav nav-pills mb-3">
          <li
            check-perfil
            [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'GALPAO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO']"
            class="nav-item"
            role="presentation"
          >
            <button
            class="m-1 btn btn-light"
              type="button"
              (click)="relatorio = 'coletas'"
              [class.active]="relatorio == 'coletas'"
            >
              Coletas
            </button>
          </li>
          <li
            check-perfil
            [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'GALPAO', 'PARCEIRO']"
            class="nav-item"
            role="presentation"
          >
            <button
            class="dashboard-buttons btn btn-light"
              type="button"
              (click)="relatorio = 'estoque'"
              [class.active]="relatorio == 'estoque'"
            >
              Estoque
            </button>
          </li>
          <li
            check-perfil
            [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO']"
            class="nav-item"
            role="presentation"
          >
            <button
            class="dashboard-buttons btn btn-light"
              type="button"
              (click)="relatorio = 'aterroZero'"
              [class.active]="relatorio == 'aterroZero'"
            >
              Taxa de Lixo Zero
            </button>
          </li>
          <li
            check-perfil
            [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO']"
            class="nav-item"
            role="presentation"
          >
            <button
              class="dashboard-buttons btn btn-light"
              type="button"
              (click)="relatorio = 'taxaReciclagem'"
              [class.active]="relatorio == 'taxaReciclagem'"
            >
              Ranking taxa de Reciclagem
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="dashboard-buttons btn btn-light"
              type="button"
              (click)="relatorio = 'impacto'"
              [class.active]="relatorio == 'impacto'"
            >
              Impacto
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container-fluid">

    <div
      class="row"
      check-perfil
      [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'GALPAO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO', 'PARCEIRO']"
      [hidden]="relatorio != 'coletas'">
      <div class="col-12">
        <sub-component-dashboard-coletas>
        </sub-component-dashboard-coletas>
      </div>
    </div>

    <div
      class="row"
      check-perfil
      [perfil]="['ADMINISTRATIVO', 'ADMINISTRADOR', 'GALPAO', 'PARCEIRO']"
      [hidden]="relatorio != 'estoque'"
    >
      <div class="col-12">
        <sub-component-dashboard-estoque>
        </sub-component-dashboard-estoque>
      </div>
    </div>

    <div
      class="row"
      [hidden]="relatorio != 'aterroZero'"
    >
      <div class="col-12" *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])">
        <sub-component-dashboard-ranking-aterro-zero>
        </sub-component-dashboard-ranking-aterro-zero>
      </div>
    </div>

    <div
      class="row"
      [hidden]="relatorio != 'taxaReciclagem'"
    >
      <div class="col-12" *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])">
        <sub-component-dashboard-ranking-taxa-reciclagem>
        </sub-component-dashboard-ranking-taxa-reciclagem>
      </div>
    </div>

  </div>
</div>

