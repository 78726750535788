import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { GestaoProgramaLogisticaReversaEntity } from '../gestao-programa-logistica-reversa.entity';

@Injectable({
  providedIn: 'root'
})
export class GestaoProgramaLogistcaReversaService extends AbstractHttpService<GestaoProgramaLogisticaReversaEntity>  {

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'missao');

    this.dictionary.findOne = {
      nome: 'nomeMissao',
      concluida: 'estaConcluida'

    }
  }

  download(id: number) {
    return this.findOne(Number(id), '');
  }

  uploadImage(data: any) {
    return this.save(data, 'imagem');
  }
}
