import {AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import {GalpaoMotoristaDocumentoEntity} from "./galpao-motorista-documentos.entity";
import {Enderecos} from "../../../../shared/entitys/enderecos";
import {GalpaoMotoristaVeiculoEntity} from "../galpao-motorista-veiculo/galpao-motorista-veiculo.entity";
import {GalpaoEntity} from "../galpao/galpao.entity";
import {ArquivoEntity} from "../../../../shared/entitys/arquivo.entity";
import { environment } from "src/environments/environment";

export class GalpaoMotoristaEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    nome?: string,
    sobrenome?: string,
    email?: string,
    login?: string,
    telefone?: number,
    celular?: number,
    identidade?: number,
    cpf?: number,
    //nomeGalpao?: string,
    cnpj?: number,
    ajudante?: {
      ajudanteUm?: string,
      ajudanteDois?: string,
    },
    habilitacao?: string,
    motoristaDocumentos?: GalpaoMotoristaDocumentoEntity[],
    motoristaVeiculos?: GalpaoMotoristaVeiculoEntity[],
    enderecos?: Enderecos[],
    galpao?: GalpaoEntity,
    imagem?: ArquivoEntity,
    imagemHabilitacao?: ArquivoEntity,
    anonimo?: boolean
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.sobrenome = sobrenome;
    this.email = email;
    this.login = login;
    this.telefone = telefone;
    this.celular = celular;
    this.identidade = identidade;
    this.cpf = cpf;
    //this.nomeGalpao = nomeGalpao;
    this.cnpj = cnpj;
    this.ajudante = !!ajudante ? ajudante : {ajudanteUm: '', ajudanteDois: ''};
    this.habilitacao = habilitacao;
    this.motoristaDocumentos = motoristaDocumentos;
    this.motoristaVeiculos = motoristaVeiculos;
    this.enderecos = Array.isArray(enderecos) ? enderecos : [];
    this.galpao = galpao;
    this.anonimo = !!anonimo;
  }

  //HEADER DA TABELA MAIN
  @DgFilter_input('text', {name: 'NOME',colum: '4'})
  @DgTableColumn({columnName: 'NOME', sort: true})
  public nome: string | undefined;

  @DgFilter_input('text', {name: 'LOGIN',colum: '4'})
  @DgTableColumn({columnName: 'LOGIN', sort: true})
  public login: string | undefined;

  @DgFilter_input('text', {name: 'EMAIL',colum: '4'})
  @DgTableColumn({columnName: 'EMAIL', sort: true})
  public email: string | undefined;

  @DgFilter_autoComplete({ colum: '4' }, {
    getUrl: environment.apiUrl + 'galpoes/nomes',
    getServerData: "all",
    propertValue: 'nome',
  })
  @DgTableColumn({columnName: 'TRANSPORTADOR', sort: true, resource: (val: GalpaoEntity) => {
      return !!val?.nome ? val.nome : '--';
    }})
  public galpao: GalpaoEntity | undefined;
  //public nomeGalpao: string | undefined;

  // @DgFilter_input('text', {name: 'CNPJ',colum: '4', mask: 'cnpj'})
  public cnpj: number | undefined;

  @DgFilter_input('text', {name: 'CELULAR',colum: '4', mask: 'celular'})
  @DgTableColumn({columnName: 'CELULAR', sort: true})
  public celular: number | undefined;

  //DEMAIS DADOS DA ENTIDADE
  public sobrenome: string | undefined;
  public telefone: number | undefined;
  public identidade: number | undefined;
  public cpf: number | undefined;
  public ajudante: {
    ajudanteUm?: string,
    ajudanteDois?: string,
  } | undefined;
  public habilitacao: string | undefined
  public motoristaDocumentos: GalpaoMotoristaDocumentoEntity[] | undefined;
  public motoristaVeiculos: GalpaoMotoristaVeiculoEntity[] | undefined;
  public enderecos: Enderecos[] = [];
  public imagem?: ArquivoEntity | undefined;
  public imagemHabilitacao?: ArquivoEntity | undefined;
  public anonimo: boolean = false;
}
