<div
    id="vendas-logistica-reversa-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Vendas Logística Reversa</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search [entityMap]="vendasLogEntity" [table]="tableVendaLog" tableEventId="vendaLogTable"></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableVendaLog
                        [entityMap]="vendasLogEntity"
                        [service]="vendasService"
                        [showPagination]="false"
                        tableEventId="vendaLogTable"
                        [register]="true"
                    ></crud-data-table>
                </div>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination
                        [dataPagination]="vendasLogEntity.configEntityTable.pagination"
                        (changePage)="tableVendaLog.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>


<page-footer>
    <a routerLink="novo">
        <button class="btn btn-success">Novo</button>
    </a>
</page-footer>
