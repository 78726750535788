import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { AvaliacaoColetaEntity } from '../avaliacao-coleta.entity';

@Injectable({
  providedIn: 'root'
})
export class AvalicaoColetaService extends AbstractHttpService<AvaliacaoColetaEntity> {

  constructor(
    public conf: ConfigDgCrudService
  ) { 
    super(conf, environment.apiUrl, 'coletas')
  }

}
