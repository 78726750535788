<nav *ngIf="(checkApresentacao())" class="app-patination" aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item" (click)="changePagination(currentPage - 1)" [class.disabled]="disabled('anterior')">
      <span class="page-link">Anterior</span>
    </li>

    <li class="page-item" (click)="changePagination(currentPage + - 2)" *ngIf="verificarMenores(2)">
      <span class="page-link" href="#">{{ currentPage - 1 }}</span>
    </li>

    <li class="page-item" (click)="changePagination(currentPage + - 1)" *ngIf="verificarMenores(1)">
      <span class="page-link" href="#">{{ currentPage }}</span>
    </li>



    <li class="page-item active-pagination">
      <span class="page-link" href="#">{{ (currentPage + 1) }}</span>
    </li>



    <li class="page-item" *ngIf="verificarMaiores(1)">
      <span class="page-link" (click)="changePagination(currentPage + 1)">{{ (currentPage + 2) }}</span>
    </li>

    <li class="page-item" *ngIf="verificarMaiores(2)">
      <span class="page-link" (click)="changePagination(currentPage + 2)">{{ (currentPage + 3) }}</span>
    </li>

    <li class="page-item" (click)="changePagination(currentPage + 1)" [class.disabled]="disabled('proximo')">
      <span class="page-link">Próximo</span>
    </li>
  </ul>
</nav>
