import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-header-buttons',
  templateUrl: './card-header-buttons.component.html',
  styleUrls: ['./card-header-buttons.component.scss']
})
export class CardHeaderButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
