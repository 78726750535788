import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@datagrupo/dg-crud";
import {environment} from "../../../../../environments/environment";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {SessionService} from "../../../../core/services/session-service/session.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public type: 'password' | 'text' = 'password';

  public form: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  })

  public viewPass: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
  }

  logar() {
    if (this.form.valid) {
      const form = this.form.value;

      this.http.post(environment.apiUrl + 'auth/login', form).subscribe(
        (resp: any) => {
          window.localStorage.setItem('accessToken', resp.data.accessToken);
          window.localStorage.setItem('refreshToken', resp.data.refreshToken);
          this.router.navigate(['/auth/start-session'])
        },
        error => {
          if (error.error.errorCode === 'LGN1002') {
            Swal.fire({
              icon: 'error',
              title: 'Usuário ou senha inválido',
              heightAuto: false
            });
          }
        }
      )
    }
  }

}
