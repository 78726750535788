import { AbstractEntity, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";

export class SelecionarPlanoEntity extends AbstractEntity{
    constructor(
        id?: number,
        nomePlano?: string,
        valor?: number,
        periodicidadePlanoEnum?: string,
        planosId?: number[],
        descricao?: string,
        dadosComplementares?: string[],
        metodoPagamentoEnum?: string
    ){
        super()
        this.id = id;
        this.nomePlano = nomePlano;
        this.valor = valor;
        this.periodicidadePlanoEnum = periodicidadePlanoEnum;
        this.descricao = descricao;
        this.dadosComplementares = dadosComplementares
        this.planosId = planosId;
        this.metodoPagamentoEnum = metodoPagamentoEnum
    }

    @DgTableColumn({columnName: 'NOME DO PLANO'})
    public nomePlano: string | undefined;

    @DgTableColumn({columnName: 'VALOR'})
    public valor: number | undefined;

    public periodicidadePlanoEnum: string | undefined;
    public descricao: string | undefined;
    public dadosComplementares: string[] | undefined
    public planosId: number[] | undefined;
    public metodoPagamentoEnum: string | undefined;
}