import {Component, HostListener, OnInit} from '@angular/core';
import {RankingAterroZeroEntity} from "../../entitys/aterro-zero.entity";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {crudDispatchEvent} from "@datagrupo/dg-crud";

@Component({
  selector: 'sub-component-dashboard-ranking-aterro-zero',
  templateUrl: './sub-component-dashboard-ranking-aterro-zero.component.html',
  styleUrls: ['./sub-component-dashboard-ranking-aterro-zero.component.scss']
})
export class SubComponentDashboardRankingAterroZeroComponent implements OnInit {
  public mapEntity = new RankingAterroZeroEntity();
  public tableParams: {[key: string]: any} = {};
  public showTable = false;
  private startPage = true;

  constructor(
    private http: HttpOldService,
    private files: FilesManageService
  ) { }

  ngOnInit(): void {}

  public generate_pdf_target(): void {
    const params = JSON.stringify({
      pagination: this.mapEntity.configEntityTable.pagination,
      ...(!!this.tableParams ? this.tableParams : {})
    });

    window.open('user/ranking-aterro-zero-print?params='+params, '_blank')?.focus();
  }

  downloadExcel() {
    const params = {
      unpaged: true,
      ...(!!this.tableParams ? this.tableParams : {})
    };

    this.http
      .get<{ data: string }>('coletas/ranking-coleta/aterro-zero/planilha', { params })
      .subscribe((resp) => {
        this.files.downloadBase64(resp.data, 'ranking-coleta-atero-zero.xlsx');
      });
  }

  @HostListener('window:atualizar-pagina-filtrada-ranking-aterro', ['$event'])
  getEventValues(ev: CustomEvent) {
    if (
      JSON.stringify(ev.detail) != JSON.stringify(this.tableParams) ||
      (JSON.stringify(ev.detail) == JSON.stringify({}) && this.startPage)
    ) {
      this.startPage = false;
      this.tableParams = ev.detail;

      setTimeout(() => crudDispatchEvent('RankingAterroZero'), 100)
    }
  }
}
