import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { SelecionarPlanoPontualEntity } from '../selecionar-plano-pontual.entity';

@Injectable({
  providedIn: 'root'
})
export class SelecionarPlanoPontualService extends AbstractHttpService<SelecionarPlanoPontualEntity>{

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'planos/periodicidade?periodicidadePlanoEnum=PONTUAL&unpaged=true')
  }


}
