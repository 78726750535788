import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeDocComponent } from './home-doc/home-doc.component';
import {DocRoutingModule} from "./doc-routing.module";
import {DocPagesModule} from "./doc-pages/doc-pages.module";



@NgModule({
  declarations: [
    HomeDocComponent
  ],
  imports: [
    CommonModule,
    DocRoutingModule,
    DocPagesModule
  ]
})
export class DocModule { }
