import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractInsertEdit, HttpClient, InsertEditConfig, InterfaceCustomActions } from "@datagrupo/dg-crud";
import { CadastroIndicadoresOdsEntity, InterIndicadores, InterMetas } from "../cadastro-indicadores-ods.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FilesManageService } from "../../../../services/files-manage/files-manage.service";
import { verifySizeFiles } from "../../../../helpers/helpers";
import { environment } from "../../../../../environments/environment";
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';

@Component({
  selector: 'app-cadastro-indicadores-ods-insert-edit',
  templateUrl: './cadastro-indicadores-ods-insert-edit.component.html',
  styleUrls: ['./cadastro-indicadores-ods-insert-edit.component.scss']
})
export class CadastroIndicadoresOdsInsertEditComponent extends AbstractInsertEdit<CadastroIndicadoresOdsEntity> implements OnInit {

  @ViewChild('fileUploadObjetivo') fileUploadObjetivo!: ElementRef;
  @ViewChild('modalMeta') modalMeta!: OldModalComponent;
  @ViewChild('modalIndicador') modalIndicador!: OldModalComponent;

  public listOds: CadastroIndicadoresOdsEntity[] = [];
  public listaMetas: CadastroIndicadoresOdsEntity[] = [];
  public listaIndicadores: CadastroIndicadoresOdsEntity[] = [];

  file: File | undefined;

  public form = new FormGroup({
    id: new FormControl(''),
    nomeObjetivo: new FormControl('', [Validators.required]),
    descricaoObjetivo: new FormControl('', [Validators.required]),
  });

  entityIndicadoresOds = new CadastroIndicadoresOdsEntity();

  public indexModal: number | undefined;

  constructor(
    public files: FilesManageService,
    public conf: InsertEditConfig,
  ) {
    super(conf, { path: environment.apiUrlMock, context: 'indicadores-ods' });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public afterClose = () => {
    this.form.reset();
  }

  initNewEntity(): void {
    this.entity = new CadastroIndicadoresOdsEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
    });
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { ...form } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    };

    return true;
  }

  uploadObjetivo(event: Event) {
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.file = undefined;
        return;
      }

      let data = new FormData();

      data.append('arquivo', file);
      data.append('objetivoId', String(this.entity.id));


      // this.servic.uploadImagem(data).subscribe(resp => {
      //   if (resp) {
      //     //@ts-ignore
      //     this.entity.imagem = resp.data
      //   }
      // })
    }
  }

  selectImg() {
    const fileImge = this.fileUploadObjetivo.nativeElement;
    fileImge.click()
  }

  salvarMeta(data: { index: number | undefined, data: InterMetas }) {
    if(data.index != undefined){
      if(!this.entity.listMeta[data.index]) return
      this.entity.listMeta[data.index] = {

        ...this.entity.listMeta[data.index],
        ...data.data
      }

      return;
    }

    this.entity.listMeta.push(data.data)
  }

  excluirMeta(index: number) {
    if (!!this.entity.listMeta[index]) {
      this.entity.listMeta.splice(index, 1)
    }
  }

  salvarIndicador(data: { index: number, data: InterIndicadores, indexInd?: number }) {
    if (!!this.entity.listMeta[data.index]) {
      if (!this.entity.listMeta[data.index]?.indicadores) this.entity.listMeta[data.index]['indicadores'] = []
    }

    if (data.indexInd != undefined) {
      //@ts-ignore
      if (!this.entity.listMeta[data.index].indicadores[data.index]) return
      //@ts-ignore
      this.entity.listMeta[data.index].indicadores[data.indexInd] = {
        //@ts-ignore
        ...this.entity.listMeta[data.index].indicadores[data.indexInd],
        ...data.data

      }

      return
    }

    this.entity.listMeta[data.index]?.indicadores?.push(data.data)
  }

  excluirIndicador(index: number, indexInd: number) {
    if (!!this.entity.listMeta[index]) {
      if (!!(this.entity.listMeta[index]?.indicadores || [])[indexInd]) {
        this.entity.listMeta[index]?.indicadores?.splice(indexInd, 1)
      }
    }
  }
}
