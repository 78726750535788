import { Component, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import { SessionService } from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import { HttpOldService } from "../../../../core/services/http/http-old.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public relatorio: any = undefined;
  public gravimetria = 'Calculando...'
  public issetCliente: boolean = false;

  constructor(
    private http: HttpOldService,
    public session: SessionService
  ) {
    this.issetCliente = !!session.user?.cliente_id
  }

  ngOnInit(): void {
    //TODO retornar esse campo após atualização do back-end

    // this.http.get('relatorio/impacto').subscribe(
    //   resp => {
    //     //@ts-ignore
    //     this.relatorio = resp.data;
    //   }
    // )
    this.http.get('relatorio/gravimetria/' + this.session.user.id).subscribe(
      resp => {
        //@ts-ignore
        if (resp.data.length > 0) {
          //@ts-ignore
          Highcharts.chart('graphic-impacto', this.ajustaDataGraph(resp.data[0]));
        } else {
          this.gravimetria = 'Sem Gravimetria'
        }
      }
    )
  }

  ajustaDataGraph(d: any): object {
    let data = JSON.stringify(d);

    return JSON.parse(data, function (key, value) {
      if (
        typeof value === "string" &&
        value.startsWith("REMOVE_QUOTE") &&
        value.endsWith("REMOVE_QUOTE")
      ) {
        value = value.replace(/REMOVE_QUOTE/g, '')
        return (0, eval)("(" + value + ")");
      }
      return value;
    });
  }

  openNewsletter() {
    // if (!(!!this.session.user.cliente_id)) return;
    // if (this.session.user.perfil == 'ADMINISTRADOR' || this.session.user.perfil == 'ADMINISTRATIVO') {
    //   Swal.fire({
    //     text: 'Usuário não possuí condomínio',
    //     timer: 5000,
    //     showConfirmButton: false,
    //     icon: 'error',
    //   })
    // } else {
      window.dispatchEvent(new CustomEvent('event-open-newsletter'));
    // }
  }

}
