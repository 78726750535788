import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TipoLixoMainComponent} from "./tipo-lixo-main/tipo-lixo-main.component";
import {TipoLixoInsertEditComponent} from "./tipo-lixo-insert-edit/tipo-lixo-insert-edit.component";
import {UiModule} from "../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {TipoLixoFilhoComponent} from "./subComponents/tipo-lixo-filho/tipo-lixo-filho.component";
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { NgxMaskModule } from 'ngx-mask';
import { DgAutocompleteModule } from '@datagrupo/dg-ng-util';
import {MatSelectModule} from "@angular/material/select";



@NgModule({
  declarations: [
    TipoLixoMainComponent,
    TipoLixoInsertEditComponent,
    TipoLixoFilhoComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        DirectivesModule,
        NgxMaskModule,
        DgAutocompleteModule,
        MatSelectModule
    ]
})
export class TipoLixoModule { }
