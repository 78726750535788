import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { MissaoEntity } from '../../missao/missao.entity';

@Injectable({
  providedIn: 'root'
})
export class MissoesService extends AbstractHttpService<MissaoEntity> {

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'missoes')
  }
}
