import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractInsertEdit,
  CrudDataTableComponent,
  crudDispatchEvent,
  HttpClient,
} from "@datagrupo/dg-crud";
import { ClientesEntity } from "../clientes.entity";
import { ConfigDgCrudService } from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import { ClientesService } from "../service/clientes.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GalpaoEntity } from "../../../_galpao/galpao/galpao.entity";
import { ArquivosClientesEntity } from "../../_entitys/arquivos-clientes.entity";
import { ContainerClienteEntity } from "../../_entitys/container-cliente.entity";
import { MissoesClientesService } from "../../missoes-clientes/service/missoes-clientes.service";
import { ParceiroEntity } from "../../../parceiro/parceiro.entity";
import { ArquivosClienteModalComponent } from '../subComponents/arquivos-cliente-modal/arquivos-cliente-modal.component';
import Swal from 'sweetalert2';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import {
  CLIENTE_ATIVO_INATIVO,
  CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA,
  GALPAO_FIND_FILTER
} from "../../../../../core/config/endpoint-list";
import { HttpHelpersService } from "../../../../../services/http-helpers/http-helpers.service";
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { defaultCallBackErrorGetCep, defaultCallBackSuccessGetCep } from 'src/app/helpers/helpers';
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { environment } from 'src/environments/environment';
import { ParceiroService } from '../../../parceiro/service/parceiro.service';
import { SegmentosOperacionaisEntity } from '../../../tipo-lixo/segmentos-operacionais.entity';
import {GrupoMercadoEntity} from "../../../tipo-lixo/grupo-merccado.entity";
import {SegmentoMercadoMercadoEntity} from "../../../tipo-lixo/segmento-mercado.entity";
import {InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {TipoUsuarioClienteEnum, TipoUsuarioClienteType} from "../../../../../tupo-usuario-cliente.enum";
import {HttpService} from "../../../../../services/http-service/http.service";
import {Router} from "@angular/router";
import {ClienteSubPageMtrComponent} from "../sub-pages/cliente-sub-page-mtr/cliente-sub-page-mtr.component";
import {
  ClienteSubPageContratoComponent
} from "../sub-pages/cliente-sub-page-contrato/cliente-sub-page-contrato.component";

@Component({
  selector: 'app-clientes-insert-edit',
  templateUrl: './clientes-insert-edit.component.html',
  styleUrls: ['./clientes-insert-edit.component.scss']
})
export class ClientesInsertEditComponent extends AbstractInsertEdit<ClientesEntity> implements OnInit {

  viewPage: 'INFO' | 'MTR' | 'CONTRATO' | 'COLETA' = 'INFO';

  @ViewChild('subPageMTR') subPageMTR!: ClienteSubPageMtrComponent;
  @ViewChild('subPageContrato') subPageContrato!: ClienteSubPageContratoComponent;

  @ViewChild('tableArquivo') tableArquivo!: CrudDataTableComponent;
  @ViewChild('modalArquivo') modalArquivo!: ArquivosClienteModalComponent
  @ViewChild('modalAtivaDesativa') modalAtivaDesativa!: OldModalComponent;
  @ViewChild('autoCompleteSegMer') autoCompleteSegMer!: InputAutocompleteComponent;
  @ViewChild('grupoMercado') grupoMercado!: InputAutocompleteComponent;
  @ViewChild('autoSeguimentoOperacional') autoSeguimentoOperacional!: InputAutocompleteComponent;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    tipoCadastro: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    interesseCliente: new FormControl('', [Validators.required]),
    parceiros: new FormControl({ value: [], disabled: false }),
    grupoMercado: new FormControl('', [Validators.required]),
    segmentoMercado: new FormControl({ disabled: true, value: '' }, [Validators.required]),
    segmentosOperacionais: new FormControl({ disabled: true, value: '' }, [Validators.required]),
    condominioIndicador: new FormControl({ value: '', disabled: false }),
    cep: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    logradouro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    bairro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    cidade: new FormControl({ value: '', disabled: true }, [Validators.required]),
    uf: new FormControl({ value: '', disabled: true }, [Validators.required]),
    galpao: new FormControl(''),
    qtdApartamentos: new FormControl('', [Validators.required]),
    qtdDeMoradores: new FormControl(''),
    estimativaColetaMensal: new FormControl(''),
  })

  public checkedDiasColetas = false;

  public preSelectedParceiros: ParceiroEntity[] = [];

  public urlAutocompleteParceiro = environment.apiUrl + 'parceiros'
  public listParceiro: ParceiroEntity[] = [];
  public listGalpao: GalpaoEntity[] = [];
  public listEstimativaCliente: any[] = [];
  public listEstimativaLogistica: any[] = [];

  public listSegmentosOperacionais: SegmentosOperacionaisEntity[] = []
  public listSegmentosMercado: any[] = []
  public listGruposMercado: [] = []

  public recalcular: boolean = false;
  public contatos: any[] = [];

  public arquivosEntity = new ArquivosClientesEntity();

  public tableParam: { clienteId?: number | string } = {};

  // editMtr = new FormControl(false)

  constructor(
    public conf: ConfigDgCrudService,
    public service: ClientesService,
    public missaoServic: MissoesClientesService,
    public httpHelper: HttpHelpersService,
    public http: HttpService,
    public session: SessionService,
    public parcService: ParceiroService,
    private router: Router
  ) {
    super(conf, service);
    service.get(GALPAO_FIND_FILTER, { params: { unpaged: true } }).subscribe(
      resp => {
        //@ts-ignore
        this.listGalpao = resp.data;
      }
    )

    if (!session.checkPerfil('GALPAO')) {
      parcService.get('parceiros', { params: { unpaged: true } }).subscribe(
        resp => {
          //@ts-ignore
          this.listParceiro = resp.data;
        }
      )
    }

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['estimativaColetaMensal'])
    )
    this.ajustEntity()

    if (this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR', 'EMPRESA', 'CONDOMINIO', 'CASA', 'EVENTO', 'PARCEIRO'])) {
      this.http.get('grupos-mercado', { params: { unpaged: true } }).subscribe((resp) => {
        //@ts-ignore
        this.listGruposMercado = resp.data
      })
    } else {
      this.form.controls['grupoMercado'].disable()
    }
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.form.controls['tipoCadastro'].valueChanges.subscribe(value => {
      if (value == 'CASA') {
        this.form.controls['cnpj'].disable()
        this.form.controls['qtdApartamentos'].disable()
      } else {
        this.entity.id ? this.form.controls['cnpj'].disable() : this.form.controls['cnpj'].enable()
        this.form.controls['qtdApartamentos'].enable()
      }
    })

  }

  initNewEntity(): void {
    this.entity = new ClientesEntity();
    this.getCep();

    if (!this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.form.controls['galpao'].disable()

      this.form.patchValue({
        galpao: this.session.user.galpao_id,
        exclusivo: true
      })
    }
  }

  preSelectedGrupMercado: GrupoMercadoEntity[] = [];
  preSelectedSegmentosOperacionais : SegmentosOperacionaisEntity[] = [];
  preSelectedSegmentoMercado: SegmentoMercadoMercadoEntity[] = [];

  override afterFetchEntity() {
    this.entity.estimativaColetaMensal = InputHelpers.initInputDecimal(this.entity.estimativaColetaMensal || '') || this.entity.estimativaColetaMensal

    this.changePaginationContato(this.paginationContato)

    this.crudConfig.subscribeEntityAfterSave = false

    const listGrupMercado: GrupoMercadoEntity[] = [];
    const listSegmentoMercado: SegmentoMercadoMercadoEntity[] = [];
    const listSegmentoOeracionais = this.entity.segmentosOperacionais || [];

    listSegmentoOeracionais.map((item: SegmentosOperacionaisEntity) => {
      if (item.segmentoMercado?.grupoMercado?.id) {
        if (listGrupMercado.findIndex(item2 => item2?.id == item.segmentoMercado?.grupoMercado?.id) == -1) {
          listGrupMercado.push(item.segmentoMercado.grupoMercado)
        }
      }

      if (item.segmentoMercado?.id) {
        if (listSegmentoMercado.findIndex(item2 => item2?.id == item.segmentoMercado?.id) == -1) {
          listSegmentoMercado.push(item.segmentoMercado)
        }
      }
    })

    this.preSelectedSegmentoMercado = listSegmentoMercado || [];
    this.preSelectedSegmentosOperacionais = listSegmentoOeracionais || [];
    this.preSelectedGrupMercado = listGrupMercado || [];

    const segmentosOperacionaisResult = (this.entity.segmentosOperacionais || [])[0];
    const segmentoMercadoResult = segmentosOperacionaisResult?.segmentoMercado;
    const grupoMercadoResult = segmentoMercadoResult?.grupoMercado


    if(!!segmentosOperacionaisResult?.id){
      this.http.get('segmentos-operacionais', { params: { unpaged: true, segmentosMercadoId: segmentoMercadoResult?.id || '' } }).subscribe((resp) => {
        //@ts-ignore
        this.listSegmentosOperacionais = resp.data
        this.form.controls['segmentosOperacionais'].enable();
      })

      this.http.get('segmentos-mercado', { params: { unpaged: true, gruposMercadoId: [grupoMercadoResult?.id || '' ]} }).subscribe((resp) => {
        //@ts-ignore
        this.listSegmentosMercado = resp.data
        this.form.controls['segmentoMercado'].enable();
      })
    }

    this.form.patchValue({
      // @ts-ignore
      ...this.entity,
      ...this.entity.enderecos[0],
      //@ts-ignore
      uf: this.entity.enderecos[0]?.estado,
      ...this.entity.predio,
      galpao: this.entity.galpao?.id,
      condominioIndicador: this.entity?.condominioIndicador?.nome,
      parceiros: this.entity?.parceiros ? this.entity?.parceiros.map(item => item.id) : [],
      email: this.entity?.email,
    });

    this.subPageMTR.setData(this.entity)
    this.subPageContrato.setData(this.entity)

    this.preSelectedParceiros = this.entity.parceiros

    if (!this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.form.controls['galpao'].disable()
      this.form.controls['tipoCadastro'].disable()

      if (!this.checkPermission()) {
        this.form.controls['nome'].disable()
        this.form.controls['cnpj'].disable()
        this.form.controls['telefone'].disable()
        this.form.controls['interesseCliente'].disable()
        this.form.controls['parceiros'].disable()
        this.form.controls['condominioIndicador'].disable()
        this.form.controls['cep'].disable()
        this.form.controls['numero'].disable()
        this.form.controls['complemento'].disable()
        this.form.controls['logradouro'].disable()
        this.form.controls['bairro'].disable()
        this.form.controls['cidade'].disable()
        this.form.controls['uf'].disable()
        this.form.controls['qtdApartamentos'].disable()
        this.form.controls['qtdDeMoradores'].disable()
        this.form.controls['exclusivo'].disable()
        this.form.controls['estimativaColetaMensal'].disable()
      }
    }

    this.tableParam = {
      clienteId: this.entity.id
    }

    if (this.entity.galpao) {
      this.listGalpao = [
        ...this.listGalpao,
        this.entity.galpao
      ]
    }

    //@ts-ignore
    this.missaoServic.get('clientes/missoes/' + this.entity.id);
    this.getCep();

  }

  getCep() {
    this.form.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        this.httpHelper.getCep(cep,
          data => {
            this.form.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "uf": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )

      }
    )
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid || !this.subPageContrato.verifyBeforeSave()) {
      this.form.markAllAsTouched();
      this.checkedDiasColetas = true;
      return false;
    }

    if(this.entity.diasColeta.length <= 0) {
      this.checkedDiasColetas = true;
      return false
    }

    const {
      segmentosOperacionais, estimativaColetaMensal, bairro, cep, cidade, numero, complemento, condominioIndicador,
      parceiros, logradouro, uf, qtdApartamentos, qtdDeMoradores, cnpj, tipoCadastro, ...form
    } = this.form.getRawValue();
    const FormValuesMTR = this.subPageMTR.getData();
    const FormValuesContrato = this.subPageContrato.getData();


    // const indexParceiro = this.listParceiro.findIndex((cat) => {
    //   return cat.id == parceiro;
    // })

    if ((segmentosOperacionais || []).length <= 0) {
      return false;
    }

    this.entity = {
      ...this.entity,
      ...form,
      ...FormValuesMTR,
      ...FormValuesContrato,
      // parceiros: indexParceiro == -1 ? null : {
      //   id: this.listParceiro[indexParceiro].id,
      //   nome: this.listParceiro[indexParceiro].nome
      // },
      parceiros: parceiros.map((item: string | number) => ({ id: item })),
      segmentosOperacionais: this.autoSeguimentoOperacional.selectedList,
      estimativaColetaMensal: InputHelpers.ajusteMoneyBrToEua(estimativaColetaMensal),
      cnpj: tipoCadastro != 'CASA' ? cnpj : '',
      tipoCadastro,
      predio: {
        ...this.entity.predio,
        qtdApartamentos: tipoCadastro != 'CASA' ? qtdApartamentos : 1,
        qtdDeMoradores: qtdDeMoradores
      },
      enderecos: [{
        id: this.entity.enderecos.length > 0 ? this.entity.enderecos[0]?.id : undefined,
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: uf.toUpperCase()
      }]
    }

    return true;
  }

  override afterSaveEntity(): void {
    if (this.entity.id && !this.entityId) {
      this.entityId = this.entity.id;
    }

    this.tableParam['clienteId'] = this.entity.id;
    if (this.entity.tipoCadastro == 'CASA' || this.entity.tipoCadastro == 'CONDOMINIO') {
      this.recalcularEstimativas();
    }
  }

  recalcularEstimativas() {
    const { qtdApartamentos, qtdDeMoradores, tipoCadastro } = this.form.getRawValue()
    const qtdQuartosPorApartamento = this.entity.predio.qtdQuartosPorApartamento
    this.service.get(
      CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA,
      {
        params: {
          qtdApartamentos: tipoCadastro != 'CASA' ? qtdApartamentos : 1,
          qtdDeMoradores: qtdDeMoradores,
          qtdQuartosPorApartamentoEnumList: qtdQuartosPorApartamento
        }
      }).subscribe(resp => {
        if (resp) {
          //@ts-ignore
          this.entity?.estimativas = resp.data
        }
      })
  }

  editDiasSemana(valor: string, arrayName: 'diasColeta' | 'qtdQuartosPorApartamento') {
    if (arrayName === 'diasColeta') {
      this.checkedDiasColetas = true;
      const index = this.entity.diasColeta.findIndex(arrayDia => arrayDia == valor);
      if (index == -1) {
        this.entity.diasColeta.push(valor)
        return;
      }
      this.entity.diasColeta.splice(index, 1);

      return;
    }

    const index = this.entity.predio.qtdQuartosPorApartamento.findIndex(arrayDia => arrayDia == valor);

    if (index == -1) {
      this.entity.predio.qtdQuartosPorApartamento.push(valor);
      return;
    }

    this.entity.predio.qtdQuartosPorApartamento.splice(index, 1);
  }

  verifyCheckBox(valor: string, arrayName: 'diasColeta' | 'qtdQuartosPorApartamento'): boolean {
    if (arrayName === 'qtdQuartosPorApartamento') {
      const index = this.entity?.predio?.qtdQuartosPorApartamento.findIndex(arrayDia => arrayDia == valor);

      return index != -1;
    } else if (arrayName === 'diasColeta') {
      const index = this.entity?.diasColeta.findIndex(arrayDia => arrayDia == valor);

      return index != -1;
    }

    return false
  }

  checkboxChecked(valor: string, arrayName: 'diasColeta' | 'qtdQuartosPorApartamento'): boolean {
    if (!this.entity.diasColeta) {
      this.entity.diasColeta = [];
    }
    if (arrayName === 'diasColeta') {
      return this.entity.diasColeta.findIndex(arrayDia => arrayDia == valor) != -1
    }

    return this.entity.predio.qtdQuartosPorApartamento.findIndex(arrayDia => arrayDia == valor) != -1
  }

  setCondominioIndicador(condominio: ClientesEntity) {
    if (condominio.id) {
      this.entity.condominioIndicador = condominio
    }
  }

  setParceiro(parceiro: ParceiroEntity) {
    if (parceiro.id) {
      this.entity.parceiro = parceiro
    }
  }

  ativarDesativarCliente() {
    Swal.fire({
      icon: 'question',
      title: 'Você deseja ' + (this.entity?.ativo ? 'desativar' : 'ativar') + ' o cliente?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        let data = {
          id: this.entityId,
          ativo: !this.entity.ativo
        }
        this.service.patch(CLIENTE_ATIVO_INATIVO, data).subscribe(resp => {
          if (resp) {
            this.entity.ativo = !this.entity.ativo
            Swal.fire({
              icon: 'success',
              title: 'Gerador ' + (this.entity.ativo ? 'ativado' : 'desativado') + ' com sucesso',
              timer: 5000,
              showConfirmButton: false
            })
            this.modalAtivaDesativa.close()
          }
        })
      }
    })
  }

  private alteraStatusContainer(id: number | string, status: boolean) {
    const data = {
      "ativado": status,
      "clienteContainerId": id
    }
    this.service.patch('cliente-containers/status-ativacao', data).subscribe(
      resp => {
        crudDispatchEvent('ContainerClienteEntity')
      }
    )
  }

  checkPerfil() {
    if (this.session.user.perfil == "ADMINISTRADOR" || this.session.user.perfil == "ADMINISTRATIVO" || this.session.user.perfil == 'GALPAO') {
      if (!!this.entity?.id) {
        return true
      }
    }
    return false
  }

  checkPerfilPergunta(perfil: string) {
    if (this.session.user.perfil == 'ADMINISTRADOR' || this.session.user.perfil == 'ADMINISTRATIVO' || this.session.user.perfil == 'GALPAO') {
      if (perfil == this.form.getRawValue().tipoCadastro) {
        return true
      }
    }
    return this.session.user.perfil == perfil;
  }

  showQtdQuartos(): boolean {
    const { tipoCadastro } = this.form.getRawValue()

    return tipoCadastro == 'CONDOMINIO' || tipoCadastro == 'CASA'
  }

  getUrlLinkMorador(): string {
    return window.location.origin + '/cadastro-usuario/' + this.entity.id
  }

  ajustEntity() {
    if (!this.checkPermission()) {
      this.arquivosEntity.addActions([
        { name: 'Download', permission: false, action: () => {} },
        { name: 'Editar', permission: false, action: () => {} },
        { name: 'excluir', permission: false, action: () => {} },
      ])

      return;
    }
  }

  public paginationContato = {
    page: 0,
    size: 5,
    totalPages: 0
  }
  public contatosResult: any[] = [];

  changePaginationContato(d: any) {
    const contatosFiltrados: any[] = this.entity.contatos.filter((item: any) => !!item.contato)

    this.paginationContato = {
      ...this.paginationContato,
      page: d.page,
      size: d.size,
      totalPages: Math.ceil((contatosFiltrados || []).length / (d?.size || this.paginationContato.size))
    }

    this.contatos = contatosFiltrados.slice(
      Number(d.page) * Number(d.size),
      (Number(d.page) + 1) * Number(d.size)
    )
  }

  /**
   * serve para verificar a possibilidade de edição dos dados na maior parte da tela
   * @param aceptGalpao
   */
  checkPermission(aceptGalpao: boolean = false) {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
      return true;
    }

    if (!this.entity?.id) return true

    if (this.session.checkPerfil('PARCEIRO')) {
     return !!this.entity?.isCreated;
    }

    if (this.session.checkPerfil('GALPAO')) {
     return !!this.entity?.exclusivo;
    }

    if (aceptGalpao) {
      return this.session.checkPerfil('GALPAO');
    }

    return false;
  }

  selecctedGrupoMercado(val: any) {
    if ((val.selectedList || []).length == 0) {
      this.form.controls['segmentoMercado'].disable();
      console.log('ssss', val.selectedList)
      return
    }

    const gruposMercadoId = val.selectedList.map((item: any) => item.id)

    this.http.get('segmentos-mercado', { params: { unpaged: true, gruposMercadoId } }).subscribe((resp) => {
      //@ts-ignore
      this.listSegmentosMercado = resp.data
      this.form.controls['segmentoMercado'].enable();
    })
  }

  segmentoMercado(val: any) {
    if ((val.selectedList || []).length == 0) {
      this.form.controls['segmentosOperacionais'].disable();
      return
    }

    const segmentosMercadoId = val.selectedList.map((item: any) => item.id)

    this.http.get('segmentos-operacionais', { params: { unpaged: true, segmentosMercadoId } }).subscribe((resp) => {
      //@ts-ignore
      this.listSegmentosOperacionais = resp.data
      this.form.controls['segmentosOperacionais'].enable();
    })
  }

  ajusteTipoUsuario(tipoUsuario: TipoUsuarioClienteType) {
    if (!tipoUsuario) return '--';

    return TipoUsuarioClienteEnum[tipoUsuario] || '--';
  }

  selectSegmentosOperacionais(val: any) {
    console.log('val', val)
    this.entity.segmentosOperacionais = val.selectedList
  }

  ajustePesoTotal(val?: string | number) {
    if (!val && (val !== 0)) return "--";
    if (Number(val) == NaN) return "--";

    return Number(val).toLocaleString('BRL');
  }
}
