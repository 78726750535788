import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { RankingColetasEntity } from '../ranking-coletas.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RankingColetasService extends AbstractHttpService<RankingColetasEntity> {
  constructor(public conf: ConfigDgCrudService) {
    super(conf, environment.apiUrl, 'coletas/ranking-coleta');
  }
}
