<div id="pageRotas" *ngIf="!!entity">

  <div
    *ngIf="listColetasPendentes.length > 0"
    [class.visible]="listColetasPendentes.length > 0"
    class="container-data rotas-pendentes"
  >
    <div
      class="alert alert-danger" role="alert"
    >
      <h5 class="fc-red d-inline-flex">
        <mat-icon style="display: flex; justify-content: center; align-items: center">local_shipping</mat-icon>
        Existem coletas pendentes para o dia selecionado {{getViewDateRota()}}
      </h5>
      <div class="row">
        <div class="col-12">
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-inline-flex">
          <p>Ver rotas pendentes</p>
          <button (click)="modal.open()" class="btn btn-sm btn-success">Ver rotas</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-inline-flex">
          <p>Aceitar todas as rotas pendentes</p>
          <button (click)="aceitarColetasPendentes(true)" class="btn btn-sm btn-success">Aceitar todas</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <card>
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="card-title">Dados da Rota</h4>
              </div>
              <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <button [disabled]="disableMostrarTodos()" (click)="mostrarTodosClientes()"
                        class="btn btn-success btn-card-header">
                  Mostrar todos as unidades geradoras
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label class="form-label">DATA DA ROTA <span class="fc-red">*</span></label>
                <input
                  type="date"
                  (paste)="ajustePasteDate($event)"
                  name="date"
                  formControlName="dataDaRota"
                  class="form-control"
                  id="dataDaRota"
                  (ngModelChange)="getRotaByData($event)"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label class="form-label">Transportador <span class="fc-red">*</span></label>
                <input-auto-complete
                  #autoComplete
                  [baseValue]="entity?.galpaoNome"
                  propertValue="nome"
                  controlName="idGalpao"
                  urlServer="galpoes/nomes"
                  (outValue)="getMotoristaByGalpao($event)"
                  [disabled]="!session.checkPerfil('ADMINISTRADOR') || !this.checkDataPass() ? true : false"
                ></input-auto-complete>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label class="form-label">MOTORISTA/CATADOR <span class="fc-red">*</span></label>
                <select class="form-select" formControlName="idMotorista" id="idMotorista">
                  <option disabled value="">Selecionar</option>
                  <option *ngFor="let motorista of listaMotorista" value="{{motorista?.id}}">{{motorista.nome}}</option>
                </select>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label class="form-label">VEÍCULO <span class="fc-red">*</span></label>
                <select class="form-select" formControlName="idVeiculo" id="idVeiculo">
                  <option disabled value="">Selecionar</option>
                  <option *ngFor="let veiculo of listVeiculo" value="{{veiculo?.id}}">{{veiculo.numeroPlaca}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="form-label">Observação</label>
                <textarea class="form-control" formControlName="observacao">
                </textarea>
              </div>
            </div>
          </form>

          <hr style="margin: 1rem 0 0">

          <div class="row" style="min-height: 8rem" *ngIf="showPickList">
            <pick-list
              #pickList
              titleList="Geradores"
              [listaDisponivel]="listaSelect"
              [listaSelecionados]="entity.clientes"
              [propertItem]="propertItemPickList"
              [disabled]="{ edit: !checkDataPass() && !isCopy, reorder: !checkReorder() }"
              [classSelectedLabel]="colorirLabelPickList"
              (changeSelect)="listClientesSelecionados = pickList.getList()"
            >
            </pick-list>
          </div>

          <hr style="margin: 1rem 0 0">

          <div class="row">
            <div class="col-12">
              <table class="crud-table">
                <thead>
                <tr>
                  <th>Ordem</th>
                  <th>nome</th>
                  <th>Logradouro</th>
                  <th>Numero</th>
                  <th>Complemento</th>
                  <th>Bairro</th>
                  <th>Cidade</th>
                  <th>Estado</th>
                  <th>Status da Coleta</th>
                </tr>
                </thead>
                <tbody cdkDropList [cdkDropListDisabled]="!checkReorder() && !isCopy" class="example-list">
                <tr
                  cdkDrag
                  class="example-box row-table"
                  *ngFor="let rowCliente of listClientesSelecionados; let i = index"
                  [class.status-com-pendencia]="rowCliente.statusColeta == 'FINALIZADO_PENDENCIA'"
                  [class.status-finalizado]="rowCliente.statusColeta == 'FINALIZADO'"
                  [class.status-aberto]="rowCliente.statusColeta == 'ABERTO'"
                  [class.status-copiado]="rowCliente.statusColeta == 'COPIADO'"
                  (cdkDragDropped)="pickList.dropAction($event)"
                >
                  <td>{{i + 1}}</td>
                  <td>{{rowCliente.nome}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'logradouro')}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'numero')}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'complemento')}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'bairro')}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'cidade')}}</td>
                  <td>{{getEnderecoTabela(rowCliente, 'estado')}}</td>
                  <td>{{getStatuColeta(rowCliente)}}</td>
                </tr>
                <tr *ngIf="listClientesSelecionados.length < 1">
                  <td colspan="9" style="text-align: center">Nenhum registro encontrado</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button (click)="verifyBeforeSaveEntity()" class="btn btn-success"
          *ngIf="checkDataPass() || checkReorder() || isCopy">Salvar e Voltar
  </button>
  <button crud-deletar class="btn btn-secondary" *ngIf="entityId">Excluir</button>
  <button class="btn btn-warning" *ngIf="entityId" (click)="enviarRota()">Enviar Rota</button>
</page-footer>

<old-dg-modal #modal title="Coletas pendentes" size="xl" [afterClose]="afterClose">
  <div dg-content>
    <table class="crud-table">
      <thead>
      <tr>
        <th></th>
        <th>Id coleta pendente</th>
        <th>Gerador</th>
        <th>Nome do recipiente</th>
        <th>Identificação</th>
        <!--        <th>Tipo de resíduo</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let coleta of listColetasPendentes" style="cursor: pointer">
        <td>
          <input type="checkbox" [(ngModel)]="coleta.check" #check>
        </td>
        <td (click)="check.click()">{{coleta.idColetaPendente || '--'}}</td>
        <td (click)="check.click()">{{coleta.nome || '--'}}</td>
        <td (click)="check.click()">{{ modalPropertTable(coleta?.checkinContainerPendente, 'nomeContainer') }}</td>
        <td (click)="check.click()">{{ modalPropertTable(coleta?.checkinContainerPendente, 'identificador') }}</td>

      </tr>
      </tbody>
    </table>
  </div>

  <div dg-footer>
    <button class="btn btn-success m-1" (click)="aceitarColetasPendentes()">Salvar</button>
    <button class="btn btn-secondary m-1" (click)="modal.close()">Sair</button>
  </div>
</old-dg-modal>
