import { Component, OnInit } from '@angular/core';
import { GalpaoEntity } from '../galpao.entity';
import {GalpaoService} from "../services/galpao-service/galpao.service";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'app-galpao-main',
  templateUrl: './galpao-main.component.html',
  styleUrls: ['./galpao-main.component.scss']
})
export class GalpaoMainComponent implements OnInit {


  public mapEntity = new GalpaoEntity();

  constructor(public session: SessionService) {
    if (session.checkPerfil('PARCEIRO')) {
      this.mapEntity.addActions([
        { name: 'excluir', permission: false, action: () => {} }
      ])
    }
  }

  ngOnInit(): void {
  }

}
