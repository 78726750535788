<div id="historico-coletas-main" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Histórico de Coletas</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntity"></filter-search>
        </div>

        <div class="card-body" style="padding: 0">
          <crud-data-table
            #crudTable
            [entityMap]="mapEntity"
            [service]="service"
            [showPagination]="false"
            showDefaultAction="editar"
          ></crud-data-table>
        </div>
        <div class="card-footer">
          <dg-pagination  [dataPagination]="mapEntity.configEntityTable.pagination"
          (changePage)="crudTable.changePages($event)"></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <button crud-find-filters class="btn btn-success">Pesquisar</button>
  <button crud-table-clear class="btn btn-secondary">Limpar</button>
</page-footer>
