<div id="missao-insert-edit" class="container">
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12">
      <div>
        <card>
          <div class="card-header">
            <h4 class="card-title col-9">
              Foto da Missão
            </h4>
            <div class="col-3">
              <button class="btn btn-success fs-10" matTooltipPosition="above"
                      matTooltip="A largura é 25% maior que altura. Exemplos de resolução: 500x400,375x300,250x200. O recomendável é 500x400">
                Ajuda
              </button>
            </div>
          </div>
          <div class="card-body div-image-container">
            <img class="image-container" src="{{files.getImageToSrc(entity?.imagem)}}"
                 alt="Imagem do container">
          </div>
          <div class="div-button-image-container  pb-2">
            <button class="btn btn-success" (click)="selectImg()" [disabled]="!entity?.id">Escolher</button>
            <input #fileUploadMissao type="file" [(ngModel)]="fileMissao" (change)="uploadMissao($event)"
                   name="uploadMissao" id="uploadMissao" accept=".jpg" style="display: none;">
          </div>
        </card>
      </div>
      <div class="mt-4">
        <card>
          <div class="card-header">
            <h4 class="card-title col-9">
              Selo da Missão
            </h4>
            <div class="col-3">
              <button class="btn btn-success fs-10" matTooltipPosition="above"
                      matTooltip="A largura é 25% maior que altura. Exemplos de resolução: 500x400,375x300,250x200. O recomendável é 500x400">
                Ajuda
              </button>
            </div>
          </div>
          <div class="card-body div-image-container pb-2">
            <img class="image-container" src="{{files.getImageToSrc(entity?.selo)}}"
                 alt="Imagem do container">
          </div>
          <div class="div-button-image-container pb-2">
            <button class="btn btn-success" (click)="selectImgSelo()"
                    [disabled]="!entity?.id">Escolher
            </button>
            <input #fileUploadSelo type="file" [(ngModel)]="fileSelo" (change)="uploadSelo($event)"
                   name="uploadSelo" id="uploadSelo" accept=".jpg" style="display: none;">
          </div>
        </card>
      </div>
    </div>
    <div class="col-md-8 col-sm-12 col-xs-12">
      <card>
        <form [formGroup]="form">
          <div class="card-body">
            <div class="content-form">
              <div class="row">
                <div class="col-4">
                  <label>Nome<small class="fc-red">*</small></label>
                  <input type="text" class="form-control" formControlName="nome">
                </div>
                <div class="col-4">
                  <label>Limite de Usuários<small class="fc-red">*</small></label>
                  <input type="text" mask="0*" class="form-control"
                         formControlName="limiteClientesAceitarMissao">
                </div>
                <div class="col-4">
                  <label>Tipo da Missão<small class="fc-red">*</small></label>
                  <select class="form-select" name="tipoMissao" id="tipoMissao"
                          formControlName="tipoMissao">
                    <option value="" disabled>selecione</option>
                    <option value="CONDOMINIO">Gerador</option>
                    <option value="MORADOR">Usuário</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label>Numero de Coletas</label>
                  <input type="text" mask="0*" class="form-control" formControlName="numeroDeColetas">
                </div>
                <div class="col-4">
                  <label>Quantidade de Usuários</label>
                  <input type="text" mask="0*" class="form-control" formControlName="qtdMoradores">
                </div>
                <div class="col-4">
                  <label>Carga do Recipiente</label>
                  <select class="form-select" name="percentualDoContainer" id="percentualDoContainer"
                          formControlName="percentualDoContainer">
                    <option value="" disabled>selecione</option>
                    <option value="ABAIXO">
                      < 50%
                    </option>
                    <option value="ACEITAVEL">50%</option>
                    <option value="OTIMO">75%</option>
                    <option value="PERFEITO">100%</option>
                    <option value="EXCELENTE">150%</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label>Tipo do recipiente</label>
                  <select class="form-select" name="tipoLixoEnum" id="tipoLixoEnum"
                          formControlName="tipoLixoEnum">
                    <option value="" disabled>selecione</option>
                    <option value="BATERIA">Bateria</option>
                    <option value="ELETRO">Eletro</option>
                    <option value="LAMPADA">Lâmpada</option>
                    <option value="SELETIVA">Seletiva</option>
                  </select>
                </div>
                <div class="col-4">
                  <label>Meta de Comunicação</label>
                  <input type="text" mask="0*" id="metaComunicacao" min="0" class="form-control"
                         formControlName="metaComunicacao">
                </div>
                <div class="col-4">
                  <label>Meta de Econômia</label>
                  <input type="text" mask="0*" id="metaEconomia" min="0" class="form-control"
                         formControlName="metaEconomia">
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label>Cupom</label>
                  <select class="form-select" name="cupom" id="cupom" formControlName="cupom">
                    <option value="" disabled>selecione</option>
                    <option *ngFor="let item of listaCupom" [value]="item.id"> {{item.nome}}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <label>Master</label>
                  <select class="form-select" name="parceiro" id="parceiro"
                          formControlName="parceiro">
                    <option value="" disabled>selecione</option>
                    <option *ngFor="let item of listaGalpaoParceiro" [value]="item.id">
                      {{item.nome}} </option>
                  </select>
                </div>
                <div class="col-4">
                  <label>Link</label>
                  <input type="text" class="form-control" formControlName="link">
                </div>
                <div class="col-4">
                  <label>Pontos da Missão</label>
                  <input type="text" mask="0*" class="form-control" formControlName="pontosMissao">
                </div>
              </div>
            </div>

            <div class="row">
              <div>
                <label for="checkboxEstado" class="fs-13">
                  <input type="checkbox" name="checkboxEstado" id="checkboxEstado"
                         [checked]="entity?.restringiuMissao"
                         (change)="entity.restringiuMissao = !entity.restringiuMissao">
                  Deseja restringir a missão para alguns estados?</label>
              </div>
            </div>

            <div [hidden]="!entity?.restringiuMissao">

              <div class="row">
                <pick-list
                  #pickListEstado
                  [listaDisponivel]="listaEstado"
                  [listaSelecionados]="listaEstadoSelecionados"
                  (changeSelect)="selectEstados($event)"
                  titleList="Estados"
                  uniClass="name"
                  [propertItem]="['estado', 'name']"
                  [verifyRemoveItem]="verifyRemoveEstado"
                >
                </pick-list>
              </div>
            </div>


            <div class="row" [hidden]="pickListEstado.getList().length <= 0 || !entity.restringiuMissao">
              <div>
                <label for="checkboxCidade" class="fs-13">
                  <input
                    type="checkbox"
                    name="checkboxCidade"
                    id="checkboxCidade"
                    [checked]="checkbox.cidade"
                    (change)="checkbox.cidade = !checkbox.cidade"
                  >
                  Deseja restringir a missão para algumas cidades?
                </label>
              </div>
            </div>

            <div [hidden]="!checkbox.cidade || pickListEstado.getList().length <= 0 || !entity?.restringiuMissao">
              <div class="row">
                <pick-list
                  #pickListCidade
                  titleList="Cidades"
                  [listaDisponivel]="listaCidade"
                  [listaSelecionados]="listaCidadeSelecionadas"
                  (changeSelect)="selectCidades($event)"
                  uniClass="cidade"
                  propertItem="cidade"
                >
                </pick-list>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12">
                <label class="form-label">DESCRIÇÃO <span class="fc-red">*</span></label>
                <angular-editor formControlName="descricao" [config]="editorConfig"></angular-editor>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>

  <div class="row pt-2" *ngIf="entity?.id">
    <card style="max-height: 600px;">
      <div class="card-header">
        <h4>Geradores Confirmados</h4>
      </div>
      <div class="card-body">
        <table class="crud-table">
          <thead>
          <tr>
            <th style="width: 50%;">Nome</th>
            <th style="width: 40%;">Meta</th>
            <th style="width: 10%;"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="listaCondominiosConfirmados.length == 0">
            <td><span>Nenhum gerador cadastrada na Missão</span></td>
            <td></td>
          </tr>

          <tr *ngFor="let item of listaCondominiosConfirmados; let i = index">
            <td >{{item.cliente?.nome}}</td>

            <td>
              <input id="metaCondominio" [hidden]="!item.edit" [(ngModel)]="item.meta" type="text"
                     class="form-control">
              <span [hidden]="item.edit">{{item.meta}}</span>
            </td>

            <td>
              <div *ngIf="!item.edit">
                <button style="font-size: 1em;" class="btn" (click)="editInput(i)">
                  <mat-icon style="font-size: 1em;">edit</mat-icon>
                </button>
              </div>

              <div *ngIf="item.edit" class="row">
                <button class="btn col-3" (click)="addMeta(i)">
                  <mat-icon class="fc-green">done</mat-icon>
                </button>
                <button class="btn col-3" (click)="cancelarAcao(i)">
                  <mat-icon class="fc-red">close</mat-icon>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar [backAfterSave]="false" class="btn btn-success">Salvar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
</page-footer>
