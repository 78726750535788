import { InterfMenuList } from "./admin.menuList";

export const parceiroMenuComunicacao: InterfMenuList = {
  menuList: [
    {
      url: '/user/comunicacao', icon: 'mail', nome: 'Comunicação', subMenu: [
        { url: '/user/comunicacao/newsletters', nome: "Newsletter", icon: 'description' },
        { url: '/user/comunicacao/grupo-comunicacao', nome: "Grupo Comunicação", icon: 'group' },
        // { url: '/user/comunicacao/administracao', nome: "Administração", icon: 'settings_suggest' },
      ]
    }
  ]
}

export const parceiroMenuFinanceiro: InterfMenuList = {
  menuList: [
    {
      url: '/user/financeiro', icon: 'payments', nome: 'Financeiro', subMenu: [
        { url: '/user/financeiro/cobrancas', nome: "Cobranças", icon: 'paid' },
        { url: '/user/financeiro/fluxo-cobranca', nome: "Fluxo Cobranças", icon: 'payments' },
      ]
    }
  ]
};

export const parceiroMenuJornadaAmbiental: InterfMenuList = {
  menuList: [
    // {
    //   url: '/user/educacao-ambiental/gestao-jornada-educacao-ambiental',
    //   nome: 'Gestão Jornada Educação Ambiental',
    //   icon: 'emoji_objects'
    // },
  ]
};

export const parceiroMenuCLR = {
  menuList: [
    {

      url: '/user/logisticaReversa', nome: 'Logistica Reversa', icon: 'recycling', subMenu: [
        { url: '/user/logisticaReversa/logisticaReversa', nome: "Ponto de coleta", icon: 'published_with_change' },
        { url: '/user/logisticaReversa/pedidoCreditoLogisticaReversa', searchName: "Pedido Crédito Logistica Reversa", nome: "Pedido CLR", icon: 'attach_money' },
        { url: '/user/logisticaReversa/vendasLogisticaReversa', nome: 'Venda Logística Reversa', icon: 'autorenew' },
        { url: '/user/logisticaReversa/gestao-programa-logistica-reversa', nome: 'Gestão Programa Logística Reversa', icon: 'emoji_objects' },
        { url: '/user/logisticaReversa/relatorioMissoes', nome: 'Relatório das Missões', icon: 'assignment' },

      ],
    },

    {
      url: '/user/educacao-ambiental', nome: 'Educação ambiental', icon: 'recycling', subMenu: [
        { url: '/user/educacao-ambiental/gestao-jornada-educacao-ambiental', nome: 'Gestão Jornada Educação Ambiental', icon: 'emoji_objects' },
        { url: '/user/educacao-ambiental/cupom', nome: 'Crie Cupons de Desconto', icon: 'diamond' },
        { url: '/user/educacao-ambiental/cupomRelatorio', nome: "Relatórios de Jornada da Educação Ambiental", icon: 'assignment' },
      ]
    }
  ]
}

export const parceiroMenuList: InterfMenuList[] = [
  {
    menuList: [
      { icon: 'desktop_windows', url: '/user', nome: 'Início', },
      { url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart' },
      { url: '/user/parceiros/parceiro', nome: "Master", icon: 'group' },
      { url: '/user/galpao/galpao', nome: 'Transportador', icon: 'recycling' },
      {url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete'},
      {
        url: '/user/clientes', nome: "Gerador", icon: 'home_work', subMenu: [
          { url: '/user/clientes/clientes', nome: "Gerador", icon: 'home_work' },
          { url: '/user/clientes/funcionarios', nome: "Usuários", icon: 'account_circle' }
        ]
      },
      // {url: '/user/parceiros/categoriaNegocio', nome: 'Categoria Negócio', icon: 'straight'},
      { url: '/user/parceiros/material', nome: 'Produto/Serviço', icon: 'panorama' },
      {
        url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory', subMenu: [
          {url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory'},
        ]
      },
    ]
  },
];
