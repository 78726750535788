<card>
  <div class="card-header" style="display: block !important;">
    <h4 class="card-title">INDICADORES DA ODS 12</h4>
    <h6 class="card-subtitle fs-12">
      A estimativa de reciclagem leva em consideração o potencial de resíduos totais (RDO) que seu condomínio gera.
    </h6>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-3 d-none d-lg-flex" style="justify-content: flex-end; align-items: center">
        <img src="assets/img/ods.png">
      </div>
      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
        <div class="card card-ods">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5 class="fc-green">SELO ODS 12</h5>
                <p>Meta 2,5 até 2030 reduzir substancialmente a geração de reíduos por meio da prevenção, redução
                  reciclagem e reuso.</p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5 class="fc-green">Indicador de impacto aferido</h5>
                <p>
                  (Métrica de redução) <br>
                  Indicador 12.5.1 Taxa de reciclagem nacional toneladas de material reciclado.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-inline-flex justify-content-center">
                <table class="table-ods">
                  <thead>
                  <tr>
                    <th>Taxa de reciclagem do Brasil</th>
                    <th>Sua estimativa de reciclagem</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <span class="fs-12">({{data.anoIndicadorBrasil}}) </span>
                      <span>{{data.valorIndicadorBrasil}} % </span>
                    </td>
                    <td>{{data.taxaReciclagem}} %</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <p class="fs-12">
              Os filtros selecionados podem diminuir a média de taxa de reciclagem, devido a algumas unidades não
              possuírem dados registrados e entrarem no cálculo da média com o valor de 0%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</card>
