<old-dg-modal
  #modalPesquisa
  size="sm"
  class="modal-pesquisa"
  title="Guia de Pesquisa"
  id="modal-pesquisa"
  [afterClose]="beforeClose"
>
  <div dg-content>
    <div class="w-100">
      <input
        id="input-search-modal-sistem"
        type="text"
        [(ngModel)]="dataModalPesquisa.inputMenu"
        (ngModelChange)="findInputMenu($event)"
        class="form-control"
        (keydown)="keyDown($event)"
        (click)="tabIndex = undefined"
        autocomplete="off"
      >
      <app-navigation
        #navigation
        prefixLiClass="modal-pesquisar"
        [listNavigation]="dataModalPesquisa.menuList"
      ></app-navigation>
    </div>
  </div>
</old-dg-modal>
