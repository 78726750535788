<div class="slider carousel-cards">
  <div class="slide-track">
    <div
      class="card"
      *ngFor="let parceiro of listParceiros"
    >
      <img
        [src]="parceiro"
        class="card-img-top"
        alt=""
      />
    </div>
  </div>
</div>
