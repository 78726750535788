import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BlogService} from "../services/blog.service";

export interface InterfListFolterPost {
  ano: string,
  list_meses: {
    mes: string,
    list_posts: {
      id: string,
      titulo: string
    }[]
  }[]
}

interface listPostFilter {
  ano: string,
  mes: {
    mes: string,
    list_posts?: {
      id: string,
      titulo: string
    }[]
  }[],
  posts?: [] | null
}

export interface listGetPosts {
  dataInclusao: string,
  id: string,
  titulo: string,
}

@Component({
  selector: 'filter-blog',
  templateUrl: './filter-blog.component.html',
  styleUrls: ['./filter-blog.component.scss']
})
export class FilterBlogComponent implements OnInit {

  public listPosts2: listPostFilter[] = []

  @Output() findOnePost = new EventEmitter<number>()

  constructor(
    private service: BlogService
  ) {
    service.get('blog/filtro-anos').subscribe((resp: { data: { ano: string, mes: string[] }[] }) => {
      // @ts-ignore
      this.listPosts2 = resp.data.map(postItem => {
        // @ts-ignore
        postItem.mes = postItem.mes.map(mes => {
          return { mes }
        })
        return postItem;
      });
    })
  }

  ngOnInit(): void {
  }

  public getNomeMes(val: number | string): string {
    switch (Number(val)) {
      case 1:
        return 'Janeiro'
      case 2:
        return 'Fevereiro'
      case 3:
        return 'Março'
      case 4:
        return 'Abril'
      case 5:
        return 'Maio'
      case 6:
        return 'Junho'
      case 7:
        return 'Julho'
      case 8:
        return 'Agosto'
      case 9:
        return 'Setembro'
      case 10:
        return 'Outubro'
      case 11:
        return 'Novembro'
      case 12:
        return 'Dezembro'
      default:
        return '-'
    }
  }

  buscarPost(id: number | string){
    this.findOnePost.emit(Number(id))
  }

  getListPosts(indexAno: number, indexMes: number) {
    if (!!this.listPosts2[indexAno].mes[indexMes]?.list_posts) {

      if ((this.listPosts2[indexAno].mes[indexMes]?.['list_posts'] || []).length > 0) {
        return;
      }
    }

    this.listPosts2[indexAno].mes[indexMes]['list_posts'] = [];

    this.service.get('blog/nomes',
      {params: { ano: this.listPosts2[indexAno].ano, mes: this.listPosts2[indexAno].mes[indexMes].mes }}
    ).subscribe(resp => {
      this.listPosts2[indexAno].mes[indexMes]['list_posts'] = resp.data;
    })
  }
}
