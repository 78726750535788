import { Component, OnInit } from '@angular/core';
import { FuncionarioEntity } from '../funcionario.entity';
import { FuncionarioService } from '../service/funcionario.service';

@Component({
  selector: 'app-funcionario-main',
  templateUrl: './funcionario-main.component.html',
  styleUrls: ['./funcionario-main.component.scss']
})
export class FuncionarioMainComponent implements OnInit {

  public mapEntity = new FuncionarioEntity()

  constructor(
    public service: FuncionarioService
  ) { }

  ngOnInit(): void {
  }

}
