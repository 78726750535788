import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractInsertEdit, clearEntity, InterfDirectiveSaveEvent} from "@datagrupo/dg-crud";
import {ColetasEntity, interfPontuacao} from "../coletas.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {ColetasService} from "../services/coletas.service";
import {FormControl, Validators} from "@angular/forms";
import {ColetaArquivosService} from "../../coleta-arquivos/services/coleta-arquivos.service";
import {ColetaArquivosEntity} from "../../coleta-arquivos/coleta-arquivos.entity";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {formata_data_utc_br, verifySizeFiles} from "../../../../../helpers/helpers";
import {CheckinEntity} from "../checkin.entity";
import {RotasEntity} from "../../../rotas/rotas.entity";
import {COLETA_AVALIACAO, COLETA_PESO, COLETAS} from "../../../../../core/config/endpoint-list";
import {ColetasCheckinContainerEntity} from "../coletas-checkin-container.entity";
import {RestReturn} from "../../../../../core/config/rest-return";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../environments/environment";
import Swal from "sweetalert2";
import {HttpService} from "../../../../../services/http-service/http.service";
import {helpersTableMTR, MtrEntity} from "../mtr.entity";

@Component({
  selector: 'app-coletas-insert-edit',
  templateUrl: './coletas-insert-edit.component.html',
  styleUrls: ['./coletas-insert-edit.component.scss']
})
export class ColetasInsertEditComponent extends AbstractInsertEdit<ColetasEntity> implements OnInit, OnDestroy {

  view: 'coletas' | 'mtr' = 'coletas';

  public fileArquivoColeta: File | undefined;
  public coletaArquivosEntity = new ColetaArquivosEntity();
  public mtr = new FormControl('');
  public tableMTR: CdkDynamicTable.tableClass;
  helpersTableMTR = helpersTableMTR(this.http, this.session)

  public listCheckin: ColetasCheckinContainerEntity[] = [];
  public checkin: CheckinEntity | undefined;

  public statusCheckin = '';

  constructor(
    public conf: ConfigDgCrudService,
    public servic: ColetasService,
    public servicArquivos: ColetaArquivosService,
    public files: FilesManageService,
    public session: SessionService,
    private createTable: CdkDynamicTableService,
    private http: HttpService
  ) {
    super(conf, servic);
    this.crudConfig.backAfterSave = false;
    this.crudConfig.subscribeEntityAfterSave = false;

    if(this.session.checkPerfil('PARCEIRO')){
      this.mtr.disable()
    }

    // this.tableMTR = this.createTable.create('request', {
    //   apiData: {
    //     path: environment.apiMTR,
    //     context: 'mtr-manager/mtr/history'
    //   }
    // })
    this.tableMTR = this.createTable.createByEntity(new MtrEntity())

  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.tableMTR?.destroy()
  }

  initNewEntity(): void {
    this.entity = new ColetasEntity();
  }

  override afterFetchEntity() {
    this.tableMTR.controls.apiData.set({params: { coletaId: this.entityId }})
    this.tableMTR.find()

    this.servic.get('checkin-containers', { params: { coletaId : this.entity.id } }).subscribe(
      resp => {
        this.listCheckin = resp.data
      }
    )
    this.servic.get('checkins/coleta/' + this.entity.id).subscribe(
      resp => {
        this.checkin = resp.data
        if (resp.data?.status) {
          this.statusCheckin = resp.data?.status;
        }
      }
    )

    this.mtr.patchValue(this.entity.codigoMtr)
  }

  override afterSaveEntity() {
    this.entity['codigoMtr'] = this.mtr.value;
  }

  override beforeSaveEntity(): boolean {
    if (this.mtr.invalid) {
      this.mtr.markAllAsTouched();
      return false;
    }

    this.entity = {
      ...this.entity,
      codigoMtr: this.mtr.value
    }

    return true
  }

  verifyStar(num: number | undefined, currentStar: number): boolean {

    if (typeof num !== "number") {
     return  false;
    }
    return num >= currentStar;
  }


  alterStar(star: number, lista: "motorista" | "cliente") {
    const data = {
      "coletaId": this.entity.id,
      "estrelas": star,
      "motorista": true
    }
    let _currentStar: string | number | undefined;

    if (lista === 'motorista') {
      if (!this.entity.avaliacaoMotoristaCliente) this.entity['avaliacaoMotoristaCliente'] = { estrelas: 0 };
      _currentStar = this.entity.avaliacaoMotoristaCliente?.estrelas
      // @ts-ignore
      this.entity.avaliacaoMotoristaCliente.estrelas = star;
      data.motorista = true;

    } else {
      if (!this.entity.avaliacaoClienteMotorista) this.entity['avaliacaoClienteMotorista'] = { estrelas: 0 };
      _currentStar = this.entity.avaliacaoClienteMotorista?.estrelas
      // @ts-ignore
      this.entity.avaliacaoClienteMotorista.estrelas = star;
      data.motorista = false;
    }

    this.servic.patch(COLETA_AVALIACAO, data).subscribe(
      () => {},
      () => {
        if (lista === 'motorista') {
          // @ts-ignore
          this.entity.avaliacaoClienteMotorista.estrelas = _currentStar
        } else {
          // @ts-ignore
          this.entity.avaliacaoClienteMotorista.estrelas = _currentStar
        }
      }
    )
  }

  removeStar(lista: 'motorista' | 'cliente') {
    if (lista === 'motorista') {
      // @ts-ignore
      this.entity.avaliacaoMotoristaCliente.estrelas = 0;
      return;
    }
    // @ts-ignore
    this.entity.avaliacaoClienteMotorista.estrelas = 0;
  }

  uploadImgContainer(event: Event, id: number | string | undefined, index: number) {
    //@ts-ignore
    if (!id || !event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {

      const data = new FormData()
      data.append('arquivo', file)
      data.append('checkinContainerId', String(id))

      // @ts-ignore
      this.servic.uploadImage(data).subscribe(
        resp => {
          //@ts-ignore
          this.entity.checkin.checkinContainer[index].imagem = resp.data
        }
      )
    }
  }

  uploadFileArquivoColeta(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.fileArquivoColeta = undefined;
        return;
      }

      const data = new FormData()
      data.append('arquivo', file)
      data.append('coletaId ', String(this.entityId))

      // @ts-ignore
      this.servicArquivos.save(data).subscribe(
        resp => {
          this.fileArquivoColeta = undefined
          window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'ColetaArquivosEntity'}));
        }
      )
    }
  }

  ajusteData(val?: string){
    if (!val) return '--';

    return formata_data_utc_br(val)
  }

  alterarRota(val: RotasEntity) {
    this.fetchEntity(this.entity.id)
  }

  alteraStatusCheckin(status: string) {
    if (!this.checkin) return

    this.checkin = {
      ...this.checkin,
      status
    }

    this.servic.put('checkins', this.checkin).subscribe(
      resp => {

      }
    )
  }

  formatData() {
    if (!this.checkin?.dataColetada) return '--';
    return formata_data_utc_br(this.checkin.dataColetada)
  }

  getPontuacao() {
    this.servic.get(COLETAS + '/' + this.entity.id).subscribe((resp: RestReturn<ColetasEntity>) => {
      if (!resp?.data?.pontuacao) return;
      this.entity.pontuacao = resp.data.pontuacao;
    })
  }

  changeMtr(data: Event) {
    if (this.mtr.value.trim() != this.entity.codigoMtr) {
      this.salvar();
    }
  }

  gerarMTR() {
    this.http.http.post(environment.apiMTR + 'mtr-manager/mtr/coleta/' + this.entity.id).subscribe((resp: any) => {
      Swal.fire({
        icon: 'success',
        title: "Pedido de emissão do MTR em andamento"
      }).then();
    })
  }
}
