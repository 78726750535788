import {ClientesEntity} from "../../pages/_user/_clientes/clientes/clientes.entity";
import {environment} from "../../../environments/environment";

export const autoCompleteClientesOutPut = (value: string | number, row: ClientesEntity) => {
  if (!row) {
    return { nomeCliente: value }
  }
  return { clienteId: row.id }
}

export const autoComplete_clientes = {
  getUrl: environment.apiUrl + 'clientes/nomes',
  getServerData: "all",
  propertKey: 'id',
  propertValue: 'nome',
  nameParamFind: 'clienteId',
  nameParamFilter: 'nome',
  outputFormat: autoCompleteClientesOutPut
}
