import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { InterMetas } from '../../cadastro-indicadores-ods.entity';

@Component({
  selector: 'modal-modal-meta-ods',
  templateUrl: './modal-meta-ods.component.html',
  styleUrls: ['./modal-meta-ods.component.scss']
})
export class ModalMetaOdsComponent implements OnInit {

  public index: number | undefined

  @ViewChild('modal') modal!: OldModalComponent;
  @Output('salvar') save = new EventEmitter<{index: number | undefined, data: InterMetas}>()

  public form = new FormGroup({
    nomeMeta: new FormControl('', [Validators.required]),
    descricaoMeta: new FormControl('', [Validators.required])
  })

  constructor() { }

  ngOnInit(): void {
  }

  public open(index?: number, meta?: InterMetas) {
    if(index != undefined && !!meta ){
      this.form.patchValue({...meta})
    }
    this.index = index
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  afterClose = () => {
    this.index = undefined
    this.form.reset();
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }
     this.save.emit({index: this.index, data: this.form.value})
    this.modal.close()
  }
}
