import {Injectable} from '@angular/core';
import {HttpClient} from "@datagrupo/dg-crud";
import {VERIFY_LOGIN_EXIST} from "../../core/config/endpoint-list";
import {GenericCrudService} from "../../core/services/generic-crud-service/generic-crud.service";
import Swal from "sweetalert2";
import {clearString} from "../../helpers/helpers";

interface retornoViaCep {
  logradouro: string,
  bairro: string,
  cidade: string,
  uf: string,
  localidade: string,
  erro: boolean
}


@Injectable({
  providedIn: 'root'
})
export class HttpHelpersService {

  constructor(
    private http: HttpClient,
    private httpService: GenericCrudService
  ) {
  }

  getCep(cep: string, callBackSuccess: (d: retornoViaCep) => void, callBackError?: Function) {
    if (cep.length < 8) return;

    return this.http.get<retornoViaCep>(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
      resp => {
        if (resp.erro) {
          if (!!callBackError) {
            callBackError(true);
            return;
          }
        }

        callBackSuccess(resp)
      },
    )
  }


  public currentLogin = '';

  autoCreateLogin(nome: string | undefined, sobrenome: string | undefined, callback: (new_login: string) => void) {
    if (!!nome && !!sobrenome) {
      this.verifyLoginExist(
        (clearString(nome).toLowerCase() + '.' + clearString(sobrenome).toLowerCase()).split(' ').join('.'),
        callback,
        callback,
        false
      )
    }
  }

  timeOutDispacheVerifyLoginExist: any = null;

  verifyLoginExist(
    userName: string,
    callback: (new_login: string) => void,
    callbackSuccess?: (new_login: string) => void,
    showPopup = true
  ) {
    if (userName == this.currentLogin) return;

    clearTimeout(this.timeOutDispacheVerifyLoginExist)

    this.timeOutDispacheVerifyLoginExist = setTimeout(() => {
      this.httpService.get(VERIFY_LOGIN_EXIST, {params: {username: userName}}).subscribe(
        (resp: { data: { [key: string]: string } }) => {
          if (Object.values((resp.data || {})).length == 0) {
            this.currentLogin = userName;
            if(!!callbackSuccess) callbackSuccess(userName)
            return;
          }

          const newLogin = Object.values(resp.data)[0]
          callback(newLogin)

          if (showPopup) {
            Swal.fire({
              icon: 'warning',
              title: "Login já existe",
              text: 'Esse login já existe, incluímos o login ' + newLogin + '. Altere caso deseje.',
              timer: 3000
            }).then()
          }
        }
      )
    }, 300)
  }

}
