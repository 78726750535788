<div
    id="indicadores"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Indicadores Regionais</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        [table]="tableIndicadores"
                        tableEventId="indicadoresTable"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableIndicadores
                        [entityMap]="mapEntity"
                        [service]="service"
                        [showPagination]="false"
                        tableEventId="indicadoresTable"
                        [register]="true"
                    ></crud-data-table>
                    <div class="d-flex justify-content-center card-footer">
                        <dg-pagination
                            [dataPagination]="mapEntity.configEntityTable.pagination"
                            (changePage)="tableIndicadores.changePages($event)"
                        ></dg-pagination>
                    </div>
                </div>
            </card>
        </div>
    </div>
</div>



<page-footer>
    <a routerLink="novo">
        <button class="btn btn-success">Novo</button>
    </a>
</page-footer>
