import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TratamentoMainComponent } from './tratamento-main/tratamento-main.component';
import { TratamentoInsertEditComponent } from './tratamento-insert-edit/tratamento-insert-edit.component';
import { UiModule } from "../../../shared/ui/ui.module";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from 'src/app/core/directives/directives.module';



@NgModule({
  declarations: [
    TratamentoMainComponent,
    TratamentoInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    RouterModule,
    ReactiveFormsModule,
    DirectivesModule
  ]
})
export class TratamentoModule { }
