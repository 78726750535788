<div class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">Informações Gerais</div>
        <div>
          <div class="row">
          <div class="col-sm-4">
            <card-item class="card" size="lg" icon="assignment_ind" text="Usuários Cadastrados">
            </card-item>
          </div>
        <div class="col-sm-4">
            <card-item class="card" icon="emoji_objects" text="Missões Cadastradas">
            </card-item>
        </div>
        <div class="col-sm-4">
            <div>
            <card-item class="card" icon="home" text="Unidades Geradoras Cadastradas"></card-item>
          </div>
       </div>
      </div>
      </div>

        <div class="row">
          <div class="col-sm-6">
        <div class="card">
            <card-item icon="group" text="Masters Cadastrados"> </card-item>
          </div>
        </div>
        <div class="col-sm-6">
        <div class="card">
            <card-item
              icon="badge"
              text="Unidades Geradoras Da Logística Reversa Cadastrados">
            </card-item>
          </div>
        </div>
        </div>


          <div class="row">
          <div class="col-sm-4">
            <div class="card">
            <card-item icon="near_me" text="Total Rotas"> </card-item>
          </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
            <card-item icon="feed" text="Total Pesagem Notas Fiscais">
            </card-item>
          </div>
        </div>
        <div class="col-sm-4">
            <div class="card">
            <card-item icon="diamond" text="Total Cupons Resgatados  ">
            </card-item>
          </div>
        </div>
        </div>


        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <card-item icon="view_in_ar" text="Total Transportadores"> </card-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <card-item
                icon="portrait"
                text="Total Toneladas Venda Logistica Reversa"
              >
              </card-item>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <card-item icon="water_drop" text="Total Litros Coletados">
              </card-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <card-item icon="delete_forever" text="Total Resíduos Coletados">
              </card-item>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
