import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GalpaoFuncionariosService} from "../../../galpao-funcionarios/service/galpao-funcionarios.service";
import {GalpaoFuncionariosEntity} from "../../../galpao-funcionarios/galpao-funcionarios.entity";
import Swal from "sweetalert2";
import {ajusteDateByPaste} from "../../../../../../helpers/helpers";
import {HttpHelpersService} from "../../../../../../services/http-helpers/http-helpers.service";

@Component({
  selector: 'subComponente-galpao-funcionarios',
  templateUrl: './funcionarios.component.html',
  styleUrls: ['./funcionarios.component.scss']
})
export class FuncionariosComponent implements OnInit {

  public form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    cargo: new FormControl('', [Validators.required]),
    cpf: new FormControl('', []),
    nascimento: new FormControl('', [])
  })

  originalLogin = ''

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ nascimento: text }))

  @Input('galpaoId') galpaoId: number | string | undefined;

  @ViewChild('modalFuncionarios') modal!: OldModalComponent

  @Output() salve = new EventEmitter<any>()

  constructor(
    public service: GalpaoFuncionariosService,
    private helper: HttpHelpersService
  ) { }

  ngOnInit(): void {
    this.form.controls['login'].valueChanges.subscribe(d => {
      if (!d || !this.modal.config.open || d == this.originalLogin) return;
      this.helper.verifyLoginExist(d, (rest) => {
        this.form.patchValue({login: rest})
      })
    });
  }

  createLogin() {
    if (this.form.value?.id) return
    const {id, nome, sobrenome} = this.form.value;

    if (!!id) return

    const callback = (login: string) => {
      this.form.patchValue({login})
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }

  public open(data?: GalpaoFuncionariosEntity | any){
    if (!this.galpaoId) {
      Swal.fire({
        icon: 'error',
        title: 'Transportador não encontrado'
      }).then();
      return;
    }

    if (!!data?.id) {
      this.service.findOne(data.id, '').subscribe(
        resp => {
          this.form.patchValue({
            ...resp.data
          });
          this.originalLogin = resp.data.login;
          // this.verifyLogin()
          this.modal.open()
        }
      );
      return;
    }

    // this.verifyLogin();
    setTimeout(() => this.modal.open(), 10)
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;
    const data = {
      ...form,
      galpaoId: this.galpaoId
    }

    this.service.saveOrUpdate(data).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'GalpaoFuncionariosEntity'}));
        this.modal.close();
      }
    )

  }

  close() {
    this.modal.close()
  }

  afterClose = () => {
    this.form.reset('');
    return true
  }

  verifyDate(ev: any) {
    let ano = ev.target.value.slice(0, 4);
    let anoAtual = new Date

    if ((anoAtual.getFullYear() - ano) < 18) {
      Swal.fire({
        title: 'Funcionário menor de idade, por favor altere a data de nascimento. ',
        icon: 'error',
        confirmButtonColor: 'green'
      }).then(confirm => {
        if(confirm.isConfirmed){
          this.form.controls['nascimento'].reset();
        }
      })
    }
  }
}
