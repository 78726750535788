import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialMainComponent } from './tutorial-main/tutorial-main.component';
import {UiModule} from "../../../shared/ui/ui.module";



@NgModule({
  declarations: [
    TutorialMainComponent
  ],
  imports: [
    CommonModule,
    UiModule
  ]
})
export class TutorialModule { }
