import { CupomRelatorioEntity } from './../cupom-relatorio.entity';
import { Component, OnInit } from '@angular/core';
import { CupomRelatorioService } from '../service/cupom-relatorio.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';

@Component({
  selector: 'app-cupom-relatorio-main',
  templateUrl: './cupom-relatorio-main.component.html',
  styleUrls: ['./cupom-relatorio-main.component.scss'],
})
export class CupomRelatorioMainComponent implements OnInit {
  public mapEntity = new CupomRelatorioEntity();

  constructor(
    public service: CupomRelatorioService,
    public file: FilesManageService
    ) {}

  ngOnInit(): void {}

  baixarPlanilha() {
      this.service.get('relatoriocupom/download').subscribe(resp => {
      if(!!resp){
        console.log(resp)
        //@ts-ignore
        this.file.downloadBase64(resp.data, 'planilha-relaorio-cupom.xls')
      }
    })
  }
}
