<div id="beneficios" class="container">
  <div class="row">
    <card>
      <div class="card-header">
        <h4 class="card-title">Benefícios para o seu Gerador</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4" *ngFor="let beneficio of listaBeneficios; let i = index">
            <card class="d-flex beneficios">
              <div class="beneficiosHeader">
                <h1 class="titleMiss">{{beneficio.nome}}</h1>
              </div>
              <div class="card-body">
                <input type="file" style="display: none" accept="image/png, image/jpeg, image/jpg">
                <img class="image-beneficios" src="assets/img/default.jpg" alt="Imagem logo">
                <div class="row mt-2 detail">
                  <div>
                    <label>Valor</label>
                    <span>{{beneficio.valor | currency: 'BRL'}}</span>
                  </div>
                  <div>
                    <label>Pontos</label>
                    <span>{{beneficio.pontos}}</span>
                  </div>
                  <div>
                    <label>Valor com desconto</label>
                    <span>{{beneficio.valorDesconto | currency: 'BRL'}}</span>
                  </div>

                  <div>
                    <label>Qtd.Min por pedido</label>
                    <span>{{beneficio.quantidadePedidoMin}}</span>
                  </div>
                  <div class="col-6">
                    <label>Quantidade</label>
                    <input style="width: 30%;" [(ngModel)]="beneficio.quantidadePedido" min="0" type="number" (keydown)="checkNumber($event)"
                      placeholder="0" class="form-control">
                  </div>
                </div>
                <div>
                  <label class="subTitle">Detalhes</label>
                  <div class="subText">
                    {{beneficio.detalhes}}
                  </div>
                  <label class="subTitle">Observações</label>
                  <div class="subText">
                    {{beneficio.observacoes}}
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </card>
  </div>
</div>

<old-dg-modal #modalBeneficio size="md" title="Confirmação da Solicitação">
  <div dg-content>
    <div class="container detail">
      <div class="row">
        <label class="fs-14">Tabela de valores da solicitação incluindo todos os itens</label>
        <span></span>
      </div>
      <div class="row mt-2 detail">
        <div>
          <label>Valor Total sem desconto</label>
          <span>{{valorSomado | currency: "BRL"}}</span>
        </div>
        <div>
          <label>Valor do desconto</label>
          <span>{{valorSomado * 0.1 | currency: 'BRL'}}</span>
        </div>

        <div>
          <label>Valor final com desconto</label>
          <span>{{valorSomado - (valorSomado * 0.1) | currency:'BRL'}}</span>
        </div>
      </div>
      <div class="row" style="margin-top: 1em;">
        <label class="fs-14">Deseja confirmar a solicitação?</label>
      </div>
    </div>
  </div>
</old-dg-modal>

<page-footer>
  <button class="btn btn-success" (click)="solicitarBeneficio()">Solicitar benefícios</button>
</page-footer>
