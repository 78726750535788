import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractInsertEdit, crudDispatchEvent, InterfaceCustomActions} from "@datagrupo/dg-crud"
import {GalpaoEntity} from "../galpao.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GalpaoFuncionariosEntity} from "../../galpao-funcionarios/galpao-funcionarios.entity";
import {ColetasEntity} from "../../../_coletas/coletas/coletas.entity";
import {FuncionariosComponent} from "../subComponents/funcionarios/funcionarios.component";
import {GalpaoArquivoEntity} from "../../galpao-arquivo/galpao-arquivo.entity";
import Swal from "sweetalert2";
import {FilesManageService} from 'src/app/services/files-manage/files-manage.service';
import {DocumentosComponent} from '../subComponents/documentos/documentos.component';
import {GALPAO_DOCUMENTO_DOWNLOAD, TIPO_LIXO_GALPAO} from "../../../../../core/config/endpoint-list";
import {HttpHelpersService} from "../../../../../services/http-helpers/http-helpers.service";
import {GalpaoService} from "../services/galpao-service/galpao.service";
import {GalpaoTipoLixoEntity} from "../galpao-tipo-lixo.entity";
import {GalpaoTipoLixoComponent} from "../subComponents/galpao-tipo-lixo/galpao-tipo-lixo.component";
import {ContainerEntity} from "../../../container/container.entity";
import { defaultCallBackErrorGetCep, defaultCallBackSuccessGetCep } from 'src/app/helpers/helpers';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { AddContainerGalpaoComponent } from '../subComponents/add-container-galpao/add-container-galpao.component';
import { ParceiroEntity } from '../../../parceiro/parceiro.entity';
import { environment } from 'src/environments/environment';
import { ParceiroService } from '../../../parceiro/service/parceiro.service';
import { GalpaoContainerEntity } from '../galpao-containers.entity';

@Component({
  selector: 'app-galpao-insert-edit',
  templateUrl: './galpao-insert-edit.component.html',
  styleUrls: ['./galpao-insert-edit.component.scss']
})
export class GalpaoInsertEditComponent extends AbstractInsertEdit<GalpaoEntity> implements OnInit {

  dataInicial: string = '';
  dataFinal: string = '';

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    emailDoOperadorLogistico: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    linkDashboard: new FormControl(''),
    cep: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    logradouro: new FormControl({ value: '', disabled: true}, [Validators.required]),
    bairro: new FormControl({ value: '', disabled: true}, [Validators.required]),
    cidade: new FormControl({ value: '', disabled: true}, [Validators.required]),
    uf: new FormControl({ value: '', disabled: true}, [Validators.required]),
    parceiro: new FormControl({ value: ''}),
  })

  public listParceiro: ParceiroEntity[] = [];
  public urlAutocompleteParceiro = environment.apiUrl + 'parceiros'
  public entityGalpFuncionarios = new GalpaoFuncionariosEntity();
  public entityGalpDocumentos = new GalpaoArquivoEntity();
  public entityTipoLixo = new GalpaoTipoLixoEntity();
  public entityContainer = new GalpaoContainerEntity();

  public pesoTotalColetas = 0;
  public tableParams: { idGalpao?: number | string } = {}
  public TIPO_LIXO_GALPAO = TIPO_LIXO_GALPAO;
  public customActionsContainers: InterfaceCustomActions[] = [
    {
      name: 'editar',
      action: (val: ContainerEntity) => {
          this.modalContainer.open(val)
      },
      permission:  (val: any) => {
        return !val.modelo ? true  : false
      }
    },
    {
      name: 'excluir',
      action: () => {
      },
      permission: false
    },
    {
      name: 'Desativar container Personalizado',
      action: (val: any) => {
        const data = {
          ativado: false,
          id: val.id
        };

        this.service.patch('containers/status-ativacao', data).subscribe(
          () => {
            crudDispatchEvent('GalpaoContainerEntity')
          }
        )
      },
      permission: (val: any) => {
        return !val.modelo && !val.dataDesativacao;
      }
    },
    {
      name: 'Ativar container Personalizado',
      action: (val: any) => {
        const data = {
          ativado: true,
          id: val.id
        };

        this.service.patch('containers/status-ativacao', data, {params: {isAtivo: true}}).subscribe(
          () => {
            crudDispatchEvent('GalpaoContainerEntity')
          }
        )
      },
      permission: (val: any) => {
        return !val.modelo && val.dataDesativacao;
      }
    },
    {
      name: 'Remover relação de container modelo',
      action: (val: any) => {
        const data = {
          containerId: val.id,
          galpaoId: this.entity.id
        };

        this.service.delet('galpao-containers/modelo', { params: data}).subscribe(
          () => {
            crudDispatchEvent('GalpaoContainerEntity')
          }
        )
      },
      permission: (val: any) => {
        return !!val.modelo && !val.dataDesativacao;
      }
    }
  ];

  @ViewChild('modalFuncionarios') modalFuncionarios!: FuncionariosComponent
  @ViewChild('modalDocumentos') modalDocumentos!: DocumentosComponent
  // @ViewChild('modalColetas') modalColetas!: GalpaoColetasComponent
  @ViewChild('modalTipoLixo') modalTipoLixo!: GalpaoTipoLixoComponent
  @ViewChild('modalContainerAdd') modalContainer!: AddContainerGalpaoComponent

  public dictionaryColetas = (val: ColetasEntity) => {
    return {
      ...val,
      rota: {
        // @ts-ignore
        dataRota: val.dataColeta,
        // @ts-ignore
        motorista: val.motoristaNome
      }
    };
  }

  constructor(
    public service: GalpaoService,
    public conf: ConfigDgCrudService,
    public filesManage: FilesManageService,
    public httpHelp: HttpHelpersService,
    private http: HttpOldService,
    public session: SessionService,
    public parcService: ParceiroService
  ) {
    super(conf, service);

    if (!session.checkPerfil('GALPAO')) {
      parcService.get('parceiros', { params: { unpaged: true } }).subscribe(
        resp => {
          //@ts-ignore
          this.listParceiro = resp.data;
        }
      )
    }

    this._customEntitys();
    this.crudConfig.backAfterFetchError = false;
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  private _customEntitys() {
    if (this.session.checkPerfil('PARCEIRO')) {
      this.entityGalpDocumentos.addActions(<InterfaceCustomActions[]>[
        {
          name: 'download',
          action: (val: GalpaoArquivoEntity) => this.download(val)
        },
        {
          name: 'excluir',
          action: () => {},
          permission: false
        },
        {
          name: 'Editar',
          action: () => {},
          permission: false
        },
      ])
      return;
    }
    // FUNCIONÁRIOS
    this.entityGalpFuncionarios.addActions(<InterfaceCustomActions[]>[
      {
        name: 'Editar',
        action: (row: GalpaoFuncionariosEntity) => {
          this.modalFuncionarios.open(row)
        }
      }
    ])
    // DOCUMENTOS
    this.entityGalpDocumentos.addActions(<InterfaceCustomActions[]>[
      {
        name: 'download',
        action: (val: GalpaoArquivoEntity) => this.download(val)
      },
      {
        name: 'Editar',
        action: (value: GalpaoArquivoEntity) => {
          this.modalDocumentos.open(value)
        }
      }
    ])
    // TIPO DE RESIDUO
    this.entityTipoLixo.addActions([
      {
        name: 'Excluir',
        action: (row: any) => {
          console.log(row)
          this.service.delet(TIPO_LIXO_GALPAO,
            {
              params: {
                galpaoId: this.entity.id,
                tipoLixoId: row.id
              }
            }).subscribe(
            resp => {
              crudDispatchEvent('GalpaoTipoLixoEntity');
              this.modalTipoLixo.close();
            }
          )
        }
      },
      {
        name: 'editar',
        action: () => {
        },
        permission: false
      }
    ]);
    // CONTAINERS
    this.entityContainer.removeColumns(['capacidadeQuilos']);
    this.entityContainer.removeFilters(['nome'])
  }

  initNewEntity(): void {
    this.entity = new GalpaoEntity();
    this.getCep();
  }

  override afterFetchEntity() {
    this.form.patchValue({
      ...this.entity,
      ...this.entity.enderecos[0],
      //@ts-ignore
      uf: this.entity.enderecos[0].estado.uf,
      parceiro: this.entity?.parceiro?.id


    })
    this.tableParams = {idGalpao: this.entity.id};
    this.getPesoTotalColetas();
    this.getCep();
    if (this.session.checkPerfil('PARCEIRO')) {
      this.form.controls['nome'].disable()
      this.form.controls['cnpj'].disable()
      this.form.controls['email'].disable()
      this.form.controls['emailDoOperadorLogistico'].disable()
      this.form.controls['telefone'].disable()
      this.form.controls['linkDashboard'].disable()
      this.form.controls['cep'].disable()
      this.form.controls['numero'].disable()
      this.form.controls['complemento'].disable()
      this.form.controls['logradouro'].disable()
      this.form.controls['bairro'].disable()
      this.form.controls['cidade'].disable()
      this.form.controls['uf'].disable()
      this.form.controls['parceiro'].disable()
    }
  }

  override afterSaveEntity() {
    this.tableParams = {idGalpao: this.entity.id};
    this.getPesoTotalColetas()
  }

  getCep() {
    this.form.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        if (cep == this.form.value.cep) return
        this.httpHelp.getCep(cep,
          data => {
            this.form.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "uf": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )

      }
    )
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const {parceiro, bairro, cep, cidade, numero, complemento, logradouro, uf, ...form} = this.form.getRawValue();

    const indexParceiro = this.listParceiro.findIndex((cat) => {
      return cat.id == parceiro;
    })

    this.entity = {
      ...this.entity,
      ...form,
       parceiro: indexParceiro == -1 ? null : {
        id: this.listParceiro[indexParceiro].id,
        nome: this.listParceiro[indexParceiro].nome
      },
      enderecos: [{
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: uf.toUpperCase(),
      }]

    }

    return true;
  }

  setParceiro(parceiro: ParceiroEntity) {
    if (parceiro.id) {
      this.entity.parceiro = parceiro
    }
  }

  getPesoTotalColetas(popup = false) {
    this.service.get('coletas/galpao/totallixo/', {params: {idGalpaoTotal: this.entity.id}}).subscribe(
      resp => {
        //@ts-ignore
        this.pesoTotalColetas = resp.data.totalLixo;
        if (popup) {
          Swal.fire({
            icon: 'success',
            title: 'Peso total atualizado',
            timer: 3000
          }).then()
        }
      }
    )
  }

  getDatas(dataFim?: string, dataInicial?: string) {
    this.service.get('coletas/galpao/', {
      params: {
        idGalpao: this.entity.id,
        dataFim: dataFim,
        dataInicial: dataInicial
      }
    }).subscribe(resp => {

    })
  }

  download = (data: GalpaoArquivoEntity) => {
    if (data.id) {
      this.service.get(GALPAO_DOCUMENTO_DOWNLOAD + '/' + data.id).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
        }
      )
    }
  }

  recolheDadosFiltro(data: any) {
    let params = {}
    if (data.dataInicial == '' || data.dataInicial == undefined || data.dataFinal == '' || data.dataFinal == undefined) {
      params = {
        idGalpaoTotal: this.entity.id
      }
    } else {
      params = {
        idGalpaoTotal: this.entity.id,
        dataInicial: data.dataInicial,
        dataFinal: data.dataFinal
      }
    }

    this.service.get('coletas/galpao/totallixo/', {params}).subscribe(resp => {
      this.pesoTotalColetas = resp.data.totalLixo;
    })
  }

  format = (val: string | number) => {
    if(val){
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    }else{
      return 0
    }
  }

  ativarDesativar() {
    Swal.fire({
      icon: 'question',
      title: 'Você deseja ' + (this.entity?.ativo ? 'desativar' : 'ativar') + ' o operador?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        let data = {
          id: this.entityId,
          ativo: !this.entity.ativo
        }
        this.service.patch('galpoes/ativo', data).subscribe(resp => {
          if (resp) {
            this.entity.ativo = !this.entity.ativo
            Swal.fire({
              icon: 'success',
              title: 'Transportador ' + (this.entity.ativo ? 'ativado' : 'desativado') + ' com sucesso',
              timer: 5000,
              showConfirmButton: false
            })
            // this.modalAtivaDesativa.close()
          }
        })
      }
    })
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  downloadExcel() {
    const params = {
      unpaged: true,
    };

    this.http
      .get<{ data: string }>('coletas/galpao/planilha', {params: {idGalpao: this.entity.id}})
      .subscribe((resp) => {
        this.filesManage.downloadBase64(resp.data, 'Controle_de_Pesagem_de_Entrada.xlsx');
      });
  }
}
