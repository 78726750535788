import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { NgxMaskModule } from "ngx-mask";
import { UiModule } from "../../../../shared/ui/ui.module";
import { GalpaoMotoristaInsertComponent } from './galpao-motorista-insert/galpao-motorista-insert.component';
import { GalpaoMotoristaMainComponent } from './galpao-motorista-main/galpao-motorista-main.component';
import { MotoristaVeiculoComponent } from './subComponents/motorista-veiculo/motorista-veiculo.component';
import { MotoristaDocumentoComponent } from './subComponents/motorista-documento/motorista-documento.component';
import {DirectivesModule} from "../../../../core/directives/directives.module";
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";



@NgModule({
  declarations: [
    GalpaoMotoristaMainComponent,
    GalpaoMotoristaInsertComponent,
    MotoristaVeiculoComponent,
    MotoristaDocumentoComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        FormsModule,
        DirectivesModule,
        DgAutocompleteModule
    ]
})
export class GalpaoMotoristaModule { }
