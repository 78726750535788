import { GalpaoArquivoEntity } from '../galpao-arquivo.entity';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GalpaoArquivoService } from '../service/galpao-arquivo.service';
import { FilesManageService } from "../../../../../services/files-manage/files-manage.service";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { CrudDataTableComponent, InterfaceCustomActions } from '@datagrupo/dg-crud';


@Component({
  selector: 'app-galpao-arquivo-main',
  templateUrl: './galpao-arquivo-main.component.html',
  styleUrls: ['./galpao-arquivo-main.component.scss']
})
export class GalpaoArquivoMainComponent implements OnInit {

  public mapEntity = new GalpaoArquivoEntity();

  @ViewChild('tableGalpaoArquivo') tableGalpaoArquivo!: CrudDataTableComponent

  constructor(
    public servic: GalpaoArquivoService,
    private filesManage: FilesManageService,
    public session: SessionService,
    public route: Router
  ) {

  }

  ngOnInit(): void {
  }

  download = (data: GalpaoArquivoEntity) => {
    if (data.id) {
      this.servic.download(Number(data.id)).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data.file, data.fileNameOriginal)
        }
      )
    }
  }
}
