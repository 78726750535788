<old-dg-modal #modal size="xl" title="Favoritos" [afterClose]="clear">
  <div dg-content>
    <div class="row">
      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <h4
              class="nav-link card-title"
              (click)="view = 'coletas'"
              [class.active]="view == 'coletas'"
            >
              Coletas
            </h4>
          </li>
          <li class="nav-item" *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRATIVO', 'GALPAO'])">
            <h4
              class="nav-link card-title"
              (click)="view = 'estoque'"
              [class.active]="view == 'estoque'"
            >
              Estoque
            </h4>
          </li>
          <li class="nav-item" *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRATIVO', 'GALPAO'])">
            <h4
              class="nav-link card-title"
              (click)="view = 'ranking'"
              [class.active]="view == 'ranking'"
            >
              Rankings
            </h4>
          </li>
        </ul>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 d-inline-flex justify-content-end">
        <button class="btn btn-success m-1" (click)="modalInsertEdit.open(view)">Novo favorito</button>
      </div>
    </div>

    <div class="row" [hidden]="view != 'coletas'">
<!--      <div class="col-12 d-inline-flex justify-content-center mt-1 mb-1">-->
<!--        <button class="btn btn-success m-1" (click)="modalInsertEdit.open('coletas')">Novo favorito</button>-->
<!--      </div>-->
      <div class="col-12">
        <table class="crud-table dg-table">
          <thead>
          <tr>
            <th>Nome do Filtro</th>
            <th>Unidades Geradoras</th>
            <th>Operadores Logísticos</th>
            <th>Motorista/Catador</th>
            <th>Tipo de Resíduo</th>
            <th>Data Início</th>
            <th>Data Fim</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngIf="(dataTables.coletas.length <= 0 && !loaders.coletas)" colspan="6" style="text-align: center">
              Nenhum registro encontrado
            </td>
            <td *ngIf="loaders.coletas" colspan="8">
              <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                <mat-spinner></mat-spinner>
              </div>
            </td>
          </tr>
          <tr *ngFor="let fav of (dataTables.coletas || [])">
            <td>{{fav['nome']}}</td>
            <td>{{getListPropert(fav, 'clientes')}}</td>
            <td>{{getListPropert(fav, 'galpoes')}}</td>
            <td>{{getListPropert(fav, 'motoristas')}}</td>
            <td>{{getListPropert(fav, 'tipoLixos')}}</td>
            <td>{{ajustaData(fav['dataInicio'])}}</td>
            <td>{{ajustaData(fav['dataFim'])}}</td>
            <td class="cdk-column-actions">
              <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="modalInsertEdit.open('coletas', fav)">Editar</button>
                <!--                <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>-->
              </mat-menu>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" [hidden]="view != 'estoque'">
      <div class="col-12">
        <table class="crud-table">
          <thead>
          <tr>
            <th>Nome do Filtro</th>
            <th>Operadores Logísticos</th>
            <th>Data Início</th>
            <th>Data Fim</th>
            <th>Tipo de rasíduo</th>
            <th>Estados</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngIf="((dataTables.estoque || []).length <= 0 && !loaders.estoque)" colspan="6"
                style="text-align: center">Nenhum registro encontrado
            </td>
            <td *ngIf="loaders.estoque" colspan="8">
              <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                <mat-spinner></mat-spinner>
              </div>
            </td>
          </tr>
          <tr *ngFor="let fav of (dataTables.estoque)">
            <td>{{fav['nome']}}</td>
            <td>{{getListPropert(fav, 'galpoes')}}</td>
            <td>{{ajustaData(fav['dataInicio'])}}</td>
            <td>{{ajustaData(fav['dataFim'])}}</td>
            <td>{{getListPropert(fav, 'tipoLixos')}}</td>
            <td>{{getListPropert(fav, 'estados')}}</td>
            <td class="cdk-column-actions">
              <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="modalInsertEdit.open('estoque', fav)">Editar</button>
                <!--                <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>-->
              </mat-menu>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" [hidden]="view != 'ranking'">
      <div class="col-12 d-inline-flex justify-content-center mt-1 mb-1">
        <button class="btn btn-success m-1">Novo favorito</button>
      </div>
      <div class="col-12">
        <table class="crud-table">
          <thead>
          <tr>
            <th>Nome do Filtro</th>
            <th>Unidades Geradoras</th>
            <th>Estados</th>
            <th>Tipo de Resíduo</th>
            <th>Data Início</th>
            <th>Data Fim</th>
            <th>Ativo/Inativo</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngIf="((dataTables.ranking || []).length <= 0 && !loaders.ranking)" colspan="6"
                style="text-align: center">Nenhum registro encontrado
            </td>
            <td *ngIf="loaders.ranking" colspan="8">
              <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                <mat-spinner></mat-spinner>
              </div>
            </td>
          </tr>
          <tr *ngFor="let fav of (dataTables.ranking || [])">
            <td>{{fav['nome']}}</td>
            <td>{{getListPropert(fav, 'clientes')}}</td>
            <td>{{getListPropert(fav, 'estados')}}</td>
            <td>{{getListPropert(fav, 'tipoLixos')}}</td>
            <td>{{ajustaData(fav['dataInicio'])}}</td>
            <td>{{ajustaData(fav['dataFim'])}}</td>
            <td>{{getAtivoInativo(fav['ativo'])}}</td>
            <td class="cdk-column-actions">
              <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="modalInsertEdit.open('ranking', fav)">Editar</button>
                <!--                <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>-->
              </mat-menu>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div dg-footer class="d-inline-flex justify-content-center">
    <button (click)="close()" class="btn btn-secondary">Fechar</button>
  </div>
</old-dg-modal>

<modal-favoritos-insert-edit2 #modalInsertEdit (afterSave)="loaderTables($event)">
</modal-favoritos-insert-edit2>
