import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit, HttpClient, InterfaceCustomActions } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import Swal from 'sweetalert2';
import { GalpaoMotoristaEntity } from '../galpao-motorista.entity';
import { GalpaoMotoristaService } from '../service/galpao-motorista.service';
import { GalpaoEntity } from "../../galpao/galpao.entity";
import { GalpaoMotoristaVeiculoEntity } from "../../galpao-motorista-veiculo/galpao-motorista-veiculo.entity";
import { MotoristaVeiculoComponent } from "../subComponents/motorista-veiculo/motorista-veiculo.component";
import { GalpaoArquivoMotoristaEntity } from "../../galpao-arquivo-motorista/galpao-arquivo-motorista.entity";
import { ArquivoEntity } from "../../../../../shared/entitys/arquivo.entity";
import { MotoristaDocumentoComponent } from "../subComponents/motorista-documento/motorista-documento.component";
import { FilesManageService } from "../../../../../services/files-manage/files-manage.service";
import { ARQUIVO_MOTORISTA_IMAGEM, GALPAO } from "../../../../../core/config/endpoint-list";
import { HttpHelpersService } from 'src/app/services/http-helpers/http-helpers.service';
import {
  defaultCallBackErrorGetCep,
  defaultCallBackSuccessGetCep,
  verifySizeFiles
} from "../../../../../helpers/helpers";

@Component({
  selector: 'app-galpao-motorista-insert',
  templateUrl: './galpao-motorista-insert.component.html',
  styleUrls: ['./galpao-motorista-insert.component.scss']
})
export class GalpaoMotoristaInsertComponent extends AbstractInsertEdit<GalpaoMotoristaEntity> implements OnInit {

  @ViewChild('imgMotorista') imgMotorista!: ElementRef;
  @ViewChild('modalVeiculos') modalVeiculos!: MotoristaVeiculoComponent;
  // @ViewChild('modalDocumentos') modalDoc!: UploadFilesModalComponent;
  @ViewChild('modalDocumentoMotorista') modalDoc!: MotoristaDocumentoComponent;

  public file: File | undefined;
  public fileCnh: File | undefined;
  public listaNomeGalpao: GalpaoEntity[] = [];
  public nomeCnh: any;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    login: new FormControl('', [Validators.required]),
    telefone: new FormControl(''),
    celular: new FormControl('', [Validators.required]),
    identidade: new FormControl(''),
    cpf: new FormControl(''),
    galpao: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    ajudanteUm: new FormControl('', [Validators.required]),
    ajudanteDois: new FormControl(''),
    habilitacao: new FormControl(''),
    cep: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    logradouro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    bairro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    cidade: new FormControl({ value: '', disabled: true }, [Validators.required]),
    uf: new FormControl({ value: '', disabled: true }, [Validators.required]),
  });

  public mapVeiculoEntity = new GalpaoMotoristaVeiculoEntity();
  public mapDocumentosEntity = new GalpaoArquivoMotoristaEntity();

  public paramsTables: { motoristaId?: number | string } = {};

  constructor(
    public servic: GalpaoMotoristaService,
    public conf: ConfigDgCrudService,
    public http: HttpClient,
    public filesManage: FilesManageService,
    public httpHelp: HttpHelpersService,
    private helper: HttpHelpersService
  ) {
    super(conf, servic);

    this.mapVeiculoEntity.addActions([
      {
        name: 'editar',
        action: (value: GalpaoMotoristaVeiculoEntity) => { this.modalVeiculos.open(value) }
      }
    ])
    this.mapDocumentosEntity.addActions(<InterfaceCustomActions[]>[
      {
        name: 'download',
        action: (val: GalpaoArquivoMotoristaEntity) => { this.download(val) }
      },
      {
        name: 'editar',
        action: (v: GalpaoArquivoMotoristaEntity) => {
          this.modalDoc.open(v)
        }
      }
    ])

    this.mapDocumentosEntity.removeColumns(['motorista'])

    servic.get(GALPAO, { params: { unpaged: true } }).subscribe(
      (resp) => {
        //@ts-ignore
        this.listaNomeGalpao = resp.data;
      }
    )

  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.form.controls['login'].valueChanges.subscribe(d => {
      if (d == this.entity?.login) return

      this.helper.verifyLoginExist(d, (rest) => {
        this.form.patchValue({ login: rest })
      })
    });
  }

  override afterSaveEntity() {
    this.paramsTables = { motoristaId: this.entity.id };
  }

  initNewEntity(): void {
    this.entity = new GalpaoMotoristaEntity()
    this.getCep();
  }

  override afterFetchEntity(): void {
    if (this.entity.anonimo) {
      Swal.fire({
        icon: 'warning',
        title: 'Não é possível alterar um motorista anônimo'
      }).then()

      window.history.back();
    }

    this.form.patchValue({
      ...this.entity,
      ...this.entity.enderecos[0],
      galpao: this.entity.galpao?.id,
      ...this.entity.ajudante,
      //@ts-ignore
      uf: this.entity.enderecos[0].estado.uf
    });
    this.nomeCnh = this.entity.imagemHabilitacao?.fileNameOriginal
    this.paramsTables = { motoristaId: this.entityId };
    this.getCep();
  }

  selectImg() {
    const fileImge = this.imgMotorista.nativeElement;
    fileImge.click()
  }

  uploadImg(event: Event, perfil?: boolean) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    // @ts-ignore
    if (!verifySizeFiles(event.target?.files[0])) {
      if (perfil) {
        this.file = undefined;
      } else {
        this.fileCnh = undefined;
      }
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {

      // @ts-ignore
      if ((file.size || 0) > 20971520) {
        Swal.fire({
          icon: 'error',
          title: 'Tamanho da imagem maior que o permitido',
          text: 'O tamanho máximo permitido nos arquivos do sistema é de 20mb'
        })
        this.file = undefined
        return;
      }

      const data = new FormData()
      data.append('arquivo', file)
      data.append('motoristaId', String(this.entity.id))
      data.append('perfil', String(!!perfil))

      this.servic.post(ARQUIVO_MOTORISTA_IMAGEM, data).subscribe(
        resp => {
          if (!!perfil) {
            this.entity.imagem = resp.data
          } else {
            this.entity.imagemHabilitacao = resp.data
            this.nomeCnh = resp.data.fileNameOriginal
          }

          Swal.fire({
            icon: 'success',
            title: (!!perfil ? 'Imagem de Perfil atualizada' : "CNH atualizada"),
            timer: 3000
          })

        }
      )
    }

  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      Swal.fire({
        title: 'Erro!',
        icon: 'warning',
        text: 'Preencha todos os dados obrigatórios',
        showConfirmButton: false,
        timer: 3000
      })
      return false;
    }

    const { bairro, cep, cidade, numero, complemento, logradouro, uf, ajudanteUm, ajudanteDois, galpao, ...formV } = this.form.getRawValue();

    this.entity = {
      ...this.entity,
      ...formV,
      ajudante: {
        ajudanteUm: ajudanteUm,
        ajudanteDois: ajudanteDois
      },
      enderecos: [{
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: uf.toUpperCase()
      }],
      galpao: !!galpao ? { id: galpao } : null,
    }
    return true;
  }

  getCep() {
    this.form.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        this.httpHelp.getCep(cep,
          data => {
            this.form.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "uf": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )

      }
    )
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  getImageToSrc(imagem: ArquivoEntity | undefined): string {
    if (!!imagem) {
      if (!!imagem?.file) {
        return 'data:image/' + imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1] + ';base64,' + imagem.file
      }
    }
    return '/assets/img/default.jpg'
  }

  download = (data: GalpaoArquivoMotoristaEntity) => {
    if (data?.id) {
      this.servic.get('arquivo-motorista/' + data.id).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data, data.arquivo.fileNameOriginal || 'arquivo')
        }
      )
    }
  }

  downloadCnh() {
    this.servic.get(`motoristas/${this.entity.id}/habilitacao`).subscribe(
      resp => {
        //@ts-ignore
        this.filesManage.downloadBase64(resp.data.file, resp.data.fileNameOriginal || 'arquivo')

      })
  }

  createLogin() {
    if (!!this.entity.login) return

    const { nome, sobrenome } = this.form.value;

    const callback = (login: string) => {
      this.form.patchValue({ login })
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }
}
