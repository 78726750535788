import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {HttpService} from "../../../../../services/http-service/http.service";
import * as repl from "repl";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'modal-favoritos-main',
  templateUrl: './modal-favoritos-main.component.html',
  styleUrls: ['./modal-favoritos-main.component.scss']
})
export class ModalFavoritosMainComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;

  @Output('uploadeFavoritoList') uploadeFavoritoList = new EventEmitter<'coletas' | 'estoque' | 'ranking'>()

  view: 'coletas' | 'estoque' | 'ranking' = 'coletas';

  loaders = {
    coletas: true,
    estoque: true,
    ranking: true,
  }

  dataTables = {
    coletas: [],
    estoque: [],
    ranking: [],
  }

  constructor(private http: HttpService, public session: SessionService) {
  }

  ngOnInit(): void {
  }

  open() {
    this.loaderTables('estoque')
    this.loaderTables('coletas')
    this.loaderTables('ranking')
    this.modal.open()
  }

  close() {
    this.modal.close()
  }

  clear = () => {
    this.loaders = {
      coletas: true,
      estoque: true,
      ranking: true,
    }

    this.dataTables = {
      coletas: [],
      estoque: [],
      ranking: [],
    }

  }

  loaderTables(loader: 'coletas' | 'estoque' | 'ranking') {
    const config = {
      coletas: 'DASHBOARD_COLETAS',
      estoque: 'DASHBOARD_ESTOQUE',
      ranking: 'RANKING_TAXA_RECICLAGEM',
    }

    this.loaders[loader] = true;
    this.dataTables[loader] = [];

    this.uploadeFavoritoList.emit(this.view)

    this.http.get('favoritos', {params: {tipoFavorito: config[loader], unpaged: true, sort: 'nome'}}).subscribe(
      resp => {
        this.loaders[loader] = false;
        this.dataTables[loader] = resp.data
      }
    )

  }

  getListPropert(
    fav: any,
    propert: 'clientes' | 'estados' | 'galpoes' | 'motoristas' | 'tipoLixos' | 'dataInicio'
  ): string {
    let result = Array.isArray(fav[propert]) ? fav[propert] : [];

    if (result.length <= 0) return '--';

    result = result.map((item: any) => {
      if (propert == 'estados') {
        return item['name']
      }
      return item['nome']
    }).join(', ')

    if (result.length > 100) {
      result = result.slice(0, 100)
      result = result.split(', ')
      result.pop()
      result = result.join(', ') + '...'
    }

    return result;
  }

  ajustaData(data: string): string {
    if (!data) return '--';
    return data.split('-').reverse().join('/');
  }

  getAtivoInativo(d: string | undefined) {
    if (!d) return 'Ambos';
    return d == 'true' ? 'Ativos' : 'Inativos';
  }
}
