<layout-side-filter idOpen="ranking" (clearFilter)="clear()">
  <div
    class="row"
    *ngIf="tipoRanking == 'taxaReciclagem'"
  >
    <div class="col-12">
      <label class="form-label">Tipo de Perfil (Gerador)</label>
      <select
        class="form-select"
        [(ngModel)]="_dataFilter.perfilUnidade"
        (ngModelChange)="verifyChangeData('perfilUnidade')"
      >
        <option disabled value="">Selecione um perfil</option>
        <option value="EMPRESA">Empresa</option>
        <option value="CONDOMINIO">Condomínio</option>
        <option value="EVENTO">Evento</option>
        <option value="CASA">Casa</option>
      </select>
    </div>
  </div>

  <div
    class="row"
  >
    <div class="col-12">
      <label class="form-label">Unidades Geradoras</label>
      <input-autocomplete
        key="id"
        label="nome"
        placeholder="Selecione um gerador"
        [multiple]="true"
        [showSelectedList]="true"
        [(ngModel)]="_dataFilter.unidades"
        [list]="(listItensDisponiveis.unidades || [])"
        [preSelectedList]="listsPreSelected.unidades"
        (close)="setAutoComplete($event, 'unidades')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Início</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'periodoInicio')"
        type="date"
        [max]='maxDate'
        [(ngModel)]="_dataFilter.periodoInicio"
        (change)="verifyChangeData('periodoInicio')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Fim</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'periodoFim')"
        type="date"
        [(ngModel)]="_dataFilter.periodoFim"
        (ngModelChange)="verifyChangeData('periodoFim')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Estados</label>
      <input-autocomplete
        key="uf"
        label="name"
        placeholder="Selecion um estado"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.estados"
        [list]="(listItensDisponiveis.estados || [])"
        [preSelectedList]="listsPreSelected.estados"
        (close)="setAutoComplete($event, 'estados')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Tipos de Resíduo</label>
      <input-autocomplete
        key="id"
        label="nome"
        placeholder="Selecione um tipo de resíduo"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.tipoResiduo"
        [list]="(listItensDisponiveis.tipoResiduo || [])"
        [preSelectedList]="listsPreSelected.tipoResiduo"
        (close)="setAutoComplete($event, 'tipoResiduo')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Ativo/Inativo</label>
      <select [(ngModel)]="_dataFilter.ativo" (change)="setQueryParams()" class="form-select">
        <option [value]="''">Ambos</option>
        <option [value]="'true'">Ativo</option>
        <option [value]="'false'">Inativo</option>
      </select>
    </div>
  </div>

</layout-side-filter>
