import { Injectable } from '@angular/core';
import { AbstractHttpService, HttpUpdateServiceInterface } from '@datagrupo/dg-crud';
import { ContainerEntity } from '../container.entity';
import { ConfigDgCrudService } from '../../../../core/config/config-dg-crud/config-dg-crud.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContainerService extends AbstractHttpService<ContainerEntity> {
  public test = {
    data: <ContainerEntity[]>[],
  };

  constructor(public conf: ConfigDgCrudService) {
    super(conf, environment.apiUrl, 'containers');

  }

  override update(data: any, endpointData?: string | boolean | HttpUpdateServiceInterface | undefined): Observable<any> {
    return this.patch(this.context, data)
  }
}
