<div class="card card-banner-03">
  <a href="https://app.pipefy.com/portals/minhacoletaajuda" target="_blank">
    <div class="h-100 w-100 card-banner-03-img">
      <div style="height: 22rem; max-height: 22rem">
        <h5 class="fc-white" style="text-align: center; padding-top: 1.5rem">
          Ajuda
          <mat-icon class="pt-1">quiz</mat-icon>
        </h5>
      </div>
    </div>
  </a>
</div>
