

export interface themeColorButtons {
  nome: string,
  classe: string,
  backgroungColor: string,
  backgroungColorHover: string,
  textColor: string,
}

export interface themeSideBarColors {
  background: string,
  backgroundItemSelected: string,
  colorText: string
}

export interface themePaginationColors {
  background: string,
  backgroundItemSelected: string,
  colorText: string
  colorTextItemSelected: string,
}

export interface themeTypograghyColors {
  colortitle: string
  colorText: string
  destaqueClaro: string
  destaqueEscuro: string
}

export class PropertiesWhiteLabel {
  constructor(
    props?: {
      colorButtons?: themeColorButtons[],
      sideBarColors?: themeSideBarColors,
      paginationColors?: themePaginationColors,
      thypograghy?: themeTypograghyColors,
    }
  ) {
    if (!!props) {
      if (Array.isArray(props?.colorButtons)) {
        this.colorButtons = props.colorButtons;
      }
      this.sideBarColors = { ...this.sideBarColors, ...props?.sideBarColors }
      this.paginationColors = { ...this.paginationColors, ...props?.paginationColors }
      this.thypograghy = { ...this.thypograghy, ...props?.thypograghy }
    }
  }
  colorButtons2: themeColorButtons[] = [
    {
      nome: "Primário",
      classe: 'primary',
      backgroungColor: "#0d6efd",
      backgroungColorHover: "#257DFF",
      textColor: "#F8F9FA",
    },
    {
      nome: "Secundário",
      classe: 'secondary',
      backgroungColor: '#6C757D',
      backgroungColorHover: '#79838c',
      textColor: '#F8F9FA',
    },
    {
      nome: "Afirmativo",
      classe: 'success',
      backgroungColor: '#518446',
      backgroungColorHover: '#5E8655',
      textColor: '#F8F9FA',
    },
    {
      nome: "Informação",
      classe: 'info',
      backgroungColor: '#0DCAF0',
      backgroungColorHover: '#13d8ff',
      textColor: '#F8F9FA',
    },
    {
      nome: "Alerta",
      classe: 'warning',
      backgroungColor: '#FFC107',
      backgroungColorHover: '#e8b006',
      textColor: '#212529',
    },
    {
      nome: "Risco",
      classe: 'danger',
      backgroungColor: '#DC3545',
      backgroungColorHover: '#c92d3c',
      textColor: '#F8F9FA',
    },
    {
      nome: "Light",
      classe: 'light',
      backgroungColor: '#F8F9FA',
      backgroungColorHover: '#e1e1e1',
      textColor: '#212529',
    },
    {
      nome: "Dark",
      classe: 'dark',
      backgroungColor: '#212529',
      backgroungColorHover: '#30353a',
      textColor: '#F8F9FA',
    },
  ];
  colorButtons: themeColorButtons[] = [
    {
      nome: "Primário",
      classe: "primary",
      backgroungColor: "#0d6efd",
      backgroungColorHover: "#257DFF",
      textColor: "#F8F9FA"
    },
    {
      nome: "Secundário",
      classe: "secondary",
      backgroungColor: "#acaeaf",
      backgroungColorHover: "#9e9e9e",
      textColor: "#000000"
    },
    {
      nome: "Afirmativo",
      classe: "success",
      backgroungColor: "#00af81",
      backgroungColorHover: "#009970",
      textColor: "#F8F9FA"
    },
    {
      nome: "Informação",
      classe: "info",
      backgroungColor: "#0DCAF0",
      backgroungColorHover: "#13d8ff",
      textColor: "#F8F9FA"
    },
    {
      nome: "Alerta",
      classe: "warning",
      backgroungColor: "#FFC107",
      backgroungColorHover: "#e8b006",
      textColor: "#212529"
    },
    {
      nome: "Risco",
      classe: "danger",
      backgroungColor: "#DC3545",
      backgroungColorHover: "#c92d3c",
      textColor: "#F8F9FA"
    },
    {
      nome: "Light",
      classe: "light",
      backgroungColor: "#F8F9FA",
      backgroungColorHover: "#e1e1e1",
      textColor: "#212529"
    },
    {
      nome: "Dark",
      classe: "dark",
      backgroungColor: "#212529",
      backgroungColorHover: "#30353a",
      textColor: "#F8F9FA"
    }
  ];
  sideBarColors: themeSideBarColors = {
    background: "#ffffff",
    backgroundItemSelected: "rgba(255, 255, 255, 0.3)",
    colorText: "#0091bb"
  };
  paginationColors: themePaginationColors = {
    background: "#FFFFFF",
    backgroundItemSelected: "#0091bb",
    colorText: "#0091bb",
    colorTextItemSelected: "#FFFFFF"
  };
  thypograghy: themeTypograghyColors = {
    colortitle: "#888888",
    colorText: "#888888",
    destaqueClaro: "#00af81",
    destaqueEscuro: "#0091bb"
  };
}
