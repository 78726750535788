<old-dg-modal
  #modalFuncionarios
  size="md"
  title="Dados do Usuário"
  [afterClose]="afterClose"
>
  <form dg-content [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label for="nomefc" class="form-label">Nome <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="nome" (focusout)="createLogin()" id="nomefc">
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label for="sobrenome" class="form-label">Sobrenome <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="sobrenome" (focusout)="createLogin()" id="sobrenome">
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label for="login" class="form-label">Login <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="login" id="login">
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="emailfc" class="form-label">Email <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="email" id="emailfc">
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="cargo" class="form-label">Cargo <span class="fc-red">*</span></label>
<!--        <input type="text" class="form-control" formControlName="cargo" id="cargo">-->
        <select class="form-select" formControlName="cargo" id="cargo">
          <option value="" disabled>Selecione um cargo</option>
          <option value="OPERADOR_LOGITISCO">operador de logística</option>
          <option value="ADMIN">Administrativo</option>
          <option value="FINANCEIRO">Financeiro</option>
          <option value="PESAGEM">Pesagem</option>
          <option value="COMERCIAL">Comercial</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="cpf" class="form-label">CPF</label>
        <input type="text" class="form-control" formControlName="cpf" id="cpf" mask="000.000.000-00">
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="dataNascimento" class="form-label">Data Nascimento</label>
        <input type="date" (change)="verifyDate($event)" (paste)="ajustePasteDate($event)" class="form-control" formControlName="nascimento" id="dataNascimento">
      </div>
    </div>
  </form>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
