import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OldModalComponent } from "../../../../../../shared/ui/modal/modal/modal.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ProdutoEntity } from "../../../../produto/produto.entity";
import { ProdutoService } from "../../../../produto/service/produto.service";
import { NotaFiscalProdutosEntity } from "../../nota-fiscal-produtos.entity";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'modal-produto-nota-fiscal',
  templateUrl: './produto-nota-fiscal.component.html',
  styleUrls: ['./produto-nota-fiscal.component.scss']
})
export class ProdutoNotaFiscalComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;
  @Output('salvar') salvar = new EventEmitter<any>()

  public listProdutos: ProdutoEntity[] = [];

  public formModalProd = new FormGroup({
    id: new FormControl(''),
    produtos: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    precoPorTonelada: new FormControl('', [Validators.required])
  })

  constructor(public service: ProdutoService) {
    this.service.get('produtos/nomes').subscribe(
      resp => {
        this.listProdutos = resp.data;
      }
    )
    this.formModalProd.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.formModalProd, v, ['quantidade'])
    )

    this.formModalProd.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.formModalProd, v, ['precoPorTonelada'])
    )
  }

  public reset = () => {
    this.formModalProd.reset({
      produtos: '',
      quantidade: '',
      precoPorTonelada: '',
      id: ''
    });
  }

  ngOnInit(): void {
  }


  open(data?: any) {
    if (!!data) {
      this.formModalProd.patchValue({
        produtos: data.produto?.id,
        quantidade: InputHelpers.initInputDecimal(data.quantidade || '') || data.quantidade,
        precoPorTonelada: InputHelpers.initInputMoney(data.precoPorTonelada || '') || data.precoPorTonelada,
        id: data.id
      })
    }
    console.log(data)
    this.modal.open();
  }

  salvarProdutos() {
    if (this.formModalProd.invalid) {
      this.formModalProd.markAllAsTouched();
      return;
    }
    const { precoPorTonelada, quantidade, ...data } = this.formModalProd.value;

    const produto = this._getRelacao(data.produtos);
    const dataF = <NotaFiscalProdutosEntity>{
      quantidade: (InputHelpers.ajusteMoneyBrToEua(quantidade)),
      precoPorTonelada: (InputHelpers.ajusteMoneyBrToEua(precoPorTonelada)),
      id: data.id,
      produto: { id: produto?.id, nome: produto?.nome },
    }
    this.salvar.emit(dataF);
    this.modal.close()
  }

  private _getRelacao(id: string | number) {
    const index = this.listProdutos.findIndex(prod => prod.id == id)

    if (index != -1) {
      return this.listProdutos[index];
    }

    return undefined
  }

  close() {
    this.modal.close();
  }
}
