import { Component, OnInit } from '@angular/core';

interface interfDocMenu {
  name: string,
  url: string,
  fragment?: string,
  subMenu?: interfDocMenu[]
}

@Component({
  selector: 'app-doc-layout',
  templateUrl: './doc-layout.component.html',
  styleUrls: ['./doc-layout.component.scss']
})
export class DocLayoutComponent implements OnInit {

  docMenuList: interfDocMenu[] = [
    { name: 'Home', url: '/doc' },
    {
      name: 'Components', url: '', subMenu: [
        { name: 'Cards', url: '/doc/components/cards' },
        { name: 'Navs', url: '/doc/components/navs' },
        {
          name: 'Tables', url: '', subMenu: [
            {
              name: 'filter-seach', url: '/doc/components/filter-search'
            },
            {
              name: 'Tabelas & filters', url: '/doc/components/table'
            },
          ]
        },
      ]
    },
    {
      name: 'Forms', url: '', subMenu: [
        { name: 'Autocomplete', url: '/doc/components/inputs/autocomplete', fragment: 'inputTitle' },
      ]
    },
    // {
    //   name: 'Testes & correções', url: '', subMenu: [
    //     { name: 'menu da tabela fica escondido', url: '' },
    //   ]
    // },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  // Função para abrir/fechar submenu
  toggleSubmenu(menuId: string): void {
    const submenu = document.getElementById(`submenu-${menuId}`);
    if (submenu) {
      submenu.classList.toggle('collapse');
    }
  }

}
