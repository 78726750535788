import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { AvaliacaoColetaEntity } from '../avaliacao-coleta.entity';
import { AvalicaoColetaService } from '../service/avalicao-coleta.service';

@Component({
  selector: 'app-avaliacao-coleta',
  templateUrl: './avaliacao-coleta.component.html',
  styleUrls: ['./avaliacao-coleta.component.scss']
})
export class AvaliacaoColetaComponent implements OnInit {

  dataAvaliacao: any[] = [];

  entity!: AvaliacaoColetaEntity;
  entityId: any;
  motorista: boolean | undefined;
  estrelas: number = 0;

  constructor(
    public service: AvalicaoColetaService,
    public route: ActivatedRoute,
    public router: Router,
    public http: HttpOldService
  ) {
    route.queryParams.subscribe((params: any) => {
      if (!params.coletaid || !params.motorista) {
        router.navigate([''])
      }
      this.entityId = params.coletaid;
      this.motorista = JSON.parse(params.motorista);
      console.log(this.motorista)
      if (this.motorista === true) {
        this.http.get(`coletas/avaliacao/motorista/${this.entityId}`).subscribe(resp => {
          //@ts-ignore
          this.entity = resp.data
        })
      } else {
        this.http.get(`coletas/avaliacao/cliente/${this.entityId}`).subscribe(resp => {
          //@ts-ignore
          this.entity = resp.data
        })
      }
    }).unsubscribe()
  }

  ngOnInit(): void {

  }

  alterStar(star: number) {
    console.log(star)
    if (star) {
      //@ts-ignore
      this.estrelas = star;
      return;
    }
    //@ts-ignore
    this.estrelas = star;
  }



  avaliar() {

    let data = {
      coletaId: this.entityId,
      motorista: this.motorista,
      estrelas: this.estrelas
    }

    console.log(data)

    this.http.patch('coletas/avaliacao', data).subscribe(resp => {
      if (resp) {
        this.router.navigate([''])
      }
    })
  }

}
