import {
  ClienteCreditoLogisticaReversaProdutoService
} from '../../service/logistica-reversa-produto/cliente-credito-logistica-reversa-produto.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { ProdutoService } from '../../../produto/service/produto.service';
import { LogisticaProdutosEntity } from '../../logistica-produtos.entity';
import { PRODUTOS } from "../../../../../core/config/endpoint-list";
import { ajusteDateByPaste } from "../../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';


@Component({
  selector: 'subComponente-logistica-reversa-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent implements OnInit {

  @Input() id: number | string | undefined

  public form = new FormGroup({
    id: new FormControl(''),
    produto: new FormControl('', [Validators.required]),
    qtdProdutoGerado: new FormControl('', [Validators.required]),
    porcentagemEmbalagens: new FormControl('', [Validators.required]),
    dataCompensacao: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required])
  })

  @ViewChild('modalProdutos') modalProdutos!: OldModalComponent;
  @Output() salve = new EventEmitter<any>()

  public clienteCredito: FormControl = new FormControl('', [Validators.required])

  public listProduto: LogisticaProdutosEntity[] = [];

  editorConfig = DefaultKolkovTextEditor;

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ dataCompensacao: text }))

  constructor(
    private produt: ProdutoService,
    private proService: ClienteCreditoLogisticaReversaProdutoService
  ) {
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['qtdProdutoGerado'])
    )
  }

  ngOnInit(): void { }

  public open(data?: LogisticaProdutosEntity | any) {

    if (!!data) {
      if (!!data.clienteCredito) this.clienteCredito = data.clienteCredito;

      this.proService.get(PRODUTOS).subscribe(
        resp => {
          this.listProduto = resp.data;

          this.form.patchValue({
            produto: data.produto?.id,
            qtdProdutoGerado: InputHelpers.initInputDecimal(data.qtdProdutoGerado || '') || data.qtdProdutoGerado,
            porcentagemEmbalagens: data.porcentagemEmbalagens,
            id: data.id,
            estado: data.estado,
            dataCompensacao: data.dataCompensacao
          });

          this.modalProdutos.open()
        }
      )
    }
  }

  salvarProduto() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    //const form = this.form.value;
    const {
      qtdProdutoGerado,
      produto,
      clienteCredito,
      valorAnualDaCobrancaDaLogistica,
      valorMensalDaCobrancaDaLogistica,
      ...form
    } = this.form.value;

    const indexProduto = this.listProduto.findIndex((produt) => {
      return produt.id == produto;

    })

    if (indexProduto == -1) {
      return;
    }


    // //  this.salve.emit(this.listProduto[indexProduto]);
    // //  this.afterClose()


    const data = <LogisticaProdutosEntity>{
      ...form,
      //id: this.id,
      qtdProdutoGerado: InputHelpers.ajusteMoneyBrToEua(qtdProdutoGerado),
      clienteCredito: { id: this.id },
      valorAnualDaCobrancaDaLogistica: valorAnualDaCobrancaDaLogistica,
      valorMensalDaCobrancaDaLogistica: valorMensalDaCobrancaDaLogistica,

      produto: {
        id: this.listProduto[indexProduto].id,
        nome: this.listProduto[indexProduto].nome,
        valorDoProduto: this.listProduto[indexProduto].valorDoProduto
      }
    }


    this.proService.saveOrUpdate(data).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'LogisticaProdutosEntity' }));
        this.modalProdutos.close();
      }
    )

  }

  afterClose = () => {
    this.form.reset('');
    this.modalProdutos.close();
    return true;
  }
}
