
import {
  AbstractEntity,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn
} from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";
import { MeuPerfilEntity } from "../meu-perfil/meu-perfil.entity";
import { MissaoEntity } from "../missao/missao.entity";
import { ClientesEntity } from "../_clientes/clientes/clientes.entity";
import {autoCompleteClientesOutPut} from "../../../helpers/auto-complete/clientes-autocomplete.helper";
import { formata_data_utc_br } from "src/app/helpers/helpers";
import { missaoAutocompleteOutput } from "src/app/helpers/auto-complete/missao-autocomplete.helper";


const progressBar = (val: number | string, entity: RelatorioMissoesEntity | undefined) => {

  let meta = entity?.meta;
  let progresso = entity?.progresso

  if (!!meta && !!progresso) {
    if (progresso > meta || meta == progresso) {
      val = 100
    } else {
      val = String(progresso * meta / 100)
    }
  }
  return `<div class="progress">
  <div class="progress-bar bg-success" role="progressbar" style="width: ${val}%" aria-valuenow="${val}" aria-valuemin="0" aria-valuemax="${meta}"></div>
</div>`
}

export class RelatorioMissoesEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nomeDaMissao?: string,
    nomeDoCliente?: string,
    dataInicial?: string,
    dataFinal?: string,
    missao?: string,
    cliente?: ClientesEntity,
    progresso?: number,
    finalizado?: string,
    usuarioInclusao?: string,
    dataInclusao?: string,
    meta?: number

  ) {

    super();
    this.id = id;
    this.nomeDaMissao = nomeDaMissao;
    this.nomeDoCliente = nomeDoCliente;
    this.dataInicial = dataInicial;
    this.dataFinal = dataFinal;
    this.missao = missao;
    this.cliente = cliente;
    this.progresso = progresso;
    this.finalizado = finalizado;
    this.usuarioInclusao = usuarioInclusao;
    this.dataInclusao = dataInclusao;
    this.meta = meta
  }
  @DgFilter_autoComplete({ name: 'NOME DA MISSÃO', colum: '4' },
  {
    getUrl: environment.apiUrl + 'missao/nomes',
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'missaoId',
    nameParamFilter: 'nomeMissao',
    outputFormat :missaoAutocompleteOutput
  })

  @DgFilter_select({ data: [] })
  public nomeDaMissao: string | undefined

  @DgFilter_autoComplete({ name: 'NOME DO CLIENTE' },
  {
    getUrl: environment.apiUrl + 'clientes/nomes',
    getServerData: "all",
    propertValue: 'nome',
    nameParamFind: 'clienteId',
    propertKey: 'id',
    outputFormat: autoCompleteClientesOutPut
  })

  @DgFilter_select({ data: [] })
  public nomeDoCliente: string | undefined

  @DgFilter_input('date', { colum: '2', name: 'DATA INICIAL' })
  public dataInicial: any;

  @DgFilter_input('date', { colum: '2', name: 'DATA FINAL' })
  public dataFinal: any;

  @DgTableColumn({ columnName: 'MISSÃO', sort: true, resource: (missao: MissaoEntity) => !!missao.nome ? missao.nome : "--" })
  public missao: string | undefined

  @DgTableColumn({ columnName: 'GERADOR', sort: true, width: '20%', resource: (condominio: ClientesEntity) => !!condominio?.nome ? condominio.nome : "--" })
  public cliente: ClientesEntity | undefined

  @DgTableColumn({ columnName: 'PROGRESSO', resource: (val: number | string, entity: RelatorioMissoesEntity) => progressBar(val, entity) })
  public progresso: number | undefined

  @DgTableColumn({ columnName: 'FINALIZADO?', resource: (finalizado: boolean) => finalizado ? 'Sim' : 'Não', width: '10%' })
  public finalizado: string | undefined

  @DgTableColumn({ columnName: 'ACEITADA PELO USUÁRIO', sort: true, resource: (perfil: MeuPerfilEntity) => !!perfil?.nome ? perfil.nome : "--" })
  public usuarioInclusao: string | undefined

  @DgTableColumn({
    columnName: 'DATA INCLUSÃO', resource: (data: string) => {
      return !!data ?  formata_data_utc_br(data) : "--"
    }, sort: true
  })
  public dataInclusao: string | undefined
  public meta: number | undefined

}
