import { RankingColetasService } from '../service/ranking-coletas.service';
import { Component, OnInit } from '@angular/core';
import { RankingColetasEntity } from '../ranking-coletas.entity';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { filtersInterface } from '../../../dashboard/dashboard-main/dashboard-main.component';

@Component({
  selector: 'app-ranking-coletas-main',
  templateUrl: './ranking-coletas-main.component.html',
  styleUrls: ['./ranking-coletas-main.component.scss'],
})
export class RankingColetasMainComponent implements OnInit {
  public mapEntity = new RankingColetasEntity();

  constructor(
    public service: RankingColetasService,
    private http: HttpOldService,
    private files: FilesManageService
  ) {}

  ngOnInit(): void {}

  public generate_pdf_target(): void {
    window.open('user/ranking-coletas-print', '_blank')?.focus();
  }

  downloadExcel() {
    const params = {
      unpaged: true,
    };

    this.http
      .get<{ data: string }>('coletas/ranking-coleta/planilha', {})
      .subscribe((resp) => {
        this.files.downloadBase64(resp.data, 'ranking-coleta.xlsx');
      });
  }
}
