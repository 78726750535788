import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TokenService} from "../../../../core/services/token-service/token.service";
import {filtersInterface} from "../../../_user/dashboard/dashboard-main/dashboard-main.component";
import {
  SubComponentDashboardColetasComponent
} from "../../../_user/dashboard/subComponents/sub-component-dashboard-coletas/sub-component-dashboard-coletas.component";

@Component({
  selector: 'app-public-dashboard-main',
  templateUrl: './public-dashboard-main.component.html',
  styleUrls: ['./public-dashboard-main.component.scss']
})
export class PublicDashboardMainComponent implements OnInit {

  showPages: boolean = false

  public config: filtersInterface = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params['id']) {
        const token = params['id'];
        const detail = this.ajustDataByEventDispacher(this.abrirToken(token));
        window.dispatchEvent(new CustomEvent('atualizar-pagina-filtrada-coletas', { detail }))
        this.showPages = true
      } else {
        window.history.back();
      }
    })
  }

  public abrirToken(token: string): filtersInterface | any {
    if (!token) {
      token = String(window.localStorage.getItem('accessToken'));
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  ajustDataByEventDispacher(data: any) {
    const { data_fim, data_inicio, tipo_residuo, estados, galpao_id, cliente_id, ativo } = data;

    const result: any = {}

    if (this.route.snapshot.params['id']) result['token'] = this.route.snapshot.params['id'];
    if (!!data_fim) result['dataFim'] = data_fim;
    if (!!data_inicio) result['dataInicio'] = data_inicio;
    if (typeof ativo == 'boolean') result['ativo'] = ativo;
    if ((tipo_residuo || []).length > 0) result['tipoResiduo'] = tipo_residuo.length > 1 ? tipo_residuo.join(',') : tipo_residuo;
    if ((galpao_id || []).length > 0) result['galpao'] = galpao_id.length > 1 ? galpao_id.join(',') : galpao_id;
    if ((cliente_id || []).length > 0) result['unidades'] = cliente_id.length > 1 ? cliente_id.join(',') : cliente_id;

    return result;
  }

}
