import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformacoesGeraisComponent } from './informacoes-gerais.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';



@NgModule({
  declarations: [
    InformacoesGeraisComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    MatTooltipModule,
    AngularEditorModule
  ]

})
export class InformacoesGeraisModule { }
