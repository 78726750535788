import { Component, OnInit } from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";

const tableData = [
  {
    "id": 2,
    "nome": "Condomínio do edifício Serra Atlântida",
    "cnpj": "68.686.427/0001-29",
    "dataInclusao": "2019-07-22T02:59:07.463+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 3,
    "nome": "Condominio Yramaia Leme",
    "cnpj": "00.137.937/0001-03",
    "dataInclusao": "2019-07-22T03:06:47.097+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 4,
    "nome": "Condomínio do Edifício Debret",
    "cnpj": "29.273.240/0001-41",
    "dataInclusao": "2019-07-22T03:10:19.932+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 5,
    "nome": "Condomínio do Edifício Monticelli",
    "cnpj": "35.799.113/0001-83",
    "dataInclusao": "2019-07-22T03:13:55.498+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 6,
    "nome": "Condominio do Edifício Itaigara",
    "cnpj": "29.016.581/0001-31",
    "dataInclusao": "2019-07-22T03:18:52.677+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 7,
    "nome": "EDIFICIO RESIDENCIAL JULIA",
    "cnpj": "09.264.937/0001-49",
    "dataInclusao": "2019-07-22T03:25:02.457+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 8,
    "nome": "Condomínio Jardins de Monet",
    "cnpj": "14.567.357/0001-60",
    "dataInclusao": "2019-07-22T03:29:08.192+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 9,
    "nome": "Condomínio Conde Duvert",
    "cnpj": "21.174.949/0001-88",
    "dataInclusao": "2019-07-22T03:32:44.954+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 10,
    "nome": "Condomínio Edifício Skorpios",
    "cnpj": "03.284.729/0001-25",
    "dataInclusao": "2019-07-22T03:37:26.060+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 11,
    "nome": "Condomínio do Edifício Solar Pedro Américo",
    "cnpj": "40.208.837/0001-45",
    "dataInclusao": "2019-07-22T03:41:48.233+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 12,
    "nome": "Condomínio do Edifício - Reserva Botafogo - Corcovado",
    "cnpj": "20.261.828/0001-00",
    "dataInclusao": "2019-07-22T03:46:14.068+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 13,
    "nome": "Condomínio do Edifício - Reserva Botafogo - Pão de Açúcar",
    "cnpj": "20.269.762/0001-03",
    "dataInclusao": "2019-07-22T03:57:22.475+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 14,
    "nome": "Condomínio do Edifício Aurelius",
    "cnpj": "32.091.514/0001-96",
    "dataInclusao": "2019-07-22T18:35:06.238+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 15,
    "nome": "CONDOMINIO DO EDIFICIO CHATEAU CHENONCEAUX",
    "cnpj": "35.854.686/0001-62",
    "dataInclusao": "2019-07-22T18:46:02.527+00:00",
    "ativo": false,
    "condominioIndicador": {}
  },
  {
    "id": 17,
    "nome": "Residencial Rio Innovation Week",
    "cnpj": "30.295.513/0001-38",
    "dataInclusao": "2019-07-23T11:04:31.362+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 26,
    "nome": "Tupã Tower",
    "dataInclusao": "2019-08-11T22:20:02.930+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 29,
    "nome": "Condomínio do Edifício Locanda Di Helena",
    "cnpj": "09.654.018/0001-81",
    "dataInclusao": "2019-08-21T02:30:43.690+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 30,
    "nome": "Condomínio Vogue Residencia",
    "dataInclusao": "2019-08-21T09:37:36.823+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 31,
    "nome": "Condomínio Porto das palmeiras",
    "dataInclusao": "2019-08-21T09:38:51.380+00:00",
    "ativo": true,
    "condominioIndicador": {}
  },
  {
    "id": 32,
    "nome": "Soberbo",
    "dataInclusao": "2019-08-21T09:40:04.077+00:00",
    "ativo": true,
    "condominioIndicador": {}
  }
]

@Component({
  selector: 'app-doc-table',
  templateUrl: './doc-table.component.html',
  styleUrls: ['./doc-table.component.scss']
})
export class DocTableComponent implements OnInit {

  table: CdkDynamicTable.tableClass;

  constructor(private createTable: CdkDynamicTableService) {
    this.table = createTable.create('array', {
      pagination:{
        size: 1
      },
      columns: [
        { name: 'nome', headerName: 'Nome' },
        { name: 'cnpj', headerName: 'CNPJ' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        // { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        // { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
        // { name: 'exemplo', headerName: 'Exemplo', resource: (cel, row) => row?.cnpj || '--' },
      ],
      actions: {
        exemplo: { name: 'Exemplo', action: () => {} },
        exemplo2: { name: 'Exemplo 2', action: () => {} },
        exemplo3: { name: 'Exemplo 3', action: () => {} },
      },
      sort: true
    });
    this.table.controls.data.set(tableData);
  }

  ngOnInit(): void {
  }

}
