import { CobrancasEntity } from '../cobrancas.entity';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CobrancasService} from "../services/cobrancas-service/cobrancas.service";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ajusteDateByPaste} from "../../../../helpers/helpers";

@Component({
  selector: 'app-cobrancas-main',
  templateUrl: './cobrancas-main.component.html',
  styleUrls: ['./cobrancas-main.component.scss']
})
export class CobrancasMainComponent implements OnInit {

  public mapEntity = new CobrancasEntity()

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ vencimento: text }))

  public inputVencimento = new FormControl('', [Validators.required]);

  public form = new FormGroup({
    id: new FormControl('', [Validators.required]),
    vencimento: new FormControl('', [Validators.required])
  })

  public minimoDataAcrescimo = this.dataAtualFormatada();

  @ViewChild('swallEstenderPesquisa') swallVencimento!: SwalComponent;

  constructor(public service: CobrancasService, public readonly swalTargets: SwalPortalTargets) { }

  ngOnInit(): void {
  }

  @HostListener('window:altera-data-pagamento-cobranca', ['$event'])
  alteraVencimento(ev: CustomEvent) {
    if (!!ev?.detail?.id && !!ev?.detail?.vencimento) {
      this.form.patchValue({
        id: ev.detail.id,
        vencimento: ev.detail.vencimento
      })

      let time = new Date();
      this.minimoDataAcrescimo = this.dataAtualFormatada(time.setDate(time.getDate() + 5)) // Adiciona 5 dias

      this.swallVencimento.fire();
    }
  }


  salvaNovoPrazo() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value

    this.service.updateVencimento(form).subscribe(
      resp => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'CobrancasEntity' }));
        this.swallVencimento.close();
      }
    )
  }

  afterClose() {
    this.form.reset();
    this.minimoDataAcrescimo = this.dataAtualFormatada()
  }

  public dataAtualFormatada(val?: string | number) {

    var data = (!!val ? new Date(val) : new Date()),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  }
}
