import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqMainComponent } from './faq-main/faq-main.component';
import {UiModule} from "../../../shared/ui/ui.module";



@NgModule({
  declarations: [
    FaqMainComponent
  ],
  imports: [
    CommonModule,
    UiModule
  ]
})
export class FaqPerguntasFrequentesModule { }
