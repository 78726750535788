import { Component, OnInit } from '@angular/core';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { RelatorioMissoesEntity } from '../relatorio-missoes.entity';
import { RelatorioMissoesService } from '../service/relatorio-missoes.service';

@Component({
  selector: 'app-relatorio-missoes-main',
  templateUrl: './relatorio-missoes-main.component.html',
  styleUrls: ['./relatorio-missoes-main.component.scss']
})
export class RelatorioMissoesMainComponent implements OnInit {

  public mapEntity = new RelatorioMissoesEntity()

  constructor(
    public service: RelatorioMissoesService,
    public http: HttpOldService,
    public fileService: FilesManageService,
    public session: SessionService
  ) { }

  ngOnInit(): void {
  }

  baixarPlanilha() {
    this.http.get('relatorio-missao/planilha').subscribe((resp: any) => {
      this.fileService.downloadBase64(resp.data, 'relatório_de_adesao_ao_programa_de_logistica_reversa.xls')
    })
  }
}
