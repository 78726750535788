<div class="wrapper">

  <app-sidebar #sidebar>
    <app-sidebar-logo></app-sidebar-logo>

    <app-navigation
      *ngFor="let menuList of navigateMenu"
      [startHr]="!!menuList.hrStart"
      [endHr]="!!menuList.hrEnd"
      [listNavigation]="(menuList.menuList || [])"
    ></app-navigation>

  </app-sidebar>

  <!-- Page Content  -->
  <div id="content" [class.sidebar-open]="sidebar._open">

    <div id='layout-appRoot'>

      <navbar-header (changeSidebar)="sidebar.changeState()"></navbar-header>

      <div class="router-outlet-root">
        <router-outlet>
        </router-outlet>

      </div>
    </div>
  </div>
</div>

<old-dg-modal
  #modalNewsletter
  size="md"
  title="Cadastro no Newsletter"
>
  <div dg-content>
    <div class="row">
      <div class="col-12">
        <!-- <p>Caso o usuário deseje receber newsletter da Ecoplat.</p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Compartilhe esse link com todos os usuários que você deseja se comunicar ligados ao seu gerador.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 f-bold form-control fc-green">
        http://server2.minhacoleta.com/restrict/newsletter/newsletter?clienteid=MTc
      </div>
    </div>
  </div>
  <div dg-footer>
    <button (click)="modalNewsletter.close()" class="btn btn-secondary">Fechar</button>
  </div>
</old-dg-modal>

<old-dg-modal
  #modalLix
  size="md"
  title="Envie sua dúvida para Lix"
>
  <div dg-content>
    <div class="row">
      <div class="col-12">
        <p> Explique o que estava tentando fazer e qual foi a dúvida ou problema encontrado</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textarea [formControl]="messageLix" rows="8" class="w-100"></textarea>
      </div>
    </div>
  </div>

  <div dg-footer>
    <button (click)="enviarLix()" class="btn btn-success m-1">Salvar</button>
    <button (click)="modalLix.close()" class="btn btn-secondary m-1">Fechar</button>
  </div>
</old-dg-modal>

<subComponent-modal-pesquisar></subComponent-modal-pesquisar>

<subComponent-alter-password></subComponent-alter-password>

<system-return-message-column></system-return-message-column>
