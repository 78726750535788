import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from "../../services/token-service/token.service";
import { SessionService } from "../../services/session-service/session.service";
import {ThemeManageService} from "../../../services/theme-manage/theme-manage.service";

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected tokenService: TokenService,
    private session: SessionService,
    public route: ActivatedRoute,
    private themeService: ThemeManageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // abrindo e verificando token
    if (!window.localStorage.getItem('accessToken') || window.localStorage.getItem('accessToken') == 'undefined') {

      if (this.exceptUrl('/user/dashboard')) {
        return true;
      }

      this.session.logout();
    }
    if (!this.session.user?.id || !this.session.user?.perfil) {
      this.session.setUser();
    }

    this.themeService.setAllTheme();

    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      if (this.session.hasRespostas == undefined) {
        if (!window.localStorage.getItem('hasRespostas') || window.localStorage.getItem('hasRespostas') == 'undefined') {
          this.router.navigate(['/auth/start-session'])
        } else {
          this.session.hasRespostas = window.localStorage.getItem('hasRespostas') == 'true'
        }
      }
    }

    // VERIFICANDO MENU
    if (!this.session.menu.valid()) {
      this.router.navigate(['/auth/start-session'])
    }

    return true;
  }

  exceptUrl(exceptUrl: string) {
    const currentUrl = window.location.pathname.split('/');
    currentUrl.pop();

    return currentUrl.join('/') === exceptUrl;
  }
}
