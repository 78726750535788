import {
  AbstractEntity, DataServer, DgActionsTable,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn,
} from '@datagrupo/dg-crud';
import {environment} from 'src/environments/environment';
import {ClientesEntity} from '../_clientes/clientes/clientes.entity';
import {CobrancaActionsTableService} from "./services/actions-table/cobranca-actions-table.service";
import {autoCompleteClientesOutPut} from "../../../helpers/auto-complete/clientes-autocomplete.helper";
import {COBRANCAS} from "../../../core/config/endpoint-list";

const objIdentificador = {
  MULTIPEDIDO: "Multi Pedido",
  PEDIDO_SIMPLES: "Pedido Simples"
}
const objIdentificadorArray = [
  {key: 'MULTIPEDIDO', value: 'Multi Pedido'},
  {key: 'PEDIDO_SIMPLES', value: 'Pedido Simples'}
];

export const objPago = {
  PAID: "Pago",
  CREATED: "Criado",
  WAITING: "Pendente",
  NOT_PAID: "Vencido",
  REVERTED: "Revertido"
}
const objPagoArray = [
  {key: 'PAID', value: 'Pago'},
  {key: 'CREATED', value: 'Criado'},
  {key: 'WAITING', value: 'Pendente'},
  {key: 'NOT_PAID', value: 'Vencido'},
  {key: 'REVERTED', value: 'Revertido'}
];

@DataServer({
  path: environment.apiUrl,
  context: COBRANCAS
})
@DgActionsTable(CobrancaActionsTableService)
export class CobrancasEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    status?: string | undefined,
    identificador?: string | undefined,
    pagamento?: string | undefined,
    vencimento?: string | undefined,
    cliente?: ClientesEntity | undefined,
    valor?: string | number,
    cobranca?: string | undefined,
    dataVencimento?: string | undefined,
  ) {
    super();
    this.id = id;
    this.status = !!status ? status : 'CREATED';
    this.identificador = identificador;
    this.pagamento = !!pagamento ? pagamento : '';
    this.vencimento = vencimento;
    this.cliente = cliente;
    this.valor = valor;
    // this.cobranca = cobranca;
  }

  @DgFilter_select({data: objIdentificadorArray, propertKey: 'key', propertValue: 'value'})
  @DgTableColumn({columnName: 'IDENTIFICADOR', resource: objIdentificador, sort: true})
  public identificador: string | undefined;

  @DgFilter_autoComplete(
    {},
    {
      getUrl: environment.apiUrl + 'clientes',
      getServerData: "all",
      propertValue: 'nome',
      propertKey: 'id',
      outputFormat: autoCompleteClientesOutPut
    }
  )
  @DgTableColumn({columnName: 'CLIENTE', sort: true, resource: (cli: ClientesEntity) => !!cli?.nome ? cli.nome : '--'})
  public cliente: ClientesEntity | undefined;

  @DgFilter_input('date', {colum: '3', name: 'DATA DE VENCIMENTO (INICIO)'})
  public dataInicioVencimento: string | undefined;

  @DgFilter_input('date', {colum: '3', name: 'DATA DE VENCIMENTTO (FIM)'})
  public dataFimVencimento: string | undefined;

  @DgTableColumn({
    columnName: 'VENCIMENTO',
    sort: true,
    resource: (v: string) => !!v ? v.split('-').reverse().join('/') : '--'
  })
  public vencimento: string | undefined;

  @DgTableColumn({
    columnName: 'PAGAMENTO',
    sort: true,
    resource: (v: string) => !!v ? v.split('-').reverse().join('/') : '--'
  })
  public pagamento: string | undefined;

  @DgTableColumn({columnName: ' VALOR', sort: true, resource: (val: string) =>  !!val ? val : '--'})
  public valor: string | number | undefined;

  @DgFilter_select({data: objPagoArray, propertKey: 'key', propertValue: 'value'})
  @DgTableColumn({columnName: 'STATUS', resource: objPago, sort: true})
  public status: string = 'CREATED';


}
