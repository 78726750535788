<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">Cards</h1>
</header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Atenção</h4>
        <p>
          Existe um componente <b>CARD</b> que está sendo utilizado em todo o sistema, em verificações posteriores
          percebi que esse componente é responsável por problemas de quebra de layout, como isso foi mexido a muito
          tempo e está em diversos locais do sistema, preferi criar outro por meio de <b>classes</b> e não mais por
          componentes.
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="alert" role="alert">
        <h4 class="alert-heading">Início rápido</h4>
        <p>
          Esse card foi feito usando bootstrap, então apenas crie um card bootstrap comum e adicione a class
          <b>.card-root</b>
        </p>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">titulo</h4>
          <p class="card-subtitle">sub titulo</p>
        </div>
        <div class="card-body">
          <p style="color: red">exemplo card depreciado</p>
        </div>
      </card>
    </div>
  </div>
</div>

<div class="container mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title">titulo</h4>
          <p class="card-subtitle">sub titulo</p>
        </div>
        <div class="card-body">
          <p>body</p>
        </div>
        <div class="card-footer">
          <p>footer</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-3">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <h4 class="card-title">card header com botões</h4>
              <p class="card-subtitle">sub titulo</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 card-header-buttons">
              <button class="btn btn-secondary btn-sm m-1">ação secundária</button>
              <button class="btn btn-success btn-sm m-1">ação</button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <p>
                Utilise a classe <b>card-header-buttons</b> para direcionar os buttons e use o bootstrap para gerenciar o grid
              </p>
<pre><textarea class="w-100" rows="13">
<div class="card-header">
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <h4 class="card-title">card header com botões</h4>
      <p class="card-subtitle">sub titulo</p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 card-header-buttons">
      <button class="btn btn-secondary btn-sm m-1">ação secundária</button>
      <button class="btn btn-success btn-sm m-1">ação</button>
    </div>
  </div>
</div>
</textarea></pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
