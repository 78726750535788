import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informacoes-gerais',
  templateUrl: './informacoes-gerais.component.html',
  styleUrls: ['./informacoes-gerais.component.scss'],
})
export class InformacoesGeraisComponent implements OnInit {
  data: any = {};
  constructor(private service: HttpOldService) {
    this.service.get('informacoes').subscribe((resp) => {
      //@ts-ignore
      this.data = resp.data;
    });
  }
  ngOnInit(): void {}
}
