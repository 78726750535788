import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { PerguntasEntity } from '../perguntas.entity';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ClientesPerguntasService } from '../service/clientes-perguntas.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ArquivoEntity } from 'src/app/shared/entitys/arquivo.entity';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientesEntity } from '../../clientes.entity';
import {verifySizeFiles} from "../../../../../../helpers/helpers";

@Component({
  selector: 'subComponent-pergunta-condominio',
  templateUrl: './pergunta-condominio.component.html',
  styleUrls: ['./pergunta-condominio.component.scss']
})
export class PerguntaCondominioComponent implements OnInit {
  listDias: number[] = [];

  optAssinaturas: { key: string, value: string }[] = [
    { key: 'MODULO_GERENCIAL', value: 'Módulo gerencial' },
    { key: 'MODULO_GESTAO_COMUNICACAO', value: 'Módulo gestão e comunicação de operadores' },
    { key: 'MODULO_COTACAO', value: 'Módulo de cotação' }
  ]

  @Input('clienteId') clienteId!: number | string | undefined;

  @ViewChild('stepper') stepper!: MatStepper;
  entity!: PerguntasEntity;
  entityId: any;

  public formPerfilInicial = new FormGroup({
    razaoSocial: new FormControl('', Validators.required),
    nomeResponsavel: new FormControl('', Validators.required),
    emailResponsavel: new FormControl('', [Validators.required, Validators.email]),
    dataLimite: new FormControl('', Validators.required)
  })
  public formEstruturaCondominio = new FormGroup({
    localArmazenamento: new FormControl(true, Validators.required),
    lixeiraCentral: new FormControl('', Validators.required),
    areaVisivel: new FormControl('', Validators.required),
    areaCoberta: new FormControl('', Validators.required),
    larguraPorta: new FormControl('', Validators.required),
    metragemLixeira: new FormControl('', Validators.required),
    passosLixeira: new FormControl('', Validators.required),
    qtdPassos: new FormControl('', Validators.required),
    funcionarioPodeLevarLixo: new FormControl(true, Validators.required),
    descricao: new FormControl('', Validators.required),
    lixeiraAndar: new FormControl(true, [Validators.required]),
    comprimentoLixeiraAndar: new FormControl('', Validators.required),
    descarteResiduo: new FormControl('', Validators.required),
  })
  public formFuncionalidades = new FormGroup({
    horarioFuncionamentoPortaria: new FormControl('', Validators.required),
    funcionariosLimpeza: new FormControl('', Validators.required),
    horarioFuncionarioLimpeza: new FormControl('', Validators.required),
    horarioAlmoco: new FormControl('', Validators.required),
    canaisComunicacao: new FormControl('', Validators.required),
    tipoColeta: new FormControl('', Validators.required),
  })
  public formAssinatura = new FormGroup({
    assinatura: new FormControl('', Validators.required)
  })

  constructor(
    public servic: ClientesPerguntasService,
    public conf: ConfigDgCrudService,
    public files: FilesManageService,
    public session: SessionService,
    public route: ActivatedRoute,
    public sanitization: DomSanitizer
  ) {
  }

  initNewEntity(): void {
    this.entity = new PerguntasEntity()
  }

  ngOnInit(): void {
    console.log('clienteId',this.clienteId)
    this.getRouteId();
    if (this.entityId != 'novo') {
      this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
        this.entity = resp.data;
        if (this.entity != undefined) {
          this.afterFetchEntity();
        } else {
          this.initNewEntity()
        }
      })
    }
    this.getDias()
  }

  getRouteId() {
    this.route.paramMap.subscribe(params => {
      if(!params.get('novo')){
        this.entityId = params.get('id')
      }
      this.entityId = this.clienteId
    })
  }

  getDias() {
    for (let cont = 1; cont <= 31; cont++) {
      this.listDias.push(cont)
    }
  }

  afterFetchEntity(): void {
    this.formPerfilInicial.patchValue({
      ...this.entity
    })
    this.formEstruturaCondominio.patchValue({
      ...this.entity
    })
    this.formFuncionalidades.patchValue({
      ...this.entity
    })
    this.formAssinatura.controls['assinatura'].setValue(this.entity.assinatura);
  }

  proximoStepper(stepp: MatStep) {
    const index = stepp._stepper.selectedIndex;
    if (stepp.stepControl.status === 'VALID') {
      this.stepper.next();
    } else {
      this.checkFormStepps(index);
      this.stepper.next();
    }
  }

  checkFormStepps(index: number | null) {
    if (index == 0) {
      this.formPerfilInicial.markAllAsTouched();
      return
    }
    if (index == 1) {
      this.formEstruturaCondominio.markAllAsTouched();

      if (this.formEstruturaCondominio.value.passosLixeira == "MAIS_SESSENTA") {
        this.formEstruturaCondominio.get('qtdPassos')?.setValidators(Validators.required);
        this.formEstruturaCondominio.get('funcionarioPodeLevarLixo')?.setValidators(Validators.required);
        this.formEstruturaCondominio.get('descricao')?.setValidators(Validators.required);

        this.formEstruturaCondominio.get('qtdPassos')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('funcionarioPodeLevarLixo')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('descricao')?.updateValueAndValidity();

      } else {
        this.formEstruturaCondominio.get('qtdPassos')?.clearValidators();
        this.formEstruturaCondominio.get('funcionarioPodeLevarLixo')?.clearValidators();
        this.formEstruturaCondominio.get('descricao')?.clearValidators();

        this.formEstruturaCondominio.get('qtdPassos')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('funcionarioPodeLevarLixo')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('descricao')?.updateValueAndValidity();

      }

      if (!!this.formEstruturaCondominio.value.localArmazenamento) {
        this.formEstruturaCondominio.get('lixeiraCentral')?.clearValidators();
        this.formEstruturaCondominio.get('lixeiraCentral')?.updateValueAndValidity();
      } else {
        this.formEstruturaCondominio.get('lixeiraCentral')?.setValidators(Validators.required);
        this.formEstruturaCondominio.get('lixeiraCentral')?.updateValueAndValidity();
      }

      if (this.formEstruturaCondominio.value.lixeiraAndar == true || this.formEstruturaCondominio.value.lixeiraAndar == 'true') {
        this.formEstruturaCondominio.get('descarteResiduo')?.clearValidators();
        this.formEstruturaCondominio.get('comprimentoLixeiraAndar')?.setValidators(Validators.required);

        this.formEstruturaCondominio.get('descarteResiduo')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('comprimentoLixeiraAndar')?.updateValueAndValidity();
      } else {
        this.formEstruturaCondominio.get('comprimentoLixeiraAndar')?.clearValidators();
        this.formEstruturaCondominio.get('descarteResiduo')?.setValidators(Validators.required);

        this.formEstruturaCondominio.get('descarteResiduo')?.updateValueAndValidity();
        this.formEstruturaCondominio.get('comprimentoLixeiraAndar')?.updateValueAndValidity();
      };
      return
    } else {
      this.formFuncionalidades.markAllAsTouched();
      return;
    }

  }

  salvarPerguntas() {
    const formV = {
      ...this.formPerfilInicial.value,
      ...this.formEstruturaCondominio.value,
      ...this.formFuncionalidades.value,
    }

    if (this.formPerfilInicial.invalid || this.formEstruturaCondominio.invalid || this.formFuncionalidades.invalid) {
      this.formPerfilInicial.markAllAsTouched();
      this.formEstruturaCondominio.markAllAsTouched();
      this.formFuncionalidades.markAllAsTouched();
      return;
    }

    this.entity = {
      ...this.entity,
      ...formV,
      assinatura: this.formAssinatura.value.assinatura,
      cliente: { id: this.entityId }
    }

    this.servic.saveOrUpdate(this.entity).subscribe(resp => {
      if (resp) {
        this.returnSaveEntity()
      }
      if (this.entity.id) {
        Swal.fire({
          title: 'Dados Atualizado',
          text: 'Informações atualizadas com sucesso',
          icon: 'success',
          timer: 3000
        })
      } else {
        Swal.fire({
          title: 'Registro Enviado',
          text: 'Obrigado por finalizar seu cadastro',
          icon: 'success',
          timer: 3000
        })
      }
      this.session.hasRespostas = true
    })
  }

  returnSaveEntity() {
    this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
      this.entity = resp.data
    })
  }

  checkBoolean(value: boolean | string | undefined) {
    if (!value) return false;
    return value == "true" || value == true
  }

  uploadImg(event: Event, id: number | string | undefined, identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    //@ts-ignore
    if (!id || !event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        return;
      }

      const data = new FormData()
      data.append('arquivo', file);
      data.append('clienteId', String(id));

      const identificadorObject = {
        fotoLixeiraCentral: 'LIXEIRA_CENTRAL',
        fotoPortaCentral: 'PORTA_CENTRAL',
        fotoLixeiraAndar: 'LIXEIRA_ANDAR',
        fotoDescarteResiduo: 'DESCARTE_RESIDUO',
      }
      data.append('tipoFotoPerguntaEnum', identificadorObject[identificador]);

      this.servic.uploadImage(data).subscribe(
        // @ts-ignore
        resp => {
          this.entity[identificador] = resp.data;
          const reader = new FileReader();

          //RETORNO DA IMAGEM
          reader.onload = e => {

            if (!this.entity[identificador]) this.entity[identificador] = <ArquivoEntity>{}

            //CONVERSÃO DA URL COM TIPAGEM, PRA STRING SEM TIPAGEM
            const srcBase64 = (String(reader.result) || '').split('base64,');

            if (!!srcBase64[1]) {
              //@ts-ignore
              this.entity[identificador]['file'] = srcBase64[1]
            }

          }
          reader.readAsDataURL(file)
        }
      )

    }
  }

  removerImagem(foto: any, identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    this.servic.delet('pergunta-respostas/foto/', { params: { idCliente: this.entityId, idFoto: foto.id } }).subscribe(
      resp => {
        this.entity[identificador] = resp.data
        const reader = new FileReader();
        reader.onload = e => {

          if (!this.entity[identificador]) this.entity[identificador] = <ArquivoEntity>{}

          //CONVERSÃO DA URL COM TIPAGEM, PRA STRING SEM TIPAGEM
          const srcBase64 = (String(reader.result) || '').split('base64,');

          if (!!srcBase64[1]) {
            //@ts-ignore
            this.entity[identificador]['file'] = srcBase64[1]
          }
        }
      }
    )
  }

  getImg(identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    if (this.entity) {
      if (this.entity[identificador]?.id) {
        return this.files.getImageToSrc(this.entity[identificador])
      }
    }
    return '/assets/img/default.jpg'
  }

}
