import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeneficiosMainComponent } from './beneficios-main/beneficios-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { UiModule } from 'src/app/shared/ui/ui.module';



@NgModule({
  declarations: [
    BeneficiosMainComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
  ]
})
export class BeneficiosModule { }
