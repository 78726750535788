import { MaterialEntity } from './../material/material.entity';

export class GravimetriaMaterialEntity {

  constructor(
    id?: number | string,
    quantidade?:  number,
    tipo?: string,
    nome?: string,
    vendido?: boolean
  ){
    this.tipo = tipo;
    this.id = id;
    this.quantidade = quantidade;
    this.nome = nome;
    this.vendido = vendido;
  }
  public id: number | string | undefined;
  public tipo : string | undefined;
  public quantidade : number | undefined;
  public nome: string  | undefined
  public vendido: boolean  | undefined
}
