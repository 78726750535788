<old-dg-modal
  #modal
  title="Dados recipiente personalizado"
  size="md"
  [afterClose]="afterClose"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Nome <span class="fc-red">*</span></label>
          <input
            class="form-control"
            formControlName="nome"
          >
        </div>
        <div class="col-6">
          <label class="form-label">Capacidade em litros <span class="fc-red">*</span></label>
          <input
            class="form-control"
            formControlName="capacidadeLitros"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <label class="form-label" for="inea">RECIPIENTE MTR INEA</label>
          <input-autocomplete
            #autoCompleteInea
            formControlName="inea"
            classes="form-control"
            key="id"
            id="inea"
            [list]="(containersMtrInea || [])"
            [showSelectedList]="true"
            [preSelectedList]=" [preSelectedInea] || []"
            label="descricao">
          </input-autocomplete>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <label class="form-label" for="sinir">RECIPIENTE MTR SINIR</label>
          <input-autocomplete
            #autoCompleteSinir
            formControlName="sinir"
            classes="form-control"
            key="id"
            id="sinir"
            [list]="(containersMtrSinir || [])"
            [showSelectedList]="true"
            [preSelectedList]="[preSelectedSinir] || []"
            label="descricao">
          </input-autocomplete>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <label class="form-label" for="sigor">RECIPIENTE MTR SIGOR</label>
          <input-autocomplete
            #autoCompleteSigor
            formControlName="sigor"
            classes="form-control"
            key="id"
            id="sigor"
            [list]="(containersMtrSigor || [])"
            [showSelectedList]="true"
            [preSelectedList]="[preSelectedSigor] || []"
            label="descricao">
          </input-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="form-label">Descrição <span class="fc-red">*</span></label>
          <textarea
            class="form-control"
            formControlName="descricao"
            rows="8"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button
      (click)="save()"
      class="btn btn-success m-1"
      dg-desable-by-period
    >
      Adicionar
    </button>
    <button
      (click)="modal.close()"
      class="btn btn-secondary m-1"
    >
      Sair
    </button>
  </div>
</old-dg-modal>
