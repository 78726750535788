<old-dg-modal
  #modalProduto
  size="lg"
  title="Produtos das Notas Fiscais"
  [afterClose]="close"
>


  <div
    dg-content
    class="modal-vendas-logistica-produtos"
  >
    <div class="container-fluid">
      <table class="crud-table">
        <thead>
        <tr>
          <th></th>
          <th>Produto</th>
          <th>Produto Nota Fiscal ID</th>
          <th>Venda Parcial</th>
          <th>Chave de acesso da nota fiscal</th>
          <th>Quantidade Disponível (TON)</th>
          <th>Validade</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="lisTprodutosFisc?.length == 0">
          <td colspan="12">Nenhum registro encontrado</td>
        </tr>
        <tr
          *ngFor="let prodF of lisTprodutosFisc"
          (click)="hiddenInput.click()"
        >
          <td style="padding-left: .8rem; padding-right: .8rem">
            <input
              #hiddenInput
              hidden
              class="form-check-input"
              type="radio"
              [(ngModel)]="produtoSelected"
              value="{{prodF?.produtNotaFiscalId}}"
            >
            <input
              class="form-check-input"
              type="radio"
              [checked]="hiddenInput.checked"
            >
          </td>


          <td>{{prodF?.nomeProduto}}</td>
          <td>{{prodF?.produtNotaFiscalId}}</td>
          <td>{{prodF?.vendaParcial ? "Sim" : "Não"}}</td>
          <td>{{prodF?.chaveNotaFiscal || '--'}}</td>
          <td>{{format(prodF?.quantidadeDisponivel)}}</td>
          <td>{{maskData(prodF?.validadeNota)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div dg-footer>
    <button
      (click)="salvarProdutos()"
      class="btn btn-success m-1"
      dg-desable-by-period
    >
      Adicionar
    </button>
    <button
      (click)="modalProduto.close()"
      class="btn btn-secondary m-1"
    >
      Fechar
    </button>
  </div>
</old-dg-modal>
