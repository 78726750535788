import { Component, OnInit } from '@angular/core';
import { VendasLogisticaReversaService } from '../service/vendas-logistica-reversa.service';
import { VendasLogisticaReversaEntity } from '../vendas-logistica-reversa.entity';

@Component({
  selector: 'app-vendas-logistica-reversa-main',
  templateUrl: './vendas-logistica-reversa-main.component.html',
  styleUrls: ['./vendas-logistica-reversa-main.component.scss']
})
export class VendasLogisticaReversaMainComponent implements OnInit {

  public vendasLogEntity = new VendasLogisticaReversaEntity()

  constructor(
    public vendasService: VendasLogisticaReversaService
  ) {
   }
  ngOnInit(): void {
  }

}
