import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {ReactiveFormsModule} from "@angular/forms";
import { RenovarSenhaComponent } from './renovar-senha/renovar-senha.component';
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    EsqueciSenhaComponent,
    RenovarSenhaComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    MatIconModule
  ]
})
export class EsqueciSenhaModule { }
