import { ColetasCheckinContainerEntity } from "./coletas-checkin-container.entity";
import { AbstractEntity, DataServer, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "../../../../../environments/environment";
import { CHECKPOINTS_CONTAINER } from "../../../../core/config/endpoint-list";
import { UnidadeMedidaEntity } from "../../unidade-medida/unidade-medida.entity";
import { formataData } from "src/app/helpers/helpers";

@DataServer({
  path: environment.apiUrl,
  context: CHECKPOINTS_CONTAINER
})
export class CheckpointEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    dataInclusao?: string,
    cargaContainer?: number | string,
    qtdContainersColetados?: number | string,
    checkinContainer?: ColetasCheckinContainerEntity,
    unidadeMedidaSelecionada?: UnidadeMedidaEntity,
  ) {
    super();
    this.id = id;
    this.dataInclusao = dataInclusao;
    this.cargaContainer = cargaContainer;
    this.qtdContainersColetados = qtdContainersColetados;
    this.checkinContainer = checkinContainer;
  }

  @DgTableColumn({
    columnName: 'Data do medição', resource: (val: string) => {
      return !!val ? formataData(val) : '--'
    }
  })
  public dataInclusao: string | undefined

  @DgTableColumn({ columnName: 'Carga do recipiente' })
  public cargaContainer?: number | string | undefined;

  @DgTableColumn({ columnName: 'Quantidade de recipientes coletados' })
  public qtdContainersColetados?: number | string | undefined;

  @DgTableColumn({ columnName: 'Unidade de Medida', resource: (val: UnidadeMedidaEntity) => val?.nome || '--' })
  unidadeMedidaSelecionada?: UnidadeMedidaEntity | undefined
  public checkinContainer?: any | undefined;
}
