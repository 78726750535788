import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

export interface InterfDirectiveSaveEvent {
  endPoint?: string | boolean | undefined,
  backAfterSave?: boolean | undefined
}

@Directive({
  selector: 'button[crud-salvar-2] , span[crud-salvar-2]'
})
export class ForkCrudSalvarDirective {
  subscription = new Subscription();

  @Input('endpoint') endpoint: string | undefined = undefined;
  @Input('backAfterSave') backAfterSave: boolean | undefined = undefined;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    // const el = this.elRef.nativeElement;
    // this.subscription = fromEvent(el.parentNode, 'click', {capture: true}).subscribe((e: any) => {
    //   if (e.target === el) {
    //     e.stopPropagation()
    //
    //     let paramSave: InterfDirectiveSaveEvent = {
    //       endPoint: false,
    //       backAfterSave: undefined
    //     };
    //
    //     if (this.endpoint) {
    //       paramSave.endPoint = this.endpoint
    //     }
    //
    //     if(typeof this.backAfterSave === "boolean") {
    //       paramSave.backAfterSave = this.backAfterSave;
    //     }
    //
    //     window.dispatchEvent(new CustomEvent('salvar-insert-edit-event', {
    //       detail: paramSave
    //     }));
    //   }
    // });
  }

  @HostListener('click')
  onClick() {
    let paramSave: InterfDirectiveSaveEvent = {
      endPoint: false,
      backAfterSave: undefined
    };

    if (this.endpoint) {
      paramSave.endPoint = this.endpoint
    }

    if(typeof this.backAfterSave === "boolean") {
      paramSave.backAfterSave = this.backAfterSave;
    }

    window.dispatchEvent(new CustomEvent('salvar-insert-edit-event', {
      detail: paramSave
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
