<div id="selecionar-plano">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Informações dos Planos Periódicos Contratados</h4>
          </div>
          <div>
          </div>
          <div class="card-body">
            <table class="crud-table">
              <thead>
              <tr>
                <th>NOME DO PLANO</th>
                <th>VALOR</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of meusPlanos?.planosContratados">
                <td>{{item.nome}}</td>
                <td>{{fotmataDinheiro(item.valor)}}</td>
              </tr>
              <tr *ngIf=" meusPlanos?.planosContratados.length <= 0">
                <td colspan="4">
                  Nenhum registro encontrado
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="planoSelecionadoEntity.configEntityTable.pagination"
              (changePage)="changePage($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Informações dos Planos Pontuais Contratados</h4>
          </div>
          <div>
          </div>
          <div class="card-body">
            <table class="crud-table">
              <thead>
              <tr>
                <th>NOME DO PLANO</th>
                <th>VALOR</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of meusPlanosPontuais">
                <td>{{item.nomePlano}}</td>
                <td>{{fotmataDinheiro(item.valor)}}</td>
              </tr>
              <tr *ngIf=" meusPlanosPontuais?.length <= 0">
                <td colspan="4">
                  Nenhum registro encontrado
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="planoSelecionadoEntity.configEntityTable.pagination"
              (changePage)="changePage($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card class="card">
          <div class="card-header">
            <h4 class="card-title">Selecione a periodicidade</h4>
          </div>
          <div class="card-body">
            <div>
              <ul class="nav nav-tabs" role="tablist">
                <li role="presentation">
                  <button
                    class="btn fs-20"
                    (click)="getTrimestrais()"
                    [class.btn-success]="periodoSelecionado == 'TRIMESTRAL'"
                    [class.btn-outline-success]="periodoSelecionado != 'TRIMESTRAL'"
                  >
                    Trimestral
                  </button>
                </li>
                <li role="presentation">
                  <button
                    class="btn fs-20"
                    (click)="getSemestrais()"
                    [class.btn-success]="periodoSelecionado == 'SEMESTRAL'"
                    [class.btn-outline-success]="periodoSelecionado != 'SEMESTRAL'"
                  >
                    Semestral
                  </button>
                </li>
                <li role="presentation">
                  <button
                    class="btn fs-20"
                    (click)="getAnuais()"
                    [class.btn-success]="periodoSelecionado == 'ANUAL'"
                    [class.btn-outline-success]="periodoSelecionado != 'ANUAL'"
                  >
                    Anual
                  </button>
                </li>

                 <li  role="presentation">
                    <button class="btn fs-20"
                      (click)="getPontual()"
                      [class.btn-success]="periodoSelecionado == 'PONTUAL'"
                      [class.btn-outline-success]="periodoSelecionado != 'PONTUAL'"
                      >
                      Solicitar Coleta Pontual
                    </button>
                  </li>


              </ul>
            </div>

            <div class="row">
              <div class="col-xs-8 col-lg-8 col-md-6 col-sm-6">
                <div class="row">

                  <div class="planos card col-sm-6 col-lg-8 col-xs-12 col-md-6"
                       *ngFor="let item of listaPlano; let i = index">
                    <input hidden type="checkbox" [value]="false" #checkBtnCard>

                    <div [hidden]="checkBtnCard.checked">
                      <div class="card-body contemText">
                        <div class="center">
                          <h1 class="tittle" style="margin-top: 1em;">Plano</h1>
                        </div>
                        <h1 class="center nameP1 fc-green">{{item.nome}}</h1>
                        <h1 style="text-transform: uppercase; margin-top: 1em;" class="fs-11">
                          {{item.periodicidadePlanoEnum}}</h1>
                        <h1 class="center fc-green"><span class="valor">R$</span> {{fotmataDinheiro(item.valor)}}</h1>
                        <h1 class="fc-green fs-12">/mes</h1>
                        <button
                          *ngIf="!disabledSelected(item?.id)"
                          class="btn btn-fill btn-wd btn-success btn-sm mb-3"
                          (click)="selecionarPlano(i, item.valor)"
                          [disabled]="verificaContratado(item.id)"
                        >
                          {{verificaContratado(item.id) ? "Contratado" : "Selecionar"}}
                        </button>
                        <button
                          class="btn btn-fill btn-wd btn-secondary btn-sm mb-3"
                          disabled
                          *ngIf="disabledSelected(item?.id)"
                          >
                          Selecionado
                        </button>
                      </div>

                      <card>
                        <table class="crud-table">
                          <tr *ngFor="let item of listaPlano[i].items; let indexItem = index" [hidden]="indexItem > 2"
                              class="card-itens">
                            <td>{{item}}</td>
                          </tr>
                        </table>
                      </card>

                      <div class="d-flex" style="justify-content: center; margin: 1em;">
                        <button class="btn btn-success d-flex" style="width: 5%; justify-content: center;"
                                (click)="checkBtnCard.click()">
                          <mat-icon>question_mark</mat-icon>
                        </button>
                      </div>
                    </div>

                    <div>
                      <div [hidden]="!checkBtnCard.checked">
                        <div class="card-body">
                          <div class="contemText">
                            <div class="d-flex" style="justify-content: end;">
                              <button class="btn btn-success d-flex"
                                      style="width: 10%; justify-content: center; margin: 1em;"
                                      (click)="checkBtnCard.click()">
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                            <div class="center">
                              <h1 class="tittle fc-green" style="margin-top: 1em;">ESTE PLANO
                                PERMITE
                              </h1>
                            </div>
                            <card>
                              <table class="crud-table">
                                <tr *ngFor="let item of listaPlano[i].items; let indexItem = index"
                                    [hidden]="indexItem <= 2" class="card-itens">
                                  <td>{{item}}</td>
                                </tr>
                              </table>
                            </card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-xs-4 col-lg-4 col-md-6 col-sm-6">
                <div class="metodo-tittle">
                  <h4>Método de Pagamento</h4>
                </div>
                <div class="row pt-3 mb-3 ml-1">
                  <div class="col-6">
                    <input type="radio" name="cartao" id="cartao" value="CARTAO" [(ngModel)]="metodoPagamento">
                    <label style="margin-left: 0.5em;" for="cartao">Cartão</label>
                  </div>
                  <div class="col-6 ">
                    <input type="radio" name="boleto" id="boleto" value="BOLETO" [(ngModel)]="metodoPagamento">
                    <label style="margin-left: 0.5em;" for="boleto">Boleto</label>
                  </div>
                </div>
                <div class="metodo-tittle">
                  <h4>Carrinho</h4>
                </div>
                <div class="content">
                  <table id="table-carrinho" class="crud-table">
                    <thead>
                    <tr>
                      <th class="ajusteNome">Nome</th>
                      <th class="ajusteValor">Valor(R$)</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of listaPlanoSelecionados; let i = index">
                      <td class="ajusteNomeSelecionado">{{item.nome}}</td>
                      <td class="ajusteValorSelecionado">{{fotmataDinheiro(item.valor)}}</td>
                      <td class="ajusteBotao">
                        <mat-icon class="fc-red" style="margin-right: 0.5em;" (click)="removerPlano(i, item.valor)">
                          cancel
                        </mat-icon>
                      </td>
                    </tr>
                    <div *ngIf="listaPlanoSelecionados.length <= 0">
                      <span class="fs-10 ajusteNome">Nenhum plano selecionado</span>
                    </div>
                    </tbody>
                  </table>
                  <div class="content-data" style="border-top: 1px solid rgba(0, 128, 0, 0.26); margin-top: 1em;">
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div *ngIf="listaPlanoSelecionados.length <= 0">
                        <div style="padding-left: 1em; margin-top: 0.5em">
                          <label>Valor total R$0,00</label>
                        </div>
                      </div>
                      <div *ngIf="listaPlanoSelecionados.length > 0">
                        <div style="padding-left: 1em; margin-top: 0.5em">
                          <label>Valor total R${{valorSomado}},00</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div style="margin-top: 0.5em">
                        <button
                          style="width: 100%;"
                          class="btn btn-success fs-10"
                          (click)="verify()"
                          [disabled]="listaPlanoSelecionados.length <= 0"
                        >
                          Contratar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>

<old-dg-modal #modalContratacao size="lg" title="Confirma Compartilhar Dados Cadastrais">
  <div dg-content>
    <span class="fs-12"> Ao contratar planos é necessário disponibilizar alguns dados cadastrais para a plataforma
      parceira,para
      podermos
      prosseguir com o feito. Deseja confirmar? Está compra será processada pela Wirecard.</span>
  </div>
  <div dg-footer>
    <button class="btn btn-secondary m-1" (click)="modalContratacao.close()">Não</button>
    <button class="btn btn-success m-1" (click)="contratar()">Sim</button>
  </div>
</old-dg-modal>
