<div class="container">
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Tratamento</h4>
                </div>




                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                                <label class="form-label">Nome</label>
                                <input
                                    class="form-control"
                                    formControlName="nome"
                                >
                            </div>
                            <div
                                class="col-xl-2 col-lg-2 col-md-6 col-sm-4"
                                style="padding-top: 2em;"
                            >
                                <label
                                    class="form-check-label pe-2"
                                    for="reciclagem"
                                >
                                    Reciclagem
                                </label>
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="reciclagem"
                                    formControlName="reciclagem"
                                >
                            </div>
                            <div
                                class="col-xl-2 col-lg-2 col-md-6 col-sm-4"
                                style="padding-top: 2em;"
                            >
                                <label
                                    class="form-check-label pe-2"
                                    for="aterroZero"
                                >
                                    Lixo Zero
                                </label>
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="aterroZero"
                                    formControlName="aterroZero"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mt-4">
                                <label class="form-label">Descrição</label>
                                <textarea
                                    class="form-control"
                                    formControlName="descricao"
                                ></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </card>
        </div>
    </div>
</div>




<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
