import { AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { Enderecos } from "../../../../shared/entitys/enderecos";
import { GalpaoEntity } from "../../_galpao/galpao/galpao.entity";
import { environment } from "../../../../../environments/environment";
import { ParceiroEntity } from "../../parceiro/parceiro.entity";
import { formataData } from "../../../../helpers/helpers";
import { autoCompleteClientesOutPut } from "../../../../helpers/auto-complete/clientes-autocomplete.helper";
import { autoCompleteClienteParceiroOutPut } from "src/app/helpers/auto-complete/cliente-parceiro-autocomplete.helper";

import {  GrupoMercadoEntity } from "../../tipo-lixo/grupo-merccado.entity"
import { SegmentoMercadoMercadoEntity } from "../../tipo-lixo/segmento-mercado.entity";
import { SegmentosOperacionaisEntity } from "../../tipo-lixo/segmentos-operacionais.entity";


export interface interfPredio {
  "qtdApartamentos": number | string,
  "qtdDeMoradores": number | string,
  "qtdQuartosPorApartamento": string[]
}

export interface interfEstimativas {
  estimativaCliente: {
    metrosCubicosGerados: number,
    totalQuartos: number,
    totalRDODia: number,
    totalRDOMes: number,
    valorEstimadoReciclaveis: number
  },
  estimativaLogistica: {
    residuosPorQuarto: number,
    totalApartamentos: number,
    totalEstimadoReciclaveis: number,
    totalReciclaveisMes: number
  }
}

const statusList = [
  { k: 'true', v: 'Sim' },
  { k: 'false', v: 'Não' }
]

@DataServer({
  path: environment.apiUrl,
  context: 'clientes'
})
export class ClientesEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    cnpj?: string,
    dataInclusao?: string,
    parceiro?: ParceiroEntity,
    parceiros?: ParceiroEntity[],
    telefone?: string,
    interesseCliente?: string,
    condominioIndicador?: ClientesEntity,
    ativo?: boolean,
    enderecos?: Enderecos[],
    galpao?: GalpaoEntity,
    diasColeta?: string[],
    predio?: interfPredio,
    estimativas?: interfEstimativas,
    exclusivo?: boolean,
    tipoCadastro?: string,
    cargo?: string,
    cpfUsuario?: string,
    instituto?: string,
    responsavel?: string,
    senha?: string,
    siteManifesto?: string,
    email?: string,
    contatos?: any,
    isCreated?: boolean
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.cnpj = cnpj;
    this.dataInclusao = dataInclusao;
    this.parceiro = parceiro;
    this.parceiros = Array.isArray(parceiros) ? parceiros : [];
    this.telefone = telefone;
    this.interesseCliente = interesseCliente;
    this.condominioIndicador = condominioIndicador;
    this.ativo = ativo;
    this.enderecos = Array.isArray(enderecos) ? enderecos : []
    this.diasColeta = Array.isArray(diasColeta) ? diasColeta : []
    this.galpao = galpao;
    this.predio = !!predio ? predio : {
      qtdQuartosPorApartamento: [],
      qtdDeMoradores: '',
      qtdApartamentos: ''
    };
    this.estimativas = estimativas;
    this.exclusivo = exclusivo;
    this.tipoCadastro = tipoCadastro;

    this.cargo = cargo;
    this.cpfUsuario = cpfUsuario;
    this.instituto = instituto;
    this.responsavel = responsavel;
    this.senha = senha;
    this.siteManifesto = siteManifesto;
    this.contatos = contatos;
    this.email = email;
    this.isCreated = !!isCreated;
  }

  @DgFilter_autoComplete(
    {},
    {
      getUrl: environment.apiUrl + 'clientes/nomes',
      getServerData: "all",
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFind: 'clienteId',
      nameParamFilter: 'nome',
      outputFormat: autoCompleteClientesOutPut
    }
  )
  @DgTableColumn({ columnName: 'NOME', sort: true, resource: (val: string, row: ClientesEntity) => {
      if (row?.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? val : '--'}</span>`
      }
      return val
    }
     })
  public nome: string | undefined;

  @DgTableColumn({ columnName: 'CNPJ', mask: 'cnpj', sort: true, resource: (val: string, row: ClientesEntity) => {
      if (row?.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? val : '--'}</span>`
      }
      return val
    } })

  public cnpj: string | undefined;

  @DgTableColumn({
    columnName: 'Data Cadastro', sort: true,
    resource: (val: string, row: ClientesEntity) => {
      if(row.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? formataData(val) : '--'}</span>`
      }
      return !!val ? formataData(val) : '--'
    }
  }
  )
  public dataInclusao: string | undefined;

  @DgFilter_autoComplete(
    {},
    {
      getUrl: environment.apiUrl + 'parceiros/nomes',
      getServerData: "all",
      nameParamFilter: 'nome',
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFind: 'parceiroId',
      outputFormat: autoCompleteClienteParceiroOutPut
    }
  )
  @DgTableColumn({
    columnName: 'Parceiro',
    resource: (val: ParceiroEntity, row: ClientesEntity) => {
      if(row.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val?.nome ? val.nome : 'Não Possui'}</span>`
      }
      return !!val?.nome ? val.nome : 'Não Possui'
    },
    sort: true
  })
  public parceiro: ParceiroEntity | undefined;

  // @DgTableColumn({
  //   columnName: 'Indicador',
  //   resource: (val: ClientesEntity) => !!val?.nome ? val.nome : 'Não Possui',
  //   sort: true
  // })
  public parceiros: ParceiroEntity[] = [];

  @DgTableColumn({
    columnName: 'Indicador',
    resource: (val: ParceiroEntity, row: ClientesEntity) => {
      if(row.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val?.nome ? val?.nome : 'Não Possui'}</span>`
      } return !!val.nome ? val.nome : 'Não Possui'
    },
    sort: true
  })
  public condominioIndicador: ClientesEntity | undefined;

  @DgFilter_select({ name: 'Ativo?', data: statusList, propertKey: 'k', propertValue: 'v', nameParamFilter: 'ativos' })
  public ativos: boolean | undefined;

  public ativo: boolean | undefined;

  public telefone: string | undefined;
  public interesseCliente: string | undefined;
  public enderecos: Enderecos[] = [];
  public galpao: GalpaoEntity | undefined;
  public diasColeta: string[] = [];
  public predio: interfPredio = {
    qtdQuartosPorApartamento: [],
    qtdDeMoradores: '',
    qtdApartamentos: ''
  }
  public estimativas: interfEstimativas | undefined;

  public sindicoOuFunc: any;
  public info: any;

  @DgFilter_select({ name: 'Possui administrador?', data: statusList, propertKey: 'k', propertValue: 'v' })
  public possuiSindico: string | undefined

  public exclusivo: boolean | undefined
  public tipoCadastro: string | undefined;
  public cargo: string | undefined;
  public cpfUsuario: string | undefined;
  public instituto: string | undefined;
  public responsavel: string | undefined;
  public senha: string | undefined;
  public siteManifesto: string | undefined;
  public estimativaColetaMensal: number | string | undefined;
  public contatos: any;
  public email: string | undefined;
  public isCreated: boolean | undefined
  public grupoMercado: GrupoMercadoEntity[] | undefined;
  public segmentoMercado:  SegmentoMercadoMercadoEntity | undefined;
  public segmentosOperacionais: SegmentosOperacionaisEntity[] | undefined;
}
