import {AbstractEntity} from "@datagrupo/dg-crud";
import {ClientesEntity} from "../_clientes/clientes/clientes.entity";
import {ArquivoEntity} from "../../../shared/entitys/arquivo.entity";
import { GrupoComunicacaoEntity } from "../grupo-comunicacao/grupo-comunicacao.entity";


export class BlogEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    titulo?: string,
    conteudo?: string,
    dataInclusao?: string,
    img?: string,
    publica?: boolean,
    clientes?: ClientesEntity[],
    grupos?: GrupoComunicacaoEntity[],
    destinatario?: string,
    imagem?: ArquivoEntity
  ) {
    super();
    this.id = id;
    this.titulo = titulo;
    this.conteudo = !!conteudo ? conteudo : '';
    this.dataInclusao = dataInclusao;
    this.img = img;
    this.publica = !!publica;
    this.clientes = Array.isArray(clientes) ? clientes : [];
    this.grupos = Array.isArray(grupos) ? grupos : [];
    this.destinatario = destinatario;
    this.imagem = imagem

    this.configEntityTable.pagination = {
      size: 5,
      sort: 'id,desc'
    }
  }

  public titulo: string | undefined;
  public img: string | undefined;
  public conteudo: string  = '';
  public dataInclusao: string | undefined;
  public publica: boolean;
  public clientes: ClientesEntity[] = [];
  public grupos: GrupoComunicacaoEntity[] = [];
  public destinatario: string | undefined;
  public imagem: ArquivoEntity | undefined;

}
