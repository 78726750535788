import {Directive, ElementRef, Input} from '@angular/core';
import {SessionService} from "../../services/session-service/session.service";

@Directive({
  selector: '*[check-perfil]'
})
export class CheckPerfilDirective {

  @Input('perfil')
  perfil: 'CONDOMINIO' | 'ADMINISTRADOR' | 'MOTORISTA' | 'GALPAO' |
    'ADMINISTRATIVO' | 'PARCEIRO' | 'CASA'| 'EMPRESA'| 'EVENTO' |
    (
      'CONDOMINIO' | 'ADMINISTRADOR' | 'MOTORISTA' | 'GALPAO' |
      'ADMINISTRATIVO' | 'PARCEIRO'| 'CASA'| 'EMPRESA'| 'EVENTO'
    )[]
    = 'CONDOMINIO';

  constructor(
    private elRef: ElementRef,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    const el = this.elRef.nativeElement;

    if (!this.sessionService.checkPerfil(this.perfil)) {
      el.style.display = 'none';
    }

  }
}
