import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'subComponent-card-promo-lix',
  templateUrl: './card-promo-lix.component.html',
  styleUrls: ['./card-promo-lix.component.scss']
})
export class CardPromoLixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    window.dispatchEvent( new CustomEvent('event-open-lix'))
  }

}
