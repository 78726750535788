import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';


declare var bootstrap: any;

@Component({
  selector: 'canvas-side-filter',
  templateUrl: './canvas-side-filter.component.html',
  styleUrls: ['./canvas-side-filter.component.scss']
})
export class CanvasSideFilterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('canvasDashboardColetas') offcanvasElement!: ElementRef;

  @Input() visibleFavorite: boolean = true;

  @Output('clear') clear = new EventEmitter<void>();
  @Output('uploadeFavoritoList') uploadeFavoritoList = new EventEmitter<'coletas' | 'estoque' | 'ranking'>()
  @Output() closed = new EventEmitter<void>();

  private offcanvasInstance: any;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.offcanvasElement) {
      this.offcanvasInstance = new bootstrap.Offcanvas(this.offcanvasElement.nativeElement);
      this.offcanvasElement.nativeElement.addEventListener('hidden.bs.offcanvas', this.onOffcanvasHidden.bind(this));
    }
  }

  ngOnDestroy(): void {
    if (this.offcanvasElement) {
      this.offcanvasElement.nativeElement.removeEventListener('hidden.bs.offcanvas', this.onOffcanvasHidden.bind(this));
    }
  }

  toggleOffcanvas(): void {
    if (this.offcanvasInstance) {
      this.offcanvasInstance.toggle();
    }
  }

  private onOffcanvasHidden(): void {
    this.closed.emit();
  }

  // @ViewChild('canvasDashboardColetas') offcanvasElement: ElementRef | undefined;
  //
  // @Input() visibleFavorite: boolean = true;
  //
  // @Output('clear') clear = new EventEmitter()
  // @Output('favoritos') favoritos = new EventEmitter()
  //
  // constructor() { }
  //
  // ngOnInit(): void {
  // }
  //
  // toggleOffcanvas() {
  //   const offcanvas = new bootstrap.Offcanvas(this.offcanvasElement?.nativeElement);
  //   offcanvas.toggle();
  // }

}
