import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrupoComunicacaoMainComponent } from './grupo-comunicacao-main/grupo-comunicacao-main.component';
import { GrupoComunicacaoInsertComponent } from './grupo-comunicacao-insert/grupo-comunicacao-insert.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [
    GrupoComunicacaoMainComponent,
    GrupoComunicacaoInsertComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        DirectivesModule
    ]
})
export class GrupoComunicacaoModule { }
