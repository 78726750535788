import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Autocomplete3Component } from './autocomplete3/autocomplete3.component';
import {CdkDynamicTableModule, DgAutocomplete3Module, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {CustomAutocompleteModule} from "../../../shared/custom-autocomplete/custom-autocomplete.module";
import {FormsModule} from "@angular/forms";
import { DocCardsComponent } from './doc-cards/doc-cards.component';
import {UiModule} from "../../../shared/ui/ui.module";
import { DocTableComponent } from './doc-table/doc-table.component';
import { DocFilterSearchComponent } from './doc-filter-search/doc-filter-search.component';
import { DocNavsComponent } from './doc-navs/doc-navs.component';



@NgModule({
  declarations: [
    Autocomplete3Component,
    DocCardsComponent,
    DocTableComponent,
    DocFilterSearchComponent,
    DocNavsComponent
  ],
    imports: [
        CommonModule,
        DgAutocomplete3Module,
        CustomAutocompleteModule,
        FormsModule,
        UiModule,
        DgTableModule,
        CdkDynamicTableModule,
        DgPaginatorModule,
    ]
})
export class DocPagesModule { }
