<old-dg-modal #modal size="md" title="Indicadores" [afterClose]="afterClose">
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Nome do Indicador<span class="fc-red">*</span></label>
          <input type="text" class="form-control" formControlName="nomeIndicador" id="nomeIndicador">
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <label class="form-label">Descrição <span class="fc-red">*</span></label>
          <textarea type="text" class="form-control" formControlName="descricaoIndicador"
                    id="descricaoIndicador"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button class="btn btn-success m-1" (click)="salvar()" dg-desable-by-period>Adicionar</button>
    <button (click)="close()" class="btn btn-secondary m-1">Fechar</button>
  </div>
</old-dg-modal>
