import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';

@Component({
  selector: 'card-promo',
  templateUrl: './card-promo.component.html',
  styleUrls: ['./card-promo.component.scss']
})
export class CardPromoComponent implements OnInit, AfterViewInit {

  @ViewChild('cardPromo') cardPromo!: any;

  @Input('bgColor') bgColor: string | undefined;
  @Input('classes') classes: string = '';
  @Input('imgBackground') imgBackground: string | undefined;
  @Input('imag-config') imgConfig: {
    size?: string,
    repeat?: string,
    position?: string
  } | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!!this.imgBackground && this.imgConfig) {
      if (this.bgColor) this.cardPromo?.nativeElement.style.setProperty('background-color', this.bgColor)
      if (this.imgBackground) this.cardPromo?.nativeElement.style.setProperty('background-image', `url(${this.imgBackground})`)
      if (this.imgConfig.size) this.cardPromo?.nativeElement.style.setProperty('background-size', this.imgConfig.size)
      if (this.imgConfig.repeat) this.cardPromo?.nativeElement.style.setProperty('background-repeat', this.imgConfig.repeat)
      if (this.imgConfig.position) this.cardPromo?.nativeElement.style.setProperty('background-position', this.imgConfig.position)








    }

  }

}
