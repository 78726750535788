import { Component, OnInit, Input, SimpleChange, SimpleChanges, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CadastrePlanoEntity } from '../cadastre-plano.entity';
import { fotmataDinheiro } from "../../../../helpers/helpers";
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import Swal from 'sweetalert2';
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'plano-pontual',
  templateUrl: './plano-pontual.component.html',
  styleUrls: ['./plano-pontual.component.scss']
})
export class PlanoPontualComponent implements OnInit {

  @Input() entity: CadastrePlanoEntity = new CadastrePlanoEntity();
  @Output('salvar') salvar = new EventEmitter<boolean>();
  @ViewChild('pickList') pickList!: PickListComponent;

  public listaSelect: { key: any, value: any }[] = [
    { key: 'SINDICO', value: "Gestor" },
    { key: 'CONSELHO', value: "Conselho/Administrativo" },
    { key: 'MORADOR', value: "Usuário" },
    { key: 'PORTEIRO', value: "Operador" },
    { key: 'FINANCEIRO', value: "Financeiro" },
  ];

  listaSelecionados: any[] | undefined = [];

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    ativo: new FormControl(false),
    pago: new FormControl(false),
    pontual: new FormControl({ value: '', disabled: true }, [Validators.required]),
    exclusivo: new FormControl(false)
  })

  public listItems: { value: any }[] = [];


  constructor() {
    this.form.controls['pago'].valueChanges.subscribe((pago) => {
      if (pago) {
        this.form.controls['pontual'].reset('')
        this.form.controls['pontual'].enable()
      } else {
        this.form.controls['pontual'].reset('')
        this.form.controls['pontual'].disable()
      }
    });
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.form, v, ['pontual'])
    )

  }

  ngOnInit(): void {
    let pontual = '';

    const indexPontual = this.entity.periodicidadesPlano?.findIndex(
      periodo => periodo?.periodicidadePlano?.toUpperCase() == 'PONTUAL'
    )

    if (indexPontual != -1) {
      //@ts-ignore
      pontual = this.entity.periodicidadesPlano[indexPontual].valor
    }

    this.listItems = this.entity?.listItems?.map((value: string) => {
      return { value: value }
    });

    this.listaSelect.filter(value => {
      this.entity.acesso.forEach(item => {
        if (item != value.key) return

        this.listaSelecionados?.push({ key: value.key, value: (value.value[0].toUpperCase() + value.value.substring(1).toLowerCase()).replace('_', ' de ') })
      })
    })

    this.form.patchValue({
      ...this.entity,
      pontual: InputHelpers.initInputMoney(pontual)
    })
  }

  @HostListener('window:salvar-plano', ['$event'])
  eventSave(ev: CustomEvent) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!!this.form.value.exclusivo && this.pickList.getList().length <= 0) {
      Swal.fire({
        title: 'Por favor escolha quem poderá contratar os planos',
        icon: 'error',
        timer: 3000,
        confirmButtonColor: 'green'
      })
      return;
    }

    const indexPontual = this.entity.periodicidadesPlano?.findIndex(periodo => 'PONTUAL' == periodo?.periodicidadePlano?.toUpperCase())
    const { pontual, ...form } = this.form.value;

    let pontualDecimalAjustado = InputHelpers.ajusteMoneyBrToEua(pontual)

    // if (String(pontual).length > 2 ) {
    //   pontualDecimalAjustado = Number(String(pontual).slice(0, String(pontual).length - 2) + '.' + String(pontual).slice(String(pontual).length - 2))
    // }

    if (indexPontual != -1) {
      // @ts-ignore
      this.entity.periodicidadesPlano[indexPontual].valor = pontualDecimalAjustado
    } else {
      this.entity.periodicidadesPlano?.push({ periodicidadePlano: 'PONTUAL', valor: pontualDecimalAjustado })
    }

    this.entity['listItems'] = this.listItems.map(item => item.value);
    if (!!this.form.value.exclusivo) {
      this.entity['acesso'] = this.pickList.getList().map(item => item.key);
    } else {
      this.entity['acesso'] = []
      this.atualiazaPickList();
    }

    this.entity.nome = form.nome;
    this.entity.ativo = form.ativo;
    this.entity.pago = form.pago;
    this.entity.gratuidade = 0;
    this.entity.padrao = false;
    this.entity.exclusivo = form.exclusivo
    this.salvar.emit(!!ev.detail['voltar']);
  }

  public addItem() {
    this.listItems.push({ value: '' })
  }

  public removerItem(index?: number) {
    if (index != undefined) {
      this.listItems.splice(index, 1)
    } else {
      this.listItems.splice(-1, 1)
    }
  }

  fotmataDinheiro(valor: number | string) {
    if (!Number(valor)) {
      return '0,00';
    }
    return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  atualiazaPickList() {
    this.listaSelecionados = this.entity.acesso.map(item => {
      return { key: item, value: (item[0].toUpperCase() + item.substring(1).toLowerCase()).replace('_', ' de ') }
    })
  }
}
