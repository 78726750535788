import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastrePlanoMainComponent } from './cadastre-plano-main/cadastre-plano-main.component';
import { CadastrePlanoInsertComponent } from './cadastre-plano-insert/cadastre-plano-insert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { PlanoPeriodicoComponent } from './plano-periodico/plano-periodico.component';
import { PlanoPontualComponent } from './plano-pontual/plano-pontual.component';
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [
    CadastrePlanoMainComponent,
    CadastrePlanoInsertComponent,
    PlanoPeriodicoComponent,
    PlanoPontualComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        RouterModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MatIconModule,
        FormsModule,
        DirectivesModule
    ],
})
export class CadastrePlanoModule { }
