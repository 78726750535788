import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CupomRelatorioMainComponent } from './cupom-relatorio-main/cupom-relatorio-main.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    CupomRelatorioMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule

  ]
})
export class CupomRelatorioModule { }
