import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicRoutingModule} from "./public-routing.module";
import {LoginModule} from "./login/login.module";
import {SolicitacaoCadastroModule} from './solicitacao-cadastro/solicitacao-cadastro.module';
import {AvaliacaoColetaModule} from './avaliacao-coleta/avaliacao-coleta.module';
import {PublicDashboardModule} from "./public-dashboard/public-dashboard.module";
import {
  PublicCreditoLogisticaReversaModule
} from "./public-credito-logistica-reversa/public-credito-logistica-reversa.module";
import {PublicHomeModule} from "./public-home/public-home.module";
import {EsqueciSenhaModule} from "./esqueci-senha/esqueci-senha.module";
import {DirectivesModule} from 'src/app/core/directives/directives.module';
import {CadastroMoradorModule} from "./cadastro-morador/cadastro-morador.module";
import {
  CertificadoCreditoLogisticaReversaComponent
} from './certificado-credito-logistica-reversa/certificado-credito-logistica-reversa.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PublicDashboard2Module} from "./public-dashboard2/public-dashboard2.module";


@NgModule({
  declarations: [
    CertificadoCreditoLogisticaReversaComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    LoginModule,
    SolicitacaoCadastroModule,
    AvaliacaoColetaModule,
    PublicDashboardModule,
    PublicCreditoLogisticaReversaModule,
    PublicHomeModule,
    EsqueciSenhaModule,
    DirectivesModule,
    CadastroMoradorModule,
    MatProgressSpinnerModule,
    PublicDashboard2Module
  ]
})
export class PublicModule {
}
