import {AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_select, DgTableColumn} from "@datagrupo/dg-crud";
import {missaoAutocompleteOutput} from "src/app/helpers/auto-complete/missao-autocomplete.helper";
import {ArquivoEntity} from "src/app/shared/entitys/arquivo.entity";
import {environment} from "src/environments/environment";
import {CupomEntity} from "../cupom/cupom.entity";
import {ParceiroEntity} from "../parceiro/parceiro.entity";
import {
  ClienteCreditoLogisticaReversaEntity
} from "../pedido-credito-logistica-reversa/cliente-credito-logistica-reversa.entity";
import {TipoLixoEntity} from "../tipo-lixo/tipo-lixo.entity";
import {ClientesEntity} from "../_clientes/clientes/clientes.entity";

export class LocalizacoesInterface {
  estado: {
    "name": string,
    "uf": string
  } | undefined;
  cidades: {
    cidade: string
    id: number | undefined
  }[] | undefined
}

const concluida = [
  {k: 'all', v: 'Todos'},
  {k: 'true', v: 'Sim'},
  {k: 'false', v: 'Não'}
]

@DataServer({
  path: environment.apiUrl,
  context: 'missao'
})
export class GestaoProgramaLogisticaReversaEntity extends AbstractEntity {

  constructor(
    id?: number,
    imagem?: ArquivoEntity,
    arquivoImagemId?: number,
    arquivoSeloId?: number,
    nome?: string,
    descricao?: string,
    estaConcluida?: boolean,
    limiteClientesAceitarMissao?: number,
    numeroDeColetas?: number,
    clienteCreditoLogisticaReversa?: ClienteCreditoLogisticaReversaEntity,
    metaTonelada?: number,
    pontosMissao?: number,
    qtdMoradores?: number,
    restringiuMissao?: boolean,
    tipoLixoEnum?: string,
    tipoMissao?: string,
    localizacoes?: LocalizacoesInterface[],
    selo?: ArquivoEntity,
    cliente?: ClientesEntity,
    tipoLixo?: TipoLixoEntity
  ) {
    super();
    this.id = id;
    this.imagem = imagem;
    this.arquivoImagemId = arquivoImagemId;
    this.arquivoSeloId = arquivoSeloId;
    this.nome = nome;
    this.descricao = descricao;
    this.estaConcluida = estaConcluida;
    this.limiteClientesAceitarMissao = limiteClientesAceitarMissao;
    this.numeroDeColetas = numeroDeColetas;
    this.clienteCreditoLogisticaReversa = clienteCreditoLogisticaReversa;
    this.metaTonelada = metaTonelada;
    this.pontosMissao = pontosMissao;
    this.qtdMoradores = qtdMoradores;
    this.restringiuMissao = restringiuMissao;
    this.tipoLixoEnum = tipoLixoEnum;
    this.tipoMissao = tipoMissao;
    this.localizacoes = localizacoes
    this.selo = selo;
    this.cliente = cliente
    this.tipoLixo = tipoLixo
  }

  @DgTableColumn({columnName: 'NOME', sort: true})
  @DgFilter_autoComplete({name: 'NOME'},
    {
      getUrl: environment.apiUrl + 'missao/nomes',
      getServerData: "all",
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFind: 'missaoId',
      nameParamFilter: 'nome',
      outputFormat: missaoAutocompleteOutput,
      params: { tipoMissao: 'CONDOMINIO' }
    })
  public nome: string | undefined;

  @DgFilter_select({
    data: concluida, name: "FINALIZADA", propertKey: 'k', propertValue: 'v', outputFormat: (val1: any, val2: any) => {
      if (val1 == 'all') {
        return;
      }

      return {concluida: val1}
      // console.log(val1, val2);
    }
  })
  @DgTableColumn({
    columnName: 'FINALIZADA', resource: (val: boolean, row: any) => {
      return !!row.finalizada ? 'Sim' : 'Não'
    }
  })
  public estaConcluida: boolean | undefined;


  public tipoMissao: string | undefined;

  public imagem: ArquivoEntity | undefined;
  public arquivoImagemId: number | undefined;
  public arquivoSeloId: number | undefined;
  public descricao: string | undefined;
  public limiteClientesAceitarMissao: number | undefined;
  public numeroDeColetas: number | undefined;
  public clienteCreditoLogisticaReversa: ClienteCreditoLogisticaReversaEntity | undefined;
  public metaTonelada: number | undefined;
  public pontosMissao: number | undefined;
  public qtdMoradores: number | undefined;
  public restringiuMissao: boolean | undefined;
  public tipoLixoEnum: string | undefined;
  public localizacoes: LocalizacoesInterface[] | undefined;
  public selo: ArquivoEntity | undefined;
  public cliente: ClientesEntity | undefined;
  public tipoLixo: TipoLixoEntity | undefined;
}
