<old-dg-modal #modal size="sm" [afterClose]="afterClose">

  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Geradores</label>
          <input-autocomplete
            #autoCompUnidade
            [multiple]="true"
            formControlName="cliente"
            key="id"
            label="nome"
            classes="form-control"
            [list]="listaUnidades"
          ></input-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="form-label">Transportadores</label>
          <input-autocomplete
            #autoCompOperador
            [multiple]="true"
            formControlName="galpao"
            key="id"
            label="nome"
            classes="form-control"
            [list]="listaGalpao"
          ></input-autocomplete>
        </div>
      </div>
    </form>
  </div>

  <div dg-footer>
    <button class="btn btn-success m-1" (click)="replicar()">Replicar</button>
    <button class="btn btn-secondary m-1" (click)="modal.close()">Sair</button>
  </div>
</old-dg-modal>
