import * as Highcharts from 'highcharts';


export function ajusteFunctionHighChartsByInsert(data: string) {
  let result = JSON.stringify(data);

  return JSON.parse(result, function (key, value) {
    if (
      typeof value === "string" &&
      value.startsWith("REMOVE_QUOTE") &&
      value.endsWith("REMOVE_QUOTE")
    ) {
      value = value.replace(/REMOVE_QUOTE/g, '')
      return (0, eval)("(" + value + ")");
    }
    return value;
  });
}

export function setGraph(id: string, data: any) {
  if (window.document.getElementById(id)) {
    setTimeout(() => {
      console.log('tentando adicionar grafico:', id)
      Highcharts.chart(id, ajusteFunctionHighChartsByInsert(data));
    }, 50)
  } else {
    setTimeout(() => setGraph(id, data), 50);
  }
}
