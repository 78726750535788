<div id="grupoComunicacao">
    <div class="container-data">
        <div class="row">
            <div class="col-12">
                <card>
                    <div class="card-header">
                        <h4 class="card-title">Grupo de Comunicação</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <form [formGroup]="form">
                                    <label
                                        for="nome"
                                        class="form-label"
                                    >
                                        Nome do Grupo
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="nome"
                                        formControlName="nome"
                                    >
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <pick-list
                                #pickList
                                [listaDisponivel]="listSelect"
                                [listaSelecionados]="listSelected"
                                [showSearchInput]="true"
                                (changeSelect)="getListClientes($event)"
                                propertItem="nome"
                                titleList="Geradores"
                            >
                            </pick-list>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</div>


<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [time]="5000"
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
