import {ClientesEntity} from '../../_clientes/clientes/clientes.entity';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractInsertEdit, HttpClient} from '@datagrupo/dg-crud';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {DefaultKolkovTextEditor} from 'src/app/core/config/default-kolkov-text-editor';
import {NewslettersEntity} from '../newsletters.entity';
import {NewslettersService} from '../service/newsletters.service';
import {CLIENTE} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-newsletters-insert-edit',
  templateUrl: './newsletters-insert-edit.component.html',
  styleUrls: ['./newsletters-insert-edit.component.scss'],
})
export class NewslettersInsertEditComponent extends AbstractInsertEdit<NewslettersEntity> implements OnInit {

  public form = new FormGroup({
    status: new FormControl('ATIVO', [Validators.required]),
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl('',),
    nomeCliente: new FormControl('', [Validators.required]),
  });

  editorConfig = DefaultKolkovTextEditor;

  public listCondominio: ClientesEntity[] = [];

  constructor(
    public servic: NewslettersService,
    public conf: ConfigDgCrudService,
    public http: HttpClient
  ) {
    super(conf, servic);

    servic.get(CLIENTE, { params: { unpaged: true } }).subscribe(
      resp => {
        this.listCondominio = resp.data;
      }
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new NewslettersEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      nomeCliente: this.entity?.nomeCliente?.nome,
    });
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }
    const {nomeCliente, ...form} = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    };

    return true;
  }
}
