import {AbstractHttpService, HttpClient} from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { CadastroGravimetriaEntity } from '../cadastro-gravimetria.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CadastroGravimetriaService extends AbstractHttpService<CadastroGravimetriaEntity> {

  constructor(
    public Http: HttpClient,
  ) {
    super(Http, environment.apiUrl, 'gravimetria')
  }
}
