import {InputAutoCompleteComponent} from "../input-auto-complete.component";


export class ElementHtmlAutocomplete {
  private self!: InputAutoCompleteComponent;

  /**
   * Atributos de CSS a serem importados nos elementos HTML
   * @private
   */
  private attrCssElements: {
    input: {[key: string]: string},
    pelicula: {[key: string]: string},
    divList: {[key: string]: string}
  } = {
    input: {
      position: 'absolute'
    },
    pelicula: {},
    divList: {}
  };

  constructor(self: InputAutoCompleteComponent) {
    this.self = self;
  }

  public createUniqId() {
    let result = '';
    do {
      result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      // console.log(result)
      // console.log(window.document.getElementById('input-autocomplete-'))
    }
    while (!!window.document.getElementById('content-input-autocomplete-' + result))

    return result;
  }

  /*################## METODOS DE ABERTURA ##################*/
  openSelectList() {
    if (this.self.showList) return;

    const elementInput = this.getElementHtml('input');
    const elementInputCoordinates  = elementInput.getBoundingClientRect();

    this.attrCssElements.input['width'] = elementInputCoordinates.width + 'px';
    this.attrCssElements.input['zIndex'] = String(Number(this.self.zIndex) + 1);

    this.attrCssElements.divList['minWidth'] = elementInputCoordinates.width + 'px';
    this.attrCssElements.divList['margin-left'] = elementInputCoordinates.left + 'px';
    this.attrCssElements.divList['scrollX'] = '0';
    this.attrCssElements.divList['zIndex'] = String(Number(this.self.zIndex) + 2);

    this.setVerticalParams(elementInputCoordinates);

    this.insertAttrInHtmlElement();

    this.self.showList = true;
  }

  closeSelectList() {
    const element = this.getElementHtml('input')

    element.style.position = '';
    element.style.width = '100%';
    this.attrCssElements.input['zIndex'] = '';
    this.self.showList = false
  }

  insertAttrInHtmlElement() {
    const elementInput = this.getElementHtml('input');
    const elementPelicula = this.getElementHtml('divPelicula');
    const elementDivList = this.getElementHtml('divList');

    // set INPUT
    Object.keys(this.attrCssElements.input).map((attr: string) => {
      // @ts-ignore
      elementInput.style[attr] = this.attrCssElements.input[attr];
    })
    //set PELICULA
    Object.keys(this.attrCssElements.pelicula).map((attr: string) => {
      // @ts-ignore
      elementPelicula.style[attr] = this.attrCssElements.pelicula[attr];
    })
    // set DIVLIST
    Object.keys(this.attrCssElements.divList).map((attr: string) => {
      // @ts-ignore
      elementDivList.style[attr] = this.attrCssElements.divList[attr];
    })
  }

  private setVerticalParams(elementInputCoordinates: DOMRect) {
    // if (window.innerHeight < 300) {
    //   this.setParamsVerticalCenter(elementInputCoordinates);
    //   return;
    // }

    if (!!this.self.positionY) {
      if (this.self.positionY == 'top') {
        if (elementInputCoordinates.top < 300) {
          this.setParamsTop(elementInputCoordinates, elementInputCoordinates.top - 10)
        } else {
          this.setParamsTop(elementInputCoordinates)
        }
        return;
      } else {
        if ((window.innerHeight - elementInputCoordinates.bottom) < 300) {
          this.setParamsBottom(elementInputCoordinates, window.innerHeight - elementInputCoordinates.bottom - 10)
        } else {
          this.setParamsBottom(elementInputCoordinates)
        }
        return;
      }
    }

    // verifica se o BOTTOM é menor que o mínimo '300px'
    if ((window.innerHeight - elementInputCoordinates.top + elementInputCoordinates.height) < 300) {
      // verifica se o TOP é menor do que o BOTTOM
      if ((window.innerHeight - elementInputCoordinates.top + (elementInputCoordinates.height / 2)) > elementInputCoordinates.top + (elementInputCoordinates.height / 2)) {
        // verifica se BOTTOM é menor que o mínimo '300px' ajusta HEIGHT
        this.setParamsBottom(elementInputCoordinates, window.innerHeight - elementInputCoordinates.bottom - 10)
        return;
      } else {
        // caso TOP for maior que BOTTOM,
        // verifica se TOP é menor que o mínimo '300px' e ajusta HEIGHT
        if (elementInputCoordinates.top < 300) {
          this.setParamsTop(elementInputCoordinates, elementInputCoordinates.top - 10)
        } else {
          this.setParamsTop(elementInputCoordinates)
        }
        return;
      }
    } else {
      // caso BOTTOM seja maior que o mínimo '300px',
      this.setParamsBottom(elementInputCoordinates)
      return;
    }
  }

  // seta parametros de apresentação TOP
  setParamsTop(elementInputCoordinates: DOMRect, height: string | number = '280') {
    this.attrCssElements.pelicula['display'] = 'flex'
    this.attrCssElements.pelicula['alignItems'] = 'flex-end'

    this.attrCssElements.divList['marginBottom'] = window.innerHeight - elementInputCoordinates.top + 'px';
    this.attrCssElements.divList['maxHeight'] = height + 'px';
  }

  // seta parametros de apresentação BOTTOM
  setParamsBottom(elementInputCoordinates: DOMRect, height: string | number = '280') {
    this.attrCssElements.pelicula['display'] = 'block'
    this.attrCssElements.divList['maxHeight'] = height + 'px';
    this.attrCssElements.divList['marginTop'] = elementInputCoordinates.top + elementInputCoordinates.height + 'px';

    // elementPelicula.style.display = 'block';
    // elementDivList.style.maxHeight = height + 'px';
    // elementDivList.style.marginTop = elementInputCoordinates.top + elementInputCoordinates.height + 'px';
  }

  // seta parametros de apresentação no CENTRO
  // ainda não utilizado
  setParamsVerticalCenter(elementInputCoordinates: DOMRect) {
    this.attrCssElements.pelicula['display'] = 'flex'
    this.attrCssElements.pelicula['alignItems'] = 'center'

    this.attrCssElements.divList['maxHeight'] = '100%';
  }

  /**
   * Busca elemento HTML pelo id
   * @param element
   */
  getElementHtml(element: 'input' | 'divPelicula' | 'divList'): HTMLElement {
    switch (element) {
      case 'input':
        const elementInput = window.document.getElementById('input-autocomplete-' + this.self.uniqId)
        if (!elementInput) throw "Não foi possível encontrar o elemento 'input-autocomplete-" + this.self.uniqId + "'";
        return elementInput;

      case 'divPelicula':
        const elementPelicula = window.document.getElementById('divPelicula-autocomplete-' + this.self.uniqId)
        if (!elementPelicula) throw "Não foi possível encontrar o elemento 'divPelicula-autocomplete-" + this.self.uniqId + "'";
        return elementPelicula;

      case 'divList':
        const elementDivList = window.document.getElementById('divList-autocomplete-' + this.self.uniqId)
        if (!elementDivList) throw "Não foi possível encontrar o elemento 'divList-autocomplete-" + this.self.uniqId + "'";
        return elementDivList;
    }
  }
}
