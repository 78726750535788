import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ConfigDgCrudService } from "src/app/core/config/config-dg-crud/config-dg-crud.service";
import { MISSOES } from "src/app/core/config/endpoint-list";
import { HttpOldService } from "src/app/core/services/http/http-old.service";
import { FilesManageService } from "src/app/services/files-manage/files-manage.service";
import { OldModalComponent } from "src/app/shared/ui/modal/modal/modal.component";
import Swal from "sweetalert2";
import { GestaoJornadaEducacaoAmbientalEntity } from "../../gestao-jornada-educacao-ambiental/gestao-jornada-educacao-ambiental.entity";
import { JornadaEducacaoAmbientalEntity } from "../jornada-educacao-ambiental.entity";
import { JornadaEducacaoAmbientalService } from "../service/jornada-educacao-ambiental.service";


@Component({
  selector: 'app-jornada-educacao-ambiental-main',
  templateUrl: './jornada-educacao-ambiental-main.component.html',
  styleUrls: ['./jornada-educacao-ambiental-main.component.scss']
})

export class JornadaEducacaoAmbientalMainComponent implements OnInit {

  listaParceiros: JornadaEducacaoAmbientalEntity[] = [];
  listaJornadas: JornadaEducacaoAmbientalEntity[] = [];
  listaConquistas: JornadaEducacaoAmbientalEntity[] = [];

  missaoAceita: any;

  imagemSelecionada: any;

  showParceiro: boolean = true;
  showListaConquistas: boolean = false;
  showJornadas: boolean = false;
  statusDasMissoes: 'MISSOES' | 'CONQUISTAS' = 'MISSOES'


  @ViewChild('modalJornadaEducacaoAmbiental') modalJornadaEducacaoAmbiental!: OldModalComponent;

  constructor(
    public conf: ConfigDgCrudService,
    public servic: JornadaEducacaoAmbientalService,
    public router: Router,
    public http: HttpOldService,
    public files: FilesManageService,
    private sanitazer: DomSanitizer
  ) {
    servic.get('missoes/parceiros').subscribe(resp => {
      //@ts-ignore
      this.listaParceiros = resp.data
    })
  }

  ngOnInit(): void {
  }

  showList(data: any) {
    this.servic.get(MISSOES, { params: { parceiroId: data.id } }).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaJornadas = resp.data.filter(item => item.podeResgatarCupom == false && item.tipoMissao == "MORADOR");
        if (this.listaJornadas.length <= 0) {
          Swal.fire({
            title: "Aviso",
            text: `O parceiro ${data.nome} ainda não cadastrou suas jornadas`,
            icon: 'warning',
          })
          return false
        }
        this.showJornadas = true;
        this.showParceiro = false;
        this.showListaConquistas = false;
      }
      return true
    })
  }
  showConquistas() {
    this.servic.get('missoes/conquistas').subscribe(resp => {
      if (resp) {
        this.listaConquistas = resp.data;
        this.showListaConquistas = true;
        this.showParceiro = false;
        this.showJornadas = false;
        this.statusDasMissoes = 'CONQUISTAS'
      }
    })
  }

  showParceiros() {
    this.http.get('missoes/parceiros').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaParceiros = resp.data;
        this.showParceiro = true;
        this.showListaConquistas = false;
        this.showJornadas = false
      }
    })
  }

  ignorSanitaze(index?: number) {
    if (index || index == 0) {
      return this.sanitazer.bypassSecurityTrustHtml(String(this.listaJornadas[index].descricao))
    }
    return;
  }

  jornadasInconcluidas() {
    this.http.get('missoes').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaJornadas = resp.data.filter(item => item.podeResgatarCupom == false && item.tipoMissao == "MORADOR");
        console.log(this.listaJornadas)
        this.showJornadas = true;
        this.showListaConquistas = false;
        this.showParceiro = false;
        this.statusDasMissoes = 'MISSOES'
      }
    })
  }

  abrirModalJornada(index: number) {
    this.modalJornadaEducacaoAmbiental.open()
    this.missaoAceita = this.listaJornadas[index];
  }

  aceitarJornada(data: any) {
    this.http.post(`missoes/aceitar/${data.id}`, data.id).subscribe();
    this.modalJornadaEducacaoAmbiental.close();
    this.jornadasInconcluidas();
  }

  resgatarCupom(index: number) {
    const id = this.listaConquistas[index].id
    console.log(this.listaConquistas[index])
    console.log(id)
    this.router.navigate(['/cupom/' + id])
  }

  cumpraDesafio(index: number) {
    if (index) {
      if (this.listaJornadas[index].link) {
        window.location.href = this.listaJornadas[index].link!;
      } else {
        Swal.fire({
          title: 'Ops!',
          icon: 'warning',
          text: 'Missão sem link de desafio, peça para o gestor cadastrar o link',
          timer: 4000,
          showConfirmButton: false
        })
      }
    }
  }

  concluirJornada(index: number) {
    const id = this.listaJornadas[index].id;
    this.http.post(`/missoes/concluir/${id}`, this.listaJornadas[index]).subscribe()
  }

}
