import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {GenericCrudService} from "../../../../../../core/services/generic-crud-service/generic-crud.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {COLETA_ROTA} from "../../../../../../core/config/endpoint-list";
import {ajusteDateByPaste} from "../../../../../../helpers/helpers";

@Component({
  selector: 'modal-alterar-rota',
  templateUrl: './alterar-rota.component.html',
  styleUrls: ['./alterar-rota.component.scss']
})
export class AlterarRotaComponent implements OnInit {

  @Input('galpaoId') galpaoId: number | string | undefined;
  @Input('rotaId') rotaId: number | string | undefined;
  @Input('clienteId') clienteId: number | string | undefined;
  @ViewChild('modal') modal!: OldModalComponent;
  @Output('alterarRotas') alterarRotas = new EventEmitter<any>();

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ date: text }))

  form = new FormGroup({
    date: new FormControl('', [Validators.required]),
    rota: new FormControl('', [Validators.required])
  });

  listRotas: any[] = [];

  constructor(private service: GenericCrudService) {}

  ngOnInit(): void {
    this.form.controls['date'].valueChanges.subscribe(val => {
      this._buscaTabelaRotas(val)
    })
  }

  open() {
    this.modal.open();
  }

  afterClose = () => {
    this.form.reset();
    this.listRotas = [];
  }

  private _buscaTabelaRotas(val?: string) {
    if (!!val) {
      if (new Date(val) >= new Date('01-01-1900')) {
        this.service.get(
          'rotas/galpao/'+ this.galpaoId,
          {
            params: {
              dataRota: val,
              clienteId: this.clienteId
            }
          }).subscribe(
          resp => {
            this.listRotas = resp.data;
          }
        )
      }
    }
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.emitSave(this.form.value.rota)
  }

  emitSave(id: number | string) {
    const index = this.listRotas.findIndex(rota => rota.id == id)

    if (index == -1) return;

    this.service.patch(COLETA_ROTA, {
      coletaId: this.rotaId,
      rotaId: id
    }).subscribe(
      resp => {
        this.modal.close();
        this.alterarRotas.emit(this.listRotas[index])
      }
    )
  }
}
