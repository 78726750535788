<old-dg-modal
  #modal
  size="md"
  title="Tipo Resíduo composto"
  [afterClose]="afterClose"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Nome <span class="fc-red">*</span></label>
          <select class="form-control" name="nomeFilho" id="nomeFilho"  formControlName="nome">
            <option value="" selected>Selecione</option>
            <option
              *ngFor="let lixo of listaTipoLixo"
              [disabled]="verifyDisabled(lixo.id)"
              value="{{lixo.id}}"
            >
              NOME: {{lixo.nome || '--'}} | CATEGORIA: {{lixo.categoria || '--'}}
            </option>
          </select>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Porcentagem <span class="fc-red">*</span></label>
          <input class="form-control" maxlength="6" formControlName="porcentagemFilho" id="porcentagemFilho" >
        </div>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button class="btn btn-success m-1" (click)="salvar()" dg-desable-by-period>Adicionar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Fechar</button>
  </div>
</old-dg-modal>
