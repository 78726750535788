import {
  AbstractEntity, DataServer,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn,
} from '@datagrupo/dg-crud';
import { autoCompleteClienteParceiroOutPut } from 'src/app/helpers/auto-complete/cliente-parceiro-autocomplete.helper';
import { autoCompleteClientesOutPut } from 'src/app/helpers/auto-complete/clientes-autocomplete.helper';
import { environment } from 'src/environments/environment';
import { GalpaoEntity } from '../../_galpao/galpao/galpao.entity';

const pontos = (val: number) => {
  return "<span style=\"padding: .6rem 1rem; background: rgba(148,159,57,.8); color: white; font-size: 14pt; border-radius: 25px\">" +
    val.toFixed(1) +
    "% </span>"
}

const posicao = (val: number | string) => {
  return '<span style="font-size: 13pt; font-weight: bolder; color: rgba(148,159,57,.8)">' + val + '</span>'
}

@DataServer({
  path: environment.apiUrl,
  context: 'coletas/ranking-coleta/aterro-zero'
})
export class RankingAterroZeroEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    periodo?: string,
    porAno?: boolean,
    posicao?: number | string,
    clienteNome?: string,
    pontuacaoRanking?: number | string,
    estados?: string | undefined,
    operadorLogistico?: GalpaoEntity | undefined
  ) {
    super();
    this.id = id;
    this.periodo = periodo;
    this.porAno = porAno;
    this.posicao = posicao;
    this.clienteNome = clienteNome;
    this.pontuacaoRanking = pontuacaoRanking;
    this.estados = estados;

    this.setPagination({ sortField: 'clienteNome' });
  }



  @DgTableColumn({ columnName: 'POSIÇÃO', resource: (val: string) => !!val ? posicao(val) : '--' })
  public posicao: number | string | undefined;

  @DgFilter_autoComplete(
    {},
    {
      getUrl: environment.apiUrl + 'clientes/nomes',
      getServerData: "all",
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFind: 'clienteId',
      nameParamFilter: 'nome',
    }
  )
  @DgTableColumn({ columnName: 'NOME', resource: (val: string) => !!val ? val : '--' })
  public clienteNome: string | undefined;

  @DgFilter_autoComplete(
    {
      name: 'TRANSPORTADOR'
    },
    {
      getServerData: 'all',
      getUrl: environment.apiUrl + 'galpoes',
      propertValue: 'nome',
      nameParamFind: 'idGalpao',
      propertKey: 'id',
    }
  )
  public operadorLogistico: GalpaoEntity | undefined;

  @DgTableColumn({ columnName: 'TAXA DE LIXO ZERO (%)', resource: (val: number) => !!val ? pontos(val) : pontos(0) })
  public pontuacaoRanking: number | string | undefined;

  @DgFilter_select({ name: "ESTADOS" })
  public estados: string | undefined

  public porAno: boolean | undefined;

  @DgFilter_input('date', { name: 'Período Início', nameParamFind: 'periodoInicio' })
  public periodo: string | undefined;

  @DgFilter_input('date', { name: 'Período Fim', nameParamFind: 'periodoFim' })
  public periodoF: string | undefined;

}
