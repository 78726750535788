import { Component, OnInit } from '@angular/core';
import {TipoLixoEntity} from "../tipo-lixo.entity";

@Component({
  selector: 'app-tipo-lixo-main',
  templateUrl: './tipo-lixo-main.component.html',
  styleUrls: ['./tipo-lixo-main.component.scss']
})
export class TipoLixoMainComponent implements OnInit {

  public mapEntity = new TipoLixoEntity();

  constructor() { }

  ngOnInit(): void {
  }

}
