import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import Swal from 'sweetalert2';
import { SelecionarPlanoEntity } from '../../../selecionar-plano/selecionar-plano.entity';
import { PlanosClientesService } from "../services/planos-clientes.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'planos-pontual-clientes-table',
  templateUrl: './planos-pontual-clientes-table.component.html',
  styleUrls: ['./planos-pontual-clientes-table.component.scss']
})
export class PlanosPontualClientesTableComponent implements OnInit {

  @ViewChild('modalPlanoPontual') modalPlanoPontual!: OldModalComponent;
  @Input('id') id: number | string | undefined;

  public loader: boolean = true;


  public planosEntity = new SelecionarPlanoEntity()

  public listPlanos: any[] = []
  public listaPlanoModal: any[] = [];
  public listaPlanoSelecionados: any[] = [];
  public valorSelecionadoSomado: number = 0;
  public metodoPagamento: string = '';
  public valorSomado: number = 0;
  public periodoTabela = '';

  constructor(
    private service: PlanosClientesService,
    public serviceHistorico: GenericCrudService,
    public session: SessionService
  ) { }

  ngOnInit(): void {
    if (!!this.id) {
    this.getTablePlanos()
      }
    }

    getTablePlanos() {
      if (!!this.id) {
        this.service.get('cliente-plano/pontual/' + this.id).subscribe(
          resp => {
            //@ts-ignore
            this.listPlanos = resp.data;
          }
          )
      }
  }

  openModal() {
    if (!this.id) {
      alert(this.id);
      return;
    }
    this.service.get('planos/periodicidade/' + this.id, { params: { periodicidadePlanoEnum: 'PONTUAL' } }).subscribe(
      resp => {
        //@ts-ignore
        this.listaPlanoModal = resp.data
        this.loader = false;
      })
    this.modalPlanoPontual.open();
  }

  public beforeClose = () => {
    this.listaPlanoModal = [];
    this.listaPlanoSelecionados = [];
    this.valorSelecionadoSomado = 0
    this.metodoPagamento = '';

  }

  verificaSelecionado(id: number | string): boolean {

    const indexMeusPlanos = this.listaPlanoSelecionados.findIndex(
      (itemMeusPlanos: any) => {
        return itemMeusPlanos.id == id
      }

    );
    return indexMeusPlanos != -1
  }

  verificaContratado(id: number | string) {
    const indexMeusPlanos = this.listPlanos.findIndex(
      (itemMeusPlanos: any) => {
        return itemMeusPlanos.id == id
      }

    );
    return indexMeusPlanos != -1
  }

  selecionarPlano(index: number, valor: number) {
    let plano = this.listaPlanoModal[index];
    if (plano) {
      this.listaPlanoSelecionados.push(plano);
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSelecionadoSomado += valor
      }
    }
  }

  removerPlano(id: number, valor: number) {
    let plano = this.listaPlanoModal[id];
    if (plano) {
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSomado -= valor
        this.listaPlanoSelecionados.splice(id, 1);
      }
    }
  }

  contratar() {
    if (!this.metodoPagamento) {
      Swal.fire({
        icon: 'error',
        title: 'Selecione uma forma de pagamento'
      }).then();
      return;
    }

    let listId: number[] = [];
    let planoContratado: {
      metodoPagamentoEnum: string,
      periodicidadePlanoEnum: string,
      planosId: number[]
    };

    this.listaPlanoSelecionados.forEach(plano => {
      listId.push(plano.id)
    })

    planoContratado = {
      metodoPagamentoEnum: this.metodoPagamento,
      periodicidadePlanoEnum: 'PONTUAL',
      planosId: listId
    };

    this.service.save(planoContratado, 'contratar/' + this.id).subscribe(
      resp => {
        this.getTablePlanos();
        Swal.fire({
          icon: 'success',
          title: 'Contratação realizada'
        });
        this.modalPlanoPontual.close()
        this.getTablePlanos()
      }
    );
  }


  formata_data_utc_br = (dataString: string) => {
    return new Date(dataString).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  }

  fotmataDinheiro(valor: number | string) {
    if (!Number(valor)) {
      return '0,00';
    }
    return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
