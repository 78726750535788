import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";

interface InterfaceToken {
  id?: number | string
  perfil?: string | 'CONDOMINIO' | 'ADMINISTRADOR' | 'GALPAO' | 'PARCEIRO',
  exp?: number | string,
  ['user-profile']?: string,
  parceiro_id?: string,
  modulos?: ("FINANCEIRO" | "EDUCACAO_AMBIENTAL" | "COMUNICACAO" | "LOGISTICA_REVERSA")[]
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient, private router: Router) { }

  public abrirToken(token?: string): InterfaceToken {
    if (!token) {
      token = String(window.localStorage.getItem('accessToken'));
    }
    if (!token || token == "null") {
      return <InterfaceToken>{};
    }

    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const { profile, perfil, ...result } = JSON.parse(jsonPayload);

    return {
      ...result,
      perfil: profile || perfil
    }
  }

  public checkExp(tokenExp?: string | any) {

    if (!tokenExp) {
      tokenExp = this.abrirToken();
      if (!tokenExp.exp) {
        return false;
      }
    }

    const finalExp = tokenExp.exp * 1000;

    return finalExp > new Date().getTime();


  }

  public getToken() {
    return window.localStorage.getItem('accessToken');
  }

  refreshToken(token: string) {
    return this.http.post(environment.apiUrl + 'auth/refresh', {
      //accessToken: token,
      refreshToken: String(window.localStorage.getItem('refreshToken'))
    }, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem('accessToken');
    window.localStorage.setItem('accessToken', token);
  }
}
