import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'subComponent-container-section',
  templateUrl: './container-section.component.html',
  styleUrls: ['./container-section.component.scss']
})
export class ContainerSectionComponent implements OnInit {

  @Input() img = '/assets/img/default.jpg'
  @Input() direct: 'left' | 'right' = 'left'

  constructor() { }

  ngOnInit(): void {
  }

}
