import {AbstractEntity, DataServer, DgActionsTable, DgTableColumn} from "@datagrupo/dg-crud";
import {ColetasEntity} from "../coletas/coletas.entity";
import {ArquivoEntity} from "../../../../shared/entitys/arquivo.entity";
import {ColetasArquivosActionTableService} from "./services/action-table/coletas-arquivos-action-table.service";
import {environment} from "../../../../../environments/environment";

@DataServer({
  path: environment.apiUrl,
  context: 'arquivo-coletas'
})
@DgActionsTable(ColetasArquivosActionTableService)
export class ColetaArquivosEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    arquivo?: ArquivoEntity,
    coleta?: ColetasEntity,
    privado?: boolean
  ) {
    super();
    this.id = id;
    this.arquivo = arquivo;
    this.coleta = coleta;
    this.privado = privado;
  }

  @DgTableColumn({columnName: 'Nome', resource: (arq: ArquivoEntity) => !!arq?.fileNameOriginal ? arq.fileNameOriginal : '--'})
  public arquivo: ArquivoEntity | undefined
  @DgTableColumn({ columnName: 'Privado', resource: (privado: boolean) => privado ? 'Sim' : 'Não'})
  public privado: boolean | undefined

  public coleta: ColetasEntity | undefined
}
