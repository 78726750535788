import {Component, OnInit} from "@angular/core";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard2-main.component.html',
  styleUrls: ['./dashboard2-main.component.scss']
})
export class Dashboard2MainComponent implements OnInit {

  loadedView = {
    coletas: false,
    estoque: false,
    rankings: false,
    impacto: false,
  }

  view: 'coletas' | 'estoque' | 'rankings' | 'impacto' = 'coletas';

  constructor(
    public session: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (activatedRoute.snapshot.queryParams['view']) {
      this.selectView(activatedRoute.snapshot.queryParams['view'])
    } else {
      this.selectView('coletas')

    }
  }

  ngOnInit() {
  }

  selectView(view: 'coletas' | 'estoque' | 'rankings' | 'impacto') {
    this.loadedView[view] = true;
    this.view = view

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { view },
        queryParamsHandling: 'merge',
      }
    ).then();
  }
}
