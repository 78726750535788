import { TipoLixoEntity } from '../../tipo-lixo/tipo-lixo.entity';
import { GravimetriaMaterialEntity } from '../gravimetria-material-entity';
import { Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CadastroGravimetriaEntity } from '../cadastro-gravimetria.entity';
import { CadastroGravimetriaService } from '../service/cadastro-gravimetria.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { OldModalComponent } from '../../../../shared/ui/modal/modal/modal.component';
import { TIPO_LIXO } from "../../../../core/config/endpoint-list";
import Swal from 'sweetalert2';
import { ajusteDateByPaste } from "../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-cadastro-gravimetria-insert-edit',
  templateUrl: './cadastro-gravimetria-insert-edit.component.html',
  styleUrls: ['./cadastro-gravimetria-insert-edit.component.scss'],
})
export class CadastroGravimetriaInsertEditComponent
  extends AbstractInsertEdit<CadastroGravimetriaEntity>
  implements OnInit {
  public form = new FormGroup({
    galpao: new FormControl('', [Validators.required]),
    formato: new FormControl('', [Validators.required]),
    dataReferencia: new FormControl(''),
  });
  public formModal = new FormGroup({
    tipoLixo: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    multiplicador: new FormControl('1', [Validators.required]),
    quantidadeVendida: new FormControl({ value: '1', disabled: true }, [Validators.required]),
    nome: new FormControl(''),
    vendido: new FormControl(''),
  });

  public listLixo: TipoLixoEntity[] = [];
  public materiais: GravimetriaMaterialEntity[] = [];

  editorConfig = DefaultKolkovTextEditor;

  @ViewChild('modalMaterial') modalMaterial!: OldModalComponent;

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ dataReferencia: text }))

  public materialEntity = new GravimetriaMaterialEntity();

  public indexModal: number | undefined;

  public urlGetTipoLixo = environment.apiUrl + 'tipo-lixo/nomes'

  constructor(
    public conf: ConfigDgCrudService,
    public servic: CadastroGravimetriaService,
  ) {
    super(conf, servic);

    servic.get(TIPO_LIXO, { params: { unpaged: true } }).subscribe((resp) => {
      //@ts-ignore
      this.listLixo = resp.data;
    });

    this.formModal.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.formModal, v, ['quantidade'])
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.formModal.controls['vendido'].valueChanges.subscribe(value => {
      if (value) {
        this.formModal.controls['quantidadeVendida'].enable();
      } else {
        this.formModal.controls['quantidadeVendida'].disable();
      }
    })
  }

  initNewEntity(): void {
    this.entity = new CadastroGravimetriaEntity();
  }

  override afterFetchEntity() {

    this.form.patchValue({
      // @ts-ignore
      ...this.entity,

    });
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { galpao, ...form } = this.form.value

    this.entity = {
      ...this.entity,
      ...form,

    };

    return true;
  }

  info() {
    Swal.fire({
      icon: 'info',
      title: 'Aqui você deve preencher a forma de armazenagem do seu material. ex: fardos, contêiner...',
      showCancelButton: false,
    })
  }

  /*
   * METODOS DE MATERIAL
   */

  public salvarMaterial() {
    if (this.formModal.invalid) {
      this.formModal.markAllAsTouched();
      return false;
    }

    const { quantidade, tipoLixo, vendido, multiplicador, quantidadeVendida, ...formV } = this.formModal.value;

    const index = this.listLixo.findIndex((val) => {
      return val.id == tipoLixo
    })

    if (index == -1) {
      return;
    }

    if (typeof this.indexModal === 'number') {
      this.entity.materiais[this.indexModal] = <GravimetriaMaterialEntity>{
        ...formV,
        vendido: !!vendido,
        tipoLixo: this.listLixo[index],
        quantidade: Number(InputHelpers.ajusteMoneyBrToEua(quantidade)),
      };
      this.modalMaterial.close();
      return;

    } else {
      for (let i = 1; i <= Number(multiplicador); i++) {
        this.entity.materiais.push(<GravimetriaMaterialEntity>{
          ...formV,
          quantidade: InputHelpers.ajusteMoneyBrToEua(quantidade),
          vendido: !!vendido ? i <= Number(quantidadeVendida) : false,
          tipoLixo: this.listLixo[index]
        });
      }
      this.modalMaterial.close();
    }
    return;
  }

  editarMat(index: number) {
    this.modalMaterial.open();
    // this.modalMaterial.open(this.entity.materiais[index]);
  }

  excluirMat(index: number, val: GravimetriaMaterialEntity) {
    Swal.fire({
      icon: 'warning',
      title: "Excluir registro",
      text: 'Deseja excluir este registro? Esta ação é permanente.',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'red',
      cancelButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        if ((this.entity.materiais.splice(index, 1) || []).length > 0) {
          Swal.fire({
            title: 'Excluído com sucesso',
            text: 'Esse registro foi deletado com sucesso',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          })
        }
      }

    })
  }

  public closeModal = () => {
    this.indexModal = undefined;
    this.formModal.reset({
      tipo: '',
      multiplicador: '1',
      quantidadeVendida: '1'
    });
  };

  public openModal(index: number, data: CadastroGravimetriaEntity) {
    if (!!data) {
      this.indexModal = index;
      this.formModal.patchValue({
        tipoLixo: data.tipoLixo?.id,
        quantidade: InputHelpers.initInputDecimal(data.quantidade || '') || data.quantidade,
        nome: data.nome,
        vendido: data.vendido
      });
    }
    this.modalMaterial.open();
  }

  format = (val: string | number) => {
    if (val) {
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    } else {
      return 0
    }
  }
}
