import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalpaoArquivoMotoristaMainComponent } from './galpao-arquivo-motorista-main/galpao-arquivo-motorista-main.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    GalpaoArquivoMotoristaMainComponent
  ],
  imports: [
    CommonModule,    
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule
  ]
})
export class GalpaoArquivoMotoristaModule { }
