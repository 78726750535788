import {Component, OnInit, ViewChild} from '@angular/core';
import {
  ThemeManageService
} from "../../../../services/theme-manage/theme-manage.service";
import Swal from "sweetalert2";
import {
  CLIENTE_FIND_FILTER,
  GALPAO_FIND_FILTER,
  WHITE_LABELS,
  WHITE_LABELS_LOGO
} from "../../../../core/config/endpoint-list";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClientesEntity} from "../../_clientes/clientes/clientes.entity";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {GalpaoEntity} from "../../_galpao/galpao/galpao.entity";
import {WhiteLabelEntity} from "../white-label.entity";
import {PropertiesWhiteLabel} from "../properties-white-label.entity";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ParceiroEntity} from "../../parceiro/parceiro.entity";
import {ModalReplicarComponent} from "../sub-components/modal-replicar/modal-replicar.component";
import {InputAutocompleteComponent} from "@datagrupo/dg-ng-util";


interface valuesMenu {
  background: string,
  backgroundItemSelected: string,
  colorText: string
}

@Component({
  selector: 'app-white-label-edit',
  templateUrl: './white-label-edit.component.html',
  styleUrls: ['./white-label-edit.component.scss']
})
export class WhiteLabelEditComponent implements OnInit {

  public statusPage: 'notSelected' | 'selected' | 'loader' = "notSelected";

  public entity = new WhiteLabelEntity(undefined, undefined, undefined, new PropertiesWhiteLabel());
  public file: File | undefined;
  public listaUnidades: ClientesEntity[] = [];
  public listaGalpao: GalpaoEntity[] = [];
  public properties: PropertiesWhiteLabel = new PropertiesWhiteLabel();
  // Esse campo serve para desfazer as ultimas altrações
  public defaultproperties: PropertiesWhiteLabel = new PropertiesWhiteLabel();

  @ViewChild('autoCompOperador') autoCompOperador!: InputAutocompleteComponent;
  @ViewChild('autoCompUnidade') autoCompUnidade!: InputAutocompleteComponent;
  @ViewChild('modalReplicar') modalReplicar!: ModalReplicarComponent;

  form = new FormGroup({
    type: new FormControl('PARCEIRO', [Validators.required]),
    cliente: new FormControl({value: '', disabled: true}, [Validators.required]),
    galpao: new FormControl({value: '', disabled: true}, [Validators.required])
  })

  constructor(
    public themeService: ThemeManageService,
    public service: HttpOldService,
    private session: SessionService
  ) {
    service.get(CLIENTE_FIND_FILTER, {params: {unpaged: true}}).subscribe((resp: any) => {
      this.listaUnidades = resp.data;
    });
    service.get(GALPAO_FIND_FILTER, {params: {unpaged: true}}).subscribe((resp: any) => {
      this.listaGalpao = resp.data;
    });

    this.form.controls['type'].valueChanges.subscribe(value => {
      this.form.controls['galpao'][value == 'OPERADOR' ? 'enable' : 'disable']();
      this.form.controls['cliente'][value == 'UNIDADE' ? 'enable' : 'disable']();
      this[value == 'UNIDADE' ? 'autoCompOperador' : 'autoCompUnidade'].clear();

      if (value == 'PARCEIRO') {
        this.find({value: session.user.parceiro_id}, 'parceiro')
        return;
      }

      this.statusPage = 'notSelected';
    });
    this.find({value: session.user.parceiro_id}, 'parceiro')
  }

  ngOnInit(): void {
  }

  uploadImg(event: any) {
    if (!event.target?.files || !this.entity?.id) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      // @ts-ignore
      if ((file.size || 0) > 20971520) {
        Swal.fire({
          icon: 'error',
          title: 'Tamanho da imagem maior que o permitido',
          text: 'O tamanho máximo permitido nos arquivos do sistema é de 20mb'
        })
        this.file = undefined
        return;
      }

      const data = new FormData()
      data.append('arquivo', file)
      data.append('whiteLabelId ', String(this.entity.id))

      this.service.post<any>(WHITE_LABELS_LOGO, data).subscribe(
        resp => {
          this.entity.arquivo = resp.data
          debugger
          if (this.form.value.type == 'PARCEIRO') {
            this.themeService.saveLogo(resp.data)
          }

          Swal.fire({
            icon: 'success',
            title: 'Logo atualizada',
            timer: 3000
          })

        }
      )
    }

  }

  removeImage() {
    if (!this.entity?.id) return;

    Swal.fire({
      icon: 'warning',
      title: 'Term certeza que deseja remover essa imagem?'
    }).then(confirm => {
      if (!this.entity?.id || !confirm.isConfirmed) return;
      this.service.delete('white-labels', this.entity.id + '/logo').subscribe(resp => {
        this.entity.arquivo = undefined;
      })
    })
  }

  salvar() {
    const {cliente, galpao, ...form} = this.form.value;

    if (!this.entity) return;

    if (this.form.value.type != 'PARCEIRO' && (!cliente && !galpao)) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione um' + (form.type == 'OPERADOR' ? ' operador logísgico' : ' o gerador'),
        text: 'Ou altere o tipo para selecionar um' + (form.type != 'OPERADOR' ? ' operador logísgico' : ' o gerador')
      }).then()
    }

    const {properties, ...rest} = this.entity;
    const result = {
      ...rest,
      properties: JSON.stringify(this.properties)
    }

    this.service[!!this.entity?.id ? 'put' : 'post']<any>(WHITE_LABELS, result)
      .subscribe(
        resp => {
          if (resp?.data) {
            if (resp.data?.id) this.entity.id = resp.data.id;
            if (this.form.value.type == 'PARCEIRO') {
              this.themeService.saveProperties(this.properties)
              this.themeService.setAllTheme()
            }
          }

          this.entity.properties = {...({...this.properties})};

          Swal.fire({
            icon: 'success',
            title: 'Alterações salvas com sucesso'
          })
        }
      )
  }

  find(value: any, type: 'cliente' | 'galpao' | 'parceiro') {
    if (type != 'parceiro' && (value?.selectedList || []).length <= 0) return;
    if (!value?.value) return;

    this.entity = new WhiteLabelEntity();
    this.properties = new PropertiesWhiteLabel()
    this.statusPage = "loader";

    this.service.get<any>(WHITE_LABELS, {params: {[type + 'Id']: value.value}}).subscribe(
      resp => {
        if (!!resp?.data) {
          const entity = resp.data;
          entity.properties = JSON.parse(resp.data.properties)

          if (entity.properties.sideBarColors) {
            this.properties.sideBarColors = entity.properties.sideBarColors;
          }

          if (entity.properties.colorButtons) {
            this.properties.colorButtons = entity.properties.colorButtons;
          }

          if (entity.properties.paginationColors) {
            this.properties.paginationColors = entity.properties.paginationColors;
          }

          if (entity.properties.thypograghy) {
            this.properties.thypograghy = entity.properties.thypograghy;
          }
          this.entity = <WhiteLabelEntity>entity;
        } else {
          this.entity = new WhiteLabelEntity();
          this.entity.properties = new PropertiesWhiteLabel();
          this.defaultproperties = new PropertiesWhiteLabel();
        }

        if (type == 'cliente') {
          // @ts-ignore
          this.entity['cliente'] = resp.data?.cliente || value.selectedList[0]
        } else if (type == 'galpao') {
          // @ts-ignore
          this.entity['galpao'] = resp.data?.galpao || value.selectedList[0]
        } else {
          if (!this.entity.parceiro) {
            this.entity.parceiro = <ParceiroEntity>{
              id: this.session.user.parceiro_id,
              nome: ''
            }
          }
        }

        this.statusPage = 'selected';
        this.themeService.setAllPreviewTheme(this.properties)
      })
  }

  remove() {
    if (!this.entity?.id) {
      return;
    }

    this.service.delete(WHITE_LABELS, this.entity.id).subscribe(resp => {
      console.log(resp)
    })
  }

  verifyDiffNotSaved(props: 'colorButtons' | 'sideBarColors' | 'paginationColors' | 'thypograghy'):boolean {
    return !(JSON.stringify(this.properties[props]) == JSON.stringify(this.entity.properties[props]));
  }

  desfazerAlteracoes(props: 'colorButtons' | 'sideBarColors' | 'paginationColors' | 'thypograghy') {
    if (!(props in this.properties)) return;
    if (!(props in this.entity.properties)) return;

    if (props == 'colorButtons') {
      this.properties[props] = [...([...this.entity.properties[props]])];
      this.themeService.setAllPreviewTheme(this.properties)
      return;
    }

    // @ts-ignore
    this.properties[props] = {...({...this.entity.properties[props]})};
    this.themeService.setAllPreviewTheme(this.properties)
  }

  reseteTheme(props: 'colorButtons' | 'sideBarColors' | 'paginationColors' | 'thypograghy') {
    const defaultProps = new PropertiesWhiteLabel();

    if (props == 'colorButtons') this.properties.colorButtons = defaultProps.colorButtons;
    if (props == 'sideBarColors') this.properties.sideBarColors = defaultProps.sideBarColors;
    if (props == 'paginationColors') this.properties.paginationColors = defaultProps.paginationColors;
    if (props == 'thypograghy') this.properties.thypograghy = defaultProps.thypograghy;
    this.themeService.setAllPreviewTheme(this.properties)
  }

  replicar() {
    if (
      this.verifyDiffNotSaved('colorButtons') ||
      this.verifyDiffNotSaved('sideBarColors') ||
      this.verifyDiffNotSaved('paginationColors') ||
      this.verifyDiffNotSaved('thypograghy')
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'Existem alterações não salvas',
        text: 'As alterações não salvar não serão replicadas',
        confirmButtonText: 'Continuar',
        showCancelButton: true,
        cancelButtonText: 'sair'
      }).then(confirm => {
        if (confirm.isConfirmed) {
          this.modalReplicar.open();
        }
      });
    } else {
      this.modalReplicar.open();
    }
  }

  voltar() {
    window.history.back()
  }
}
