import { Component, OnInit, ViewChild } from '@angular/core';
import { NotaFiscalEntity } from "../nota-fiscal.entity";
import { NotaFiscalService } from "../services/nota-fiscal.service";
import { ProdutoService } from "../../../produto/service/produto.service";
import { OldModalComponent } from "../../../../../shared/ui/modal/modal/modal.component";
import { ProdutoEntity } from "../../../produto/produto.entity";
import { HttpClient } from '@datagrupo/dg-crud';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';

@Component({
  selector: 'app-nota-fiscal-main',
  templateUrl: './nota-fiscal-main.component.html',
  styleUrls: ['./nota-fiscal-main.component.scss']
})
export class NotaFiscalMainComponent implements OnInit {

  @ViewChild('configurar') modal!: OldModalComponent;
  public listProdConfig: ProdutoEntity[] = [];
  public _listProdConfig: ProdutoEntity[] = [];
  public mapProdEntity = new ProdutoEntity

  public produto: {index: number | undefined, salvo: boolean}[] = [] ;

  public mapEntity = new NotaFiscalEntity()
  constructor(
    public service: NotaFiscalService,
    private prodService: ProdutoService,
    private http: HttpOldService
  ) {
    this.getNotasList()
  }

  ngOnInit(): void {
  }

  opemConfigurar() {
    this.prodService.findAll().subscribe(
      resp => {
        //@ts-ignore
        this.listProdConfig = resp.data
        //@ts-ignore
        this._listProdConfig = resp.data
        this.modal.open()
      }
    )
  }

  editConfigurar(quantidade: Event, id: string | number | undefined, index: number) {
    if (!id) return;

    this.service.alterEstoqueMinimo({ quantidade: Number(quantidade), produtoId: id }).subscribe(
      resp => {
          this.produto[index] = {
            index: index,
            salvo: true
          }
          setTimeout(() => {
            this.produto[index] = {
              index: index,
              salvo: false
            }
          }, 3000)
      },
      (error) => {
        this.listProdConfig[index].quantidadeProdutoAviso = this._listProdConfig[index].quantidadeProdutoAviso;
      }
    )
  }

  getNotasList() {
    // @ts-ignore
    this.prodService.findAll({ pagination: this.mapProdEntity.configEntityTable.pagination }).subscribe(
      resp => {
        //@ts-ignore
        this.listProdutos = resp.data;

        this.mapProdEntity.configEntityTable.pagination = {
          ...this.mapProdEntity.configEntityTable.pagination,
          //@ts-ignore
          ...resp.page,
          //@ts-ignore
          page: resp?.page?.number
        }

        //@ts-ignore
        this.mapProdEntity.configEntityTable.pagination = { ...this.mapProdEntity.configEntityTable.pagination }

      }
    )
  }

  changePage(dataPagination: any) {
    console.log(dataPagination);
    this.mapProdEntity.configEntityTable.pagination = {
      ...this.mapProdEntity.configEntityTable.pagination,
      ...dataPagination
    }

    this.getNotasList();
  }

  baixarPlanilha() {
    this.service.downloadPlanilha();
  }
}
