import { AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgTableColumn, DgFilter_select } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { GalpaoEntity } from '../_galpao/galpao/galpao.entity';
import { GalpaoMotoristaEntity } from '../_galpao/galpao-motorista/galpao-motorista.entity';
import { ClientesEntity } from "../_clientes/clientes/clientes.entity";
import { GalpaoMotoristaVeiculoEntity } from '../_galpao/galpao-motorista-veiculo/galpao-motorista-veiculo.entity';
import { formata_data_utc_br } from "../../../helpers/helpers";
import { autoCompleteRotasOutPut } from 'src/app/helpers/auto-complete/rotas-autocomplete.helper';


export class RotasEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nomeMotorista?: GalpaoMotoristaEntity,
    motorista?: GalpaoMotoristaEntity,
    veiculoPlaca?: GalpaoMotoristaVeiculoEntity,
    dataBuscaInicio?: string,
    galpaoNome?: GalpaoEntity,
    clientes?: ClientesEntity[],
    dataRota?: string,
    observacao?: string,
    idColetasPendentes?: (number | string)[]
  ) {
    super();
    this.id = id;
    this.nomeMotorista = nomeMotorista;
    this.motorista = nomeMotorista;
    this.veiculoPlaca = veiculoPlaca;
    this.dataBuscaInicio = dataBuscaInicio;
    this.galpaoNome = galpaoNome;
    this.clientes = Array.isArray(clientes) ? clientes : [];
    this.dataRota = dataRota;
    this.observacao = observacao;
    this.idColetasPendentes = Array.isArray(idColetasPendentes) ? idColetasPendentes : [];
  }
  @DgFilter_autoComplete(
    {
      name: 'TRANSPORTADOR'
    },
    {
      getServerData: "all",
      getUrl: environment.apiUrl + 'galpoes/nomes',
      propertValue: 'nome',
      propertKey: 'id',
      nameParamFind: 'galpaoId',
      outputFormat: autoCompleteRotasOutPut
    })
  public galpao: GalpaoEntity | undefined;

  @DgFilter_autoComplete(
    {
      name: 'NOME DO MOTORISTA/CATADOR'
    },
    {
      getServerData: "all",
      getUrl: environment.apiUrl + 'motoristas',
      propertValue: 'nome',
      //propertKey: 'id',
      nameParamFind: 'motoristaNome'
    })
  @DgTableColumn({
    columnName: 'NOME DO MOTORISTA/CATADOR', resource: (mot: GalpaoMotoristaEntity) => {
      // console.log(mot)
      return !!mot?.id ? mot.nome : '--'
    }
  })
  public nomeMotorista: GalpaoMotoristaEntity | undefined

  @DgFilter_input('date', { colum: '3', name: 'DATA ROTA INICIAL' })
  public dataBuscaInicio: any;

  @DgFilter_input('date', { colum: '3', name: 'DATA ROTA FINAL' })
  public dataBuscaFim: any;

  @DgTableColumn({ columnName: 'DATA DA ROTA', sort: true, resource: formata_data_utc_br })
  public dataRota: string | undefined

  @DgTableColumn({
    columnName: 'PLACA VEÍCULO', resource: (vei: any) => {
      return !!vei?.numeroPlaca ? vei.numeroPlaca : '--'
    }
  })
  public veiculoPlaca: GalpaoMotoristaVeiculoEntity | undefined

  @DgTableColumn({
    columnName: 'TRANSPORTADOR', resource: (galp: GalpaoEntity) => {
      //console.log(galp);
      return !!galp?.nome ? galp.nome : '--'
    }


  })
  public galpaoNome: GalpaoEntity | undefined;

  @DgTableColumn({ columnName: 'INCLUSÃO', sort: false, resource: (val:string) => !!val ? formata_data_utc_br(val): '--' })
  public dataInclusao: string | undefined


  public clientes: ClientesEntity[] = [];
  public observacao: string | undefined;
  public idColetasPendentes?: (number | string)[] = [];
  public motorista: GalpaoMotoristaEntity | undefined


}
