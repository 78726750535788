import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ColetaArquivosEntity} from "../coleta-arquivos.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ColetaArquivosService extends AbstractHttpService<ColetaArquivosEntity>{

  constructor(
    public config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, 'arquivo-coletas');
  }
}
