import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicadoresOdsMainComponent } from './indicadores-ods-main/indicadores-ods-main.component';
import { IndicadoresOdsInsertEditComponent } from './indicadores-ods-insert-edit/indicadores-ods-insert-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { UiModule } from 'src/app/shared/ui/ui.module';

@NgModule({
  declarations: [
    IndicadoresOdsMainComponent,
    IndicadoresOdsInsertEditComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    RouterModule,
    DirectivesModule
  ]
})
export class IndicadoresOdsModule { }
