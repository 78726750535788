import { Component, OnInit } from '@angular/core';
import { CadastroGravimetriaEntity } from '../cadastro-gravimetria.entity';
import { CadastroGravimetriaService } from '../service/cadastro-gravimetria.service';

@Component({
  selector: 'app-cadastro-gravimetria-main',
  templateUrl: './cadastro-gravimetria-main.component.html',
  styleUrls: ['./cadastro-gravimetria-main.component.scss']
})
export class CadastroGravimetriaMainComponent implements OnInit {

  public mapEntity = new CadastroGravimetriaEntity()

  constructor(
    public service: CadastroGravimetriaService
  ) {}

  ngOnInit(): void {
  }

}
