import { MeuPerfilEntity } from '../meu-perfil.entity';
import { Injectable } from '@angular/core';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';

@Injectable({
  providedIn: 'root',
})
export class MeuPerfilService extends AbstractHttpService<MeuPerfilEntity> {
  public test = {
    data: <MeuPerfilEntity[]>[],
  };
  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'users');
  }

  override save(data: any, endpoint?: string | boolean): Observable<MeuPerfilEntity> {
    alert();
    // @ts-ignore
    return super.update(data, endpoint);
  }
}
