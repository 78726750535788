import { AdministracaoMainComponent } from './administracao/administracao-main/administracao-main.component';
import { HistoricoColetasInsertEditComponent } from './_coletas/historico-coletas/historico-coletas-insert-edit/historico-coletas-insert-edit.component';
import { HistoricoColetasMainComponent } from './_coletas/historico-coletas/historico-coletas-main/historico-coletas-main.component';
import { InformacoesGeraisComponent } from './informacoes-gerais/informacoes-gerais.component';
import { CupomRelatorioMainComponent } from "./cupom-relatorio/cupom-relatorio-main/cupom-relatorio-main.component";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardMainComponent } from "./dashboard/dashboard-main/dashboard-main.component";
import { GalpaoMainComponent } from "./_galpao/galpao/galpao-main/galpao-main.component";
import { GalpaoInsertEditComponent } from "./_galpao/galpao/galpao-insert-edit/galpao-insert-edit.component";
import { ClientesMainComponent } from "./_clientes/clientes/clientes-main/clientes-main.component";
import { ClientesInsertEditComponent } from "./_clientes/clientes/clientes-insert-edit/clientes-insert-edit.component";
import { ColetasMainComponent } from "./_coletas/coletas/coletas-main/coletas-main.component";
import { ColetasInsertEditComponent } from "./_coletas/coletas/coletas-insert-edit/coletas-insert-edit.component";
import { ContainerMainComponent } from "./container/container-main/container-main.component";
import { ContainerInsertEditComponent } from "./container/container-insert-edit/container-insert-edit.component";
import { GalpaoMotoristaMainComponent } from './_galpao/galpao-motorista/galpao-motorista-main/galpao-motorista-main.component';
import { GalpaoMotoristaInsertComponent } from './_galpao/galpao-motorista/galpao-motorista-insert/galpao-motorista-insert.component';
import { GalpaoArquivoMainComponent } from './_galpao/galpao-arquivo/galpao-arquivo-main/galpao-arquivo-main.component';
import { CategoriaArquivoMainComponent } from './categoria-arquivo/categoria-arquivo-main/categoria-arquivo-motorista-main.component';
import { CategoriaArquivoInsertComponent } from './categoria-arquivo/categoria-arquivo-insert/categoria-arquivo-insert.component';
import { ProdutoMainComponent } from './produto/produto-main/produto-main.component';
import { ProdutoInsertEditComponent } from './produto/produto-insert-edit/produto-insert-edit.component';
import { GalpaoArquivoMotoristaMainComponent } from "./_galpao/galpao-arquivo-motorista/galpao-arquivo-motorista-main/galpao-arquivo-motorista-main.component";
import { IndustriaDestinoMainComponent } from './industria-destino/industria-destino-main/industria-destino-main.component';
import { IndustriaDestinoInsertEditComponent } from './industria-destino/industria-destino-insert-edit/industria-destino-insert-edit.component';
import { FuncionarioMainComponent } from './funcionario/funcionario-main/funcionario-main.component';
import { FuncionarioInsertEditComponent } from './funcionario/funcionario-insert-edit/funcionario-insert-edit.component';
import { CupomMainComponent } from "./cupom/cupom-main/cupom-main.component";
import { CupomInsertEditComponent } from "./cupom/cupom-insert-edit/cupom-insert-edit.component";
import {
  ClientesFuncionariosMainComponent
} from "./_clientes/clientes-funcionarios/clientes-funcionarios-main/clientes-funcionarios-main.component";
import {
  ClientesFuncionariosInsertEditComponent
} from "./_clientes/clientes-funcionarios/clientes-funcionarios-insert-edit/clientes-funcionarios-insert-edit.component";
import { MaterialMainComponent } from './material/material-main/material-main.component';
import { MaterialInsertEditComponent } from './material/material-insert-edit/material-insert-edit.component';
import { RelatorioMissoesMainComponent } from './relatorio-missoes/relatorio-missoes-main/relatorio-missoes-main.component';
import { CategoriaNegocioMainComponent } from './categoria-negocio/categoria-negocio-main/categoria-negocio-main.component';
import { CategoriaNegocioInsertEditComponent } from './categoria-negocio/categoria-negocio-insert-edit/categoria-negocio-insert-edit.component';
import { RotasMainComponent } from './rotas/rotas-main/rotas-main.component';
import { MissaoMainComponent } from "./missao/missao-main/missao-main.component";
import { MissaoInsertEditComponent } from "./missao/missao-insert-edit/missao-insert-edit.component";
import { CadastroGravimetriaMainComponent } from './cadastro-gravimetria/cadastro-gravimetria-main/cadastro-gravimetria-main.component';
import { CadastroGravimetriaInsertEditComponent } from './cadastro-gravimetria/cadastro-gravimetria-insert-edit/cadastro-gravimetria-insert-edit.component';
import { RotasInsertEditComponent } from "./rotas/rotas-insert-edit/rotas-insert-edit.component";
import { NotaFiscalMainComponent } from "./_nota-fiscal/nota-fiscal/nota-fiscal-main/nota-fiscal-main.component";
import { NotaFiscalInsertEditComponent } from "./_nota-fiscal/nota-fiscal/nota-fiscal-insert-edit/nota-fiscal-insert-edit.component";
import { SelecionarPlanoComponent } from './selecionar-plano/selecionar-plano/selecionar-plano.component';
import { MissoesMainComponent } from './missoes/missoes-main/missoes-main.component';
import { MissoesCupomComponent } from './missoes/missoes-cupom/missoes-cupom.component';
import { ParceiroMainComponent } from './parceiro/parceiro-main/parceiro-main.component';
import { ParceiroInsertEditComponent } from './parceiro/parceiro-insert-edit/parceiro-insert-edit.component';
import { CobrancasMainComponent } from './cobrancas/cobrancas-main/cobrancas-main.component';
import { NewslettersInsertEditComponent } from "./newsletters/newsletters-insert-edit/newsletters-insert-edit.component";
import { NewslettersMainComponent } from "./newsletters/newsletters-main/newsletters-main.component";
import { LogisticaReversaMainComponent } from "./logistica-reversa/logistica-reversa-main/logistica-reversa-main.component";
import { VendasLogisticaReversaInsertEditComponent } from './vendas-logistica-reversa/vendas-logistica-reversa-insert-edit/vendas-logistica-reversa-insert-edit.component';
import { BeneficiosMainComponent } from './beneficios/beneficios-main/beneficios-main.component';
import { LogisticaReversaInsertEditComponent } from './logistica-reversa/logistica-reversa-insert-edit/logistica-reversa-insert-edit.component';
import { VendasLogisticaReversaMainComponent } from './vendas-logistica-reversa/vendas-logistica-reversa-main/vendas-logistica-reversa-main.component';
import { CadastrePlanoMainComponent } from './cadastre-plano/cadastre-plano-main/cadastre-plano-main.component';
import { CadastrePlanoInsertComponent } from './cadastre-plano/cadastre-plano-insert/cadastre-plano-insert.component';
import { FluxoCobrancaMainComponent } from './fluxo-cobranca/fluxo-cobranca-main/fluxo-cobranca-main.component';
import { FluxoCobrancaInsertComponent } from './fluxo-cobranca/fluxo-cobranca-insert/fluxo-cobranca-insert.component';
import { CobrancasEditComponent } from "./cobrancas/cobrancas-edit/cobrancas-edit.component";
import { SelecionarPlanoPontualComponent } from './selecionar-plano-pontual/selecionar-plano-pontual/selecionar-plano-pontual.component';
import { MeuPerfilInsertEditComponent } from './meu-perfil/meu-perfil-insert-edit/meu-perfil-insert-edit.component';
import { PermissionGuard } from "../../core/guards/guard-permission/permission.guard";
import { TipoLixoMainComponent } from "./tipo-lixo/tipo-lixo-main/tipo-lixo-main.component";
import { TipoLixoInsertEditComponent } from "./tipo-lixo/tipo-lixo-insert-edit/tipo-lixo-insert-edit.component";
import { UnidadeMedidaMainComponent } from "./unidade-medida/unidade-medida-main/unidade-medida-main.component";
import {
  UnidadeMedidaInsertEditComponent
} from "./unidade-medida/unidade-medida-insert-edit/unidade-medida-insert-edit.component";
import { HomeComponent } from "./home/home/home.component";
import { GrupoComunicacaoMainComponent } from './grupo-comunicacao/grupo-comunicacao-main/grupo-comunicacao-main.component';
import { GrupoComunicacaoInsertComponent } from './grupo-comunicacao/grupo-comunicacao-insert/grupo-comunicacao-insert.component';
import { TratamentoMainComponent } from "./tratamento/tratamento-main/tratamento-main.component";
import { TratamentoInsertEditComponent } from "./tratamento/tratamento-insert-edit/tratamento-insert-edit.component";
import { JornadaEducacaoAmbientalMainComponent } from './jornada-educacao-ambiental/jornada-educacao-ambiental-main/jornada-educacao-ambiental-main.component';
import { GestaoJornadaEducacaoAmbientalInsertEditComponent } from './gestao-jornada-educacao-ambiental/gestao-jornada-educacao-ambiental-insert-edit/gestao-jornada-educacao-ambiental-insert-edit.component';
import { GestaoJornadaEducacaoAmbientalMainComponent } from './gestao-jornada-educacao-ambiental/gestao-jornada-educacao-ambiental-main/gestao-jornada-educacao-ambiental-main.component';
import { GestaoProgramaLogisticaReversaMainComponent } from './gestao-programa-logistica-reversa/gestao-programa-logistica-reversa-main/gestao-programa-logistica-reversa-main.component';
import { GestaoProgramaLogisticaReversaInsertEditComponent } from './gestao-programa-logistica-reversa/gestao-programa-logistica-reversa-insert-edit/gestao-programa-logistica-reversa-insert-edit.component';
import { ProgramaLogisticaReversaMainComponent } from './programa-logistica-reversa/programa-logistica-reversa-main/programa-logistica-reversa-main.component';
import { PedidoCreditoLogisticaReversaInsertEditComponent } from './pedido-credito-logistica-reversa/pedido-credito-logistica-reversa-insert-edit/pedido-credito-logistica-reversa-insert-edit.component';
import { PedidoCreditoLogisticaReversaMainComponent } from './pedido-credito-logistica-reversa/pedido-credito-logistica-reversa-main/pedido-credito-logistica-reversa-main.component';
import { IndicadoresMainComponent } from './indicadores/indicadores-main/indicadores-main.component';
import { IndicadoresInsertEditComponent } from './indicadores/indicadores-insert-edit/indicadores-insert-edit.component';
import { IndicadoresOdsMainComponent } from './indicadores-ods/indicadores-ods-main/indicadores-ods-main.component';
import { IndicadoresOdsInsertEditComponent } from './indicadores-ods/indicadores-ods-insert-edit/indicadores-ods-insert-edit.component';
import {
  CadastroIndicadoresOdsMainComponent
} from "./cadastro-indicadores-ods/cadastro-indicadores-ods-main/cadastro-indicadores-ods-main.component";
import {
  CadastroIndicadoresOdsInsertEditComponent
} from "./cadastro-indicadores-ods/cadastro-indicadores-ods-insert-edit/cadastro-indicadores-ods-insert-edit.component";
import {WhiteLabelEditComponent} from "./white-label/white-label-edit/white-label-edit.component";
import {ControlePesagemMainComponent} from "./controle-pesagem/controle-pesagem-main/controle-pesagem-main.component";
import {Dashboard2MainComponent} from "./dashboard2/dashboard2-main/dashboard2-main.component";
import {ColetasMain2Component} from "./_coletas/coletas/coletas-main2/coletas-main2.component";
import {
  IndustriaArquivosMainComponent
} from "./industria-destino/pages/industria-arquivos-main/industria-arquivos-main.component";

const galpao: Routes = [
  {
    path: 'galpao/galpao',
    component: GalpaoMainComponent
  },
  {
    path: 'galpao/galpao',
    children: [
      { path: ':id', component: GalpaoInsertEditComponent }
    ]
  },
  {
    path: 'galpao/motoristas',
    component: GalpaoMotoristaMainComponent
  },
  {
    path: 'galpao/motoristas',
    children: [
      { path: ':id', component: GalpaoMotoristaInsertComponent }
    ]
  },
  {
    path: 'galpao/arquivos',
    component: GalpaoArquivoMainComponent
  },
  {
    path: 'galpao/arquivos-motoristas',
    component: GalpaoArquivoMotoristaMainComponent
  },
  {
    path: 'operacional/categoria-arquivo', component: CategoriaArquivoMainComponent
  },
  {
    path: 'operacional/categoria-arquivo',
    children: [
      { path: ':id', component: CategoriaArquivoInsertComponent }
    ]
  },
  {
    path: 'operacional/residuo-tipo', component: TipoLixoMainComponent
  },
  {
    path: 'operacional/residuo-tipo',
    children: [
      { path: ':id', component: TipoLixoInsertEditComponent }
    ]
  },
  {
    path: 'operacional/unidade-medida', component: UnidadeMedidaMainComponent
  },
  {
    path: 'operacional/unidade-medida',
    children: [
      { path: ':id', component: UnidadeMedidaInsertEditComponent }
    ]
  }
];

const clientes: Routes = [
  {
    path: 'clientes/clientes',
    component: ClientesMainComponent
  },
  {
    path: 'clientes/clientes',
    children: [
      { path: ':id', component: ClientesInsertEditComponent }
    ]
  },
  {
    path: 'clientes/funcionarios',
    component: ClientesFuncionariosMainComponent
  },
  {
    path: 'clientes/funcionarios',
    children: [
      { path: ':id', component: ClientesFuncionariosInsertEditComponent }
    ]
  },
]

const routes: Routes = [

  {
    path: '',
    component: HomeComponent
  },

  {
    path: 'dashboard2',
    component: Dashboard2MainComponent
  },

  {
    path: 'dashboard',
    component: DashboardMainComponent
  },
  // {
  //   path: 'dashboard',
  //   children: [
  //     { path: ':id', component: DashboardMainComponent }
  //   ]
  // },

  ...galpao,
  ...clientes,

  {
    path: "controle-pesagem",
    component: ControlePesagemMainComponent
  },
  {
    path: "financeiro/cobrancas",
    component: CobrancasMainComponent
  },
  {
    path: 'financeiro/cobrancas',
    children: [
      { path: ':id', component: CobrancasEditComponent }
    ]
  },

  {
    path: 'operacional/coletas/coletas',
    component: ColetasMainComponent
  },

  {
    path: 'operacional/coletas/coletas2',
    component: ColetasMain2Component
  },
  {
    path: 'operacional/coletas/coletas',
    children: [
      { path: ':id', component: ColetasInsertEditComponent }
    ]
  },
  {
    path: 'operacional/container',
    component: ContainerMainComponent
  },
  {
    path: 'operacional/container',
    children: [
      { path: ':id', component: ContainerInsertEditComponent }
    ]
  },

  {
    path: 'operacional/produto',
    component: ProdutoMainComponent
  },

  {
    path: 'operacional/produto',
    children: [
      { path: ':id', component: ProdutoInsertEditComponent }
    ]
  },

  {
    path: 'industriaDestino/arquivos-destinador',
    component: IndustriaArquivosMainComponent
  },

  {
    path: 'industriaDestino',
    component: IndustriaDestinoMainComponent
  },

  {
    path: 'industriaDestino',
    children: [
      { path: ':id', component: IndustriaDestinoInsertEditComponent }
    ]
  },

  {
    path: 'operacional/funcionario',
    component: FuncionarioMainComponent
  },

  {
    path: 'operacional/funcionario',
    children: [
      { path: ':id', component: FuncionarioInsertEditComponent }
    ]
  },
  {
    path: 'parceiros/material',
    component: MaterialMainComponent
  },
  {
    path: 'parceiros/material',
    children: [
      { path: ':id', component: MaterialInsertEditComponent }
    ]
  },
  {
    path: 'logisticaReversa/relatorioMissoes',
    component: RelatorioMissoesMainComponent
  },
  {
    path: 'operacional/gravimetria',
    component: CadastroGravimetriaMainComponent
  },
  {
    path: 'operacional/gravimetria',
    children: [
      { path: ':id', component: CadastroGravimetriaInsertEditComponent }
    ]
  },
  {
    path: 'comunicacao/newsletters',
    component: NewslettersMainComponent
  },
  {
    path: 'comunicacao/newsletters',
    children: [
      { path: ':id', component: NewslettersInsertEditComponent }
    ]
  },
  {
    path: 'logisticaReversa/logisticaReversa',
    component: LogisticaReversaMainComponent
  },
  {
    path: 'logisticaReversa/logisticaReversa',
    children: [
      { path: ':id', component: LogisticaReversaInsertEditComponent }
    ]
  },
  {
    path: 'logisticaReversa/pedidoCreditoLogisticaReversa',
    component: PedidoCreditoLogisticaReversaMainComponent
  },
  {
    path: 'logisticaReversa/pedidoCreditoLogisticaReversa',
    children: [
      {path: ':id', component: PedidoCreditoLogisticaReversaInsertEditComponent}
    ]
  },
  {
    path: 'logisticaReversa/vendasLogisticaReversa',
    component: VendasLogisticaReversaMainComponent
  },
  {
    path: 'logisticaReversa/vendasLogisticaReversa',
    children: [
      { path: ':id', component: VendasLogisticaReversaInsertEditComponent }
    ]
  },

  {
    path: 'educacao-ambiental/cupom',
    component: CupomMainComponent
  },

  {
    path: 'educacao-ambiental/cupom',
    children: [
      { path: ':id', component: CupomInsertEditComponent }
    ]
  },

  {
    path: 'parceiros/categoriaNegocio',
    component: CategoriaNegocioMainComponent
  },
  {
    path: 'parceiros/categoriaNegocio',
    children: [
      { path: ':id', component: CategoriaNegocioInsertEditComponent }
    ]
  },

  {
    path: 'educacao-ambiental/cupomRelatorio',
    component: CupomRelatorioMainComponent
  },
  {
    path: 'galpao/rotas',
    component: RotasMainComponent
  },
  {
    path: 'galpao/rotas',
    children: [
      { path: ':id', component: RotasInsertEditComponent }
    ]
  },

  {
    path: 'missao',
    component: MissaoMainComponent
  },
  {
    path: 'missao',
    children: [
      { path: ':id', component: MissaoInsertEditComponent }
    ]
  },

  {
    path: 'jornada-educacao-ambiental',
    component: JornadaEducacaoAmbientalMainComponent
  },

  {
    path: 'educacao-ambiental/gestao-jornada-educacao-ambiental',
    component: GestaoJornadaEducacaoAmbientalMainComponent
  },
  {
    path: 'educacao-ambiental/gestao-jornada-educacao-ambiental',
    children: [
      {path: ':id', component: GestaoJornadaEducacaoAmbientalInsertEditComponent}
    ]
  },

  {
    path: 'logisticaReversa/gestao-programa-logistica-reversa',
    component: GestaoProgramaLogisticaReversaMainComponent
  },
  {
    path: 'logisticaReversa/gestao-programa-logistica-reversa',
    children: [
      {path: ':id', component: GestaoProgramaLogisticaReversaInsertEditComponent}
    ]
  },
  {
    path: 'selecionar-plano/programa-logistica-reversa',
    component: ProgramaLogisticaReversaMainComponent
  },

  {
    path: 'selecionar-plano',
    component: SelecionarPlanoComponent
  },
  {
    path: 'solicitar-coleta-pontual',
    component: SelecionarPlanoPontualComponent
  },

  {
    path: 'missoes',
    component: MissoesMainComponent,
    data: { perfil: 'CONDOMINIO' },
    canActivate: [PermissionGuard],
  },

  { path: 'parceiros/parceiro', component: ParceiroMainComponent },
  {
    path: 'parceiros/parceiro',
    children: [
      { path: ':id', component: ParceiroInsertEditComponent }
    ]
  },

  { path: 'operacional/coleta-tratamento', component: TratamentoMainComponent },
  {
    path: 'operacional/coleta-tratamento',
    children: [
      { path: ':id', component: TratamentoInsertEditComponent }
    ]
  },

  {
    path: 'missoes',
    children: [
      { path: ':id', component: MissoesCupomComponent }
    ]
  },

  {
    path: 'informacoes-gerais',
    component: InformacoesGeraisComponent
  },

  { path: 'beneficios', component: BeneficiosMainComponent },

  {
    path: 'financeiro/nota_fiscal',
    component: NotaFiscalMainComponent
  },
  {
    path: 'financeiro/nota_fiscal',
    children: [
      { path: ':id', component: NotaFiscalInsertEditComponent }
    ]
  },


  { path: 'contratarColetas/cadastre-plano', component: CadastrePlanoMainComponent },

  {
    path: 'contratarColetas/cadastre-plano',
    children: [
      { path: ':id', component: CadastrePlanoInsertComponent }
    ]
  },

  { path: 'financeiro/fluxo-cobranca', component: FluxoCobrancaMainComponent },

  {
    path: 'financeiro/fluxo-cobranca',
    children: [
      { path: ':id', component: FluxoCobrancaInsertComponent }
    ]
  },

  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },

  {
    path: 'historico-coletas',
    component: HistoricoColetasMainComponent,
  },
  {
    path: 'historico-coletas',
    children: [{ path: ':id', component: HistoricoColetasInsertEditComponent }],
  },

  {
    path: 'comunicacao/administracao',
    component: AdministracaoMainComponent,
  },
  {
    path: 'meu-perfil',
    component: MeuPerfilInsertEditComponent
  },

  {
    path: 'comunicacao/grupo-comunicacao',
    component: GrupoComunicacaoMainComponent
  },

  {
    path: 'comunicacao/grupo-comunicacao',
    children: [
      { path: ':id', component: GrupoComunicacaoInsertComponent }
    ]
  },

  {
    path: 'operacional/indicadores',
    component: IndicadoresMainComponent
  },

  {
    path: 'operacional/indicadores',
    children: [
      { path: ':id', component: IndicadoresInsertEditComponent }
    ]
  },

  {
    path: 'indicadores-ods',
    component: IndicadoresOdsMainComponent
  },

  {
    path: 'indicadores-ods',
    children: [
      { path: ':id', component: IndicadoresOdsInsertEditComponent }
    ]
  },

  {
    path: 'cadastro-indicadores-ods',
    component: CadastroIndicadoresOdsMainComponent
  },

  {
    path: 'cadastro-indicadores-ods',
    children: [
      { path: ':id', component: CadastroIndicadoresOdsInsertEditComponent }
    ]
  },
  {
    path: 'white-label',
    component: WhiteLabelEditComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
