


export declare type TipoUsuarioClienteType = 'SINDICO'|'CONSELHO'|'MORADOR'|'PORTEIRO'|'FINANCEIRO';

export enum TipoUsuarioClienteEnum {
  SINDICO = "Gestor",
  CONSELHO = "Administrativo",
  MORADOR = "Usuário",
  PORTEIRO = "Operador",
  FINANCEIRO = "Financeiro"
}
