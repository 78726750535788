import {Component, HostListener, Input, OnInit} from '@angular/core';
import {filtersInterface} from "../../dashboard-main/dashboard-main.component";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {DASHBOARD} from "../../../../../core/config/endpoint-list";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {DetalhamentoUnidadeGeradoraEntity} from "../../entitys/detalhamento-unidade-geradora.entity";
import {DetalhamentoGriEntity} from "../../entitys/detalhamento-gri.entity";
import {crudDispatchEvent} from "@datagrupo/dg-crud";
import * as Highcharts from "highcharts";
import {ajusteFunctionHighChartsByInsert} from "../../helpers/highcharts.helper";
import {Router} from "@angular/router";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'sub-component-dashboard-coletas',
  templateUrl: './sub-component-dashboard-coletas.component.html',
  styleUrls: ['./sub-component-dashboard-coletas.component.scss']
})
export class SubComponentDashboardColetasComponent implements OnInit {

  @Input('public') public: boolean = false
  @Input('showPages') showPages: boolean = false

  // showPages = false;
  liberarTabelas = false;
  existDataColetas = true
  persistParams = {}
  registerParams = {}
  cardInfo = {
    numeroClientes: 0,
    numeroApartmentos: 0
  }
  graph: {
    anos: any,
    meses: any,
    totais: any,
    residuos: any,
    ods: any,
  } = {
    anos: undefined,
    meses: undefined,
    totais: undefined,
    residuos: undefined,
    ods: undefined,
  }
  impacto: {
    minerio: number | string,
    areia: number | string,
    arvores: number | string,
    petroleo: number | string,
    agua: number | string,
    energia: number | string,
  } = {
    minerio: 0,
    areia: 0,
    arvores: 0,
    petroleo: 0,
    agua: 0,
    energia: 0,
  }
  ods = {
    taxaReciclagem: 0,
    valorIndicadorBrasil: 0,
    anoIndicadorBrasil: '--'
  }

  loader: number = 100;

  loaders: {
    all: number,
    anos: boolean,
    meses: boolean,
    totais: boolean,
    residuos: boolean,
    ods: boolean,
    motoristas: boolean,
    impacto: boolean,
    aterroZero: boolean
  } = {
    all: 100,
    anos: true,
    meses: true,
    totais: true,
    residuos: true,
    ods: true,
    motoristas: true,
    impacto: true,
    aterroZero: true
  }

  public contAtualizacao = 0;
  public listGraph: any[] = [];

  public entity = new DetalhamentoUnidadeGeradoraEntity()
  public entityGri = new DetalhamentoGriEntity()

  constructor(
    public service: GenericCrudService,
    private files: FilesManageService,
    private router: Router,
    public session: SessionService
  ) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: '.',
        decimalPoint: ','
      }
    });
  }

  ngOnInit(): void {
  }

  /* METODOS DE GESTÃO DE DADOS DE MONTAGEM DA TELA */

  @HostListener('window:atualizar-pagina-filtrada-coletas', ['$event'])
  getEventValues(ev: CustomEvent) {
    if (this.verifyDiffParams(ev.detail)) {
      this.reset();
      this.persistParams = ev.detail;
      this.registerParams = this.persistParams;
      setTimeout(() => {
        this.getInfo()
      }, 100)
    }
  }

  reset() {
    this.graph = {
      anos: undefined,
      meses: undefined,
      totais: undefined,
      residuos: undefined,
      ods: undefined,
    }
    this.contAtualizacao++
    this.loaders = {
      all: 100,
      anos: true,
      meses: true,
      totais: true,
      residuos: true,
      ods: true,
      motoristas: true,
      impacto: true,
      aterroZero: true
    }
  }

  verifyDiffParams(newParams: any): boolean {
    const result = JSON.stringify(this.registerParams) != JSON.stringify(newParams);
    return result;
  }

  getInfo() {
    const params = this.persistParams;
    this.loader = 0;

    this.service.get('dashboard/info', {params}).subscribe(
      resp => {
        this.addLoader()
        this.cardInfo.numeroApartmentos = resp.data.numeroDeApartamentos || 0;
        this.cardInfo.numeroClientes = resp.data.numeroDeUnidades || 0;
        this.existDataColetas = resp.data.hasAnyDados;

        if (!!resp.data.hasAnyDados) {
          this.getValues().then()
        } else {
          this.loader = 100;
        }
      }
    )
  }

  async getValues() {
    const params = this.persistParams;

    setTimeout(() => {
      crudDispatchEvent('tabelaDetalhamento')
      crudDispatchEvent('tabelaGRI')
    }, 10)

    this.impacto = {
      minerio: 0,
      areia: 0,
      arvores: 0,
      petroleo: 0,
      agua: 0,
      energia: 0,
    }

    await this.service.get('dashboard/anos', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.anos = false;
        this.setGraph('id-chart-anos', resp.data.charts[0])

      }
    )
    await this.service.get('dashboard/meses', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.meses = false;
        this.setGraph('id-chart-meses', resp.data.charts[0])
      }
    )
    await this.service.get('dashboard/totais', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.totais = false;
        this.setGraph('id-chart-totais', resp.data.charts[0])
      }
    )
    await this.service.get('dashboard/residuos', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.residuos = false;
        this.setGraph('id-chart-residuos', resp.data.charts[0])
      }
    )

    if (!this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      await this.service.get('dashboard/motoristas', {params}).subscribe(
        resp => {
          this.addLoader()
          this.loaders.motoristas = false;
          this.setGraph('id-chart-motoristas', resp.data.charts[0])
        }
      )
    }

    await this.service.get('dashboard/aterro-zero', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.aterroZero = false;
        this.setGraph('id-chart-aterro-zero', resp.data[0])
      }
    )

    await this.service.get('dashboard/indicador-ods', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.ods = false;

        this.ods = {
          taxaReciclagem: resp.data.taxaReciclagem || 0,
          valorIndicadorBrasil: resp.data.valorIndicadorBrasil || 0,
          anoIndicadorBrasil: resp.data.anoIndicadorBrasil || '--'
        }
      }
    )

    await this.service.get('dashboard/relatorio-impacto', {params}).subscribe(
      resp => {
        this.addLoader();
        this.loaders.impacto = false;

        (resp.data || []).map((impacto: any) => {
          if (String(impacto.indicador.nome).toLowerCase() == 'areia') {
            this.impacto.areia = Number(1550.05).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'arvores') {
            this.impacto.arvores = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'petroleo') {
            this.impacto.petroleo = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'agua') {
            this.impacto.agua = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'energia eletrica') {
            this.impacto.energia = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'minerio') {
            this.impacto.minerio = Number(impacto.valor).toLocaleString('BR')
          }
          // this.impacto[<'minerio'>impacto.indicador.nome] = impacto.valor
          // if (impacto.indicador.nome == 'minerio') {
          //   this.impacto.minerio = impacto.valor;
          // }
        })
      }
    )
  }

  addLoader() {
    const numberRequests = 5;
    this.loader = this.loader + (100 / numberRequests);
  }

  criarId(i: number, chart: 'anos' | 'meses' | 'totais' | 'residuos' | 'ods'): string {
    return 'chart-id-' + String(i) + '-' + this.contAtualizacao;
  }

  ajustParams() {
    return {}
  }

  setGraph(id: string, data: any) {
    if (window.document.getElementById(id)) {
      setTimeout(() => {
        Highcharts.chart(id, ajusteFunctionHighChartsByInsert(data));
      }, 100)
    }
  }

  changeshowPages(ev: boolean) {
    if (this.public) return;

    this.showPages = ev;
  }

  issetFilterUnidadeByOperador(): boolean {
    if (this.session.checkPerfil('GALPAO')) {
      return 'unidades' in this.persistParams
    }

    return false
  }

  /* METODOS HELPER DAS TABELAS */

  downloadExcelDetalhamento() {
    const params = {
      unpaged: true,
      ...this.persistParams
    };

    this.service.get('dashboard/datatable/planilha', {params}).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta.xlsx')
      }
    )
  }

  downloadExcelGri() {
    const params = {
      unpaged: true,
      ...this.persistParams
    };

    this.service.get('dashboard/gri-datatable/planilha', {params}).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta-planilha-GRI.xlsx')
      }
    )
  }

  compartilharRelatorio() {
    const params = this.persistParams;

    this.service.get('dashboard/share', {params}).subscribe(
      resp => {
        const url = this.router.createUrlTree(['dashboard', resp.data])
        window.open(url.toString(), '_blank')
      }
    )
  }

  generate_pdf() {
    const params = JSON.stringify(this.persistParams);
    const paginationTableClientes = JSON.stringify(this.entity.configEntityTable.pagination)
    const paginationTableGRI = JSON.stringify(this.entityGri.configEntityTable.pagination)

    console.log('teste', params);
    debugger

    window.open(
      '/user/dashboard-print-coletas?paginationTableClientes=' +
      paginationTableClientes + '&paginationTableGRI=' + paginationTableGRI + '&params=' + params, '_blank')?.focus();
  }
}
