import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PlanosClientesTableComponent} from "./planos-clientes-table/planos-clientes-table.component";
import { PlanosPontualClientesTableComponent } from './planos-pontual-clientes-table/planos-pontual-clientes-table.component';
import {UiModule} from "../../../../shared/ui/ui.module";
import {MatIconModule} from "@angular/material/icon";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {FormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    PlanosClientesTableComponent,
    PlanosPontualClientesTableComponent
  ],
  exports: [
    PlanosPontualClientesTableComponent,
    PlanosClientesTableComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatIconModule,
    DgCrudModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class PlanosClientesModule { }
