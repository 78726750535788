

export namespace ProgressLoader {
  export declare type classe = ProgressLoaderClass;

  export declare type loaderItem = { finish: boolean, error: boolean }

  export function createLoader(callbackFinishAll?: () => void) {
    return new ProgressLoaderClass(callbackFinishAll);
  }
}

class ProgressLoaderClass {

  totalLoaders: number = 0;
  listLoaders: {[key: string]: ProgressLoader.loaderItem} = {}
  showLoader: boolean = false;
  callbackFinishAll: undefined | (() => void)

  constructor(callbackFinishAll?: () => void) {
    this.callbackFinishAll = callbackFinishAll
  }

  /**
   * registra os loaders que devem ser aguardados
   * @param loaders
   */
  register(loaders: string[]) {
    this.totalLoaders = loaders.length;
    const listLoaders: {[key: string]: boolean} = {};
    // loaders.map(item => listLoaders[item] = false);
    this.listLoaders = {};
    loaders.map(item => {
      this.listLoaders[item] = {
        finish: false,
        error: false
      }
    });
    this.showLoader = loaders.length > 0;

    // this.listLoaders = listLoaders;
  }

  progress(): {percent: number, total: number, complete: number} {
    const part = 100/this.totalLoaders;

    const partFull = Object.values(this.listLoaders).filter(item => item.finish).length;

    return {
      percent: partFull* part,
      total: Object.keys(this.listLoaders).length,
      complete: Object.values(this.listLoaders).filter(item => item.finish).length
    }
  }

  getItemLoader(loader: string): ProgressLoader.loaderItem {
    if (loader in this.listLoaders) {
      return this.listLoaders[loader];
    }
    return {finish: false, error: true};
  }

  finish(loader: string, isError: boolean = false) {
    if (!(loader in this.listLoaders)) return;
    this.listLoaders[loader].error = isError;
    this.listLoaders[loader].finish = true;

    if (Object.values(this.listLoaders).filter(item => !item.finish).length == 0) {
      setTimeout(() => {
        this.showLoader = false;
        if (this.callbackFinishAll) this.callbackFinishAll();
      }, 200)
    }
  }
}
