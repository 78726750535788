import { environment } from './../../../../../../environments/environment';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { LogisticaProdutosEntity } from '../../logistica-produtos.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteCreditoLogisticaReversaProdutoService extends AbstractHttpService<LogisticaProdutosEntity>  {

  constructor(public config: ConfigDgCrudService ) {
    super(config, environment.apiUrl, 'cliente-credito-logistica-reversa/produtos')
  }
}
