import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit {

  enableBtnPrint: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  print() {
    window.print();
  }

  @HostListener('window:togle-enable-btn-print', ['$event'])
  togleEnableBtnPrint(ev: CustomEvent<boolean>) {
    this.enableBtnPrint = !!ev.detail;
  }
}
