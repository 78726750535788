<div
  class="offcanvas offcanvas-end sidebar-filter"
  tabindex="-1"
  [id]="'sideFilter'+(idOpen ? '-'+idOpen : '')"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Filtros</h5>

    <div>
      <span class="material-symbols-outlined" (click)="clearFilter.emit()" matTooltip="Limpar filtros">delete</span>
      <span
        (click)="modal.open()"
        *ngIf="visibleFavorite"
        class="material-symbols-outlined"
        matTooltip="Salvar filtros como favorito"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >star</span>
    </div>

    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content></ng-content>
  </div>
</div>


<modal-favoritos-main #modal></modal-favoritos-main>
