import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractEntity, CrudDataTableComponent} from "@datagrupo/dg-crud";

@Component({
  selector: 'dg-pagination',
  templateUrl: './dg-pagination.component.html',
  styleUrls: ['./dg-pagination.component.scss']
})
export class DgPaginationComponent implements OnInit, OnChanges {

  @Input('table') table: CrudDataTableComponent | any;
  @Input('entity') entity: AbstractEntity | undefined;
  @Input() dataPagination: { page: number | undefined } | any | undefined;
  @Input() optionsSizesPagination: number[] = [5, 10, 20, 50, 100]
  @Input() showSizeSelect: boolean = true;
  @Input() className: string = '';

  @Output() changePage = new EventEmitter<any>()

  public currentPage: number = 1;

  public data = {
    size: 10,
    page: 0,
    totalPages: 0
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataPagination']) {
      const dataPagination = changes['dataPagination'].currentValue;

      this.data.page = (!!dataPagination?.page && dataPagination.page != 0) ? Number(dataPagination.page) : 0;
      this.data.size = (!!dataPagination?.size && dataPagination.size != 0) ? Number(dataPagination.size) : 10
      this.data.totalPages = (!!dataPagination?.totalPages && dataPagination.totalPages != 0) ? Number(dataPagination.totalPages) : 0
    }
  }

  changePagination(value: number) {
    this.data.page = value;
    this.changePage.emit(this.data);
  }

  verificarMenores(value: number) {
    return  ((Number(this.data.page) + 1) - value) > 0
  }

  disabled(data: 'menos' | 'mais', valor?: number) {

    if (data === 'menos') {
      if (!!valor) {
        return (this.data.page - valor) < 0
      } else {
        return this.data.page <= 0
      }

    } else {
      if (!!valor) {
        return Number(this.data.page) + valor >= this.data.totalPages;
      } else {
        return Number(this.data.page) + 1 >= this.data.totalPages;
      }
    }

  }

  verificarMaiores(value: number) {
    return ((Number(this.data.page) + 1) + value) <= this.data.totalPages
  }

  alterSize(size: number) {
    this.data.page = 0;
    this.data.size = size;
    this.changePage.emit(this.data);
  }
}
