<div [class.load]="!load">
  <mat-spinner></mat-spinner>
</div>

<div class="folhas" [class.load]="!!load">
  <div class="conteudo" #conteudo >
    <div class="container-data">
      <div class="row">
        <div class="col-6">
          <div class="w-100 destaque-claro d-inline-flex justify-content-center">
            Número de clientes
          </div>
          <div class="w-100 destaque-claro d-inline-flex justify-content-center">
            0
          </div>
        </div>
        <div class="col-6">
          <div class="w-100 destaque-claro d-inline-flex justify-content-center">
            Número de Apartamentos
          </div>
          <div class="w-100 destaque-claro d-inline-flex justify-content-center">
            0
          </div>
        </div>
      </div>
    </div>

    <div class="container-data">
      <div class="row">
        <div
          *ngFor="let grapg of listGraph; let i = index"
          class="col-6"
        >
          <app-card-graph [id]="criarId(i)" [dataGraphic]="grapg">
          </app-card-graph>
        </div>
      </div>
    </div>

    <div class="container-data">
      <card>
        <div class="card-body">
          <crud-data-table
            #table
            [resourceDictionary]="teste"
            [service]="service"
            [entityMap]="entity"
            [showPagination]="false"
            [showDefaultAction]="false"
            [endpoint]="'datatable'"
          ></crud-data-table>
        </div>
      </card>
    </div>
  </div>
</div>
