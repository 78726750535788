import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {NotaFiscalEntity} from "../nota-fiscal.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {Observable} from "rxjs";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";

@Injectable({
  providedIn: 'root'
})
export class NotaFiscalService extends AbstractHttpService<NotaFiscalEntity>{

  constructor(
    public config: ConfigDgCrudService,
    public httpServ: HttpOldService,
    private files: FilesManageService
  ) {
    super(config, environment.apiUrl, 'notafiscal')
  }

  downloadPlanilha() {
    this.httpServ.get('notafiscal/planilha').subscribe(
      (resp) => {
        //@ts-ignore
        this.files.downloadBase64(resp.data, 'planilha-notas-fiscais.xlsx')
      }
    )
  }

  alterEstoqueMinimo(data: object) {
    return this.http.patch(this.path, data)
  }
}
