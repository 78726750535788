import { Component, OnInit } from '@angular/core';
import { MaterialEntity } from '../material.entity';
import { MaterialService } from '../service/material.service';

@Component({
  selector: 'app-material-main',
  templateUrl: './material-main.component.html',
  styleUrls: ['./material-main.component.scss']
})
export class MaterialMainComponent implements OnInit {

  public mapEntity = new MaterialEntity()

  constructor(
    public service: MaterialService
  ) { }

  ngOnInit(): void {
  }

}
