import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {FilesManageService} from "../../../../services/files-manage/files-manage.service";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {SideFilterComponent} from "../../../../shared/side-filter/side-filter/side-filter.component";

export interface filtersInterface {
  unidades: string[] | number[],
  dataInicio: string,
  dataFim: string,
  estados: string[] | number[],
  galpao: string[] | number[],
  tipoResiduo: string[] | number[],
  perfilUnidade?: string,
  ativo: string,
  prelistUnidades?: any[]
  prelistOperadores?: any[]
  prelistTipoResiduo?: any[]
  prelistEstados?: any[],
  paginationTableClientes?: object
  paginationTableGRI?: object
}

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss']
})
export class DashboardMainComponent implements OnInit {

  @ViewChild('filter') filter!: SideFilterComponent;

  public unidadeGeradora: string[] | number[] = [];
  public ano: string[] = [];

  public filterData: filtersInterface = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };

  public relatorio: 'estoque' | 'coletas' | 'taxaReciclagem' | 'aterroZero' | 'impacto' = 'coletas'

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpOldService,
    private files: FilesManageService,
    public session: SessionService
  ) {
    if (!window.localStorage.getItem('accessToken') || window.localStorage.getItem('accessToken') == 'undefined') {
      route.params.subscribe(params => {
        if (!!params['id']) {
          router.navigate(['dashboard', params['id']]).then()
        }
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
        this.filterData['prelistUnidades'] = [{ id: this.session.user.cliente_id, nome: 'this.session.user.cliente_nome' }]
        this.filterData['unidades'] = [<number>this.session.user.cliente_id]
      } else {
        if (!!params['unidadeGeradora']) {
          this.filterData['prelistUnidades'] = JSON.parse(params['unidadeGeradora'])
          this.filterData['unidades'] = JSON.parse(params['unidadeGeradora']).map((item: any) => item.id);
        }
      }

      if (!!params['galpao']) {
        this.filterData['prelistOperadores'] = JSON.parse(params['galpao']);
        this.filterData['galpao'] = JSON.parse(params['galpao']).map((item: any) => item.id);
      }

      if (!!params['tipoResiduo']) {
        this.filterData['prelistTipoResiduo'] = JSON.parse(params['tipoResiduo']);
        this.filterData['tipoResiduo'] = JSON.parse(params['tipoResiduo']).map((item: any) => item.id);
      }

      if (!!params['estados']) {
        this.filterData['prelistEstados'] = JSON.parse(params['estados']);
        this.filterData['estados'] = JSON.parse(params['estados']).map((item: any) => item.uf);
      }

      if (!!params['dataInicio']) {
        this.filterData['dataInicio'] = params['dataInicio'];
      }

      if (!!params['dataFim']) {
        this.filterData['dataFim'] = params['dataFim'];
      }

      if (!!params['ativo']) {
        this.filterData['ativo'] = params['ativo'];
      }

      window.dispatchEvent(
        new CustomEvent('setDataSidebar',
          { detail: {...this.filterData} })
      )

    }).unsubscribe()
  }

  compartilharRelatorio() {
    const params = this.ajustParams();

    this.http.get<{ data: string }>('dashboard/share', { params }).subscribe(
      resp => {
        const url = this.router.createUrlTree(['dashboard', resp.data])
        window.open(url.toString(), '_blank')
      }
    )

  }

  public ajustParams() {
    const params: any = {};

    if (this.filterData.dataInicio) {
      params['dataInicio'] = this.filterData.dataInicio
    }

    if (this.filterData.dataFim) {
      params['dataFim'] = this.filterData.dataFim
    }

    if (this.filterData.unidades.length > 0) {
      params['unidades'] = this.filterData.unidades.join(',')
    }

    if (this.filterData.estados.length > 0) {
      params['estados'] = this.filterData.estados.join(',')
    }

    if (this.filterData.galpao.length > 0) {
      params['galpao'] = this.filterData.galpao.join(',')
    }

    if (!!this.filterData.ativo) {
      params['ativo'] = this.filterData.ativo
    }

    return params;
  }

  public generate_pdf(): void {
    if (this.relatorio == 'coletas') {
      window.dispatchEvent( new CustomEvent('print-coletas'))
    }

    return;
  }

  changeFilter(ev: filtersInterface) {
    this.filterData = {...ev};
  }

  showData(): boolean {
    return !!this.filterData.dataInicio || !!this.filterData.dataFim || this.filterData.estados.length > 0 ||
      this.filterData.unidades.length > 0 ||this.filterData.galpao.length > 0 || !!this.filterData.ativo ||
      this.filterData.tipoResiduo.length > 0
  }

  downloadExcel() {
    const params = {
      unpaged: true,
      ...this.ajustParams()
    };

    this.http.get<{ data: string }>('dashboard/datatable/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta.xlsx')
      }
    )
  }
}
