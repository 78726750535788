import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicDashboard2ColetasComponent } from './public-dashboard2-coletas/public-dashboard2-coletas.component';
import {Dashboard2Module} from "../../_user/dashboard2/dashboard2.module";



@NgModule({
  declarations: [
    PublicDashboard2ColetasComponent
  ],
  imports: [
    CommonModule,
    Dashboard2Module
  ]
})
export class PublicDashboard2Module { }
