import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CrudDataTableComponent, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {SessionService} from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import {IndustriaDestinoEntity} from '../industria-destino.entity';
import {IndustriaDestinoService} from '../service/industria-destino.service';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {HttpService} from "../../../../services/http-service/http.service";
import {ClientesEntity} from "../../_clientes/clientes/clientes.entity";

@Component({
  selector: 'app-industria-destino-main',
  templateUrl: './industria-destino-main.component.html',
  styleUrls: ['./industria-destino-main.component.scss']
})
export class IndustriaDestinoMainComponent implements OnInit, OnDestroy {

  public mapEntity = new IndustriaDestinoEntity()
  // @ViewChild('tableIndustriaDestino') tableIndustriaDestino!: CrudDataTableComponent;
  // public customAction: InterfaceCustomActions[] = [
  //   {
  //     name: 'Editar',
  //     action: (val: IndustriaDestinoEntity) => {
  //       this.route.navigate(['user/industriaDestino/' + val.id])
  //     },
  //     permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
  //   },
  //   {
  //     name: 'Excluir',
  //     action: (val: IndustriaDestinoEntity) => {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: "Excluir registro",
  //         text: 'Deseja excluir este registro? Esta ação é permanente.',
  //         showCancelButton: true,
  //         confirmButtonText: 'Excluir',
  //         confirmButtonColor: 'red',
  //         cancelButtonColor: 'blue',
  //         cancelButtonText: 'Cancelar'
  //       }).then(confirm => {
  //         if (confirm.isConfirmed){
  //           this.service.delet('industria/' + val.id).subscribe(() => this.tableIndustriaDestino._pesquisar())
  //           Swal.fire({
  //             title: 'Deletado com sucesso',
  //             text: 'Esse registro foi deletado com sucesso',
  //             icon: 'success',
  //             timer: 2000,
  //             showConfirmButton: false
  //           })
  //         }
  //       })
  //     },
  //     permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
  //   }
  // ]

  table: CdkDynamicTable.tableClass;

  formFilters = new FormGroup({
    industriaId: new FormControl(''),
    cnpj: new FormControl(''),
    clienteId: new FormControl(''),
  })

  listIndustrias: Partial<IndustriaDestinoEntity>[] = []
  listGeradores: Partial<ClientesEntity>[] = []

  constructor(
    public service: IndustriaDestinoService,
    public session: SessionService,
    public route: Router,
    private createTable: CdkDynamicTableService,
    private http: HttpService
  ) {
    this.table = createTable.create("request", {
      apiData: {
        path: environment.apiUrl,
        context: 'industria'
      },
      columns: [
        {name: 'nome', headerName: 'Nome'},
        {name: 'cnpj', headerName: 'CJPN'},
        {name: 'inscricaoEstadual', headerName: 'Inscrição estadual'},
      ],
      actions: {
        editar: {
          name: 'Editar',
          dbClick: true,
          action: (val: IndustriaDestinoEntity) => {
            this.route.navigate(['user/industriaDestino/' + val.id])
          },
          permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
        },
        excluir: {
          name: 'Excluir',
          action: (val: IndustriaDestinoEntity) => {
            Swal.fire({
              icon: 'warning',
              title: "Excluir registro",
              text: 'Deseja excluir este registro? Esta ação é permanente.',
              showCancelButton: true,
              confirmButtonText: 'Excluir',
              confirmButtonColor: 'red',
              cancelButtonColor: 'blue',
              cancelButtonText: 'Cancelar'
            }).then(confirm => {
              if (confirm.isConfirmed) {
                // this.service.delet('industria/' + val.id).subscribe(() => this.tableIndustriaDestino._pesquisar())
                // Swal.fire({
                //   title: 'Deletado com sucesso',
                //   text: 'Esse registro foi deletado com sucesso',
                //   icon: 'success',
                //   timer: 2000,
                //   showConfirmButton: false
                // })
              }
            })
          },
          permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
        },
      },
      sort: true,
      filters: {
        group: 'destinador',
        filters: {
          industriaId: {},
          cnpj: {},
          clienteId: {},
        }
      },
      saveQueryParams: {
        group: 'destinador',
        save: true,
        encrypt: false
      }
    })
    http.get('industria/nomes').subscribe(resp => {
      this.listIndustrias = resp.data;
    })
    http.get('clientes/nomes', {params: {unpaged: true, filtrar: true}}).subscribe(resp => {
      this.listGeradores = resp.data;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.table.destroy();
  }

}
