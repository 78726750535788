import { Component, OnInit } from '@angular/core';
import {ContainerEntity} from "../container.entity";
import {ContainerService} from "../service/container.service";

@Component({
  selector: 'app-container-main',
  templateUrl: './container-main.component.html',
  styleUrls: ['./container-main.component.scss']
})
export class ContainerMainComponent implements OnInit {

  public mapEntity = new ContainerEntity()

  constructor(
    public service: ContainerService
  ) {
    this.mapEntity.removeFilters(['ativo'])
  }

  ngOnInit(): void {
  }

}
