import { AbstractEntity, DgFilter_autoComplete, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { ClientesEntity } from "../_clientes/clientes/clientes.entity";
import { SelecionarPlanoEntity } from "../selecionar-plano/selecionar-plano.entity";
import { ReceptoresEntity } from "./receptores-venda.entity";
import { autoCompleteClientesOutPut } from "src/app/helpers/auto-complete/clientes-autocomplete.helper";
import { environment } from "src/environments/environment";
import { autoCompleteFluxoCobrancaClienteOutPut } from "src/app/helpers/auto-complete/fluxo-cobranca-cliente-autocomplete.helper";
import { CLIENTE_FIND_FILTER, PLANOS_FIND_FILTER } from "src/app/core/config/endpoint-list";
import { autoCompleteFluxoCobrancaPlanoOutPut } from "src/app/helpers/auto-complete/fluxo-cobranca-plano-autocomplete.helper";

const planos = [
  { key: 'todos', value: 'Todos' },
  { key: 'true', value: 'Sim' },
  { key: 'false', value: 'Não' },
]
export class FluxoCobrancaEntity extends AbstractEntity {
  constructor(
    id?: number,
    aprovado?: boolean,
    diaVencimentoFatura?: string,
    dataContratacaoPlano?: string,
    nomeCliente?: ClientesEntity,
    planoValor?: SelecionarPlanoEntity,
    metodoPagamento?: string,
    receptorVendaDTO?: ReceptoresEntity[],
    valorPlanoContratado?: number,
    dataInclusao?: number,
  ) {
    super();
    this.id = id;
    this.aprovado = aprovado;
    this.diaVencimentoFatura = diaVencimentoFatura;
    this.dataContratacaoPlano = dataContratacaoPlano;
    this.nomeCliente = nomeCliente;
    this.planoValor = planoValor;
    this.metodoPagamento = metodoPagamento;
    this.receptorVendaDTO = receptorVendaDTO
    this.valorPlanoContratado = valorPlanoContratado;
    this.dataInclusao = dataInclusao;
  }

  @DgFilter_autoComplete({ name: 'CLIENTE' }, {
    getUrl: environment.apiUrl + CLIENTE_FIND_FILTER,
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFilter: 'nome',
    outputFormat: autoCompleteFluxoCobrancaClienteOutPut
  })
  @DgTableColumn({ columnName: 'CLIENTE', resource: (val: ClientesEntity) => !!val.nome ? val.nome : '--' })
  public nomeCliente: ClientesEntity | undefined;

  @DgFilter_autoComplete({ name: 'PLANO' }, {
    getUrl: environment.apiUrl + PLANOS_FIND_FILTER,
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFilter: 'nome',
    outputFormat: autoCompleteFluxoCobrancaPlanoOutPut
  })
  @DgTableColumn({ columnName: 'PLANO', resource: (val: SelecionarPlanoEntity) => !!val?.nomePlano ? val.nomePlano : '--' })
  public planoValor: SelecionarPlanoEntity | undefined;

  @DgTableColumn({ columnName: 'Data Contratação', mask: 'date', sort: true })
  public dataContratacaoPlano: string | undefined;

  @DgTableColumn({
    columnName: 'Valor', resource: (val: number) => {
      return !!val ? val.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) : '--';
    }, width: '4rem', sort: true
  })
  public valorPlanoContratado: number | undefined;

  @DgTableColumn({ columnName: 'Metod. Pagamento', sort: true })
  public metodoPagamento: string | undefined;

  @DgTableColumn({ columnName: 'Dia Vencimento Fatura', sort: true })
  public diaVencimentoFatura: string | undefined;

  @DgTableColumn({ columnName: 'Data Insersão Fluxo Cobrança', mask: 'date', sort: true })
  public dataInclusao: number | undefined

  @DgFilter_select({
    name: 'STATUS DE PAGAMENTO',
    data: planos,
    propertKey: 'key',
    propertValue: 'value',
    defaultData: 'Todos',
    outputFormat: (val: any) => {
      if (val == 'todos') return {};

      return { aprovado: val }
    }
  })
  public aprovado: boolean | undefined;
  public receptorVendaDTO: ReceptoresEntity[] | undefined
}
