import {
  AfterContentChecked, AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input, OnChanges,
  OnInit, Output, SimpleChanges
} from '@angular/core';
import { ChangeDetection } from "@angular/cli/lib/config/workspace-schema";
import { finalize } from "rxjs";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

export interface pickListOutputEvent {
  type: 'add' | 'addAll' | 'remove' | 'removeAll' | 'changAutoComplete' | 'reload' | 'reorder'
  valueSearch: string,
  remove_selected: any[],
  add_selected: any[],
  all_selected: any[],
  all_options: any[]
}

@Component({
  selector: 'pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss']
})
export class PickListComponent implements OnChanges, AfterContentChecked {

  @Input() listaDisponivel: any[] | undefined = [];
  @Input() listaSelecionados: any[] | undefined = [];
  @Input() propertItem: string | string[] | Function | undefined;
  @Input('titleList') titleList!: string;
  @Input() uniClass: string = 'classDefaultPickList';
  @Input() showSearchInput: boolean = false;
  @Input() maxHeight: string | undefined;
  @Input() verifyRemoveItem: Function | undefined;
  @Input() disabled: boolean | { edit: boolean, reorder: boolean } = false;
  @Input() classAllLabel: string | undefined | Function;
  @Input() classSelectLabel: string | undefined | Function;
  @Input() classSelectedLabel: string | undefined | Function;

  public valueInputSearch = '';

  @Output() changeSelect = new EventEmitter<pickListOutputEvent>()

  private _load = true;
  public listaSelect: { check: boolean, text: string, value: any }[] = [];
  public listaSelected: { check: boolean, text: string, value: any }[] = [];

  constructor() {
    if (!!window.document.getElementById('pick-list-component')) {
      throw 'informe uma classe unica ao attr UNICLASS'
    }
  }

  ngAfterContentChecked(): void {
    if (!!this.listaDisponivel) {
      if (this.listaSelect.length == 0 && this.listaDisponivel.length > 0 && this._load) {
        this._load = false;
        this.startPickList();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //@ts-ignore
    if (!!changes?.listaDisponivel) {
      this.listaSelect = [
        //@ts-ignore
        ...this._ajustaLista(changes?.listaDisponivel.currentValue)
      ]
    }

    //@ts-ignore
    if (!!changes?.listaSelecionados) {
      this.listaSelected = [
        ...this.listaSelected || [],
        //@ts-ignore
        ...this._ajustaLista(changes?.listaSelecionados.currentValue)
      ]
    }

    this._clearSelect();

    this.listaDisponivel = undefined;
    this.listaSelecionados = undefined;

  }

  startPickList() {
    this.listaSelect = this._ajustaLista(this.listaDisponivel || []);
    this.listaSelected = this._ajustaLista(this.listaSelecionados || []);
    this._clearSelect();
  }

  public getList(): any[] {
    const result: any[] = [];

    this.listaSelected.map((item) => {
      result.push(item.value)
    })
    return result;
  }

  public clear() {
    this.listaSelected = [];
  }

  public reLoader() {
    this.changeSelect.emit({
      type: 'reload',
      valueSearch: this.valueInputSearch,
      add_selected: [],
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: []
    })
  }

  add() {
    const newListSelected: { check: boolean, text: string, value: any }[] = [];
    const newListSelect: { check: boolean, text: string, value: any }[] = [];

    this.listaSelect.map((item) => {
      if (item.check) {
        item.check = false;
        newListSelected.push(item)
      } else {
        newListSelect.push(item);
      }
    })

    this.listaSelected = [...newListSelected, ...this.listaSelected];
    this.listaSelect = newListSelect;

    this.changeSelect.emit({
      type: 'add',
      valueSearch: this.valueInputSearch,
      add_selected: newListSelect,
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: []
    })
  }

  remove(verified: boolean = false, emitEvent: boolean = true) {
    const newListSelected: { check: boolean, text: string, value: any }[] = [];
    const newListSelect: { check: boolean, text: string, value: any }[] = [];
    const all_remove: { check: boolean, text: string, value: any }[] = [];

    if (!!this.verifyRemoveItem && !verified) {
      const resultVerifyRemoveItem = this.verifyRemoveItem(this.listaSelected.filter(
        (itemSelected: { check: boolean }) => {
          return itemSelected.check
        }
      ))

      if (!resultVerifyRemoveItem) return;
      if (Array.isArray(resultVerifyRemoveItem)) {
        if (resultVerifyRemoveItem?.length == 0) {
          return;
        } else {
          resultVerifyRemoveItem.map(itemRemove => {
            const index = this.listaSelected.findIndex(itemListaSelected => itemListaSelected.text.toUpperCase() == itemRemove.text.toUpperCase())

            if (index == -1) {

            }
          })
        }
      }
    }

    this.listaSelected.map((item) => {
      if (item.check) {
        item.check = false;
        newListSelect.push(item)
        all_remove.push(item)
      } else {
        newListSelected.push(item);
      }
    })

    this.listaSelected = newListSelected;
    this.listaSelect = [...newListSelect, ...this.listaSelect];

    if (!emitEvent) return;

    this.changeSelect.emit({
      type: 'remove',
      valueSearch: this.valueInputSearch,
      add_selected: [],
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: all_remove
    })
  }

  addAllItens() {
    for (let i = 0; i < this.listaSelect.length; i++) {
      const element = this.listaSelect[i];
      this.listaSelected.push(element);
    }
    for (let i = 0; i < this.listaSelect.length; i++) {
      this.listaSelect.splice(i)
    }

    this.changeSelect.emit({
      type: 'addAll',
      valueSearch: this.valueInputSearch,
      add_selected: this.listaSelected,
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: []
    })
  }

  removeAllItems() {
    const all_remove = this.listaSelected;


    for (let i = 0; i < this.listaSelected.length; i++) {
      this.listaSelected[i].check = true
    }
    this.remove(false, false)

    this.changeSelect.emit({
      type: 'removeAll',
      valueSearch: this.valueInputSearch,
      add_selected: [],
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: all_remove
    })

  }

  selectItem(list: any[], subscribe?: Function): boolean {
    if (list.length <= 0) return false;

    list.map((item) => {
      const index = this.listaSelect.findIndex(itm => itm.text.toUpperCase() == this._getPropertItem(item).toUpperCase())

      if (index != -1) {
        this.listaSelect[index].check = true;

        if (!!subscribe) {

          if (!!this.listaSelect[index]?.value) {
            this.listaSelect[index].value = {
              ...this.listaSelect[index].value,
              ...subscribe(item, this.listaSelect[index]?.value)
            }
          }
        }

        this.add();
      } else {
        console.warn('item não encontrado', item)
      }
    })

    return true;
  }

  unSelectItem(list: any[], subscribe?: Function): boolean {
    if (list.length <= 0) return false;

    console.log(list);

    list.map((item) => {
      const index = this.listaSelected.findIndex(itm => itm.text.toUpperCase() == this._getPropertItem(item).toUpperCase())

      if (index != -1) {
        this.listaSelected[index].check = true;

        if (!!subscribe) {

          if (!!this.listaSelected[index]?.value) {
            this.listaSelected[index].value = {
              ...this.listaSelected[index].value,
              ...subscribe(item, this.listaSelected[index]?.value)
            }
          }
        }

        this.remove(true);
      } else {
        console.warn('item não encontrado', item)
      }
    })

    return true;
  }

  private _ajustaLista(list: any[]): { check: boolean, text: string, value: any }[] {
    const newList: any[] = [];
    list.map(item => {
      newList.push({ check: false, text: this._getPropertItem(item), value: item });
    })

    return newList
  }

  private _clearSelect() {
    this.listaSelected.map(item => {
      const index = this.listaSelect.findIndex(i => this._clearString(i.text) === this._clearString(item.text))
      if (index != -1) {
        this.listaSelect.splice(index, 1);
      }
    })
  }

  _clearString(str: string): string {
    if (typeof str !== "string") {
      return 'error';
    }
    str = str.replace(/a|A|á|Á|â|Â|à|À|ä|Ä|ã|Ã/g, 'a')
    str = str.replace(/e|E|é|É|ê|Ê|è|È|ë|Ë/g, 'e')
    str = str.replace(/i|I|í|Í|î|Î|ì|Ì|ï|Ï/g, 'i')
    str = str.replace(/u|U|ú|Ú|û|Û|ù|Ù|ü|Ü/g, 'u')
    str = str.replace(/o|O|ó|Ó|ô|Ô|ò|Ò|ö|Ö|õ|Õ/g, 'o')
    str = str.replace(/c|C|ç|Ç/g, 'ç')
    return str.toUpperCase();
  }

  emitChangeInputSearch(ev: string) {
    this.changeSelect.emit({
      type: 'changAutoComplete',
      valueSearch: ev,
      add_selected: [],
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: []
    })
  }

  private _getPropertItem(item: any): string {
    if (typeof item === "string") return item;

    if (typeof this.propertItem == 'function') {
      return this.propertItem(item);
    }

    if (typeof this.propertItem === 'string') {
      if (this.propertItem in item) {
        return item[this.propertItem]
      }

      return item;
    }

    if (Array.isArray(this.propertItem)) {
      return this._getValueForArrayLevel(item)
    }

    return String(item)
  }

  _getValueForArrayLevel(objSelected: object): string {
    let propertArray: string[] = [];

    if (
      typeof this.propertItem === 'string' ||
      typeof this.propertItem === 'function' ||
      !this.propertItem
    ) throw 'propert de autocompleteComponent não é um array'
    propertArray = this.propertItem;

    let result: any = objSelected;

    propertArray.map((nivelArray: string) => {
      if (!!result) {
        if (nivelArray in result) {
          result = result[nivelArray];
        }
      }
    })

    return result;
  }

  getClassLabel(type: 'classAllLabel' | 'classSelectedLabel' | 'classSelectLabel', itemVal: any): string {
    if (!this[type]) return '';

    const classLabel  = this[type];

    if (typeof classLabel == 'string') return classLabel;

    if (typeof classLabel == 'function') return classLabel(itemVal)

    return '';
  }

  dropAction(ev: CdkDragDrop<any>) {
    moveItemInArray(this.listaSelected, ev.previousIndex, ev.currentIndex);

    this.changeSelect.emit({
      type: 'reorder',
      valueSearch: this.valueInputSearch,
      add_selected: [],
      all_selected: this.listaSelected,
      all_options: this.listaSelect,
      remove_selected: []
    })
  }

  verifyDisabled(type: 'edit' | 'reorder'): boolean {
    if (typeof this.disabled == 'boolean') return this.disabled;

    if (type in this.disabled) {
      return this.disabled[type];
    }

      return false;
  }
}
