import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelecionarPlanoPontualComponent } from './selecionar-plano-pontual/selecionar-plano-pontual.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    SelecionarPlanoPontualComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    DgCrudModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule
  ]
})
export class SelecionarPlanoPontualModule { }
