<div
    id="nota-fiscal-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Notas Fiscais</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        style="background-color: #0d6efd"
                        [entityMap]="mapEntity"
                        [table]="tableNotaFiscal"
                        tableEventId="notaFiscalTable"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableNotaFiscal
                        [entityMap]="mapEntity"
                        [service]="service"
                        [showPagination]="false"
                        [register]="true"
                        tableEventId="notaFiscalTable"
                    ></crud-data-table>
                    <div class="d-flex justify-content-center card-footer">
                        <dg-pagination
                            [dataPagination]="mapEntity.configEntityTable.pagination"
                            (changePage)="tableNotaFiscal.changePages($event)"
                        ></dg-pagination>
                    </div>
                </div>
            </card>
        </div>
    </div>
</div>




<page-footer>
    <a routerLink="novo">
        <button class="btn btn-success">Novo</button>
    </a>
    <button
        (click)="baixarPlanilha()"
        class="btn btn-primary"
    >
        Baixar Planilha
    </button>
    <button
        (click)="opemConfigurar()"
        class="btn btn-primary"
    >
        Configurar
    </button>



</page-footer>



<old-dg-modal
    #configurar
    size="md"
    title="Configurações para aviso do estoque"
>
    <div dg-content>
        <div>
            <p class="card-subtitle mb-4 fs-10">
                * Os dados estão sendo salvos altomaticamente
            </p>
        </div>
        <table class="crud-table">



            <thead>
                <tr>
                    <th style="width: 50%">Produto</th>
                    <th style="width: 50%">Quantidade</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prod of listProdConfig; let indexProd = index">
                    <td>{{prod.nome}}</td>
                    <td>
                        <input
                            type="text"
                            mask="0*"
                            class="form-control"
                            [(ngModel)]="prod.quantidadeProdutoAviso"
                            (ngModelChange)="editConfigurar($event, prod?.id, indexProd)"
                        >
                        <span
                            [hidden]="!produto[indexProd]?.salvo"
                            class="fc-green-medio"
                        >
                            salvando...
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer">
            <dg-pagination
                [dataPagination]="mapProdEntity.configEntityTable.pagination"
                (changePage)="changePage($event)"
            ></dg-pagination>
        </div>
    </div>
    <div dg-footer>
        <button
            class="btn btn-secondary m-1"
            (click)="configurar.close()"
        >
            Sair
        </button>
    </div>
</old-dg-modal>
