import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractSideFilter, dataSeideFilter} from "../cdk/abstract-side-filter";
import {OldModalComponent} from "../../ui/modal/modal/modal.component";
import {ClientesEntity} from "../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../pages/_user/_galpao/galpao/galpao.entity";
import {TipoLixoEntity} from "../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {GalpaoMotoristaEntity} from "../../../pages/_user/_galpao/galpao-motorista/galpao-motorista.entity";
import {GenericCrudService} from "../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../core/services/session-service/session.service";
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_OPERADOR_LOGISTICO, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
  DASHBOARD_FILTER_UNIDADE_GERADORA
} from "../../../core/config/endpoint-list";
import {ajusteDateByPaste} from "../../../helpers/helpers";
import {CloseAutocomplete, InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {FormControl, Validators} from "@angular/forms";
import Swal from "sweetalert2";

declare type typeFavorito = 'DASHBOARD_COLETAS' | 'DASHBOARD_ESTOQUE' | 'RANKING_ATERRO_ZERO' | 'RANKING_TAXA_RECICLAGEM'

@Component({
  selector: 'modal-favoritos-insert-edit',
  templateUrl: './favoritos-insert-edit.component.html',
  styleUrls: ['./favoritos-insert-edit.component.scss']
})
export class FavoritosInsertEditComponent extends AbstractSideFilter implements OnInit {

  @Output('save') saveEvent = new EventEmitter<'DASHBOARD_COLETAS' | 'DASHBOARD_ESTOQUE' | 'RANKING_ATERRO_ZERO' | 'RANKING_TAXA_RECICLAGEM'>();

  @ViewChild('modal') modal!: OldModalComponent;
  objParamName = 'coletas';
  typeFavorito: typeFavorito = 'DASHBOARD_COLETAS'
  saveDisabled = false;

  nomeFavorito = new FormControl('', [Validators.required])

  @Input() override listsPreSelected: {
    clientes: any[],
    estados: any[],
    galpoes: any[],
    motoristas: any[],
    tipoLixos: any[],
    // favoritos: string
  } = {
    clientes: [],
    estados: [],
    galpoes: [],
    motoristas: [],
    tipoLixos: [],
    // favoritos: ''
  }
  listSelected: {
    clientes: any[],
    estados: any[],
    galpoes: any[],
    motoristas: any[],
    tipoLixos: any[]
  } = {
    clientes: [],
    estados: [],
    galpoes: [],
    motoristas: [],
    tipoLixos: []
  }
  public _dataFilter = {
    clientes: <any[]>[],
    estados: <any[]>[],
    galpoes: <any[]>[],
    motoristas: <any[]>[],
    tipoLixos: <any[]>[],
    dataInicio: '',
    dataFim: '',
    ativo: '',
    perfilUnidade: ''
  };
  listItensDisponiveis: {
    clientes: ClientesEntity[],
    estados: { name: string, uf: string }[],
    galpao: GalpaoEntity[],
    tipoLixos: TipoLixoEntity[],
    motoristas: GalpaoMotoristaEntity[],
  } = {
    clientes: [],
    estados: [],
    galpao: [],
    tipoLixos: [],
    motoristas: []
  }

  timeout: any = null;

  constructor(
    public service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    super(service, router, route);
  }

  ngOnInit(): void {
  }

  //*************************** METODOS PARA O ABSTRACT ***************************//

  setInitParams(params?: any, preValues?: any): void {
    if (!!params) {
      this.listsPreSelected = {
        ...this.listsPreSelected,
        ...preValues.listsPreSelected
      };
      this.listSelected = {
        ...this.listSelected,
        ...preValues.listSelected
      };
      this._dataFilter = {
        ...this._dataFilter,
        ...preValues._dataFilter
      };
    }

    this.ajustDataByPerfil(true)
    this.getDataAutocompletes();

    // setTimeout(() => this.setQueryParams(), 10)
  }

  ajsutDataSetParams(preValues: object) {
    const {ativo} = this._dataFilter;
    const result: any = preValues;
    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;

    return result;
  }

  clearFilter(preValues: dataSeideFilter) {
    this._dataFilter = {
      ...preValues._dataFilter
    }
    this.listSelected = {
      ...preValues.listSelected
    }
    this.listsPreSelected = {
      ...preValues.listsPreSelected
    }
    this.ajustDataByPerfil()
    this.updateDataAutocompletes('all')
  }

  ajsutDataEmiter(preValues: object) {
    return preValues;
  }

  //*************************** METODOS LOCAIS ***************************//

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  verifyChangeData(tipo: 'dataFim' | 'dataInicio' | 'perfilUnidade'): void {
    if (tipo == 'perfilUnidade') {
      return
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const date = new Date(tipo == 'dataFim' ? this._dataFilter.dataFim : this._dataFilter.dataInicio);

      if (!isNaN(date.valueOf())) {
        if (date.getTime() >= new Date(new Date('1995-12-17T03:24:00')).getTime()) {
          // this.setQueryParams()
        }
      } else if (this._dataFilter[tipo] == '') {
        // this.setQueryParams();
      }
    }, 300);
  }

  ajustDataByPerfil(initFilter = false) {
    if (this.session.checkPerfil('GALPAO')) {
      const galpao = {id: this.session.user.galpao_id, nome: this.session.user.galpao_nome};

      if (initFilter) {
        this.listsPreSelected.galpoes = [galpao]
      }
      this.listSelected.galpoes = [galpao]
      this._dataFilter.galpoes = [galpao.id]
    } else if (this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EVENTO', 'EMPRESA'])) {

      const unidade = {id: this.session.user.cliente_id, nome: this.session.user.cliente_nome || '--'};

      if (initFilter) {
        this.listsPreSelected.clientes = [unidade]
      }
      this.listSelected.clientes = [unidade]
      this._dataFilter.clientes = [unidade.id]
    }
  }

  getDataAutocompletes() {
    let {
      unidades,
      galpao,
      tipoLixos
    } = <{ unidades: string, galpao: string, tipoLixos: string }>this.preAjustDataEmiter();

    if (!this.session.checkPerfil('GALPAO')) {
      this._service.get(
        DASHBOARD_FILTER_OPERADOR_LOGISTICO,
        {
          params: {
            unpaged: true,
            ...(!!unidades ? {unidades} : {}),
            ...(!!tipoLixos ? {tipoLixoId: tipoLixos} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.galpao = resp.data;

          this.listItensDisponiveis.galpao = this.listItensDisponiveis.galpao.sort(
            (a: GalpaoEntity, b: GalpaoEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA'])) {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
            ...(!!galpao ? {galpao} : {}),
            ...(!!galpao ? {operadores: galpao} : {}),
            ...(!!tipoLixos ? {tipoLixoId: tipoLixos} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.clientes = resp.data;

          this.listItensDisponiveis.clientes = this.listItensDisponiveis.clientes.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this._service.get('motoristas/nomes',
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.motoristas = resp.data
      }
    )
    this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {}),
            ...(!!unidades ? {clienteId: unidades} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.tipoLixos = resp.data;
      }
    )

    let {estados} = <{ galpao: string, estados: string }>this.preAjustDataEmiter();

    this.service.get(CLIENTE_ESTADOS,
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {}),
            ...(!!estados ? {estados: estados} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.estados = resp.data
      }
    )
  }

  updateDataAutocompletes(origin: 'galpoes' | 'clientes' | 'motoristas' | 'tipoLixos' | 'all') {
    let {
      unidades,
      galpao,
      tipoLixos
    } = <{ unidades: string, galpao: string, tipoLixos: string }>this.preAjustDataEmiter();

    if (!this.session.checkPerfil('GALPAO') && origin != 'galpoes') {
      this._service.get(
        DASHBOARD_FILTER_OPERADOR_LOGISTICO,
        {
          params: {
            unpaged: true,
            ...(!!unidades ? {unidades} : {}),
            ...(!!unidades ? {operadores: unidades} : {}),
            ...(!!tipoLixos ? {tipoLixoId: tipoLixos} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.galpao = resp.data;

          this.listItensDisponiveis.galpao = this.listItensDisponiveis.galpao.sort(
            (a: GalpaoEntity, b: GalpaoEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA']) && origin != 'clientes') {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
            ...(!!galpao ? {galpao} : {}),
            ...(!!galpao ? {operadores: galpao} : {}),
            ...(!!tipoLixos ? {tipoLixoId: tipoLixos} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.clientes = resp.data;


          this.listItensDisponiveis.clientes = this.listItensDisponiveis.clientes.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (origin == 'galpoes') {
      this._service.get('motoristas/nomes',
        {
          params:
            {
              unpaged: true,
              ...(!!galpao ? {galpaoId: galpao} : {})
            }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.motoristas = resp.data
        }
      )
    }

    if (origin == 'galpoes' || origin == 'clientes' || origin == 'all') {
      this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
        {
          params:
            {
              unpaged: true,
              ...(!!galpao ? {galpaoId: galpao} : {}),
              ...(!!unidades ? {clienteId: unidades} : {})
            }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.tipoLixos = resp.data;
        }
      )
    }

  }

  public ajustePasteDate = (ev: any, varName: 'dataInicio' | 'dataFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)

  setAutoComplete(data: CloseAutocomplete, identificador: 'galpoes' | 'clientes' | 'motoristas' | 'tipoLixos' | 'estados') {
    if (this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this._dataFilter[identificador] = this.listSelected[identificador].map(item => item.id)
    } else {
      this.listSelected[identificador] = [...data.selectedList];
    }

    if (identificador == 'galpoes' || identificador == 'clientes' || identificador == 'tipoLixos') {
      this.updateDataAutocompletes(identificador)
    }
  }

  @ViewChild('modalFavoritosInsertEdit') modalIsetEdit!: OldModalComponent;

  @ViewChild('autoGalpao') autoGalpao!: InputAutocompleteComponent;
  @ViewChild('autoUnidade') autoUnidade!: InputAutocompleteComponent;
  @ViewChild('autoMotorista') autoMotorista!: InputAutocompleteComponent;
  @ViewChild('autoResiduo') autoResiduo!: InputAutocompleteComponent;
  @ViewChild('autoEstados') autoEstados!: InputAutocompleteComponent;

  private id: number | string | undefined;

  openNew(type: typeFavorito) {
    this.typeFavorito = type
    this.setInitParams()
    this.modal.open();
  }

  open(typeFavorito: typeFavorito, d: any) {

    if (d?.id) {
      this.service.get('favoritos/'+d.id).subscribe(resp => {

        const data = resp.data;

        this.nomeFavorito.patchValue(data.nome)
        this.typeFavorito = data.tipoFavorito;
        this.id = data.id;

        this.setInitParams()

        this._dataFilter = {
          ...this._dataFilter,
          clientes: <any[]>[],
          estados: <any[]>[],
          galpoes: <any[]>[],
          motoristas: <any[]>[],
          tipoLixos: <any[]>[],
          dataInicio: '',
          dataFim: '',
          ativo: '',
          perfilUnidade: ''
        }

        const mapAutocompletes: {
          [key: string]: 'autoGalpao' | 'autoUnidade' | 'autoMotorista' | 'autoResiduo' | 'autoEstados'}
          = {
          galpoes: 'autoGalpao',
          clientes: 'autoUnidade',
          motoristas: 'autoMotorista',
          tipoLixos: 'autoResiduo',
          estados: 'autoEstados',
        }

        Object.keys(data).map((key) => {
          if (Array.isArray(data[key])) {
            if (data[key].length > 0) {
              if (key in mapAutocompletes) {
                this[mapAutocompletes[key]].selectedList = data[key] || [];
                const value = this[mapAutocompletes[key]].selectedList.map(item => this[mapAutocompletes[key]].dataManage.getKey(item))
                this[mapAutocompletes[key]].dataManage.setValue(value)

                this[mapAutocompletes[key]].eventManage.close()
              }
            }
          } else if (['dataInicio', 'dataFim', 'ativo', 'perfilUnidade'].includes(key)){
            this._dataFilter[<'dataInicio' | 'dataFim' |  'ativo' | 'perfilUnidade'>key] = data[key]
          }
        })

        this.modal.open();
      })
    }
  }

  beforeSave(): boolean {
    if (this.nomeFavorito.invalid) {
      this.nomeFavorito.markAllAsTouched()
      return false;
    }

    let existValue = false
    Object.values(this.listSelected).map(value => {
      if (existValue) return;
      if (typeof value == 'string') {
        existValue = !!value;
      } else if (Array.isArray(value)) {
        existValue = value.length > 0
      }
    })

    if (!existValue) {
      alert('seleciona ao menos um valor');
      return false;
    }

    return true;
  }

  afterClose = () => {
    this.nomeFavorito.reset('');
    this.id = undefined;
    this._dataFilter = {
      clientes: <any[]>[],
      estados: <any[]>[],
      galpoes: <any[]>[],
      motoristas: <any[]>[],
      tipoLixos: <any[]>[],
      dataInicio: '',
      dataFim: '',
      ativo: '',
      perfilUnidade: ''
    }
    this.listSelected = {
      clientes: [],
      estados: [],
      galpoes: [],
      motoristas: [],
      tipoLixos: []
    }
    this.listsPreSelected = {
      clientes: [],
      estados: [],
      galpoes: [],
      motoristas: [],
      tipoLixos: []
    }
  }

  salvar() {
    if (!this.beforeSave()) return;
    if (this.saveDisabled) return;

    const {dataFim, dataInicio, ativo, perfilUnidade} = this._dataFilter;

    const result = {
      id: this.id,
      tipoFavorito: this.typeFavorito,
      nome: this.nomeFavorito.value,
      dataInicio,
      dataFim,
      ativo,
      perfilUnidade,
      ...this.listSelected,
    }

    if (this.typeFavorito == 'RANKING_TAXA_RECICLAGEM') {
      if (!result?.perfilUnidade) {
        Swal.fire({
          icon: 'error',
          title: 'O tipo de perfil não pode ser em branco',
          text: 'Selecione um "Tipo de Perfil (Gerador)"'
        }).then();
        return;
      }
    }
    this.saveDisabled = true;

    this.service[!!this.id ? 'put' : 'post']('favoritos', result).subscribe(resp => {
      this.saveEvent.emit(this.typeFavorito)
      this.modal.close()
      this.saveDisabled = false;
    }, () => {
      this.saveDisabled = false;
    })

    // if (!this.id) {
    //   favoritosList.push({
    //     ...result,
    //     id: favoritosList.length + 1,
    //   })
    // } else {
    //   const index = favoritosList.findIndex((item) => item.id == this.id)
    //
    //   if (index == -1) return;
    //
    //   favoritosList.splice(index,1, {
    //     ...result,
    //     id: this.id,
    //   })
    // }
    //
    // objList[this.typeFavorito] = favoritosList;
    //
    // window.localStorage.setItem('listFavorites', '--')
    // window.localStorage.setItem('listFavorites', JSON.stringify(objList));
    //
    // this.saveEvent.emit()
    // this.modal.close()
  }

  verifyVisible(type: typeFavorito | typeFavorito[]): boolean {
    if (typeof type == 'string') {
      return type == this.typeFavorito
    }

    return type.includes(this.typeFavorito)
  }
}
