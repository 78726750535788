import { Component, OnInit } from '@angular/core';
import { GalpaoMotoristaEntity } from '../galpao-motorista.entity';
import { GalpaoMotoristaService } from '../service/galpao-motorista.service';
import {crudDispatchEvent} from "@datagrupo/dg-crud";
import Swal from "sweetalert2";

@Component({
  selector: 'app-galpao-motorista-main',
  templateUrl: './galpao-motorista-main.component.html',
  styleUrls: ['./galpao-motorista-main.component.scss']
})
export class GalpaoMotoristaMainComponent implements OnInit {

  public mapEntity = new GalpaoMotoristaEntity();

  constructor(
    public service: GalpaoMotoristaService
  ) {

    this.mapEntity.addActions([
      {
        name: 'Excluir',
        action: (row: GalpaoMotoristaEntity) => {
         Swal.fire({
           icon: 'question',
           title: 'Deseja excluir esse registro?'
         }).then(conf => {
           if (conf.isConfirmed) {
             service.delet('/motoristas/'+row.id).subscribe(resp => {
               crudDispatchEvent('galpaoMotoristaTable')

               Swal.fire({
                 icon: 'success',
                 title: 'Registro removido com sucesso'
               }).then()

             })
           }
         })
        },
        permission: (row:GalpaoMotoristaEntity) => !row.anonimo
      }
    ])
  }

  ngOnInit(): void {
  }

}
