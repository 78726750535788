import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OldModalComponent} from "../../ui/modal/modal/modal.component";
import {SessionService} from "../../../core/services/session-service/session.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClientesEntity} from "../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../pages/_user/_galpao/galpao/galpao.entity";
import {TipoLixoEntity} from "../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {GalpaoMotoristaEntity} from "../../../pages/_user/_galpao/galpao-motorista/galpao-motorista.entity";
import {
  DASHBOARD_FILTER_OPERADOR_LOGISTICO, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
  DASHBOARD_FILTER_UNIDADE_GERADORA
} from "../../../core/config/endpoint-list";
import {HttpOldService} from "../../../core/services/http/http-old.service";
import {AbstractSideFilter, dataSeideFilter} from "../cdk/abstract-side-filter";
import {GenericCrudService} from "../../../core/services/generic-crud-service/generic-crud.service";
import {ajusteDateByPaste} from "../../../helpers/helpers";
import {CloseAutocomplete} from "@datagrupo/dg-ng-util";
import {FavoritosInsertEditComponent} from "../favoritos-insert-edit/favoritos-insert-edit.component";

@Component({
  selector: 'subcomponent-sidebar-modal-favorite',
  templateUrl: './modal-favorite.component.html',
  styleUrls: ['./modal-favorite.component.scss']
})
export class ModalFavoriteComponent implements OnInit {

  @ViewChild('modalFavoritos') modal!: OldModalComponent;
  @ViewChild('modalFavoritosInsertEdit') modalIsetEdit!: FavoritosInsertEditComponent;

  listFavorites: any[] = [];
  tableVisible: 'coletas' | 'estoque' | 'Ranking-aterro'| 'Ranking-taxa' = 'coletas'

  public listPreselect: {
    coletas: any
  } = {
    coletas: undefined
  }

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    unidade: new FormControl(''),
    galpao: new FormControl(''),
  })

  constructor(
    private route: ActivatedRoute,
    public session: SessionService,
    private service: HttpOldService
  ) { }

  ngOnInit(): void {}

  open() {
    if (!!window.localStorage.getItem('listFavorites')) {
      this.listFavorites = <any[]> (JSON.parse(window.localStorage.getItem('listFavorites') || '[]') || [])
    } else {
      this.listFavorites = [];
    }

    if (this.route.snapshot.queryParams['coletas']) {
      const params = JSON.parse(this.route.snapshot.queryParams['coletas']) || {};

      if (!!params) {
        console.log(params)
        this.listPreselect.coletas = params;
      }
    }

    this.modal.open();
  }

  importFavoritosColeta() {
    if (!!this.route.snapshot.queryParams['coletas']) {
      const params = JSON.parse(this.route.snapshot.queryParams['coletas']) || {};

      // this.modalIsetEdit.open();

      // if (!!window.localStorage.getItem('listFavorites')) {
      //   this.preselectedList.unidades = <any[]> (JSON.parse(window.localStorage.getItem('listFavorites') || '') || [])
      // }

      // this.listFavorites.push({ id: this.listFavorites.length + 1, ...params })
      //
      // window.localStorage.setItem('listFavorites', '--')
      // window.localStorage.setItem('listFavorites', JSON.stringify(this.listFavorites));
    }
  }

  novoFavoritoColeta() {
    // this.getDataAutocompletes()
    // this.modalIsetEdit.open();
  }

  save(fav: any) {
    console.log(fav)

    if (!!window.localStorage.getItem('listFavorites')) {
      this.listFavorites = <any[]> (JSON.parse(window.localStorage.getItem('listFavorites') || '') || [])
    }

    this.listFavorites.push({ id: this.listFavorites.length + 1, ...fav })

    window.localStorage.setItem('listFavorites', '--')
    window.localStorage.setItem('listFavorites', JSON.stringify(this.listFavorites));
  }

  createFavorite() {
    if (!!this.route.snapshot.queryParams['coletas']) {
      const params = JSON.parse(this.route.snapshot.queryParams['coletas']) || {};

      if (!!window.localStorage.getItem('listFavorites')) {
        this.listFavorites = <any[]> (JSON.parse(window.localStorage.getItem('listFavorites') || '') || [])
      }

      this.listFavorites.push({ id: this.listFavorites.length + 1, ...params })

      window.localStorage.setItem('listFavorites', '--')
      window.localStorage.setItem('listFavorites', JSON.stringify(this.listFavorites));
    }
  }

  getListPropert(
    fav: any,
    propert: 'unidades' | 'estados' | 'galpao' | 'motorista' | 'tipoResiduo' | 'dataInicio'
  ): string {
    const result = Array.isArray(fav[propert]) ? fav[propert] : [];

    if (result.length <= 0) return '--';

    return result.map((item: any) => item.nome).join(', ')
  }

  afterClose = () => {
    this.listPreselect = {
      coletas: undefined
    }
  }
}
