import { AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { TipoLixoCompostoEntity } from "./tipo-lixo-composto.entity";
import { environment } from "../../../../environments/environment";
import { TIPO_LIXO, TIPO_LIXO_FIND_FILTER } from "../../../core/config/endpoint-list";
import {  GrupoMercadoEntity } from "./grupo-merccado.entity";
import { SegmentoMercadoMercadoEntity } from "./segmento-mercado.entity";
import { SegmentosOperacionaisEntity } from "./segmentos-operacionais.entity";
import { TipoLixoClasses } from "./tipo-lixo-classes.entity";
import { TipoLixoCodigosConama } from "./tipo-lixo-codigosConama";
import {UnidadeMedidaEntity} from "../unidade-medida/unidade-medida.entity";

@DataServer({
  path: environment.apiUrl,
  context: TIPO_LIXO
})
export class TipoLixoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    categoria?: string,
    classe?: string,
    classesResiduos?: TipoLixoClasses[],
    codigoOnu?: string,
    codigosConama?: TipoLixoCodigosConama[],
    codigoUnidade?: string,
    codigo?: string,
    segmentoMercado?: SegmentoMercadoMercadoEntity,
    segmentosOperacionais?: SegmentosOperacionaisEntity[],
    grupoMercado?: GrupoMercadoEntity,
    densidade?: number,
    listTipoLixoComposto?: TipoLixoCompostoEntity[],
    unidadesMedida?: UnidadeMedidaEntity[]
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.categoria = categoria;
    this.codigoOnu = codigoOnu;
    this.codigosConama = codigosConama;
    this.codigoUnidade = codigoUnidade;
    this.classesResiduos = classesResiduos;
    this.classe = classe;
    this.codigo = codigo;
    this.densidade = densidade;
    this.grupoMercado = grupoMercado;
    this.segmentoMercado = segmentoMercado;
    this.segmentosOperacionais = segmentosOperacionais;
    this.listTipoLixoComposto = !!listTipoLixoComposto ? listTipoLixoComposto : [];
  }

  @DgFilter_autoComplete(
    { name: 'Descrição do tipo de resíduo' },
    {
      getServerData: 'all',
      getUrl: environment.apiUrl + TIPO_LIXO_FIND_FILTER,
      propertKey: 'id',
      propertValue: 'nome',
      outputFormat: (value: string, row: TipoLixoEntity) => {
        if (!row) {
          return { tipoLixoNome: value }
        }
        return { tipoLixoId: row.id }
      }
    }
  )
  @DgTableColumn({ columnName: 'Descrição do tipo de resíduo', sort: true })
  public nome: string | undefined;

  @DgFilter_input('text', { name: 'Categoria Ecoplat' })
  @DgTableColumn({ columnName: 'Categoria Ecoplat', sort: true })
  public categoria: string | undefined;

  @DgFilter_input('text', { name: 'ABNT 10.004' })
  @DgTableColumn({ columnName: 'ABNT 10.004', sort: true })
  public classesResiduos: TipoLixoClasses[] | undefined;

  @DgFilter_input('text', { name: 'Código IBAMA' })
  @DgTableColumn({ columnName: 'Código IBAMA', sort: true })
  public codigo: string | undefined;

  public classe: string | undefined;
  public densidade: number | string | undefined;
  public codigoOnu: number | string | undefined;
  public codigosConama: TipoLixoCodigosConama[] | undefined;
  public codigoUnidade: number | string | undefined;
  public grupoMercado: GrupoMercadoEntity | undefined;
  public segmentoMercado:  SegmentoMercadoMercadoEntity | undefined;
  public segmentosOperacionais:  SegmentosOperacionaisEntity[] | undefined;
  public listTipoLixoComposto: TipoLixoCompostoEntity[];
  public unidadesMedida: UnidadeMedidaEntity[] = [];
}
