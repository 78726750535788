import {AbstractEntity, DataServer, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import {environment} from "../../../../environments/environment";
import {TRATAMENTO} from "../../../core/config/endpoint-list";

@DataServer({
  path: environment.apiUrl,
  context: TRATAMENTO
})
export class TratamentoEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    nome?: string,
    aterroZero?: boolean,
    descricao?: string
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.aterroZero = aterroZero
    this.descricao = descricao;
  }

  @DgFilter_input('text', { colum: '6', nameParamFind: 'tratamentoNome' })
  @DgTableColumn({ columnName: 'Nome', sort: true })
  public nome: string | undefined;


  aterroZero: boolean | undefined;
  descricao: string | undefined;
  reciclagem: string | undefined;

}
