import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import {InterfaceMenuList, NavigationComponent} from "../navigation/navigation.component";
import {OldModalComponent} from "../../../../shared/ui/modal/modal/modal.component";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {Router} from "@angular/router";

@Component({
  selector: 'subComponent-modal-pesquisar',
  templateUrl: './modal-pesquisar.component.html',
  styleUrls: ['./modal-pesquisar.component.scss']
})
export class ModalPesquisarComponent implements OnInit {

  @ViewChild('modalPesquisa') modal!: OldModalComponent
  @ViewChild('navigation') nav!: NavigationComponent;

  private stopwatchPressShift: number | null = null;
  public currentSelectItem: number | undefined;
  public tabIndex: number | undefined = undefined;
  private prefixNavigationn = 'modal-pesquisar_';

  public dataModalPesquisa: {
    _menuList: InterfaceMenuList[],
    menuList: InterfaceMenuList[],
    inputMenu: string
  } = {
    _menuList: [],
    menuList: [],
    inputMenu: ''
  }

  constructor(private session: SessionService, private router: Router) {
  }

  ngOnInit(): void {
    window.addEventListener('keyup', (e) => {
      const currentKeyPress = Date.now()

      if (e.key == 'Shift') {
        if (!!this.stopwatchPressShift) {

          if (currentKeyPress - this.stopwatchPressShift < 200) {
            this.openModal();
            this.stopwatchPressShift = null;
          } else {
            this.stopwatchPressShift = currentKeyPress;
          }
        } else {
          this.stopwatchPressShift = currentKeyPress;
        }
      }
    })

    document.querySelectorAll('ul.sidebar-navigation').forEach((el) =>
      el.addEventListener('click', (event) => {
        this.modal.close()
      })
    );

    window.document.getElementById('modal-pesquisa')?.addEventListener('keydown', e => {
      if (e.key == 'Enter') {
        // alert(this.tabIndex)
        if (this.tabIndex != undefined) {
          if (!!this.dataModalPesquisa.menuList[this.tabIndex]) {
            if (e.ctrlKey) {
              const url = this.router.createUrlTree([this.dataModalPesquisa.menuList[this.tabIndex].url])
              window.open(url.toString(), '_blank');
            } else {
              this.router.navigate([this.dataModalPesquisa.menuList[this.tabIndex].url])
            }
            this.modal.close();
          }
        }
      }

      if (e.key == 'ArrowDown') {
        e.preventDefault();
        this.focusDown();
      }

      if (e.key == 'ArrowUp') {
        e.preventDefault();
        this.focusUp();
      }
    })
  }

  findInputMenu(input: string) {
    if (!input) {
      this.dataModalPesquisa.menuList = this.dataModalPesquisa._menuList;
    }

    const newData: InterfaceMenuList[] = [];

    this.dataModalPesquisa._menuList.map(d => {

      if (this._clearString(d.nome).match(new RegExp(`${this._clearString(input)}.*$`, 'g'))) {
        newData.push(d)
      }
    })

    this.dataModalPesquisa.menuList = newData;
  }

  _clearString(str: string): string {
    if (typeof str !== "string") {
      return 'error';
    }
    str = str.replace(/a|A|á|Á|â|Â|à|À|ä|Ä|ã|Ã/g, 'a')
    str = str.replace(/e|E|é|É|ê|Ê|è|È|ë|Ë/g, 'e')
    str = str.replace(/i|I|í|Í|î|Î|ì|Ì|ï|Ï/g, 'i')
    str = str.replace(/u|U|ú|Ú|û|Û|ù|Ù|ü|Ü/g, 'u')
    str = str.replace(/o|O|ó|Ó|ô|Ô|ò|Ò|ö|Ö|õ|Õ/g, 'o')
    str = str.replace(/c|C|ç|Ç/g, 'ç')

    return str.toUpperCase();
  }

  @HostListener('window:open-modal-search-sistem')
  openModal() {
    const resultMenu: InterfaceMenuList[] = [];
    this.session.menu.get()[1].menuList.map(item => {
      if (!!item.subMenu) {
        item.subMenu.map(subItem => {
          resultMenu.push(subItem)
        })
        return;
      }

      resultMenu.push(item)
    });

    this.dataModalPesquisa._menuList = resultMenu;
    this.dataModalPesquisa.menuList = resultMenu;
    this.modal.open();
    setTimeout(() => {
      window.document.getElementById('input-search-modal-sistem')?.focus();
    }, 50)
  }

  beforeClose = () => {
    this.dataModalPesquisa.inputMenu = '';
    this.tabIndex = undefined;
  }

  keyDown(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      if (!!this.dataModalPesquisa.menuList[0]) {
        this.router.navigate([this.dataModalPesquisa.menuList[0].url])
        this.modal.close()
      }
      return;
    }
  }

  focusDown() {
    const tabIndex = this.tabIndex == undefined ? 0 : this.tabIndex + 1;

    if (!this.dataModalPesquisa.menuList[tabIndex]) return;

    // @ts-ignore
    const idElement = this.prefixNavigationn + this.dataModalPesquisa.menuList[tabIndex]?.url.replace(/\//g, '_')

    console.log(window.document.getElementById(idElement))

    window.document.getElementById(idElement)?.focus();

    this.tabIndex = tabIndex;
  }

  focusUp() {
    if (this.tabIndex == 0) {
      window.document.getElementById('input-search-modal-sistem')?.focus();
      this.tabIndex = undefined;
      return;
    }

    if (this.tabIndex == undefined) return;

    const tabIndex = this.tabIndex - 1;

    if (!this.dataModalPesquisa.menuList[tabIndex]) return;

    // @ts-ignore
    const idElement = this.prefixNavigationn + this.dataModalPesquisa.menuList[tabIndex]?.url.replace(/\//g, '_')

    console.log(window.document.getElementById(idElement))

    window.document.getElementById(idElement)?.focus();

    this.tabIndex = tabIndex;
  }
}
