<div
  id="nota-fiscal-insert-edit"
  *ngIf="!!entity"
>
  <div class="container-data">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Dados da Nota Fiscal</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Transportador
                    <span class="fc-red">*</span>
                  </label>
                  <input-auto-complete
                    propertValue="nome"
                    controlName="galpao"
                    urlServer="galpoes/nomes"
                    [service]="galpaoServ"
                    (outValue)="entity.galpao = $event"
                  ></input-auto-complete>
                </div>


                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Industria Destino
                    <span class="fc-red">*</span>
                  </label>
                  <input-auto-complete
                    propertValue="nome"
                    controlName="industria"
                    urlServer="industria/nomes"
                    [service]="industriaDestServ"
                    (outValue)="entity.industria = $event"
                  ></input-auto-complete>
                </div>


                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Chave
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    [validation]="false"
                    type="text"
                    class="form-control"
                    formControlName="chaveAcesso"
                    id="chave"
                    mask="0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000"
                  >
                </div>
              </div>


              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Numero da Nota
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="numero"
                    id="numero"
                    mask="000.000.000"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Data Nota
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="date"
                    (paste)="ajustePasteDate($event)"
                    formControlName="dataNota"
                    class="form-control"
                    id="dataNota"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">
                    Data Validade
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="date"
                    [value]="entity.validadeNota"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </form>
  </div>


  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Produtos</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    (click)="modal.open()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">


            <crud-data-table
              select-server-data="array"
              tableEventId="produtoFiscalEntity"
              #tableProdutoNotaFiscal
              [entityMap]="produtoFiscalEntity"
              [data]="entity.produtos"
              [customActions]="customActionsProdutos"
            >
            </crud-data-table>


          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="produtoFiscalEntity.configEntityTable.pagination"
              (changePage)="tableProdutoNotaFiscal.changePages($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>


  <div
    class="container-data"
    *ngIf="!!entity.id"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Arquivos da Nota Fiscal</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    (click)="docInput.click()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
            <input
              #docInput
              type="file"
              name="imgMotorista"
              id="imgMotorista"
              accept=".pdf, .xml"
              style="display: none"
              [(ngModel)]="docFile"
              (change)="uploadDocumento($event)"
            >
          </div>


          <div class="card-body">
            <crud-data-table
              #tableDocumentos
              [service]="serviceDoc"
              [entityMap]="entityDocumentos"
              [showPagination]="false"
            ></crud-data-table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="entityDocumentos.configEntityTable.pagination"
              (changePage)="tableDocumentos.changePages($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    dg-desable-by-period
    [backAfterSave]="false"
    crud-salvar
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
    crud-deletar
    class="btn btn-secondary"
  >
    Excluir
  </button>
</page-footer>


<modal-produto-nota-fiscal
  #modal
  (salvar)="salvarProdutos($event)"
></modal-produto-nota-fiscal>
