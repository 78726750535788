import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { GalpaoArquivoMotoristaEntity } from '../galpao-arquivo-motorista.entity';
import {ARQUIVO_MOTORISTA} from "../../../../../core/config/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class GalpaoArquivoMotoristaService extends AbstractHttpService<GalpaoArquivoMotoristaEntity> {

  constructor(
    public conf: ConfigDgCrudService,
    public HttpServ: HttpOldService
  ) {
   super(conf, environment.apiUrl, ARQUIVO_MOTORISTA)
  }

  download(id: number) {
    return this.findOne(Number(id), '');
  }

  uploadImage(data: any) {
    return this.save(data, 'imagem');
  }
}
