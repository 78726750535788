import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotaFiscalMainComponent } from './nota-fiscal-main/nota-fiscal-main.component';
import { NotaFiscalInsertEditComponent } from './nota-fiscal-insert-edit/nota-fiscal-insert-edit.component';
import {DgCrudModule} from "@datagrupo/dg-crud";
import {UiModule} from "../../../../shared/ui/ui.module";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import { ProdutoNotaFiscalComponent } from './subComponents/produto-nota-fiscal/produto-nota-fiscal.component';
import { DirectivesModule } from 'src/app/core/directives/directives.module';



@NgModule({
  declarations: [
    NotaFiscalMainComponent,
    NotaFiscalInsertEditComponent,
    ProdutoNotaFiscalComponent
  ],
  imports: [
    CommonModule,
    DgCrudModule,
    UiModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatIconModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    DirectivesModule
  ]
})
export class NotaFiscalModule { }
