import { Component, OnInit } from '@angular/core';
import {listaTutoriais} from "../tutorial-itens";

@Component({
  selector: 'app-tutorial-main',
  templateUrl: './tutorial-main.component.html',
  styleUrls: ['./tutorial-main.component.scss']
})
export class TutorialMainComponent implements OnInit {

  public listTutorial: {
    title: string,
    list: {
      img: string,
      passos: string[]
    }[]
  }[] = listaTutoriais;

  constructor() { }

  ngOnInit(): void {
  }

  numberStep(
    tutor: { title: string; list: { img: string; passos: string[] }[] }, indexItemTutor: number, indexPassos: number
  ): string | number {
    if (tutor.list.length == 1 || indexItemTutor == 0) {
      return (indexPassos + 1)
    }
    let result = 0;

    for(let i=0; i<indexItemTutor; i++) {
      result = result + tutor.list[i].passos.length;
    }

    return (result + +indexPassos + 1);
  }

}
