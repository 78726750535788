import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RotasMainComponent} from './rotas-main/rotas-main.component';
import {DgCrudModule} from '@datagrupo/dg-crud';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {UiModule} from 'src/app/shared/ui/ui.module';
import {RotasInsertEditComponent} from './rotas-insert-edit/rotas-insert-edit.component';
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
  declarations: [
    RotasMainComponent,
    RotasInsertEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormsModule,
    DragDropModule
  ]
})
export class RotasModule {
}
