import { Injectable } from '@angular/core';
import {AbstractGenericService} from "@datagrupo/dg-ng-util";
import {HttpClient} from "@datagrupo/dg-crud";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService extends AbstractGenericService {

  constructor(protected Http: HttpClient) {
    super(Http, environment.apiUrl)
  }

  /**
   * Metodo para modificação do path sem perder os outros processos
   */
  public customPath(path?: string) {
    const clone = Object.create(Object.getPrototypeOf(this));
    Object.assign(clone, this);

    const cloneFinal = <HttpService>clone;

    cloneFinal.changePath(path || environment.apiUrlMock);

    return cloneFinal
  }

  override get(context: string, options?: any, noteCallbackMessage: boolean = false): Observable<any> {
    return super.get(context, {
      ...options,
      headers: {
        ...(options?.headers || {}),
        ...(noteCallbackMessage ? {noteCallbackMessage: '1'} : {}),
      }
    });
  }
}
