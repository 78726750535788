import {Component, OnDestroy, OnInit} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {COLETA_RANKING_ATERRO} from "../../../../core/config/endpoint-list";
import Swal from "sweetalert2";
import {HttpService} from "../../../../services/http-service/http.service";

@Component({
  selector: 'app-ranking-lixo-zero',
  templateUrl: './ranking-lixo-zero.component.html',
  styleUrls: ['./ranking-lixo-zero.component.scss']
})
export class RankingLixoZeroComponent implements OnInit, OnDestroy {

  tableLixoZero: CdkDynamicTable.tableClass;
  finishLoader = false;

  constructor(
    private createTable: CdkDynamicTableService,
    private activatedRoute: ActivatedRoute,
    private http: HttpService
  ) {
    window.dispatchEvent(new CustomEvent('togle-enable-btn-print', { detail: false }))
    this.tableLixoZero = createTable.create('array', {
      apiData: {
        path: environment.apiUrl,
        context: COLETA_RANKING_ATERRO
      },
      pagination: {
        sort: []
      },
      columns: [
        {
          name: 'posicao', headerName: 'POSIÇÃO', resource: (val: number | string) => {
            return '<span style="font-size: 13pt; font-weight: bolder; color: rgba(148,159,57,.8)">' + val + '</span>'
          }
        },
        {name: 'clienteNome', headerName: 'NOME'},
        {
          name: 'pontuacaoRanking', headerName: 'TAXA DE ATERRO ZERO (%)', resource: (val: number) => {
            return '<span style="padding: .3rem .7rem; background: rgba(148,159,57,.8); color: white; font-size: 14pt; border-radius: 25px">' +
              val.toFixed(1) +
              "% </span>"
          }
        },
      ],
      filters: { group: 'ranking-taxa', reactive: true }
    })

    if (!activatedRoute.snapshot.queryParams['rankings']) {
      Swal.fire({
        icon: 'error',
        title: 'Dados de impressão não encontrados'
      }).then(() => {
        window.close()
      })
    } else {
      let params = JSON.parse(activatedRoute.snapshot.queryParams['rankings'])

      if (!!params['pagination']) {
        const {totalElements, totalPages, typePagination, ...rest} = params['pagination'];
        params = {
          ...params,
          ...rest
        }
      } else {
        this.tableLixoZero.controls.pagination.set({size: 1000})
      }

      http.get(COLETA_RANKING_ATERRO, { params }).subscribe(resp => {
        this.tableLixoZero.controls.data.set(resp.data)
        this.finishLoader = true;
        this.tableLixoZero.find()
      })

      this.tableLixoZero.observables.dataSource.subscribe((resp) => {
        if (resp.type == 'end' && this.finishLoader) {
          setTimeout(() => {
            window.dispatchEvent(new CustomEvent('togle-enable-btn-print', { detail: true }))
            window.print();
          }, 100)
        }
      })
    }
  }


  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tableLixoZero.destroy()
  }
}
