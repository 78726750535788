<div class="page-footer {{classes}}" [class.sidebar-opened]="sidebaOpened()">
  <div class="conteiner h-100">
    <div class="row h-70">

      <div class="col-lg-7 col-md-12 row-btn">
        <ng-content></ng-content>
      </div>
    </div>

<!--    <div class="row h-30">-->
<!--      <div class="col-12 copyright">-->
<!--        Copyright © 2022 <a [href]="urlMinhaColeta" target="_blank">Ecoplat</a>.-->
<!--        Desenvolvido por <a href="http://www.datagrupo.com.br" target="_blank">DATAGRUPO</a>.-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
