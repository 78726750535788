import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-home',
  templateUrl: './public-home.component.html',
  styleUrls: ['./public-home.component.scss']
})
export class PublicHomeComponent implements OnInit {

  public listCardsSection1: { text: string, textButton: string, classButton: String, link: string, queryParams?: object }[] = [
    {
      text: 'Coleta para unidades geradoras',
      textButton: 'CADASTRE-SE',
      classButton: 'btn-success',
      link:'/solicitacao-cadastro',
      queryParams: {perfil: 'unidade_geradora'}
    },
    {
      text: 'Projetos de Logística Reversa',
      textButton: 'COMPRE CRÉDITOS',
      classButton: 'btn-success',
      link:'compre-credito-logistica-reversa'
    },
    {
      text: ' Transportador',
      textButton: 'CADASTRE-SE',
      classButton: 'btn-success',
      link:'/solicitacao-cadastro',
      queryParams: {perfil: 'operador_logistico'}
    },
    {
      text: 'Indústria recicladora',
      textButton: 'BREVE',
      classButton: 'btn-warning',
      link:''
    },
  ];
  public listCardsSection5: { img: string, title: string, text: string}[] = [
    {
      img: '/assets/img/site/section-5/geral-01.png',
      title: ' As unidades geradoras, através de uma assinatura, acessam nosso sistema de gestão, engajamento, benefícios e encontram operadores logísticos ideais para a sua coleta.',
      text: 'Os usuários e funcionários se engajam através de missões e assim acessam diversos benefícios.'
    },
    {
      img: '/assets/img/site/section-5/geral-02.png',
      title: 'Os operadores logísticos conseguem criar e gerenciar rotas de maneira inteligente prezando a escala da coleta.',
      text: 'Eles registram o recolhimento do resíduo e levam para operadores logísticos parceiros cadastrados.'
    },
    {
      img: '/assets/img/site/section-5/geral-03.png',
      title: 'Os destinadores recicladoras conseguem comprar materiais de todos os nossos operadores através de um único processo de compliance, logística e pagamento.',
      text: 'Com mão de obra de ex-catadores oferecem condições melhores de trabalho e qualidade de vida para a categoria.'
    },
    {
      img: '/assets/img/site/section-5/geral-04.png',
      title: 'Depois de separado, os volumes são lançados em nosso sistema.',
      text: 'Também apoiamos a venda dos materiais para indústria recicladora garantindo altos padrões de qualidade deste resíduo.'
    },
    {
      img: '/assets/img/site/section-5/geral-05.png',
      title: ' A receita da venda fica para os operadores logísticos para bancar a operação.',
      text: ''
    },
    {
      img: '/assets/img/site/section-5/geral-06.png',
      title: 'Nosso processo é rastreado de ponta a ponta.',
      text: 'Assim possibilitamos a comercialização dos créditos de logística reversa e a criação de programas de logística reversa gamificados e baratos para marcas fabricantes aproximando-as das unidades geradoras cadastrados e seus usuários através das missões e desafios sustentáveis'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
