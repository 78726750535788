import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';

@Component({
  selector: 'app-jornada-cupom',
  templateUrl: './jornada-cupom.component.html',
  styleUrls: ['./jornada-cupom.component.scss']
})
export class JornadaCupomComponent implements OnInit {


  data: any;

  constructor(
    public route: ActivatedRoute,
    public http: HttpOldService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
    let cupomId: number;
    cupomId = this.route.snapshot.params["id"];
    this.http.get(`missoes/cupom/${cupomId}`).subscribe(resp => {
      //@ts-ignore
      this.data = resp.data;
    })
  }

  resgatarCupom() {
    this.http.post(`missoes/conquistas/resgatar-cupom/${this.data.missaoId}`, this.data.missaoId).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        window.open(resp.data, "_blank")
      }
    });
  }

}
