<div id="pergunta-condominio">
    <div class="container-data">
        <div class="row">
            <div class="col-12">
                <card>
                    <mat-stepper linear #stepper>
                        <mat-step [stepControl]="formPerfilInicial" #stepInicial>
                            <form [formGroup]="formPerfilInicial">
                                <ng-template matStepLabel>PERFIL INICIAL</ng-template>
                                <div class="row d-flex align-items-end">
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">VOCÊ SERÁ O RESPONSÁVEL FINANCEIRO PELA
                                            ASSINATURA:</label>
                                        <select name="responsavelFinanceiro" id="responsavelFinanceiro"
                                            class="form-select" formControlName="responsavelFinanceiro">
                                            <option value="" disabled>Selecione</option>
                                            <option [value]="true">Sim</option>
                                            <option [value]="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12" >
                                        <label class="form-label">DISPONIBILIDADE DE HORÁRIOS NOS DIAS DA COLETA
                                            DOMICILIAR:</label>
                                        <input type="text" min="0" class="form-control"
                                            formControlName="horarioColeta">
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12" >
                                        <label class="form-label">QUANTIDADE APROXIMADA DE USUÁRIOS:</label>
                                        <input type="number" min="0" class="form-control"
                                            formControlName="qtdMoradores">
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">NOME COMPLETO:</label>
                                        <input type="text" class="form-control" formControlName="razaoSocial">
                                    </div>

                                </div>
                                <div class="pt-3 d-flex justify-content-end container">
                                    <button class="btn btn-secondary" style="margin-right: 1em"
                                        matStepperPrevious>Anterior</button>
                                    <button class="btn btn-primary " matStepperNext
                                        (click)="proximoStepper(stepInicial, stepper)">Próximo</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formPlanosPrecos" #stepPlano>
                            <form [formGroup]="formPlanosPrecos">
                                <ng-template matStepLabel>PLANOS E PREÇOS</ng-template>

                                <div class="row d-flex align-items-end">
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label" for="local">PLANO FECHADO NA COLETA
                                            DOMICILIAR:</label>
                                        <select name="local" id="local" class="form-select"
                                            formControlName="planoColetaDomiciliar">
                                            <option value="" disabled>Selecione</option>
                                            <option [value]="true">Sim</option>
                                            <option [value]="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">CPF:</label>
                                        <input type="text" mask="000.000.000-00" class="form-control"
                                            formControlName="cpf">
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">RG:</label>
                                        <input type="text" mask="00.000.000-0" class="form-control"
                                            formControlName="rg">
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">NOME DO RESPONSÁVEL POR EXECUTAR O PAGAMENTO:</label>
                                        <input type="text" class="form-control" formControlName="nomeResponsavel">
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">E-MAIL DO RESPONSÁVEL POR EXECUTAR O
                                            PAGAMENTO:</label>
                                        <input type="text" class="form-control" formControlName="emailResponsavel">
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                        <label class="form-label">DATA LIMITE PARA EMISSÃO DE NOTA FISCAL:</label>
                                        <select name="dataLimite" id="dataLimite" class="form-select"
                                            formControlName="dataLimite">
                                            <option value="" selected disabled>Selecione</option>
                                            <option *ngFor="let data of listDias" value="{{data}}">
                                                {{data}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="pt-3 d-flex justify-content-end container">
                                    <button class="btn btn-secondary" style="margin-right: 1em"
                                        matStepperPrevious>Anterior</button>
                                    <button class="btn btn-primary " matStepperNext
                                        (click)="proximoStepper(stepPlano, stepper)">Próximo</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formAssinatura">
                            <form [formGroup]="formAssinatura">
                                <ng-template matStepLabel>ASSINATURA</ng-template>
                                <div class="row">
                                    <label class="form-label">ESCOLHA SUA ASSINATURA:</label>
                                    <mat-select class="form-select" formControlName="assinatura" multiple required>
                                        <mat-option *ngFor="let topping of optAssinaturas"
                                            [value]="topping.key">{{topping.value}}</mat-option>
                                    </mat-select>
                                </div>
                            </form>
                            <div class="pt-3 d-flex justify-content-end container">
                                <button class="btn btn-success" (click)="salvarPerguntas()">Salvar</button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </card>
            </div>
        </div>
    </div>

</div>