<old-dg-modal
  #modalDocumentos
  size="md"
  title="Dados do Arquivo"
  [beforeClose]="close"
>
  <div dg-content class="modal-galpao-documentos">
    <div class="row">
      <div *ngIf="!!docName" class="col-12">
        <label class="form-label">Nome do arquivo</label>
        <table class="crud-table">
          <tr>
            <td>{{ docName }}</td>
          </tr>
        </table>
      </div>

      <div *ngIf="!edit" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-btn">
        <button (click)="uploadDocumento_click()" class="btn btn-success w-100">
          Escolher
        </button>
        <input
          #docInput
          type="file"
          name="imgMotorista"
          id="imgMotorista"
          accept=".pdf"
          style="display: none"
          [(ngModel)]="docFile"
          (change)="uploadDocumento($event)"
        />
      </div>
      <div>
        <div *ngIf="edit" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-btn">
          <button (click)="download()" class="btn btn-success w-100">
            Download
          </button>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="categoria" class="form-label"
          >Categoria <span class="fc-red">*</span></label
        >
        <select
          [formControl]="categoriaArquivo"
          class="form-select"
          id="categoria"
        >
          <option value="" disabled>Selecione uma categoria</option>
          <option *ngFor="let categ of categoriasList" value="{{ categ.id }}">
            {{ categ.nome }}
          </option>
        </select>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="categoria" class="form-label"
          >Vencimento <span class="fc-red">*</span></label
        >
        <input
          type="date"
          [formControl]="vencimento"
          (paste)="ajustePasteDate($event)"
          class="form-select"
          id="vencimento"
        />
      </div>
    </div>
  </div>
  <div dg-footer>
    <button (click)="saveOrUpdate()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modalDocumentos.close()" class="btn btn-secondary m-1">
      Sair
    </button>
  </div>
</old-dg-modal>
