import { LogisticaReversaEntity } from './../logistica-reversa.entity';
import { Component, OnInit } from '@angular/core';
import { LogisticaReversaService } from '../service/logistica-reversa.service';
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';

@Component({
  selector: 'app-logistica-reversa-main',
  templateUrl: './logistica-reversa-main.component.html',
  styleUrls: ['./logistica-reversa-main.component.scss']
})
export class LogisticaReversaMainComponent implements OnInit {

  public mapEntity = new LogisticaReversaEntity()

  constructor(
    public service: LogisticaReversaService
  ) {
  }
  ngOnInit(): void {
  }
}
