import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, Validators} from "@angular/forms";
import {ArquivosClientesEntity} from "../../../_entitys/arquivos-clientes.entity";
import {ArquivosClientesService} from "../../../arquivo-clientes/service/clientes-service/arquivos-clientes.service";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";
import {ajusteDateByPaste, formata_data_utc_br, formataData, verifySizeFiles} from "../../../../../../helpers/helpers";

@Component({
  selector: 'subComponent-arquivos-cliente-modal',
  templateUrl: './arquivos-cliente-modal.component.html',
  styleUrls: ['./arquivos-cliente-modal.component.scss']
})
export class ArquivosClienteModalComponent implements OnInit {

  @ViewChild('modalDocumentos') modal!: OldModalComponent;

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.dataPagamento.patchValue(text))

  public idArquivo: number | undefined;
  @Input() idCliente: number | string | undefined;

  @Output() atualizarTabela = new EventEmitter<any>();

  public dataPagamento: FormControl = new FormControl('', [Validators.required])
  public pago: FormControl = new FormControl(false)
  public arquivo: File | undefined;
  public inputArquivo: File | undefined;
  public entityArquivo: ArquivosClientesEntity | undefined
  public arquivoName: string | undefined;

  constructor(private service: ArquivosClientesService) {
  }

  ngOnInit(): void {
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.arquivo = undefined;
        return;
      }

      this.arquivoName = file.name
      this.arquivo = file
    }
  }

  @HostListener('window:open-modal-arquivo-cliente', ['$event'])
  public open(ev?: CustomEvent) {
    if (!!ev) {
      if (ev?.detail?.id) {
        this.idArquivo = ev.detail.id;

        this.service.findOne(ev.detail.id, '').subscribe(
          resp => {
            console.log(resp);
            // this.dataPagamento.patchValue(this.formataData())
            this.entityArquivo = ev?.detail;
            this.dataPagamento.patchValue(formata_data_utc_br(ev?.detail.dataPagamento).split('/').reverse().join('-'))
            this.pago.patchValue(ev?.detail.pago)
            this.modal.open();
          }
        );
        return;
      }

    }

    this.modal.open();


    // if (ev?.detail?.id) {
    //   this.service.findOne(ev.detail.id, '').subscribe(
    //     resp => {
    //       console.log(resp);
    //       // this.dataPagamento.patchValue(this.formataData())
    //     }
    //   )
    // }
  }

  // formataData(dataString: string) {
  //   var data = new Date(dataString),
  //     dia = data.getDate().toString(),
  //     diaF = (dia.length == 1) ? '0' + dia : dia,
  //     mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  //     mesF = (mes.length == 1) ? '0' + mes : mes,
  //     anoF = data.getFullYear();
  //   return anoF + "-" + mesF + "-" + diaF;
  // }

  salvar() {
    if (this.dataPagamento.invalid || (!this.idArquivo && !this.arquivoName)) {
      this.dataPagamento.markAllAsTouched()
      return;
    }

    const data = {
      clienteId: this.idCliente,
      id: this.idArquivo,
      arquivo: this.arquivo,
      dataPagamento: this.dataPagamento.valid,
      pago: this.pago.value
    }

    var formData = new FormData();

    formData.append('clienteId', String(this.idCliente));
    formData.append('dataPagamento', String(new Date(this.dataPagamento.value)));
    formData.append('pago', String(!!this.pago.value));

    if (!!this.idArquivo) formData.append('id', String(this.idArquivo));
    if (!!this.arquivo) formData.set('arquivo', this.arquivo);


    if (!formData.get('id')) {
      this.service.save(formData).subscribe(() => this.afterSaveFile())
    } else {
      this.service.update(formData, '').subscribe(() => this.afterSaveFile())
    }

    //@ts-ignore
    // this.service.saveOrUpdate(formData).subscribe(
    //   resp => {
    //     this.atualizarTabela.emit();
    //     // window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: "ArquivosClientesEntity"}));
    //     this.modal.close();
    //   }
    // )
  }

  afterSaveFile() {
    this.atualizarTabela.emit();
    // window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: "ArquivosClientesEntity"}));
    this.modal.close();
  }

  afterClose = (e?: any) => {
    this.dataPagamento.reset('');
    this.pago.reset();
    this.arquivo = undefined;
    this.arquivoName = undefined;
    this.idArquivo = undefined;
    this.inputArquivo = undefined;
    this.entityArquivo = undefined;
    return true
  }

  download(file: ArquivosClientesEntity) {
    if (!file?.id) {
      throw "Id do arquivo não encontrado"
    }

    this.service.downloadFile(file.id);
  }
}
