import { Component, OnInit, ViewChild } from '@angular/core';
import { CLIENTE } from 'src/app/core/config/endpoint-list';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { GestaoJornadaEducacaoAmbientalEntity } from '../gestao-jornada-educacao-ambiental.entity';
import { GestaoJornadaEducacaoAmbientalService } from '../service/gestao-jornada-educacao-ambiental.service';

@Component({
  selector: 'app-gestao-jornada-educacao-ambiental-main',
  templateUrl: './gestao-jornada-educacao-ambiental-main.component.html',
  styleUrls: ['./gestao-jornada-educacao-ambiental-main.component.scss']
})
export class GestaoJornadaEducacaoAmbientalMainComponent implements OnInit {

  @ViewChild('modalGestaoJornadaEducacaoAmbiental') modalGestaoJornadaEducacaoAmbiental!: OldModalComponent;
  @ViewChild('pickList') pickList!: PickListComponent;

  public mapEntity = new GestaoJornadaEducacaoAmbientalEntity();
  listaClientes: any[] = [];
  listaClienteSelecionados: any[] = [];
  entityId: string | number | undefined;

  public tableParams: { tipoMissao?: number | string } = {};

  constructor(
    public service: GestaoJornadaEducacaoAmbientalService,
    public http: HttpOldService,
    public fileService: FilesManageService
  ) {
    this.mapEntity.addActions([
      {
        name: 'Convidar',
        action: (row: GestaoJornadaEducacaoAmbientalEntity) => { this.open(row) }
      }
    ])
    this.tableParams = {tipoMissao: "MORADOR"}
  }

  ngOnInit(): void {
  }

  open(data: GestaoJornadaEducacaoAmbientalEntity | undefined) {
    this.entityId = data?.id;
    this.mapEntity.nome = data?.nome

    this.service.get(CLIENTE, { params: { unpaged: true } }).subscribe(resp => {
      //@ts-ignore
      this.listaClientes = resp.data

      this.modalGestaoJornadaEducacaoAmbiental.open()
    })
  }

  public closeModal = () => {
    this.listaClientes = [];
    this.listaClienteSelecionados = [];
    this.pickList.clear();
  }

  convidar() {
    let listaClientesId: any[] = [];
    listaClientesId = this.pickList.getList().map(item => item.id);
    this.http.get(`missao/clientes/convidar?clientes=${listaClientesId}&missaoId=${this.entityId}`).subscribe(resp => {
      if (resp) {
        Swal.fire({
          title: 'E-mail enviado com sucesso',
          icon: 'success',
          timer: 5000,
          showConfirmButton: false
        });
        this.modalGestaoJornadaEducacaoAmbiental.close()
      }
    })
  }

  baixarPlanilha() {
    let listaClientesId: any[] = [];
    listaClientesId = this.pickList.getList().map(item => item.id);
    this.http.get(`missao/clientes/planilha?clientes=${listaClientesId}&missaoId=${this.entityId}`).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.fileService.downloadBase64(resp.data, 'planilha clientes.xls');
        this.modalGestaoJornadaEducacaoAmbiental.close()
      }
    })
  }


}
