import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { NgxMaskModule } from "ngx-mask";
import { UiModule } from "src/app/shared/ui/ui.module";
import { FluxoCobrancaInsertComponent } from "./fluxo-cobranca-insert/fluxo-cobranca-insert.component";
import { FluxoCobrancaMainComponent } from "./fluxo-cobranca-main/fluxo-cobranca-main.component";
import { MatStepperModule } from '@angular/material/stepper';
import { DirectivesModule } from "src/app/core/directives/directives.module";


@NgModule({
  declarations: [
    FluxoCobrancaMainComponent,
    FluxoCobrancaInsertComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule, 
    MatStepperModule,
    DirectivesModule
  ]
})
export class FluxoCobrancaModule { }
