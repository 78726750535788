<div id="cobranca-edit" class="container" *ngIf="!!entity">
  <div class="row">
    <form [formGroup]="form">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4>Informações da Cobrança</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <label class="form-label">VENCIMENTO <span class="fc-red">*</span></label>
                <input type="date" (paste)="ajustePasteDate($event, 'vencimento')" formControlName="vencimento" class="form-control">
              </div>
              <div class="col-4">
                <label class="form-label">PAGAMENTO <span class="fc-red">*</span></label>
                <input type="date" (paste)="ajustePasteDate($event, 'pagamento')" class="form-control" formControlName="pagamento">
              </div>
              <div class="col-4">
                <label class="form-label">STATUS <span class="fc-red">*</span></label>
                <select class="form-select" formControlName="status">
                  <option selected>Selecione um status</option>
                  <option *ngFor="let status of listStatus" value="{{status.key}}">{{status.value}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="form-label">CLIENTE <span class="fc-red">*</span></label>
                <input type="text"  class="form-control" formControlName="cliente">
              </div>
            </div>
          </div>
        </card>
      </div>
    </form>

  </div>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-deletar class="btn btn-secondary">Excluir</button>
</page-footer>
