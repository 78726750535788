import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";

@DataServer({
    path: environment.apiUrlMock,
    context: 'indicadores-ods'
})

export class IndicadoresOdsEntity extends AbstractEntity {

    constructor(
        id?: number | string,
        nome?: string,
        unidadeGeradora?: string,
        ano?: string,
        verificarTaxas?: string,
    ) {

        super();
        this.id = id;
        this.nome = nome;
        this.unidadeGeradora = unidadeGeradora;
        this.ano = ano;
        this.verificarTaxas = verificarTaxas;
    };


    @DgFilter_input('text', { colum: '4', name: 'NOME' })
    @DgTableColumn({ columnName: 'NOME' })
    public nome: string | undefined

    @DgTableColumn({ columnName: 'GERADOR' })
    public unidadeGeradora: string | undefined

    @DgTableColumn({ columnName: 'ANO' })
    public ano: string | undefined

    @DgTableColumn({ columnName: 'VERIFICAR TAXAS' })
    public verificarTaxas: string | undefined


}
