import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicLayoutModule} from "./public/public-layout.module";
import {UserLayoutModule} from "./user/user-layout.module";
import {PrintLayoutModule} from "./print-layout/print-layout.module";
import {DocLayoutComponent} from "./doc-layout/doc-layout.component";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    DocLayoutComponent
  ],
  imports: [
    CommonModule,
    PublicLayoutModule,
    UserLayoutModule,
    PrintLayoutModule,
    RouterModule,
  ]
})
export class LayoutsModule { }
