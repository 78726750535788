import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CdkDynamicGroupModule, CdkDynamicTableModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {PrintRoutingModule} from "./print-routing.module";
import {FormsModule} from "@angular/forms";
import {DashboardImpressaoModule} from "./dashboard-impressao/dashboard-impressao.module";



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    PrintRoutingModule,
    DgTableModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    FormsModule,
    DashboardImpressaoModule,
  ]
})
export class ImpressaoModule { }
