import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParceiroMainComponent } from './parceiro-main/parceiro-main.component';
import { ParceiroInsertEditComponent } from './parceiro-insert-edit/parceiro-insert-edit.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import {DirectivesModule} from "../../../core/directives/directives.module";
import { MatButtonModule } from '@angular/material/button';
import { HoldingCadastroEntity } from './cadastro-holding.entity';
import { CadastroHoldingComponent } from './subComponent/cadastro-holding/cadastro-holding.component';
import {MatSelectModule} from "@angular/material/select";



@NgModule({
  declarations: [
    ParceiroMainComponent,
    ParceiroInsertEditComponent,
    CadastroHoldingComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        DgCrudModule,
        ReactiveFormsModule,
        FormsModule,
        DirectivesModule,
        MatButtonModule,
        MatSelectModule
    ]
})
export class ParceiroModule { }
