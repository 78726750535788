import { HistoricoColetasInsertEditComponent } from './historico-coletas-insert-edit/historico-coletas-insert-edit.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricoColetasMainComponent } from './historico-coletas-main/historico-coletas-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [
    HistoricoColetasMainComponent,
    HistoricoColetasInsertEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormsModule,
    MatMenuModule

  ]
})
export class HistoricoColetasModule { }
