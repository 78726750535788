import { Component, OnInit } from '@angular/core';
import { IndicadoresEntity } from '../indicadores.entity';
import { IndicadoresService } from '../service/indicadores.service';

@Component({
  selector: 'app-indicadores-main',
  templateUrl: './indicadores-main.component.html',
  styleUrls: ['./indicadores-main.component.scss'],
})
export class IndicadoresMainComponent implements OnInit {
  public mapEntity = new IndicadoresEntity();

  constructor(public service: IndicadoresService) {}

  ngOnInit(): void {
  }
}
