<div id="system-return-message-column" *ngIf="listMessages.length > 0">
  <div
    *ngFor="let item of listMessages; let i = index"
    class="toast show {{item.status}}" role="alert" aria-live="assertive" aria-atomic="true"
  >
    <div class="toast-header">
      <strong class="me-auto">{{item.title}}</strong>
<!--      <small class="text-muted">just now</small>-->
      <button class="btn-close" (click)="close(i)"></button>
    </div>
    <div class="toast-body">
      {{item.message}}
    </div>
  </div>
</div>
