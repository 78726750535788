import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobrancasMainComponent } from './cobrancas-main/cobrancas-main.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CobrancasEditComponent } from './cobrancas-edit/cobrancas-edit.component';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";



@NgModule({
  declarations: [
    CobrancasMainComponent,
    CobrancasEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    SweetAlert2Module
  ]
})
export class CobrancasModule { }
