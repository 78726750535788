<div class="container-fluid mt-5 pick-list-component" id="{{uniClass}}">
  <div class="row">
    <div class="col-5 col-list-select-pickList">
      <label class="label-title" style="justify-content: center;">{{titleList}} Disponíveis</label>

      <div class="w-100 pb-2" *ngIf="showSearchInput">
        <input
          type="text"
          [(ngModel)]="valueInputSearch"
          (ngModelChange)="emitChangeInputSearch($event)"
          class="form-control w-100"
          placeholder="Buscar..."
        >
      </div>

      <ul class="list-group">
        <li
          (click)="inputCheckboxHidden.click()"
          *ngFor="let cliente of listaSelect; let i = index"
          class="list-group-item {{getClassLabel('classSelectLabel', cliente.value)}} {{getClassLabel('classAllLabel', cliente.value)}}"
        >
          <div class="form-check">

            <input class="form-check-input" type="checkbox" [checked]="inputCheckboxHidden.checked">

            <input
              #inputCheckboxHidden
              hidden
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="cliente.check"
              id="lista-disponivel-select-{{uniClass}}-{{i}}"
              [disabled]="verifyDisabled('edit')"
            >

            <label class="form-check-label">
              {{cliente.text}}
            </label>
          </div>
        </li>

      </ul>
    </div>

    <div class="col-2" style="margin-top: 3em;">
      <ul class="list-group">
        <button class="btn btn-light mb-2" [disabled]="verifyDisabled('edit')" matTooltip="adicionar" matTooltipPosition="right"
                (click)="add()">
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button class="btn btn-light mb-2" [disabled]="verifyDisabled('edit')" matTooltip="adicionar todos" matTooltipPosition="right"
                (click)="addAllItens()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
        <button class="btn btn-light mb-2" [disabled]="verifyDisabled('edit')" matTooltip="remover" matTooltipPosition="right"
                (click)="remove()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button class="btn btn-light mb-2" [disabled]="verifyDisabled('edit')" matTooltip="remover todos" matTooltipPosition="right"
                (click)="removeAllItems()">
          <mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
      </ul>
    </div>

    <div class="col-5 col-list-select-pickList">
      <label class="label-title">{{titleList}} Selecionados</label>
      <ul class="list-group" cdkDropList [cdkDropListDisabled]="verifyDisabled('reorder')">
        <li
          cdkDrag
          (click)="inputCheckboxSelectedHidden.click()"
          *ngFor="let cliente of listaSelected; let i = index"
          class="list-group-item {{getClassLabel('classSelectedLabel', cliente.value)}} {{getClassLabel('classAllLabel', cliente.value)}}"
          (cdkDragDropped)="dropAction($event)"
        >
          <div class="form-check">

            <input class="form-check-input" type="checkbox" [checked]="inputCheckboxSelectedHidden.checked">

            <input
              #inputCheckboxSelectedHidden
              hidden
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="cliente.check"
              id="lista-disponivel-selected-{{uniClass}}-{{i}}"
              [disabled]="verifyDisabled('edit')"
            >
            <label class="form-check-label">
              {{cliente.text}}
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
