import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doc-navs',
  templateUrl: './doc-navs.component.html',
  styleUrls: ['./doc-navs.component.scss']
})
export class DocNavsComponent implements OnInit {

  public tableVisible = 'opcao-1';

  constructor() { }

  ngOnInit(): void {
  }

}
