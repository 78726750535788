<old-dg-modal #modal title="Favoritos" [afterClose]="clear">
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label>Nome de identificação do filtro</label>
          <input formControlName="nome" name="nomeFavorito" class="form-control">
        </div>
      </div>

      <div class="row">
        <div *ngIf="controlles.permissionFilters.includes('perfilUnidade')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Tipo de Perfil (Gerador)</label>
          <select
            class="form-select form-select-sm"
            formControlName="perfilUnidade"
            (change)="loaderFilterList('perfilUnidade')"
          >
            <option disabled value="">Selecione um perfil</option>
            <option value="EMPRESA">Empresa</option>
            <option value="CONDOMINIO">Condomínio</option>
            <option value="EVENTO">Evento</option>
            <option value="CASA">Casa</option>
          </select>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('unidades')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Unidades Geradoras</label>
          <dg-autocomplete3
            #AutoC_unidades
            key="id" label="nome" [multiple]="true"
            classes="form-control form-control-sm"
            formControlName="unidades"
            [data]="controlles.filtersOptions['unidades']"
            (change)="loaderFilterList('unidades')"
          ></dg-autocomplete3>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('transportadores')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Operadores Logísticos</label>
          <dg-autocomplete3
            key="id" label="nome" [multiple]="true"
            classes="form-control form-control-sm" formControlName="galpao"
            [data]="controlles.filtersOptions['transportador']"
            dg-dynamic-group="estoque" paramName="transportador" typeComunication="change"
            (change)="loaderFilterList('transportadores')"
          ></dg-autocomplete3>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('motoristas')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Motorista/Catador</label>
          <dg-autocomplete3
            key="id" label="nome" [multiple]="true" (change)="loaderFilterList('motoristas')"
            classes="form-control form-control-sm" formControlName="motorista"
            [data]="controlles.filtersOptions['motoristas']"
          ></dg-autocomplete3>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('tipoResiduos')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Tipos de Resíduo</label>
          <dg-autocomplete3
            key="id" label="nome" [multiple]="true" (change)="loaderFilterList('tipoResiduos')"
            classes="form-control form-control-sm" formControlName="tipoResiduos" [data]="controlles.filtersOptions['tipoResiduos']"
          ></dg-autocomplete3>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('dataInicio')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Data Início</label>
          <input type="date" formControlName="dataInicio" class="form-control form-control-sm">
        </div>
        <div *ngIf="controlles.permissionFilters.includes('dataFim')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Data Fim</label>
          <input type="date" formControlName="dataFim" class="form-control form-control-sm">
        </div>
        <div *ngIf="controlles.permissionFilters.includes('ativo')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12" >
          <label class="form-label">Ativo/Inativo</label>
          <select formControlName="ativo" class="form-control form-control-sm">
            <option [value]="''">Ambos</option>
            <option [value]="'true'">Ativo</option>
            <option [value]="'false'">Inativo</option>
          </select>
        </div>
        <div *ngIf="controlles.permissionFilters.includes('estados')" class="col-xl-6 col-lg-6 col-md-12 col-sm-12" >
          <label class="form-label">Estados</label>
          <dg-autocomplete3
            key="uf" label="name" [multiple]="true"
            classes="form-control form-control-sm" formControlName="estados" [data]="controlles.filtersOptions['estados']"
            dg-dynamic-group="estoque" paramName="estados" typeComunication="change" (change)="loaderFilterList('estados')"
          ></dg-autocomplete3>
        </div>
      </div>
    </form>
  </div>

  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1">Salvar</button>
    <button (click)="close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
