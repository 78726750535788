import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-ods2',
  templateUrl: './card-ods2.component.html',
  styleUrls: ['./card-ods2.component.scss']
})
export class CardOds2Component implements OnInit {

  @Input() data: {
    taxaReciclagem: number,
    valorIndicadorBrasil: number,
    anoIndicadorBrasil: number | string
  } = {
    taxaReciclagem: 0,
    valorIndicadorBrasil: 0,
    anoIndicadorBrasil: '--'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
