import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissaoMainComponent } from './missao-main/missao-main.component';
import { MissaoInsertEditComponent } from './missao-insert-edit/missao-insert-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [
    MissaoMainComponent,
    MissaoInsertEditComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AngularEditorModule,
        DirectivesModule
    ]
})
export class MissaoModule { }
