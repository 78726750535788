import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { ArquivoEntity } from "../../../shared/entitys/arquivo.entity";
import { environment } from "src/environments/environment";

export interface InterIndicadores {
  id?: number | string
  nomeIndicador?: string,
  descricaoIndicador?: string,
}

export interface InterMetas {
  id?: number | string
  nomeMeta?: string,
  descricaoMeta?: string,
  indicadores?: InterIndicadores[]
}

@DataServer({
  path: environment.apiUrlMock,
  context: "indicadores-ods"
})

export class CadastroIndicadoresOdsEntity extends AbstractEntity {

  constructor(
    id?: number,
    nomeObjetivo?: string,
    descricaoObjetivo?: string,
    imagem?: ArquivoEntity,
    listMeta?: InterMetas[] 
  ) {
    super();
    this.id = id;
    this.nomeObjetivo = nomeObjetivo;
    this.descricaoObjetivo = descricaoObjetivo;
    this.imagem = imagem;
    this.listMeta = Array.isArray(listMeta) ? listMeta : [];
  };

  @DgFilter_input('text', { colum: '4', name: 'NOME' })
  @DgTableColumn({ columnName: 'NOME' })
  public nomeObjetivo: string | undefined

  public descricaoObjetivo: string | undefined
  public imagem: ArquivoEntity | undefined
  public listMeta: InterMetas[]

}
