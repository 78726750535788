<div id="fragment-side-menu-preview">
  <app-sidebar [preview]="true">
    <div class="sidebar-logo">
      <img [src]="themeService.getLogo(image)">
    </div>

    <app-navigation
      [startHr]="true"
      [listNavigation]="navigateMenu[0].menuList"
    ></app-navigation>

    <app-navigation
      [startHr]="true"
      [listNavigation]="navigateMenu[1].menuList"
    ></app-navigation>
  </app-sidebar>
</div>
