import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import {TratamentoEntity} from "../tratamento.entity";

@Component({
  selector: 'app-tratamento-main',
  templateUrl: './tratamento-main.component.html',
  styleUrls: ['./tratamento-main.component.scss']
})
export class TratamentoMainComponent implements OnInit {

  public mapEntity = new TratamentoEntity();

  constructor(
    public session: SessionService
  ) { }

  ngOnInit(): void {
  }

  checkDbClick(): boolean{
    if(this.session.user.perfil == "ADMINISTRADOR" || this.session.user.perfil == "ADMINISTRATIVO"){
      return false;
    }else{
      return true;
    }
  }

}
