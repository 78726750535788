import { Injectable } from '@angular/core';
import {
  PropertiesWhiteLabel,
  themePaginationColors,
  themeSideBarColors, themeTypograghyColors
} from "../../pages/_user/white-label/properties-white-label.entity";
import {ArquivoEntity} from "../../shared/entitys/arquivo.entity";

export interface themeColorButtons {
  nome: string,
  classe: string,
  backgroungColor: string,
  backgroungColorHover: string,
  textColor: string,
}
export interface parcialThemeColorButtons {
  nome: string,
  classe: string,
  backgroungColor?: string,
  backgroungColorHover?: string,
  textColor?: string,
}

export const defaultListButtonsColors = (): themeColorButtons[] => {
  return [
    {
      nome: "Primary",
      classe: 'primary',
      backgroungColor: "#0d6efd",
      backgroungColorHover: "#257DFF",
      textColor: "#F8F9FA",
    },
    {
      nome: "Secondary",
      classe: 'secondary',
      backgroungColor: '#6C757D',
      backgroungColorHover: '#79838c',
      textColor: '#F8F9FA',
    },
    {
      nome: "Success",
      classe: 'success',
      backgroungColor: '#198754',
      backgroungColorHover: '#5E8655',
      textColor: '#F8F9FA',
    },
    {
      nome: "Info",
      classe: 'info',
      backgroungColor: '#0DCAF0',
      backgroungColorHover: '#13d8ff',
      textColor: '#F8F9FA',
    },
    {
      nome: "Warning",
      classe: 'warning',
      backgroungColor: '#FFC107',
      backgroungColorHover: '#e8b006',
      textColor: '#212529',
    },
    {
      nome: "Danger",
      classe: 'danger',
      backgroungColor: '#DC3545',
      backgroungColorHover: '#c92d3c',
      textColor: '#F8F9FA',
    },
    {
      nome: "Light",
      classe: 'light',
      backgroungColor: '#F8F9FA',
      backgroungColorHover: '#e1e1e1',
      textColor: '#212529',
    },
    {
      nome: "Dark",
      classe: 'dark',
      backgroungColor: '#212529',
      backgroungColorHover: '#30353a',
      textColor: '#F8F9FA',
    },
  ]
}

@Injectable({
  providedIn: 'root'
})
export class ThemeManageService {

  constructor() { }
  //######### LOGO
  getLogo(imagem?: ArquivoEntity) {
    if (!!imagem) {
      if (!!imagem?.file) {
        return 'data:image/' + imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1] + ';base64,' + imagem.file
      }
    }

    let imagemString: string | null = window.localStorage.getItem('themeLogo');

    if (!!imagemString) {
      imagem = <ArquivoEntity>JSON.parse(imagemString)
      if (!!imagem) {
        if (!!imagem?.file) {
          return 'data:image/' + imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1] + ';base64,' + imagem.file
        }
      }
    }

    return 'assets/img/logo/logo-minha-coleta-OLD.png';
  }

  //######### STORAGY (verificados)
  saveProperties(props: PropertiesWhiteLabel | string) {
    if (typeof props != 'string') {
      props = JSON.stringify(props);
    }

    window.localStorage.setItem('whiteLabel', props)
  }

  saveLogo(logo: ArquivoEntity) {
    window.localStorage.setItem('themeLogo', JSON.stringify(logo))
  }

  getProperties():PropertiesWhiteLabel {
    const propertiesString = window.localStorage.getItem('whiteLabel');
    if (!propertiesString) return <PropertiesWhiteLabel>{};
    return <PropertiesWhiteLabel>JSON.parse(propertiesString);
  }

  //######### THEME (verificados)
  setAllTheme(props?: PropertiesWhiteLabel) {
    if (!props) {
      props = this.getProperties();
    }

    const rootProps = new PropertiesWhiteLabel();

    this.setSideBar(props.sideBarColors || rootProps.sideBarColors)
    this.setTypography(props.thypograghy || rootProps.thypograghy)
    this.setPagination(props.paginationColors || rootProps.paginationColors)
    this.setButtons(props?.colorButtons || rootProps.colorButtons)
  }

  setButtons(props: themeColorButtons[]) {
    const root = document.documentElement;

    props.map((color: parcialThemeColorButtons) => {
      if (color) {
        if (!!color.backgroungColor) {
          root.style.setProperty('--bs-'+color.classe+'-custom-button', color.backgroungColor);
        }
        if (color.backgroungColorHover) {
          root.style.setProperty('--bs-'+color.classe+'-custom-button-hover', color.backgroungColorHover);
          root.style.setProperty('--bs-'+color.classe+'-custom-button-selected', color.backgroungColorHover);
        }
        if (color.textColor) {
          root.style.setProperty('--bs-'+color.classe+'-custom-button-text', color.textColor);
        }
      }
    })
  }

  setPagination(props: themePaginationColors) {
    const root = document.documentElement;

    if (!!props.background) {
      root.style.setProperty('--bs-pagination-background', props.background);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-pagination-text', props.colorText);
    }
    if (!!props.backgroundItemSelected) {
      root.style.setProperty('--bs-pagination-background-selected', props.backgroundItemSelected);
    }
    if (!!props.colorTextItemSelected) {
      root.style.setProperty('--bs-pagination-selected-text', props.colorTextItemSelected);
    }
  }

  setTypography(props: themeTypograghyColors) {
    const root = document.documentElement;

    if (!!props.colortitle) {
      root.style.setProperty('--bs-typography-title-color', props.colortitle);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-typography-text-color', props.colorText);
    }
    if (!!props.destaqueClaro) {
      root.style.setProperty('--bs-typography-text-color-destaque-claro', props.destaqueClaro);
    }
    if (!!props.destaqueEscuro) {
      root.style.setProperty('--bs-typography-text-color-destaque-escuro', props.destaqueEscuro);
    }
  }

  setSideBar(props: themeSideBarColors) {
    const root = document.documentElement;

    if (!!props.background) {
      root.style.setProperty('--bs-background-menu', props.background);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-background-menu-text', props.colorText);
    }
  }

  //######### PREVIEW
  setAllPreviewTheme(props: PropertiesWhiteLabel) {
    if (props.sideBarColors) this.setPreviewMenu(props.sideBarColors)
    if (props.thypograghy) this.setPreviewTypography(props.thypograghy)
    if (props.paginationColors) this.setPreviewPagination(props.paginationColors)
    if (props.colorButtons) this.setPreviewButtons(props.colorButtons)
  }

  setPreviewButtons(list: themeColorButtons[]) {
    // const resultList = this.getDifButtonsValues(list)

    if (list.length < 1) return;

    const root = document.documentElement;

    list.map(color => {
      if (color.backgroungColor) root.style.setProperty('--bs-'+color.classe+'-custom-button-preview', color.backgroungColor);
      if (color.backgroungColorHover) root.style.setProperty('--bs-'+color.classe+'-custom-button-hover-preview', color.backgroungColorHover);
      if (color.textColor) root.style.setProperty('--bs-'+color.classe+'-custom-button-text-preview', color.textColor);
    });
  }

  setPreviewMenu(props: themeSideBarColors) {
    const root = document.documentElement;

    if (!!props.background) {
      root.style.setProperty('--bs-background-menu-preview', props.background);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-background-menu-text-preview', props.colorText);
    }
  }

  setPreviewPagination(props: themePaginationColors) {
    const root = document.documentElement;

    if (!!props.background) {
      root.style.setProperty('--bs-pagination-background-preview', props.background);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-pagination-text-preview', props.colorText);
    }
    if (!!props.backgroundItemSelected) {
      root.style.setProperty('--bs-pagination-background-selected-preview', props.backgroundItemSelected);
    }
    if (!!props.colorTextItemSelected) {
      root.style.setProperty('--bs-pagination-selected-text-preview', props.colorTextItemSelected);
    }
  }

  setPreviewTypography(props: themeTypograghyColors) {
    const root = document.documentElement;

    if (!!props.colortitle) {
      root.style.setProperty('--bs-typography-title-color-preview', props.colortitle);
    }
    if (!!props.colorText) {
      root.style.setProperty('--bs-typography-text-color-preview', props.colorText);
    }
    if (!!props.destaqueClaro) {
      root.style.setProperty('--bs-typography-text-color-destaque-claro-preview', props.destaqueClaro);
    }
    if (!!props.destaqueEscuro) {
      root.style.setProperty('--bs-typography-text-color-destaque-escuro-preview', props.destaqueEscuro);
    }
  }
}
