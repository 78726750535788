import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideFilterComponent } from './side-filter/side-filter.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";
import {MatTooltipModule} from "@angular/material/tooltip";
import { DashboardSideFilterComponent } from './custom-side-filter/dashboard-side-filter/dashboard-side-filter.component';
import { SidefilterDashboardColetasComponent } from './custom-side-filter/sidefilter-dashboard-coletas/sidefilter-dashboard-coletas.component';
import { LayoutSideFilterComponent } from './layout-side-filter/layout-side-filter.component';
import { SidefilterDashboardEstoqueComponent } from './custom-side-filter/sidefilter-dashboard-estoque/sidefilter-dashboard-estoque.component';
import { SidefilterRankingComponent } from './custom-side-filter/sidefilter-ranking/sidefilter-ranking.component';
import { SidefilterDashboardRankingAterroZeroComponent } from './custom-side-filter/sidefilter-dashboard-ranking-aterro-zero/sidefilter-dashboard-ranking-aterro-zero.component';
import { SidefilterDashboardRankingTaxaReciclagemComponent } from './custom-side-filter/sidefilter-dashboard-ranking-taxa-reciclagem/sidefilter-dashboard-ranking-taxa-reciclagem.component';
import {UiModule} from "../ui/ui.module";
import { FavoritosInsertEditComponent } from './favoritos-insert-edit/favoritos-insert-edit.component';
import {ModalFavoriteComponent} from "./modal-favorite/modal-favorite.component";
import { ModalFavoritosMainComponent } from './favoritos/modal-favoritos-main/modal-favoritos-main.component';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    SideFilterComponent,
    DashboardSideFilterComponent,
    SidefilterDashboardColetasComponent,
    LayoutSideFilterComponent,
    SidefilterDashboardEstoqueComponent,
    SidefilterRankingComponent,
    SidefilterDashboardRankingAterroZeroComponent,
    SidefilterDashboardRankingTaxaReciclagemComponent,
    ModalFavoriteComponent,
    FavoritosInsertEditComponent,
    ModalFavoritosMainComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        DgAutocompleteModule,
        MatTooltipModule,
        UiModule,
        ReactiveFormsModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    exports: [SideFilterComponent, SidefilterDashboardColetasComponent, SidefilterDashboardEstoqueComponent, SidefilterRankingComponent, SidefilterDashboardRankingAterroZeroComponent, SidefilterDashboardRankingTaxaReciclagemComponent]
})
export class SideFilterModule { }
