import Swal from 'sweetalert2';
import { OldModalComponent } from './../../../../shared/ui/modal/modal/modal.component';
import { PickListComponent } from './../../../../shared/ui/pick-list/pick-list.component';
import { ClientesService } from './../../_clientes/clientes/service/clientes.service';
import { ClientesEntity } from './../../_clientes/clientes/clientes.entity';
import { GalpaoEntity } from './../../_galpao/galpao/galpao.entity';
import { RotasService } from './../service/rotas.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RotasEntity } from '../rotas.entity';
import { Route, Router } from "@angular/router";
import { CLIENTE, CLIENTE_FIND_FILTER, ROTAS_BY_ID } from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-rotas-main',
  templateUrl: './rotas-main.component.html',
  styleUrls: ['./rotas-main.component.scss']
})
export class RotasMainComponent implements OnInit {

  public mapEntity = new RotasEntity();

  listClientesRelatorio: ClientesEntity[] = [];
  paramsFilter: any

  @ViewChild('modalRelatorio') modal!: OldModalComponent;
  @ViewChild('pickList') pickList!: PickListComponent;

  constructor(
    public service: RotasService,
    public router: Router,
    private clienteService: ClientesService
  ) {
    this.mapEntity.addActions([
      {
        name: 'editar',
        action: (data: RotasEntity) => this.ajustaLinkEditarRota(data)
      },
      {
        name: 'Copiar Rota',
        action: (row: RotasEntity) => {
          //@ts-ignore
          if (!row.id || !row.galpaoNome?.id || !row.dataRota || !!row.galpaoNome.dataExclusao) return
          const params = {
            id: row.id,
            idGalpao: row.galpaoNome?.id,
            dataRota: row.dataRota
          }

          service.get(ROTAS_BY_ID, { params }).subscribe(resp => {
            if ('id' in resp.data) delete resp.data.id;

            this.router.navigate(['/user/galpao/rotas/novo_copiado'], { queryParams: { rota: JSON.stringify(resp.data) } })
          })
        },
        //@ts-ignore
        permission: (row: RotasEntity) => !row.galpaoNome.dataExclusao
      },
      // {
      //   name: 'Copiar Rota',
      //   action: (row: RotasEntity) => {
      //     //@ts-ignore
      //     if (!row.id || !row.galpaoNome?.id || !row.dataRota || !!row.galpaoNome.dataExclusao) return
      //     const params = {
      //       id: row.id,
      //       idGalpao: row.galpaoNome?.id,
      //       dataRota: row.dataRota
      //     }
      //
      //     service.get(ROTAS_BY_ID, { params }).subscribe(resp => {
      //       if ('id' in resp.data) delete resp.data.id;
      //
      //       this.router.navigate(['/user/rotas/novo'], { queryParams: { rota: JSON.stringify(resp.data) } })
      //     })
      //   },
      //   //@ts-ignore
      //   permission: (row: RotasEntity) => !row.galpaoNome.dataExclusao
      // }
    ]);
  }

  ngOnInit(): void {
  }

  enviarRelatorio() {

    const ids = this.pickList.getList().map((clientes: ClientesEntity) => String(clientes?.id)).join(',')

    console.log(typeof ids)

    this.service.post('rotas/enviarrelatorio', {
      clienteIds: JSON.stringify(ids),
        ...(!!this.paramsFilter ? this.paramsFilter : {})
    }, {
      params: {
        clienteIds: ids,
        ...(!!this.paramsFilter ? this.paramsFilter : {})
      }

    }).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Enviado com sucesso',
          timer: 5000
        });
        this.modal.close()
      }
    )
  }



  getFilterValue(event: any) {
    this.paramsFilter = event;
  }

  //TODO VOLTAR DEPOIS
  getClientesRelatorio(cliente?: any) {
    this.clienteService.get('rotas/clientes/nomes',
      {
        params: {
          unpaged: true,
          ...(!!this.paramsFilter ? this.paramsFilter : {})
        },
      }).subscribe(
        resp => {
          //@ts-ignore
          this.listClientesRelatorio = resp.data;
          this.modal.open()
        }
      )
  }

  open() {
    this.getClientesRelatorio()
  }

  ajustaLinkEditarRota(data: RotasEntity) {
    this.router.navigate(['user/galpao/rotas/' + data.id], {
      queryParams: {
        idGalpao: data?.galpaoNome?.id,
        dataRota: data.dataRota
      }
    })
  }

  beforeClose = () => {
    this.pickList.listaSelected = [];
  }

}
