import { Injectable } from '@angular/core';
import {AbstractHttpService, HttpUpdateServiceInterface} from "@datagrupo/dg-crud";
import {ColetasEntity} from "../coletas.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {ClientesEntity} from "../../../_clientes/clientes/clientes.entity";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";

@Injectable({
  providedIn: 'root'
})
export class ColetasService extends AbstractHttpService<ColetasEntity>{

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'coletas')

    this.dictionary.findOne = {
      galpao: 'galpaoNome',
      motorista: 'nomeMotorista',
    }
    this.dictionary.update = {
      galpaoNome: 'galpao',
      nomeMotorista: 'motorista'
    }

    this.dictionary.findAll = (val: ColetasEntity) => {
      val= {
        ...val,
        //@ts-ignore
        rota: {
          ...val.rota,
          //@ts-ignore
          galpaoNome: val.rota?.galpao,

        },
        //@ts-ignore
        rotaGalpao: val.rota?.galpao
      }
      return val;
    }
  }

  download(id: number) {
    return this.findOne(Number(id), '');
  }

  uploadImage(data: any) {
    return this.save(data, 'imagem-container');
  }

  override update(data: any, endpointData?: HttpUpdateServiceInterface | string | boolean): Observable<any> {
    return this.patch('coletas/codigo-mtr', {
      codigoMtr: <ColetasEntity>data.codigoMtr,
      coletaId: data.id
    })
  }
}
