import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { IndustriaDestinoEntity } from '../industria-destino.entity';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';

@Injectable({
  providedIn: 'root'
})
export class IndustriaDestinoService extends AbstractHttpService<IndustriaDestinoEntity> {

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'industria')
  }
}
