<div
  id="industria-destino-main"
  class="container"
>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Destinador</h4>
        </div>
        <div class="card-subtitle">
          <filter-search2 dynamicGroupName="destinador">
            <form [formGroup]="formFilters">
              <div class="row">
                <div class="col-xl-4 xol-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">Nome</label>
                  <default-autocomplete-input
                    formControlName="industriaId"
                    label="nome"
                    key="id"
                    dg-dynamic-group="destinador"
                    paramName="industriaId"
                    [data]="listIndustrias"
                  >
                  </default-autocomplete-input>
                </div>
                <div class="col-xl-3 xol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">CNPJ</label>
                  <input
                    class="form-control"
                    mask="00.000.000/0000-00"
                    formControlName="cnpj"
                    dg-dynamic-group="destinador"
                    paramName="cnpj"
                  >
                </div>
                <div *ngIf="session.checkPerfil(['PARCEIRO', 'ADMINISTRADOR', 'ADMINISTRATIVO'])" class="col-xl-4 xol-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">Gerador</label>
                  <default-autocomplete-input
                    formControlName="clienteId"
                    label="nome"
                    key="id"
                    dg-dynamic-group="destinador"
                    paramName="clienteId"
                    [data]="listGeradores"
                  >
                  </default-autocomplete-input>
                </div>
              </div>
            </form>
          </filter-search2>
<!--          <filter-search-->
<!--            [entityMap]="mapEntity"-->
<!--            [table]="tableIndustriaDestino"-->
<!--            tableEventId="industriaDestinoTable"-->
<!--          ></filter-search>-->
        </div>
        <div
          class="card-body"
          style="padding: 0"
        >
          <dg-table dg-dynamic-table [dynamicTable]="table">
          </dg-table>
<!--          <crud-data-table-->
<!--            #tableIndustriaDestino-->
<!--            [entityMap]="mapEntity"-->
<!--            [service]="service"-->
<!--            [showPagination]="false"-->
<!--            [customDblclick]="!!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? false : true"-->
<!--            [customActions]="customAction"-->
<!--            [context]="!!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? 'industria' : 'industria/galpao' "-->
<!--            tableEventId="industriaDestinoTable"-->
<!--            [register]="true"-->
<!--          ></crud-data-table>-->
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
<!--          <dg-pagination-->
<!--            [dataPagination]="mapEntity.configEntityTable.pagination"-->
<!--            (changePage)="tableIndustriaDestino.changePages($event)"-->
<!--          ></dg-pagination>-->
        </div>
      </card>
    </div>
  </div>
</div>


<page-footer>
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
</page-footer>
