import {
  AbstractEntity,
  DgFilter_input,
  DgTableColumn,
} from '@datagrupo/dg-crud';

export class CategoriaNegocioEntity extends AbstractEntity {
  constructor(id?: number | string, nome?: string) {
    super();
    this.id = id;
    this.nome = nome;
  }
  @DgFilter_input('text', { colum: '4' } )
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined;
}
