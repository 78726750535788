<div
    id="galpao-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Arquivos Motorista/Catador</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        tableEventId="arquivosMotorista"
                        [table]="tableGalpaoArquivoMotorista"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableGalpaoArquivoMotorista
                        [entityMap]="mapEntity"
                        [service]="service"
                        [showPagination]="false"
                        [singleAction]="{icon:'download', action: download}"
                        [customDblclick]="true"
                        (dblclick)="download($event)"
                        [showDefaultAction]="session.user.perfil == 'ADMINISTRADOR' || session.user.perfil == 'GALPAO' || session.user.profile == 'SINDICO' ? true : false"
                        tableEventId="arquivosMotorista"
                        [register]="true"
                    ></crud-data-table>
                </div>
                <div class="card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableGalpaoArquivoMotorista.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>
