import { Component, OnInit } from '@angular/core';
import {UnidadeMedidaEntity} from "../unidade-medida.entity";

@Component({
  selector: 'app-unidade-medida-main',
  templateUrl: './unidade-medida-main.component.html',
  styleUrls: ['./unidade-medida-main.component.scss']
})
export class UnidadeMedidaMainComponent implements OnInit {

  mapEntity = new UnidadeMedidaEntity()

  constructor() { }

  ngOnInit(): void {
  }

}
