<card>
  <div class="card-header">
    <h4 class="card-title">Relatório de Impacto Ambiental</h4>
  </div>
  <div class="card-body card-impacto-ambiental">
    <div class="row">
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Árvores</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-01.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.arvores || 0) }}</p>
                <p style="margin: 0; padding: 0; font-weight: bold">Papel</p>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Água</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-02.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.agua || 0) }} m3</p>
                <p style="margin: 0; padding: 0; font-weight: bold">Papel</p>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Energia Elétrica</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-03.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.energia || 0) }} kw/h</p>
                <p style="margin: 0; padding: 0; font-weight: bold">Papel</p>
              </div>
            </div>
          </div>
        </card>

      </div>
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Areia</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-04.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.areia || 0) }} kg</p>
                <p style="margin: 0; padding: 0; font-weight: bold">vidro</p>
              </div>
            </div>
          </div>
        </card>

      </div>
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Petróleo</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-05.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.petroleo || 0) }} L</p>
                <p style="margin: 0; padding: 0; font-weight: bold">plástico</p>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-card col-xl-4 col-lg-4 col-mg-6 col-sm-12">
        <card>
          <div class="card-header">
            <p class="card-title">Minério</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <img class="img-table-impacto" src="assets/img/flag/flag-06.png" alt="imagem de ilustração da categoria">
              </div>
              <div class="col-7">
                <p *ngIf="loader" style="margin: 0; padding: 0" class="fc-green">Calculando...</p>
                <p *ngIf="!loader" style="margin: 0; padding: 0" class="fc-green">{{ ajustaNumber(data?.minerio || 0) }} Kg</p>
                <p style="margin: 0; padding: 0; font-weight: bold">metal</p>
              </div>
            </div>
          </div>
        </card>

      </div>
    </div>
  </div>
</card>
