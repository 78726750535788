import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../modal/modal.component";
import {FormControl, Validators} from "@angular/forms";
import {CategoriaArquivoEntity} from "../../../../../pages/_user/categoria-arquivo/categoria-arquivo.entity";
import {CategoriaArquivoService} from "../../../../../pages/_user/categoria-arquivo/service/categoria-arquivo.service";
import {GalpaoDocumentosService} from "../../../../../pages/_user/_galpao/galpao-documentos/service/galpao-documentos.service";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {verifySizeFiles} from "../../../../../helpers/helpers";

@Component({
  selector: 'upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.scss']
})
export class UploadFilesModalComponent implements OnInit {
  @ViewChild('modal') modal!: OldModalComponent;
  @ViewChild('docInput') docInput!: ElementRef;
  @Output() salve = new EventEmitter<any>();
  @Input() append: object = {};
  @Input() endpoint: string = '';
  @Input() eventKey: string = '';

  public id: number | string | undefined;

  public file: File | undefined;
  public docFile: File | undefined;
  public docName: string | undefined;

  public categoriaArquivo: FormControl = new FormControl('', [Validators.required]);

  public categoriasList: CategoriaArquivoEntity[] = [];

  constructor(
    private categArquiv: CategoriaArquivoService,
    private docService: GalpaoDocumentosService,
    private http: HttpOldService,
    private service: GenericCrudService
  ) {
    categArquiv.findAll().subscribe(
      resp => {
        //@ts-ignore
        this.categoriasList = resp.data;
      }
    )
  }

  ngOnInit(): void {
    if (!this.endpoint) {
      throw 'endpoint de envio de arquivo não informado'
    }
    this.service.alterResource(this.endpoint);
  }

  public open(data?: { id: number | string | undefined, categoria: number | string | undefined }){
    if (!!data?.id && !!data?.categoria) {
      this.id = data.id;
      this.categoriaArquivo.patchValue(data.categoria)
    }
    this.modal.open()
  }

  uploadDocumento_click() {
    const docInput = this.docInput.nativeElement;
    docInput.click()
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (!verifySizeFiles(file)) {
      this.docFile = undefined;
      return;
    }

    if (file) {
      this.docName = file.name
      this.file = file
    }
  }

  salvar() {
    if (!this.categoriaArquivo.valid || !this.docName || !this.file) {
      this.categoriaArquivo.markAllAsTouched()
      return;
    }

    var formData = new FormData();

    // formData.append("idGalpao", this.idGalpao);
    // formData.set("file", this.file);

    if (this.append) {
      Object.keys(this.append).map((append, index) => {
        // @ts-ignore
        formData.append(append, this.append[append])
      });
    }

    if (!!this.id) {
      formData.append("id", String(this.id))
    }

    formData.append("categoriaId", this.categoriaArquivo.value);
    formData.set("arquivo", this.file);


    //@ts-ignore
    this.service.saveOrUpdate(formData, '').subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: this.eventKey }));
        this.modal.close();
      }
    )
  }

  close = (e?: any) => {
    this.categoriaArquivo.reset('')
    this.docName = undefined;
    this.docFile = undefined;
    return true
  }
}
