import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ajusteDateByPaste} from "../../../../helpers/helpers";
import {GenericCrudService} from "../../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {CloseAutocomplete, InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {AbstractSideFilter, dataSeideFilter} from "../../cdk/abstract-side-filter";
import {
  DASHBOARD_FILTER_OPERADOR_LOGISTICO, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
  DASHBOARD_FILTER_UNIDADE_GERADORA
} from "../../../../core/config/endpoint-list";
import {ClientesEntity} from "../../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../../pages/_user/_galpao/galpao/galpao.entity";
import {TipoLixoEntity} from "../../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {GalpaoMotoristaEntity} from "../../../../pages/_user/_galpao/galpao-motorista/galpao-motorista.entity";

@Component({
  selector: 'sidefilter-dashboard-coletas',
  templateUrl: './sidefilter-dashboard-coletas.component.html',
  styleUrls: ['./sidefilter-dashboard-coletas.component.scss']
})
export class SidefilterDashboardColetasComponent extends AbstractSideFilter implements OnInit {

  objParamName = 'coletas'

  override listsPreSelected: {
    favoritos: any[],
    unidades: any[],
    estados: any[],
    galpao: any[],
    motorista: any[],
    tipoResiduo: any[]
  } = {
    favoritos: [],
    unidades: [],
    estados: [],
    galpao: [],
    motorista: [],
    tipoResiduo: []
  }
  listSelected: {
    unidades: any[],
    estados: any[],
    galpao: any[],
    motorista: any[],
    tipoResiduo: any[]
  } = {
    unidades: [],
    estados: [],
    galpao: [],
    motorista: [],
    tipoResiduo: []
  }
  public _dataFilter = {
    favoritos: <any>undefined,
    unidades: <any[]>[],
    estados: <any[]>[],
    galpao: <any[]>[],
    motorista: <any[]>[],
    tipoResiduo: <any[]>[],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };
  listItensDisponiveis: {
    favoritos: any[],
    unidades: ClientesEntity[],
    estados: { name: string, uf: string }[],
    galpao: GalpaoEntity[],
    tipoResiduo: TipoLixoEntity[],
    motorista: GalpaoMotoristaEntity[],
  } = {
    favoritos: [],
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    motorista: []
  }

  timeout: any = null;

  constructor(
    public service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    super(service, router, route);
  }

  ngOnInit(): void {
    this.verifyParamsStart()
  }

  //*************************** METODOS PARA O ABSTRACT ***************************//

  setInitParams(params?: any, preValues?: any): void {
    if (!!params) {
      this.listsPreSelected = {
        ...this.listsPreSelected,
        ...preValues.listsPreSelected
      };
      this.listSelected = {
        ...this.listSelected,
        ...preValues.listSelected
      };
      this._dataFilter = {
        ...this._dataFilter,
        ...preValues._dataFilter
      };
    }

    this.ajustDataByPerfil(true)
    this.getDataAutocompletes();

    setTimeout(() => this.setQueryParams(), 10)
  }

  ajsutDataSetParams(preValues: object) {
    const {ativo} = this._dataFilter;
    const result: any = preValues;
    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;

    return result;
  }

  clearFilter(preValues: dataSeideFilter) {
    this._dataFilter = {
      ...this._dataFilter,
      ...preValues._dataFilter
    }
    this.listSelected = {
      ...this.listSelected,
      ...preValues.listSelected
    }
    this.listsPreSelected = {
      ...this.listsPreSelected,
      ...preValues.listsPreSelected
    }
    this.ajustDataByPerfil()
    this.updateDataAutocompletes('all')
  }

  ajsutDataEmiter(preValues: object) {
    return preValues;
  }

  //*************************** METODOS LOCAIS ***************************//

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  verifyChangeData(tipo: 'dataFim' | 'dataInicio'): void {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const date = new Date(tipo == 'dataFim' ? this._dataFilter.dataFim : this._dataFilter.dataInicio);

      if (!isNaN(date.valueOf())) {
        if (date.getTime() >= new Date(new Date('1995-12-17T03:24:00')).getTime()) {
          this.setQueryParams()
        }
      } else if (this._dataFilter[tipo] == '') {
        this.setQueryParams();
      }
    }, 300);
  }

  ajustDataByPerfil(initFilter = false) {
    if (this.session.checkPerfil('GALPAO')) {
      const galpao = {id: this.session.user.galpao_id, nome: this.session.user.galpao_nome};

      if (initFilter) {
        this.listsPreSelected.galpao = [galpao]
      }
      this.listSelected.galpao = [galpao]
      this._dataFilter.galpao = [galpao.id]
    } else if (this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EVENTO', 'EMPRESA'])) {

      const unidade = {id: this.session.user.cliente_id, nome: this.session.user.cliente_nome || '--'};

      if (initFilter) {
        this.listsPreSelected.unidades = [unidade]
      }
      this.listSelected.unidades = [unidade]
      this._dataFilter.unidades = [unidade.id]
    }
  }

  getDataAutocompletes() {
    let {unidades, galpao, tipoResiduo} = <{ unidades: string, galpao: string, tipoResiduo: string }>this.preAjustDataEmiter();

    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
      this.updateFavoritos()
    }

    if (!this.session.checkPerfil('GALPAO')) {
      this._service.get(
        DASHBOARD_FILTER_OPERADOR_LOGISTICO,
        {
          params: {
            unpaged: true,
            ...(!!unidades ? {unidades} : {}),
            ...(!!tipoResiduo ? {tipoLixoId: tipoResiduo} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.galpao = resp.data;

          this.listItensDisponiveis.galpao = this.listItensDisponiveis.galpao.sort(
            (a: GalpaoEntity, b: GalpaoEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA'])) {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
            ...(!!galpao ? {galpao} : {}),
            ...(!!galpao ? {operadores: galpao} : {}),
            ...(!!tipoResiduo ? {tipoLixoId: tipoResiduo} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;

          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this._service.get('motoristas/nomes',
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.motorista = resp.data
      }
    )
    this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {}),
            ...(!!unidades ? {clienteId: unidades} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )
  }

  updateDataAutocompletes(origin: 'galpao' | 'unidades' | 'motorista' | 'tipoResiduo' | 'all') {
    let {unidades, galpao, tipoResiduo} = <{ unidades: string, galpao: string, tipoResiduo: string }>this.preAjustDataEmiter();

    if (!this.session.checkPerfil('GALPAO') && origin != 'galpao') {
      this._service.get(
        DASHBOARD_FILTER_OPERADOR_LOGISTICO,
        {
          params: {
            unpaged: true,
            ...(!!unidades ? {unidades} : {}),
            ...(!!unidades ? {operadores: unidades} : {}),
            ...(!!tipoResiduo ? {tipoLixoId: tipoResiduo} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.galpao = resp.data;

          this.listItensDisponiveis.galpao = this.listItensDisponiveis.galpao.sort(
            (a: GalpaoEntity, b: GalpaoEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA']) && origin != 'unidades') {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
            ...(!!galpao ? {galpao} : {}),
            ...(!!galpao ? {operadores: galpao} : {}),
            ...(!!tipoResiduo ? {tipoLixoId: tipoResiduo} : {})
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;


          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    if (origin == 'galpao') {
      this._service.get('motoristas/nomes',
        {
          params:
            {
              unpaged: true,
              ...(!!galpao ? {galpaoId: galpao} : {})
            }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.motorista = resp.data
        }
      )
    }

    if (origin == 'galpao' || origin == 'unidades' || origin == 'all') {
      this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
        {
          params:
            {
              unpaged: true,
              ...(!!galpao ? {galpaoId: galpao} : {}),
              ...(!!unidades ? {clienteId: unidades} : {})
            }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.tipoResiduo = resp.data;
        }
      )
    }

  }

  public ajustePasteDate = (ev: any, varName: 'dataInicio' | 'dataFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)

  setAutoComplete(data: CloseAutocomplete, identificador: 'galpao' | 'unidades' | 'motorista' | 'tipoResiduo') {
    if (this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this.setQueryParams().then(() => {
        // if (identificador == 'favoritos') {
        //   this.setAutoCompleteFavorito(data)
        // }
      });
    } else {
      this.listSelected[identificador] = [...data.selectedList];
      // if (identificador == 'favoritos') return;
    }

    if (
      identificador == 'galpao' || identificador == 'unidades' ||
      identificador == 'tipoResiduo'
    ) {
      this.updateDataAutocompletes(identificador)
    }
  }

  public reload = false;

  async setAutoCompleteFavorito(data: CloseAutocomplete) {

    this.listsPreSelected = {
      galpao: data.selectedList[0].galpao,
      estados: [],
      unidades: [],
      tipoResiduo: [],
      motorista: [],
      favoritos: [],
    }

    this.listSelected = {
      galpao: data.selectedList[0].galpao,
      estados: [],
      unidades: [],
      tipoResiduo: [],
      motorista: [],
    }

    this._dataFilter = {
      galpao: (data.selectedList[0].galpao || []).map((item: any) => item.id) || [],
      estados: [],
      unidades: [],
      tipoResiduo: [],
      motorista: [],
      favoritos: [],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    }

    this.listsPreSelected = {
      ...this.listsPreSelected,
      galpao: data.selectedList[0].galpao
    };
    // this.listSelected = {
    //   ...this.listSelected,
    //   galpao: data.selectedList[0].galpao
    // };
    // this._dataFilter = {
    //   ...this._dataFilter,
    //   galpao: (data.selectedList[0].galpao || []).map((item: any) => item.id) || []
    // };

    console.log(this._dataFilter.galpao)
    console.log(this.listsPreSelected.galpao)

    this.getDataAutocompletes();

    setTimeout(() => {
      // alert()
      // this.setQueryParams()
    }, 10)

    // await this.setQueryParams(null).then()

    // if (data.selectedList[0]?.galpao) {
    //   await this.router.navigate(['.'], {
    //     relativeTo: this.route,
    //     queryParams: {
    //       [this.objParamName]: JSON.stringify({ galpao: data.selectedList[0].galpao || null })
    //     },
    //     queryParamsHandling: 'merge'
    //   }).then(() => {
    //     // console.log(JSON.parse(this.route.snapshot.queryParams[this.objParamName]))
    //     console.log(this.preAjustInitParams(JSON.parse(this.route.snapshot.queryParams[this.objParamName])))
    //     debugger
    //     this.setInitParams(JSON.parse(this.route.snapshot.queryParams[this.objParamName]), this.preAjustInitParams(JSON.parse(this.route.snapshot.queryParams[this.objParamName])));
    //
    //     this.listsPreSelected = {
    //       ...this.listsPreSelected,
    //       galpao: data.selectedList[0].galpao
    //     };
    //     this.listSelected = {
    //       ...this.listSelected,
    //       galpao: data.selectedList[0].galpao
    //     };
    //     this._dataFilter = {
    //       ...this._dataFilter,
    //       galpao: (data.selectedList[0].galpao || []).map((item: any) => item.id) || []
    //     };
    //
    //     this.getDataAutocompletes();
    //
    //     setTimeout(() => this.setQueryParams(), 10)
    //   })
    // }

  }

  @ViewChild('autoGalpao') autoGalpao!: InputAutocompleteComponent;
  @ViewChild('autoUnidade') autoUnidade!: InputAutocompleteComponent;
  @ViewChild('autoMotorista') autoMotorista!: InputAutocompleteComponent;
  @ViewChild('autoResiduo') autoResiduo!: InputAutocompleteComponent;

  setFavoritos(d: any) {
    const data = d.selectedList[0] || {}
    console.log(data);

    this._dataFilter = {
      ...this._dataFilter,
      unidades: <any[]>[],
      estados: <any[]>[],
      galpao: <any[]>[],
      motorista: <any[]>[],
      tipoResiduo: <any[]>[],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    }

    const mapAutocompletes: {[key: string]: 'autoGalpao' | 'autoUnidade' | 'autoMotorista' | 'autoResiduo'} = {
      galpoes: 'autoGalpao',
      clientes: 'autoUnidade',
      motoristas: 'autoMotorista',
      tipoLixos: 'autoResiduo',
    }

    Object.keys(data).map((key) => {
      if (!(key in data)) return
      if (Array.isArray(data[key])) {
        if (data[key].length > 0) {
          if (key in mapAutocompletes) {
            if (!this[mapAutocompletes[key]]) return;
            this[mapAutocompletes[key]].selectedList = data[key] || [];
            const value = this[mapAutocompletes[key]].selectedList.map(item => this[mapAutocompletes[key]].dataManage.getKey(item))
            this[mapAutocompletes[key]].dataManage.setValue(value)

            this[mapAutocompletes[key]].eventManage.close()
          }
        }
      } else if (['dataInicio', 'dataFim'].includes(key)){
        this._dataFilter[<'dataInicio' | 'dataFim' | 'ativo'>key] = data[key]
      }
    })

    this.setQueryParams().then();
  }

  @HostListener('window:update-DASHBOARD_COLETAS')
  updateFavoritos() {
    this._service.get('favoritos', { params: { unpaged: true, tipoFavorito: 'DASHBOARD_COLETAS' } }).subscribe(
      resp => {
        this.listItensDisponiveis.favoritos = resp.data || [];
      }
    )
  }
}
