import { Component, OnInit } from '@angular/core';
import { CategoriaNegocioEntity } from '../categoria-negocio.entity';
import { CategoriaNegocioService } from '../service/categoria-negocio.service';

@Component({
  selector: 'app-categoria-negocio-main',
  templateUrl: './categoria-negocio-main.component.html',
  styleUrls: ['./categoria-negocio-main.component.scss']
})
export class CategoriaNegocioMainComponent implements OnInit {

  public mapEntity = new CategoriaNegocioEntity();

  constructor(
    public service: CategoriaNegocioService
  ) { }

  ngOnInit(): void {
  }

}
