<div class="wrapper d-flex align-items-stretch">
  <!-- Page Content  -->
  <div id="content">

<!--    <nav id="public-header-principal" class="navbar navbar-light navbar-expand-md">-->
<!--      <div class="container-fluid">-->

<!--        <button class="navbar-toggler ms-auto fc-green" type="button" data-bs-toggle="collapse" data-bs-target="#nav-bar-public-home" aria-controls="nav-bar-public-home" aria-expanded="false" aria-label="Toggle navigation">-->
<!--          <mat-icon class="fc-green">menu</mat-icon>-->
<!--        </button>-->

<!--        <div class="collapse navbar-collapse" id="nav-bar-public-home">-->
<!--          <ul class="navbar-nav mb-2 mb-lg-0  me-auto">-->
<!--            <li class="nav-item" style="margin: 0 11rem">-->
<!--              <a class="nav-link" aria-current="page" routerLink="">Home</a>-->
<!--            </li>-->
<!--          </ul>-->

<!--          <ul class="navbar-nav mb-2 mb-lg-0 me-0">-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" aria-current="page" routerLink="compre-credito-logistica-reversa">-->
<!--                <button class="btn btn-sm btn-outline-success">-->
<!--                  COMPRE CRÉDITO DE LOGISTICA REVERSA-->
<!--                </button>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" aria-current="page" routerLink="solicitacao-cadastro">-->
<!--                <button class="btn btn-sm btn-outline-success">-->
<!--                  CADASTRE-SE-->
<!--                </button>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" aria-current="page" routerLink="login">-->
<!--                <button class="btn btn-sm btn-success" style="border: white solid 2px">-->
<!--                  LOGIN-->
<!--                </button>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

<!--      </div>-->
<!--    </nav>-->

    <div id='layout-appRoot'>
      <div class="router-outlet-root">
        <router-outlet>
        </router-outlet>
      </div>
    </div>

<!--    <public-footer></public-footer>-->
  </div>
</div>
