import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnidadeMedidaMainComponent } from './unidade-medida-main/unidade-medida-main.component';
import { UnidadeMedidaInsertEditComponent } from './unidade-medida-insert-edit/unidade-medida-insert-edit.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import { DirectivesModule } from 'src/app/core/directives/directives.module';



@NgModule({
  declarations: [
    UnidadeMedidaMainComponent,
    UnidadeMedidaInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    DirectivesModule
  ]
})
export class UnidadeMedidaModule { }
