import {Component, EventEmitter, Output} from "@angular/core";
import {ajusteDateByPaste} from "../../../helpers/helpers";
import {ActivatedRoute, Router} from "@angular/router";
import {GenericCrudService} from "../../../core/services/generic-crud-service/generic-crud.service";
import {ClientesEntity} from "../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../pages/_user/_galpao/galpao/galpao.entity";
import {TipoLixoEntity} from "../../../pages/_user/tipo-lixo/tipo-lixo.entity";

export interface dataSeideFilter {
  _dataFilter: any
  listSelected: any
  listsPreSelected: any
}

@Component({
  template: ''
})
export abstract class AbstractSideFilter {
  @Output('visible') visible = new EventEmitter<boolean>();

  // comporta a lista que iniciará os itens selecionados mesmo que esses
  // não estejam na lista de disponíveis para seleção.
  listsPreSelected: {[key: string]: any[]} = {}
  abstract _dataFilter: {[key: string]: any[] | string}
  // lista de itens selecionados para alimentar os params da url
  abstract listSelected: any

  // lista de itens disponíveis para serem selecionados
  abstract listItensDisponiveis: any


  async setQueryParams() {
    let params: object | null = this.ajsutDataSetParams(this.preAjsutDataSetParams());

    if(Object.keys((params || {})).length <= 0) {
      params = {[this.objParamName]: null}
    } else {
      params = {[this.objParamName]: JSON.stringify(params)}
    }

    await this._router.navigate(['.'], {
      relativeTo: this._route,
      queryParams: {
        ...params
      },
      queryParamsHandling: 'merge'
    }).then(() => {
      this.emitChange();
    })
  }

  emitChange() {
    this.verifyEmitVisible()
    const detail = this.ajsutDataEmiter(this.preAjustDataEmiter())
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('atualizar-pagina-filtrada-' + this.objParamName, { detail }))
    }, 10)
  }

  verifyEmitVisible() {
    let result = false;

    Object.keys(this._dataFilter).map(name => {
      if (result) return;

      if (Array.isArray(this._dataFilter[name])) {
        result = this._dataFilter[name].length > 0
      } else {
        result = !!this._dataFilter[name];
      }
    })

    this.visible.emit(result);
  }

  clearRouter() {
    this._router.navigate([], {
      queryParams: {
        [this.objParamName]: null,
      },
      queryParamsHandling: 'merge'
    }).then()
  }

  protected constructor(
    public _service: GenericCrudService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  verifyParamsStart() {
    this._route.queryParams.subscribe(resp => {
      if (!!this.objParamName) {
        if (this.objParamName in resp) {
          this.setInitParams(JSON.parse(resp[this.objParamName]), this.preAjustInitParams(JSON.parse(resp[this.objParamName])));
          this.emitChange();
          return;
        }
      }

      this.setInitParams()
    }).unsubscribe()
  }

  clear():void {
    this.clearFilter(this.preAjustDataClear())
    setTimeout(() => {
      this.clearRouter();
      this.setQueryParams();
    }, 10)
  }

  abstract objParamName: string;

  preAjustDataEmiter(): object {
    let params: any = {};

    Object.keys(this._dataFilter).map((name) => {
      if (Array.isArray(this._dataFilter[name])) {
        if (this._dataFilter[name].length > 0) {
          // @ts-ignore
          params[name] = this._dataFilter[name].join(',')
        }
      } else {
        if (!!this._dataFilter[name]) {
          params[name] = this._dataFilter[name]
        }
      }
    })

    return params;
  }

  preAjsutDataSetParams(): object {
    let params: any = {};

    Object.keys(this._dataFilter).map((name) => {
      if (Array.isArray(this._dataFilter[name])) {
        if (this._dataFilter[name].length > 0) {
          if (name in this.listSelected) {
            // @ts-ignore
            params[name] = this.listSelected[name]
          }
        }
      } else {
        if (!!this._dataFilter[name]) {
          params[name] = this._dataFilter[name]
        }
      }
    })

    return params;
  }

  preAjustDataClear(): dataSeideFilter {
    const params = {
      listsPreSelected: {},
      listSelected: <any>{},
      _dataFilter: <any>{}
    };

    Object.keys(this._dataFilter).map(name => {
      if (name in this._dataFilter) {
        if (Array.isArray(this._dataFilter[name])) {
          params._dataFilter[name] = []
          params.listSelected[name] = []
        } else {
          params._dataFilter[name] = ''
        }
      }
    })

    return params;
  }

  preAjustInitParams(data: object): dataSeideFilter {
    const params = {
      listsPreSelected: <any>{},
      listSelected: <any>{},
      _dataFilter: <any>{}
    };

    Object.keys((typeof data == 'object' ? data : {})).map(name => {
      // @ts-ignore
      if (Array.isArray(data[name])){
        // @ts-ignore
        params.listsPreSelected[name] = data[name]
        // @ts-ignore
        params.listSelected[name] = [...data[name]]
        // @ts-ignore
        params._dataFilter[name] = data[name].map(a => String(a.id))

        // @ts-ignore
      } else {
        // @ts-ignore
        params._dataFilter[name] = data[name]
      }
    })

    return params;
  }

  abstract ajsutDataSetParams(preValues: object):object

  abstract ajsutDataEmiter(preValues: object): object;

  abstract setInitParams(params?: any, preValues?: object):void

  abstract clearFilter(preValues: dataSeideFilter):void
}
