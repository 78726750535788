import { UiModule } from '../../../../shared/ui/ui.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { GalpaoArquivoMainComponent } from './galpao-arquivo-main/galpao-arquivo-main.component';


@NgModule({
  declarations: [
    GalpaoArquivoMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule
  ]
})
export class GalpaoArquivoModule { }
