
import { ReactiveFormsModule } from '@angular/forms';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoMainComponent } from './produto-main/produto-main.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ProdutoInsertEditComponent } from './produto-insert-edit/produto-insert-edit.component';
import { DirectivesModule } from 'src/app/core/directives/directives.module';




@NgModule({
  declarations: [
    ProdutoMainComponent,
    ProdutoInsertEditComponent

  ],

  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    DirectivesModule
  ]
})
export class ProdutoModule { }
