import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {DashboardFilterHelperService} from "../../services/dashboard-filter-helper/dashboard-filter-helper.service";
import {HttpService} from "../../../../../services/http-service/http.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {
  DgAutocomplete3Component
} from "@datagrupo/dg-ng-util/lib/components/dg-autocomplete3/components/dg-autocomplete3/dg-autocomplete3.component";

@Component({
  selector: 'modal-favoritos-insert-edit2',
  templateUrl: './modal-favoritos-insert-edit.component.html',
  styleUrls: ['./modal-favoritos-insert-edit.component.scss']
})
export class ModalFavoritosInsertEditComponent implements OnInit {

  @ViewChild('AutoC_unidades') AutoC_unidades!: DgAutocomplete3Component;
  @ViewChild('modal') modal!: OldModalComponent;

  @Output('afterSave') afterSave = new EventEmitter<'estoque'|'coletas'|'ranking'>()

  form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    tipoFavorito: new FormControl('', [Validators.required]),
    perfilUnidade: new FormControl('', [Validators.required]),
    unidades: new FormControl([]),
    galpao: new FormControl([]),
    motorista: new FormControl([]),
    ativo: new FormControl(''),
    tipoResiduos: new FormControl([]),
    dataInicio: new FormControl([]),
    dataFim: new FormControl([]),
    estados: new FormControl([]),
  })

  controlles: {
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de filtros que pode aparecer
    permissionFilters: string[],
  } = {
    filtersOptions: {
      unidades: [],
      transportador: [],
      tipoResiduos: [],
      motoristas: [],
      estados: [],
    },
    handleHequestsFilters: {},
    permissionFilters: [],
  }

  typeFavorito: 'coletas' | 'estoque' | 'ranking' = 'coletas';

  constructor(
    public filterHelpers: DashboardFilterHelperService,
    private http: HttpService,
    private session: SessionService
  ) {
  }

  ngOnInit(): void {
  }

  open(type: 'coletas' | 'estoque' | 'ranking', data?: any) {
    this.initPermission(type)
    this.typeFavorito = type;

    const tiposFavoritos = {
      coletas: 'DASHBOARD_COLETAS',
      estoque: 'DASHBOARD_ESTOQUE',
      ranking: 'RANKING_TAXA_RECICLAGEM',
    }

    if (data) {
      console.log(data)
      this.form.patchValue({
        ...data,
        unidades: data?.clientes?.map((item: any) => item.id) || [],
        galpao: data?.galpoes?.map((item: any) => item.id) || [],
        estados: data?.estados?.map((item: any) => item.uf) || [],
        tipoResiduos: data?.tipoLixos?.map((item: any) => item.id) || [],
        tipoFavorito: tiposFavoritos[type],
      })
    } else {
      this.form.patchValue({
        tipoFavorito: tiposFavoritos[type]
      })
    }

    this.loaderFilterList()
    this.modal.open()
  }

  initPermission(type: 'coletas' | 'estoque' | 'ranking') {
    if (type == "coletas") {
      this.form.controls['perfilUnidade'].disable()
      this.form.controls['motorista'].disable()
      this.form.controls['estados'].disable()

      if (this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])) {
        this.controlles.permissionFilters = [
          'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
          'dataFim', 'ativo'
        ]

      } else if (this.session.checkPerfil(['GALPAO'])) {
        this.controlles.permissionFilters = [
          'unidades', 'tipoResiduos', 'motoristas', 'dataInicio',
          'dataFim', 'ativo'
        ]

      } else if (this.session.checkPerfil(['PARCEIRO'])) {
        this.controlles.permissionFilters = [
          'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
          'dataFim', 'ativo'
        ]

      } else if (this.session.checkPerfil(['CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])) {
        this.controlles.permissionFilters = [
          'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
          'dataFim', 'ativo'
        ]
      }
    } else if (type == 'estoque') {
      this.form.controls['perfilUnidade'].disable()
      this.form.controls['motorista'].disable()
      this.form.controls['estados'].disable()

      if (this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])) {
        this.controlles.permissionFilters = [
          'tipoResiduos', 'dataInicio', 'dataFim', 'transportadores', 'estados'
        ]
      } else if (this.session.checkPerfil(['GALPAO'])) {

      }
    } else {
      this.form.controls['motorista'].disable()
      if (this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])) {
        this.controlles.permissionFilters = [
          'perfilUnidade', 'unidades', 'tipoResiduos', 'dataInicio', 'dataFim', 'estados'
        ]
      } else if (this.session.checkPerfil(['GALPAO'])) {

      } else if (this.session.checkPerfil([])) {

      }
    }
  }

  close() {
    this.modal.close()
  }

  clear = () => {
    this.form.reset({
      id:'',
      nome: '',
      tipoFavorito: '',
      perfilUnidade: '',
      unidades:[],
      galpao:[],
      motorista:[],
      ativo:'',
      tipoResiduos:[],
      dataInicio:[],
      dataFim:[],
      estados:[],
    });

    this.controlles.filtersOptions = {
      unidades: [],
      transportador: [],
      tipoResiduos: [],
      motoristas: [],
      estados: [],
    }
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      console.log(this.form.value)
      return;
    }

    const form = this.form.getRawValue();

    form['clientes'] = this?.AutoC_unidades?.selectedList || []
    form['galpoes'] = form?.galpao?.map((item: any) => {
      return { id: item, nome: '' }
    }) || [];
    form['motoristas'] = form?.motorista?.map((item: any) => {
      return { id: item, nome: '' }
    }) || [];
    form['tipoLixos'] = form?.tipoResiduos?.map((item: any) => {
      return { id: item, nome: '' }
    }) || [];
    delete form['unidades']
    delete form['galpao']
    delete form['tipoResiduos']
    delete form['motorista']

    let request: Observable<any>;

    debugger
    console.log(form?.id)
    if (!!form?.id) {
      request = this.http.put('favoritos', form)
    } else {
      delete form.id;
      request = this.http.post('favoritos', form)
    }

    request.subscribe(resp => {
      this.afterSave.emit(this.typeFavorito);
      this.close();
    })
  }

  // requests dos filtros, foram criados dessa forma, pois são chamados em duas
  // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
  // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    geradores: () => {
      if (!!this.controlles.handleHequestsFilters['geradores']) {
        this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
        this.controlles.handleHequestsFilters['geradores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.form, true)
      if (params['galpao']) {
        params['operadores'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
    },
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.form, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    motoristas: () => {
      if (!!this.controlles.handleHequestsFilters['motoristas']) {
        this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
        this.controlles.handleHequestsFilters['motoristas'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.form, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.form, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['transportador'] = list)
    },
    estados: () => {
      if (!!this.controlles.handleHequestsFilters['estados']) {
        this.controlles.handleHequestsFilters['estados']?.unsubscribe();
        this.controlles.handleHequestsFilters['estados'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.form, true)

      if (params['operadores']) {
        params['galpaoId'] = params['operadores'];
        delete params['operadores'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('estados', params, list => this.controlles.filtersOptions['estados'] = list)
    },
  }

  loaderFilterList(filterList?: string) {
    const rootParams = this.filterHelpers.getFormFilterValues(this.form, true)
    //TODO mudar isso para o this.requestsFilters
    const requests = {
      geradores: () => {
        if (!!this.controlles.handleHequestsFilters['geradores']) {
          this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
          this.controlles.handleHequestsFilters['geradores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['operadores'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', {}, list => this.controlles.filtersOptions['unidades'] = list)
      },
      residuos: () => {
        if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
          this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
          this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', {}, list => this.controlles.filtersOptions['tipoResiduos'] = list)
      },
      motoristas: () => {
        if (!!this.controlles.handleHequestsFilters['motoristas']) {
          this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
          this.controlles.handleHequestsFilters['motoristas'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', {}, list => this.controlles.filtersOptions['motoristas'] = list)
      },
      transportadores: () => {
        if (!!this.controlles.handleHequestsFilters['transportadores']) {
          this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
          this.controlles.handleHequestsFilters['transportadores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', {}, list => this.controlles.filtersOptions['transportador'] = list)
      },
    }

    if (!filterList) {
      if (this.controlles.permissionFilters.includes('unidades')) requests.geradores()
      if (this.controlles.permissionFilters.includes('tipoResiduos')) requests.residuos()
      if (this.controlles.permissionFilters.includes('motoristas')) requests.motoristas()
      if (this.controlles.permissionFilters.includes('transportadores')) requests.transportadores()
      if (this.controlles.permissionFilters.includes('estados')) this.requestsFilters.estados()

      return
    }

    // const mapAtualisacoes: { [key: string]: string[] } = {
    //   unidades: ['tipoResiduos', 'transportadores'],
    //   transportadores: ['unidades', 'motoristas', 'tipoResiduos'],
    //   tipoResiduos: ['transportadores', 'unidades', 'motoristas']
    // }
    //
    // if (!this.controlles.permissionFilters.includes(filterList)) return;
    // if (!(filterList in mapAtualisacoes)) return;
    //
    // if (mapAtualisacoes[filterList].includes('unidades')) {
    //   requests.geradores()
    // }
    //
    // if (mapAtualisacoes[filterList].includes('transportadores')) {
    //   requests.transportadores()
    // }
    //
    // if (mapAtualisacoes[filterList].includes('tipoResiduos')) {
    //   requests.residuos()
    // }
    //
    // if (mapAtualisacoes[filterList].includes('motoristas')) {
    //   requests.motoristas()
    // }
  }
}
