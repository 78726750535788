import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import {GalpaoMotoristaEntity} from "../galpao-motorista/galpao-motorista.entity";
import {environment} from "../../../../../environments/environment";
import {VEICULO} from "../../../../core/config/endpoint-list";

@DataServer({
  path: environment.apiUrl,
  context: VEICULO
})
export class GalpaoMotoristaVeiculoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    marca?: string,
    tipo?: string,
    capacidade?: string,
    anoFabricacao?: number,
    renavam?: number,
    numeroPlaca?: string,
    motorista?: GalpaoMotoristaEntity
  ) {
    super()
    this.marca = marca;
    this.tipo = tipo;
    this.capacidade = capacidade;
    this.anoFabricacao = anoFabricacao;
    this.renavam = renavam;
    this.numeroPlaca = numeroPlaca
    this.motorista = motorista
  }

  @DgTableColumn({ columnName: 'MARCA' })
  public marca: string | undefined;
  @DgTableColumn({ columnName: 'TIPO' })
  public tipo: string | undefined;

  public capacidade: string | undefined;

  @DgTableColumn({ columnName: 'ANO DE FABRICAÇÃO' })
  public anoFabricacao: number | undefined;

  public renavam: number | undefined;

  @DgTableColumn({ columnName: 'PLACA' })
  public numeroPlaca: string | undefined;

  public motorista: GalpaoMotoristaEntity | undefined

}
