<div class="w-100" [style.background]="direct === 'left' ? 'white' : 'none'">
  <div class="container container-section {{direct}}">
    <div *ngIf="direct === 'left'" class="row align-items-center">
      <div class="col-lx-5 col-lg-5 col-md-6 col-sm-12 div-img-section" style="padding: 0">
        <img alt="teste" [src]="img">
      </div>
      <div class="col-lx-7 col-lg-7 col-md-6 col-sm-12">
        <div class="container">
          <ng-container *ngTemplateOutlet="body"></ng-container>
          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="direct === 'right'" class="row align-items-center">
      <div class="col-lx-7 col-lg-7 col-md-6 col-sm-12">
        <div class="container">
          <ng-container *ngTemplateOutlet="body"></ng-container>
          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
      </div>
      <div class="col-lx-5 col-lg-5 col-md-6 col-sm-12 div-img-section" style="padding: 0">
        <img alt="teste" [src]="img">
      </div>
    </div>
  </div>
</div>

<ng-template #body>
  <ng-content select="[body-section]"></ng-content>
</ng-template>

<ng-template #buttons>
  <ng-content select="[buttons-section]"></ng-content>
</ng-template>
