<div
    id="cupom-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Cupom</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        [table]="tableCupom"
                        tableEventId="cumpomTable"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableCupom
                        [entityMap]="mapEntity"
                        [service]="service"
                        [showPagination]="false"
                        tableEventId="cumpomTable"
                        [register]="true"
                    ></crud-data-table>
                </div>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableCupom.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>


<old-dg-modal
    #modalCupom
    size="lg"
    title="Enviar Cupons"
>
    <div dg-content>
        <pick-list
            titleList="Missão"
            #pickList
            [listaDisponivel]="listaMissoes"
            [listaSelecionados]="listaMissoesSelecionadas"
            uniClass="nome"
            propertItem="nome"
        >
        </pick-list>
    </div>
    <div dg-footer>
        <button
            (click)="modalCupom.close()"
            class="btn btn-secondary m-1"
        >
            Fechar
        </button>
        <button
            class="btn btn-success m-1"
            (click)="convidar()"
        >
            Enviar
        </button>
        <button
            class="btn btn-success m-1"
            (click)="baixarPlanilha()"
        >
            Baixar Planilha
        </button>
    </div>
</old-dg-modal>


<page-footer>
    <a routerLink="novo">
        <button class="btn btn-success">Novo</button>
    </a>
</page-footer>
