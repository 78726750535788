import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PlanosClientesService } from "../services/planos-clientes.service";
import { SelecionarPlanoService } from "../../../selecionar-plano/service/selecionar-plano.service";
import { SelecionarPlanoEntity } from "../../../selecionar-plano/selecionar-plano.entity";
import { OldModalComponent } from "../../../../../shared/ui/modal/modal/modal.component";
import Swal from "sweetalert2";
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'planos-clientes-table',
  templateUrl: './planos-clientes-table.component.html',
  styleUrls: ['./planos-clientes-table.component.scss']
})
export class PlanosClientesTableComponent implements OnInit {

  @Input('id') id: number | string | undefined;
  @ViewChild('modalPlanos') modalPlanos!: OldModalComponent;

  public loader: boolean = true;

  public listPlanos: any[] = [];
  public planosEntity = new SelecionarPlanoEntity()
  public valorSomado: number = 0;

  public listaPlanoModal: any[] = [];
  public metodoPagamento: string = '';
  public listaPlanoSelecionados: any[] = [];
  public valorSelecionadoSomado: number = 0;

  public periodoTabela = '';

  constructor(
    private service: PlanosClientesService,
    public serviceHistorico: GenericCrudService,
    public session: SessionService
  ) {
  }


  ngOnInit(): void {
    this.getTablePlanos()
  }

  getTablePlanos() {
    if (!!this.id) {
      this.serviceHistorico.get('historicos', { params: { idCliente: this.id } }).subscribe(
        resp => {
          //@ts-ignore
          this.listPlanos = resp.data
        }
      )
    }
  }

  openModal() {
    if (!this.id) {
      alert(this.id);
      return;
    }

    this.service.get('planos/periodicidade/' + this.id).subscribe(resp => {
      //@ts-ignore
      this.listaPlanoModal = resp.data;
      this.loader = false;
    });
    this.modalPlanos.open();
  }

  alterPeriodo(periodicidadePlanoEnum: 'TRIMESTRAL' | 'SEMESTRAL' | 'ANUAL') {
    this.loader = true;

    this.service.get('planos/periodicidade/' + this.id, { params: { periodicidadePlanoEnum } }).subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data
      this.loader = false;
      this.periodoTabela = periodicidadePlanoEnum;
      this.listaPlanoModal = resp.data
    });
    this.listaPlanoSelecionados = [];
  }

  selecionarPlano(index: number, valor: number) {
    let plano = this.listaPlanoModal[index];
    if (plano) {
      this.listaPlanoSelecionados.push(plano);
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSelecionadoSomado += valor
      }
    }
  }

  removerPlano(id: number, valor: number) {
    let plano = this.listaPlanoModal[id];
    if (plano) {
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSomado -= valor
        this.listaPlanoSelecionados.splice(id, 1);
      }
    }
  }

  contratar() {
    if (!this.metodoPagamento) {
      Swal.fire({
        icon: 'error',
        title: 'Selecione uma forma de pagamento'
      }).then();
      return;
    }

    let listId: number[] = [];
    let planoContratado: {
      metodoPagamentoEnum: string,
      periodicidadePlanoEnum: string,
      planosId: number[]
    };

    this.listaPlanoSelecionados.forEach(plano => {
      listId.push(plano.id)
    })

    planoContratado = {
      metodoPagamentoEnum: this.metodoPagamento,
      periodicidadePlanoEnum: !!this.listaPlanoSelecionados[0].periodicidadePlanoEnum ? this.listaPlanoSelecionados[0].periodicidadePlanoEnum : 'TRIMESTRAL',
      planosId: listId
    };

    this.service.save(planoContratado, 'contratar/' + this.id).subscribe(
      resp => {
        this.getTablePlanos();
        Swal.fire({
          icon: 'success',
          title: 'Contratação realizada'
        });
        this.modalPlanos.close()
      }
    );
  }

  public beforeClose = () => {
    this.listaPlanoModal = [];
    this.listaPlanoSelecionados = [];
    this.valorSelecionadoSomado = 0
    this.metodoPagamento = '';
  }

  verificaSelecionado(id: number | string): boolean {

    const indexMeusPlanos = this.listaPlanoSelecionados.findIndex(
      (itemMeusPlanos: any) => {
        return itemMeusPlanos.id == id
      }

    );
    return indexMeusPlanos != -1
  }

  verificaContratado(id: number | string) {
    const indexMeusPlanos = this.listPlanos.findIndex(
      (itemMeusPlanos: any) => {
        return itemMeusPlanos.id == id
      }

    );
    return indexMeusPlanos != -1
  }

  formata_data_utc_br = (dataString: string) => {
    return new Date(dataString).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  }

  fotmataDinheiro(valor: number | string) {
    if (!Number(valor)) {
      return '0,00';
    }
    return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
