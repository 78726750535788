<div class="container-data">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <card>
            <div class="card-header">
              <h4 class="card-title">Dados</h4>
            </div>

           <div class="card-body">
              <div class="row">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                 <label for="nome" class="form-label">NOME<span class="fc-red">*</span></label>
                 <input type="text" formControlName="nome" (focusout)="createLogin()" class="form-control" id="nome">
               </div>
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                 <label for="sobrenome" class="form-label">SOBRENOME<span class="fc-red">*</span></label>
                 <input type="text" formControlName="sobrenome" (focusout)="createLogin()" class="form-control" id="sobrenome">
               </div>
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                 <label for="email" class="form-label">E-MAIL<span class="fc-red">*</span></label>
                 <input type="text" formControlName="email" class="form-control" id="email">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label for="login" class="form-label">LOGIN<span class="fc-red">*</span></label>
                <input type="text" formControlName="login" class="form-control" id="login">
              </div>
            </div>
           </div>
        </card>
      </div>
    </div>
  </form>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-deletar class="btn btn-secondary">excluir</button>
 </page-footer>

