import { ProdutoEntity } from '../produto.entity';
import { Component, OnInit } from '@angular/core';
import {ProdutoService} from "../service/produto.service";

@Component({
  selector: 'app-produto-main',
  templateUrl: './produto-main.component.html',
  styleUrls: ['./produto-main.component.scss']
})
export class ProdutoMainComponent implements OnInit {

  public mapEntity = new ProdutoEntity();

  constructor(
    public service: ProdutoService
  ) {
  }

  ngOnInit(): void {
  }

}
