<div *ngIf="session.user?.perfil == 'CONDOMINIO'">
  <!--CONTAINER-->
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h4 class="card-title">Containers</h4>
            </div>
          </div>
          <div class="card-body">
            <div class="crud-table">
              <crud-data-table
                #table
                [entityMap]="entity"
                [service]="containerServic"
                [showDefaultAction]="false"
                [customDblclick]="true"
              >
              </crud-data-table>
            </div>
          </div>
          <div class="card-footer">
            <dg-pagination [dataPagination]="entity.configEntityTable.pagination" (changePage)="table.changePages($event)"></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>
