<card-promo
  bgColor="#55a346"
  classes="card-image"
  imgBackground="/assets/img-mock/banner-inicial-01.png"
  [imag-config]="data"
>
  <div  class="card-body">
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-7 col-sm-10" style="padding: 0 0 0 2em">
        <h2 class="f-bold fc-white">
          Conheça o plano mais adequado para seu <span style="color: #f4bd0f">condomínio</span>.
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-7 col-md-8 col-sm-10" style="padding: 0 0 0 2em">
        <h5 class="fc-white">Benefícios, segurança e transparência na coleta seletiva.</h5>
      </div>
    </div>
    <div class="row" style="padding: 0 0 0 2em">
      <div class="col-12">
        <a [routerLink]="!(!!session.user.cliente_id) ? null : '/user/selecionar-plano'">
          <button
            [disabled]="!(!!session.user.cliente_id)"
            class="btn btn-success"
          >Ver Planos</button>
        </a>
      </div>
    </div>
  </div>
</card-promo>
