import { Component, OnInit } from '@angular/core';
import {MissoesService} from "../../service/missoes.service";
import {RelatorioMissoesEntity} from "../../relatorio-missoes.entity";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";

@Component({
  selector: 'relatorio-missoes',
  templateUrl: './relatorio-missoes.component.html',
  styleUrls: ['./relatorio-missoes.component.scss']
})
export class RelatorioMissoesComponent implements OnInit {

  public listMissao: RelatorioMissoesEntity[] = [];

  public entityMissoes = new RelatorioMissoesEntity();

  constructor(
    private missoesService: MissoesService,
    public service: GenericCrudService
  ) {
    this.getMissoesList()
  }

  ngOnInit(): void {
  }

  getMissoesList() {
    // this.missoesService.alterResource('missoes/lista-home')
    // @ts-ignore
    // this.missoesService.findAll({pagination: this.entityMissoes.configEntityTable.pagination}).subscribe(
    this.missoesService.get('missoes/lista-home', {params: this.entityMissoes.configEntityTable.pagination}).subscribe(
      resp => {
        //@ts-ignore
        this.listMissao = resp.data;

        this.entityMissoes.configEntityTable.pagination = {
          ...this.entityMissoes.configEntityTable.pagination,
          //@ts-ignore
          ...resp.page,
          //@ts-ignore
          page: !!resp?.page?.number ? Number(resp.page.number) : 0
        }

        //@ts-ignore
        this.entityMissoes.configEntityTable.pagination = {...this.entityMissoes.configEntityTable.pagination}
        console.log(this.listMissao)
      }
    )
  }

  realiza_calculo(meta: number | string | undefined, progresso: number | string | undefined) {
    if (!meta || !progresso) return 0
    meta = String(meta)
    progresso = String(progresso)

    var maior = (parseFloat(meta) > parseFloat(progresso)? meta : progresso);
    var menor = (parseFloat(meta) < parseFloat(progresso)? meta : progresso);

    return ((Number(menor)/Number(maior))*100).toFixed(0);
  }

  changePage(dataPagination: any) {
    console.log(dataPagination);
    this.entityMissoes.configEntityTable.pagination = {
      ...this.entityMissoes.configEntityTable.pagination,
      ...dataPagination
    }

    this.getMissoesList();
  }

}
