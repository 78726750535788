import {AbstractEntity, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import {GalpaoEntity} from "../../_galpao/galpao/galpao.entity";
import {IndustriaDestinoEntity} from "../../industria-destino/industria-destino.entity";
import {NotaFiscalProdutosEntity} from "./nota-fiscal-produtos.entity";


export class NotaFiscalEntity extends AbstractEntity{

  constructor(
    id?: number | string,
    chaveAcesso?: string,
    galpao?: GalpaoEntity,
    industria?: IndustriaDestinoEntity,
    dataNota?: string,
    numero?: string,
    validadeNota?: string,
    produtos?: NotaFiscalProdutosEntity[],
  ) {
    super();
    this.chaveAcesso = chaveAcesso;
    this.galpao = galpao;
    this.industria = industria;
    this.dataNota = dataNota;
    this.numero = numero;
    this.validadeNota = !!validadeNota ? validadeNota : '';
    this.produtos = !!Array.isArray(produtos) ? produtos : [];
  }

  @DgFilter_input('text', { name: 'Chave de acesso', colum: '6',  mask: '0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000'  })
  @DgTableColumn({ columnName: 'Chave', sort: true, mask: '0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000' })
  public chaveAcesso: string | undefined

  @DgTableColumn({ columnName: 'Transportador', sort: true, resource: (galp: GalpaoEntity) => galp.nome })
  public galpao: GalpaoEntity | undefined

  @DgTableColumn({ columnName: 'Industria de destino', sort: true, resource: (indust: IndustriaDestinoEntity) => !!indust?.nome ? indust.nome : '--' })
  public industria: IndustriaDestinoEntity | undefined

  @DgTableColumn({columnName: 'Data da nota', sort: true, resource: (val: string) => !!val ? val.split('-').reverse().join('/') : '--'})
  public dataNota: string | undefined

  @DgFilter_input('text', {colum: '6', name: "Número da nota",  mask: '000.000.000'})
  @DgTableColumn({columnName: 'Numero da nota', sort: true, mask: '000.000.000'})
  public numero: string | undefined

  @DgFilter_input('date',{name: 'Data Inicial', colum: '6', nameParamFind: 'dataInicial'})
  public dataInicial: string | undefined;
  @DgFilter_input('date',{name: 'Data Final', colum: '6', nameParamFind: 'dataFinal'})
  public validadeNota: string | undefined;
  public produtos: NotaFiscalProdutosEntity[] = [];
}
