import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {MissoesClientesEntity} from "../missoes-clientes.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MissoesClientesService extends AbstractHttpService<MissoesClientesEntity>{

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'clientes/missoes')
  }
}
