import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {HomeDocComponent} from "./home-doc/home-doc.component";
import {Autocomplete3Component} from "./doc-pages/autocomplete3/autocomplete3.component";
import {DocCardsComponent} from "./doc-pages/doc-cards/doc-cards.component";
import {DocTableComponent} from "./doc-pages/doc-table/doc-table.component";
import {DocFilterSearchComponent} from "./doc-pages/doc-filter-search/doc-filter-search.component";
import {DocNavsComponent} from "./doc-pages/doc-navs/doc-navs.component";


const routes: Routes = [
  {
    path: '',
    component: HomeDocComponent
  },
  {
    path: 'components/inputs/autocomplete',
    component: Autocomplete3Component
  },
  {
    path: 'components/cards',
    component: DocCardsComponent
  },
  {
    path: 'components/table',
    component: DocTableComponent
  },
  {
    path: 'components/filter-search',
    component: DocFilterSearchComponent
  },
  {
    path: 'components/navs',
    component: DocNavsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocRoutingModule { }
