<old-dg-modal
  #modal
  title="Editar Controle de Pesagem de Entrada"
  [afterClose]="close"
  size="lg"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row mt-2">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label f-bold">
            Indústria de destino (Destinador Final)
          </label>
          <p class="m-0" *ngIf="!verTodosDestinadores">{{listDestinadores[0]?.nome || '--'}}</p>
          <p class="m-0" *ngFor="let item of (verTodosDestinadores ? listDestinadores : [])">{{item.nome || '--'}}</p>
          <p (click)="verTodosDestinadores = true" *ngIf="listDestinadores.length > 1 && !verTodosDestinadores" class="m-0 f-bold fs-10 fc-green pointer">Ver todos</p>
          <p (click)="verTodosDestinadores = false" *ngIf="listDestinadores.length > 1 && verTodosDestinadores" class="m-0 f-bold fs-10 fc-green pointer">Ver menos</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label f-bold">
            Armazenador temporário
          </label>
          <p class="m-0" *ngIf="!verTodosArmazenadores">{{listArmazenadores[0]?.nome || '--'}}</p>
          <p class="m-0" *ngFor="let item of (verTodosArmazenadores ? listArmazenadores : [])">{{item.nome || '--'}}</p>
          <p (click)="verTodosArmazenadores = true" *ngIf="listArmazenadores.length > 1 && !verTodosArmazenadores" class="m-0 f-bold fs-10 fc-green pointer">Ver todos</p>
          <p (click)="verTodosArmazenadores = false" *ngIf="listArmazenadores.length > 1 && verTodosArmazenadores" class="m-0 f-bold fs-10 fc-green pointer">Ver menos</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-xl-4 col-sm-6 col-md-12 col-sm-12">
          <label class="form-label">N° CDF</label>
          <input class="form-control" formControlName="codigoDestinacaoFinal">
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">DATA DA COLETA</label>
          <input
            class="form-control"
            type="date"
            disabled
            [value]="dataColeta"
          >
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">MOTORISTA/CATADOR</label>
          <input
            class="form-control"
            disabled
            [value]="motorista"
          >
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <label class="form-label">
            PESO DO CAMINHÃO NA SAÍDA (KG)
            <span class="fc-red">*</span>
          </label>
          <input
            class="form-control"
            formControlName="pesoCaminhaoSaida"
            ajusteDecimal
          >
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <label class="form-label">
            PESO DO CAMINHÃO NA CHEGADA (KG)
            <span class="fc-red">*</span>
          </label>
          <input
            class="form-control"
            formControlName="pesoCaminhaoChegada"
            ajusteDecimal
          >
        </div>
      </div>

      <div class="row mt-3 mb-3 justify-content-center">
        <div class="col-auto">
          <p style="text-align: end; padding: 0; margin: 0">
            <span class="fc-black">PESO TOTAL DE RESÍDUO (KG): </span><b>{{format(pesoTotal)}}</b>
          </p>
          <p style="text-align: end; padding: 0; margin: 0">
            <span class="fc-black">PESO TOTAL DISTRIBUÍDO (KG): </span><b [class.fc-red]="pesoTotalDistribuido > pesoTotal">{{format(pesoTotalDistribuido)}}</b>
          </p>
          <p style="text-align: end; padding: 0; margin: 0">
            <span class="fc-black">PESO TOTAL (KG): </span><b class="" [class.fc-red]="(pesoTotal - pesoTotalDistribuido) != 0">{{format(pesoTotal - pesoTotalDistribuido)}}</b>
          </p>
        </div>

        <div *ngIf="(pesoTotal - pesoTotalDistribuido) != 0" class="col-auto">
          <div class="alert alert-danger" role="alert">
            <p>O peso distribuído é {{pesoTotalDistribuido > pesoTotal ? "maior" : 'menor'}} que o peso total.</p>
            <p *ngIf="!verifyPesosSaidaeChegada()">
              O peso de saída não pode ser maior que o da chegada.
            </p>
          </div>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-12">
        <dg-table class="default-table" dg-dynamic-table [dynamicTable]="table">
          <ng-template *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'ADMINISTRATIVO'])" dgTableColumn columnName="pesoTotalFinal" let-data>
            <input
              class="form-control"
              [(ngModel)]="data.row.pesoTotalFinal"
              ajusteDecimal
              (change)="updatePesoTotalDistribuido()"
            >
          </ng-template>
        </dg-table>
      </div>
      <div class="col-12">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button
      (click)="save()"
      class="btn btn-success m-1"
      dg-desable-by-period
    >
      Salvar
    </button>
    <button
      (click)="modal.close()"
      class="btn btn-secondary m-1"
    >
      Sair
    </button>
    <button
      *ngIf="pesoTotal_verificador > 0"
      (click)="distribuirPeso()"
      class="btn btn-success m-1"
    >
      Distribuir peso
    </button>
  </div>
</old-dg-modal>
