import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerMainComponent } from './container-main/container-main.component';
import { ContainerInsertEditComponent } from './container-insert-edit/container-insert-edit.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import { NgxMaskModule } from 'ngx-mask';
import {DgAutocomplete3Module, DgAutocompleteModule} from '@datagrupo/dg-ng-util';



@NgModule({
  declarations: [
    ContainerMainComponent,
    ContainerInsertEditComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        RouterModule,
        NgxMaskModule,
        DgAutocompleteModule,
        DgAutocomplete3Module
    ]
})
export class ContainerModule { }
