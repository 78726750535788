<div
  id="parceiro"
  class="container"
>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 container-data">
        <ng-container *ngTemplateOutlet="cardImage"></ng-container>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 container-data">
        <card>
          <div class="card-header">
            <h4 class="card-title">Dados</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label
                  for="nome"
                  class="form-label"
                >
                  NOME
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nome"
                  formControlName="nome"
                >
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label
                  for="email"
                  class="form-label"
                >
                  E-MAIL
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="cnpj"
                  class="form-label"
                >
                  CNPJ
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="cnpj"
                  formControlName="cnpj"
                  placeholder="00.000.000/0000-00"
                  mask="00.000.000/0000-00"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="telefone"
                  class="form-label"
                >
                  TELEFONE(DDD)
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="telefone"
                  formControlName="telefone"
                  placeholder="(00) 0000-0000"
                  mask="(00) 0000-0000"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="nomeContato"
                  class="form-label"
                >
                  NOME DO CONTATO
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nomeContato"
                  formControlName="nomeContato"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="tipoParceiro"
                  class="form-label"
                >
                  TIPO DE PARCEIRO
                  <span class="fc-red">*</span>
                </label>
                <select
                  class="form-select"
                  id="tipoParceiro"
                  formControlName="tipoParceiro"
                >
                  <option disabled>Selecione</option>
                  <option
                    *ngFor="let parceiro of listaTipoParceiro"
                    [value]="parceiro.key"
                  >
                    {{ parceiro.value }}
                  </option>
                </select>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="tipoDesconto"
                  class="form-label"
                >
                  TIPO DE DESCONTO
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="tipoDesconto"
                  formControlName="tipoDesconto"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="tipoNegocio"
                  class="form-label"
                >
                  TIPO DE NEGÓCIO
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="tipoNegocio"
                  formControlName="tipoNegocio"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label
                  for="categoriaNegocio"
                  class="form-label"
                >
                  CATEGORIA DE NEGÓCIO
                </label>
                <select
                  class="form-select"
                  id="categoriaNegocio"
                  formControlName="categoriaNegocio"
                >
                  <option disabled>Selecione</option>
                  <option
                    *ngFor="let negocio of listaCategoriaNegocio"
                    [value]="negocio.id"
                  >
                    {{ negocio.nome }}
                  </option>
                </select>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 d-flex">
                <label class="form-label labelLinkParceiro">LINK DO PARCEIRO:</label>
                <div
                  class="d-flex"
                  *ngIf="entityId == undefined"
                >
                  <span class="form-label linkParceiro fs-10">Não existe</span>
                </div>
                <div
                  class="d-flex"
                  *ngIf="entityId != undefined"
                >
                  <a
                    style="cursor: pointer;"
                    class="form-label linkParceiro fs-10"
                    (click)="linkParceiro(entityId)"
                  >
                    clique aqui
                  </a>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Modulos</label>
                <mat-select multiple formControlName="modulos">
                  <mat-option value="FINANCEIRO">Financeiro</mat-option>
                  <mat-option value="EDUCACAO_AMBIENTAL">Educação ambiental</mat-option>
                  <mat-option value="COMUNICACAO">Comunicação</mat-option>
                  <mat-option value="LOGISTICA_REVERSA">Logística reversa</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div
      *ngIf="!!tableParams?.parceiroId"
      class="container-data"
    >
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                    <h4 class="card-title">Usuários</h4>
                  </div>
                  <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                    <button
                      (click)="modalCadastroHolding.open()"
                      class="btn btn-success btn-card-header"
                    >
                      <mat-icon>add</mat-icon>
                      Cadastrar Usuários
                    </button>
                  </card-header-buttons>
                </div>
              </div>
            </div>
            <div class="card-body">
              <crud-data-table
                #tableCadastroHolding
                tableEventId="HoldingCadastroEntity"
                [entityMap]="entityCadastroHolding"
                [persistentParams]="tableParams"
              ></crud-data-table>

              <div class="d-flex justify-content-center card-footer">
                <dg-pagination
                  [dataPagination]="entityCadastroHolding.configEntityTable.pagination"
                ></dg-pagination>
                <!-- (changePage)="entityCadastroHolding.changePages($event)" -->
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6">
                    <h4 class="card-title">Endereço</h4>
                  </div>
                  <div class="col-6 d-inline-flex justify-content-end">
                    <button
                      class="btn btn-success btn-sm"
                      (click)="addCepManual()"
                    >
                      Adicionar dados manualmente
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="cep"
                    class="form-label"
                  >
                    CEP
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cep"
                    class="form-control"
                    id="cep"
                    placeholder="00.000-000"
                    mask="00.000-000"
                  >
                </div>


                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <label
                    for="logradouro"
                    class="form-label"
                  >
                    LOGRADOURO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="logradouro"
                    class="form-control"
                    id="logradouro"
                  >
                </div>
              </div>


              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="numero"
                    class="form-label"
                  >
                    NUMERO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="numero"
                    class="form-control"
                    id="numero"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="complemento"
                    class="form-label"
                  >
                    COMPLEMENTO
                  </label>
                  <input
                    type="text"
                    formControlName="complemento"
                    class="form-control"
                    id="complemento"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="bairro"
                    class="form-label"
                  >
                    BAIRRO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    formControlName="bairro"
                    type="text"
                    class="form-control"
                    id="bairro"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="cidade"
                    class="form-label"
                  >
                    CIDADE
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    formControlName="cidade"
                    type="text"
                    class="form-control"
                    id="cidade"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="uf"
                    class="form-label"
                  >
                    UF
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    formControlName="uf"
                    type="text"
                    class="form-control"
                    id="uf"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </form>
</div>

<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
  *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
   [hidden]="!entityId"
    crud-deletar
    class="btn btn-secondary"
  >
    excluir
  </button>
</page-footer>

<ng-template #cardImage>
  <card>
    <div class="card-body">
      <div class="col-12">
        <div class="div-image-parceiro w-100">
          <input
            #fileUploadPlitica
            type="file"
            [(ngModel)]="file"
            (change)="uploadParceiro($event)"
            style="display: none"
            accept="image/png, image/jpeg, image/jpg"
          >
          <img
            class="image-parceiro"
            [src]="files.getImageToSrc(entity?.imagem)"
            alt="Imagem do master"
          >
        </div>
      </div>
    </div>


    <div class="card-footer">
      <button
        [disabled]="!entity?.id"
        (click)="fileUploadPlitica.click()"
        class="btn btn-success"
      >
        Escolher
      </button>
    </div>


  </card>
</ng-template>

<app-cadastro-holding
  #modalCadastroHolding
  [parceiroId]="entity?.id"
  [parceiroNome]="entity?.nome"
></app-cadastro-holding>
