<div
    id="galpao-arquivo-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Arquivos do transportador</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        [table]="tableGalpaoArquivo"
                        tableEventId="galpaoArquivo"
                    ></filter-search>
                </div>
                <div class="card-body">
                    <crud-data-table
                        #tableGalpaoArquivo
                        [entityMap]="mapEntity"
                        [service]="servic"
                        [showPagination]="false"
                        [singleAction]="{ icon: 'download', action: download }"
                        [customDblclick]="true"
                        (dblclick)="download($event)"
                        [showDefaultAction]="session.user.perfil == 'ADMINISTRADOR' || session.user.perfil == 'GALPAO' || session.user.profile == 'SINDICO' ? true : false"
                        [register]="true"
                        tableEventId="galpaoArquivo"
                    >
                    </crud-data-table>
                </div>
                <div class="card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableGalpaoArquivo.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>

