import { AbstractEntity, DataServer, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "../../../../../environments/environment";

@DataServer({
  path: environment.apiUrl,
  context: 'dashboard/gri-datatable'
})
export class DetalhamentoGriEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    dataColeta?: string,
    unidade?: string,
    identificador?: string,
    categoria?: string,
    nomeTipoResiduo?: string,
    classeIbama?: string,
    classe?: string,
    estimativa?: string,
    peso?: string,
    tratamento?: string,
    codigoMtr?: string,
    operador?: string,
  ) {
    super();
    this.dataColeta = dataColeta;
    this.unidadeGeradora = unidade;
    this.identificacaoClienteContainer = identificador;
    this.categoriaTipoResiduo = categoria;
    this.nomeTipoResiduo = nomeTipoResiduo;
    this.codigoTipoResiduo = classeIbama;
    this.classeTipoResiduo = classe;
    this.estimativa = estimativa;
    this.peso = peso;
    this.tratamento = tratamento;
    this.codigoMtr = codigoMtr;
    this.nomeEmpresa = operador;
  }

  @DgTableColumn({ columnName: 'Data da Coleta', mask: 'date' })
  public dataColeta: string | undefined;

  @DgTableColumn({ columnName: 'Gerador' })
  public unidadeGeradora: string | undefined;

  @DgTableColumn({ columnName: 'Nome do resíduo (cliente)' })
  public identificacaoClienteContainer: string | undefined;

  @DgTableColumn({ columnName: 'Categoria Ecoplat ' })
  public categoriaTipoResiduo: string | undefined;

  @DgTableColumn({ columnName: 'Nome Ibama' })
  public nomeTipoResiduo: string | undefined;

  @DgTableColumn({
    columnName: 'Código Ibama', resource: (val: string ) => {
      return !!val ? val.split(',', 1) : '--';
    }
  })
  public codigoTipoResiduo: string | undefined;

  @DgTableColumn({ columnName: 'Classe' })
  public classeTipoResiduo: string | undefined;

  @DgTableColumn({
    columnName: 'Peso da coleta estimativa (TON)', resource: (val: string | number) => {
      return !!val ? Number(val).toLocaleString("pt-BR", { maximumFractionDigits: 2 }) : '--';
    }
  })
  public estimativa: string | undefined;

  @DgTableColumn({
    columnName: 'Peso da coletas (TON)', resource: (val: string | number) => {
      return !!val ? Number(val).toLocaleString("pt-BR", { maximumFractionDigits: 2 }) : '--';
    }
  })
  public peso: string | undefined;

  @DgTableColumn({ columnName: 'Peso recebido', resource: (val:string) => val || '--' })
  public pesoRecebido: string | undefined;

  @DgTableColumn({ columnName: 'Tratamento ' })
  public tratamento: string | undefined;

  @DgTableColumn({ columnName: 'Código MTR', resource: (val: string) => !!val ? val : '--' })
  public codigoMtr: string | undefined;

  @DgTableColumn({ columnName: 'Empresa que fez a coleta' })
  public nomeEmpresa: string | undefined;

  @DgTableColumn({ columnName: 'CNPJ da empresa que fez a coleta', mask: 'cnpj' })
  public cnpjEmpresa: string | undefined;

  @DgTableColumn({ columnName: 'Empresa de Destinação', resource: (val:string) => val || '--' })
  public nomeIndustria: string | undefined;

  @DgTableColumn({ columnName: 'CNPJ da empresa de destinação', resource: (val:string) => val || '--' })
  public cnpjIndustria: string | undefined;

  @DgTableColumn({ columnName: 'N° CDF', resource: (val:string) => val || '--' })
  public cdf: string | undefined;


}

const obj = {
  categoriaTipoResiduo: "Vidrinho MC'",
  classeTipoResiduo: "IIA",
  cnpjEmpresa: "06.887.014/0001-55",
  codigoTipoResiduo: "200010",
  dataColeta: "2019-08-26",
  estimativa: 0,
  identificacaoClienteContainer: "container - teste btn",
  nomeEmpresa: "Prórecicle",
  peso: 0,
  tratamento: "aterro sanitário",
  unidadeGeradora: "Condominio Yramaia Leme",
}
