import {AbstractEntity, DgTableColumn} from "@datagrupo/dg-crud";
import {ArquivoEntity} from "../../../../shared/entitys/arquivo.entity";


export class ArquivoNotaFiscalEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    arquivo?: ArquivoEntity
  ) {
    super();
    this.id = id;
    this.arquivo = arquivo;
  }

  @DgTableColumn({ columnName: "Nome", resource: (val: ArquivoNotaFiscalEntity) => {
    return !!val.arquivo?.fileNameOriginal ? val.arquivo?.fileNameOriginal : '--'
    } })
  public arquivo: ArquivoEntity | undefined;
}
