import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import {environment} from "../../../../../environments/environment";
import {TIPO_LIXO_GALPAO} from "../../../../core/config/endpoint-list";
import {TipoLixoEntity} from "../../tipo-lixo/tipo-lixo.entity";

@DataServer({
  path: environment.apiUrl,
  context: TIPO_LIXO_GALPAO
})
export class GalpaoTipoLixoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    galpaoId?: number | string,
    tipoLixoId?: number | string
  ) {
    super();
    this.id = id;
    this.galpaoId = galpaoId;
    this.tipoLixoId = tipoLixoId;
  }

  @DgTableColumn({
    columnName: 'Nome',
    resource: (val: string, row: TipoLixoEntity) => {
      return val || '--'
    }
  })
  nome: string | undefined;

  @DgTableColumn({
    columnName: 'Categoria',
    resource: (val: string) => {
      return val || '--'
    }
  })
  categoria: string | undefined;
  tipoLixoId: number | string | undefined;
  galpaoId: number | string | undefined;

}
