import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-dashboard2-coletas',
  templateUrl: './public-dashboard2-coletas.component.html',
  styleUrls: ['./public-dashboard2-coletas.component.scss']
})
export class PublicDashboard2ColetasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
