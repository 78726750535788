<!-- BARRA DE PROGRESSO -->
<div id="loader-dashboard" *ngIf="controlles.loaders.showLoader">
  <mat-progress-bar mode="buffer" [value]="controlles.loaders.progress().percent"></mat-progress-bar>
</div>
<!-- LINHA DE SELEÇÃO DE FILTROS -->
<div class="container-fluid" *ngIf="!session.checkProfile('MORADOR') && !isPublic">
  <div class="row">
    <div *ngIf="verifyShow.hasAnyDados" class="col-12 d-inline-flex justify-content-end">
      <button
        class="btn btn-outline dashboard-buttons"
        type="button"
        (click)="canvas.toggleOffcanvas()"
      >
        <mat-icon>search</mat-icon>
        Filtros
      </button>

      <div class="btn-group">
        <button
          class="btn btn-outline dashboard-buttons dropdown-toggle"
          data-bs-toggle="dropdown"
          data-bs-display="static"
          aria-expanded="false"
        >
          <mat-icon>settings</mat-icon>
          Opções
        </button>

        <ul class="dropdown-menu dropdown-menu-lg-end">
          <li>
            <button class="dropdown-item" type="button" (click)="compartilharRelatorio()">
              <mat-icon class="icon-pre">share</mat-icon>
              <span>Compartilhar Relatório</span>
              <mat-icon
                class="icon-pos"
                matTooltip="Não será necessário login para abrir esse link"
                aria-label="Não será necessário login para abrir esse link"
              >contact_support
              </mat-icon>
            </button>
          </li>
          <li>
            <a
              id="link-print-coletas" hidden
              routerLink="/private/impressao/dadhboard/coletas"
              [queryParams]="dataDashboard.queryPrint"
              target="_blank"
            ></a>
            <span (click)="print()">
              <button class="dropdown-item" type="button">
                <mat-icon class="icon-pre">print</mat-icon>
                <span>Imprimir</span>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr class="fc-grey">
    </div>
  </div>
</div>
<!-- CONTAINER DE SELEÇÃO DE FILTROS -->
<div class="container container-amostra" *ngIf="!verifyShow.hasAnyDados && !controlles.loaders.showLoader">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div style="text-align: center">
        <h4 class="card-title">Selecione os filtros</h4>
        <p *ngIf="verifyShow.hasAnyDados === false" class="card-subtitle">Filtros insuficientes para gerar dados</p>
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          (click)="canvas.toggleOffcanvas()"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>
      </div>
    </div>
  </div>
</div>
<!-- CONTEUDO DA TELA -->
<div class="container-fluid" *ngIf="verifyShow.hasAnyDados">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12">
              <div class="row justify-content-between">
                <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                  <card>
                    <div class="card-body">
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold">Número de clientes</span>
                      </div>
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold fs-15">{{dataDashboard.cardInfo.numeroClientes}}</span>
                      </div>
                    </div>
                  </card>
                </div>

                <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                  <card>
                    <div class="card-body">
                      <div class="w-100 d-inline-flex justify-content-center">
                      <span matTooltip="Número de apartamentos/departamento/lojas"
                            class="destaque-claro f-bold">Ambientes</span>
                      </div>
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold fs-15">{{dataDashboard.cardInfo.numeroApartmentos}}</span>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>

  <div class="row d-flex align-items-stretch">
    <div
      *ngIf="controlles.loaders.getItemLoader('anos').finish && !controlles.loaders.getItemLoader('anos').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Ano</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['ano'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['ano'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['ano'] = 'table'"
                    [class.active]="verifyShow.viewGraph['ano'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['ano'] != 'graph'">
          <div id="id-chart-anos"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['ano'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estimativa (ton)</th>
              <th>Peso</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.ano || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estimativa}}</td>
              <td>{{item?.seriesData?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="controlles.loaders.getItemLoader('meses').finish && !controlles.loaders.getItemLoader('meses').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Meses</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['meses'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['meses'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['meses'] = 'table'"
                    [class.active]="verifyShow.viewGraph['meses'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['meses'] != 'graph'">
          <div id="id-chart-meses"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['meses'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estimativa (ton)</th>
              <th>Peso</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.meses || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estimativa}}</td>
              <td>{{item?.seriesData?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="controlles.loaders.getItemLoader('totais').finish && !controlles.loaders.getItemLoader('totais').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Totais</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['total'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['total'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['total'] = 'table'"
                    [class.active]="verifyShow.viewGraph['total'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['total'] != 'graph'">
          <div id="id-chart-totais"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['total'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estimativa (ton)</th>
              <th>Peso</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.totais || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estimativa}}</td>
              <td>{{item?.seriesData?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="controlles.loaders.getItemLoader('tipoResiduos').finish && !controlles.loaders.getItemLoader('tipoResiduos').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Resíduos</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['residuos'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['residuos'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['residuos'] = 'table'"
                    [class.active]="verifyShow.viewGraph['residuos'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['residuos'] != 'graph'">
          <div id="id-chart-residuos"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['residuos'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Percentual</th>
              <th>Peso em (ton)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.residuos || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.percentage}}</td>
              <td>{{item?.y |json}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
      *ngIf="controlles.loaders.getItemLoader('motoristas').finish && !controlles.loaders.getItemLoader('motoristas').error"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Motorista/Catador</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['motoristas'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['motoristas'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['motoristas'] = 'table'"
                    [class.active]="verifyShow.viewGraph['motoristas'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['motoristas'] != 'graph'">
          <div id="id-chart-motoristas"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['motoristas'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estimativa (ton)</th>
              <th>Peso</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.motoristas || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estimativa}}</td>
              <td>{{item?.seriesData?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
      *ngIf="controlles.loaders.getItemLoader('tratamento').finish && !controlles.loaders.getItemLoader('tratamento').error"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Tratamento</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['tratamento'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['tratamento'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['tratamento'] = 'table'"
                    [class.active]="verifyShow.viewGraph['tratamento'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['tratamento'] != 'graph'">
          <div id="id-chart-tratamento"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['tratamento'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estimativa (ton)</th>
              <th>Peso</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.tratamento || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estimativa}}</td>
              <td>{{item?.seriesData?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
      *ngIf="controlles.loaders.getItemLoader('gruposResiduos').finish && !controlles.loaders.getItemLoader('gruposResiduos').error"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Gráfico de grupos de resíduos</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['gruposResiduos'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['gruposResiduos'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item disabled">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['gruposResiduos'] = 'table'"
                    [class.active]="verifyShow.viewGraph['gruposResiduos'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['gruposResiduos'] != 'graph'">
          <div id="id-chart-gruposResiduos"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['gruposResiduos'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Percentual</th>
              <th>Peso (ton)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.gruposResiduos || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.percentual}}</td>
              <td>{{item?.peso}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="col-12 mt-1 mb-1"
      *ngIf="controlles.loaders.getItemLoader('lixoZero').finish && !controlles.loaders.getItemLoader('lixoZero').error"
    >
      <div class="card">
        <div class="card-header">
          <h4>Gráfico de lixo zero</h4>
        </div>
        <div class="card-body">
          <div id="id-chart-aterro-zero"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="controlles.listLoaderPermission.includes('tableGerador')">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <h4 class="card-title">Medição de coletas</h4>
              </div>

              <card-header-buttons *ngIf="!isPublic" class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <button class="btn btn-success btn-card-header" (click)="downloadExcelDetalhamento()">
                  <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                  Download Excel
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>
        <div class="card-body body-table table-gerador">
          <dg-table classes="default-table" classes="default-table" dg-dynamic-table [dynamicTable]="tableGerador" [initAutoLoader]="false"></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableGerador"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- SIDE BAS FILTER -->
<canvas-side-filter
  #canvas
  (clear)="clearFilters()"
  (closed)="initLoaderData()"
  (uploadeFavoritoList)="$event == 'coletas' ? loaderFavoritos() : null"
>
  <form [formGroup]="formFilter">
    <div class="row">
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('favoritos')">
        <label class="form-label form-label-sm">Favoritos</label>
        <dg-autocomplete3
          #favoritos
          key="id" label="nome"
          classes="form-control form-control-sm" formControlName="favoritos"
          [data]="controlles.filtersOptions['favoritos']"
          dg-dynamic-group="estoque" paramName="favoritos" typeComunication="change" (change)="propagarFavorito()"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('unidades')">
        <label class="form-label form-label-sm">Geradores</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control form-control-sm" formControlName="unidades"
          [data]="controlles.filtersOptions['unidades']"
          dg-dynamic-group="estoque" paramName="unidades" typeComunication="change" (change)="changeFilters('unidades')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('transportadores')">
        <label class="form-label form-label-sm">Transportador</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control form-control-sm" formControlName="galpao"
          [data]="controlles.filtersOptions['transportador']"
          dg-dynamic-group="estoque" paramName="transportador" typeComunication="change"
          (change)="changeFilters('transportadores')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('motoristas')">
        <label class="form-label form-label-sm">Motorista/Catador</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('motoristas')"
          classes="form-control form-control-sm" formControlName="motorista"
          [data]="controlles.filtersOptions['motoristas']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataInicio')">
        <label class="form-label form-label-sm">Data Início</label>
        <input type="date" formControlName="dataInicio" class="form-control form-control-sm" (change)="changeFilters()">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataFim')">
        <label class="form-label form-label-sm">Data Fim</label>
        <input type="date" formControlName="dataFim" class="form-control form-control-sm" (change)="changeFilters()">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('tipoResiduos')">
        <label class="form-label form-label-sm">Tipos de Resíduo</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('tipoResiduos')"
          classes="form-control" formControlName="tipoResiduos" [data]="controlles.filtersOptions['tipoResiduos']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('gruposResiduos')">
        <label class="form-label form-label-sm">Grupos tipos de Resíduo</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('gruposResiduos')"
          classes="form-control" formControlName="gruposTipoResiduo" [data]="controlles.filtersOptions['gruposResiduos']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('tratamento')">
        <label class="form-label form-label-sm">Tratamento</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('tratamento')"
          classes="form-control" formControlName="tratamentos" [data]="controlles.filtersOptions['tratamento']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('ativo')">
        <label class="form-label form-label-sm">Ativo/Inativo</label>
        <select class="form-select form-select-sm" formControlName="ativo" (change)="changeFilters('ativo')">
          <option value="">Ambos</option>
          <option [value]="true">Ativos</option>
          <option [value]="false">Inativos</option>
        </select>
      </div>
    </div>
  </form>
</canvas-side-filter>
<!-- LOADER DE INFORMAÇÕES -->
<div class="dashboard-custom-loader" [class.show]="verifyShow.loaderInfo">
  <mat-spinner></mat-spinner>
</div>
