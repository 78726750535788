import Swal from 'sweetalert2';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractInsertEdit, HttpClient, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {LogisticaReversaEntity} from '../logistica-reversa.entity';
import {LogisticaReversaService} from '../service/logistica-reversa.service';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {LogisticaDocumentoEntity} from "../logistica-documento.entity";
import {DocumentosComponent} from "../subComponentes/documentos/documentos.component";
import {ProdutosComponent} from '../subComponentes/produtos/produtos.component';
import {LogisticaProdutosEntity} from '../logistica-produtos.entity';
import {CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS_DOWNLOAD} from "../../../../core/config/endpoint-list";
import {FilesManageService} from "../../../../services/files-manage/files-manage.service";
import {HttpHelpersService} from "../../../../services/http-helpers/http-helpers.service";
import {defaultCallBackErrorGetCep, defaultCallBackSuccessGetCep, fotmataDinheiro} from "../../../../helpers/helpers";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ParceiroEntity} from "../../parceiro/parceiro.entity";


@Component({
  selector: 'app-logistica-reversa-insert-edit',
  templateUrl: './logistica-reversa-insert-edit.component.html',
  styleUrls: ['./logistica-reversa-insert-edit.component.scss']
})
export class LogisticaReversaInsertEditComponent extends AbstractInsertEdit<LogisticaReversaEntity> implements OnInit {

  @ViewChild('modalDocumentos') modalDoc!: DocumentosComponent
  @ViewChild('modalProdutos') modalProd!: ProdutosComponent

  public paramsTables: { [key: string]: string | number | undefined } = {};
  public mapEntity = new LogisticaReversaEntity()
  public listParceiro: ParceiroEntity[] = [];
  public formataValorDinheiro = fotmataDinheiro;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    nomeContato: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    comprovante: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    bairro: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
    produtoId: new FormControl(''),
    clienteCreditoId: new FormControl('')
  })

  public entityLogisticaReversaProdutos = new LogisticaProdutosEntity();
  public entityLogisticaReversaDocumentos = new LogisticaDocumentoEntity();

  public valorMensal = '';
  public valorAnual = '';

  constructor(
    public service: LogisticaReversaService,
    public conf: ConfigDgCrudService,
    public http: HttpClient,
    private files: FilesManageService,
    private httpHelp: HttpHelpersService,
    public session: SessionService,
  ) {
    super(conf, service);

    this.crudConfig.backAfterFetchError = false

    if (!this.session.checkPerfil('PARCEIRO')) {
      service.get('parceiros', { params: { unpaged: true } }).subscribe(
        resp => {
          //@ts-ignore
          this.listParceiro = resp.data;
        }
      )
    }

    this.entityLogisticaReversaProdutos.addActions(<InterfaceCustomActions[]>[
      {
        name: 'editar',
        action: (row: LogisticaProdutosEntity) => {
          this.modalProd.open(row)
        }
      }
    ])

    this.entityLogisticaReversaDocumentos.addActions(<InterfaceCustomActions[]>[
      {
        name: 'editar',
        action: (row: LogisticaDocumentoEntity) => {
          this.modalDoc.open(row)
        }
      },
      {
        name: 'download',
        action: (row: LogisticaDocumentoEntity) => {
          this.clickDownload(row.id)
        }
      }

    ])

    this.form.controls['cep'].valueChanges.subscribe(
      (cep: string) => {
        if (cep == this.form.value.cep) return

        this.httpHelp.getCep(cep,
          data => {
            this.form.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "uf": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )
      }
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new LogisticaReversaEntity()
  }

  override afterFetchEntity() {

    this.paramsTables['clienteCreditoId'] = this.entity.id;

    this.form.patchValue({
      ...this.entity,
      ...this.entity.endereco,

    })

    if (this.entity?.produto) {
      this.entity.produto = [];
    }
    if (this.entity?.documento) {
      this.entity.documento = [];
    }
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      console.log(this.form.value)
      return false;
    }
    const {bairro, cep, cidade, numero, complemento, logradouro, uf, ...form} = this.form.getRawValue();

    this.entity = {
      ...this.entity,
      ...form,


      endereco: {
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: uf
      }
    }
    return true;
  }

  override afterSaveEntity() {
    this.paramsTables['clienteCreditoId'] = this.entity.id;
  }

  public salvarProduto(data: any) {
    console.log(data);
    if (typeof data.index == 'number') {
      this.entity.produto.splice(data.index, 1, <LogisticaProdutosEntity>{
        //nome: data.prod.id,
        clienteCreditoId: data.clienteCreditoId,
        produto: data.prod.produto,
        dataInclusao: data.prod.dataInclusao,
        qtdProdutoGerado: data.prod.qtdProdutoGerado,
        porcentagemEmbalagens: data.prod.porcentagemEmbalagensMercado,


      });
    } else {
      this.entity.produto.push(<LogisticaProdutosEntity>{
        // nome: data.prod.id,
        clienteCreditoId: data.clienteCreditoId,
        produto: data.prod.produto,
        dataInclusao: data.prod.dataInclusao,
        qtdProdutoGerado: data.prod.qtdProdutoGerado,
        porcentagemEmbalagens: data.prod.porcentagemEmbalagens
      })
    }
  }

  public salvarDoc(data: any) {
    console.log(data);
    if (typeof data.index == 'number') {
      this.entity.documento.splice(data.index, 1, <LogisticaDocumentoEntity>{

        produto: data.doc.produto,
        quantidade: data.doc.quantidade,
        clienteCreditoId: data.doc.clienteCreditoId,
        nome: data.doc.docName
      });
    } else {

      this.entity.documento.push(<LogisticaDocumentoEntity>{
        produto: data.doc.produto,
        quantidade: data.doc.quantidade,
        clienteCreditoId: data.doc.clienteCreditoId,
        nome: data.doc.docName
      })
    }
  }

  public dictionaryTable = (val: any) => {
    this.valorAnual = val.valorAnualDaCobrancaDaLogistica;
    this.valorMensal = val.valorMensalDaCobrancaDaLogistica;
    return val.clienteCreditoLogisticaReversaProduto;
  }

  clickDownload(id: any) {
    this.service.get(CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS_DOWNLOAD + id).subscribe(
      resp => {
        if (resp.data.file) {
          this.files.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
        }
      }
    )
  }

  info(){
    Swal.fire({
      icon: 'info',
      title: 'Aqui você pode adicionar, documentos com notas de compras de embalagens, nota de vendas de produtos, relatórios operacionais de geração de embalagens para aumentar o nível das operações e conseguir gerenciar de forma mais simples a meta de logística reversa da sua empresa.',
      showCancelButton: false,
    })
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  setParceiro(parceiro: ParceiroEntity) {
    if (parceiro.id) {
      this.entity.parceiro = parceiro
    }
  }
}

