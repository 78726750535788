import { Injectable } from '@angular/core';
import { AbstractHttpService, HttpUpdateServiceInterface } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { IndicadoresEntity } from '../indicadores.entity';

@Injectable({
  providedIn: 'root',
})
export class IndicadoresService extends AbstractHttpService<IndicadoresEntity> {
  constructor(public conf: ConfigDgCrudService) {
    super(conf, environment.apiUrl, 'indicador');
  }

  override update(data: any): Observable<any> {
    return this.put(this.context, data)
  }
}
