import { AbstractEntity } from "@datagrupo/dg-crud";


export interface interfMissao {
  id: number,
  nome: string
}

export class RelatorioMissoesEntity extends AbstractEntity {

  constructor(
    id?: number,
    meta?: number,
    progresso?: number,
    nome?: string,
    numeroDeColetas?: number,
    qtdMoradores?: number,
  ) {
    super();
    this.id = id;
    this.meta = meta;
    this.progresso = progresso;
    this.nome = nome;
    this.numeroDeColetas = numeroDeColetas;
    this.qtdMoradores = qtdMoradores;

    this.configEntityTable.pagination = {
      ...this.configEntityTable.pagination,
      size: 5
    };
  }

  public meta: number | undefined
  public progresso: number | undefined;
  public nome: string | undefined
  public numeroDeColetas: number | undefined
  public qtdMoradores: number | undefined
}
