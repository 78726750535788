<div class="row mt-3">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header w-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h4 class="card-title">Dados de MTR</h4>
            </div>
            <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <button
                *ngIf="checkPermission()"
                (click)="editMtr.patchValue(!editMtr.value)"
                class="btn btn-success btn-card-header"
              >
                Editar
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> INSTITUTO </label>
              <select class="form-select" formControlName="instituto">
                <option disabled value="">Selecione um instituto</option>
                <option value="SIGOR">Sigor</option>
                <option value="SINIR">Sinir</option>
                <option value="INEA">Inea</option>
              </select>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> CNPJ </label>
              <input
                class="form-control"
                formControlName="cnpjMtr"
                mask="00.000.000/0000-00"
              >
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> EMAIL </label>
              <input type="email" class="form-control" formControlName="emailMtr"/>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">
                SITE DO MANIFESTO
              </label>
              <input class="form-control" formControlName="siteManifesto"/>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">
                CÓDIGO DO USUÁRIO (CPF)
              </label>
              <input
                class="form-control"
                formControlName="cpfUsuario"
                mask="000.000.000-00"
              >
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> RESPONSÁVEL </label>
              <input class="form-control" formControlName="responsavel"/>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> CARGO </label>
              <input class="form-control" formControlName="cargo"/>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> CÓDIGO </label>
              <input class="form-control" formControlName="codigo"/>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label"> SENHA </label>
              <input class="form-control" formControlName="senha"/>
            </div>
          </div>
        </form>
      </div>
    </card>
  </div>
</div>

<div
  *ngIf="!!entity?.id"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h4 class="card-title">MTR's Provisórios</h4>
            </div>
            <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <button
                *ngIf="checkPermission()"
                (click)="modalArquivo2.open()"
                class="btn btn-success btn-card-header"
              >
                <mat-icon>add</mat-icon>
                Cadastrar
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body">
        <crud-data-table
          #tableArquivo
          [entityMap]="arquivosEntity"
          [persistentParams]="{clienteId: entity?.id}"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="arquivosEntity.configEntityTable.pagination"
          (changePage)="tableArquivo.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>
</div>

<subComponent-arquivos-cliente-modal
  #modalArquivo2
  [idCliente]="entity?.id"
  (atualizarTabela)="tableArquivo?._pesquisar()"
></subComponent-arquivos-cliente-modal>
