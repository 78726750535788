import { AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { ClientesEntity } from "../_clientes/clientes/clientes.entity";
import { GalpaoEntity } from "../_galpao/galpao/galpao.entity";
import { MeuPerfilEntity } from './../meu-perfil/meu-perfil.entity';
import { MissaoEntity } from './../missao/missao.entity';
import { environment } from "src/environments/environment";
import {missaoAutocompleteOutput} from "../../../helpers/auto-complete/missao-autocomplete.helper";
import {autoCompleteClientesOutPut} from "../../../helpers/auto-complete/clientes-autocomplete.helper";
import {cupomRelatorioAutocompleteOutput} from "../../../helpers/auto-complete/cupom-autocomplete.helper";
import { formata_data_utc_br } from "src/app/helpers/helpers";


export class CupomRelatorioEntity extends AbstractEntity {

  constructor(

    id?: number | string,
    nome?: CupomRelatorioEntity,
    dataInicio?: string,
    dataFim?: string,
    cliente?: ClientesEntity,
    clienteId?: ClientesEntity,
    cupom?: CupomRelatorioEntity,
    cupomId?: CupomRelatorioEntity,
    missaoId?: MissaoEntity,
    missao?: MissaoEntity,
    usuario?: MeuPerfilEntity,
    dataInclusao?: string


  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.cliente = cliente;
    this.clienteId = clienteId;
    this.cupom = cupom;
    this.cupomId = cupomId;
    this.missaoId = missaoId;
    this.missao = missao;
    this.usuario = usuario;
    this.dataInclusao = dataInclusao;
  }

  @DgFilter_input('date', { colum: '3', name: 'DATA INICIAL' })
  public dataInicio: string | undefined;

  @DgFilter_input('date', { colum: '3', name: 'DATA FINAL' })
  public dataFim: string | undefined;

  @DgTableColumn({ columnName: 'DATA DO RESGATE', sort: true, resource: (data: string) => {
  return !!data ?  formata_data_utc_br(data) : '--'}})
    public dataInclusao:  string | undefined


  @DgFilter_autoComplete({ name: 'NOME DO CUPOM' },
  {
    getUrl: environment.apiUrl+'cupom/nomes',
    getServerData: "all",
    propertValue: 'nome',
    //propertKey: "id" ,
    nameParamFind: 'cupomId',
    outputFormat: cupomRelatorioAutocompleteOutput
  })
  public cupomId: CupomRelatorioEntity |undefined;

  @DgTableColumn({ columnName: 'CUPOM', sort: true, resource: (cupom: CupomRelatorioEntity) => !!cupom.nome ? cupom.nome : '--' })
  public cupom: CupomRelatorioEntity | undefined;

  @DgFilter_autoComplete ({ name: 'NOME DA MISSÃO' },
  {
    getUrl: environment.apiUrl+'missao/nomes',
    getServerData: "all",
    propertValue: 'nome',
    propertKey: "id",
    nameParamFind: 'missaoId',
    outputFormat: missaoAutocompleteOutput
  })
  public missaoId: MissaoEntity | undefined

  @DgTableColumn({ columnName: 'MISSÃO', sort: true, resource: (missao: MissaoEntity) => !!missao.nome ? missao.nome : '--' })
  public missao: MissaoEntity | undefined

  @DgTableColumn({ columnName: 'USUÁRIO', sort: true, resource: (cel: MeuPerfilEntity) => !!cel.nome ? cel.nome : '--' })
  public usuario: MeuPerfilEntity | undefined;

  @DgFilter_autoComplete({ name: 'NOME DO GERADOR' },
  {
    getUrl: environment.apiUrl+'clientes/nomes',
    getServerData: "all",
    propertValue: 'nome',
    propertKey: "id",
    nameParamFind: 'clienteId',
    outputFormat: autoCompleteClientesOutPut
  })
  public clienteId : ClientesEntity | undefined

  @DgTableColumn({ columnName: 'GERADOR', resource: (cond: ClientesEntity) => !!cond.nome ? cond.nome : '--' })
  public cliente : ClientesEntity | undefined

  public nome: CupomRelatorioEntity | undefined

}
