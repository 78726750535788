import { AbstractEntity, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";

export interface interEstado {
  uf?: string,
  name?: string,
  ano?: string,
  link?: string,
  reciclagem?: string,
}

export class IndicadoresEntity extends AbstractEntity {

  constructor(
    id?: number,
    estado?: interEstado,
    anoReferencia?: string,
    linkReferencia?: string,
    reciclagemEstado?: number,
  ) {

    super();
    this.anoReferencia = anoReferencia;
    this.linkReferencia = linkReferencia;
    this.reciclagemEstado = reciclagemEstado;
    this.id = id;
    this.estado = !!estado ? estado : {
      uf: '',
      name: ''
    };
  }

  @DgFilter_input('text', { colum: '4', name: 'ESTADO' })
  public localizacao: string | undefined
  @DgTableColumn({
    columnName: 'ESTADO', resource: (val: interEstado, row: IndicadoresEntity) => {
      return !!val?.name ? val.name : "--"
    }
  })
  public estado: interEstado | undefined

  @DgTableColumn({
    columnName: 'VALOR %', resource: (val: string) => {
      return !!val ? Number(val).toLocaleString("pt-BR") + '%' : '--';
    }
  })
  public valorIndicador: number | undefined

  @DgTableColumn({
    columnName: 'RECICLAGEM DO ESTADO (TON)', resource: (val: number) => {
      return !!val ? val.toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      }) : '--';
    }
  })
  public reciclagemEstado: number | string | undefined

  @DgTableColumn({
    columnName: 'ANO', resource: (val: number) => {
      return !!val ? val : '--';
    }
  })
  public anoReferencia: string | undefined

  // @DgTableColumn({ columnName: 'LINK DE REFERÊNCIA', resource: (val: number | string) => {
  //   return !!val ? val : '--'
  // }})
  public linkReferencia: string | undefined

  public uf: string | undefined
  public name: string | undefined

}
