import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartSessionComponent } from './start-session/start-session.component';
import {UiModule} from "../../shared/ui/ui.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    StartSessionComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatProgressSpinnerModule
  ]
})
export class AuthModule { }
