import {LogisticaReversaEntity} from '../logistica-reversa.entity';
import {Injectable} from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import { Enderecos } from 'src/app/shared/entitys/enderecos';

@Injectable({
  providedIn: 'root'
})
export class LogisticaReversaService extends AbstractHttpService<LogisticaReversaEntity> {

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'cliente-credito-logistica-reversa')

    this.dictionary.findOne = (val: LogisticaReversaEntity) => {
      //@ts-ignore
      if (typeof val.endereco === 'object') {
      //@ts-ignore
      val.enderecos = <Enderecos[]>[val.endereco]
      }

      return val;
    }
  }
}
