import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicDashboardMainComponent } from './public-dashboard-main/public-dashboard-main.component';
import {DashboardModule} from "../../_user/dashboard/dashboard.module";



@NgModule({
  declarations: [
    PublicDashboardMainComponent
  ],
  imports: [
    CommonModule,
    DashboardModule
  ]
})
export class PublicDashboardModule { }
