import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit {

  @Input('image') image: string | undefined;
  @Input('icon') icon: string | undefined;
  @Input('text') text: string | undefined;
  // @Input('textColor') textColor: string | undefined;
  @Input('href') href: string | undefined;
  @Input('classes') classes: string = '';
  @Input('size') size: 'sm' | 'md' | 'lg' = "md";
  @Input('subText') subText: string | undefined;
  @Input('fragment') fragment: string | undefined
  @Input('disable') disable: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
