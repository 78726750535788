<div id="solicitacao-cadastro" class="container">
  <div>
    <form [formGroup]="form">
      <div class="container-data">
        <div class="row">
          <div class="col-12">
            <div class="card-header-buttons div-button">
              <div class="row justify-content-between d-inline-flex">

                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <button class="btn btn-success" (click)="escolhePerfil('OPERADOR_LOGISTICO')" [ngClass]="perfilCadastro == 'OPERADOR_LOGISTICO' ? 'btn-selecionado' : '' ">
                    Transportador
                  </button>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12" >
                  <button class="btn btn-success" id="cond" (click)="escolhePerfil('CONDOMINIO')" [ngClass]="perfilCadastro == 'CONDOMINIO' ? 'btn-selecionado' : '' ">
                    Gerador (Condomínio)
                  </button>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <button class="btn btn-success" (click)="escolhePerfil('EMPRESA')" [ngClass]="perfilCadastro == 'EMPRESA' ? 'btn-selecionado' : '' ">
                    Gerador (Empresas)
                  </button>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <button class="btn btn-success" (click)="escolhePerfil('EVENTO')" [ngClass]="perfilCadastro == 'EVENTO' ? 'btn-selecionado' : '' ">
                    Gerador (Eventos)
                  </button>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <button class="btn btn-success" (click)="escolhePerfil('CASA')" [ngClass]="perfilCadastro == 'CASA' ? 'btn-selecionado' : '' ">
                    Gerador (Casas)
                  </button>
                </div>
              </div>
            </div>

            <!-- CADASTRO GERADOR -->
            <div class="card-body" *ngIf="perfilCadastro == 'CONDOMINIO'; else vazio">
              <div class="row mt-5">
                <h5 class="perfil fs-10">CADASTRO DE GERADOR</h5>
                <div class="row d-flex mb-3">
                  <span class="text-center">Após finalizar seu cadastro</span>
                  <span class="text-center">
                    você terá acesso completo ao nosso
                    <strong class="fc-green"> sistema de gestão </strong> e
                    poderá acompanhar todo processo da reciclagem.</span>
                  <span class="fc-green text-center">Suas ações ajudam nosso planeta e trazem benefícios para a
                    sua Gerador!</span>
                </div>

                <div class="row">

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <select class="form-select" formControlName="tipoDeCadastro" id="tipoDeCadastro" formControlName="tipoDeCadastro">
                      <option value="" disabled selected>Tipo de cadastro</option>
                      <option value="SINDICO">Gestor</option>
                      <option value="CONSELHO">Conselho/Administrativo</option>
                      <option value="MORADOR">Usuário</option>
                      <option value="PORTEIRO">Operador</option>
                      <option value="FINANCEIRO">Financeiro</option>
                    </select>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Nome *" class="form-control" formControlName="nome" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Sobrenome *" class="form-control" formControlName="sobrenome" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Login *" class="form-control" formControlName="login" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Telefone(DDD) *" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                      formControlName="telefone" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input placeholder="Nome do gerador" name="nomeCondominio" class="form-control"
                      formControlName="nomeCondominio">


                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Número de Apartamentos do gerador" class="form-control"
                      formControlName="qtdApartamentos" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3" *ngIf="!!entity">
                    <label class="form-label col-12 d-inline-flex justify-content-center"
                      >QUANTOS QUARTOS TEM POR APARTAMENTO?</label>
                    <div class="col-12 d-inline-flex justify-content-center">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" id="item-qtd-1" type="checkbox"
                          (click)="checkedQuartosApartamentos('UM')" [checked]="verifyCheked('UM')" />
                        <label class="form-check-label" for="item-qtd-1">1</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" id="item-qtd-2" type="checkbox"
                          (click)="checkedQuartosApartamentos('DOIS')" [checked]="verifyCheked('DOIS')" />
                        <label class="form-check-label" for="item-qtd-2">2</label>
                      </div>


                      <div class="form-check form-check-inline">
                        <input class="form-check-input" id="item-qtd-3" type="checkbox"
                          (click)="checkedQuartosApartamentos('TRES')" [checked]="verifyCheked('TRES')" />
                        <label class="form-check-label" for="item-qtd-3">3</label>
                      </div>


                      <div class="form-check form-check-inline">
                        <input class="form-check-input" id="item-qtd-4" type="checkbox"
                          (click)="checkedQuartosApartamentos('QUATRO')" [checked]="verifyCheked('QUATRO')" />
                        <label class="form-check-label" for="item-qtd-4">4</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" id="item-qtd-5" type="checkbox"
                          (click)="checkedQuartosApartamentos('CINCO_OU_MAIS')"
                          [checked]="verifyCheked('CINCO_OU_MAIS')" />
                        <label class="form-check-label" for="item-qtd-5">+5</label>
                      </div>

                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="email" placeholder="email@email.com.br" class="form-control" formControlName="email" />
                  </div>

                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <label>cep<small class="fc-red">*</small></label>
                      <input type="text"  class="form-control" placeholder="00000-000" mask="00000-000"
                        formControlName="cep">
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <label>logradouro<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="logradouro" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>número<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="numero">
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>complemento</label>
                      <input type="text" class="form-control" formControlName="complemento">
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-6">
                      <label> bairro<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="bairro" readonly>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>cidade<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="cidade" readonly>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>uf<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="estado" readonly>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-8 col-xs-12">
                    <label class="conteudo" for="conteudo">Você aceita receber conteúdo sobre práticas sustentáveis
                      e dicas para melhorar sua gestão de resíduos?</label>
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" checked type="radio" name="infoColeta" id="sim" formControlName="infoColeta"
                            value=true />
                          <label for="sim">Sim</label>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" type="radio" name="infoColeta" id="nao" formControlName="infoColeta"
                            value="false" />
                          <label for="nao">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-4 justify-content-end">
                    <div class="col-2">
                      <button class="btn btn-success" (click)="voltarLogin()">
                        Voltar
                      </button>
                    </div>
                    <div class="col-2">
                      <button class="btn btn-success" (click)="salvarInfo()">
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CADASTRO CASAS -->
            <div>
              <div class="row mt-5" *ngIf="perfilCadastro == 'CASA'; else vazio">
                <h5 class="perfil fs-10">CADASTRO DE CASA</h5>
                <div class="row d-flex mb-3">
                  <span class="text-center">Após finalizar seu cadastro</span>
                  <span class="text-center">
                    você terá acesso completo ao nosso
                    <strong class="fc-green"> sistema de gestão </strong> e
                    poderá acompanhar todo processo da reciclagem.</span>
                  <span class="fc-green text-center">Suas ações ajudam nosso planeta e trazem benefícios para a
                    sua casa!</span>
                </div>

                <div class="row">

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Nome *" class="form-control" formControlName="nome" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Sobrenome *" class="form-control" formControlName="sobrenome" />
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Login *" class="form-control" formControlName="login" />
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <input type="text" placeholder="Telefone(DDD) *" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                      formControlName="telefone" />
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <input type="email" placeholder="email@email.com.br" class="form-control" formControlName="email" />
                  </div>

                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <label>cep<small class="fc-red">*</small></label>
                      <input type="text"  class="form-control" placeholder="00000-000" mask="00000-000"
                        formControlName="cep">
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <label>logradouro<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="logradouro" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>número<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="numero">
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>complemento</label>
                      <input type="text" class="form-control" formControlName="complemento">
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-6">
                      <label> bairro<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="bairro" readonly>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>cidade<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="cidade" readonly>
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-6">
                      <label>uf<small class="fc-red">*</small></label>
                      <input type="text" class="form-control" formControlName="estado" readonly>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-8 col-xs-12">
                    <label class="conteudo" for="conteudo">Você aceita receber conteúdo sobre práticas sustentáveis
                      e dicas para melhorar sua gestão de resíduos?</label>
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" checked type="radio" name="infoColeta" id="sim" formControlName="infoColeta"
                            value=true />
                          <label for="sim">Sim</label>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" type="radio" name="infoColeta" id="nao" formControlName="infoColeta"
                            value="false" />
                          <label for="nao">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-4 justify-content-end">
                    <div class="col-2">
                      <button class="btn btn-success" (click)="voltarLogin()">
                        Voltar
                      </button>
                    </div>
                    <div class="col-2">
                      <button class="btn btn-success" (click)="salvarInfo()">
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CADASTRO EMPRESAS -->
            <div class="card-body" *ngIf="perfilCadastro == 'EMPRESA'; else vazio">
              <div class="row mt-5">
                <h5 class="perfil fs-10">CADASTRO DE EMPRESA</h5>
                <div class="row d-flex mb-3">
                  <span class="text-center">Após finalizar seu cadastro</span>
                  <span class="text-center">
                    você terá acesso completo ao nosso
                    <strong class="fc-green"> sistema de gestão </strong> e
                    poderá acompanhar todo processo da reciclagem.</span>
                  <span class="fc-green text-center">Suas ações ajudam nosso planeta e trazem benefícios para a
                    sua empresa!</span>
                </div>
              </div>

              <div class="row">

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome *" class="form-control" formControlName="nome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Sobrenome *" class="form-control" formControlName="sobrenome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Login *" class="form-control" formControlName="login" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Telefone(DDD) *" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                    formControlName="telefone" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome da empresa *" class="form-control"
                    formControlName="nomeEmpresa" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Tipo de negócio *" class="form-control"
                    formControlName="tipoNegocio" />
                </div>

                <div class="col-12 mb-3">
                  <input type="email" placeholder="email@email.com.br" class="form-control" formControlName="email" />
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-4 col-xs-12">
                    <label>cep<small class="fc-red">*</small></label>
                    <input type="text"  class="form-control" placeholder="00000-000" mask="00000-000"
                      formControlName="cep">
                  </div>
                  <div class="col-md-8 col-sm-8 col-xs-12">
                    <label>logradouro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="logradouro" readonly>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>número<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="numero">
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>complemento</label>
                    <input type="text" class="form-control" formControlName="complemento">
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-6">
                    <label> bairro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="bairro" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>cidade<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="cidade" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>uf<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="estado" readonly>
                  </div>


                  <div class="col-md-12 col-sm-8 col-xs-12">
                    <label class="conteudo" for="conteudo">Você aceita receber conteúdo sobre práticas sustentáveis
                      e dicas para melhorar sua gestão de resíduos?</label>
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" checked type="radio" name="infoColeta" id="sim" formControlName="infoColeta"
                            value=true />
                          <label for="sim">Sim</label>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" type="radio" name="infoColeta" id="nao" formControlName="infoColeta"
                            value="false" />
                          <label for="nao">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row pt-4 justify-content-end">
                  <div class="col-2">
                    <button class="btn btn-success" (click)="voltarLogin()">
                      Voltar
                    </button>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-success" (click)="salvarInfo()">
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- CCADASTRO EVENTOS-->
            <div class="card-body" *ngIf="perfilCadastro == 'EVENTO'; else vazio">
              <div class="row mt-5">
                <h5 class="perfil fs-10">CADASTRO DE EVENTO</h5>
                <div class="row d-flex mb-3">
                  <span class="text-center">Após finalizar seu cadastro</span>
                  <span class="text-center">
                    você terá acesso completo ao nosso
                    <strong class="fc-green"> sistema de gestão </strong> e
                    poderá acompanhar todo processo da reciclagem.</span>
                  <span class="fc-green text-center">Suas ações ajudam nosso planeta e trazem benefícios para o
                    seu evento!</span>
                </div>
              </div>

              <div class="row">

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome *" class="form-control" formControlName="nome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Sobrenome *" class="form-control" formControlName="sobrenome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Login *" class="form-control" formControlName="login" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Telefone(DDD) *" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                    formControlName="telefone" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome do evento *" class="form-control" formControlName="nomeEvento" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="quantos dias de evento *" class="form-control"
                    formControlName="diasEvento" />
                </div>


                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="email" placeholder="email@email.com.br" class="form-control" formControlName="email" />
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <label class="form-label col-12">DATA DO EVENTO<small class="fc-red">*</small></label>
                    <input type="date" (paste)="ajustePasteDate($event)"  placeholder="Qual a data do evento *" class="form-control"
                      formControlName="dataEvento" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-4 col-xs-12">
                    <label>cep<small class="fc-red">*</small></label>
                    <input type="text"  class="form-control" placeholder="00000-000" mask="00000-000"
                      formControlName="cep">
                  </div>
                  <div class="col-md-8 col-sm-8 col-xs-12">
                    <label>logradouro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="logradouro" readonly>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>número<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="numero">
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>complemento</label>
                    <input type="text" class="form-control" formControlName="complemento">
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-6">
                    <label> bairro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="bairro" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>cidade<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="cidade" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>uf<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="estado" readonly>
                  </div>
                </div>

                <div class="col-md-12 col-sm-8 col-xs-12">
                  <label class="conteudo" for="conteudo">Você aceita receber conteúdo sobre práticas sustentáveis
                    e dicas para melhorar sua gestão de resíduos?</label>
                  <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <input class="ml" checked type="radio" name="infoColeta" id="sim" formControlName="infoColeta"
                          value=true />
                        <label for="sim">Sim</label>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <input class="ml" type="radio" name="infoColeta" id="nao" formControlName="infoColeta"
                          value="false" />
                        <label for="nao">Não</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pt-4 justify-content-end">
                  <div class="col-2">
                    <button class="btn btn-success" (click)="voltarLogin()">
                      Voltar
                    </button>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-success" (click)="salvarInfo()">
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- CADASTRO OPERADOR LOGISTICO-->
            <div class="card-body" *ngIf="perfilCadastro == 'OPERADOR_LOGISTICO'; else vazio">
              <div class="row mt-5">
                <h5 class="perfil fs-10">CADASTRO DE TRANSPORTADOR</h5>
                <div class="row d-flex mb-3">
                  <span class="text-center">Após finalizar seu cadastro</span>
                  <span class="text-center">
                    você terá acesso completo ao nosso
                    <strong class="fc-green"> sistema de gestão </strong> e
                    poderá acompanhar todo processo da reciclagem.</span>
                  <span class="fc-green text-center">Suas ações ajudam nosso planeta e trazem benefícios para a
                    sua empresa!</span>
                </div>
              </div>

              <div class="row">

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome *" class="form-control" formControlName="nome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Sobrenome *" class="form-control" formControlName="sobrenome" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Login *" class="form-control" formControlName="login" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Telefone(DDD) *" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                    formControlName="telefone" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <input type="text" placeholder="Nome da empresa *" class="form-control"
                    formControlName="nomeEmpresa" />
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                  <select class="form-select" formControlName="cargo">
                    <option value="" selected disabled>Selecione um Cargo</option>
                    <option value="ADMIN">Administrador</option>
                    <option value="OPERADOR_LOGITISCO">Transportador</option>
                    <option value="FINANCEIRO">Financeiro</option>
                    <option value="PESAGEM">Pesagem</option>
                    <option value="COMERCIAL">Comercial</option>
                  </select>
                </div>

                <div class="col-12 mb-3">
                  <input type="email" placeholder="email@email.com.br" class="form-control" formControlName="email" />
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-4 col-xs-12">
                    <label>cep<small class="fc-red">*</small></label>
                    <input type="text"  class="form-control" placeholder="00000-000" mask="00000-000"
                      formControlName="cep">
                  </div>
                  <div class="col-md-8 col-sm-8 col-xs-12">
                    <label>logradouro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="logradouro" readonly>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>número<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="numero">
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>complemento</label>
                    <input type="text" class="form-control" formControlName="complemento">
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-6">
                    <label> bairro<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="bairro" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>cidade<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="cidade" readonly>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6">
                    <label>uf<small class="fc-red">*</small></label>
                    <input type="text" class="form-control" formControlName="estado" readonly>
                  </div>


                  <div class="col-md-12 col-sm-8 col-xs-12">
                    <label class="conteudo">Você aceita receber conteúdo sobre práticas sustentáveis
                      e dicas para melhorar sua gestão de resíduos?</label>
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" checked type="radio" name="infoColeta" id="sim" formControlName="infoColeta"
                            value=true />
                          <label for="sim">Sim</label>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <input class="ml" type="radio" name="infoColeta" id="nao" formControlName="infoColeta"
                            value="false" />
                          <label for="nao">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row pt-4 justify-content-end">
                  <div class="col-2">
                    <button class="btn btn-success" (click)="voltarLogin()">
                      Voltar
                    </button>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-success" (click)="salvarInfo()">
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #vazio></ng-template>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>
