import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { CadastrePlanoEntity } from '../../cadastre-plano/cadastre-plano.entity';
import { CadastrePlanoService } from '../../cadastre-plano/service/cadastre-plano.service';
import { ClientesEntity } from '../../_clientes/clientes/clientes.entity';
import { GalpaoEntity } from '../../_galpao/galpao/galpao.entity';
import { FluxoCobrancaEntity } from '../fluxo-cobranca.entity';
import { FluxoCobrancaService } from '../service/fluxo-cobranca.service';
import { FLUXO_COBRANCAS, GALPAO, PLANOS } from "../../../../core/config/endpoint-list";
import { ajusteDateByPaste, formata_data_utc_br } from "../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'app-fluxo-cobranca-insert',
  templateUrl: './fluxo-cobranca-insert.component.html',
  styleUrls: ['./fluxo-cobranca-insert.component.scss']
})
export class FluxoCobrancaInsertComponent extends AbstractInsertEdit<FluxoCobrancaEntity> implements OnInit {

  public form = new FormGroup({
    nomeCliente: new FormControl('', [Validators.required]),
    planoValor: new FormControl('', [Validators.required]),
    valorPlanoContratado: new FormControl('', [Validators.required]),
    metodoPagamento: new FormControl('', [Validators.required]),
    diaVencimentoFatura: new FormControl('', [Validators.required]),
    dataContratacaoPlano: new FormControl('', [Validators.required]),
    aprovado: new FormControl(false),
  });

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ dataContratacaoPlano: text }))

  listaPlanos: CadastrePlanoEntity[] = [];
  listaClientes: ClientesEntity[] = [];
  listaGalpao: GalpaoEntity[] = [];
  listaMetodoPagamento: string[] = ['CARTAO', 'BOLETO'];
  listaReceptores: { idGalpao: any, percentual: any }[] | undefined = [];
  listaAprovados: any[] = [];

  constructor(
    public servic: FluxoCobrancaService,
    public conf: ConfigDgCrudService
  ) {
    super(conf, servic);
    servic.get(PLANOS, { params: { unpaged: true } }).subscribe(resp => {
      this.listaPlanos = resp.data
    });
    servic.get(GALPAO, { params: { unpaged: true } }).subscribe((resp: any) => {
      this.listaGalpao = resp.data
    });

    this.form.controls['diaVencimentoFatura'].valueChanges.subscribe((diaVencimento: string) => {
      if (!!Number(diaVencimento)) {
        if (Number(diaVencimento) <= 31) {
          return;
        }
      }

      this.form.patchValue({ diaVencimentoFatura: '' })
    })

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.form, v, ['valorPlanoContratado'])
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new FluxoCobrancaEntity();
  }

  override afterFetchEntity(): void {

    this.form.controls['nomeCliente'].disable()

    console.log(this.entity.planoValor);

    this.form.patchValue({
      ...this.entity,
      valorPlanoContratado: InputHelpers.initInputMoney(this.entity.valorPlanoContratado || '') || this.entity.valorPlanoContratado,
      nomeCliente: this.entity.nomeCliente?.nome,
      planoValor: this.entity.planoValor?.nomePlano,
      dataContratacaoPlano: !!this.entity.dataContratacaoPlano
        ? formata_data_utc_br(this.entity.dataContratacaoPlano).split('/').reverse().join('-')
        : undefined
    });
    this.listaReceptores = this.entity?.receptorVendaDTO?.map(resp => {
      return { idGalpao: new FormControl(resp.id), percentual: new FormControl(resp.percentual) }
    })
    this.servic.get(
      FLUXO_COBRANCAS,
      {
        params: { aprovado: true, idCliente: this.entity.nomeCliente?.id }
      }).subscribe(resp => {
        this.listaAprovados = resp.data;
      })
  }

  addReceptor() {
    this.listaReceptores?.push({
      idGalpao: new FormControl('', [Validators.required]),
      percentual: new FormControl('', [Validators.required]),
    });
  }

  removerReceptor(id: number) {
    this.listaReceptores?.splice(id, 1);
  }


  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { valorPlanoContratado, nomeCliente, planoValor, planoId, ...formV } = this.form.value;

    if (!this.entity.nomeCliente) {
      this.form.controls['nomeCliente'].setErrors({ required: false })
      return false;
    }

    if (!this.entity.planoValor) {
      this.form.controls['planoValor'].setErrors({ required: false })
      return false;
    }

    let listaValue = this.listaReceptores?.map(resp => {
      return { idGalpao: resp.idGalpao.value, percentual: resp.percentual.value }
    })

    this.entity = {
      ...this.entity,
      ...formV,
      valorPlanoContratado: InputHelpers.ajusteMoneyBrToEua(valorPlanoContratado),
      receptorVendaDTO: listaValue,
    }
    return true;
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };

}
