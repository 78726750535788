<div id="dashboard-coleta">

  <div class="container-fluid">
    <div class="row">
      <div *ngIf="showPages" class="col-12 d-inline-flex justify-content-end">
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sideFilter-dashboard-estoque"
          aria-controls="sideFilter-dashboard-estoque"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr class="fc-grey">
      </div>
    </div>
  </div>

  <div class="container container-amostra" *ngIf="!showPages">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <div style="text-align: center">
          <h4 class="card-title">Selecione os filtros</h4>
          <button
            class="btn btn-outline dashboard-buttons"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sideFilter-dashboard-estoque"
            aria-controls="sideFilter-dashboard-estoque"
          >
            <mat-icon>search</mat-icon>
            Filtros
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <div class="row d-flex align-items-stretch">
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <h4 class="card-title mb-0">Estoque</h4>
              <span class="card-subtitle fc-grey mt-0">Gráfico de barras</span>
            </div>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.estoqueBarras" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.estoqueBarras" id="id-chart-estoque-barra"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <h4 class="card-title mb-0">Estoque</h4>
              <span class="card-subtitle fc-grey mt-0">Gráfico de rosca</span>
            </div>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.estoqueRosca" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.estoqueRosca" id="id-chart-estoque-rosca"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <h4 class="card-title mb-0">Taxa de reciclagem</h4>
            </div>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.comparativo" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.comparativo" id="id-chart-estoque-comparativo"></div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <div class="row">
      <card-grafico-aterro-zero></card-grafico-aterro-zero>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <div class="row">
      <card-impacto-ambiental [loader]="loaders.impacto" [data]="impacto"></card-impacto-ambiental>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <div class="row">
      <card-ods [data]="ods"></card-ods>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-fluid">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Insumos para o relatório GRI/SASB (GRI 306)</h4>
              <h6 class="card-subtitle">
                Este relatório foi elaborado em conformidade com
                as Normas GRI: opção Abrangente
              </h6>
            </div>

            <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header">
                <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                Download Excel
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body table-gri">
        <crud-data-table
          #tabelaGri
          [fetchStart]="false"
          [tableEventId]="'tabelaGRI-estoque'"
          [entityMap]="entityGri"
          [showDefaultAction]="false"
          [customDblclick]="true"
          [persistentParams]="persistParams"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="entityGri.configEntityTable.pagination"
          (changePage)="tabelaGri.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>




  <div *ngIf="!existDataColetas && showPages" class="container-data">
    <card>
      <div class="card-header">
        <div class="col-9">
          <h4 class="card-title">Os filtros selecionados não geraram dados, selecione novos dados.</h4>
        </div>
      </div>
      <div class="card-body">

      </div>
    </card>
  </div>
</div>

<div id="loader-dashboard" *ngIf="loader < 100">
  <mat-progress-bar mode="buffer" [value]="loader"></mat-progress-bar>
</div>

<sidefilter-dashboard-estoque (visible)="showPages = $event">
</sidefilter-dashboard-estoque>
