<card class="checkin-container">
  <div
    class="card-header"
    *ngIf="!!checkinContainer.container?.identificacao"
  >
    <h4>
      Resíduo: {{ checkinContainer.container?.identificacao}}
    </h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
        <h5>Foto do Recipiente</h5>
        <div class="div-image-container">
          <input
            #fileUploadPlitica
            type="file"
            (change)="uploadImgContainer($event, checkinContainer?.id)"
            style="display: none"
            accept="image/png, image/jpeg, image/jpg"
          >
          <img
            class="image-container"
            src="{{files.getImageToSrc(checkinContainer?.imagem)}}"
            alt="Imagem do recipiente"
          >
        </div>
        <div  class="div-button-image-container">
          <button
            *ngIf="!session.checkPerfil(['PARCEIRO'])"
            (click)="fileUploadPlitica.click()"
            class="btn btn-success sm m-1"
          >
            Escolher
          </button>
          <button
            *ngIf="!session.checkPerfil(['PARCEIRO'])"
            [disabled]="!checkinContainer?.imagem?.id"
            (click)="removerImagem()"
            class="btn btn-danger sm m-1"
          >
            Excluir
          </button>
          <button
            [disabled]="!checkinContainer?.imagem?.id"
            (click)="downloadImagem()"
            class="btn btn-secondary sm m-1"
          >
            Download
          </button>
        </div>
      </div>


      <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
        <div class="row">
          <h5>Dados do Coleta</h5>
        </div>


        <div class="row">
          <div class="col-12">
            <label class="form-label f-bold">Identificação do recipiente:</label>
            <p>
              {{ checkinContainer.container?.container?.nome || '--' }}
            </p>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <label class="form-label f-bold">Tipo do Resíduo:</label>
            <p>
              Nome: {{ checkinContainer.container?.tipoLixoContainer?.nome || '--'}} |
              Categoria: {{ checkinContainer.container?.identificacao || '--'}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="form-label f-bold">Tratamento:</label>
            <p>
              {{ checkinContainer?.tratamento?.nome || '--'}}
            </p>
          </div>
          <div class="col-12">
            <label class="form-label f-bold fs-12">Destinador:</label>
          </div>
          <div class="col-12">
            <label class="form-label f-bold">Nome:</label>
            <p>{{checkinContainer?.destinador?.nome || '--'}}</p>
          </div>
          <div class="col-12">
            <label class="form-label f-bold">CNPJ:</label>
            <p>{{checkinContainer?.destinador?.cnpj || '--'}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="form-label f-bold fs-12">Armazenador temporário:</label>
          </div>
          <div class="col-12">
            <label class="form-label f-bold">Nome:</label>
            <p>{{checkinContainer?.armazenadorTemporario?.nome || '--'}}</p>
          </div>
          <div class="col-12">
            <label class="form-label f-bold">CNPJ:</label>
            <p>{{checkinContainer?.armazenadorTemporario?.cnpj || '--'}}</p>
          </div>
        </div>
      </div>
    </div>


    <hr>


    <div class="row">
      <div
        class="col-12"
        *ngIf="!!contextCheckpointTable"
      >
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h5 class="card-title">Medições da coleta</h5>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <button
                    *ngIf="!session.checkPerfil(['PARCEIRO'])"
                    (click)="openModal()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    Medição de coleta
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #table
              [entityMap]="tableCheckpint"
              [context]="contextCheckpointTable"
              [customActions]="customActionsCheckpointTable"
            >
            </crud-data-table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="tableCheckpint.configEntityTable.pagination"
              (changePage)="table.changePages($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</card>


<old-dg-modal
  #modal
  size="lg"
  title="Medições da coleta"
  [afterClose]="afterClose"
>
  <div dg-content>
    <form [formGroup]="formModal">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Unidade de medida:</label>
          <select
            class="form-select"
            formControlName="unidadeMedidaSelecionada"
          >
            <option
              disabled
              selected
              value
            >
              Selecione
            </option>
            <option
              *ngFor="let unidadeM of listUnidadeMedida"
              [value]="unidadeM.id"
            >
              {{ unidadeM.nome }}
            </option>
          </select>
        </div>
      </div>


      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Carga do recipiente</label>
          <select
            class="form-select"
            formControlName="cargaContainer"
            *ngIf="showSelectFaixas"
          >
            <option
              disabled
              value
            >
              Selecione uma faixa
            </option>
            <option
              *ngFor="let faixa of listFaixas"
              [value]="faixa.valor"
            >
              {{faixa.valor || '--'}}
            </option>
          </select>


          <input
            class="form-control"
            formControlName="cargaContainer"
            *ngIf="!showSelectFaixas"
          >
        </div>


        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">Quantidade de recipientes coletados</label>
          <input
            class="form-control"
            formControlName="qtdContainersColetados"
          >
        </div>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button
      (click)="salvarCheckpoint()"
      class="btn btn-success m-1"
    >
      Salvar
    </button>
    <button
      (click)="modal.close()"
      class="btn btn-secondary m-1"
    >
      Sair
    </button>
  </div>
</old-dg-modal>
