import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractEntity} from "@datagrupo/dg-crud";
import {HttpOldService} from "../../../core/services/http/http-old.service";
import {
  CrudDataTableComponent
} from "@datagrupo/dg-crud/lib/dg-crud/components/crud-data-table/crud-data-table.component";

@Component({
  selector: 'filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {

  @Input() entityMap!: AbstractEntity;
  @Input() tableEventId: string | undefined;
  @Input() table: CrudDataTableComponent | undefined;

  @Output('dispatchFindFilters') dispatchFindFilters = new EventEmitter<{ [key: string]: any }>()

  constructor(public http: HttpOldService) { }

  ngOnInit(): void {
  }

}
