<div id="missoes" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="intro d-flex" style="justify-content: center;">
          <div class="card-header">

            <button
            class="btn fs-16"
            style="margin: 1em;"
            (click)="missoesInconcluidas()"
            [class.btn-outline-Light]="statusDasMissoes != 'MISSOES'"
            [class.btn-success]="statusDasMissoes == 'MISSOES'"
            >
            <div class="ajuste-botoes">
              <mat-icon>emoji_objects</mat-icon>
              <span>| MISSÕES</span>
            </div>
          </button>
            <button
            class="btn fs-16"
            (click)="showConquistas()"
            [class.btn-outline-Light]="statusDasMissoes != 'CONQUISTAS'"
            [class.btn-success]="statusDasMissoes == 'CONQUISTAS'"
            >
            <div class="ajuste-botoes">
              <mat-icon>grade</mat-icon>
              | CONQUISTAS
            </div>
          </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="item">
            <div class="card-body">
              <div class="row" *ngIf="!showListaConquistas && !showMissoes">
                <div class="col-4" *ngFor="let item of listaParceiros; let i = index">
                  <card class="d-flex ajustCard">
                    <input type="file" style="display: none" accept="image/png, image/jpeg, image/jpg">
                    <img class="ajustCard" src="{{files.getImageToSrc(item.imagem)}}" alt="Imagem logo">
                    <div class="cover p-2" (click)="showList(item)">
                      <h3 class="name">{{item.nome}}
                      </h3>
                      <p class="title">Clique aqui e veja as missões deste parceiro!</p>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row" *ngIf="showMissoes">
                <div class="col-4" *ngFor="let item of listaMissoes; let i = index">
                  <card class="d-flex missoes">
                    <div class="missoesHeader">
                      <h1 class="titleMiss">{{item.nome}}</h1>
                    </div>
                    <input type="file" style="display: none" accept="image/png, image/jpeg, image/jpg">
                    <img class="image-material" src="{{files.getImageToSrc(item.imagem)}}" alt="Imagem logo">
                    <div class="card-body" style="background-color: rgb(221, 221, 221);"
                      [innerHTML]="item.descricao || ''"></div>
                    <div class="d-flex card-buttoes" style="justify-content: center;">
                      <button class="btn btn-success" *ngIf="!item.aceita && item.disponivel != 0"
                        (click)="abrirModalMissao(i)">aceitar
                        missão</button>
                      <button class="btn btn-success" *ngIf="item.podeConcluirMissao" (click)="concluirMissao(i)">concluir missão</button>
                      <button class="btn btn-success" *ngIf="item.podeCumprirDesafio" (click)="cumpraDesafio(i)">cumpra o desafio</button>
                      <button class="btn btn-outline-dark" *ngIf="!item.aceita && item.disponivel == 0" disabled>missão
                        esgotada</button>
                      <button class="btn btn-success"
                        *ngIf="item.podeResgatarCupom === true && !item.porcentagemMetaAtingida"
                        (click)="resgatarCupom(i)">resgatar
                        cupom</button>
                      <div class="progress" *ngIf="item.barraProgresso">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 25%;" aria-valuenow="10"
                          aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row" *ngIf="!showParceiro && !showMissoes">
                <div class="col-4" *ngFor="let item of listaConquistas; let i = index">
                  <card class="d-flex conquistas">
                    <div class="missoesHeader">
                      <h1 class="titleMiss">{{item.nome}}</h1>
                    </div>
                    <input #fileUploadPlitica type="file" style="display: none" accept="image/png, image/jpeg, image/jpg">
                    <img class="image-material" src="{{files.getImageToSrc(item.imagem)}}" alt="Imagem logo">
                    <div class="card-body" style="background-color: rgb(221, 221, 221);"
                      [innerHTML]="item.descricao || ''">
                    </div>
                    <div class="d-flex" style="justify-content: center;">
                      <button class="btn btn-success" style="margin: 1em;" *ngIf="item.podeResgatarCupom"
                        (click)="resgatarCupom(i)">
                        CUMPRA O DESAFIO
                      </button>
                      <button class="btn btn-outline-success" disabled style="margin: 1em;"
                        *ngIf="!item.podeResgatarCupom">
                        missão concluída
                      </button>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer [hidden]="showListaConquistas">
  <button class="btn btn-success" (click)="missoesInconcluidas()">mostrar missões inconcluídas</button>
  <button class="btn btn-success" (click)="showParceiros()">filtrar missões dos parceiros</button>
</page-footer>

<old-dg-modal #modalMissao size="md" title="Deseja aceitar a missão?">
  <div dg-content>
    <div class="container">
      <div class="row mb-4">
        <label class="fs-10">NOME DA MISSÃO</label>
        <span>{{missaoAceita?.nome}}</span>
      </div>
      <div class="row">
        <label class="fs-10">Descrição</label>
        <card>
          <div [innerHTML]="missaoAceita?.descricao || ''"></div>
        </card>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button class="btn btn-success m-1" (click)="aceitarMissao(missaoAceita)">Aceitar</button>
    <button (click)="modalMissao.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
