import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramaLogisticaReversaMainComponent } from './programa-logistica-reversa-main/programa-logistica-reversa-main.component';
import { ProgramaCupomComponent } from './programa-cupom/programa-cupom.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';



@NgModule({
  declarations: [
    ProgramaLogisticaReversaMainComponent,
    ProgramaCupomComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    MatTooltipModule,
    AngularEditorModule
  ]
})
export class ProgramaLogisticaReversaModule { }
