import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { RelatorioMissoesEntity } from '../relatorio-missoes.entity';

@Injectable({
  providedIn: 'root'
})
export class RelatorioMissoesService extends AbstractHttpService<RelatorioMissoesEntity> {

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'relatorio-missao')
  }
}

