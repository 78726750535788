import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { CadastrePlanoEntity } from '../cadastre-plano.entity';
import { CadastrePlanoService } from '../service/cadastre-plano.service';

@Component({
  selector: 'app-cadastre-plano-main',
  templateUrl: './cadastre-plano-main.component.html',
  styleUrls: ['./cadastre-plano-main.component.scss']
})
export class CadastrePlanoMainComponent implements OnInit {

  public mapEntity = new CadastrePlanoEntity()
  public tableParam: { padrao?: boolean | undefined } = {};
  constructor(
    public service: CadastrePlanoService,
    protected session: SessionService
  ) {
    if(session.checkPerfil('GALPAO')){
      this.tableParam = {padrao: false}
    }
  }

  ngOnInit(): void {
  }

}
