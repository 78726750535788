import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import { LayoutsModule } from "./layouts/layouts.module";
import { PublicModule } from "./pages/_public/public.module";
import { UserModule } from "./pages/_user/user.module";
import { EsteiraDesenvolvimentoModule } from "./shared/esteira-desenvolvimento/esteira-desenvolvimento.module";
import { PageComponentsComponent } from './pages/page-components/page-components.component';
import { UiModule } from "./shared/ui/ui.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SessionService } from "./core/services/session-service/session.service";
import { AddTokenInterceptor } from "./core/interceptors/add-token/add-token.interceptor";
import { CallbackMessageInterceptor } from "./core/interceptors/callback-message/callback-message.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AuthModule } from "./pages/_auth/auth.module";
import { DirectivesModule } from "./core/directives/directives.module";
import { TipoLixoModule } from "./pages/_user/tipo-lixo/tipo-lixo.module";
import { HomeModule } from "./pages/_user/home/home.module";

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FilterPlusSignInterceptor } from './core/interceptors/filter-plus-sign/filter-plus-sign.interceptor';
import {ConfigDgTable, ConfigDynamicTable} from "@datagrupo/dg-ng-util";
import {configGlobalDgTable} from "./core/config/dg-ng-util/config-global-dg-table";
import {ConfigGlobalDynamicTable} from "./core/config/dg-ng-util/config-global-dynamic-table";
import {configGlobalDynamicTable} from "./core/config/config-dg-util/config-global-dynamic-table";
import {ImpressaoModule} from "./pages/impressao/impressao.module";
import {DocModule} from "./pages/doc/doc.module";

registerLocaleData(localePt);

ConfigDynamicTable.set(configGlobalDynamicTable)
ConfigDgTable.set(configGlobalDgTable)


// config para não impor validação em mascaras NGX-MASK
const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    AppComponent,
    PageComponentsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    LayoutsModule,
    PublicModule,
    UserModule,
    ImpressaoModule,
    EsteiraDesenvolvimentoModule,
    UiModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfig),
    SweetAlert2Module.forRoot(),
    MatSnackBarModule,
    AuthModule,
    DirectivesModule,
    TipoLixoModule,
    HomeModule,
    DocModule
  ],
  providers: [
    SessionService,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CallbackMessageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FilterPlusSignInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
