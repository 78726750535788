import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../ui/modal/modal/modal.component";

@Component({
  selector: 'layout-side-filter',
  templateUrl: './layout-side-filter.component.html',
  styleUrls: ['./layout-side-filter.component.scss']
})
export class LayoutSideFilterComponent implements OnInit {

  @Output('clearFilter') clearFilter = new EventEmitter();
  @Output('favorite') favorite = new EventEmitter();

  @Input() idOpen: string | undefined;
  @Input() visibleFavorite: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
