import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncionarioMainComponent } from './funcionario-main/funcionario-main.component';
import { FuncionarioInsertEditComponent } from './funcionario-insert-edit/funcionario-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    FuncionarioMainComponent,
    FuncionarioInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    NgxMaskModule
  ]
})
export class FuncionarioModule { }
