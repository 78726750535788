<div id="logistica-reversa-insert-edit">


  <form [formGroup]="form">
    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Cliente Logística Reversa</h4>
              <hr>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    NOME
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="nome"
                    class="form-control"
                    id="nome"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="email"
                    class="form-label"
                  >
                    E-MAIL
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    id="email"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="cnpj"
                    class="form-label"
                  >
                    CNPJ
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cnpj"
                    class="form-control"
                    id="cnpj"
                    mask="000.000.000-00||00.000.000/0000-00"
                    placeholder="00.000.000/0000-00"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="telefone"
                    class="form-label"
                  >
                    TELEFONE (DDD)
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefone"
                    placeholder="(00) 00000-0000"
                    mask="(00) 0000-0000||(00) 00000-0000"
                    formControlName="telefone"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="nomeContato"
                    class="form-label"
                  >
                    NOME DO CONTATO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="nomeContato"
                    formControlName="nomeContato"
                    class="form-control"
                    id="nomeContato"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="comprovante"
                    class="form-label"
                  >
                    COMPROVADO COMO
                    <span class="fc-red">*</span>
                  </label>
                  <select
                    formControlName="comprovante"
                    class="form-select"
                    id="comprovante"
                  >
                    <option
                      disabled
                      value="undefined"
                      selected
                    >
                      Selecione
                    </option>
                    <option value="INFORMADO_ATO_COMPRA">Informado no ato da compra</option>
                    <option value="DOCUMENTACAO_VENDA">Comprovado por documentação de venda</option>
                  </select>
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >
                  <label class="form-label">PARCEIRO</label>
                  <input-autocomplete
                    #autocompleteParceiro
                    formControlName="parceiro"
                    key="id"
                    classes="form-control"
                    label="nome"
                    (outValue)="setParceiro($event)"
                    [list]="listParceiro"
                  >
                    <ng-template #button_end>
                      <mat-icon (click)="autocompleteParceiro.clear()">close</mat-icon>
                    </ng-template>
                  </input-autocomplete>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>


    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header row w-100">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6">
                    <h4 class="card-title">Endereço</h4>
                  </div>
                  <div class="col-6 d-inline-flex justify-content-end">
                    <button
                      class="btn btn-success btn-sm"
                      (click)="addCepManual()"
                    >
                      Adicionar dados manualmente
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="cep"
                    class="form-label"
                  >
                    CEP
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cep"
                    class="form-control"
                    id="cep"
                    placeholder="00.000-000"
                    mask="00.000-000"
                  >
                </div>


                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <label
                    for="logradouro"
                    class="form-label"
                  >
                    LOGRADOURO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    [attr.disabled]="true"
                    type="text"
                    formControlName="logradouro"
                    class="form-control"
                    id="logradouro"
                  >
                </div>
              </div>


              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="numero"
                    class="form-label"
                  >
                    NUMERO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="numero"
                    class="form-control"
                    id="numero"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="complemento"
                    class="form-label"
                  >
                    COMPLEMENTO
                  </label>
                  <input
                    type="text"
                    formControlName="complemento"
                    class="form-control"
                    id="complemento"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label
                    for="bairro"
                    class="form-label"
                  >
                    BAIRRO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    [attr.disabled]="true"
                    formControlName="bairro"
                    type="text"
                    class="form-control"
                    id="bairro"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="cidade"
                    class="form-label"
                  >
                    CIDADE
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    [attr.disabled]="true"
                    formControlName="cidade"
                    type="text"
                    class="form-control"
                    id="cidade"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                  <label
                    for="uf"
                    class="form-label"
                  >
                    UF
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    [attr.disabled]="true"
                    formControlName="uf"
                    type="text"
                    class="form-control"
                    id="uf"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </form>
  <!-- *ngIf="!!entity.id" -->


  <div
    class="container-data"
    *ngIf="!!entity?.id"
  >
    <!-- <div class="row"> -->
    <div class="col-12">
      <card>
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                <h4 class="card-title">Produtos</h4>
              </div>
              <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                <button
                  (click)="modalProd.open({produto : {id: entity.id}})"
                  class="btn btn-success btn-card-header"
                >


                  <mat-icon>add</mat-icon>
                  adicionar
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>


        <div class="card-body">


          <crud-data-table
            #tableLogisticaReversa
            tableEventId="LogisticaProdutosEntity"
            [entityMap]="entityLogisticaReversaProdutos"
            [resourceDictionary]="dictionaryTable"
            [persistentParams]="paramsTables"
          ></crud-data-table>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="entityLogisticaReversaProdutos.configEntityTable.pagination"
              (changePage)="tableLogisticaReversa.changePages($event)"
            ></dg-pagination>
          </div>
          <!-- <div class="row">
    <div class="col-6">
      <p>VALOR MENSAL QUE O CLIENTE PAGARÁ: R$ {{formataValorDinheiro(valorMensal)}}</p>
      <p>VALOR ANUAL QUE O CLIENTE PAGARÁ: R$ {{formataValorDinheiro(valorAnual)}}</p>
    </div>
  </div> -->
        </div>
      </card>
    </div>
    <!-- </div> -->
  </div>


  <!-- *ngIf="!!entity.id" -->
  <div
    class="container-data"
    *ngIf="!!entity?.id"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                  <h4 class="card-title">
                    Documentos de Comprovação de Embalagens
                    <span
                      (click)="info()"
                      class="material-symbols-outlined"
                      style="margin-right: .3rem; cursor: pointer; font-size: 13pt; padding-bottom: 0;"
                    >
                    info
                </span>
                  </h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                  <button
                    (click)="modalDocumentos.open()"
                    class="btn btn-success btn-card-header"
                  >


                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
            <hr>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableLogisticaReversaDocumentos
              tableEventId="LogisticaDocumentoEntity"
              [entityMap]="entityLogisticaReversaDocumentos"
              [persistentParams]="paramsTables"
            ></crud-data-table>
          </div>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="entityLogisticaReversaDocumentos.configEntityTable.pagination"
              (changePage)="tableLogisticaReversaDocumentos.changePages($event)"
            ></dg-pagination>
          </div>


        </card>
      </div>
    </div>
  </div>


</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
</page-footer>


<subComponente-logistica-reversa-documentos
  #modalDocumentos
  (salve)="salvarDoc($event)"
  [clienteCreditoId]="entity?.id"
></subComponente-logistica-reversa-documentos>


<subComponente-logistica-reversa-produtos
  #modalProdutos
  [id]="entity?.id"
></subComponente-logistica-reversa-produtos>
