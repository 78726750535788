import { Injectable } from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from "@datagrupo/dg-crud";
import {ArquivosClientesEntity} from "../../../_entitys/arquivos-clientes.entity";
import {ArquivosClientesService} from "../clientes-service/arquivos-clientes.service";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";

@Injectable({
  providedIn: 'root'
})
export class ActionsArquivosClienteService extends AbstractActionsTableService{

  constructor(
    private service: ArquivosClientesService,
    private files: FilesManageService
    ) {
    super()
  }

  override list(): InterfaceCustomActions[] {
    return [
      {
        name: 'Download',
        action: (val: ArquivosClientesEntity) => {
          if (val.id) {
            this.service.downloadFile(val.id);
          }
        }
      }
      ,
      {
        name: 'Editar',
        action: (val: ArquivosClientesEntity) => {
          window.dispatchEvent(new CustomEvent('open-modal-arquivo-cliente', { detail: val }))
        }
      }
    ];
  }


}
