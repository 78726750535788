import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {AbstractInsertEdit, InsertEditConfig} from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { CadastrePlanoEntity } from '../cadastre-plano.entity';
import { PlanoPeriodicoComponent } from '../plano-periodico/plano-periodico.component';
import { PlanoPontualComponent } from '../plano-pontual/plano-pontual.component';
import { CadastrePlanoService } from '../service/cadastre-plano.service';

@Component({
  selector: 'app-cadastre-plano-insert',
  templateUrl: './cadastre-plano-insert.component.html',
  styleUrls: ['./cadastre-plano-insert.component.scss']
})
export class CadastrePlanoInsertComponent extends AbstractInsertEdit<CadastrePlanoEntity> implements OnInit {

  constructor(
    public servic: CadastrePlanoService,
    public conf: InsertEditConfig
  ) {
    super(conf, servic);
    this.crudConfig.subscribeEntityAfterSave = true;
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new CadastrePlanoEntity();
  }

  override beforeSaveEntity(): boolean {
    if (!this.entity.pago) {
      this.entity.periodicidadesPlano = [];
    }
    return true;
  }

  disparaSalvar(voltar: boolean) {
    this.crudConfig.backAfterSave = voltar;
    this.salvar()
  }

  VerifySubComponentPlano(voltar = false) {
    window.dispatchEvent(new CustomEvent('salvar-plano', { detail: {voltar} }))
  }
}
