<div class="container" id="login-page">
  <div class="row justify-content-center">
    <div class="card card-login col-5">
      <div class="card-header">
        <h5 class="card-title">Login</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="container-fluid">

            <div class="row">
              <div class="col-12">
                <div class="input-group mb-3">
                  <input type="email" formControlName="login" class="form-control" placeholder="login" aria-label="email" aria-describedby="email">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input [type]="type" formControlName="password" class="form-control" id="password"  placeholder="Senha">
                </div>
              </div>
            </div>

            <div class="row row-password">
              <div class="col-2 visible" [class.checked]="type === 'text'" (click)="type === 'password' ? type = 'text' : type = 'password'">
                <mat-icon class="icon-view">{{type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
              </div>
              <div class="col-10" style="text-align: end">
                <a routerLink="/esqueci-senha" href="#" class="fs-11 forget">Esqueci a minha senha</a>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <button (click)="logar()" class="btn btn-success w-100">Login</button>
              </div>
            </div>

            <div class="row mt-4 mb-2">
              <div class="col-12">
                <hr />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <a routerLink="/compre-credito-logistica-reversa">
                  <button class="fs-10 btn btn-outline-success w-100">COMPRE CRÉDITO DE LOGISTICA REVERSA</button>
                </a>
              </div>
              <div class="col-12 mt-3">
                <a routerLink="/solicitacao-cadastro">
                  <button class="btn btn-outline-success w-100">CADASTRE-SE</button>
                </a>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
