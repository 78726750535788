import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractInsertEdit, clearEntity} from '@datagrupo/dg-crud';
import {RotasEntity} from '../rotas.entity';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RotasService} from '../service/rotas.service';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {PickListComponent, pickListOutputEvent} from 'src/app/shared/ui/pick-list/pick-list.component';
import {ClientesEntity} from '../../_clientes/clientes/clientes.entity';
import {GalpaoEntity} from '../../_galpao/galpao/galpao.entity';
import {GalpaoMotoristaEntity} from '../../_galpao/galpao-motorista/galpao-motorista.entity';
import {GalpaoMotoristaVeiculoEntity} from '../../_galpao/galpao-motorista-veiculo/galpao-motorista-veiculo.entity';
import {ActivatedRoute} from "@angular/router";
import {SessionService} from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import {
  CLIENTE_DATA_ROTA,
  CLIENTE_PARA_ROTAS,
  GALPAO_MOTORISTA,
  GALPAO_VEICULO, ROTAS_BY_ID
} from "../../../../core/config/endpoint-list";
import {ajusteDateByPaste, formata_data_utc_br} from "../../../../helpers/helpers";
import {InputAutoCompleteComponent} from "../../../../shared/ui/input-auto-complete/input-auto-complete.component";
import {RotaClienteEntity} from "../rota-cliente.entity";
import {OldModalComponent} from "../../../../shared/ui/modal/modal/modal.component";

class ClientesRotasEntity extends ClientesEntity {
  idColetaPendente: number | string | undefined;
  clienteContainer: any;
  check: boolean = false;
}

@Component({
  selector: 'app-rotas-insert-edit',
  templateUrl: './rotas-insert-edit.component.html',
  styleUrls: ['./rotas-insert-edit.component.scss']
})
export class RotasInsertEditComponent extends AbstractInsertEdit<RotasEntity> implements OnInit {

  public listaSelect: ClientesRotasEntity[] = [];
  public listaMotorista: GalpaoMotoristaEntity[] = [];
  public listVeiculo: GalpaoMotoristaVeiculoEntity[] = [];
  public listFeriados: string[] = [];
  public listClientesSelecionados: RotaClienteEntity[] = [];

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({dataDaRota: text}))

  clienteEndity = new ClientesEntity();
  public anoAtual: any;

  public isCopy: boolean = true;

  public podeEditar: boolean = true;
  public showPickList = true;
  public listColetasPendentes: ClientesRotasEntity[] | any[] = [];

  @ViewChild('pickList') pickList!: PickListComponent;
  @ViewChild('autoComplete') autoComplete!: InputAutoCompleteComponent;

  @ViewChild('modal') modal!: OldModalComponent;

  public form = new FormGroup({
    dataDaRota: new FormControl({value: '', disabled: false}, Validators.required),
    idGalpao: new FormControl({value: '', disabled: false}, Validators.required),
    idMotorista: new FormControl({value: '', disabled: false}, Validators.required),
    idVeiculo: new FormControl({value: '', disabled: false}, Validators.required),
    observacao: new FormControl('', [Validators.max(300)]),
  })

  constructor(
    public servic: RotasService,
    public conf: ConfigDgCrudService,
    public route: ActivatedRoute,
    public session: SessionService
  ) {
    super(conf, servic);
    this.setFluxoPerfil(false);
    this.form.controls['idMotorista'].valueChanges.subscribe(
      resp => {
        if (!!resp) {
          servic.get(GALPAO_VEICULO, {params: {motoristaId: resp}}).subscribe(
            resp => {
              this.listVeiculo = resp.data;
              //    this.form.controls['idVeiculo'].reset('')
            }
          )
        }
      }
    )

    this.anoAtual = new Date().getFullYear()

    this.servic.http.get<any[]>(`https://date.nager.at/api/v3/publicholidays/${this.anoAtual}/BR`).subscribe((resp) => {
      if (resp) {
        this.listFeriados = resp.map(feriado => {
          return feriado.date.substring(5, 10);
        })
      }
    });
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override getIdFromRoute() {
    this.route.params.subscribe(params => {
      if (!params['id']) {
        this.location.back();
        return
      }

      if (params['id'].toUpperCase().startsWith('NOVO_COPIADO')) {
        return this.initNewEntity('copia');
      }

      if (params['id'].toUpperCase().startsWith('NOVO_PENDENTE')) {
        return this.initNewEntity('pendente');
      }

      if (params['id'].toUpperCase().startsWith('NOVO')) {
        return this.initNewEntity();
      }

      if (params['id'].trim() !== '' || !!Number(params['id'])) {
        this.entityId = params['id'];
        this.fetchEntity(this.entityId)
      }
    })
  }

  override fetchEntity(id: any) {
    if (id) {
      const paramsUrl = {
        id: 0,
        idGalpao: 0
      }

      this.route.queryParams.subscribe((params: { idGalpao?: number, dataRota?: number }) => {

        if (!params?.idGalpao || !params.dataRota) {
          this.voltar()
        }

        // @ts-ignore
        paramsUrl.idGalpao = params.idGalpao
        // @ts-ignore
        paramsUrl.dataRota = params.dataRota
      }).unsubscribe()

      // @ts-ignore
      paramsUrl.id = String(id).split('?')[0];

      this.servic.get(ROTAS_BY_ID, {params: paramsUrl}).subscribe((response) => {

        // @ts-ignore
        this.entity = this.crudService.dictionaryOne(response.data);
        this.afterFetchEntity();

      }, error => {
        if (this.crudConfig.backAfterFetchError) {
          this.location.back();
        }
      });
    }
  }

  initNewEntity(typeInit?: 'copia' | 'pendente'): void {
    this.entity = new RotasEntity()
    this.setFluxoPerfil(true);

    if (!!typeInit) {
      this.route.queryParams.subscribe(params => {
        if (typeInit == 'copia') {
          if (!params['rota']) {
            return;
          }

          let data = JSON.parse(params['rota']);
          this.entity = this.servic.dictionaryOne(data, 'findOne');
          this.afterFetchEntity();

          this.form.controls['dataDaRota'].enable();
          this.form.controls['idGalpao'].disable();
          this.form.controls['idMotorista'].enable();
          this.form.controls['idVeiculo'].enable();
          this.form.controls['dataDaRota'].reset('')
        } else {
          if (!params['data']) {
            return;
          }
          this.form.patchValue({dataDaRota: params['data']})
        }
      })
    }
  }


  override afterFetchEntity(): void {
    this.form.patchValue({
      // @ts-ignore
      ...this.entity,
      idMotorista: this.entity.nomeMotorista?.id,
      idVeiculo: this.entity.veiculoPlaca?.id,
    })
    this.checkDataPass();

    // if (this.isCopy) {
    //   this.form.controls['dataDaRota'].enable();
    //   this.form.controls['idGalpao'].disable();
    //   this.form.controls['idMotorista'].enable();
    //   this.form.controls['idVeiculo'].enable();
    //   this.form.controls['dataDaRota'].reset('')
    // } else {
    //   if (!this.checkDataPass()) {
    //     this.form.controls['dataDaRota'].disable();
    //     this.form.controls['idGalpao'].disable()
    //     this.form.controls['idMotorista'].disable()
    //     this.form.controls['idVeiculo'].disable()
    //   }
    // }

    this.listClientesSelecionados = <RotaClienteEntity[]>this.entity.clientes;
    if (this.isCopy) {
      this.listClientesSelecionados.map(res => {
        res.statusColeta = 'COPIADO'
      })
    }
    this.getClientes(this.entity.dataRota)
    this.setFluxoPerfil(true)
  }

  override afterSaveEntity() {
    //@ts-ignore
    this.entity.galpaoNome = {id: this.entity.idGalpao}
  }

  verifyBeforeSaveEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    debugger

    if (this.listColetasPendentes.length <= 0) {
      // const listPendente = this.listaSelect.map(item => {
      //   if (item?.idColetaPendente) {
      //     const index = this.listClientesSelecionados.findIndex(itm => {
      //       // @ts-ignore
      //       if (itm?.idColetaPendente) {
      //         // @ts-ignore
      //         return itm.idColetaPendente == item.idColetaPendente
      //       }
      //       return false
      //     });
      //     if (index != -1) {
      //       return this.listClientesSelecionados[index];
      //     }
      //
      //     return false;
      //   }
      //
      //   return false;
      // }).filter(item => !!item)

      const listPendente: any[] = [];

        // @ts-ignore
      this.listaSelect.filter(item => !!item.idColetaPendente).map((item: ClientesRotasEntity) => {
        // @ts-ignore
        const index = this.listClientesSelecionados.findIndex((itemSelected: ClientesRotasEntity) => {
          return itemSelected.idColetaPendente == item.idColetaPendente
        });

        if (index == -1) {
          listPendente.push(item);
        }
      })

      console.log(listPendente)

      if (listPendente.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Rotas pendentes não incluídas',
          text: 'Algumas coletas pendentes foram selecionadas mas não estão marcadas para essa rota.  Deseja continuar mesmo assim?',
          confirmButtonText: 'Ignorar rotas pendentes',
          cancelButtonText: 'Voltar',
          showCancelButton: true,
          cancelButtonColor: '#008861FF',
          confirmButtonColor: 'grey',
          focusCancel: true
        }).then(c => {
          if (c.isConfirmed) this.salvar()
        })

        return;
      }

      this.salvar()
      return
    }

    Swal.fire({
      icon: 'warning',
      title: 'Ainda existem rotas pendentes',
      text: 'Algumas rotas pendentes não foram selecionadas. Deseja continuar mesmo assim?',
      confirmButtonText: 'Ignorar rotas pendentes',
      cancelButtonText: 'Voltar',
      showCancelButton: true,
      cancelButtonColor: '#008861FF',
      confirmButtonColor: 'grey',
      focusCancel: true
    }).then(c => {
      if (c.isConfirmed) this.salvar()
    })
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const {idMotorista, idGalpao, idVeiculo, placa, veiculoPlaca, idClientes, ...formV} = this.form.getRawValue();

    const idClientesArray: (string | number)[] = [];
    const idColetasPendentesArray: (string | number)[] = [];

    this.pickList.getList().map((cli: ClientesRotasEntity) => {
      if (!!cli?.idColetaPendente) {
        idColetasPendentesArray.push(cli?.idColetaPendente)
        return
      }

      if (!cli.id) return;
      idClientesArray.push(cli.id)
    })

    this.entity = {
      ...this.entity,
      ...formV,
      idClientes: idClientesArray,
      idColetasPendentes: idColetasPendentesArray,
      idGalpao: this.entity.galpaoNome?.id,
      idMotorista,
      idVeiculo
    }

    return true;
  }

  mostrarTodosClientes() {
    let params = {}

    if (!!this.entity.galpaoNome?.id) {
      params = {galpaoId: this.entity.galpaoNome.id}
    }

    this.servic.get(
      CLIENTE_PARA_ROTAS, {params: {unpaged: true, ...params}}
    ).subscribe(
      resp => {
        this.listaSelect = resp.data;
      }
    )

  }

  getMotoristaByGalpao(data: { id: number, nome: string }) {
    this.entity.galpaoNome = <GalpaoEntity>data;

    this.servic.get(GALPAO_MOTORISTA, {params: {galpaoId: data.id}}).subscribe(
      resp => {
        this.listaMotorista = resp.data;
      }
    )

    this.getClientes(this.form.value.dataDaRota, this.entity.galpaoNome.id)
  }

  getClientes(data?: string, idGalpao?: string | number) {
    if (new Date(this.form.value.dataDaRota) < new Date('1900-01-01')) {
      return;
    }

    let idGalpaoFinal: string | number | undefined;

    if (!this?.autoComplete?.selectedValue?.id) {
      if (!idGalpao) {
        return;
      }
      idGalpaoFinal = idGalpao;
    } else {
      idGalpaoFinal = this.autoComplete.selectedValue.id;
    }

    if (data) {
      this.servic.get(
        CLIENTE_DATA_ROTA,
        {
          params: {
            dataRota: data,
            galpaoId: idGalpaoFinal
          }
        }).subscribe(resp => {
        //@ts-ignore
        this.listaSelect = resp.data
        this.servic.removeWhere(['dataRota'])
        this.getColetasPendentes(data);
      })
    }
  }

  checkDataPass() {
    //@ts-ignore
    // let dataRotaUrl = this.route.queryParams._value.dataRota
    let dataRotaUrl = this.entity?.dataDaRota;
    if (!dataRotaUrl) return true;

    let dataRota = new Date(dataRotaUrl)
    let data = new Date()

    return dataRota >= data;
  }

  checkReorder() {
    const listStatusFilter = this.listClientesSelecionados.filter(cliente => {
      return cliente.statusColeta == 'ABERTO' || !(!!cliente?.statusColeta)
    });

    return listStatusFilter.length > 0;
  }

  private timeOutDate: any = null;

  getRotaByData(data: string) {
    if (!data) return;

    let dia = new Date(data);

    clearTimeout(this.timeOutDate)

    this.timeOutDate = setTimeout(() => {
      if (dia.getUTCDay() == 6 || dia.getUTCDay() == 0 || this.checkFeriado()) {
        Swal.fire({
          title: `Esta data selecionada ${formata_data_utc_br(data)}, não é uma data comercial, deseja continuar mesmo assim?`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.getClientes(data)
          } else {
            this.form.controls['dataDaRota'].setValue('', {emitEvent: false})
          }
        })
      } else {
        if (!this?.autoComplete?.selectedValue?.id) {
          return;
        }

        this.getClientes(data);
      }
    }, 400)
  }

  checkFeriado(): boolean {
    const data = this.form.value.dataDaRota.substring(5, 10);
    return this.listFeriados.findIndex(feriado => feriado == data) != -1;
  }

  enviarRota() {
    let idRota: any = this.entity.id;

    this.servic.post(`rotas/enviarrota/${idRota}`, {idRota}).subscribe(resp => {
      if (resp) {
        Swal.fire({
          icon: 'success',
          text: 'Rota enviada com sucesso',
          timer: 5000
        })
      }
    })
  }

  propertItemPickList = (val: ClientesRotasEntity) => {
    let labelCompleta = val.nome;

    if (val?.idColetaPendente) labelCompleta = 'Pendente - ' + val.idColetaPendente + ' | ' + labelCompleta;

    if ((val?.enderecos || []).length < 1) return labelCompleta

    if (val.enderecos[0]['logradouro']) labelCompleta = labelCompleta + ' ' + val.enderecos[0]['logradouro'] + " - "
    if (val.enderecos[0]['numero']) labelCompleta = labelCompleta + ' ' + val.enderecos[0]['numero'] + " - "
    if (val.enderecos[0]['complemento']) labelCompleta = labelCompleta + ' ' + val.enderecos[0]['complemento'] + " - "
    if (val.enderecos[0]['bairro']) labelCompleta = labelCompleta + ' ' + val.enderecos[0]['bairro'] + " - "
    if (val.enderecos[0]['cidade']) labelCompleta = labelCompleta + ' ' + val.enderecos[0]['cidade'] + " - "

    return labelCompleta;
  }

  colorirLabelPickList = (val: any): string => {
    if (!!val.statusColeta) {
      switch (val.statusColeta) {
        case 'ABERTO':
          return 'status-aberto';
        case 'FINALIZADO_PENDENCIA':
          return 'status-com-pendencia';
        case 'FINALIZADO':
          return 'status-finalizado';
        case 'COPIADO':
          return 'status-copiado';
        default:
          return '';
      }
    }
    return '';
  }

  getEnderecoTabela(cliente: RotaClienteEntity | any, attr: string) {
    if ((cliente.enderecos || []).length <= 0) return '--';
    switch (attr) {
      case 'logradouro':
        return cliente.enderecos[0]['logradouro'] || '--';
      case 'numero':
        return cliente.enderecos[0]['numero'] || '--';
      case 'complemento':
        return cliente.enderecos[0]['complemento'] || '--';
      case 'bairro':
        return cliente.enderecos[0]['bairro'] || '--';
      case 'cidade':
        return cliente.enderecos[0]['cidade'] || '--';
      case 'estado':
        return cliente.enderecos[0]['estado'] || '--';
      case 'status':
        return cliente.enderecos[0]['status'] || '--';
      default:
        return '--'
    }
  }

  getStatuColeta(cliente: RotaClienteEntity): string {
    if (!cliente.statusColeta) return '--';
    switch (cliente.statusColeta) {
      case 'ABERTO':
        return 'Aberto';
      case 'FINALIZADO_PENDENCIA':
        return 'Finalizado com Pendências';
      case 'FINALIZADO':
        return 'Finalizado';
      case 'COPIADO':
        return 'Rota Copiada';
      default:
        return '--';
    }
  }

  disableMostrarTodos(): boolean {
    if (
      this.entity.galpaoNome?.id == undefined ||
      this.form.controls['idGalpao'].value == ""
    ) {
      this.listaMotorista = []
      this.listVeiculo = []
      // this.listClientesSelecionados = []
      // this.entity.clientes = []
      return true
    }
    if (
      this.session.user.perfil == 'ADMINISTRADOR' ||
      this.session.user.perfil == 'ADMINISTRATIVO'
    ) {
      return false
    }

    return !this.entity.galpaoNome?.id;
  }

  setFluxoPerfil(setGalpao = true) {
    if (this.session.user.perfil == "GALPAO") {
      this.form.controls['idGalpao'].disable();
      if (setGalpao) {
        this.entity.galpaoNome = <GalpaoEntity>{id: this.session.user.galpao_id, nome: this.session.user.galpao_nome}
      }
    }
  }

  getColetasPendentes(data?: string, idGalpao?: string | number) {

    let idGalpaoFinal: string | number | undefined;

    if (!this?.autoComplete?.selectedValue?.id) {
      if (!idGalpao) {
        return;
      }
      idGalpaoFinal = idGalpao;
    } else {
      idGalpaoFinal = this.autoComplete.selectedValue.id;
    }

    this.servic.get('coletas-pendentes/data-rota', {
      params: {
        dataRota: data,
        galpaoId: idGalpaoFinal
      }
    }).subscribe(resp => {
      if (!resp?.data) return;
      if (!Array.isArray(resp.data)) return;

      if (resp.data.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Existem coletas pendentes para o dia selecionado ' + this.getViewDateRota(),
          confirmButtonText: 'Ver coletas',
          cancelButtonText: 'Sair',
          showCancelButton: true
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.modal.open();
          }
        })
      }

      this.listColetasPendentes = resp.data.map((c: any) => {
        return {
          id: c?.clienteId,
          nome: c?.clienteNome,
          enderecos: [c?.clienteEnderecos],
          idColetaPendente: c?.coletaPendenteId,
          clienteContainer: c?.checkinContainerPendente?.clienteContainer,
          check: false,
          checkinContainerPendente: c.checkinContainerPendente
        }
      });
    })
  }

  getViewDateRota() {
    return (this.form.value?.dataDaRota || '').split('-').reverse().join('/');
  }

  aceitarColetasPendentes(all = false) {
    if (all) {
      this.listaSelect = [
        ...this.listaSelect,
        ...this.listColetasPendentes
      ];

      this.listColetasPendentes = [];

    } else {
      const coletasAceitas: ClientesRotasEntity[] = []
      const coletasResult: ClientesRotasEntity[] = []
      this.listColetasPendentes.map(c => {
        if (!!c?.check) {
          coletasAceitas.push(c);
        } else {
          coletasResult.push(c)
        }
      })
      this.listaSelect = [
        ...this.listaSelect,
        ...coletasAceitas
      ];
      this.listColetasPendentes = coletasResult;

      this.modal.close();
    }
  }

  afterClose = () => {
    this.listColetasPendentes.map(c => c.check = false)
  }

  modalPropertTable(val: any[], type: 'nomeContainer' | 'identificador') {
    switch (type) {
      case 'nomeContainer':
        const result1 = val || [];
        return result1.map((c: any) => {
          if (c?.clienteContainer) {
            if (c.clienteContainer?.containerNome) return c.clienteContainer.containerNome
          }

          return null;
        }).filter((c: string) => !!c).join(', ')
      case "identificador":
        let result2 = val || [];
        return result2.map((c: any) => {
          if (c?.clienteContainer?.identificacao) return c.clienteContainer.identificacao;

          return null;
        }).filter((c: string) => !!c).join(', ')
        return '--';
      default:
        return '--'
    }
  }
}
