<div class="container-data">
  <h4 class="card-title">Controle de Envio de Emails</h4>
</div>
<div class="container-data">
  <card>
    <div class="card-body">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label class="form-label">Módulo de comunicação</label>
        <input type="text"  class="form-control" id="nome"  [(ngModel)]="status.linkModuloComunicacao">
      </div>
    </div>
  </card>
</div>

<div class="container-data">
  <h4 class="card-title">Controle de Envio de Emails</h4>
</div>

<div class="container-data">
  <card>
    <div class="card-header">
      <h4 class="card-title">Ao Cliente</h4>
    </div>
    <div class="card-body">
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button"
        [(ngModel)]="status.habiliteEnvioEmailAvalieColeta"
      />
      <label for="button">
        Habilitar Envio de Email ao Gerador e Motorista/Catador Para Avalição do
        Serviço
      </label>

      <div>
        <input
          class="form-check-input marg"
          type="checkbox"
          id="button10"
          [(ngModel)]="status.habiliteEnvioEmailContratacaoPlanoPontual"
        />
        <label for="button10"
          >Habilitar Envio de Email ao Gerador Com um Informe Sobre os Planos
          Contratados
        </label>
      </div>

      <div>
        <input
          class="form-check-input marg"
          type="checkbox"
          id="button13"
          [(ngModel)]="status.habiliteEnvioEmailConviteMissao"
        />
        <label for="button13"
          >Habilitar Envio de Email aos Clientes Selecionados Contendo um
          Convite Para a Missão Selecionada
        </label>
      </div>

      <div>
        <input
          class="form-check-input marg"
          type="checkbox"
          id="button12"
          [(ngModel)]="status.habiliteEnvioEmailComCupom"
        />
        <label for="button12"
          >Habilitar Envio de Email ao Condomínio Contendo um Link Para um
          Novo Cupom Disponível Para Uso
        </label>
      </div>
    </div>
  </card>
</div>
<div class="container-data">

  <card>
    <div class="card-header">
      <h4 class="card-title">Ao Novo Usuário</h4>
    </div>
    <div class="card-body">
      <div>
        <input
        class="form-check-input marg"
        type="checkbox"
        id="button3"
        [(ngModel)]="status.habiliteEnvioEmailBoasVindas"
        />
        <label for="button3">
          Habilitar Envio de Email Boas Vindas ao Novo Usuário Criado
        </label>
      </div>
    </div>
  </card>
</div>
<div class="container-data">

  <card>
    <div  class="card-header">
      <h4 class="card-title">À Ecoplat</h4>
    </div>
    <div class="card-body">
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button4"
        [(ngModel)]="status.habiliteEnvioEmailAvisoEstoqueBaixo"
      />
      <label for="button4"
        >Habilitar Envio de Email à Ecoplat Sobre Estoque Baixo de
        Produto
      </label>

      <div>
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button5"
        [(ngModel)]="status.habiliteEnvioEmailAvisoMCPlanoCliente"
      />
      <label for="button5"
        >Habilitar Envio de Email à Ecoplat. Quando um Gerador Fizer
        Alterações em Seus Planos Contratados
      </label>
    </div>

    <div>
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button6"
        [(ngModel)]="status.habiliteEnvioEmailAvisoMCClienteContratouPlano"
      />
      <label for="button6"
        >Habilitar Envio de Email à Ecoplat Sobre Contratação de um Plano
        Feito Pelo Cliente
      </label>
    </div>

    <div>
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button7"
        [(ngModel)]="
          status.habiliteEnvioEmailAvisoMCClienteContratouPlanoPontual
        "
      />
      <label for="button7"
        >Habilitar Envio de Email à Ecoplat Sobre Contratação de um Plano
        Pontual Feito Pelo Clientexxxxxxxxx
      </label>
    </div>

    <div>
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button8"
        [(ngModel)]="status.habiliteEnvioEmailSolicitacaoCadastroUsuario"
      />
      <label for="button8"
        >Habilitar Envio de Email à Ecoplat Sobre Solicitação Cadastro no
        Sistema Feita por um Condomínio
      </label>
    </div>
  </div>
</card>

</div>
<div class="container-data">
  <card>
    <div class="card-header">
      <h4 class="card-title">Ao Gestor</h4>
    </div>
    <div class="card-body">
      <input
        class="form-check-input marg"
        type="checkbox"
        id="button9"
        [(ngModel)]="
          status.habiliteEnvioEmailAvisoUsuarioSindicoPlanosContratados
        "
      />
      <label for="button9"
        >Habilitar Envio de Email ao Gestor do Gerador Sobre Contratação
        de Plano Feita Por um Usuário
      </label>

      <div>
        <input
        class="form-check-input marg"
        type="checkbox"
        id="button14"
        [(ngModel)]="status.habiliteEnvioEmailCobranca"
      />
      <label for="button14"
      >Habilitar Envio de Email Agendado Enviado ao Gestor Contendo
      Informações Sobre Planos Contratados Pendentes de Pagamento
      </label>
    </div>
  </div>
  </card>
</div>



  <page-footer>
    <button (click)="atualizar()" class="btn btn-success">Salvar</button>
  </page-footer>
