import { ColetaArquivoEntity } from '../../coletas/coleta-arquivo.entity';
import { ColetasService } from '../../coletas/services/coletas.service';
import { HistoricoColetasService } from '../service/historico-coletas.service';
import { HistoricoColetasEntity } from '../historico-coletas.entity';
import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {ColetaArquivosService} from "../../coleta-arquivos/services/coleta-arquivos.service";
import {ColetaArquivosEntity} from "../../coleta-arquivos/coleta-arquivos.entity";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {ArquivoEntity} from "../../../../../shared/entitys/arquivo.entity";
import {formata_data_utc_br} from "../../../../../helpers/helpers";

@Component({
  selector: 'app-rotas-insert-edit',
  templateUrl: './historico-coletas-insert-edit.component.html',
  styleUrls: ['./historico-coletas-insert-edit.component.scss'],
})
export class HistoricoColetasInsertEditComponent extends AbstractInsertEdit<HistoricoColetasEntity> implements OnInit {

  public arquivoColetasEntity = new ColetaArquivosEntity()

  constructor(
    public servic: HistoricoColetasService,
    public conf: ConfigDgCrudService,
    public servicArquivos: ColetaArquivosService,
    public files: FilesManageService
  ) {
    super(conf, servic);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.servicArquivos.addWhere({coletaId: this.entityId})
  }

  initNewEntity(): void {
    this.entity = new HistoricoColetasEntity();
  }

  override afterFetchEntity(): void {}

  ajusteDadosImagem(byteImagem: string | undefined, img: ArquivoEntity) {
    if (!img) {
      return '/assets/img/default.jpg';
    }

    img.file = byteImagem;

    return this.files.getImageToSrc(img)
  }

  maskData(val?: string) {
    if (!val) return '--'
    return formata_data_utc_br(val);
  }
}
