import {
  Component,
  ElementRef,
  EventEmitter, Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { ProdutoEntity } from '../../../produto/produto.entity';
import { ProdutoService } from '../../../produto/service/produto.service';
import { LogisticaReversaService } from '../../service/logistica-reversa.service';
import { LogisticaDocumentoEntity } from '../../logistica-documento.entity';
import { OldModalComponent } from '../../../../../shared/ui/modal/modal/modal.component';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import {
  ClienteCreditoLogisticaReversaDocumentoService
} from '../../service/logistica-reversa-documento/cliente-credito-logistica-reversa-documento.service';
import Swal from "sweetalert2";
import { GenericCrudService } from "../../../../../core/services/generic-crud-service/generic-crud.service";
import { PRODUTOS } from "../../../../../core/config/endpoint-list";
import { verifySizeFiles } from "../../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'subComponente-logistica-reversa-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosComponent implements OnInit {
  @ViewChild('modalDocumentos') modalDocumentos!: OldModalComponent;
  @ViewChild('docInput') docInput!: ElementRef;
  @Output() salve = new EventEmitter<any>();

  @Input() clienteCreditoId: number | string | undefined;

  public documentoId: number | string | undefined;

  public form = new FormGroup({
    id: new FormControl(''),
    produtoId: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required])
  });


  public file: File | undefined;
  public docFile: File | undefined;
  public docName: string | undefined;
  public entityArquivo: LogisticaDocumentoEntity | undefined;

  public listProduto: LogisticaDocumentoEntity[] = [];

  editorConfig = DefaultKolkovTextEditor;

  public edit = false;

  constructor(
    private docService: ClienteCreditoLogisticaReversaDocumentoService
  ) {
    docService.get(PRODUTOS, { params: { unpaged: true } }).subscribe((resp) => {
      this.listProduto = resp.data;
    });

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['quantidade'])
    )
  }

  ngOnInit(): void {
  }

  public open(data?: LogisticaDocumentoEntity | any) {
    if (!!data) {
      this.edit = true;
      this.form.patchValue({
        produtoId: data.produto?.id,
        quantidade: InputHelpers.initInputDecimal(data.quantidade || '') || data.quantidade,
        id: data.id
      });
    }

    this.modalDocumentos.open();
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.docFile = undefined;
        return;
      }

      this.docName = file.name;
      this.file = file;
    }
  }

  saveOrUpdate(data: any) {
    if (this.edit) {
      return this.docService.update(data)
    }

    return this.docService.save(data)
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { ...form } = this.form.value;
    let data: FormData | { [key: string]: any } = {};

    if (this.edit) {
      if (!!form.id) data['id'] = form.id;
      data['clienteCreditoId'] = Number(this.clienteCreditoId);
      data['quantidade'] = InputHelpers.ajusteMoneyBrToEua(form.quantidade);
      data['produtoId'] = form.produtoId;

    } else {
      if (!this.file) {
        Swal.fire({
          icon: 'error',
          title: 'Selecione um arquivo para envio'
        }).then();
        return;
      }

      const formData = new FormData();

      if (!!form.id) formData.append('id', String(form.id));
      formData.append('clienteCreditoId', String(this.clienteCreditoId));
      formData.append('quantidade', InputHelpers.ajusteMoneyBrToEua(form.quantidade));
      formData.append('produtoId', form.produtoId);
      formData.set('arquivo', this.file);

      data = formData;
    }


    this.saveOrUpdate(data).subscribe(() => {
      window.dispatchEvent(
        new CustomEvent('dg-table-atualizar-event', {
          detail: 'LogisticaDocumentoEntity',
        })
      );
      this.modalDocumentos.close();
    });
  }

  close = () => {
    this.edit = false;
    this.form.reset('');
    this.docName = undefined;
    this.docFile = undefined;
    return true;
  };

  checkNumber = (e: any) => {
    if (e.target.type === 'number' && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };
}
