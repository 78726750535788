import {Component, OnInit, ViewChild} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService, DgAutocomplete3Component} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ProgressLoader} from "../../../../helpers/progress-loader";
import {
  DashboardFilterHelperService
} from "../../../_user/dashboard2/services/dashboard-filter-helper/dashboard-filter-helper.service";
import {HttpService} from "../../../../services/http-service/http.service";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {addMessageCallbackColumn, removeMessageCallbackColumn} from "../../../../helpers/events.helpers";
import {setGraph} from "../../../_user/dashboard/helpers/highcharts.helper";
import {
  DASHBOARD_ATERRO_ZERO,
  DASHBOARD_GRUPOS_RESIDUOS,
  DASHBOARD_TRATAMENTO
} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-dashboard-coletas-impressao',
  templateUrl: './dashboard-coletas-impressao.component.html',
  styleUrls: ['./dashboard-coletas-impressao.component.scss']
})
export class DashboardColetasImpressaoComponent implements OnInit {

  @ViewChild('favoritos') favoritos!: DgAutocomplete3Component

  /**
   * formulário dos filtros
   */
  formFilter = new FormGroup({
    favoritos: new FormControl(''),
    unidades: new FormControl([]),
    galpao: new FormControl([]),
    tipoResiduos: new FormControl([]),
    motorista: new FormControl([]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    ativo: new FormControl(''),
    gruposTipoResiduo: new FormControl([]),
    tratamentos: new FormControl([]),
  })

  controlles: {
    // lidando com requests
    handleHequests: { [key: string]: Subscription | undefined },
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de graficos/requests que devem ser executados
    listLoaderPermission: string[],
    // lista de filtros que pode aparecer
    permissionFilters: string[],
    // controller de loader dos dados
    loaders: ProgressLoader.classe,
    // ultimos filtros buscados
    lastFilter: any
  } = {
    filtersOptions: {
      favoritos: [],
      unidades: [],
      transportador: [],
      tipoResiduos: [],
      motoristas: [],
    },
    handleHequests: {
      info: undefined,
      anos: undefined,
      meses: undefined,
      totais: undefined,
      tipoResiduos: undefined,
      motoristas: undefined,
    },
    handleHequestsFilters: {},
    listLoaderPermission: [],
    permissionFilters: [],
    loaders: ProgressLoader.createLoader(() => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('togle-enable-btn-print', {detail: true}))
        window.print();
      }, 1000)
    }),
    lastFilter: undefined
  }

  dataDashboard: {
    cardInfo: {
      numeroClientes: number,
      numeroApartmentos: number,
    },
    tadaGraphAno: any[],
    tadaGraphMeses: any[],
    dataGraphs: {
      totais: any[],
      ano: any[],
      meses: any[],
      motoristas: any[],
      residuos: any[],
      tratamento: any[],
      gruposResiduos: any[],
    }
  } = {
    cardInfo: {
      numeroClientes: 0,
      numeroApartmentos: 0,
    },
    tadaGraphAno: [],
    tadaGraphMeses: [],
    dataGraphs: {
      totais: [],
      ano: [],
      meses: [],
      motoristas: [],
      residuos: [],
      tratamento: [],
      gruposResiduos: [],
    },
  }

  verifyShow: {
    hasAnyDados: boolean | undefined,
    loaderInfo: boolean,
  } = {
    hasAnyDados: false,
    loaderInfo: false,
  }

  tableGerador: CdkDynamicTable.tableClass

  constructor(
    public filterHelpers: DashboardFilterHelperService,
    private http: HttpService,
    public session: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private createTable: CdkDynamicTableService
  ) {
    window.dispatchEvent(new CustomEvent('togle-enable-btn-print', { detail: false }))
    // filterHelpers.loaderListFavoritos('coletas', list => this.controlles.filtersOptions['favoritos'] = list)

    this.initPermissions()

    this.tableGerador = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'dashboard/datatable'
      },
      columns: [
        {
          name: 'dataColeta', headerName: 'Data Coleta', resource: (cel: string) => {
            return cel.split('-').reverse().join('/');
          }
        },
        {name: 'unidadeGeradora', headerName: 'Gerador'},
        {
          name: 'estimativaPeso', headerName: 'Peso da coleta (TON)', resource: (val: string | number) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {maximumFractionDigits: 2}) : '--'
          }
        },
        {name: 'pesoDia', headerName: 'Peso recebido'},
        {name: 'numeroColetas', headerName: 'total recipientes coletados'},
        {name: 'totalContainersColetados', headerName: 'Total de recipientes coletados'},
        {name: 'tipoLixoNome', headerName: 'Tipo de residuo'},
        {name: 'containerNome', headerName: 'Nome do container'},
        {name: 'custoTotalTonelada', headerName: 'Custo total tonelada'},
      ]
    })

    this.tableGerador.observables.dataSource.subscribe(resp => {
      if (resp.type == 'end') {
        this.controlles.loaders.finish('tableGerador')
      }
    })

    if (activatedRoute.snapshot.queryParams['coletas']) {
      const filtersParams = JSON.parse(activatedRoute.snapshot.queryParams['coletas'])
      this.formFilter.patchValue(filtersParams);
      this.initLoaderData()
    }
  }

  /**#################################################
   * ## INICIALIZAÇÃO E PERMISSAO
   #################################################*/

  ngOnInit(): void {
  }

  initPermissions() {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.controlles.permissionFilters = [
        'favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'motoristas', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']

    } else if (this.session.checkPerfil(['PARCEIRO'])) {
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.controlles.permissionFilters = [
        'favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]

    } else if (this.session.checkPerfil(['CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])) {
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.controlles.permissionFilters = [
        'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.formFilter.patchValue({
        unidades: [this.session.user.cliente_id]
      })
      this.changeFilters()
      this.initLoaderData()

    } else if (this.session.checkPerfil(['GALPAO'])) {
      this.controlles.permissionFilters = [
        'unidades', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'motoristas', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.formFilter.patchValue({
        galpao: [this.session.user.galpao_id]
      })
      this.changeFilters()
      this.initLoaderData()
    }
  }

  /**#################################################
   * ## FILTROS
   #################################################*/
    // requests dos filtros, foram criados dessa forma, pois são chamados em duas
    // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
    // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    geradores: () => {
      if (!!this.controlles.handleHequestsFilters['geradores']) {
        this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
        this.controlles.handleHequestsFilters['geradores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['operadores'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
    },
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    motoristas: () => {
      if (!!this.controlles.handleHequestsFilters['motoristas']) {
        this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
        this.controlles.handleHequestsFilters['motoristas'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['transportador'] = list)
    },
  }

  changeFilters(nameFilter?: string) {
    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {coletas: Object.keys(queryParams).length > 0 ? JSON.stringify(queryParams) : null},
        queryParamsHandling: 'merge',
      }
    ).then();
  }

  clearFilters() {
    this.formFilter.patchValue({
      favoritos: '',
      unidades: [],
      transportador: [],
      galpao: [],
      tipoResiduos: [],
      motorista: [],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    })
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    this.changeFilters()
  }

  propagarFavorito() {
    const favoritoValues = this.favoritos.selectedList[0]

    if (!favoritoValues) return;

    this.formFilter.patchValue({
      unidades: (favoritoValues?.clientes || []).map((item: any) => item.id),
      galpao: (favoritoValues?.galpoes || []).map((item: any) => item.id),
      motoristas: (favoritoValues?.motoristas || []).map((item: any) => item.id),
      tipoResiduos: (favoritoValues?.tipoLixos || []).map((item: any) => item.id),
      dataInicio: favoritoValues?.dataInicio,
      dataFim: favoritoValues?.dataFim,
    })

    this.changeFilters()
  }

  /**#################################################
   * ## DADOS
   #################################################*/

  initLoaderData() {
    if (JSON.stringify(this.controlles.lastFilter) == JSON.stringify(this.filterHelpers.getFormFilterValues(this.formFilter))) {
      return;
    }

    this.clearData()
    this.controlles.lastFilter = this.filterHelpers.getFormFilterValues(this.formFilter);

    if (Object.keys(this.filterHelpers.getFormFilterValues(this.formFilter)).length <= 0) {
      this.controlles.loaders.register([])
      return
    }

    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    this.verifyShow.loaderInfo = true;
    this.controlles.loaders.register(['info'])
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    removeMessageCallbackColumn(['dashboard-coletas'])

    this.controlles.handleHequests['info'] = this.http.get('dashboard/info', {params: queryParams}).subscribe(
      resp => {
        this.verifyShow.loaderInfo = false;
        this.dataDashboard.cardInfo.numeroApartmentos = resp.data.numeroDeApartamentos || 0;
        this.dataDashboard.cardInfo.numeroClientes = resp.data.numeroDeUnidades || 0;
        this.verifyShow.hasAnyDados = resp.data.hasAnyDados;

        if (!!resp.data.hasAnyDados) {
          this.controlles.loaders.register(this.controlles.listLoaderPermission)
          this.loaderData()
        } else {
          this.controlles.loaders.register([])
        }
      }
    )

  }

  loaderData() {
    const params = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    if (this.controlles.listLoaderPermission.includes('anos')) {
      this.controlles.handleHequests['anos'] = this.http.get('dashboard/anos', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('anos')
          if (resp.data.charts[0]?.chart) {
            // @ts-ignore
            resp.data.charts[0]?.chart['height'] = '60%';
          }
          console.log(resp.data.charts[0])
          setGraph('id-chart-anos', resp.data.charts[0])
          this.dataDashboard.dataGraphs.ano = this.filterHelpers.extractChartData(resp.data.charts[0])
        },
        () => {
          this.controlles.loaders.finish('anos', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório do Ano',
            keys: ['dashboard-coletas', 'anos'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('meses')) {
      this.controlles.handleHequests['meses'] = this.http.get('dashboard/meses', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('meses')
          if (resp.data.charts[0]?.chart) {
            // @ts-ignore
            resp.data.charts[0]?.chart['height'] = '40%';
          }
          setGraph('id-chart-meses', resp.data.charts[0])
          this.dataDashboard.dataGraphs.meses = this.filterHelpers.extractChartData(resp.data.charts[0])
        },
        () => {
          this.controlles.loaders.finish('meses', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar relatório de Meses',
            keys: ['dashboard-coletas', 'meses'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('totais')) {
      this.controlles.handleHequests['totais'] = this.http.get('dashboard/totais', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('totais')
          if (resp.data.charts[0]?.chart) {
            // @ts-ignore
            resp.data.charts[0]?.chart['height'] = '40%';
          }
          if (resp.data.charts[0]?.chart?.scrollablePlotArea) {
            delete resp.data.charts[0]?.chart?.scrollablePlotArea;
          }
          setGraph('id-chart-totais', resp.data.charts[0])
          this.dataDashboard.dataGraphs.totais = this.filterHelpers.extractChartData(resp.data.charts[0]);
        },
        () => {
          this.controlles.loaders.finish('totais', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de totais',
            keys: ['dashboard-coletas', 'totais'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('tipoResiduos')) {
      this.controlles.handleHequests['tipoResiduos'] = this.http.get('dashboard/residuos', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('tipoResiduos');
          if (resp.data.charts[0]?.chart) {
            // @ts-ignore
            resp.data.charts[0]?.chart['height'] = '100%';
          }
          setGraph('id-chart-residuos', resp.data.charts[0])
          this.dataDashboard.dataGraphs.residuos = this.filterHelpers.extractChartPie(resp.data);
        },
        () => {
          this.controlles.loaders.finish('tipoResiduos', true);
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de resíduos',
            keys: ['dashboard-coletas', 'tipoResiduos'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('motoristas')) {
      if (this.controlles.listLoaderPermission.includes('motoristas')) this.controlles.handleHequests['motoristas'] = this.http.get('dashboard/motoristas', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('motoristas')
          if (resp.data.charts[0]?.chart) {
            // @ts-ignore
            resp.data.charts[0]?.chart['height'] = '40%';
          }
          setGraph('id-chart-motoristas', resp.data.charts[0])
          this.dataDashboard.dataGraphs.motoristas = this.filterHelpers.extractChartData(resp.data.charts[0]);
        },
        () => {
          this.controlles.loaders.finish('motoristas', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de motoristas',
            keys: ['dashboard-coletas', 'motoristas'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('lixoZero')) {
      this.controlles.handleHequests['lixoZero'] = this.http.get(DASHBOARD_ATERRO_ZERO, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('lixoZero')
          if (resp.data[0]?.chart) {
            // @ts-ignore
            resp.data[0]?.chart['height'] = '40%';
          }
          setGraph('id-chart-aterro-zero', resp.data[0])
        },
        () => {
          this.controlles.loaders.finish('lixoZero', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Gráfico de lixo zero"',
            keys: ['dashboard-coletas', 'lixoZero'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('tableGerador')) {
      this.tableGerador.find()
    }
    if (this.controlles.listLoaderPermission.includes('tratamento')) {
      this.controlles.handleHequests['tratamento'] = this.http.get(DASHBOARD_TRATAMENTO, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('tratamento')
          setGraph('id-chart-tratamento', resp.data.charts[0])
          this.dataDashboard.dataGraphs.tratamento = this.filterHelpers.extractChartData(resp.data.charts[0]).map((item) => {
            return item
          })
        },
        () => {
          this.controlles.loaders.finish('tratamento', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Gráfico de lixo zero"',
            keys: ['dashboard-coletas', 'tratamento'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('gruposResiduos')) {
      this.controlles.handleHequests['gruposResiduos'] = this.http.get(DASHBOARD_GRUPOS_RESIDUOS, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('gruposResiduos')
          setGraph('id-chart-gruposResiduos', resp.data.charts[0])
          console.log(resp.data.charts[0])
          debugger
          this.dataDashboard.dataGraphs.gruposResiduos = this.filterHelpers.extractChartData(resp.data.charts[0]).map((item) => {
            return item
          })
        },
        () => {
          this.controlles.loaders.finish('gruposResiduos', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Grupo de resíduos"',
            keys: ['dashboard-coletas', 'gruposResiduos'],
          })
        }
      )
    }
  }

  clearData() {
    this.verifyShow.hasAnyDados = undefined;
    this.dataDashboard.cardInfo = {
      numeroClientes: 0,
      numeroApartmentos: 0
    }
  }
}
