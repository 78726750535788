import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AbstractSideFilter, dataSeideFilter} from "../../cdk/abstract-side-filter";
import {GenericCrudService} from "../../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ajusteDateByPaste} from "../../../../helpers/helpers";
import {CloseAutocomplete, InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_OPERADOR_LOGISTICO, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
} from "../../../../core/config/endpoint-list";
import {ClientesEntity} from "../../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../../pages/_user/_galpao/galpao/galpao.entity";
import {TipoLixoEntity} from "../../../../pages/_user/tipo-lixo/tipo-lixo.entity";

@Component({
  selector: 'sidefilter-dashboard-estoque',
  templateUrl: './sidefilter-dashboard-estoque.component.html',
  styleUrls: ['./sidefilter-dashboard-estoque.component.scss']
})
export class SidefilterDashboardEstoqueComponent extends AbstractSideFilter implements OnInit {

  objParamName = 'estoque'

  override listsPreSelected: {
    favoritos: any[],
    estados: any[],
    galpao: any[],
    tipoResiduo: any[]
  } = {
    favoritos: [],
    estados: [],
    galpao: [],
    tipoResiduo: []
  }
  listSelected: {
    favoritos: any[],
    estados: any[],
    galpao: any[],
    tipoResiduo: any[]
  } = {
    favoritos: [],
    estados: [],
    galpao: [],
    tipoResiduo: []
  }
  public _dataFilter = {
    favoritos: [],
    estados: <any>[],
    galpao: <any[]>[],
    tipoResiduo: [],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };
  listItensDisponiveis: {
    unidades: ClientesEntity[],
    estados: { name: string, uf: string }[],
    galpao: GalpaoEntity[],
    tipoResiduo: TipoLixoEntity[],
    favoritos: any[],
  } = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    favoritos: []
  }

  timeout: any = null;

  constructor(
    public service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    super(service, router, route);
  }

  ngOnInit(): void {
    this.verifyParamsStart()
  }

  //*************************** METODOS PARA O ABSTRACT ***************************//

  setInitParams(params?: any, preValues?: any): void {
    if (!!params) {
      this.listsPreSelected = {
        ...this.listsPreSelected,
        ...preValues.listsPreSelected
      };
      this.listSelected = {
        ...this.listSelected,
        ...preValues.listSelected
      };
      this._dataFilter = {
        ...this._dataFilter,
        ...preValues._dataFilter,
        estados: (params.estados || []).map((m: any) => m.uf)
      };
    }

    this.ajustDataByOperador(true)
    this.getDataAutocompletes();
    this.setQueryParams();
  }

  ajsutDataSetParams(preValues: object) {
    const {ativo} = this._dataFilter;
    const result: any = preValues;
    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;

    return result;
  }

  clearFilter(preValues: dataSeideFilter) {
    this._dataFilter = {
      ...this._dataFilter,
      ...preValues._dataFilter
    }
    this.listSelected = {
      ...this.listSelected,
      ...preValues.listSelected
    }
    this.listsPreSelected = {
      ...this.listsPreSelected,
      ...preValues.listsPreSelected
    }
    this.ajustDataByOperador()
  }

  ajsutDataEmiter(preValues: object) {
    return {
      ...preValues,
      ...((this._dataFilter.estados || []).len > 0 ?
        {estados: (this._dataFilter.estados || []).map((est: any) => est.uf).join(',')}
        : {})
    };
  }

  //*************************** METODOS LOCAIS ***************************//

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  verifyChangeData(tipo: 'dataFim' | 'dataInicio'): void {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const date = new Date(tipo == 'dataFim' ? this._dataFilter.dataFim : this._dataFilter.dataInicio);

      if (!isNaN(date.valueOf())) {
        if (date >= new Date('1995-12-17T03:24:00')) {
          this.setQueryParams()
        }
      } else if (this._dataFilter[tipo] == '') {
        this.setQueryParams();
      }
    }, 300);
  }

  ajustDataByOperador(initFilter = false) {
    if (this.session.checkPerfil('GALPAO')) {
      const galpao = {id: this.session.user.galpao_id, nome: this.session.user.galpao_nome};

      if (initFilter) {
        this.listsPreSelected.galpao = [galpao]
      }
      this.listSelected.galpao = [galpao]
      this._dataFilter.galpao = [galpao.id]
    }
  }

  getDataAutocompletes() {

    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
      if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
        this.updateFavoritos()
      }
    }

    if (!this.session.checkPerfil('GALPAO')) {
      this._service.get(
        DASHBOARD_FILTER_OPERADOR_LOGISTICO,
        {
          params: {
            unpaged: true,
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.galpao = resp.data;

          this.listItensDisponiveis.galpao = this.listItensDisponiveis.galpao.sort(
            (a: GalpaoEntity, b: GalpaoEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO, {params: {unpaged: true}}).subscribe(
      resp => {
        this.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )

    let {galpao, estados} = <{ galpao: string, estados: string }>this.preAjustDataEmiter();

    this.service.get(CLIENTE_ESTADOS,
      {
        params:
          {
            unpaged: true,
            ...(!!galpao ? {galpaoId: galpao} : {}),
            ...(!!estados ? {estados: estados} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.estados = resp.data
      }
    )
  }

  public ajustePasteDate = (ev: any, varName: 'dataInicio' | 'dataFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)


  updateDataAutocompletes(origin: 'galpao' | 'estados' | 'tipoResiduo' | 'all') {
    let {galpao, estados} = <{ galpao: string, estados: string }>this.preAjustDataEmiter();


    if (origin == 'estados' || origin == 'all') {
      this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
        {
          params:
            {
              unpaged: true,
              ...(!!galpao ? {galpaoId: galpao} : {}),
              ...(!!estados ? {estados: estados} : {})
            }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.tipoResiduo = resp.data;
        }
      )
    }

  }

  setAutoComplete(data: CloseAutocomplete, identificador: 'galpao' | 'estados' | 'tipoResiduo') {
    if (this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this.setQueryParams();
    } else {
      this.listSelected[identificador] = [...data.selectedList];
    }

    if (identificador == 'estados' || identificador == 'galpao') {
      this.updateDataAutocompletes(identificador)
    }
  }


  @ViewChild('autoEstados') autoEstados!: InputAutocompleteComponent;
  @ViewChild('autoResiduo') autoResiduo!: InputAutocompleteComponent;
  @ViewChild('autoGalpao') autoGalpao!: InputAutocompleteComponent;

  setFavoritos(d: any) {
    const data = d.selectedList[0] || {}

    this._dataFilter = {
      ...this._dataFilter,
      estados: <any>[],
      galpao: <any[]>[],
      tipoResiduo: [],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    }

    const mapAutocompletes: {[key: string]: 'autoGalpao' | 'autoEstados' | 'autoResiduo'} = {
      tipoLixos: 'autoResiduo',
      estados: 'autoEstados',
      galpoes: 'autoGalpao',
    }

    Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        if (data[key].length > 0) {
          if (key in mapAutocompletes) {
            if (!this[mapAutocompletes[key]]) return;
            this[mapAutocompletes[key]].selectedList = data[key] || [];
            const value = this[mapAutocompletes[key]].selectedList.map(item => this[mapAutocompletes[key]].dataManage.getKey(item))
            this[mapAutocompletes[key]].dataManage.setValue(value)

            this[mapAutocompletes[key]].eventManage.close()
          }
        }
      } else if (['dataInicio', 'dataFim'].includes(key)){
        this._dataFilter[<'dataInicio' | 'dataFim' | 'ativo'>key] = data[key]


      }
    })

    this.setQueryParams().then();
  }

  @HostListener('window:update-DASHBOARD_ESTOQUE')
  updateFavoritos() {
    this._service.get('favoritos', { params: { unpaged: true, tipoFavorito: 'DASHBOARD_ESTOQUE' } }).subscribe(
      resp => {
        this.listItensDisponiveis.favoritos = resp.data || [];
      }
    )
  }
}
