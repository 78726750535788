import { NgxMaskModule } from 'ngx-mask';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewslettersMainComponent } from './newsletters-main/newsletters-main.component';
import { NewslettersInsertEditComponent } from './newsletters-insert-edit/newsletters-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [

    NewslettersMainComponent,
    NewslettersInsertEditComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        RouterModule,
        ReactiveFormsModule,
        NgxMaskModule,
        DirectivesModule
    ]
})
export class NewslettersModule { }
