<div class="container-data">
  <div class="row">
    <div class="col-12">
      <h4 style="margin-bottom: 0" class="text-center fc-green">Usuário</h4>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10 text-center">
      <p style="margin-top: 0; margin-bottom: 0">CADASTRO</p>
      <p style="margin-top: 0; margin-bottom: 0">
        Após finalizar seu cadastro você terá acesso completo ao nosso <b class="fc-green">sistema de
        gestão</b> e poderá acompanhar todo processo da reciclagem.
      </p>
      <p style="margin-top: 0" class="fc-green text-center">
        Suas ações ajudam nosso <b>planeta</b> e trazem benefícios para você!
      </p>
    </div>
  </div>
</div>

<form [formGroup]="form">
  <div class="container-data">
      <div class="row">
        <div class="col-4">
          <label class="form-label">Nome <span class="fc-red">*</span></label>
          <input class="form-control" (focusout)="createLogin()" formControlName="nome" type="text">
        </div>
        <div class="col-4">
          <label class="form-label">Sobrenome <span class="fc-red">*</span></label>
          <input class="form-control" (focusout)="createLogin()" formControlName="sobrenome" type="text">
        </div>
        <div class="col-4">
          <label class="form-label">Login <span class="fc-red">*</span></label>
          <input class="form-control" formControlName="login" type="text">
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="form-label">Email <span class="fc-red">*</span></label>
          <input class="form-control" formControlName="email" type="text">
        </div>
        <div class="col-4">
          <label class="form-label">Telefone <span class="fc-red">*</span></label>
          <input class="form-control" formControlName="telefone" type="text" mask="(00) 0000-0000||(00) 00000-0000">
        </div>
        <div class="col-4">
          <label class="form-label">Complemento</label>
          <input
            class="form-control"
            formControlName="complemento"
            placeholder="Ex. casa 1, apto 203"
          >
        </div>
        <div
              class="col-xl-6 col-lg-6 col-md-8 col-sm-8"
              style="padding-top: 2.1em;"
            >
              <input
                type="checkbox"
                class="form-check-input"
                name="contato"
                id="contato"
                formControlName="contato"
              >
              <label
                class="form-label ms-2"
                for="contato"
              >
                 FUNCIONÁRIO DE CONTATO DO GERADOR
              </label>
            </div>
      </div>
  </div>

  <div class="container-data">
    <div class="col-12">
      <label class="form-label fs-13">Deseja receber conteúdo sobre práticas e dicas sustentáveis?*</label>
    </div>
    <div class="col-12 d-inline-flex">
      <div class="m-2">
        <input type="radio" formControlName="interesse" id="interesse-sim" [value]="true">
        <label for="interesse-sim" class="form-check-label fs-11" style="margin-left: .2rem">Sim</label>
      </div>
      <div class="m-2">
        <input type="radio" formControlName="interesse" id="interesse-nao" [value]="false">
        <label for="interesse-nao" class="form-check-label fs-11" style="margin-left: .2rem">Não</label>
      </div>
    </div>
  </div>
</form>

<div class="container-data">
  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <button (click)="salvar()" class="btn btn-success">Enviar</button>
    </div>
  </div>
</div>

