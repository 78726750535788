import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatorioMissoesMainComponent } from './relatorio-missoes-main/relatorio-missoes-main.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    RelatorioMissoesMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    FormsModule,
    MatIconModule,
    MatMenuModule
  ]
})
export class RelatorioMissoesModule { }
