import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { ClienteCreditoLogisticaReversaEntity } from '../cliente-credito-logistica-reversa.entity';

@Injectable({
  providedIn: 'root'
})
export class PedidoCreditoLogisticaReversaService extends AbstractHttpService<ClienteCreditoLogisticaReversaEntity>{

  constructor(
    public conf: ConfigDgCrudService,
    ) {
      super(conf, environment.apiUrl, 'cliente-credito-logistica-reversa/produtos')
    }
}
