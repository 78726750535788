import { ConfigDgCrudService } from '../../../../core/config/config-dg-crud/config-dg-crud.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { Component, OnInit } from '@angular/core';
import { ProdutoEntity } from '../produto.entity';
import {ProdutoService} from "../service/produto.service";
import {InputHelpers} from "../../../../helpers/input.helpers";

@Component({
  selector: 'app-produto-insert-edit',
  templateUrl: './produto-insert-edit.component.html',
  styleUrls: ['./produto-insert-edit.component.scss']
})
export class ProdutoInsertEditComponent extends AbstractInsertEdit<ProdutoEntity> implements OnInit {

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    valorDoProduto: new FormControl('', [Validators.required])
  });

  constructor(
    public servic: ProdutoService,
    public conf: ConfigDgCrudService,
  ) {
    super(conf, servic);
    servic.alterResource('produtos');
    this.form.valueChanges.subscribe(v => InputHelpers.ajusteInputMoney(this.form, v, 'valorDoProduto'))
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new ProdutoEntity()
    this.form.patchValue({ valorDoProduto: '' })
  }

  override afterFetchEntity(): void {
    this.entity.valorDoProduto = InputHelpers.initInputMoney(this.entity.valorDoProduto || '')
    this.form.patchValue({
      ...this.entity
    })
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const {valorDoProduto, ...form} = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      valorDoProduto: InputHelpers.ajusteMoneyBrToEua(valorDoProduto)
    }
    return true;
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };

  money(value: string | number, campo: string) {
    let val = String(value).replace(',', '')
    if (!String(val).trim() || isNaN(Number(val))) this.form.patchValue({valorDoProduto: '0,00'}, {emitEvent: false});


    if (val.length < 3) {
      if (val.length == 1) val = '00' + val
      if (val.length == 2) val = '0' + val
    }

    const int = Number(val.slice(0, val.length -2))
    const decimal = val.slice(val.length -2)

    value =  Number(`${int}.${decimal}`).toLocaleString("BRL");

    this.form.patchValue({ [campo]: value}, {emitEvent: false})
  }

}
