<div class="footer">
  <div class="container" style="padding: 0 4rem">
    <div class="row row-info">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-12 info-text">
            <h4 class="font-fredoka fc-green">Faça da Ecoplat a sua coleta.</h4>
            <p class="fc-green">
              Dê um passo simples para atitudes mais sustentáveis.
            </p>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="row justify-content-center">
          <div class="col-12 col-buttons">
            <a routerLink="solicitacao-cadastro">
              <button class="btn btn-sm btn-success">CADASTRE-SE</button>
            </a>
            <a routerLink="compre-credito-logistica-reversa">
              <button class="btn btn-sm btn-success">COMPRE CRÉDITOS LOGISTICA REVERSA</button>
            </a>
          </div>
          <div class="col-lx-12 col-lg-12 col-md-8 col-sm-12 col-12 col-button-round">
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/minha-coleta" target="_blank">
                  <button mat-mini-fab class="button-footer-round">
                    <mat-icon svgIcon="linkedin"></mat-icon>
                  </button>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/MinhaColeta/" target="_blank">
                  <button mat-mini-fab class="button-footer-round">
                    <mat-icon svgIcon="f-facebook"></mat-icon>
                  </button>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/minha_coleta/" target="_blank">
                  <button mat-mini-fab class="button-footer-round">
                    <mat-icon svgIcon="instagram"></mat-icon>
                  </button>
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row row-parceiros">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-inline-flex justify-content-center">
        <p>Desenvolvido por <span class="fc-green">Data Grupo</span></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-inline-flex justify-content-center">
        <div class="row h-100">
          <div class="col-10 col-logo-parceiros">
            <a href="http://www.datagrupo.com.br/" target="_blank">
              <img alt="" src="/assets/img/site/parceiros/logoDG.png">
            </a>
          </div>
          <div class="col-2">
            <a href="https://www.linkedin.com/company/data-grupo" target="_blank">
              <button class="button-footer-round-mini">
                <mat-icon svgIcon="linkedin"></mat-icon>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-inline-flex justify-content-center">
        <div class="row h-100">
          <div class="col-10 col-logo-parceiros">
            <a href="http://www.timaker.com.br/" target="_blank">
              <img alt="" src="/assets/img/site/parceiros/logoTI.png">
            </a>
          </div>
          <div class="col-2">
            <a href="https://www.linkedin.com/company/ti-maker-tecnologia-da-informa-o-ltda/" target="_blank">
              <button class="button-footer-round-mini">
              <mat-icon svgIcon="linkedin"></mat-icon>
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="row copy">-->
<!--      <div class="col-12">-->
<!--        <p>Desenvolvido por Data Grupo</p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
