import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { JornadaEducacaoAmbientalEntity } from '../jornada-educacao-ambiental.entity';

@Injectable({
  providedIn: 'root'
})
export class JornadaEducacaoAmbientalService extends AbstractHttpService<JornadaEducacaoAmbientalEntity> {


  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'missoes')
  }

}
