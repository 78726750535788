<div id="dashboard-coleta">

  <div class="container-fluid" *ngIf="!session.checkProfile('MORADOR')">
    <div class="row">
      <div *ngIf="showPages && !public" class="col-12 d-inline-flex justify-content-end">
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sideFilter-dashboard-coletas"
          aria-controls="sideFilter-dashboard-coletas"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>

        <div class="btn-group" *ngIf="!issetFilterUnidadeByOperador()">
          <button
            class="btn btn-outline dashboard-buttons dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <mat-icon>settings</mat-icon>
            Opções
          </button>

          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li>
              <button class="dropdown-item" type="button" (click)="compartilharRelatorio()">
                <mat-icon class="icon-pre">share</mat-icon>
                <span>Compartilhar Relatório</span>
                <mat-icon
                  class="icon-pos"
                  matTooltip="Não será necessário login para abrir esse link"
                  aria-label="Não será necessário login para abrir esse link"
                >contact_support</mat-icon>
              </button>
            </li>
            <li>
              <button class="dropdown-item" type="button" (click)="generate_pdf()">
                <mat-icon class="icon-pre">print</mat-icon>
                <span>Imprimir</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr class="fc-grey">
      </div>
    </div>
  </div>

  <div class="container container-amostra" *ngIf="!showPages && !public">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <div style="text-align: center">
          <h4 class="card-title">Selecione os filtros</h4>
          <button
            class="btn btn-outline dashboard-buttons"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sideFilter-dashboard-coletas"
            aria-controls="sideFilter-dashboard-coletas"
          >
            <mat-icon>search</mat-icon>
            Filtros
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-data" *ngIf="showPages">
    <card>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12">
            <div class="row justify-content-between">
              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold">Número de clientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{cardInfo.numeroClientes}}</span>
                    </div>
                  </div>
                </card>
              </div>

              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span matTooltip="Número de apartamentos/departamento/lojas"
                            class="destaque-claro f-bold">Ambientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{cardInfo.numeroApartmentos}}</span>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <div class="row d-flex align-items-stretch">
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Ano</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.anos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.anos" id="id-chart-anos"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Meses</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.meses" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.meses" id="id-chart-meses"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Totais</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.totais" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.totais" id="id-chart-totais"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Resíduos</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.residuos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.residuos" id="id-chart-residuos"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
        *ngIf="!session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO', 'PARCEIRO'])"
      >
        <card>
          <div class="card-header">
            <h4>Motorista/Catador</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.residuos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.residuos" id="id-chart-motoristas"></div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages && !session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card-impacto-ambiental [loader]="loaders.impacto" [data]="impacto"></card-impacto-ambiental>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages && !session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card>
        <div class="card-header">
          <h4 class="card-title">Gráfico de lixo zero</h4>
        </div>
        <div class="card-body">
          <div *ngIf="loaders.aterroZero" class="h-100 w-100 d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
          </div>
          <div [hidden]="loaders.aterroZero" id="id-chart-aterro-zero"></div>
        </div>
      </card>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages && !session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card-ods [data]="ods"></card-ods>
    </div>
  </div>

  <div *ngIf="existDataColetas && showPages" class="container-data">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Detalhamento por Gerador</h4>
            </div>

            <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header" (click)="downloadExcelDetalhamento()">
                <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                Download Excel
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body">
        <crud-data-table
          #table
          [fetchStart]="false"
          [tableEventId]="'tabelaDetalhamento'"
          [entityMap]="entity"
          [persistentParams]="persistParams"
          [showDefaultAction]="false"
          [customDblclick]="true"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="entity.configEntityTable.pagination"
          (changePage)="table.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>

  <div *ngIf="existDataColetas && showPages && !session.checkPerfil('GALPAO')" class="container-fluid">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Insumos para o relatório GRI/SASB (GRI 306)</h4>
              <h6 class="card-subtitle">
                Este relatório foi elaborado em conformidade com
                as Normas GRI: opção Abrangente
              </h6>
            </div>

            <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header" (click)="downloadExcelGri()">
                <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                Download Excel
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body table-gri">
        <crud-data-table
          #tabelaGri
          [fetchStart]="false"
          [tableEventId]="'tabelaGRI'"
          [entityMap]="entityGri"
          [showDefaultAction]="false"
          [customDblclick]="true"
          [persistentParams]="persistParams"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="entityGri.configEntityTable.pagination"
          (changePage)="tabelaGri.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>


  <div *ngIf="!existDataColetas && showPages" class="container-data">
    <card>
      <div class="card-header">
        <div class="col-9">
          <h4 class="card-title">Os filtros selecionados não geraram dados, selecione novos dados.</h4>
        </div>
      </div>
      <div class="card-body">

      </div>
    </card>
  </div>
</div>

<div id="loader-dashboard" *ngIf="loader < 100">
  <mat-progress-bar mode="buffer" [value]="loader"></mat-progress-bar>
</div>

<sidefilter-dashboard-coletas *ngIf="!public" (visible)="changeshowPages($event)">
</sidefilter-dashboard-coletas>
