import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardColetasImpressaoComponent} from './dashboard-coletas-impressao/dashboard-coletas-impressao.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {CdkDynamicTableModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RankingLixoZeroComponent} from './ranking-lixo-zero/ranking-lixo-zero.component';
import { RankingTaxaReciclagemComponent } from './ranking-taxa-reciclagem/ranking-taxa-reciclagem.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  declarations: [
    DashboardColetasImpressaoComponent,
    RankingLixoZeroComponent,
    RankingTaxaReciclagemComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        CdkDynamicTableModule,
        DgTableModule,
        MatProgressBarModule
    ]
})
export class DashboardImpressaoModule {
}
