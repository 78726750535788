import {
  AbstractEntity,
  DgFilter_input,
  DgTableColumn,
} from '@datagrupo/dg-crud';

export class CategoriaArquivoEntity extends AbstractEntity {
  constructor(
    id?: number,
    nome?: string,
    acesso?: string[]
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.acesso = Array.isArray(acesso) ? acesso : [];
  }

  @DgFilter_input('text', { colum: '4' })
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined;

  public acesso: string[] | undefined;
}
