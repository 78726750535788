<div
    id="cadastre-plano-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Planos</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        style="background-color: #0d6efd"
                        [entityMap]="mapEntity"
                        [table]="tableCadastrePlano"
                        tableEventId="cadastrePlanoTable"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableCadastrePlano
                        [entityMap]="mapEntity"
                        [service]="service"
                        [persistentParams]="tableParam"
                        [showPagination]="false"
                        [register]="true"
                        tableEventId="cadastrePlanoTable"
                    >
                    </crud-data-table>
                </div>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableCadastrePlano.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>



<page-footer>
    <a routerLink="novo">
        <button class="btn btn-success">Novo</button>
    </a>
</page-footer>
