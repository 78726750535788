import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendasLogisticaReversaMainComponent } from './vendas-logistica-reversa-main/vendas-logistica-reversa-main.component';
import { VendasLogisticaReversaInsertEditComponent } from './vendas-logistica-reversa-insert-edit/vendas-logistica-reversa-insert-edit.component';
import { ProdutosComponent } from './subComponentes/produtos/produtos.component';
import { ClientesComponent } from './subComponentes/clientes/clientes.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    VendasLogisticaReversaMainComponent,
    VendasLogisticaReversaInsertEditComponent,
    ProdutosComponent,
    ClientesComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    AngularEditorModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule
  ]
})
export class VendasLogisticaReversaModule { }
