import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientesMainComponent} from './clientes-main/clientes-main.component';
import {ClientesInsertEditComponent} from './clientes-insert-edit/clientes-insert-edit.component';
import {UiModule} from "../../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {MatIconModule} from "@angular/material/icon";
import {ArquivosClienteModalComponent} from './subComponents/arquivos-cliente-modal/arquivos-cliente-modal.component';
import {
  ContainerClienteModalComponent
} from './subComponents/container-cliente-modal/container-cliente-modal.component';
import {PlanosClientesModule} from "../planos-clientes/planos-clientes.module";
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from "@angular/material/select";
import {MatStepperModule} from '@angular/material/stepper';
import {PerguntaCasaComponent} from './clientes-perguntas/pergunta-casa/pergunta-casa.component';
import {PerguntaCondominioComponent} from './clientes-perguntas/pergunta-condominio/pergunta-condominio.component';
import {PerguntaEmpresaComponent} from './clientes-perguntas/pergunta-empresa/pergunta-empresa.component';
import {PerguntaEventoComponent} from './clientes-perguntas/pergunta-evento/pergunta-evento.component';
import {DirectivesModule} from "../../../../core/directives/directives.module";
import {
  CdkDynamicGroupModule,
  CdkDynamicTableModule,
  DgAutocompleteModule,
  DgModalModule, DgPaginatorModule,
  DgTableModule
} from "@datagrupo/dg-ng-util";

import {
  ModalClienteRelacaoContratoComponent
} from './subComponents/modal-cliente-relacao-contrato/modal-cliente-relacao-contrato.component';
import {CustomAutocompleteModule} from "../../../../shared/custom-autocomplete/custom-autocomplete.module";
import {
  ClienteSubPageContratoComponent
} from "./sub-pages/cliente-sub-page-contrato/cliente-sub-page-contrato.component";
import {ClienteSubPageMtrComponent} from "./sub-pages/cliente-sub-page-mtr/cliente-sub-page-mtr.component";


@NgModule({
  declarations: [
    ClientesMainComponent,
    ClientesInsertEditComponent,
    ArquivosClienteModalComponent,
    ContainerClienteModalComponent,
    PerguntaCasaComponent,
    PerguntaCondominioComponent,
    PerguntaEmpresaComponent,
    PerguntaEventoComponent,
    ClienteSubPageContratoComponent,
    ModalClienteRelacaoContratoComponent,
    ClienteSubPageMtrComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatIconModule,
    FormsModule,
    PlanosClientesModule,
    MatButtonModule,
    MatSelectModule,
    MatStepperModule,
    DirectivesModule,
    DgAutocompleteModule,
    DgModalModule,
    DgTableModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule,
    CustomAutocompleteModule
  ]
})
export class ClientesModule {
}
