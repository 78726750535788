import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { ParceiroEntity } from '../../parceiro/parceiro.entity';
import { ParceiroService } from '../../parceiro/service/parceiro.service';
import { MaterialEntity } from '../material.entity';
import { MaterialService } from '../service/material.service';
import { verifySizeFiles } from "../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'app-material-insert-edit',
  templateUrl: './material-insert-edit.component.html',
  styleUrls: ['./material-insert-edit.component.scss']
})
export class MaterialInsertEditComponent extends AbstractInsertEdit<MaterialEntity> implements OnInit {

  @ViewChild('fileUploadMaterial') fileUploadMaterial!: ElementRef;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    descricao: new FormControl('', [Validators.required]),
    valor: new FormControl('', [Validators.required]),
    disponivel: new FormControl('', [Validators.required]),
    minimoPedido: new FormControl('', [Validators.required]),
    pontos: new FormControl('', [Validators.required]),
    precoPorPontos: new FormControl('', [Validators.required]),
    parceiro: new FormControl('', [Validators.required]),
  })

  editorConfig = DefaultKolkovTextEditor;

  public listParceiro: ParceiroEntity[] = [];

  fileMaterial: File | undefined;

  constructor(
    public conf: ConfigDgCrudService,
    public servic: MaterialService,
    public serviceParceiro: ParceiroService,
    public files: FilesManageService,
    public http: HttpOldService
  ) {
    super(conf, servic)
    this.serviceParceiro.findAll().subscribe(resp => {
      //@ts-ignore
      this.listParceiro = resp.data
    })

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.form, v, ['valor', 'precoPorPontos'])
    )
  }
  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity() {
    this.form.patchValue({
      ...this.entity,
      valor: InputHelpers.initInputMoney(this.entity.valor || '') || this.entity.valor,
      precoPorPontos: InputHelpers.initInputMoney(this.entity.precoPorPontos || '') || this.entity.precoPorPontos,
      parceiro: this.entity.parceiro?.id
    })
  }

  initNewEntity(): void {
    this.entity = new MaterialEntity();
  }

  selectImg() {
    const fileImg = this.fileUploadMaterial.nativeElement;
    fileImg.click()
  }

  uploadMaterial(event: Event) {
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.fileMaterial = undefined;
        return;
      }

      let data = new FormData();

      data.append('arquivo', file);
      data.append('materialId', String(this.entity.id));


      this.servic.uploadImagem(data).subscribe(resp => {
        if (resp) {
          //@ts-ignore
          this.entity.imagem = resp.data
        }
      })
    }


    // this.http.post('material/imagem', data).subscribe(resp => {
    //   if (resp) {
    //     //@ts-ignore
    //     this.entity.imagem = resp.data
    //   }
    // })
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false
    }

    const { valor, precoPorPontos, parceiro, ...form } = this.form.value;

    const indexParceiro = this.listParceiro.findIndex((cat) => {
      return cat.id == parceiro;
    })

    if (indexParceiro == -1) {
      return false;
    }


    this.entity = {
      ...this.entity,
      ...form,
      valor: InputHelpers.ajusteMoneyBrToEua(valor),
      precoPorPontos: InputHelpers.ajusteMoneyBrToEua(precoPorPontos),
      parceiro: { id: this.listParceiro[indexParceiro].id }
    }

    return true;
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };

}
