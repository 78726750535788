import {NgModule} from "@angular/core";
import {Dashboard2MainComponent} from "./dashboard2-main/dashboard2-main.component";
import {CommonModule} from "@angular/common";
import {Dashboard2ColetasComponent} from './sub-pages/dashboard2-coletas/dashboard2-coletas.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {Dashboard2EstoqueComponent} from './sub-pages/dashboard2-estoque/dashboard2-estoque.component';
import {
  CdkDynamicGroupModule,
  CdkDynamicTableModule,
  DgAutocomplete3Module, DgPaginatorModule,
  DgTableModule
} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {
  CardImpactoAmbiental2Component
} from './sub-components/card-impacto-ambiental2/card-impacto-ambiental2.component';
import {CardOds2Component} from './sub-components/card-ods2/card-ods2.component';
import {Dashboard2RankingsComponent} from './sub-pages/dashboard2-rankings/dashboard2-rankings.component';
import {Dashboard2ImpactoComponent} from './sub-pages/dashboard2-impacto/dashboard2-impacto.component';
import {RouterModule} from "@angular/router";
import {ModalFavoritosMainComponent} from './sub-components/modal-favoritos-main/modal-favoritos-main.component';
import {CanvasSideFilterComponent} from "./sub-components/canvas-side-filter/canvas-side-filter.component";
import {MatMenuModule} from "@angular/material/menu";
import { ModalFavoritosInsertEditComponent } from './sub-components/modal-favoritos-insert-edit/modal-favoritos-insert-edit.component';

@NgModule({
    declarations: [
        Dashboard2MainComponent,
        Dashboard2ColetasComponent,
        Dashboard2EstoqueComponent,
        CardImpactoAmbiental2Component,
        CardOds2Component,
        Dashboard2RankingsComponent,
        Dashboard2ImpactoComponent,
        ModalFavoritosMainComponent,
        CanvasSideFilterComponent,
        ModalFavoritosInsertEditComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
        DgAutocomplete3Module,
        ReactiveFormsModule,
        MatTooltipModule,
        CdkDynamicGroupModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        DgTableModule,
        CdkDynamicTableModule,
        DgPaginatorModule,
        RouterModule,
        MatMenuModule,
    ],
    exports: [
        Dashboard2ColetasComponent
    ]
})
export class Dashboard2Module {
}
