<old-dg-modal
  #modalFavoritos
  [size]="'xl'"
  [afterClose]="afterClose"
  [escClose]="!modalIsetEdit?.modal?.config?.open"
  class="modal-table-favorito"
  title="Favoritos"
>
  <div dg-content>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <span class="nav-link" [class.active]="tableVisible == 'DASHBOARD_COLETAS'" (click)="tableVisible = 'DASHBOARD_COLETAS'" aria-current="page" href="#">Coletas</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" [class.active]="tableVisible == 'DASHBOARD_ESTOQUE'" (click)="tableVisible = 'DASHBOARD_ESTOQUE'" href="#">Estoque</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" [class.active]="tableVisible == 'RANKING_ATERRO_ZERO'" (click)="tableVisible = 'RANKING_ATERRO_ZERO'" href="#">Ranking Lixo Zero</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" [class.active]="tableVisible == 'RANKING_TAXA_RECICLAGEM'" (click)="tableVisible = 'RANKING_TAXA_RECICLAGEM'">Ranking Taxa de Reciclagem</span>
      </li>
    </ul>

    <!--    COLETAS -->
    <div [hidden]="tableVisible != 'DASHBOARD_COLETAS'">
      <div class="container-data">
        <div>
          <div class="col-12 d-inline-flex justify-content-center">
            <button class="btn btn-success m-1" (click)="modalIsetEdit.openNew(tableVisible)">Criar novo favorito</button>
          </div>
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="crud-table">
            <thead>
            <tr>
              <th>Nome do Filtro</th>
              <th>Unidades Geradoras</th>
              <th>Operadores Logísticos</th>
              <th>Motorista/Catador</th>
              <th>Tipo de Resíduo</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td *ngIf="(listFavoritos.coletas.length <= 0 && !loader.DASHBOARD_COLETAS)" colspan="6" style="text-align: center">Nenhum registro encontrado</td>
              <td *ngIf="loader.DASHBOARD_COLETAS" colspan="8">
                <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
            </tr>
            <tr *ngFor="let fav of (loader.DASHBOARD_COLETAS ? [] : listFavoritos.coletas)" (dblclick)="modalIsetEdit.open(tableVisible, fav)">
              <td>{{fav.nome}}</td>
              <td>{{getListPropert(fav, 'clientes')}}</td>
              <td>{{getListPropert(fav, 'galpoes')}}</td>
              <td>{{getListPropert(fav, 'motoristas')}}</td>
              <td>{{getListPropert(fav, 'tipoLixos')}}</td>
              <td >{{fav?.dataInicio?.split('-').reverse().join('/') || '--'}}</td>
              <td>{{fav?.dataFim?.split('-').reverse().join('/') || '--'}}</td>
              <td class="cdk-column-actions">
                <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="modalIsetEdit.open(tableVisible, fav)">Editar</button>
                  <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--    ESTOQUE-->
    <div [hidden]="tableVisible != 'DASHBOARD_ESTOQUE'">
      <div class="container-data">
        <div>
          <div class="col-12 d-inline-flex justify-content-center">
            <button class="btn btn-success m-1" (click)="modalIsetEdit.openNew(tableVisible)">Criar novo favorito</button>
          </div>
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="crud-table">
            <thead>
            <tr>
              <th>Nome do Filtro</th>
              <th>Operadores Logísticos</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Tipo de rasíduo</th>
              <th>Estados</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td *ngIf="((listFavoritos.estoque || []).length <= 0 && !loader.DASHBOARD_ESTOQUE)" colspan="6" style="text-align: center">Nenhum registro encontrado</td>
              <td *ngIf="loader.DASHBOARD_ESTOQUE" colspan="8">
                <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
            </tr>
            <tr *ngFor="let fav of (loader.DASHBOARD_ESTOQUE ? [] : listFavoritos.estoque || [])" (dblclick)="modalIsetEdit.open(tableVisible, fav)">
              <td>{{fav.nome}}</td>
              <td>{{getListPropert(fav, 'galpoes')}}</td>
              <td>{{fav.dataInicio ? fav.dataInicio.split('-').reverse().join('/') : '--'}}</td>
              <td>{{fav.dataFim ? fav.dataFim.split('-').reverse().join('/') : '--'}}</td>
              <td>{{getListPropert(fav, 'tipoLixos')}}</td>
              <td>{{getListPropert(fav, 'estados')}}</td>
              <td class="cdk-column-actions">
                <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="modalIsetEdit.open(tableVisible, fav)">Editar</button>
                  <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--    RANKING ATERRO ZERO-->
    <div [hidden]="tableVisible != 'RANKING_ATERRO_ZERO'">
      <div class="container-data">
        <div>
          <div class="col-12 d-inline-flex justify-content-center">
            <button class="btn btn-success m-1" (click)="modalIsetEdit.openNew(tableVisible)">Criar novo favorito</button>
          </div>
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="crud-table">
            <thead>
            <tr>
              <th>Nome do Filtro</th>
              <th>Unidades Geradoras</th>
              <th>Estados</th>
              <th>Tipo de Resíduo</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ativo/Inativo</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td *ngIf="((listFavoritos.estoque || []).length <= 0 && !loader.RANKING_ATERRO_ZERO)" colspan="6" style="text-align: center">Nenhum registro encontrado</td>
              <td *ngIf="loader.RANKING_ATERRO_ZERO" colspan="8">
                <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
            </tr>
            <tr *ngFor="let fav of (loader.RANKING_ATERRO_ZERO ? [] : listFavoritos.rankingAterro)" (dblclick)="modalIsetEdit.open(tableVisible, fav)">
              <td>{{fav.nome}}</td>
              <td>{{getListPropert(fav, 'clientes')}}</td>
              <td>{{getListPropert(fav, 'estados')}}</td>
              <td>{{getListPropert(fav, 'tipoLixos')}}</td>
              <td>{{fav.dataInicio ? fav.dataInicio.split('-').reverse().join('/') : '--'}}</td>
              <td>{{fav.dataFim ? fav.dataFim.split('-').reverse().join('/') : '--'}}</td>
              <td>{{getAtivoInativo(fav?.ativo)}}</td>
              <td class="cdk-column-actions">
                <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="modalIsetEdit.open(tableVisible, fav)">Editar</button>
                  <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--    RANKING TAXA DE RECICLAGEM-->
    <div [hidden]="tableVisible != 'RANKING_TAXA_RECICLAGEM'">
      <div class="container-data">
        <div>
          <div class="col-12 d-inline-flex justify-content-center">
            <button class="btn btn-success m-1" (click)="modalIsetEdit.openNew(tableVisible)">Criar novo favorito</button>
          </div>
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="crud-table">
            <thead>
            <tr>
              <th>Nome do Filtro</th>
              <th>Unidades Geradoras</th>
              <th>Estados</th>
              <th>Tipo de Resíduo</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ativo/Inativo</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td *ngIf="((listFavoritos.estoque || []).length <= 0 && !loader.RANKING_TAXA_RECICLAGEM)" colspan="6" style="text-align: center">Nenhum registro encontrado</td>
              <td *ngIf="loader.RANKING_TAXA_RECICLAGEM" colspan="8">
                <div style="width: 100%; height: 100%" class="d-inline-flex justify-content-center align-items-center">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
            </tr>
            <tr *ngFor="let fav of (loader.RANKING_TAXA_RECICLAGEM ? [] : listFavoritos.rankingTaxa)" (dblclick)="modalIsetEdit.open(tableVisible, fav)">
              <td>{{fav.nome}}</td>
              <td>{{getListPropert(fav, 'clientes')}}</td>
              <td>{{getListPropert(fav, 'estados')}}</td>
              <td>{{getListPropert(fav, 'tipoLixos')}}</td>
              <td>{{fav.dataInicio ? fav.dataInicio.split('-').reverse().join('/') : '--'}}</td>
              <td>{{fav.dataFim ? fav.dataFim.split('-').reverse().join('/') : '--'}}</td>
              <td>{{getAtivoInativo(fav?.ativo)}}</td>
              <td class="cdk-column-actions">
                <button class="btn btn-success" [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="modalIsetEdit.open(tableVisible, fav)">Editar</button>
                  <button mat-menu-item (click)="removeFav(tableVisible, fav)">Excluir</button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</old-dg-modal>


<modal-favoritos-insert-edit #modalFavoritosInsertEdit (save)="save($event)">
</modal-favoritos-insert-edit>
