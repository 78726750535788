import { Component, OnInit, ViewChild } from '@angular/core';
import {AbstractInsertEdit, crudDispatchEvent, InterfaceCustomActions} from "@datagrupo/dg-crud";
import { NotaFiscalEntity } from "../nota-fiscal.entity";
import { ConfigDgCrudService } from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import { NotaFiscalService } from "../services/nota-fiscal.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GalpaoEntity } from "../../../_galpao/galpao/galpao.entity";
import { GalpaoService } from "../../../_galpao/galpao/services/galpao-service/galpao.service";
import { ProdutoEntity } from "../../../produto/produto.entity";
import { ProdutoService } from "../../../produto/service/produto.service";
import { IndustriaDestinoService } from "../../../industria-destino/service/industria-destino.service";
import { IndustriaDestinoEntity } from "../../../industria-destino/industria-destino.entity";
import { OldModalComponent } from "../../../../../shared/ui/modal/modal/modal.component";
import { NotaFiscalProdutosEntity } from "../nota-fiscal-produtos.entity";
import { ArquivoNotaFiscalEntity } from "../../arquivo-nota-fiscal/arquivo-nota-fiscal.entity";
import { ArquivoNotaFiscalService } from "../../arquivo-nota-fiscal/services/arquivo-nota-fiscal.service";
import Swal from "sweetalert2";
import { FilesManageService } from "../../../../../services/files-manage/files-manage.service";
import {ajusteDateByPaste, clearString, formata_data_utc_br, verifySizeFiles} from 'src/app/helpers/helpers';
import { NOTA_FISCAL } from 'src/app/core/config/endpoint-list';
import {ProdutoNotaFiscalComponent} from "../subComponents/produto-nota-fiscal/produto-nota-fiscal.component";

@Component({
  selector: 'app-nota-fiscal-insert-edit',
  templateUrl: './nota-fiscal-insert-edit.component.html',
  styleUrls: ['./nota-fiscal-insert-edit.component.scss']
})
export class NotaFiscalInsertEditComponent extends AbstractInsertEdit<NotaFiscalEntity> implements OnInit {

  public form = new FormGroup({
    chaveAcesso: new FormControl('', [Validators.required]),
    galpao: new FormControl('', [Validators.required]),
    industria: new FormControl('', [Validators.required]),
    dataNota: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required])
  });

  public formModalProd = new FormGroup({
    id: new FormControl(''),
    produto: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    precoPorTonelada: new FormControl('', [Validators.required])
  })

  public file: File | undefined;

  public produtoEntity = new ProdutoEntity();
  public produtoFiscalEntity = new NotaFiscalProdutosEntity();

  public galpaoList: GalpaoEntity[] = [];
  public industriaList: IndustriaDestinoEntity[] = [];
  public listProdutos: ProdutoEntity[] = [];
  public entityDocumentos = new ArquivoNotaFiscalEntity();
  public docFile: File | undefined;

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ dataNota: text }))

  @ViewChild('produtosModal') modalProd!: OldModalComponent;
  @ViewChild('modal') modal!: ProdutoNotaFiscalComponent;

  customActionsProdutos: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: NotaFiscalProdutosEntity) => {
        let index = -1;
        if (val.id) {
          index = this.entity.produtos.findIndex(prod => prod.id == val.id);
        } else {
          index = this.entity.produtos.findIndex(prod => prod.produto?.nome == val.produto?.nome);
        }

        // this.openModal(index)
        this.modal.open(val)
      }
    },
    {
      name: 'Excluir',
      action: (val: NotaFiscalProdutosEntity) => {
        let index = -1;
        if (val.id) {
          index = this.entity.produtos.findIndex(prod => prod.id == val.id);
        } else {
          index = this.entity.produtos.findIndex(prod => prod.produto?.nome == val.produto?.nome);
        }

        this.removerProduto(index, val)
      }
    }
  ]

  constructor(
    public config: ConfigDgCrudService,
    public service: NotaFiscalService,
    public galpaoServ: GalpaoService,
    public produtosServ: ProdutoService,
    public industriaDestServ: IndustriaDestinoService,
    public serviceDoc: ArquivoNotaFiscalService,
    private files: FilesManageService
  ) {
    super(config, service)

    this.customActions();

    industriaDestServ.findAll().subscribe(
      resp => {
        //@ts-ignore
        this.industriaList = resp.data;
      }
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity() {
    this.form.patchValue({
      ...this.entity,
      galpao: this.entity.galpao?.nome,
      industria: this.entity.industria?.nome,
    });
    this.observableChangeData();
    this.serviceDoc.addWhere({ notaFiscalId: this.entity.id })
  }

  observableChangeData() {
    this.form.controls['dataNota'].valueChanges.subscribe((value: string) => {
      const data = new Date(value);
      data.setFullYear(data.getFullYear() + 2)

      this.entity.validadeNota = this.dataAtualFormatada(String(data))
    })
  }

  override afterSaveEntity() {
    if (!this.entity.validadeNota) return;

    this.serviceDoc.addWhere({ notaFiscalId: this.entity.id })
    this.entity.validadeNota = this.dataAtualFormatada(this.entity.validadeNota)
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { galpao, industria, ...formV } = this.form.value;


    this.entity.produtos.map((prod, index) => {
      if (prod.produto) {
        if ('produtosPlano' in prod.produto) {
          //@ts-ignore
          delete this.entity.produtos[index].produto.produtosPlano
        }
      }
    })

    if (!this.entity.validadeNota) return false;

    this.entity = {
      ...this.entity,
      ...formV,
      validadeNota: new Date(this.entity.validadeNota)
    }

    return true
  }

  initNewEntity(): void {
    this.entity = new NotaFiscalEntity();
    this.observableChangeData()
  }

  customActions() {
    this.entityDocumentos.addActions(<InterfaceCustomActions[]>[
      {
        name: 'download',
        action: (val: ArquivoNotaFiscalEntity) => {
          if (!!val.id) {
            this.serviceDoc.findOne(val.id, '').subscribe(
              resp => {
                //@ts-ignore
                this.files.downloadBase64(resp.data, val.arquivo?.fileNameOriginal)
              }
            )
          }
        }
      },
      {
        name: 'editar',
        action: () => { },
        permission: false
      },
      {
        name: 'Excluir',
        action: (val: any) => {
          Swal.fire({
            icon: 'question',
            title: "Remover esse Arquivo?",
            showCancelButton: true,
            cancelButtonText: 'Sair',
            confirmButtonText: 'Excluir'
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.serviceDoc.delete(val.id, undefined, String(this.entity.id)).subscribe(
                resp => {
                  window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'ArquivoNotaFiscalEntity' }));
                  Swal.fire({
                    icon: 'success',
                    title: "Arquivo removido",
                    confirmButtonText: 'Sair',
                    timer: 5000
                  })
                }
              )
            }
          })

        }
      }
    ])
  }

  private _getRelacao(id: string | number) {
    const index = this.listProdutos.findIndex(prod => prod.id == id)

    if (index != -1) {
      return this.listProdutos[index];
    }

    return undefined
  }

  openModal(index?: number) {
    this.produtosServ.get('produtos/nomes').subscribe(
      resp => {


        if (!!index || index === 0) {
          this.formModalProd.patchValue({
            produto: this.entity.produtos[index].produto?.id,
            quantidade: this.entity.produtos[index].quantidade,
            precoPorTonelada: this.entity.produtos[index].precoPorTonelada,
            id: this.entity.produtos[index].id
          })
        }

        this.listProdutos = resp.data;

        this.modalProd.open();
      }
    )
  }

  salvarProdutos(data: any) {
    if (!!data.id) {
      const index = this.entity.produtos.findIndex((prod) => prod.id == data.id);

      if (index != -1) {
        // dataF['id'] = data.id
        this.entity.produtos.splice(index, 1, data)
      }
    } else {
      const index = this.entity.produtos.findIndex((prod) => {
        crudDispatchEvent('produtoFiscalEntity')
        return clearString(prod?.produto?.nome || '').toLowerCase() == clearString(data.produto.nome).toLowerCase()
      });

      if (index != -1) {
        if (!this.entity.produtos[index].id) {
          this.entity.produtos.splice(index, 1, data);
          this.modalProd.close();
          crudDispatchEvent('produtoFiscalEntity')
          return;
        }
      }

      this.entity.produtos.push(data)
    }

    crudDispatchEvent('produtoFiscalEntity')
    this.formModalProd.reset();
    this.modal.close();
  }

  removerProduto(index: number, data: any) {
    Swal.fire({
      icon: 'question',
      title: "Remover esse Produto?",
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: 'Excluir'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        if (data.id) {
          this.produtosServ.delet(NOTA_FISCAL + '/produto/' + data.id).subscribe(resp => {
            this.entity.produtos.splice(index, 1);

            Swal.fire({
              icon: 'success',
              title: "Produto removido",
              confirmButtonText: 'Sair',
              timer: 5000
            })
            crudDispatchEvent('produtoFiscalEntity')
          })
        } else {
          this.entity.produtos.splice(index, 1);

          crudDispatchEvent('produtoFiscalEntity')
          Swal.fire({
            icon: 'success',
            title: "Produto removido",
            confirmButtonText: 'Sair',
            timer: 5000
          })
        }
      }
    })
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.docFile = undefined;
        return;
      }

      const data = new FormData()
      data.append('arquivo', file)
      data.append('notaFiscalId', String(this.entity.id))
      // @ts-ignore
      this.serviceDoc.save(data).subscribe(
        () => {
          window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'ArquivoNotaFiscalEntity' }));
          // this.file = undefined;
        }
      )
    }
  }

  dataAtualFormatada(val?: string | number) {
    return formata_data_utc_br(String(val)).split('/').reverse().join('-');
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };

  public reset = () => {
    this.formModalProd.reset({
      produto: '',
      quantidade: '',
      precoPorTonelada: '',
      id: ''
    });
  }

}
