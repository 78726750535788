import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'subComponent-carousel-cards',
  templateUrl: './carousel-cards.component.html',
  styleUrls: ['./carousel-cards.component.scss']
})
export class CarouselCardsComponent implements OnInit, AfterViewInit {

  public listParceiros: string[] = [
    '/assets/img/site/logos-parceiros/ambiente.png',
    '/assets/img/site/logos-parceiros/AS_logo_azul.png',
    '/assets/img/site/logos-parceiros/cipa.png',
    '/assets/img/site/logos-parceiros/coco-legal.png',
    '/assets/img/site/logos-parceiros/enseada.png',
    '/assets/img/site/logos-parceiros/logo_condominioapp_transparente.png',
    '/assets/img/site/logos-parceiros/logo-potato-recicla.jpg',
    '/assets/img/site/logos-parceiros/oceano-residuos-logo-og.png',
    '/assets/img/site/logos-parceiros/organico.png',
    '/assets/img/site/logos-parceiros/pac-oleo.png',
    '/assets/img/site/logos-parceiros/pro-recicle.png',
    '/assets/img/site/logos-parceiros/tassinari.jpg'
  ];

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const a = window.document.getElementsByClassName('.carousel-item');
    const b = window.document.getElementsByClassName('.carousel');

    // if (Array.isArray(a)) {
    //   a.map((html) => {
    //     const minPerSlide = 4;
    //     let next = html.next();
    //     if (!next.length) {
    //       next = html.siblings(':first');
    //     }
    //     next.children(':first-child').clone().appendTo(html);
    //
    //     for (var i=0;i<minPerSlide;i++) {
    //       next=next.next();
    //       if (!next.length) {
    //         next = html.siblings(':first');
    //       }
    //
    //       next.children(':first-child').clone().appendTo(html);
    //     }
    //   })
    // }
    //
    // if (Array.isArray(b)) {
    //   b.map((html) => {
    //     const minPerSlide = 4;
    //     let next = html.next();
    //     if (!next.length) {
    //       next = html.siblings(':first');
    //     }
    //     next.children(':first-child').clone().appendTo(html);
    //
    //     for (var i=0;i<minPerSlide;i++) {
    //       next=next.next();
    //       if (!next.length) {
    //         next = html.siblings(':first');
    //       }
    //
    //       next.children(':first-child').clone().appendTo(html);
    //     }
    //   })
    // }
  }

}
