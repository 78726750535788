import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlogEntity} from "../blog.entity";
import {formataData} from '../../../../helpers/helpers'
import {FilesManageService} from "../../../../services/files-manage/files-manage.service";

@Component({
  selector: 'card-post',
  templateUrl: './card-post.component.html',
  styleUrls: ['./card-post.component.scss']
})
export class CardPostComponent implements OnInit {

  @Input('dataPost') dataPost!: BlogEntity;
  @Output() editar = new EventEmitter<BlogEntity>();
  @Output() excluir = new EventEmitter<number | string>();

  public formatData = formataData;

  constructor(public file: FilesManageService) { }

  ngOnInit(): void {
  }

  public editarPost() {
    this.editar.emit(this.dataPost)
  }

  public excluirPost() {
    if (!this.dataPost.id) {
      return;
    }

    this.excluir.emit(this.dataPost.id)
  }

  recortTextBlog(text: string, recort: boolean): string{
    if (text.length <= 300) {
      return text;
    }

    if (!recort) {
      let text2 = text.substring(0,300).split(' ');
      text2.pop();

      return text2.join(' ') + '...';
    }

    return text
  }

}
