<div
    id="material-insert-edit"
    class="container"
>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <card>




                <div class="card-header">
                    <div class="col-10">
                        <h4 class="card-title">Foto do Material</h4>
                    </div>
                    <div class="col-2">
                        <mat-icon
                            matTooltipPosition="above"
                            style="color: grey;"
                            matTooltip="A largura é 25 por cento maior que a altura. Exemplos de resolução: 500x400, 375x300, 250x200"
                        >
                            help
                        </mat-icon>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="div-image-material w-100">
                                <input
                                    #fileUploadPlitica
                                    [(ngModel)]="fileMaterial"
                                    type="file"
                                    (change)="uploadMaterial($event)"
                                    name="uploadMaterial"
                                    id="uploadMaterial"
                                    accept="image/png, image/jpeg, image/jpg"
                                    style="display: none"
                                >
                                <img
                                    class="image-material"
                                    [src]="files.getImageToSrc(entity?.imagem)"
                                    alt="Imagem do material"
                                >
                            </div>




                        </div>
                    </div>
                </div>
                <div
                    class="div-button-image-material w-100 pb-3"
                    matTooltipPosition="above"
                    [matTooltip]="!entity?.id ? 'Para adicionar uma imagem é necessario salvar os dados primeiro.' : ''"
                >
                    <button
                        [disabled]="!entity?.id"
                        (click)="fileUploadPlitica.click()"
                        class="btn btn-success"
                    >
                        Escolher
                    </button>
                </div>
            </card>
        </div>




        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6">
            <form [formGroup]="form">
                <card>




                    <div class="card-header">
                        <h4 class="card-title">Informações do material</h4>
                    </div>




                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <label
                                    for="nome"
                                    class="form-label"
                                >
                                    NOME
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="text"
                                    formControlName="nome"
                                    class="form-control"
                                    id="nome"
                                >
                            </div>
                            <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
                                <label
                                    for="pontos"
                                    class="form-label"
                                >
                                    PONTOS PARA ACESSAR O DESCONTO
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    (keydown)="checkNumber($event)"
                                    formControlName="pontos"
                                    class="form-control"
                                    id="pontos"
                                >
                            </div>
                            <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12">
                                <label
                                    for="precoPorPontos"
                                    class="form-label"
                                >
                                    PREÇO POR PONTO
                                    <span class="fc-red">*</span>
                                </label>
                                <div class="input-group w-100">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1">R$:</span>
                                    </div>
                                <input
                                    formControlName="precoPorPontos"
                                    class="form-control"
                                    id="precoPorPontos"
                                >
                            </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label
                                    for="valor"
                                    class="form-label"
                                >
                                    VALOR (R$)
                                    <span class="fc-red">*</span>
                                </label>
                                <div class="input-group w-100">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1">R$:</span>
                                    </div>
                                <input
                                    formControlName="valor"
                                    class="form-control"
                                    id="valor"
                                >
                            </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label
                                    for="minimoPedido"
                                    class="form-label"
                                >
                                    QUANTIDADE MÍNIMA
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    (keydown)="checkNumber($event)"
                                    formControlName="minimoPedido"
                                    class="form-control"
                                    id="minimoPedido"
                                >
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label
                                    for="parceiro"
                                    class="form-label"
                                >
                                    PARCEIRO
                                </label>
                                <select
                                    class="form-select"
                                    formControlName="parceiro"
                                    id="parceiro"
                                >
                                    <option
                                        disabled
                                        value
                                    >
                                        Selecione
                                    </option>
                                    <option
                                        *ngFor="let parceiro of listParceiro"
                                        [value]="parceiro.id"
                                    >
                                        {{ parceiro.nome }}
                                    </option>




                                </select>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label
                                    for="disponivel"
                                    class="form-label"
                                >
                                    DISPONIVEL
                                </label>
                                <select
                                    class="form-select"
                                    formControlName="disponivel"
                                    id="disponivel"
                                >
                                    <option
                                        disabled
                                        value
                                    >
                                        Selecione
                                    </option>
                                    <option value="true">Sim</option>
                                    <option value="false">Não</option>
                                </select>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12">
                                    <label class="form-label">
                                        DESCRIÇÃO
                                        <span class="fc-red">*</span>
                                    </label>
                                    <angular-editor
                                        formControlName="descricao"
                                        [config]="editorConfig"
                                    ></angular-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </form>
        </div>
    </div>
</div>




<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
