<old-dg-modal
    #modalVeiculo
    size="md"
    title="Dados do veículo"
    [afterClose]="afterClose"
    :xmlns:div="http://www.w3.org/1999/html"
>
    <div dg-content>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">MARCA</label>
                    <input
                        type="text"
                        formControlName="marca"
                        class="form-control"
                    >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">TIPO</label>
                    <input
                        type="text"
                        formControlName="tipo"
                        class="form-control"
                    >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">CAPACIDADE (m³)</label>
                    <input
                        type="text"
                        formControlName="capacidade"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">ANO DE FABRICAÇÃO</label>
                    <input
                        type="text"
                        formControlName="anoFabricacao"
                        mask="0000"
                        class="form-control"
                    >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">RENAVAM</label>
                    <input
                        type="text"
                        formControlName="renavam"
                        mask="0000000000-0"
                        class="form-control"
                    >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="form-label">PLACA</label>
                    <input
                        type="text"
                        formControlName="numeroPlaca"
                        class="form-control"
                    >
                </div>
            </div>
        </form>
    </div>
    <div dg-footer>
        <button
            (click)="salvar()"
            class="btn btn-success m-1"
            dg-desable-by-period
        >
            Salvar
        </button>
        <button
            (click)="modalVeiculo.close()"
            class="btn btn-secondary m-1"
        >
            Sair
        </button>
    </div>
</old-dg-modal>
