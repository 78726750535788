import {Injectable} from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {BlogEntity} from "../blog.entity";
import {ConfigDgCrudService} from "../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../environments/environment";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {BLOG} from "../../../../core/config/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class BlogService extends AbstractHttpService<BlogEntity> {

  constructor(
    public config: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(config, environment.apiUrl, BLOG)
  }

  uploadImage(data: any) {
    return this.save(data, 'imagem');
  }
}
