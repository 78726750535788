import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserLayoutComponent} from './user-layout/user-layout.component';
import {RouterModule} from "@angular/router";
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRadioModule} from "@angular/material/radio";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {NavigationComponent} from './components/navigation/navigation.component';
import {SidebarLogoComponent} from './components/sidebar-logo/sidebar-logo.component';
import {NavbarHeaderComponent} from './components/navbar-header/navbar-header.component';
import {UiModule} from "../../shared/ui/ui.module";
import {DirectivesModule} from "../../core/directives/directives.module";
import {AlterPasswordComponent} from './components/alter-password/alter-password.component';
import {ModalPesquisarComponent} from './components/modal-pesquisar/modal-pesquisar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SystemReturnMessageColumnComponent } from './components/system-return-message-column/system-return-message-column.component';


@NgModule({
  declarations: [
    UserLayoutComponent,
    SidebarComponent,
    NavigationComponent,
    SidebarLogoComponent,
    NavbarHeaderComponent,
    AlterPasswordComponent,
    ModalPesquisarComponent,
    SystemReturnMessageColumnComponent,
  ],
  exports: [
    SidebarComponent,
    SidebarLogoComponent,
    NavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    UiModule,
    DirectivesModule,
    FormsModule,
    MatTooltipModule
  ]
})
export class UserLayoutModule {
}
