import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-impacto-ambiental2',
  templateUrl: './card-impacto-ambiental2.component.html',
  styleUrls: ['./card-impacto-ambiental2.component.scss']
})
export class CardImpactoAmbiental2Component implements OnInit {

  @Input('loader') loader = true;

  @Input('data') data: {
    areia: 0,
    arvores: 0,
    petroleo: 0,
    agua: 0,
    energia: 0,
    minerio: 0,
  } | any = {
    areia: 0,
    arvores: 0,
    petroleo: 0,
    agua: 0,
    energia: 0,
    minerio: 0,
  }

  constructor() {}

  ngOnInit(): void {}

  ajustaNumber(val: number) {
    if (!val) return 0;

    return val.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 3,
    });
  }
}
