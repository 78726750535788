<div style="margin-top: 1rem">
  <card>
    <div class="card-header">
      <h3 class="card-title">Filtros</h3>
    </div>
    <div class="card-body">
      <ul class="list-filter-blog-ano">
        <li
          style="padding: 0;"
          *ngFor="let ano of listPosts2; let i = index"
        >
          <p
            class="item-list-ano"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse-ano-'+ano.ano"
            aria-expanded="false"
            [attr.aria-controls]="'collapse-ano-'+ano.ano"
          >
            {{ano.ano}}
          </p>

          <ul class="collapse list-filter-blog-mes" id="collapse-ano-{{ano.ano}}">
            <li
              *ngFor="let mes of listPosts2[i].mes; let index = index"
              (click)="getListPosts(i, index)"
            >
              <p
                class="item-list-mes"
                style="margin: 0; padding: .5rem"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-ano-'+ano.ano+'-'+mes.mes"
                aria-expanded="false"
                [attr.aria-controls]="'collapse-ano-'+ano.ano+'-'+mes.mes"
              >
                {{getNomeMes(mes.mes)}}
              </p>

              <ul class="collapse list-filter-blog-post" id="collapse-ano-{{ano.ano}}-{{mes.mes}}">
                <li *ngIf="(listPosts2[i].mes[index]?.list_posts || []).length <= 0">
                  Buscando...
                </li>
                <a>
                  <li
                    *ngFor="let post of mes?.list_posts"
                    (click)="buscarPost(post.id)"
                  >
                    <p [matTooltip]="post.titulo" matTooltipPosition="before" class="item-list-post">
                      {{post.titulo}}
                    </p>
                  </li>
                </a>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </card>
</div>
