import {
  callbackMessageColumn
} from "../layouts/user/components/system-return-message-column/system-return-message-column.component";

/**
 * A medida que o sistema vai crescendo e nós vamos precisando realizar comunicações entre partes
 * por meio de eventos, crieie esse helper para agrupar funções que auxiliam nessa comunicação
 */

// evento para adicionar mensagens de retorno do sistema
export function addMessageCallbackColumn(message: callbackMessageColumn) {
  window.dispatchEvent(new CustomEvent('system-return-message-column-add', { detail: message }))
}
// evento para remover mensagens de retorno do sistema
export function removeMessageCallbackColumn(keys: string[]) {
  window.dispatchEvent(new CustomEvent('system-return-message-column-remove', { detail: keys }))
}
