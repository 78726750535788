<div id="indicadores-ods-insert-edit" class="container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 container-data">
        <ng-container *ngTemplateOutlet="cardImage"></ng-container>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 container-data">
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">NOME DO OBJETIVO <span class="fc-red">*</span></label>
                <input class="form-control " formControlName="nomeObjetivo" id="nomeObjetivo">
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label class="form-label">DESCRIÇÃO DO OBJETIVO <span
                    class="fc-red">*</span></label>
                <textarea class="form-control" formControlName="descricaoObjetivo" id="descricaoObjetivo" cols="20"
                  rows="9"></textarea>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>
</div>

<div class="container-data">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">

          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="card-title">Meta</h4>
              </div>
              <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <button class="btn btn-success btn-card-header" (click)="modalMeta.open()">adicionar Meta</button>
              </card-header-buttons>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="accordion">
            <div class="accordion-item" *ngFor="let meta of entity?.listMeta; let index = index">
              <div class="accordion-header">
                <div class="row">
                  <div
                    class="col-xl-10 col-lg-10 col-md-8 col-sm-8  d-inline-flex justify-content-between"
                    aria-expanded="false"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapseOne-'+index"
                    [attr.aria-controls]="'collapseOne-'+index"
                  >
                    NOME DA META: {{meta?.nomeMeta}}
                  </div>
                  <div class="col-1">
                    <div>
                      <button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button  mat-menu-item (click)="modalMeta.open(index, meta)">Editar Meta</button>
                        <button  mat-menu-item (click)="excluirMeta(index)">Excluir Meta</button>
                        <button  mat-menu-item (click)="modalIndicador.open(index)">Adicionar Indicador</button>
                        <div *ngFor="let ind of (meta?.indicadores || []); let indexInd = index">
                          <button *ngIf="!meta.indicadores" mat-menu-item (click)="modalIndicador.open(index, indexInd, ind)">Editar</button>
                          <button  *ngIf="!meta.indicadores" mat-menu-item (click)="excluirIndicador(index, indexInd)">Excluir</button>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </div>
              <div [id]="'collapseOne-'+index"
                class="accordion-collapse collapse show" aria-labelledby="headingOne">
                <div class="accordion-body">
                  <table class="crud-table">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ind of (meta?.indicadores || []); let indexInd = index">
                        <td>{{ind?.nomeIndicador || '--'}}</td>
                        <td>{{ind?.descricaoIndicador || '--'}}</td>
                        <td>
                          <button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button *ngIf="meta.indicadores" mat-menu-item (click)="modalIndicador.open(index, indexInd, ind)">Editar</button>
                            <button *ngIf="meta.indicadores"  mat-menu-item (click)="excluirIndicador(index, indexInd)">Excluir</button>
                          </mat-menu></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>

<!-- IMAGEM -->
<ng-template #cardImage>
  <card>
    <div class="card-body">
      <div class="col-12">
        <div class="div-image-objetivo w-100">
          <input #fileUploadPlitica type="file" (change)="uploadObjetivo($event)" style="display: none"
            accept="image/png, image/jpeg, image/jpg">
          <img class="image-objetivo" [src]="files.getImageToSrc(entity?.imagem)" alt="Imagem do objetivo">
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button [disabled]="!entity?.id" (click)="fileUploadPlitica.click()" class="btn btn-success">
        Escolher
      </button>
    </div>

  </card>
</ng-template>

<!-- FOOTER -->
<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar [backAfterSave]="false" class="btn btn-success">Salvar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
</page-footer>

<!-- MODAL META -->
<modal-modal-meta-ods #modalMeta (salvar)="salvarMeta($event)">
</modal-modal-meta-ods>

<!-- MODAL INDICADOR -->
<modal-indicador-meta-ods #modalIndicador (salvar)="salvarIndicador($event)">
</modal-indicador-meta-ods>
