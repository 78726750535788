import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { VendasLogisticaProdutosEntity } from '../../../vendas-produtos/vendas-logistica-produtos.entity';
import { VendasLogisticaProdutosService } from '../../../vendas-produtos/services/vendas-logistica-produtos.service';
import { InterfaceCustomActions } from '@datagrupo/dg-crud';
import { ProdutoService } from "../../../produto/service/produto.service";
import { VendasLogisticaReversaService } from "../../service/vendas-logistica-reversa.service";
import { formata_data_utc_br } from 'src/app/helpers/helpers';

@Component({
  selector: 'subComponente-Vendas-Logistica-Produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent implements OnInit {

  @ViewChild('modalProduto') modalProduto!: OldModalComponent
  @Output() salve = new EventEmitter<any>()
  @Input() idCliente: number | string | undefined;
  @Input() idVendaLogisticaReversa: number | string | undefined;

  public lisTprodutosFisc: any[] = [
    {
      produto: '',
      quantidadeVen: '',
      vendaParcial: '',
      quantidadeDisp: '',
      validade: ''
    }
  ];

  public produtoSelected = '';

  constructor(
    public proService: VendasLogisticaReversaService
  ) { }

  ngOnInit(): void {
  }

  public open(data?: any, index?: number) {

    if (data.length <= 0) {
      if (!!this.idCliente) {
        this.proService.findOne(this.idCliente, 'produtos-nota-fiscal').subscribe(
          resp => {
            this.lisTprodutosFisc = resp.data
          }
        )
        this.modalProduto.open()
      }
    } else {
      let listId = data.map((produto: any) => {
        return produto.produtoNotaFiscal.id
      });
      this.proService.get(`venda-logistica-reversa/produtos-nota-fiscal/${this.idCliente}`, {params:{listPnfIds: listId}}).subscribe(
        resp => {
          this.lisTprodutosFisc = resp.data
        }
      )
      this.modalProduto.open()
    }
  }

  salvarProdutos(data?: any) {
    const index = this.lisTprodutosFisc.findIndex((prods) => prods.produtNotaFiscalId == this.produtoSelected);
    if (index == -1) {
      return;
    }

    const result = {
      produtoNotaFiscal: {
        id: this.lisTprodutosFisc[index].produtNotaFiscalId,
        produto: {
          id: this.lisTprodutosFisc[index].produtoId,
          nome: this.lisTprodutosFisc[index].nomeProduto
        }
      },
      quantidadeVendida: this.lisTprodutosFisc[index].quantidadeVendida,
      quantidadeDisponivel: this.lisTprodutosFisc[index].quantidadeDisponivel,
      notaFiscalChaveAcesso: this.lisTprodutosFisc[index]?.chaveNotaFiscal
    }

    this.salve.emit(result);

    this.modalProduto.close();
  }

  close = (e?: any) => {
    this.produtoSelected = '';
    return true
  }

  maskData(val?: string) {
    if (!val) return '--'
    return formata_data_utc_br(val);
  }

  format = (val: string | number | undefined) => {
    if (val) {
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    } else {
      return 0
    }
  }
}
