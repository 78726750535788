<div class="offcanvas offcanvas-end sidebar-filter" tabindex="-1" id="offcanvasRight"
     aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Filtros (original)</h5>
    <span class="material-symbols-outlined" style="cursor: pointer" (click)="dataManage.clearFilter()" matTooltip="Limpar filtros">delete</span>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div
      class="row"
      *ngIf="tipoDeTela == 'rankingTaxaReciclagem' && this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
    >
      <div class="col-12">
        <label class="form-label">Tipo de Perfil (Gerador)</label>
        <select
          class="form-select"
          [(ngModel)]="_dataFilter.perfilUnidade"
          (ngModelChange)="dataManage.verifyChangeData('perfilUnidade')"
        >
          <option disabled value="">Selecione um perfil</option>
          <option value="EMPRESA">Empresa</option>
          <option value="CONDOMINIO">Condomínio</option>
          <option value="EVENTO">Evento</option>
          <option value="CASA">Casa</option>
        </select>
      </div>
    </div>

    <div
      class="row"
      *ngIf="tipoDeTela == 'rankingAterroZero' || tipoDeTela == 'rankingTaxaReciclagem' ? true : !this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])"
    >
      <div class="col-12">
        <label class="form-label">Unidades Geradoras</label>
        <input-autocomplete
          key="id"
          label="nome"
          [multiple]="true"
          [showSelectedList]="true"
          [(ngModel)]="_dataFilter.unidades"
          [list]="(listItensDisponiveis.unidades || [])"
          [preSelectedList]="listsPreSelected.unidades"
          (close)="setAutoComplete($event, 'unidades')"
        >
        </input-autocomplete>
      </div>
    </div>

    <div class="row" *ngIf="tipoDeTela != 'rankingAterroZero' && tipoDeTela != 'rankingTaxaReciclagem'">
      <div class="col-12">
        <label class="form-label">Operadores Logísticos</label>
        <input-autocomplete
          key="id"
          label="nome"
          [multiple]="true"
          [showSelectedList]="true"
          [(ngModel)]="_dataFilter.galpao"
          [list]="(listItensDisponiveis.galpao || [])"
          [preSelectedList]="listsPreSelected.galpao"
          (close)="setAutoComplete($event, 'galpao')"
        >
        </input-autocomplete>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label">Data Início</label>
        <input
          class="form-control"
          (paste)="ajustePasteDate($event, 'dataInicio')"
          type="date"
          [max]='maxDate'
          [(ngModel)]="_dataFilter.dataInicio"
          (change)="dataManage.verifyChangeData('dataInicio')"
        >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label">Data Fim</label>
        <input
          class="form-control"
          (paste)="ajustePasteDate($event, 'dataFim')"
          type="date"
          [(ngModel)]="_dataFilter.dataFim"
          (ngModelChange)="dataManage.verifyChangeData('dataFim')"
        >
      </div>
    </div>

    <div
      class="row"
      *ngIf="tipoDeTela == 'rankingAterroZero' || tipoDeTela == 'rankingTaxaReciclagem' ? true : !session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR', 'GALPAO'])"
    >
      <div class="col-12">
        <label class="form-label">Estados</label>
        <input-autocomplete
          key="uf"
          label="name"
          [multiple]="true"
          [(ngModel)]="_dataFilter.estados"
          [list]="(listItensDisponiveis.estados || [])"
          [preSelectedList]="listsPreSelected.estados"
          (close)="setAutoComplete($event, 'estados')"
        >
        </input-autocomplete>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label">Tipos de Resíduo</label>
        <input-autocomplete
          key="id"
          label="nome"
          [showSelectedList]="true"
          [multiple]="true"
          [(ngModel)]="_dataFilter.tipoResiduo"
          [list]="(listItensDisponiveis.tipoResiduo || [])"
          [preSelectedList]="listsPreSelected.tipoResiduo"
          (close)="setAutoComplete($event, 'tipoResiduo')"
        >
        </input-autocomplete>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label">Ativo/Inativo</label>
        <select [(ngModel)]="_dataFilter.ativo" (change)="setQueryParams()" class="form-select">
          <option [value]="''">Ambos</option>
          <option [value]="'true'">Ativo</option>
          <option [value]="'false'">Inativo</option>
        </select>
      </div>
    </div>

  </div>
</div>
