import {AbstractEntity, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";


export class ProdutoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    valorDoProduto?: string,
    quantidadeProdutoAviso?: number
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.valorDoProduto = valorDoProduto;
    this.quantidadeProdutoAviso = quantidadeProdutoAviso;
  }

  @DgFilter_input('text', { colum: '4' })
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined



  public quantidadeProdutoAviso: number | undefined;
  public valorDoProduto: string | undefined;


}
