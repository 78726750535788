<div id="carouselPrincipalHome" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner h-100">

    <div class="carousel-item h-100 w-100 active" [style.background-image]="'url(assets/img/site/slide/slide-01.png)'">
      <div class="col-xl-2 col-lg-2 col-md-4 carousel-caption">
        <div class="div-caption w-100">
          <h4 class="font-fredoka fc-custom-title-carousel-green">Engajamento em fazer a gestão de resíduos e a logística reversa acontecer</h4>
          <p class="fc-white fs-14 f-bold">Fazemos gestão de resíduos.</p>
        </div>
      </div>
    </div>
    <div class="carousel-item h-100 w-100" [style.background-image]="'url(assets/img/site/slide/slide-02.jpg)'">
      <div class="offset-xl-1 offset-lg-1 col-xl-2 col-lg-2 col-md-6 carousel-caption">
        <div class="div-caption">
          <h4 class="font-fredoka" style="color: #363e41">Sua indústria tem um programa de logística reversa automatizada, rastreável e barata</h4>
          <p class="fc-green fs-14 f-bold">Seu resíduo encaminhado de forma correta e garantida.</p>
        </div>
      </div>
    </div>
    <div class="carousel-item h-100 w-100" [style.background-image]="'url(assets/img/site/slide/slide-03.png)'">
      <div class="col-xl-2 col-lg-2 col-md-4 carousel-caption">
        <div class="div-caption">
          <h4 class="font-fredoka fc-custom-title-carousel-green">Depósito de reciclagem podem aumentar os recicláveis e o relacionamento com a indústria.</h4>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselPrincipalHome" data-bs-slide="prev">
    <div class="seletores">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </div>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselPrincipalHome" data-bs-slide="next">
    <div class="seletores">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </div>
  </button>

  <div class="borda-inclinada"></div>

</div>
