import { Component, OnInit } from '@angular/core';
import { InterfPagination } from '@datagrupo/dg-crud';
import { COBRANCAS } from 'src/app/core/config/endpoint-list';
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { CobrancasService } from '../../../cobrancas/services/cobrancas-service/cobrancas.service';
import { MeuPerfilService } from '../../service/meu-perfil.service';

@Component({
  selector: 'subComponent-user-historico-cobrancas',
  templateUrl: './user-historico-cobrancas.component.html',
  styleUrls: ['./user-historico-cobrancas.component.scss']
})
export class UserHistoricoCobrancasComponent implements OnInit {

  historicos: any;

  pagination: InterfPagination = {
    page: 0,
    size: 10,
    sort: 'id,desc'
  }

  constructor(
    private service: CobrancasService,
    private session: SessionService,
  ) {
    // service.alterResource(`cobrancas/idCliente=${session.user.id}`)
  }

  ngOnInit(): void {
    this.getData();

  }

  getData() {
    // @ts-ignore
    this.service.get(
      COBRANCAS,
      {
        params: {
          ...this.pagination,
          idCliente: this.session.user.id
        }
      }
    ).subscribe((resp: any) => {
      //@ts-ignore
      this.historicos = resp.data;

      //  @ts-ignore
      this.pagination = {
        ...resp.page,
        //  @ts-ignore
        page: resp.page.number
      }
    });
  }

  changePage(pagination: any) {
    console.log(pagination);
    this.pagination = {
      ...this.pagination,
      ...pagination
    }

    this.getData()
  }

}
