import {AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import {ClientesEntity} from "../../_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../_galpao/galpao/galpao.entity";
import {ColetaArquivoEntity} from "./coleta-arquivo.entity";
import {ColetasContainerEntity} from "./coletas-container.entity";
import {environment} from "../../../../../environments/environment";
import {RotasEntity} from "../../rotas/rotas.entity";
import {CheckinEntity} from "./checkin.entity";
import {GalpaoMotoristaEntity} from "../../_galpao/galpao-motorista/galpao-motorista.entity";
import {autoCompleteClientesOutPut} from "../../../../helpers/auto-complete/clientes-autocomplete.helper";

export interface inferfAvaliacao {
  id?: number | string,
  estrelas: number
}

export interface interfPontuacao {
  id: number,
  valor: number,
  valorRanking: number
}

@DataServer({
  path: environment.apiUrl,
  context: 'coletas'
})
export class ColetasEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    avaliacaoClienteMotorista?: inferfAvaliacao,
    avaliacaoMotoristaCliente?: inferfAvaliacao,
    dataColeta?: string,
    cliente?: ClientesEntity,
    motorista?: GalpaoMotoristaEntity,
    galpao?: GalpaoEntity,
    status?: string,
    arquivos?: ColetaArquivoEntity[],
    checkin?: CheckinEntity,
    rota?: RotasEntity,
    pontuacao?: interfPontuacao,
    pesoReal?: number,
    codigoMtr?: string
  ) {
    super();
    this.id = id;
    this.avaliacaoClienteMotorista = avaliacaoClienteMotorista;
    this.avaliacaoMotoristaCliente = avaliacaoMotoristaCliente;
    this.dataColeta = dataColeta;
    this.cliente = cliente;
    this.motorista = motorista;
    //this.galpao = galpao;
    this.status = status;
    this.arquivos = Array.isArray(arquivos) ? arquivos : [];
    this.checkin = !!checkin ? checkin : new CheckinEntity();
    this.rota = rota;
    this.pontuacao = pontuacao;
    this.pesoReal = pesoReal;
    this.codigoMtr = codigoMtr;
  }

  @DgFilter_input('date', {colum: '3', name: 'DATA INICIAL'})
  public dataInicial: any;

  @DgFilter_input('date', {colum: '3', name: 'DATA FINAL'})
  public dataFinal: any;

  @DgTableColumn({
    columnName: 'ID rota', resource: (cel: RotasEntity, row: ColetasEntity) => row.rota?.id || '--',
    columnClassName: 'p-1'
  })
  public idRota: any;

  @DgTableColumn({
    columnName: 'Data Coleta', sort: true, resource: (cel: RotasEntity, row: ColetasEntity) => {
      return !!row.rota?.dataRota ? row.rota.dataRota.split('-').reverse().join('/') : '--'
    },
  })
  public ['rota.dataRota']: any;


  public rota: RotasEntity | undefined;

  @DgFilter_autoComplete({name: 'Cliente'},
    {
      getUrl: environment.apiUrl + 'clientes/nomes',
      getServerData: "all",
      propertValue: 'nome',
      nameParamFind: 'clienteId',
      propertKey: 'id',
      outputFormat: autoCompleteClientesOutPut,
    })
  @DgTableColumn({columnName: 'Cliente', sort: true, resource: (cel: ClientesEntity) => !!cel?.nome ? cel.nome : '--'})
  public cliente: ClientesEntity | undefined

  @DgFilter_autoComplete({},
    {
      getUrl: environment.apiUrl + 'motoristas',
      getServerData: "all",
      propertValue: 'nome',
      nameParamFind: 'motoristaId',
      propertKey: 'id'
    })
  @DgTableColumn({
    columnName: 'Motorista/Catador', resource: (cel: any, row: ColetasEntity) => {
      return !!row?.rota?.motorista?.nome ? row.rota.motorista.nome : '--'
    }
  })
  public rotaMotorista: any;

  @DgTableColumn({
    columnName: 'Transportador', resource: (cel: any, row: ColetasEntity) => {
      return !!row?.rota?.galpao?.nome ? row.rota.galpao.nome : '--'
    }
  })
  public rotaGalpao: GalpaoEntity | undefined

  @DgTableColumn({
    columnName: 'N° MTR', resource: (cel?: string) => {
      return cel || '--';
    }
  })
  public codigoMtr: string | undefined;

  @DgTableColumn({
    columnName: 'Status', resource: (cel: string, row: ColetasEntity) => {
      return !!row?.status ? row.status : '--'

    }
  })
  public status: string | undefined

  public pontuacao: interfPontuacao | undefined;


  public avaliacaoMotoristaCliente: inferfAvaliacao | undefined;
  public avaliacaoClienteMotorista: inferfAvaliacao | undefined;
  public arquivos: ColetaArquivoEntity[] = [];
  public checkin: CheckinEntity = new CheckinEntity();
  public pesoReal: number | undefined;

  //   EXCLUIR
  public dataColeta: number | string | undefined
  public motorista: GalpaoMotoristaEntity | undefined
  public galpao: GalpaoEntity | undefined
}
