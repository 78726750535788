
export const CLIENTE = 'clientes'
export const CLIENTE_FIND_FILTER = 'clientes/nomes'
export const ARQUIVO_CLIENTE = 'arquivo-cliente'
export const CLIENTE_CONTAINER = 'cliente-containers'
export const CLIENTE_CONTAINER_PLANO_REGULAR = 'cliente-containers/plano-regular'
export const CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA = 'clientes/recalcula'
export const CLIENTE_ATIVO_INATIVO = 'clientes/ativo'
export const CLIENTE_DATA_ROTA = 'clientes/data-rota'
export const CLIENTE_ESTADOS = 'clientes/estados'
export const CLIENTE_PARA_ROTAS = 'clientes/with-cliente-container'
export const CADASTRAR_WEBSITE = 'clientes/externo'
