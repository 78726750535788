<old-dg-modal
  #modal
  title="Modificar a data da rota"
  [size]="listRotas.length > 0 ? 'md' : 'sm'"
  [afterClose]="afterClose"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Informe uma data</label>
          <input type="date" (paste)="ajustePasteDate($event)" formControlName="date" class="form-control">
        </div>
      </div>

      <div class="row mt-2">
        <card>
          <div class="card-body">
            <div class="col-12">
              <table class="crud-table">
                <thead>
                <tr>
                  <th></th>
                  <th>Data</th>
                  <td>Motorista/Catador</td>
                  <td>Veículo</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rota of listRotas" (click)="realInput.click()" (dblclick)="emitSave(rota.id)">
                  <td class="m-2">
                    <input type="radio" [checked]="realInput.checked">
                    <input
                      #realInput
                      type="radio"
                      [hidden]="true"
                      formControlName="rota"
                      [value]="rota.id"
                    >
                  </td>
                  <td>{{rota.dataRota}}</td>
                  <td>{{rota?.motorista?.nome || '--'}}</td>
                  <td>{{rota?.veiculo?.numeroPlaca || '--'}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button (click)="save()" class="btn btn-success m-1">Alterar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
