<div class="loading-content" *ngIf="show && visible">
  <div class="root">
    <div class="card col-6">
      <div class="card-header d-inline-flex justify-content-between">
        <h6>Buscando dados</h6>
        <mat-icon (click)="show = false">close</mat-icon>
      </div>
      <div class="card-body">
        <table class="w-100" *ngIf="!msgMount">
          <thead>
          <tr>
            <th style="width: 60%"></th>
            <th style="width: 40%"></th>
          </tr>
          </thead>
          <tbody>
          <tr  *ngFor="let itemLoad of loadingList">
            <td>{{itemLoad.nome}}</td>
            <td>
              <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width]="itemLoad.complete ? '100%' : '0%'" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <h4 style="text-align: center" *ngIf="msgMount">Montando tela</h4>
      </div>
    </div>
  </div>
</div>
