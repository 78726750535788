<card>
  <div class="card-header" style="display: block !important;">
    <h4 class="card-title">Gráfico de lixo zero</h4>
    <p class="card-subtitle fc-yellow fs-11">Apenas Demonstrativo (implementação do gráfico em desenvolvimento)</p>
  </div>
  <div class="card-body">
    <div class="row">
      <div *ngIf="loader" class="h-100 w-100 d-flex justify-content-center align-items-center">
        <mat-spinner></mat-spinner>
      </div>
      <div [hidden]="loader" id="id-chart-impacto"></div>


<!--      <img src="assets/img/mock/img.png" style="width: 100%; height: 100%; opacity: .2">-->
    </div>
  </div>
</card>
