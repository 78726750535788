<div
    id="cupom-insert-edit"
    class="container-data"
>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <card>
                    <div class="card-header">
                        <h4 class="card-title">Cupom</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <label
                                    for="nome"
                                    class="form-label"
                                >
                                    NOME
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="text"
                                    formControlName="nome"
                                    class="form-control"
                                    id="nome"
                                >
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <label
                                    for="link"
                                    class="form-label"
                                >
                                    Link
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="text"
                                    formControlName="link"
                                    class="form-control"
                                    id="link"
                                >
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-4 d-flex justify-content-center align-items-center">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    formControlName="enviarCupomTodos"
                                    id="flexCheckIndeterminate"
                                >
                                <label
                                    class="form-check-label fs-12"
                                    style="margin-left: 0.3rem"
                                    for="flexCheckIndeterminate"
                                >
                                    ENVIAR PARA TODOS
                                </label>
                            </div>
                        </div>



                        <div class="row mt-5">
                            <div class="col-12">
                                <label class="form-label">DESCRIÇÃO</label>
                                <angular-editor
                                    formControlName="descricao"
                                    [config]="editorConfig"
                                ></angular-editor>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </form>
</div>



<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        class="btn btn-success"
        [backAfterSave]="false"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
