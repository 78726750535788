<div id="coletas-main" class="container">

  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="tableVisible = 'coletas'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas'">Coletas</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'coletas-pendentes'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas-pendentes'">Coletas Pendentes</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'coletas-canceladas'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas-canceladas'">Coletas Canceladas</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'mtr'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'mtr'">MTR</h4>
        </li>
      </ul>
    </div>
  </div>

  <div class="row mt-3" [hidden]="tableVisible != 'coletas'">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title" [class.active]="tableVisible == 'coletas'">Coletas</h4>
        </div>
        <div class="card-subtitle">
          <filter-search2 dynamicGroupName="coletas">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data inicial</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas"
                  paramName="dataInicial"
                  [formControl]="filters.coletas.dataInicial"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data final</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas"
                  paramName="dataFinal"
                  [formControl]="filters.coletas.dataFinal"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])">
                <label class="form-label">Cliente</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.cliente"
                  dg-dynamic-group="coletas"
                  paramName="clienteId"
                  [data]="filtersList.coletas.clientes"
                ></default-autocomplete-input>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Motorista/Catador</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.motorista"
                  dg-dynamic-group="coletas"
                  paramName="motoristaId"
                  [data]="filtersList.coletas.motorista"
                ></default-autocomplete-input>
              </div>
            </div>
          </filter-search2>
        </div>
        <div class="card-body" style="padding: 0">
          <dg-table dg-dynamic-table [dynamicTable]="tableColetas" classes="default-table"></dg-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableColetas"></dg-paginator>
        </div>
      </card>
    </div>
  </div>

  <div
    *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])"
    class="row mt-3"
    [hidden]="tableVisible != 'coletas-pendentes'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Coletas Pendentes</h4>
        </div>

        <div class="card-subtitle">
          <filter-search2 dynamicGroupName="coletas-pendentes">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data inicial</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas-pendentes"
                  paramName="dataInicial"
                  [formControl]="filters.coletas.dataInicial"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data final</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas-pendentes"
                  paramName="dataFinal"
                  [formControl]="filters.coletas.dataFinal"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])">
                <label class="form-label">Cliente</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.cliente"
                  dg-dynamic-group="coletas-pendentes"
                  paramName="clienteId"
                  [data]="filtersList.coletas.clientes"
                ></default-autocomplete-input>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Motorista/Catador</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.motorista"
                  dg-dynamic-group="coletas-pendentes"
                  paramName="motoristaId"
                  [data]="filtersList.coletas.motorista"
                ></default-autocomplete-input>
              </div>
            </div>
          </filter-search2>
        </div>
        <div class="card-body" style="padding: 0">
          <dg-table dg-dynamic-table [dynamicTable]="tableColetasPendentes" classes="default-table"></dg-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableColetasPendentes"></dg-paginator>
        </div>
      </card>
    </div>
  </div>

  <div
    *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])"
    class="row mt-3"
    [hidden]="tableVisible != 'coletas-canceladas'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Coletas Canceladas</h4>
        </div>

        <div class="card-subtitle">
          <filter-search2 dynamicGroupName="coletas-canceladas">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data inicial</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas-canceladas"
                  paramName="dataInicial"
                  [formControl]="filters.coletas.dataInicial"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Data final</label>
                <input
                  class="form-control"
                  type="date"
                  dg-dynamic-group="coletas-canceladas"
                  paramName="dataFinal"
                  [formControl]="filters.coletas.dataFinal"
                >
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])">
                <label class="form-label">Cliente</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.cliente"
                  dg-dynamic-group="coletas-canceladas"
                  paramName="clienteId"
                  [data]="filtersList.coletas.clientes"
                ></default-autocomplete-input>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Motorista/Catador</label>
                <default-autocomplete-input
                  label="nome" key="id"
                  [formControl]="filters.coletas.motorista"
                  dg-dynamic-group="coletas-canceladas"
                  paramName="motoristaId"
                  [data]="filtersList.coletas.motorista"
                ></default-autocomplete-input>
              </div>
            </div>
          </filter-search2>
        </div>
        <div class="card-body" style="padding: 0">
          <dg-table dg-dynamic-table [dynamicTable]="tableColetasCanceladas" classes="default-table"></dg-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableColetasCanceladas"></dg-paginator>
        </div>
      </card>
    </div>
  </div>

  <div
    *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])"
    class="row mt-3"
    [hidden]="tableVisible != 'mtr'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">MTR</h4>
        </div>
        <div class="card-subtitle">
        </div>
        <div class="card-body" style="padding: 0">
          <dg-table dg-dynamic-table [dynamicTable]="tableMTR"></dg-table>
        </div>
        <div class="d-flex justify-content-center card-footer">

        </div>
      </card>
    </div>
  </div>


  <!--
  TODO
          ESTOU DEIXANDO O CODIGO ANTIGO COMENTADO APENAS PARA CONFERENCIA,
          REMOVER APÓS A VALIDAÇÃO
  -->

  <!--
    <div class="row mt-5">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 *ngIf="session.checkPerfil(['EMPRESA', 'CONDOMINIO', 'CONDOMINIO', 'EVENTO'])" class="card-title" [class.active]="tableVisible == 'coletas'">Coletas</h4>
          </div>

          <div [class.d-none]="tableVisible != 'coletas'">
            <div class="card-subtitle">
              <filter-search [entityMap]="mapEntity" [table]="crudTable" tableEventId="ColetasEntity"></filter-search>
            </div>
            <div class="card-body" style="padding: 0">
              <crud-data-table
                #crudTable
                tableEventId="ColetasEntity"
                [entityMap]="mapEntity"
                [service]="service"
                [showDefaultAction]="'editar'"
                [showPagination]="false"
                [customActions]="customAction"
                [register]="true"
              ></crud-data-table>
            </div>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="mapEntity.configEntityTable.pagination"
                (changePage)="crudTable.changePages($event)"
              ></dg-pagination>
            </div>
          </div>

          <div
            hidden
            *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
            [class.d-none]="tableVisible != 'coletas-pendentes'"
          >
            <div class="card-subtitle">

            </div>
            <div class="card-body" style="padding: 0">
              <crud-data-table
                #crudTablePentendes
                tableEventId="coletasPendentes"
                [entityMap]="mapEntityPendentes"
                [register]="true"
              ></crud-data-table>
            </div>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="mapEntityPendentes.configEntityTable.pagination"
                (changePage)="crudTablePentendes.changePages($event)"
              ></dg-pagination>
            </div>
          </div>

          <div hidden [class.d-none]="tableVisible != 'coletas-canceladas'">
            <div class="card-subtitle">

            </div>
            <div class="card-body" style="padding: 0">
              <crud-data-table
                #crudTableCanceladas
                tableEventId="coletasCanceladas"
                [entityMap]="mapEntityCanceladas"
              ></crud-data-table>
            </div>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="mapEntityCanceladas.configEntityTable.pagination"
                (changePage)="crudTableCanceladas.changePages($event)"
              ></dg-pagination>
            </div>
          </div>

          <div hidden [class.d-none]="tableVisible != 'mtr'">
            <div class="card-subtitle">

            </div>
            <div class="card-body" style="padding: 0">
              <dg-table dg-dynamic-table [dynamicTable]="tableMTR"></dg-table>
            </div>
            <div class="d-flex justify-content-center card-footer">

            </div>
          </div>
        </card>
      </div>
    </div>
  -->

</div>


<page-footer *ngIf="!!session.user.cliente_id">
  <button class="btn btn-success" (click)="modalPlanejamento.open()">Plano de
    Coleta</button>
  <button class="btn btn-success" (click)="modalColetaPontual.open()">Coleta
    Pontual</button>
</page-footer>

<modal-coleta-pontual #modalColetaPontual></modal-coleta-pontual>

<modal-plano-coleta #modalPlanejamento></modal-plano-coleta>
