<div id="dashboard-coleta" style="max-width: 100%">

  <div class="container-data">
    <div class="row">
      <div
        *ngFor="let grapg of listGraph; let i = index"
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <app-card-graph [id]="criarId(i)" [dataGraphic]="grapg">
        </app-card-graph>

      </div>
    </div>
  </div>

  <div class="container-data">
    <card>
      <div class="card-body">
        <crud-data-table
          #table
          [service]="service"
          [entityMap]="entity"
          [persistentParams]="getParamsFind()"
          [showDefaultAction]="false"
          [endpoint]="'datatable'"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="entity.configEntityTable.pagination"
          (changePage)="table.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>

</div>
