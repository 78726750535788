import {ClientesEntity} from "../_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../_galpao/galpao/galpao.entity";
import {ArquivoEntity} from "../../../shared/entitys/arquivo.entity";
import {PropertiesWhiteLabel} from "./properties-white-label.entity";
import {ParceiroEntity} from "../parceiro/parceiro.entity";


export class WhiteLabelEntity {
  constructor(
    id?: number | string,
    cliente?: ClientesEntity,
    galpao?: GalpaoEntity,
    properties?: PropertiesWhiteLabel,
    arquivo?: ArquivoEntity,
    parceiro?: ParceiroEntity,
  ) {
    this.id = id;
    this.cliente = cliente;
    this.galpao = galpao;
    this.parceiro = parceiro;
    this.arquivo = arquivo;
    this.properties = !properties ? new PropertiesWhiteLabel() : properties;
  }

  id: number | string | undefined
  cliente: ClientesEntity | undefined;
  galpao: GalpaoEntity | undefined;
  parceiro: ParceiroEntity | undefined;
  properties: PropertiesWhiteLabel;
  arquivo: ArquivoEntity | undefined;
}
