import { Injectable } from '@angular/core';
import {ArquivoEntity} from "../../shared/entitys/arquivo.entity";

@Injectable({
  providedIn: 'root'
})
export class FilesManageService {

  constructor() { }

  base64ToFile(base64Src: string, tipoArquivo: string){
    const byteArray = new Uint8Array(atob(base64Src).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: tipoArquivo});
  }

  downloadBase64(file: string, nameFile: string = 'arquivo.pdf') {
    let blob = this.base64ToFile(file, '.pdf');
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = nameFile;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  getImageToSrc(imagem?: ArquivoEntity | undefined):string{
    if (!!imagem) {
      if (!!imagem?.file) {
        return 'data:image/'+imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1]+';base64,'+ imagem.file
      }
    }
    return '/assets/img/default.jpg'
  }
}
