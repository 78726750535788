import { Component, OnInit } from '@angular/core';
import { CadastroIndicadoresOdsEntity } from "../cadastro-indicadores-ods.entity";

@Component({
  selector: 'app-cadastro-indicadores-ods-main',
  templateUrl: './cadastro-indicadores-ods-main.component.html',
  styleUrls: ['./cadastro-indicadores-ods-main.component.scss']
})
export class CadastroIndicadoresOdsMainComponent implements OnInit {

  public mapEntity = new CadastroIndicadoresOdsEntity()

  constructor() { }

  ngOnInit(): void {
  }

}
