import { ClientesEntity } from '../_clientes/clientes/clientes.entity';
import { DgTableColumn } from "@datagrupo/dg-crud";
export class VendasLogisticaClientesEntity extends ClientesEntity {

  constructor(
    id?: string | number,
    nomeCliente?:string,
    quantidadeVendida?: string,
    idPagamento?: string,
    clienteCreditoLogisticaReversa?: ClientesEntity,
    dataCadastro?: string,
    quantidadeDisponivel?: string,
  ) {
    super();
    this.id = id;
    this.nomeCliente = nomeCliente;
    this.quantidadeVendida = quantidadeVendida;
    this.idPagamento =  idPagamento;
    this.clienteCreditoLogisticaReversa = clienteCreditoLogisticaReversa;
    this.quantidadeDisponivel = quantidadeDisponivel;
    // this.dataCadastro = dataCadastro;
    }

  public nomeCliente: string | undefined;
  // public quantidadeComp: string | undefined;
  public idPagamento: string | undefined;
  public clienteCreditoLogisticaReversa: ClientesEntity | undefined;
  public quantidadeVendida: string |  number |undefined;
  public identificadorPagamento: string | any;
  public quantidadeDisponivel: string | undefined

 }








