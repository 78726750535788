<div id="user-home-page">

  <div class="container-data">
    <div class="col-12">
      <card>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
<!--              <card-item [disable]="!issetCliente" icon="emoji_objects" text="Programa de Logística Reversa"-->
<!--                href="jornada-educacao-ambiental" size="lg">-->
<!--              </card-item>-->
              <a href="https://app.pipefy.com/portals/minhacoletaajuda" target="_blank" style="text-decoration: none">
                <div
                  class="card dg-card-item"
                  style="width: 100%; height: 100%"
                >
                  <mat-icon>quiz</mat-icon>
                  <p class="text">Ajuda</p>
                </div>
                <!--              <card-item icon="emoji_objects" text="Ajuda"-->
                <!--                href="https://app.pipefy.com/portals/minhacoletaajuda" size="lg">-->
                <!--              </card-item>-->
              </a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <card-item icon="signal_cellular_alt" text="Dashboard" href="/user/dashboard2"
                fragment="metrica-impacto" size="lg">
              </card-item>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <card-item icon="history_toggle_off" text="Histórico da Coleta" href="operacional/coletas/coletas"
                         size="lg">
              </card-item>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <card-item
                icon="emoji_events" text=" Desafio de Logística Reversa"
                href="programa-logistica-reversa"  size="lg"
                [disable]="session.checkProfile('USUARIO_CONSULTA')"
              >
              </card-item>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <a [attr.href]="session.checkProfile('USUARIO_CONSULTA') ? null : 'https://app.pipefy.com/public/form/kO2AARXs'" target="_blank" style="text-decoration: none">
                <card-item
                  icon="local_shipping" text="Chamado para Transportador" href="/user/"
                  fragment="metrica-impacto" size="lg"
                  [disable]="session.checkProfile('USUARIO_CONSULTA')"
                >
                </card-item>
              </a>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <card-item
                icon="star"
                text="Conscientize as pessoas"
                style="cursor: pointer"
                (click)="session.checkProfile('USUARIO_CONSULTA') ? null :openNewsletter()" size="lg"
                [disable]="session.checkProfile('USUARIO_CONSULTA')"
              >
              </card-item>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>

<!--  <div class="container-data">-->
<!--    <div class="col-12">-->
<!--      <subComponent-card-promo1></subComponent-card-promo1>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="container-data">-->
<!--    <div class="row">-->

<!--      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">-->
<!--        <subComponent-card-promo2></subComponent-card-promo2>-->
<!--      </div>-->

<!--      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">-->
<!--        <subComponent-card-promo-lix></subComponent-card-promo-lix>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->

  <div *ngIf="!session.checkProfile('USUARIO_CONSULTA')" class="container-data">
    <hr style="color: rgba(106,111,119,0.54); margin: 2rem auto">
  </div>

  <blog-main *ngIf="!session.checkProfile('USUARIO_CONSULTA')"></blog-main>


</div>
