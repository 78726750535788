<div id="tipo-lixo-insert-edit">

  <div class="container-data">
    <card>
      <div class="card-header">
        <h4 class="card-title">Cadastro de Unidade de Medida</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Nome</label>
              <input type="text" formControlName="nome" class="form-control">
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Categoria da Unidade de Medida</label>
              <select name="categoriaUnidadeMedida" id="categoriaUnidadeMedida" class="form-select"
                formControlName="categoriaUnidadeMedida" (change)="validaCampos()">
                <option value="QUANTIDADE">Quantidade</option>
                <option value="PESO">Peso</option>
                <option value="PORCENTAGEM">Porcentagem</option>
                <option value="VOLUME">Volume</option>
              </select>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Fator de conversão</label>
              <div class="input-group mb-3">
                <input formControlName="fatorConversao" class="form-control">
                <div class="input-group-append">
                  <span class="input-group-text">{{form.value.categoriaUnidadeMedida == 'PESO'? 'TON' : 'M³' }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </card>
  </div>

  <div class="container-data" *ngIf="form.value.categoriaUnidadeMedida == 'PORCENTAGEM'">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <h4 class="card-title">Faixas</h4>
            </div>
            <div class="col-6 d-inline-flex justify-content-end">
              <button class="btn btn-success btn-sm" (click)="addFaixa()">Adicionar Faixa</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="entity?.faixas">
        <div class="row" *ngFor="let faixa of entity.faixas || []; let i = index">
          <div class="col-12">
            <label class="form-label">Valor</label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" [(ngModel)]="faixa.valor">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2" (click)="removerFaixa(i, faixa)">
                  <mat-icon>close</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</div>

<page-footer>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
</page-footer>
