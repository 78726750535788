import { CobrancasEntity } from '../../cobrancas.entity';
import {Injectable, OnInit} from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CobrancasService extends AbstractHttpService<CobrancasEntity>{
  constructor(
    public config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, 'cobrancas');
    this.dictionary.findAll = {
      cliente: 'nomeCliente'
    }
  }

  pagar(status: string, id: number | string) {
    return this.http.patch(this.path+`/updatestatus/${status}/${id}`, {})
  }

  enviarCobranca(id: number | string) {
    return this.http.post(this.path+`/enviacobranca/${id}`, {})
  }

  updateVencimento(data: object) {
    return this.http.patch(this.path+'/updatevencimento', data)
  }
}
