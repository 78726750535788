<old-dg-modal
  id="modal-plano-coletas"
  #modal [size]="listContainers.length > 0 ? 'xl' : 'md'"
  title="Plano de coletas"
  [afterClose]="afterClose"
>
  <div dg-content style="overflow-x: visible">
    <div class="card-body">
      <form [formGroup]="form">
        <div class="row">
          <div [class]="listContainers.length > 0 ? 'col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12' : 'col-6'">
            <label class="form-label">Selecione o mês</label>
            <input
              id="inputDate"
              formControlName="mes"
              class="form-control"
              type="text"
              mask="00/0000"
              placeholder="mês/ano"
              [validation]="true"
            >
          </div>
          <div [class]="listContainers.length > 0 ? 'col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12' : 'col-6'">
            <label class="form-label">Agrupar MTR</label>
            <select class="form-select" formControlName="grouped">
              <option [value]="true">agrupado</option>
              <option [value]="false">individual</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="listContainers.length > 0">
          <div class="col-12">
            <p
              class="text-muted"
              style="font-size: 9pt"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Para poder alterar os dados de 'responsável' e 'cargo' basta ativar o campo de 'Dados temporários' na relação de contrato"
            >
              Deseja selecionar os dados de responsavel ou cargo?
            </p>
          </div>
        </div>
        <div class="row" *ngIf="listContainers.length > 0">
          <div class="col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label class="form-label">Responsável</label>
            <input class="form-control" formControlName="responsavelMtr">
          </div>
          <div class="col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label class="form-label">Cargo</label>
            <input class="form-control" formControlName="cargoMtr">
          </div>
        </div>
      </form>
    </div>

    <div class="card-body">
      <div class="col-12" *ngIf="listContainers.length > 0">
        <div>
          <label class="form-label">Selecione um recipiente</label>
          <table class="table" style="min-width: 2000px">
            <thead>
            <tr>
              <th>Data</th>
              <th>Identificação</th>
              <th>Transportador</th>
              <th>Tipo do Resíduo</th>
              <th>Recipiente</th>
              <th>Tratamento</th>
              <th style="width: 150px">
                MTR
                <input type="checkbox" [checked]="verifyCheckedAllMtr()" (click)="markAllMtr()">
              </th>
              <th
                data-bs-toggle="tooltip" data-bs-placement="top"
                title="Apenas os valorez iguais a '0' ou vazios serão alterados"
              >
                Peso MTR
                <input type="number" min="0" [(ngModel)]="pesoMtr" (change)="setPesoMtr()" class="form-control" placeholder="adicionar peso">
              </th>
              <th>Destinador</th>
              <th>Armazenador temporário</th>
              <th>Unidade de Medida Padrão</th>
              <th>Obs</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let container of listContainers; let i = index">
              <tr>
                <td>
                  <input
                    type="date"
                    class="form-control"
                    [max]="getLastDayMonth()"
                    [min]="mesReferencia+'-01'"
                    [formControl]="container.dataDaColeta"
                  >
                </td>
                <td>
                  <label>
                    <input type="checkbox" [(ngModel)]="container.selected">
                    {{container.identificacao}}
                  </label>
                </td>
                <td>
                  {{container.galpao?.nome}}
                </td>
                <td>
                  {{container.tipoLixoContainer?.nome}}
                </td>
                <td>
                  {{container.container?.nome}}
                </td>
                <td>
                  {{container.tratamento?.nome}}
                </td>
                <td>
                  <input
                    #input
                    type="checkbox"
                    [(ngModel)]="container.hasMtr"
                  >
                </td>
                <td style="width: 150px">
                  <input
                    type="text"
                    min="0"
                    class="form-control"
                    [(ngModel)]="container.pesoMtr"
                    [disabled]="!container.hasMtr"
                    (keyup)="clearPesoMtr(i)"
                  >
                </td>
                <td>{{container?.industria?.nome || '--'}}</td>
                <td>{{container?.armazenadorTemporario?.nome || '--'}}</td>
                <td>
                  {{container.unidadeMedidaPadrao?.nome}}
                </td>
                <td>
                  <button class="btn btn-success btn-sm" (click)="container['openObs']= !container?.openObs">+</button>
                </td>
              </tr>
              <tr *ngIf="!!container?.openObs">
                <td colspan="100%">
                  <label class="form-label">Observações</label>
                  <textarea [(ngModel)]="container['observacoesMtr']" class="form-control"></textarea>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1">Enviar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
