import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {PlanoClienteEntity} from "../plano-cliente.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PlanosClientesService extends AbstractHttpService<PlanoClienteEntity> {

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'cliente-plano/')
  }
}
