import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelecionarPlanoComponent } from './selecionar-plano/selecionar-plano.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';



@NgModule({
  declarations: [
    SelecionarPlanoComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class SelecionarPlanoModule { }
