import { AbstractEntity, DgTableColumn } from "@datagrupo/dg-crud";
import { ProdutoEntity } from "../../produto/produto.entity";


export class NotaFiscalProdutosEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    produto?: ProdutoEntity,
    bloqueada?: boolean,
    quantidade?: string,
    precoPorTonelada?: number
  ) {
    super();
    this.produto = produto;
    this.bloqueada = !!bloqueada;
    this.quantidade = quantidade;
    this.precoPorTonelada = precoPorTonelada;
  }

  @DgTableColumn({
    columnName: 'Nome', resource: (val: ProdutoEntity) => {
      return !!val.nome ? val.nome : '--';
    }
  })
  public produto: ProdutoEntity | undefined;

  public bloqueada: boolean | undefined;

  @DgTableColumn({
    columnName: 'Toneladas', resource: (val: string) => {
      return !!val ? Number(val).toLocaleString('pt-BR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      }) : '--';
    }
  })
  public quantidade: string | undefined;

  @DgTableColumn({
    columnName: 'Preço por Tonelada (TON)', resource: (val: string) => {
      return !!val ? Number(val).toLocaleString('pt-BR') : '--';
    }
  })
  public precoPorTonelada: number | string | undefined
}
