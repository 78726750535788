
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { LogisticaDocumentoEntity } from '../../logistica-documento.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";

@Injectable({
  providedIn: 'root'
})
export class ClienteCreditoLogisticaReversaDocumentoService extends AbstractHttpService<LogisticaDocumentoEntity> {

  constructor(public config: ConfigDgCrudService, private files: FilesManageService) {
    super(config, environment.apiUrl,'cliente-credito-logistica-reversa/arquivos')
  }

  downloadFile(id: number | string) {
    this.findOne(id, 'download').subscribe(
      resp => {
        console.log(resp)
        // @ts-ignore
        if (resp.data.file) {
          // @ts-ignore
          this.files.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
        }
      }
    )
  }
}
