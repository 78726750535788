import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { LoginGuard } from '../../core/guards/guard-login/login.guard';
import { MissoesCupomComponent } from '../_user/missoes/missoes-cupom/missoes-cupom.component';
import { SolicitacaoCadastroInsertComponent } from './solicitacao-cadastro/solicitacao-cadastro-insert/solicitacao-cadastro-insert.component';
import { AvaliacaoColetaComponent } from './avaliacao-coleta/avaliacao-coleta/avaliacao-coleta.component';
import { PublicDashboardMainComponent } from './public-dashboard/public-dashboard-main/public-dashboard-main.component';
import { TutorialMainComponent } from '../_user/tutorial/tutorial-main/tutorial-main.component';
import { FaqMainComponent } from '../_user/faq-perguntas-frequentes/faq-main/faq-main.component';
import { PublicCreditoLogisticaReversaComponent } from './public-credito-logistica-reversa/public-credito-logistica-reversa/public-credito-logistica-reversa.component';
import {EsqueciSenhaComponent} from "./esqueci-senha/esqueci-senha/esqueci-senha.component";
import {RenovarSenhaComponent} from "./esqueci-senha/renovar-senha/renovar-senha.component";
import {CadastroMoradorComponent} from "./cadastro-morador/cadastro-morador/cadastro-morador.component";
import {
  CertificadoCreditoLogisticaReversaComponent
} from "./certificado-credito-logistica-reversa/certificado-credito-logistica-reversa.component";
import {
  PublicDashboard2ColetasComponent
} from "./public-dashboard2/public-dashboard2-coletas/public-dashboard2-coletas.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },

  {
    path: 'tutorial',
    component: TutorialMainComponent,
  },

  {
    path: 'esqueci-senha',
    component: EsqueciSenhaComponent,
  },
  {
    path: 'auth/renovar_senha/:token',
    component: RenovarSenhaComponent,
  },

  {
    path: 'faq',
    component: FaqMainComponent,
  },

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'cupom',
    children: [{ path: ':id', component: MissoesCupomComponent }],
  },
  {
    path: 'solicitacao-cadastro',
    component: SolicitacaoCadastroInsertComponent,
  },
  {
    path: 'solicitacao-cadastro',
    children: [{ path: ':id', component: SolicitacaoCadastroInsertComponent }],
  },
  {
    path: 'cadastro-usuario',
    children: [{ path: ':id', component: CadastroMoradorComponent }],
  },
  {
    path: 'compre-credito-logistica-reversa',
    component: PublicCreditoLogisticaReversaComponent,
  },
  {
    path: 'avaliacao-da-coleta',
    children: [{ path: '', component: AvaliacaoColetaComponent }],
  },
  {
    path: 'certificado-credito-logistica-reversa/:id',
    component: CertificadoCreditoLogisticaReversaComponent
  },
  {
    path: 'dashboard/:id',
    component: PublicDashboardMainComponent,
  },
  {
    path: 'dashboard2-coletas/:id',
    component: PublicDashboard2ColetasComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
