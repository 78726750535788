<layout-side-filter idOpen="dashboard-estoque" (clearFilter)="clear()">

  <div
    class="row"
    *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])"
  >
    <div class="col-12">
      <label class="form-label">Favoritos</label>
      <input-autocomplete
        key="id"
        label="nome"
        placeholder="Selecione um favorito"
        [(ngModel)]="_dataFilter.favoritos"
        [list]="(listItensDisponiveis.favoritos || [])"
        [preSelectedList]="listsPreSelected.favoritos"
        (close)="setFavoritos($event)"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row" *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])">
    <div class="col-12">
      <label class="form-label">Operadores Logísticos</label>
      <input-autocomplete
        #autoGalpao
        key="id"
        label="nome"
        placeholder="Selecione um operadores logísticos"
        [disabled]="session.checkPerfil('GALPAO')"
        [multiple]="true"
        [showSelectedList]="true"
        [(ngModel)]="_dataFilter.galpao"
        [list]="(listItensDisponiveis.galpao || [])"
        [preSelectedList]="listsPreSelected.galpao"
        (close)="setAutoComplete($event, 'galpao')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Início</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'dataInicio')"
        type="date"
        [(ngModel)]="_dataFilter.dataInicio"
        (change)="verifyChangeData('dataInicio')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Fim</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'dataFim')"
        type="date"
        [(ngModel)]="_dataFilter.dataFim"
        (ngModelChange)="verifyChangeData('dataFim')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Tipos de Resíduo</label>
      <input-autocomplete
        #autoResiduo
        key="id"
        label="nome"
        placeholder="Selecione um tipos de resíduo"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.tipoResiduo"
        [list]="(listItensDisponiveis.tipoResiduo || [])"
        [preSelectedList]="listsPreSelected.tipoResiduo"
        (close)="setAutoComplete($event, 'tipoResiduo')"
        classes="form-control"
      >
      <ng-template #row_option let-option ><input class="checkBox" type="checkbox" [checked]="option.selected">{{option.row.nome}}</ng-template>
    </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Estados</label>
      <input-autocomplete
        #autoEstados
        key="uf"
        label="name"
        placeholder="Selecion um estado"
        positionX="end"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.estados"
        [list]="(listItensDisponiveis.estados || [])"
        [preSelectedList]="listsPreSelected.estados"
        (close)="setAutoComplete($event, 'estados')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

</layout-side-filter>
