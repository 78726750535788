<old-dg-modal
  #modal
  size="md"
  title="Dados do Arquivo"
  [beforeClose]="close"
>
  <div dg-content class="modal-galpao-documentos">
    <div class="row">
      <div *ngIf="!!docName" class="col-12">
        <label class="form-label">Nome do arquivo</label>
        <table class="crud-table">
          <tr>
            <td> {{docName}} </td>
          </tr>
        </table>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-btn">
        <button [disabled]="!!id" (click)="uploadDocumento_click()" class="btn btn-success w-100">Escolher</button>
        <input
          #docInput
          type="file"
          name="imgMotorista"
          id="imgMotorista"
          accept=".pdf"
          style="display: none;"
          [(ngModel)]="docFile"
          (change)="uploadDocumento($event)"
        >
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="categoria" class="form-label">Categoria <span class="fc-red">*</span></label>
        <select [formControl]="categoriaArquivo" class="form-select" id="categoria">
          <option value="" disabled>Selecione uma categoria</option>
          <option *ngFor="let categ of categoriasList" value="{{categ.id}}">{{categ.nome}}</option>
        </select>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1">Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
