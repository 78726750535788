import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import { PerguntasEntity } from '../perguntas.entity';
import { ClientesPerguntasService } from '../service/clientes-perguntas.service';

@Component({
  selector: 'subComponent-pergunta-casa',
  templateUrl: './pergunta-casa.component.html',
  styleUrls: ['./pergunta-casa.component.scss']
})
export class PerguntaCasaComponent implements OnInit {

  listDias: number[] = [];

  optAssinaturas: { key: string, value: string }[] = [
    { key: 'MODULO_GERENCIAL', value: 'Módulo gerencial' },
    { key: 'MODULO_GESTAO_COMUNICACAO', value: 'Módulo gestão e comunicação de operadores' },
    { key: 'MODULO_COTACAO', value: 'Módulo de cotação' }
  ]


  @Input('clienteId') clienteId!: number | string | undefined;

  entity!: PerguntasEntity;
  entityId!: any;

  public formPerfilInicial = new FormGroup({
    responsavelFinanceiro: new FormControl('', Validators.required),
    qtdMoradores: new FormControl('', Validators.required),
    razaoSocial: new FormControl('', Validators.required),
    horarioColeta: new FormControl('', Validators.required)
  })

  public formPlanosPrecos = new FormGroup({
    planoColetaDomiciliar: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.required),
    rg: new FormControl('', Validators.required),
    nomeResponsavel: new FormControl('', Validators.required),
    emailResponsavel: new FormControl('', [Validators.required, Validators.email]),
    dataLimite: new FormControl('', Validators.required)
  })

  public formAssinatura = new FormGroup({
    assinatura: new FormControl('', Validators.required)
  })

  constructor(
    public servic: ClientesPerguntasService,
    public conf: ConfigDgCrudService,
    public session: SessionService,
    public route: ActivatedRoute
  ) {
  }

  initNewEntity(): void {
    this.entity = new PerguntasEntity()
  }

  ngOnInit(): void {
    this.getRouteId();
    if (this.entityId != 'novo') {
      this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
        this.entity = resp.data;
        if (this.entity != undefined) {
          this.afterFetchEntity();
        } else {
          this.initNewEntity()
        }
      })
    }
    this.getDias()
  }

  getRouteId() {
    this.route.paramMap.subscribe(params => {
      if(!params.get('novo')){
        this.entityId = params.get('id')
      }
      this.entityId = this.clienteId
    })
  }

  getDias() {
    for (let cont = 1; cont <= 31; cont++) {
      this.listDias.push(cont)
    }
  }

  afterFetchEntity(): void {
    this.formPerfilInicial.patchValue({
      ...this.entity
    })
    this.formPlanosPrecos.patchValue({
      ...this.entity
    })
    this.formAssinatura.controls['assinatura'].setValue(this.entity.assinatura);
  }

  proximoStepper(stepp: MatStep, stepper: MatStepper) {
    const index = stepper.selectedIndex;
    if (stepp.stepControl.status === 'VALID') {
      stepper.next();
    } else {
      if (index == 0) {
        this.formPerfilInicial.markAllAsTouched();
        return
      }
      if (index == 1) {
        this.formPlanosPrecos.markAllAsTouched();
        return
      }
      if (index == 2) {
        this.formAssinatura.markAllAsTouched();
      }
    }
  }

  salvarPerguntas() {
    const formPerfilIncialV = this.formPerfilInicial.value;
    const formPlanoPrecoV = this.formPlanosPrecos.value;

    if (this.formPerfilInicial.invalid || this.formPlanosPrecos.invalid || this.formAssinatura.invalid) {
      this.formPerfilInicial.markAllAsTouched();
      this.formPlanosPrecos.markAllAsTouched();
      this.formAssinatura.markAllAsTouched();
      return;
    }

    this.entity = {
      ...this.entity,
      ...formPerfilIncialV,
      ...formPlanoPrecoV,
      assinatura: this.formAssinatura.value.assinatura,
      cliente: { id: this.entityId }
    }

    this.servic.saveOrUpdate(this.entity).subscribe(resp => {
      if (resp) {
        this.returnSaveEntity();
      }
      if (this.entity.id) {
        Swal.fire({
          title: 'Dados Atualizado',
          text: 'Informações atualizadas com sucesso',
          icon: 'success',
          timer: 3000
        })
      } else {
        Swal.fire({
          title: 'Registro Enviado',
          text: 'Obrigado por finalizar seu cadastro',
          icon: 'success',
          timer: 3000
        })
      }
      this.session.hasRespostas = true
    })

  }

  returnSaveEntity() {
    this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
      this.entity = resp.data
    })
  }

}
