<div id="relatorio-missoes-main" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Ranking dos Geradores</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntity"></filter-search>
        </div>

        <div class="card-body" style="padding: 0">
          <crud-data-table

            #table
            [entityMap]="mapEntity"
            [service]="service"
            [showPagination]="false"
            [showDefaultAction]="false"
            [customDblclick]="true"
            sort="nomeCliente"
          ></crud-data-table>
        </div>
        <div class="card-footer">
          <dg-pagination
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="table.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <button crud-find-filters class="btn btn-success">Pesquisar</button>
  <button crud-table-clear class="btn btn-secondary">Limpar</button>
  <button (click)="generate_pdf_target()" class="btn btn-success m-1">Imprimir</button>
  <button (click)="downloadExcel()" class="btn btn-success m-1">Excel</button>
</page-footer>
