import { Component, OnInit } from '@angular/core';
import { ParceiroEntity } from '../parceiro.entity';
import { ParceiroService } from '../service/parceiro.service';

@Component({
  selector: 'app-parceiro-main',
  templateUrl: './parceiro-main.component.html',
  styleUrls: ['./parceiro-main.component.scss']
})
export class ParceiroMainComponent implements OnInit {

public mapEntity = new ParceiroEntity();

  constructor(
    public service: ParceiroService
  ) { }

  ngOnInit(): void {
  }

}
