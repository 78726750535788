<div
  class="input-group autocomplete3-default"
  [class.ng-touched]="_ngControl['touched']"
  [class.ng-invalid]="_ngControl['invalid']"
>
  <input
    type="text"
    class="autocomplete3 input form-control {{classes}}"
    [style.z-index]="opened ? 3001 : null"
    [id]="inputId"
    [formControl]="searchFilter"
    [class.ng-valid]="!_ngControl['invalid']"
    (click)="controls.open()"
  >
  <span class="input-group-text">
    <span class="rounded-circle" *ngIf="!loaderData">
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
    <span class="loader" *ngIf="!!loaderData">
      <mat-spinner [diameter]="25"></mat-spinner>
    </span>
  </span>
</div>


<div class="autocomplete3 autocomplete3-default root-popper" [id]="inputId+'-root-popper'">
  <div class="card-popper">
    <div class="card-popper-body" [id]="inputId+'-list'">
      <!--
      #################################################################
      ##### LISTA DE ITENS SELECIONADOS
      #################################################################
      -->
      <div class="root-list selected-list" *ngIf="_controls.verify.showSelectedList() && selectedList.length > 0">
        <div class="header-list">
          <span class="title-list">Item selecionado</span>
        </div>

        <div class="content-list" *ngIf="selectedList.length > 0">
          <ul class="list">
            <!-- #### ITENS SELECIONADOS DA LISTA -->
            <li
              *ngFor="let item of selectedList; let i = index"
              (click)="$event.stopPropagation(); _controls.events.unSelectOption(i)"
              class="list-option selected"
            >
              <!-- #### ITEM PADRÃO DA LISTA -->
              <div>
                <input type="checkbox" *ngIf="(!!multiple || !!showCheckInputOption)" checked>
                {{_controls.item.getLabel(item)}}
              </div>
            </li>
          </ul>
        </div>
        <hr>
      </div>


      <!--
      #################################################################
      ##### LISTA DE ITENS DISPONÍVEIS
      #################################################################
      -->
      <div class="root-list">
        <div class="header-list">
          <span class="title-list">Selecionar</span>
        </div>

        <div class="content-list">
          <ul class="list">
            <!-- #### ITENS DA LISTA -->
            <li *ngIf="controls.getListFiltered().length == 0" class="list-option">
              Nenhum registro encontrado
            </li>
            <li
              *ngFor="let item of controls.getListFiltered() let i = index"
              class="list-option"
              [class.selected]="_controls.verify.selectedItem(i)"
              (click)="_controls.events.selectOption(item, i)"
              [hidden]="_controls.verify.ocultarItemSelecionado() && _controls.verify.selectedItem(i)"
            >
              <!-- #### ITEM PADRÃO DA LISTA -->
              <div>
                <input
                  [type]="typeCheckInputOption"
                  *ngIf="(!!multiple || !!showCheckInputOption)"
                  [checked]="_controls.verify.selectedItem(i)"
                >
                {{_controls.item.getLabel(item)}}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
