<div id="indicadores-insert-edit">
    <form [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <card>
                        <div class="card-header">
                            <h4 class="card-title">Indicadores Regionais</h4>
                            <hr>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label class="form-label">
                                        ESTADO
                                        <span class="fc-red">*</span>
                                    </label>
                                    <select
                                        formControlName="uf"
                                        class="form-select"
                                    >
                                        <option
                                            value
                                            selected
                                            disabled
                                        >
                                            Selecione um Estado
                                        </option>
                                        <option
                                            *ngFor="let uf of estados"
                                            [value]="uf.uf"
                                        >
                                            {{uf.name}}
                                        </option>
                                    </select>
                                </div>


                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label
                                        for="valorIndicador"
                                        class="form-label"
                                    >
                                        VALOR %
                                        <span class="fc-red">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        formControlName="valorIndicador"
                                        class="form-control"
                                        id="valorIndicador"
                                        min="0"
                                        max="100"
                                    >
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="anoReferencia"
                                        class="form-label"
                                    >
                                        ANO
                                    </label>
                                    <input
                                        type="number"
                                        formControlName="anoReferencia"
                                        class="form-control"
                                        id="anoReferencia"
                                        mask="0000"
                                    >
                                </div>


                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="linkReferencia"
                                        class="form-label"
                                    >
                                        LINK DE REFERÊNCIA
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="linkReferencia"
                                        class="form-control"
                                        id="linkReferencia"
                                    >
                                </div>


                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label
                                        for="reciclagemEstado"
                                        class="form-label"
                                    >
                                        RECICLAGEM DO ESTADO (ton)
                                    </label>
                                    <input
                                        formControlName="reciclagemEstado"
                                        class="form-control"
                                        id="reciclagemEstado"
                                    >
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </form>
</div>


<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
