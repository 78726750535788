import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CupomMainComponent } from './cupom-main/cupom-main.component';
import { CupomInsertEditComponent } from './cupom-insert-edit/cupom-insert-edit.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {NgxMaskModule} from "ngx-mask";
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [
    CupomMainComponent,
    CupomInsertEditComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        RouterModule,
        ReactiveFormsModule,
        AngularEditorModule,
        NgxMaskModule,
        DirectivesModule
    ]
})
export class CupomModule { }
