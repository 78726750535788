<div id="print-layout">
  <div class="page">
    <div class="subpage">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!--<div *ngIf="printService.showLoader" class="progress-content">-->
<!--  <div class="progress progress-bar-animated">-->
<!--    <div-->
<!--      class="progress-bar"-->
<!--      role="progressbar"-->
<!--      [style.width]="printService.progress()+'%'"-->
<!--      aria-valuemin="0"-->
<!--      aria-valuemax="100"-->
<!--    ></div>-->
<!--  </div>-->
<!--</div>-->

<div class="row-button-print">
  <button [disabled]="!enableBtnPrint" (click)="print()" class="button-print">
    <i class="fa-solid fa-print"></i>
  </button>
</div>
