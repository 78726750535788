<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">Autocomplete</h1>
</header>

<div class="container">

  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Atenção</h4>
        <p>
          Existem versões anteriores em depreciação no sistema. O componente correto a ser usado deve ser
          "<span class="alert-link">default-autocomplete-input</span>" ou uma nova implementação do dg-autocomplete3
        </p>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Sobre esse componente</h4>
        <p>
          Esse componente é uma extenção do componente <span class="alert-link">dg-autocomplete3</span> localizado em
          <span class="alert-link">dg-ng-util</span>
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert" role="alert">
        <h4 class="alert-heading">Início rápido</h4>
        <p>
          Importe o modulo <b>CustomAutocompleteModule</b> forneça um acesso ao ngControl, a lista de elementos e
          informe a label e a key, onde a label é o que deve aparecer na seleção e o key representa o valor selecionado.
        </p>
        <p>
          caso não informa a key todo o objeto será usado na seleção
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-4">
      <dg-autocomplete3 [data]="estadosBrasil"></dg-autocomplete3>
    </div>
    <div class="col-4">
      <default-autocomplete-input
        [data]="estadosBrasil"
        (searchFilterValue)="filterTest($event)"
        [(ngModel)]="teste"
      ></default-autocomplete-input>
    </div>
    <div class="col-4">
      <default-autocomplete-input
        [data]="estadosBrasil"
        [multiple]="true"
        (searchFilterValue)="filterTest($event)"
        [(ngModel)]="teste"
      ></default-autocomplete-input>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
<pre>
<textarea disabled style="width: 100%" rows="4">
<dg-autocomplete3
  [data]="estadosBrasil"
  [formControl]="formControl"
></dg-autocomplete3>
</textarea>
</pre>
    </div>
    <div class="col-4">
<pre>
<textarea disabled style="width: 100%" rows="4">
<dg-autocomplete3
  [data]="estadosBrasil"
  formControlName="controlName"
></dg-autocomplete3>
</textarea>
</pre>
    </div>
    <div class="col-4">
<pre>
<textarea disabled style="width: 100%" rows="4">
<dg-autocomplete3
  [data]="estadosBrasil"
  [(ngModel)]="input"
></dg-autocomplete3>
</textarea>
</pre>
    </div>
  </div>
</div>
