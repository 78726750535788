<div id="galpao-insert-edit">
  <form [formGroup]="form">
    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Dados do Transportador</h4>
              <hr>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    Nome
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="nome"
                    class="form-control"
                    id="nome"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    CNPJ
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cnpj"
                    mask="00.000.000/0000-00"
                    class="form-control"
                    id="cnpj"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    E-mail
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    id="email"
                  >
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    E-mail do transportador
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="email"
                    formControlName="emailDoOperadorLogistico"
                    class="form-control"
                    id="emailOperador"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="nome"
                    class="form-label"
                  >
                    Telefone
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefone"
                    placeholder="(00) 00000-0000"
                    mask="(00) 0000-0000||(00) 00000-0000"
                    formControlName="telefone"
                  >
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >
                  <label class="form-label">Master</label>
                  <input-autocomplete
                    #autocompleteParceiro
                    formControlName="parceiro"
                    key="id"
                    classes="form-control"
                    label="nome"
                    [disabled]="!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
                    (outValue)="setParceiro($event)"
                    [list]="listParceiro"
                  >
                    <ng-template #button_end>
                      <mat-icon (click)="autocompleteParceiro.clear()">close</mat-icon>
                    </ng-template>
                  </input-autocomplete>

                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <label
                    for="linkDashboard"
                    class="form-label"
                  >
                    Link master
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="linkDashboard"
                    formControlName="linkDashboard"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>


    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title">Endereço</h4>
                  </div>
                  <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <button
                      *ngIf="!session.checkPerfil('PARCEIRO')"
                      class="btn btn-success btn-sm"
                      (click)="addCepManual()"
                    >
                      Adicionar dados manualmente
                    </button>
                  </card-header-buttons>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="cep"
                    class="form-label"
                  >
                    CEP
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cep"
                    class="form-control"
                    id="cep"
                    placeholder="00.000-000"
                    mask="00.000-000"
                  >
                </div>


                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <label
                    for="logradouro"
                    class="form-label"
                  >
                    LOGRADOURO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="logradouro"
                    class="form-control"
                    id="logradouro"
                  >
                </div>
              </div>


              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <label
                    for="numero"
                    class="form-label"
                  >
                    NUMERO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="numero"
                    class="form-control"
                    id="numero"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <label
                    for="complemento"
                    class="form-label"
                  >
                    COMPLEMENTO
                  </label>
                  <input
                    type="text"
                    formControlName="complemento"
                    class="form-control"
                    id="complemento"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="bairro"
                    class="form-label"
                  >
                    BAIRRO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    formControlName="bairro"
                    type="text"
                    class="form-control"
                    id="bairro"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <label
                    for="cidade"
                    class="form-label"
                  >
                    CIDADE
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    formControlName="cidade"
                    type="text"
                    class="form-control"
                    id="cidade"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <label
                    for="uf"
                    class="form-label"
                  >
                    UF
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    formControlName="uf"
                    type="text"
                    class="form-control"
                    id="uf"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </form>


  <div
    *ngIf="!!tableParams?.idGalpao"
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Funcionários Transportador</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="!session.checkPerfil('PARCEIRO')"
                    (click)="modalFuncionarios.open()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    Cadastrar
                  </button>
                </card-header-buttons>
                <hr>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableFuncionario
              tableEventId="GalpaoFuncionariosEntity"
              [entityMap]="entityGalpFuncionarios"
              [persistentParams]="tableParams"
              [showDefaultAction]="!session.checkPerfil('PARCEIRO')"
            ></crud-data-table>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="entityGalpFuncionarios.configEntityTable.pagination"
                (changePage)="tableFuncionario.changePages($event)"
              ></dg-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>


  <div
    *ngIf="!!tableParams?.idGalpao"
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Documentos</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="!session.checkPerfil('PARCEIRO')"
                    (click)="modalDocumentos.open()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableDocumentos
              tableEventId="GalpaoArquivoEntity"
              [entityMap]="entityGalpDocumentos"
              [persistentParams]="tableParams"
              [showDefaultAction]="!session.checkPerfil('PARCEIRO')"
            ></crud-data-table>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="entityGalpDocumentos.configEntityTable.pagination"
                (changePage)="tableDocumentos.changePages($event)"
              ></dg-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>


  <div
    *ngIf="!!tableParams?.idGalpao"
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Tipo de Resíduo</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="!session.checkPerfil('PARCEIRO')"
                    (click)="modalTipoLixo.open()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    Adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableTipoLixo
              tableEventId="GalpaoTipoLixoEntity"
              [entityMap]="entityTipoLixo"
              [persistentParams]="tableParams"
              [context]="TIPO_LIXO_GALPAO + (tableParams?.idGalpao || '')"
              [contextDelete]="TIPO_LIXO_GALPAO"
              [showDefaultAction]="session.checkPerfil('PARCEIRO') ? false : 'excluir'"
            ></crud-data-table>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="entityTipoLixo.configEntityTable.pagination"
                (changePage)="tableTipoLixo.changePages($event)"
              ></dg-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>


  <div
    *ngIf="!!tableParams?.idGalpao"
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Container</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    (click)="modalContainerRelacionar.open()"
                    class="btn btn-success btn-card-header"
                  >
                    Relacionar container modelo
                  </button>
                  <button
                    *ngIf="!session.checkPerfil('PARCEIRO')"
                    (click)="modalContainerAdd.open()"
                    class="btn btn-success btn-card-header"
                  >
                    Adicionar container personalizado
                  </button>

                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-subtitle">
            <filter-search
              style="background-color: #0d6efd"
              [entityMap]="entityContainer"
              [table]="TableContainer"
              tableEventId="GalpaoContainerEntity"
            ></filter-search>
          </div>
          <div class="card-body">
            <crud-data-table
              #TableContainer
              tableEventId="GalpaoContainerEntity"
              context="galpao-containers"
              [entityMap]="entityContainer"
              [showDefaultAction]="false"
              [persistentParams]="{galpaoId: tableParams.idGalpao}"
              [customActions]="session.checkPerfil('PARCEIRO') ? undefined : customActionsContainers"
              [customDblclick]="true"

            ></crud-data-table>
            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="entityContainer.configEntityTable.pagination"
                (changePage)="TableContainer.changePages($event)"
              ></dg-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    *ngIf="!session.checkPerfil('PARCEIRO')"
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    *ngIf="!session.checkPerfil('PARCEIRO')"
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
    *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
    class="btn"
    (click)="ativarDesativar()"
    [class.btn-danger]="entity?.ativo"
    [class.btn-success]="!entity?.ativo"
  >
    {{entity?.ativo ? 'desativar' : 'ativar'}}
    operador
  </button>
</page-footer>


<subComponente-galpao-funcionarios
  #modalFuncionarios
  [galpaoId]="entity?.id"
></subComponente-galpao-funcionarios>


<subComponente-galpao-documentos
  #modalDocumentos
  [idGalpao]="entity?.id"
></subComponente-galpao-documentos>

<subComponent-galpao-tipo-lixo
  #modalTipoLixo
  [idGalpao]="entity?.id"
></subComponent-galpao-tipo-lixo>


<model-relacionar-container-galpao
  #modalContainerRelacionar
  [idGalpao]="entity?.id"
>
</model-relacionar-container-galpao>


<model-add-container-galpao
  #modalContainerAdd
  [idGalpao]="entity?.id"
>
</model-add-container-galpao>
