import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit {

  public login = new FormControl('', [Validators.required])

  constructor(public service: HttpOldService, private router: Router) { }

  ngOnInit(): void {
  }

  enviar() {
    if (this.login.invalid) {
      this.login.markAllAsTouched();
      return;
    }

    this.service.post('auth/esqueci-senha', { login: this.login.value }).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: "Pedido de renovação realizado",
        text: 'Você receberá um email com o link para renovação.'
      }).then(() => {
        this.router.navigate(['/login'])
      })
    })
  }

}
