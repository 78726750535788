import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {HttpOldService} from 'src/app/core/services/http/http-old.service';
import Swal from 'sweetalert2';
import {ClientesService} from '../../_clientes/clientes/service/clientes.service';
import {SelecionarPlanoService} from '../../selecionar-plano/service/selecionar-plano.service';
import {FluxoCobrancaEntity} from '../fluxo-cobranca.entity';
import {FluxoCobrancaService} from '../service/fluxo-cobranca.service';
import {environment} from "../../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {ClientesEntity} from "../../_clientes/clientes/clientes.entity";
import {InputAutoCompleteComponent} from "../../../../shared/ui/input-auto-complete/input-auto-complete.component";

@Component({
  selector: 'app-fluxo-cobranca-main',
  templateUrl: './fluxo-cobranca-main.component.html',
  styleUrls: ['./fluxo-cobranca-main.component.scss']
})
export class FluxoCobrancaMainComponent implements OnInit {

  mapEntity = new FluxoCobrancaEntity();

  constructor(public service: FluxoCobrancaService) { }

  ngOnInit(): void {
  }

}
