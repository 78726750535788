<div id="indicadores-ods-insert-edit">
    <form [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <card>
                        <div class="card-header">
                            <h4 class="card-title">Indicadores ODS</h4>
                            <hr />
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label class="form-label">NOME <span class="fc-red">*</span></label>
                                    <div>
                                        <select class="form-select" formControlName="nome" id="nome">
                                            <option value="" selected disabled>selecione</option>
                                            <option *ngFor="let item of listIndOds" value="{{item?.nome}}">
                                                {{item?.nome}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label for="unidadeGeradora" class="form-label">
                                        GERADOR <span class="fc-red">*</span></label>
                                    <input-auto-complete
                                    property="id"
                                    propertValue="nome"
                                    controlName="unidadeGeradora"
                                    urlServer="clientes/nomes"
                                    [service]="clientesServic"
                                    >
                                    </input-auto-complete>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label for="ano" class="form-label">ANO<span class="fc-red">*</span></label>
                                    <input type="number" formControlName="ano" class="form-control" id="ano" mask="0000" min="2000" max="3000">
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label for="verificarTaxas" class="form-label">VERIFICAR TAXAS<span
                                            class="fc-red">*</span></label>
                                    <input type="text" formControlName="verificarTaxas" class="form-control"
                                        id="verificarTaxas">
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </form>
</div>

<page-footer>
    <button crud-voltar class="btn btn-secondary">Voltar</button>
    <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
</page-footer>
