<div class="container-data">
  <!--HISTORICO DE PLANOS -->
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Histórico de Planos</h4>
        </div>
        <div class="card-body">
          <div class="crud-table">
            <table class="crud-table">
              <tr>
                <th>Data</th>
                <th>Planos</th>
                <th>Periodicidades</th>
                <th>Quantidade</th>
              </tr>
              <tbody>
                <tr *ngIf="historicos?.length <= 0">
                  <td style="text-align: center" colspan="4">Nenhum registro encontrado</td>
                </tr>
                <tr *ngFor="let historico of historicos">
                  <td>{{ formataData(historico.dataDeInclusao) }}</td>
                  <td>{{ historico.nomeAnterior }}</td>
                  <td>{{ historico?.periodicidadeAnterior?.name || '--' }}</td>
                  <td>{{ historico.quantidade }}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="card-footer">
          <dg-pagination (changePage)="changePage($event)" [dataPagination]="pagination"></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>