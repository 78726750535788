import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { RotasEntity } from '../rotas.entity';

@Injectable({
  providedIn: 'root'
})
export class RotasService extends AbstractHttpService<RotasEntity> {


  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'rotas');

    this.dictionary.findOne = {
      clientes: 'nomeCliente',
      nomeMotorista: 'motoristaNome',
    }

    this.dictionary.update = {
      nomeCliente: 'clientes',
      motoristaNome: 'nomeMotorista'
    }
  }

  enviarRelatorio(ids: number[]){
    return this.save(ids, 'enviarrelatorio');
  }

}
