import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogisticaReversaMainComponent } from './logistica-reversa-main/logistica-reversa-main.component';
import { LogisticaReversaInsertEditComponent } from './logistica-reversa-insert-edit/logistica-reversa-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DocumentosComponent } from './subComponentes/documentos/documentos.component';
import { ProdutosComponent } from './subComponentes/produtos/produtos.component';
import {MatMenuModule} from "@angular/material/menu";
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";



@NgModule({
  declarations: [
    LogisticaReversaMainComponent,
    LogisticaReversaInsertEditComponent,
    DocumentosComponent,
    ProdutosComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        DgCrudModule,
        ReactiveFormsModule,
        AngularEditorModule,
        FormsModule,
        MatButtonModule,
        MatMenuModule,
        DirectivesModule,
        DgAutocompleteModule
    ]
})
export class LogisticaReversaModule { }
