import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {ClientesEntity} from "../clientes.entity";
import {environment} from "../../../../../../environments/environment";
import {CLIENTE} from "../../../../../core/config/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class ClientesService extends AbstractHttpService<ClientesEntity>{

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, CLIENTE);
  }

  getPontuacao() {
    return this.http.get(this.path+'/pontuacao')
  }
}
