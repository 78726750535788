import { formata_data_utc_br } from 'src/app/helpers/helpers';
import { ClientesEntity } from 'src/app/pages/_user/_clientes/clientes/clientes.entity';
import { VendasLogisticaProdutosService } from '../../../vendas-produtos/services/vendas-logistica-produtos.service';
import { VendasLogisticaProdutosEntity } from '../../../vendas-produtos/vendas-logistica-produtos.entity';
import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { ClientesService } from '../../../_clientes/clientes/service/clientes.service';
import { VendasLogisticaClientesEntity } from '../../../vendas-clientes/vendas-logistica-clientes.entity';
import { VendasLogisticaClientesService } from '../../../vendas-clientes/services/vendas-logistica-clientes.service';
import { InterfaceCustomActions } from '@datagrupo/dg-crud';
import cli from '@angular/cli';
import { LogisticaReversaService } from "../../../logistica-reversa/service/logistica-reversa.service";
import {
  ClienteCreditoLogisticaReversaProdutoService
} from "../../../logistica-reversa/service/logistica-reversa-produto/cliente-credito-logistica-reversa-produto.service";
import { VendasLogisticaReversaService } from "../../service/vendas-logistica-reversa.service";
import { CLIENTE_CREDITO_LOGISTICA_REVERSA_PRODUTOS } from "../../../../../core/config/endpoint-list";
import { InputHelpers } from 'src/app/helpers/input.helpers';


@Component({
  selector: 'subComponentes-vendas-logistica-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  @ViewChild('modalCliente') modalCliente!: OldModalComponent;
  @Output() salve = new EventEmitter<any>()
  editorConfig = DefaultKolkovTextEditor;

  public selectedCliente: number | string | undefined;

  public produtosAssoc: any[] = [];
  public produtosComp: any[] = [];

  public formModal = new FormGroup({
    clientes: new FormControl('', [Validators.required]),
    quantidadeVendida: new FormControl('', [Validators.required, Validators.minLength(1)]),
  })

  public vendaCliEntity = new VendasLogisticaClientesEntity();
  public vendaProEntity = new VendasLogisticaProdutosEntity();
  public listClient: VendasLogisticaClientesEntity[] = []
  public clientEntity = new ClientesEntity();
  public isError: boolean = false;

  constructor(
    public clientService: LogisticaReversaService,
    public produtosService: ClienteCreditoLogisticaReversaProdutoService,
    public service: VendasLogisticaReversaService
  ) {

    this.formModal.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.formModal, v, ['quantidadeVendida'])
    )

    clientService.findAll().subscribe(
      resp => {
        //@ts-ignore
        this.listClient = resp.data;
      }
    )

    // this.vendaCliEntity.setPagination(null);

    this.vendaCliEntity.addColumns([
      {
        nameAtributo: 'clientes',
        columnName: 'Nome'
      },
      {
        nameAtributo: 'quantidade',
        columnName: 'Quantidade'
      },
      {
        nameAtributo: 'dataInclusao',
        columnName: 'Data Inclusão'
      }
    ])

    this.vendaCliEntity.removeColumns([
      "valor", "pontos", "disponivel", "quantidadeComp", "nome", "idPagamento",
      "produtos", "nomeCliente"
    ]);

    this.vendaCliEntity.addActions(<InterfaceCustomActions[]>[
      {
        name: 'editar',
        action: (data: VendasLogisticaClientesEntity) => this.open(data)
      }
    ]);


  }

  ngOnInit(): void { }

  afterFetchEntity() {
  }

  public open(data?: any, index?: number) {
    if (!!data) {
      this.formModal.patchValue({
        quantidadeVendida: InputHelpers.initInputDecimal(data.quantidadeVendida || '') || data.quantidadeVendida,
        clientes: data.clienteCreditoLogisticaReversa.nome,
      })
      this.selectedCliente = data.clienteCreditoLogisticaReversa;

      this.loadTable(data.clienteCreditoLogisticaReversa.id)
      if (data.id) {
        this.formModal.controls['clientes'].disable()
      }
    }
    this.modalCliente.open();
  }

  public salvarCliente() {
    if (!this.formModal.valid) {
      this.formModal.markAllAsTouched();
      this.isError = false;
      return;
    }

    const { clientes, quantidadeVendida } = this.formModal.value;

    console.log(Number(InputHelpers.ajusteMoneyBrToEua(quantidadeVendida)))
    console.log(quantidadeVendida)
    debugger

    if(Number(InputHelpers.ajusteMoneyBrToEua(quantidadeVendida)) <= 0) {
      this.isError = true;
      return
    }

    this.salve.emit({
      quantidadeVendida: Number(InputHelpers.ajusteMoneyBrToEua(quantidadeVendida)),
      clientes: this.selectedCliente,
    })
    this.modalCliente.close();
  }

  close = (e?: any) => {
    this.formModal.reset();
    this.produtosAssoc = [];
    this.produtosComp = [];
    this.selectedCliente = undefined;
    return true
  }

  loadTable(id: number | string) {
    this.service.get(CLIENTE_CREDITO_LOGISTICA_REVERSA_PRODUTOS, { params: { clienteCreditoId: id } }).subscribe(
      resp => {
        //@ts-ignore
        this.produtosAssoc = resp.data.clienteCreditoLogisticaReversaProduto
      }
    )
    this.service.findOne(id, 'produtos-compensados').subscribe(
      resp => {
        //@ts-ignore
        this.produtosComp = resp.data;
      }
    )

  }

  alterClienteModal(ev: any) {
    console.log(ev)
    if (!!ev?.id) {
      this.loadTable(ev.id)
      this.selectedCliente = ev
    }
  }

  formatDate(date?: string) {
    return !!date ? formata_data_utc_br(date) : '--'
  }

  format = (val: string | number) => {
    if (val) {
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    } else {
      return 0
    }
  }
}
