<old-dg-modal #modalAlterPassword id="modalAlterPassword" size="xl" title="Redefinir Senha" [afterClose]="beforeClose">
  <div dg-content>
    <div class="row">
      <div class="col-12">
        <p>Deseja redefinir sua senha?</p>
      </div>
    </div>
    <div class="row">
      <form [formGroup]="form">
        <div class="input-group">
          <input type="{{!!oldViewPass ? 'text' : 'password'}}" class="form-control"
            placeholder="Digite sua senha atual" formControlName="senhaAntiga">
          <button class="btn btn-outline input-group-addon icon-button" (click)="oldViewPass = !oldViewPass">
            <mat-icon *ngIf="oldViewPass">visibility</mat-icon>
            <mat-icon *ngIf="!oldViewPass">visibility_off</mat-icon>
          </button>
        </div>
        <div class="input-group">
          <input type="{{!!viewPass ? 'text' : 'password'}}" class="form-control mt-2"
            placeholder="Digite sua nova senha" formControlName="senhaNova">
          <button class="btn btn-outline" (click)="viewPass = !viewPass">
            <mat-icon *ngIf="viewPass">visibility</mat-icon>
            <mat-icon *ngIf="!viewPass">visibility_off</mat-icon>
          </button>
        </div>
        <div class="input-group">
          <input type="{{!!confirmViewPass ? 'text' : 'password'}}" class="form-control mt-2"
            placeholder="Confirme sua nova senha" formControlName="senhaNovaConfirmar">
          <button class="btn btn-outline" (click)="confirmViewPass = !confirmViewPass">
            <mat-icon *ngIf="confirmViewPass">visibility</mat-icon>
            <mat-icon *ngIf="!confirmViewPass">visibility_off</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div dg-footer>
    <button (click)="modalAlterPassword.close()" class="btn btn-secondary m-1">Fechar</button>
    <button (click)="salvar()" class="btn btn-success m-1">Salvar</button>
  </div>
</old-dg-modal>
