import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { CLIENTE_FIND_FILTER } from 'src/app/core/config/endpoint-list';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import Swal from 'sweetalert2';
import { ClientesEntity } from '../../_clientes/clientes/clientes.entity';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
import { GrupoComunicacaoEntity } from '../grupo-comunicacao.entity';
import { GrupoComunicacaoService } from '../service/grupo-comunicacao.service';

@Component({
  selector: 'app-grupo-comunicacao-insert',
  templateUrl: './grupo-comunicacao-insert.component.html',
  styleUrls: ['./grupo-comunicacao-insert.component.scss']
})
export class GrupoComunicacaoInsertComponent extends AbstractInsertEdit<GrupoComunicacaoEntity> implements OnInit {

  @ViewChild('pickList') pickList!: PickListComponent;

  public listSelect: any[] = [];
  listSelected: any[] = [];

  form = new FormGroup({
    nome: new FormControl('', [Validators.required])
  })

  constructor(
    public servic: GrupoComunicacaoService,
    public conf: ConfigDgCrudService,
    public serviceCliente: ClientesService,
    public route: Router
  ) {
    super(conf, servic);
    serviceCliente.addWhere({ unpaged: true })
    serviceCliente.findAll().subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listSelect = resp.data
      }
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override afterFetchEntity(): void {
    this.form.patchValue({ ...this.entity })
    this.listSelected = this.entity.clientes.map(unidade => {
      return { id: unidade.id, nome: unidade.nome }
    })

  }

  initNewEntity(): void {
    this.entity = new GrupoComunicacaoEntity();
  }

  getListClientes(cliente?: any) {
    this.serviceCliente.get(CLIENTE_FIND_FILTER,
      {
        params: {
          unpaged: true,
          ...(!!cliente?.valueSearch ? { nome: cliente.valueSearch } : {})
        },
      }).subscribe(
     resp => {
       //@ts-ignore
       this.listSelect = resp.data;
     }
    )
  }

  override beforeSaveEntity(): boolean {
    let listCondominios = this.pickList.getList().map((cli: ClientesEntity) => {
      return { id: cli.id, nome: cli.nome }
    })


    this.entity = {
      ...this.entity,
      ...this.form.value,
      clientes: listCondominios
    }

    console.log(this.entity)
    if (!this.verify()) return false;

    return true
  }


  verify() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false
    }
    if (this.entity) {
      if (this.entity.clientes.length <= 0) {
        Swal.fire({
          text: 'A lista de unidades geradoras selecionados está vazia, adicione ao menos um condomínio',
          icon: 'warning',
          timer: 5000,
          showConfirmButton: true,
        })
        return false
      }
    }
    return true;
  }


}
