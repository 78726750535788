import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { MISSOES } from 'src/app/core/config/endpoint-list';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import Swal from 'sweetalert2';
import { ProgramaLogisticaReversaEntity } from '../programa-logistica-reversa.entity';
import { ProgramaLogisticaReversaService } from '../service/programa-logistica-reversa.service';

@Component({
  selector: 'app-programa-logistica-reversa-main',
  templateUrl: './programa-logistica-reversa-main.component.html',
  styleUrls: ['./programa-logistica-reversa-main.component.scss']
})
export class ProgramaLogisticaReversaMainComponent implements OnInit {

  listaParceiros: ProgramaLogisticaReversaEntity[] = [];
  listaProgramas: ProgramaLogisticaReversaEntity[] = [];
  listaConquistas: ProgramaLogisticaReversaEntity[] = [];

  missaoAceita: any;

  imagemSelecionada: any;

  showParceiro: boolean = true;
  showListaConquistas: boolean = false;
  showProgramas: boolean = false;
  statusDasMissoes: 'MISSOES' | 'CONQUISTAS' = 'MISSOES'


  @ViewChild('modalProgramas') modalProgramas!: OldModalComponent;

  constructor(
    public conf: ConfigDgCrudService,
    public servic: ProgramaLogisticaReversaService,
    public router: Router,
    public http: HttpOldService,
    public files: FilesManageService,
    private sanitazer: DomSanitizer,
    public session: SessionService
  ) {
    servic.get('missoes/cliente-credito').subscribe(resp => {
      //@ts-ignore
      this.listaParceiros = resp.data
      console.log(resp.data)
    })
  }

  ngOnInit(): void {
  }

  showList(data: any) {
    this.servic.get(MISSOES, { params: { clienteCreditoId: data.id } }).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaProgramas = resp.data.filter(item => item.podeResgatarCupom == false && item.tipoMissao == "CONDOMINIO");
        if(this.listaProgramas.length <= 0){
          Swal.fire({
            title: "Aviso",
            text: `O parceiro ${data.nome} ainda não cadastrou suas Programas`,
            icon: 'warning',
          })
          return false
        }
        this.showProgramas = true;
        this.showParceiro = false;
        this.showListaConquistas = false;
      }
      return true
    })
  }
  showConquistas() {
    this.servic.get('missoes/conquistas').subscribe(resp => {
      if (resp) {
        this.listaConquistas = resp.data;
        this.showListaConquistas = true;
        this.showParceiro = false;
        this.showProgramas = false;
        this.statusDasMissoes = 'CONQUISTAS'
      }
    })
  }

  showParceiros() {
    this.http.get('missoes/cliente-credito').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaParceiros = resp.data;
        this.showParceiro = true;
        this.showListaConquistas = false;
        this.showProgramas = false
      }
    })
  }

  ignorSanitaze(index?: number) {
    if (index || index == 0) {
      return this.sanitazer.bypassSecurityTrustHtml(String(this.listaProgramas[index].descricao))
    }
    return;
  }

  programasInconcluidas() {
    this.http.get('missoes').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaProgramas = resp.data.filter(item => item.podeResgatarCupom == false && item.tipoMissao == "CONDOMINIO");
        console.log(this.listaProgramas)
        this.showProgramas = true;
        this.showListaConquistas = false;
        this.showParceiro = false;
        this.statusDasMissoes = 'MISSOES'
      }
    })
  }

  abrirModalPrograma(index: number) {
    this.modalProgramas.open()
    this.missaoAceita = this.listaProgramas[index];
  }

  aceitarPrograma(data: any) {
    this.http.post(`missoes/aceitar/${data.id}`, data.id).subscribe();
    this.modalProgramas.close();
    this.programasInconcluidas();
  }

  resgatarCupom(index: number) {
    const id = this.listaConquistas[index].id
    this.router.navigate(['/cupom/' + id])
  }

  cumpraDesafio(index: number) {
    if (index) {
      if (this.listaProgramas[index].link) {
        window.location.href = this.listaProgramas[index].link!;
      } else {
        Swal.fire({
          title: 'Ops!',
          icon: 'warning',
          text: 'Missão sem link de desafio, peça para o gestor cadastrar o link',
          timer: 4000,
          showConfirmButton: false
        })
      }
    }
  }

  concluirPrograma(index: number) {
    const id = this.listaProgramas[index].id;
    this.http.post(`/missoes/concluir/${id}`, this.listaProgramas[index]).subscribe()
  }

}
