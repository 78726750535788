<card-promo
  imgBackground="/assets/img-mock/banner-inicial-02.jpg"
  [imag-config]="data"
>
  <div class="card-body" style="padding: 1em 2em; height: 22rem">
    <div class="row">
      <div class="col-xl-7 col-lg-8 col-md-9 col-sm-9">
        <h1 class="fc-white f-bold" style="-webkit-text-stroke: 0.06rem black">
          Confira os desafios de logística reversa que você pode fazer e ter cashback!
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <a [routerLink]="!!session.user.cliente_id ? '/user/jornada-educacao-ambiental' : null">
          <button class="btn btn-success" [disabled]="!(!!session.user.cliente_id)">veja desafios</button>
        </a>
      </div>
    </div>
  </div>
</card-promo>
