<div id="meu-perfil" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Dados Cadastrais</h4>
          <hr/>
        </div>
        <div class="card-body">
          <form [formGroup]="form2">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="nome" class="form-label">NOME <span class="fc-red">*</span></label>
                <input type="text" class="form-control" id="nome" formControlName="nome"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="sobrenome" class="form-label">SOBRENOME <span class="fc-red">*</span></label>
                <input type="text" class="form-control" id="sobrenome" formControlName="sobrenome"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil('PARCEIRO')">
                <label for="nascimento" class="form-label">
                  DATA DE NASCIMENTO <span class="fc-red">*</span>
                </label>
                <input type="date" (change)="verifyDate($event)" (paste)="ajustePasteDate($event)" class="form-control"
                       id="nascimento"
                       formControlName="nascimento"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="email" class="form-label">E-MAIL <span class="fc-red">*</span></label>
                <input type="email" class="form-control" id="email" formControlName="email"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="login" class="form-label">LOGIN <span class="fc-red">*</span></label>
                <input type="email" class="form-control" id="login" formControlName="login"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil('PARCEIRO')">
                <label for="cpf" class="form-label">CPF </label>
                <input type="text" class="form-control" id="cpf" placeholder="000.000.000-00" mask="000.000.000-00"
                       formControlName="cpf"/>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil(['GALPAO', 'PARCEIRO'])">
                <label class="form-label">TELEFONE <span class="fc-red">*</span></label>
                <input type="text" class="form-control" placeholder="(00) 00000-0000"
                       mask="(00) 0000-0000 ||(00) 00000-0000" formControlName="telefone"/>
              </div>

              <div *ngIf="!!session.checkPerfil('CONDOMINIO')" class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="tipoUsuario" class="form-label">PERFIL DO USUÁRIO</label>
                <select class="form-select" formControlName="tipoUsuario" id="tipoUsuario">
                  <option value="" disabled selected>Tipo de cadastro</option>
                  <option value="SINDICO">Gestor</option>
                  <option value="CONSELHO">Conselho/Administrativo</option>
                  <option value="MORADOR">Usuário</option>
                  <option value="PORTEIRO">Operador</option>
                  <option value="FINANCEIRO">Financeiro</option>
                </select>

              </div>

              <div *ngIf="!!session.checkPerfil('GALPAO')" class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="nome" class="form-label">PERFIL DO USUÁRIO</label>
                <select class="form-select" formControlName="cargo" id="cargo">
                  <option value="OPERADOR_LOGITISCO">Transportador</option>
                  <option value="ADMIN">Administrativo</option>
                  <option value="FINANCEIRO">Financeiro</option>
                  <option value="PESAGEM">Pesagem</option>
                  <option value="COMERCIAL">Comercial</option>
                </select>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="!session.checkPerfil('PARCEIRO')">
                <label class="form-label">Complemento</label>
                <input
                  class="form-control"
                  formControlName="complemento"
                  placeholder="Ex. casa 1, apto 203"
                >
              </div>

            </div>

            <div class="row" *ngIf="session.checkPerfil(['CONDOMINIO', 'EVENTO', 'EMPRESA', 'EVENTO'])">
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row ">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <input class="ml"  type="radio" name="receberEmailsInformativos" id="sim" formControlName="receberEmailsInformativos"
                         value=true />
                  <label for="sim">Sim</label>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <input class="ml" type="radio" name="receberEmailsInformativos" id="nao" formControlName="receberEmailsInformativos"
                         value="false" />
                  <label for="nao">Não</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <button class="btn btn-secondary" (click)="getTermo()">Termo de adesão</button>
  <button (click)="save()" class="btn btn-success">Salvar</button>
  <button class="btn btn-success" (click)="alterarSenha()">Alterar senha</button>
</page-footer>
