import {Component, HostListener, OnInit} from '@angular/core';
import {RankingTaxaReciclagemEntity} from "../../entitys/ranking-taxa-reciclagem.entity";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {crudDispatchEvent} from "@datagrupo/dg-crud";

@Component({
  selector: 'sub-component-dashboard-ranking-taxa-reciclagem',
  templateUrl: './sub-component-dashboard-ranking-taxa-reciclagem.component.html',
  styleUrls: ['./sub-component-dashboard-ranking-taxa-reciclagem.component.scss']
})
export class SubComponentDashboardRankingTaxaReciclagemComponent implements OnInit {

  public mapEntity = new RankingTaxaReciclagemEntity();
  public tableParams: {[key: string]: any} = {};
  public showTable = false;

  constructor(
    private http: HttpOldService,
    private files: FilesManageService
  ) { }

  ngOnInit(): void {}

  public generate_pdf_target(): void {
    const params = JSON.stringify({
      pagination: this.mapEntity.configEntityTable.pagination,
      ...(!!this.tableParams ? this.tableParams : {})
    });

    window.open('user/ranking-taxa-reciclagem-print?params='+params, '_blank')?.focus();
  }

  downloadExcel() {
    const params = {
      unpaged: true,
      ...(!!this.tableParams ? this.tableParams : {})
    };

    this.http
      .get<{ data: string }>(
        'coletas/ranking-coleta/taxa-reciclagem/planilha',
        { params }).subscribe((resp) => {
        this.files.downloadBase64(resp.data, 'ranking-coleta-taxa-reciclagem.xlsx');
      });
  }

  @HostListener('window:atualizar-pagina-filtrada-ranking-taxa', ['$event'])
  getEventValues(ev: CustomEvent) {
    if (JSON.stringify(ev.detail) != JSON.stringify(this.tableParams)) {
      this.tableParams = ev.detail;
      setTimeout(() => crudDispatchEvent('RankingTaxaReciclagem'), 100)
    }
  }

}
