import {Component, OnInit, ViewChild} from '@angular/core';
import {PrintLoadingComponent} from "../../../../../shared/print/print-loading/print-loading.component";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {DASHBOARD, DASHBOARD_TABLE, DASHBOARD_TABLE_GRI} from "../../../../../core/config/endpoint-list";
import {ActivatedRoute} from "@angular/router";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {DetalhamentoUnidadeGeradoraEntity} from "../../entitys/detalhamento-unidade-geradora.entity";
import {DetalhamentoGriEntity} from "../../entitys/detalhamento-gri.entity";
import {formata_data_utc_br} from "../../../../../helpers/helpers";
import * as Highcharts from "highcharts";
import {ajusteFunctionHighChartsByInsert} from "../../helpers/highcharts.helper";

@Component({
  selector: 'app-dashboard-print-coletas',
  templateUrl: './dashboard-print-coletas.component.html',
  styleUrls: ['./dashboard-print-coletas.component.scss']
})
export class DashboardPrintColetasComponent implements OnInit {

  @ViewChild('printLoading') printLoading!: PrintLoadingComponent;

  showModal = true;

  list: {chave: string, nome: string}[] = [];

  ods = {
    taxaReciclagem: 0,
    valorIndicadorBrasil: 0,
    anoIndicadorBrasil: '--'
  }

  loaders: {
    all: number,
    anos: boolean,
    meses: boolean,
    totais: boolean,
    residuos: boolean,
    ods: boolean,
    motoristas: boolean,
    impacto: boolean,
    aterroZero: boolean
  } = {
    all: 100,
    anos: true,
    meses: true,
    totais: true,
    residuos: true,
    ods: true,
    motoristas: true,
    impacto: true,
    aterroZero: true
  }
  cardInfo = {
    numeroClientes: 0,
    numeroApartmentos: 0
  }
  impacto: {
    minerio: number | string,
    areia: number | string,
    arvores: number | string,
    petroleo: number | string,
    agua: number | string,
    energia: number | string,
  } = {
    minerio: 0,
    areia: 0,
    arvores: 0,
    petroleo: 0,
    agua: 0,
    energia: 0,
  }

  public numeroClientes = 0;
  public numeroApartmentos = 0;
  public listGraph: any[] = [];
  public contAtualizacao = 0;
  // public filterData: filtersInterface = {
  public filterData: any = {};
  public tabelaUnidades: DetalhamentoUnidadeGeradoraEntity[] = [];
  public tabelaGRI: DetalhamentoGriEntity[] = [];


  constructor(
    private service: GenericCrudService,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    this.list = this.setListGraph()
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {

      if (!!p['paginationTableClientes']) {
        let pagination = JSON.parse(p['paginationTableClientes']);
        this.filterData['paginationTableClientes'] = {
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort
        }
      }

      if (!!p['paginationTableGRI']) {
        this.filterData['paginationTableGRI'] = JSON.parse(p['paginationTableGRI']);
      }

      if (!!p['params']) {
        const params = JSON.parse(p['params']);


        console.log('params', params);

        if (!!params['unidades']) {
          this.filterData['unidades'] = params['unidades'];
        }

        if (!!params['galpao']) {
          this.filterData['galpao'] = params['galpao'];
        }

        if (!!params['estados']) {
          this.filterData['estados'] = JSON.parse(params['estados']).map((item: any) => item.uf);
        }

        // console.log(!!params['dataInicio'])
        // console.log(params['dataInicio'])
        // console.log(!!params['dataFim'])
        // console.log(params['dataFim'])
        // debugger

        if (!!params['dataInicio']) {
          this.filterData['dataInicio'] = params['dataInicio'];
        }

        if (!!params['dataFim']) {
          this.filterData['dataFim'] = params['dataFim'];
        }

        if (!!params['ativo']) {
          this.filterData['ativo'] = params['ativo'];
        }
      }


      // this.getGraph();
      // this.getTable();
      // this.getTableGRI();

      this.getInfo()
      // this.getValues().then()

    }).unsubscribe()
  }

  async getValues1() {
    const {paginationTableClientes, paginationTableGRI, ...restParams} = this.filterData;

    await this.service.get(DASHBOARD, {params: restParams}).subscribe(resp => this.callbackGraph(resp))

    await this.service.get(
      DASHBOARD_TABLE,
      {
        params: {
          ...restParams,
          ...paginationTableClientes
        }
      }).subscribe(resp => this.callbackTableCliente(resp.data))

    await this.service.get(
      DASHBOARD_TABLE_GRI,
      {
        params: {
          ...restParams,
          ...paginationTableGRI
        }
      }).subscribe(resp => this.callbackTableGri(resp.data))
  }

  getInfo() {
    const {paginationTableClientes, paginationTableGRI, ...params} = this.filterData;

    this.service.get('dashboard/info', {params}).subscribe(
      resp => {
        // this.addLoader()
        this.cardInfo.numeroApartmentos = resp.data.numeroDeApartamentos || 0;
        this.cardInfo.numeroClientes = resp.data.numeroDeUnidades || 0;
        // this.existDataColetas = resp.data.hasAnyDados;

        if (!!resp.data.hasAnyDados) {
          this.getValues().then()
        } else {
          // this.loader = 100;
        }
      }
    )
  }

  async getValues() {
    const {paginationTableClientes, paginationTableGRI, ...params} = this.filterData;

    await this.service.get('dashboard/anos', {params}).subscribe(
      resp => {
        this.printLoading.updateRegister('graficos_ano', true)
        this.loaders.anos = false;
        this.setGraph('id-chart-anos', resp.data.charts[0])

      }
    )
    await this.service.get('dashboard/meses', {params}).subscribe(
      resp => {
        this.printLoading.updateRegister('graficos_meses', true)
        this.loaders.meses = false;
        this.setGraph('id-chart-meses', resp.data.charts[0])
      }
    )
    await this.service.get('dashboard/totais', {params}).subscribe(
      resp => {
        this.printLoading.updateRegister('graficos_totais', true)
        this.loaders.totais = false;
        this.setGraph('id-chart-totais', resp.data.charts[0])
      }
    )
    await this.service.get('dashboard/residuos', {params}).subscribe(
      resp => {
        this.printLoading.updateRegister('graficos_residuos', true)
        this.loaders.residuos = false;
        this.setGraph('id-chart-residuos', resp.data.charts[0])
      }
    )
    await this.service.get('dashboard/motoristas', {params}).subscribe(
      resp => {
        this.printLoading.updateRegister('graficos_motoristas', true)
        this.loaders.motoristas = false;
        this.setGraph('id-chart-motoristas', resp.data.charts[0])
      }
    )

    await this.service.get(
      DASHBOARD_TABLE,
      {
        params: {
          ...params,
          ...paginationTableClientes
        }
      }).subscribe(resp => {
      this.printLoading.updateRegister('tabela', true)
      this.tabelaUnidades = resp.data;
    })

    await this.service.get(
      DASHBOARD_TABLE_GRI,
      {
        params: {
          ...params,
          ...paginationTableGRI
        }
      }).subscribe(resp => {
      this.printLoading.updateRegister('tabelaGRI', true)
      this.tabelaGRI = resp.data;
    })



    if (!this.session.checkPerfil('GALPAO')) {
      this.impacto = {
        minerio: 0,
        areia: 0,
        arvores: 0,
        petroleo: 0,
        agua: 0,
        energia: 0,
      }

      await this.service.get('dashboard/relatorio-impacto', {params}).subscribe(
        resp => {
          this.printLoading.updateRegister('impacto', true)
          this.loaders.impacto = false;

          (resp.data || []).map((impacto: any) => {
            if (String(impacto.indicador.nome).toLowerCase() == 'areia') {
              this.impacto.areia = Number(1550.05).toLocaleString('BR')
            }
            if (String(impacto.indicador.nome).toLowerCase() == 'arvores') {
              this.impacto.arvores = Number(impacto.valor).toLocaleString('BR')
            }
            if (String(impacto.indicador.nome).toLowerCase() == 'petroleo') {
              this.impacto.petroleo = Number(impacto.valor).toLocaleString('BR')
            }
            if (String(impacto.indicador.nome).toLowerCase() == 'agua') {
              this.impacto.agua = Number(impacto.valor).toLocaleString('BR')
            }
            if (String(impacto.indicador.nome).toLowerCase() == 'energia eletrica') {
              this.impacto.energia = Number(impacto.valor).toLocaleString('BR')
            }
            if (String(impacto.indicador.nome).toLowerCase() == 'minerio') {
              this.impacto.minerio = Number(impacto.valor).toLocaleString('BR')
            }
          })
        }
      )

      await this.service.get('dashboard/aterro-zero', {params}).subscribe(
        resp => {
          this.loaders.aterroZero = false;
          this.printLoading.updateRegister('aterroZero', true)
          this.setGraph('id-chart-aterro-zero', resp.data[0])
        }
      )

      await this.service.get('dashboard/indicador-ods', {params}).subscribe(
        resp => {
          this.printLoading.updateRegister('indicadores_ods', true)
          this.loaders.ods = false;

          this.ods = {
            taxaReciclagem: resp.data.taxaReciclagem || 0,
            valorIndicadorBrasil: resp.data.valorIndicadorBrasil || 0,
            anoIndicadorBrasil: resp.data.anoIndicadorBrasil || '--'
          }
        }
      )
    }
  }

  callbackGraph(resp: any) {
    this.contAtualizacao++;
    //@ts-ignore
    this.listGraph = resp.data?.charts || [];

    if (resp.data?.info) {
      this.numeroApartmentos = resp.data.info.numeroDeApartamentos || 0;
      this.numeroClientes = resp.data.info.numeroDeUnidades || 0;
    }

    this.printLoading.updateRegister('graficos', true)
  }

  callbackTableCliente(resp: DetalhamentoUnidadeGeradoraEntity[]) {

  }

  callbackTableGri(resp: DetalhamentoGriEntity[]) {
  }

  criarId(i: number): string {
    return 'chartid-' + String(i) + '-' + this.contAtualizacao;
  }

  print() {
    setTimeout(() => {
      window.print()
    }, 500);
  }

  dateToLocaleString(data: string | undefined) {
    return !!data ? formata_data_utc_br(data) : '--'
  }

  numberToLocaleString(estimativa: number | string | undefined) {
    return !!estimativa ? Number(estimativa).toLocaleString("pt-BR") : '--'
  }

  setGraph(id: string, data: any) {
    if (window.document.getElementById(id)) {
      setTimeout(() => {
        Highcharts.chart(id, ajusteFunctionHighChartsByInsert(data));
      }, 100)
    }
  }

  setListGraph(): any[] {
    let result: any[] = [
      {chave: 'graficos_ano', nome: 'Gráficos ano'},
      {chave: 'graficos_meses', nome: 'Gráficos meses'},
      {chave: 'graficos_totais', nome: 'Gráficos totais'},
      {chave: 'graficos_residuos', nome: 'Gráficos resíduos'},
      {chave: 'graficos_motoristas', nome: 'Gráficos motoristas/Catadores'},
      {chave: 'tabela', nome: 'Tabela de Geradores'},
      {chave: 'tabelaGRI', nome: 'Insumos para o relatório GRI/SASB (GRI 306)'}
    ];

    if (!this.session.checkPerfil('GALPAO')) {
      result = [
        ...result,
        { chave: 'impacto', nome:'Impacto Ambiental' },
        { chave: 'indicadores_ods', nome:'Indicadores ODS' },
        { chave: 'aterroZero', nome:'Lixo Zero' },
      ]
    }

    return result;
  }
}
