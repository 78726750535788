<div id="pergunta-evento">
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <mat-stepper linear #stepper>
            <mat-step [stepControl]="formPerfilInicial" #stepPerfilInicial>
              <form [formGroup]="formPerfilInicial">
                <ng-template matStepLabel>PERFIL INICIAL</ng-template>
                <div class="row d-flex align-items-end">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">QUAL SEU CARGO OU SETOR NO EVENTO:</label>
                      <select name="cargo" id="cargo" class="form-select"
                              formControlName="cargoEvento">
                        <option value="" selected disabled>Selecione</option>
                        <option value="PATROCINADOR">Patrocinador</option>
                        <option value="PRODUTOR">Produtor</option>
                        <option value="OUTRO"> Outro</option>
                      </select>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">QUAL SOLUÇÃO MAIS SE ADEQUA AO SEU EVENTO:</label>
                      <select name="solucao" id="solucao" class="form-select"
                              formControlName="interessePrincipalEvento">
                        <option value="" selected disabled>Selecione</option>
                        <option value="GESTAO">Preciso de serviço de gestão dos resíduos
                        </option>
                        <option value="COLETA_SELETIVA">Preciso de coleta seletiva
                        </option>
                        <option value="COLETA_PONTUAL"> Preciso de coleta pontual de algum
                          resíduo.
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">NOME DO RESPONSÁVEL POR EXECUTAR O
                        PAGAMENTO:</label>
                      <input type="text" class="form-control" formControlName="nomeResponsavel">
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">E-MAIL DO RESPONSÁVEL POR EXECUTAR O
                        PAGAMENTO:</label>
                      <input type="text" class="form-control" formControlName="emailResponsavel">
                    </div>
                  </div>

                </div>
                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepPerfilInicial)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="formFuncionamentoEvento" #stepFuncionamentoEvento>
              <form [formGroup]="formFuncionamentoEvento">
                <ng-template matStepLabel>FUNCIONAMENTO DO EVENTO</ng-template>

                <div class="row d-flex align-items-end">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label" for="local">DESCREVA OS DIAS E HORÁRIOS DE INÍCIO E
                      FIM DO EVENTO:</label>
                    <input type="text" class="form-control" formControlName="diasHorarioEvento">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUANTAS PESSOAS ESTARÃO NA LIXEIRA CENTRAL DURANTE OS
                      HORÁRIOS DO EVENTO:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="qtdPessoasLixeiraCentral">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">O HORÁRIO DA COLETA PODE OCORRER APÓS O FIM DO
                      EVENTO:</label>
                    <select name="coletaEvento" id="coletaEvento" class="form-select"
                            formControlName="coletaPosEvento">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label" for="local">EXISTE UM LOCAL FECHADO (QUARTO)
                      ONDE OS
                      RESÍDUOS FICAM ARMAZENADOS</label>
                    <select name="local" id="local" class="form-select"
                            formControlName="localArmazenamento">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       [style]="!checkBoolean(formFuncionamentoEvento.value.localArmazenamento) ? 'padding-top: 1.7em;' : 'padding-top: 2.5em;'"
                       *ngIf="!checkBoolean(formFuncionamentoEvento.value.localArmazenamento) ">
                    <label class="form-label">ONDE SERÁ CRIADA A LIXEIRA CENTRAL?</label>
                    <input type="text" class="form-control" formControlName="localLixeiraCentral">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       [style]="!checkBoolean(formFuncionamentoEvento.value.localArmazenamento) ? 'padding-top: .7em;' : 'padding-top: 1.7em;'">
                    <label class="form-label">É UMA ÁREA DE VISIBILIDADE DO PARTICIPANTE?</label>
                    <select name="areaVisivel" id="areaVisivel" class="form-select"
                            formControlName="areaVisivel">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       [style]="!checkBoolean(formFuncionamentoEvento.value.localArmazenamento) ? 'padding-top: 1.5em;' : 'padding-top: 2.5em;'">
                    <label class="form-label">É UMA ÁREA COBERTA?</label>
                    <select name="areaCoberta" id="areaCoberta" class="form-select"
                            formControlName="areaCoberta">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       [style]="!checkBoolean(formFuncionamentoEvento.value.localArmazenamento) ? 'padding-top: 1.5em;' : 'padding-top: 2.5em;'">
                    <label class="form-label">QUAL A LARGURA DA PORTA DA
                      LIXEIRA:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="larguraPorta">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 pt-4">
                    <label class="form-label">QUAL A METRAGEM QUADRADA DA
                      LIXEIRA:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="metragemLixeira">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 pt-1">
                    <label class="form-label">QUANTOS PASSOS DA LIXEIRA CENTRAL ATÉ O
                      PONTO DE
                      PARADA DO CAMINHÃO:</label>
                    <select class="form-select" name="passosLixeira" id="passosLixeira"
                            formControlName="passosLixeira">
                      <option value="" selected disabled>Selecione</option>
                      <option value="ATE_DEZ">até 10 passos</option>
                      <option value="ONZE_VINTE">entre 11 e 20 passos</option>
                      <option value="VINTE_UM_TRINTA">entre 21 e 30 passos</option>
                      <option value="TRINTA_UM_QUARENTA">entre 31 e 40 passos</option>
                      <option value="QUARENTA_UM_CINQUENTA">entre 41 e 50</option>
                      <option value="CINQUENTA_UM_SESSENTA">entre 51 e 60</option>
                      <option value="MAIS_SESSENTA">mais de 60</option>
                    </select>
                  </div>

                  <div *ngIf="formFuncionamentoEvento.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">
                      QUANTOS PASSOS ATÉ A LIXEIRA:
                    </label>
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      formControlName="qtdPassos"
                    >
                  </div>
                  <div *ngIf="formFuncionamentoEvento.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                       style="padding-top: 1.1em">
                    <label class="form-label">ALGUM FUNCIONÁRIO SEU PODE
                      REGISTRAR O VOLUME DE RESÍDUO EXISTENTE NO CONTAINER NA PLATAFORMA DA
                      ECOPLAT E LEVAR O RESÍDUO ATÉ UMA DISTÂNCIA DE 15 PASSOS DO PONTO
                      DE PARADA DO CAMINHÃO:
                    </label>
                    <select formControlName="funcionarioPodeLevarLixo"
                            class="form-select" id="funcionarioPodeLevarLixo"
                            name="funcionarioPodeLevarLixo">
                      <option value="" selected disabled>Selecione
                      </option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div *ngIf="formFuncionamentoEvento.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                       style="padding-top: 1em">
                    <label class="form-label">
                      DESCREVA COMO ISSO PODE SER FEITO:
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="descricao" formControlName="descricao" rows="5"
                      cols="5"
                    ></textarea>
                    <span class="fs-10 text-muted">
                      Caso não tenha as condições ideais de distância os
                      coletadores poderão perder o interesse pela sua coleta.</span>
                  </div>
                </div>

                <hr class="mt-4 mb-4">

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" >
                    <h5>ANEXE UMA FOTO DA LIXEIRA CENTRAL:</h5>
                    <div class="div-image-containers">
                      <input #fileUploadfotoLixeiraCentral type="file"
                             (change)="uploadImg($event, entityId, 'fotoLixeiraCentral')"
                             style="display: none" accept="image/png, image/jpeg, image/jpg">
                      <img class="image-container"
                           src="{{ files.getImageToSrc(entity?.fotoLixeiraCentral)}}"
                           alt="Imagem do container">

                    </div>
                    <div
                      class="div-button-image-container justify-content-center">
                      <button (click)="fileUploadfotoLixeiraCentral.click()"
                              class="btn btn-success m-1">
                        Escolher
                      </button>
                      <button [disabled]="!entity?.fotoLixeiraCentral?.id"
                              (click)="removerImagem(entity?.fotoLixeiraCentral, 'fotoLixeiraCentral')"
                              class="btn btn-danger m-1">
                        Excluir
                      </button>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h5>ANEXE UMA FOTO DA PORTA DA LIXEIRA CENTRAL:</h5>
                    <div class="div-image-containers">

                      <input #fileUploadfotoPortaCentral type="file"
                             (change)="uploadImg($event, entityId, 'fotoPortaCentral')"
                             style="display: none" accept="image/png, image/jpeg, image/jpg">
                      <img class="image-container"
                           src="{{files.getImageToSrc(entity?.fotoPortaCentral)}}"
                           alt="Imagem do container">

                    </div>
                    <div
                      class="div-button-image-container justify-content-center">
                      <button (click)="fileUploadfotoPortaCentral.click()"
                              class="btn btn-success m-1">
                        Escolher
                      </button>
                      <button [disabled]="!entity?.fotoPortaCentral?.id"
                              (click)="removerImagem(entity?.fotoPortaCentral, 'fotoPortaCentral')"
                              class="btn btn-danger m-1">
                        Excluir
                      </button>
                    </div>
                  </div>

                </div>

                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepFuncionamentoEvento)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="formAssinatura">
              <form [formGroup]="formAssinatura">
                <ng-template matStepLabel>ASSINATURA</ng-template>
                <div class="row">
                  <label class="form-label">ESCOLHA SUA ASSINATURA:</label>
                  <mat-select class="form-select" formControlName="assinatura" multiple required>
                    <mat-option *ngFor="let topping of optAssinaturas"
                                [value]="topping.key">{{topping.value}}</mat-option>
                  </mat-select>
                </div>
              </form>
              <div class="pt-3 d-flex justify-content-end container">
                <button class="btn btn-success" (click)="salvarPerguntas()">Salvar</button>
              </div>
            </mat-step>
          </mat-stepper>
        </card>
      </div>
    </div>
  </div>

</div>
