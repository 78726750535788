import { ClientesEntity } from '../_clientes/clientes/clientes.entity';

import {
  AbstractEntity, DataServer,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn,
} from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import {CLIENTE_FIND_FILTER, NEWSLETTER} from "../../../core/config/endpoint-list";
import { autoCompleteClientesOutPut } from 'src/app/helpers/auto-complete/clientes-autocomplete.helper';

@DataServer({
  path: environment.apiUrl,
  context: NEWSLETTER
})
export class NewslettersEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    nome?: ClientesEntity,
    email?: string,
    nomeCliente?: ClientesEntity,
    telefone?: string,
    status?: string
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.email = email;
    this.nomeCliente = nomeCliente;
    this.telefone = telefone;
    this.status = status;
  }

  @DgFilter_input('text', { colum: '4' })
  @DgTableColumn({
    columnName: 'NOME',
    sort: true,
  })
  public nome: ClientesEntity | undefined;

  @DgFilter_input('text', { colum: '4' })
  @DgTableColumn({ columnName: 'E-MAIL', sort: true })
  public email: string | undefined;

  @DgFilter_autoComplete(
    { name: 'GERADOR' },
    {
      getUrl: environment.apiUrl + CLIENTE_FIND_FILTER,
      getServerData: "all",
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFilter: 'nome',
      outputFormat: autoCompleteClientesOutPut
    }
  )
  @DgTableColumn({
    columnName: 'CLIENTE',
    resource: (nomeCliente: ClientesEntity) => !!nomeCliente?.nome ? nomeCliente.nome : "Não Possui"
  })
  public nomeCliente: ClientesEntity | undefined;

  public telefone: string | undefined;
  public status: string | undefined;
}
