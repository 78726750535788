import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TokenService} from "../../../../core/services/token-service/token.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-renovar-senha',
  templateUrl: './renovar-senha.component.html',
  styleUrls: ['./renovar-senha.component.scss']
})
export class RenovarSenhaComponent implements OnInit {

  token: string | undefined
  typePass: 'text' | 'password' = 'password'
  typePassEqual: 'text' | 'password' = 'password'

  public form = new FormGroup({
    newPassword: new FormControl('', [Validators.required]),
    confirmNewPassword: new FormControl('', [Validators.required])
  })

  constructor(
    public service: HttpOldService,
    private route: ActivatedRoute,
    public router: Router,
    private tokenService: TokenService
  ) {
    route.params.subscribe(params => {
      // debugger
      if (!params['token']) {
        router.navigate(['/'])
        return;
      }

      this.token = params['token']
    })
  }

  ngOnInit(): void {
    if (!!this.token) {
      if (
        !this.tokenService.abrirToken(this.token)?.exp &&
        //@ts-ignore
        !this.tokenService.abrirToken(this.token)?.renew_password
      ) this.router.navigate(['/'])

      if (new Date(this.tokenService.abrirToken(this.token)?.exp || '') > new Date()) {
        this.router.navigate(['/'])
      }
    }
  }

  enviar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;

    if (form.newPassword != form.confirmNewPassword) {
      Swal.fire({
        icon: 'error',
        title: 'As senhas não são iguais'
      }).then()
      return;
    }

    const data = {
      ...form,
      token: this.token
    }

    this.service.put('auth/esqueci-senha', data).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: "Senha Alterado",
        text: 'A sua senha foi alterada com sucesso'
      }).then(() => {
        this.router.navigate(['/login']).then()
      })
    })
  }

}
