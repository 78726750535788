import { MeuPerfilEntity } from './../meu-perfil.entity';
import { MeuPerfilService } from '../service/meu-perfil.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../../../../core/services/session-service/session.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
import Swal from "sweetalert2";
import {ajusteDateByPaste} from "../../../../helpers/helpers";

@Component({
  selector: 'app-meu-perfil-insert-edit',
  templateUrl: './meu-perfil-insert-edit.component.html',
  styleUrls: ['./meu-perfil-insert-edit.component.scss'],
})
export class MeuPerfilInsertEditComponent implements OnInit {

  entity = new MeuPerfilEntity();

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form2.patchValue({ nascimento: text }))

  public form2: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    cpf: new FormControl(''),
    nascimento: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    cargo: new FormControl({value: '', disabled: true}),
    tipoUsuario: new FormControl({value: '', disabled: true}),
    complemento: new FormControl(''),
    receberEmailsInformativos: new FormControl('')
  });

  constructor(
    public service: MeuPerfilService,
    public http: HttpOldService,
    public session: SessionService,
    public serviceCliente: ClientesService,
  ) {
    if (session.user.perfil == 'ADMINISTRADOR') {
      this.getAdministrador()
    } else if (session.user.perfil == 'CONDOMINIO') {
      this.getCondomínio();
      this.setFormsCondominio();
    }else if(session.user.perfil == 'PARCEIRO') {
      this.getParceiro();
    }else if(session.user.perfil == 'GALPAO'){
      this.getGalpao();
      this.setFormsGalpao();
      this.form2.get('telefone')?.clearValidators();
      this.form2.updateValueAndValidity();
    }
  }

  ngOnInit(): void {

  }

  setFormsCondominio() {
    this.form2 = new FormGroup({
      cpf: new FormControl(''),
      email: new FormControl('', [Validators.required]),
      login: new FormControl('', [Validators.required]),
      nascimento: new FormControl('', [Validators.required]),
      nome: new FormControl('', [Validators.required]),
      sobrenome: new FormControl('', [Validators.required]),
      telefone: new FormControl('', [Validators.required]),
      cargo: new FormControl({value: '', disabled: true}),
      tipoUsuario: new FormControl({value: '', disabled: true}),
      complemento: new FormControl(''),
      receberEmailsInformativos: new FormControl('')
    })
  }

  setFormsGalpao() {
    this.form2 = new FormGroup({
      cpf: new FormControl(''),
      email: new FormControl('', [Validators.required]),
      login: new FormControl('', [Validators.required]),
      nascimento: new FormControl('', [Validators.required]),
      nome: new FormControl('', [Validators.required]),
      sobrenome: new FormControl('', [Validators.required]),
      cargo: new FormControl({value: '', disabled: true}),
      tipoUsuario: new FormControl({value: '', disabled: true}),
      receberEmailsInformativos: new FormControl('')
    })
  }

  getAdministrador() {
    if (!this.session.user?.id) return;


    this.service.findOne(Number(this.session.user.id), 'meuperfil').subscribe((resp) => {
      this.entity = {
        ...resp.data
      };

      this.form2.patchValue({
        ...this.entity
      })
    });
  }

  getParceiro() {
    if (!this.session.user?.id) return;

    this.service.findOne(Number(this.session.user.id), 'meuperfil').subscribe((resp) => {
      this.entity = {
        ...resp.data
      };

      this.form2.patchValue({
        ...this.entity
      })
    });
  }

  getCondomínio() {
    if (!this.session.user?.id) return;

    this.service.findOne(Number(this.session.user.id), 'meuperfil').subscribe((resp) => {
      this.entity = {
       ...resp.data
      };

      this.form2.patchValue({
        ...this.entity,
      })
    });
  }

  getGalpao() {
    this.service.findOne(Number(this.session.user.id), 'meuperfil').subscribe(resp => {
      this.entity = {
        ...resp.data
      }
      this.form2.patchValue({
        ...this.entity
      })
    })
  }

  save() {
    if (this.form2.invalid) {
      this.form2.markAllAsTouched();
      return;
    }

    const {
      cnpj,
      ...form
    } = this.form2.value;

    this.entity = {
      ...this.entity,
      ...form,
      ...cnpj
    }

    this.service.update(this.entity, 'meuperfil').subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Salvo com Sucesso!'
        }).then()
      }
    );
  }

  alterarSenha() {
    window.dispatchEvent(new CustomEvent('open-alter-password'));
  }

  verifyDate(ev: any) {
    let ano = ev.target.value.slice(0, 4);
    let anoAtual = new Date

    if ((anoAtual.getFullYear() - ano) < 18) {
      Swal.fire({
        title: 'Funcionário menor de idade, por favor altere a data de nascimento. ',
        icon: 'error',
        confirmButtonColor: 'green'
      }).then(confirm => {
        if(confirm.isConfirmed){
          this.form2.controls['nascimento'].reset();
        }
      })
    }
  }

  getTermo() {
    // this.service.findAll({options:{endpoint:'adesao'}}).subscribe(resp => {
    this.service.get('users/meuperfil/adesao').subscribe(resp => {
      if (!!resp) {
        //@ts-ignore
        this.base64ToPDF(resp.data.file)
      }
    })
  }

  base64ToPDF(data: any) {

    const byteArray = new Uint8Array(
      atob(data)
        .split("")
        .map(char => char.charCodeAt(0))
    );
    const file = new Blob([byteArray], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    let pdfName = "termo_adesao.pdf";
    window.open(fileURL, '_blank');

  }
}
