import {Component, HostListener, Input, OnInit} from '@angular/core';
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {InterfMenuList} from "../../../../../core/config/menu/admin.menuList";
import {ThemeManageService} from "../../../../../services/theme-manage/theme-manage.service";
import {ArquivoEntity} from "../../../../../shared/entitys/arquivo.entity";

@Component({
  selector: 'subcomponent-fragment-side-menu',
  templateUrl: './fragment-side-menu.component.html',
  styleUrls: ['./fragment-side-menu.component.scss']
})
export class FragmentSideMenuComponent implements OnInit {

  navigateMenu: InterfMenuList[] = []

  @Input() image: ArquivoEntity | undefined

  constructor(
    private session: SessionService,
    public themeService: ThemeManageService
  ) {
    this._menu();
  }

  ngOnInit(): void {
    let root = document.documentElement;
    // root.style.setProperty('--bs-background-menu-preview', 'blue');
    // root.style.setProperty('--bs-background-menu-text-preview', 'blue');
    // root.style.setProperty('--bs-background-menu-item-hover-preview', 'blue');
  }

  private _menu() {
    let navigationMenu = this.session.menu.get();
    if (!navigationMenu) {
      return;
    }

    this.navigateMenu = navigationMenu;
  }

  @HostListener('window:change-preview-menu')
  changePreviewMenu() {
    alert()
  }
}
