import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { MeuPerfilService } from 'src/app/pages/_user/meu-perfil/service/meu-perfil.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'subComponent-alter-password',
  templateUrl: './alter-password.component.html',
  styleUrls: ['./alter-password.component.scss']
})
export class AlterPasswordComponent implements OnInit {

  @ViewChild('modalAlterPassword') modalAlterPassword!: OldModalComponent

  entity!: { id: number, senhaAntiga: string, senhaNova: string, senhaNovaConfirmar: string };

  oldViewPass: boolean = false;
  viewPass: boolean = false;
  confirmViewPass: boolean = false;


  form = new FormGroup({
    senhaAntiga: new FormControl(''),
    senhaNova: new FormControl(''),
    senhaNovaConfirmar: new FormControl('')
  })

  constructor(
    private session: SessionService,
    protected service: HttpOldService
  ) { }

  ngOnInit(): void {
  }

  @HostListener('window:open-alter-password')
  openAlterarSenha() {
    this.modalAlterPassword.open()
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    const formV = this.form.value
    this.entity = {
      idUser: this.session.user.id,
      ...formV
    }
    this.service.patch('users', this.entity).subscribe(resp => {
      if (resp) {
        Swal.fire({
          text: 'Senha Alterada com sucesso',
          icon: 'success',
          timer: 5000,
          showConfirmButton: false
        })
        this.modalAlterPassword.close();
      }
    });
    return true
  }

  beforeClose = () => {
    this.form.reset({
      senhaAntiga: '',
      senhaNova: '',
      senhaNovaConfirmar: '',
    });
  }

}
