import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { PerguntasEntity } from '../perguntas.entity';

@Injectable({
  providedIn: 'root'
})
export class ClientesPerguntasService extends AbstractHttpService<PerguntasEntity> {

  constructor(
    public conf: ConfigDgCrudService,
  ) { 
    super(conf, environment.apiUrl, 'pergunta-respostas');
  }

  uploadImage(data: any){
    return this.patch('pergunta-respostas/foto', data)
  }

}
