

<div class="folhas">
  <div class="card-body" style="padding: 0">
    <crud-data-table
      #table
      [entityMap]="mapEntity"
      [service]="service"
      [showPagination]="false"
      [showDefaultAction]="false"
      [customDblclick]="true"
      [persistentParams]="{unpaged: true}"
      [resourceDictionary]="startPrint"
      sort="nomeCliente"
    ></crud-data-table>
  </div>
</div>
