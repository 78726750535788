<div id="galpao-motorista">


  <form [formGroup]="form">


    <div class="container-data">
      <div class="row">
        <div class="col-md-4 sm-2 xs-12">
          <ng-container *ngTemplateOutlet="cardUploadImage"></ng-container>
        </div>
        <div class="col-md-8 sm-10 xs-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Dados Motorista/Catador</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    nome
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    id="nome"
                    (focusout)="createLogin()"
                    class="form-control"
                    formControlName="nome"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    sobrenome
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    id="sobrenome"
                    (focusout)="createLogin()"
                    class="form-control"
                    formControlName="sobrenome"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    email
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    id="email"
                    class="form-control"
                    formControlName="email"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    login
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    id="login"
                    class="form-control"
                    formControlName="login"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>telefone (DDD)</label>
                  <input
                    type="text"
                    placeholder="(00) 0000-0000"
                    mask="(00) 0000-0000"
                    class="form-control"
                    formControlName="telefone"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    celular (DDD)
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    placeholder="(00) 00000-0000"
                    mask="(00) 00000-0000"
                    class="form-control"
                    formControlName="celular"
                  >
                </div>
              </div>


              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>rg</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="identidade"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>cpf</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="172.416.741-93"
                    mask="000.000.000-00"
                    formControlName="cpf"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    nome do transportador
                    <small class="fc-red">*</small>
                  </label>
                  <input-autocomplete
                    id="galpao"
                    formControlName="galpao"
                    classes="form-control"
                    [list]="listaNomeGalpao"
                    key="id"
                    label="nome"
                  ></input-autocomplete>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    cnpj
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="87.145.992/0001-23"
                    mask="00.000.000/0000-00"
                    formControlName="cnpj"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>
                    ajudante 1
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="ajudanteUm"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>ajudante 2</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="ajudanteDois"
                  >
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <label>habilitação</label>
                  <div class="input-group">
                    <label
                      type="text"
                      class="form-control"
                    >
                      {{nomeCnh}}
                    </label>
                    <!-- formControlName="imagemHabilitacao"> -->
                    <span class="input-group-addon">
                                            <button
                                              *ngIf="entity?.imagemHabilitacao?.id"
                                              [disabled]="!entity?.id"
                                              class="btn btn-primary"
                                              style="border-left: none;"
                                              (click)="downloadCnh()"
                                            >
                                                <mat-icon>download</mat-icon>
                                            </button>
                                            <button
                                              type="button"
                                              [disabled]="!entity?.id"
                                              class="btn btn-outline-secondary"
                                              style="border-left: none;"
                                              (click)="cnhMotorista.click()"
                                            >
                                                <mat-icon>attach_file</mat-icon>
                                            </button>
                                        </span>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="container-data">
      <div class="row pt-3">
        <div class="col-12">
          <card>
            <div class="card-header row w-100">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title">Endereço</h4>
                  </div>
                  <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <button
                      class="btn btn-success btn-sm"
                      (click)="addCepManual()"
                    >
                      Adicionar dados manualmente
                    </button>
                  </card-header-buttons>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <label>
                    cep
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    id="cep"
                    maxlength="9"
                    class="form-control"
                    placeholder="22780-792"
                    mask="00000-000"
                    formControlName="cep"
                  >
                </div>
                <div class="col-md-8 col-sm-8 col-xs-12">
                  <label>
                    logradouro
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="logradouro"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 col-sm-4 col-xs-6">
                  <label>
                    número
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="numero"
                  >
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6">
                  <label>complemento</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="complemento"
                  >
                </div>
                <div class="col-md-4 col-sm-4 col-xs-6">
                  <label>
                    bairro
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="bairro"
                  >
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6">
                  <label>
                    cidade
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="cidade"
                  >
                </div>
                <div class="col-md-2 col-sm-4 col-xs-6">
                  <label>
                    uf
                    <small class="fc-red">*</small>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    maxlength="2"
                    formControlName="uf"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </form>


  <div
    *ngIf="!!paramsTables?.motoristaId"
    class="container-data"
  >
    <div class="row pt-3">
      <card>
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="card-title">Veículos</h4>
              </div>
              <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <button
                  (click)="modalVeiculos.open({motorista: {id: entity.id}})"
                  class="btn btn-success btn-card-header"
                >
                  <mat-icon>add</mat-icon>
                  adicionar
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>
        <div class="card-body">
          <crud-data-table
            #tableVeiculo
            [entityMap]="mapVeiculoEntity"
            [persistentParams]="paramsTables"
            tableEventId="GalpaoMotoristaVeiculoEntity"
          >
          </crud-data-table>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="mapVeiculoEntity.configEntityTable.pagination"
              (changePage)="tableVeiculo.changePages($event)"
            ></dg-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>


  <div
    *ngIf="!!paramsTables?.motoristaId"
    class="container-data"
  >
    <div class="row pt-3">
      <card>
        <div class="card-header">
          <div class="container-fluid">
            <div>
              <div class="col-6">
                <h4 class="card-title">Documentos</h4>
              </div>
              <card-header-buttons class="col-6">
                <button
                  (click)="modalDocumentoMotorista.open()"
                  class="btn btn-success btn-card-header"
                >
                  <mat-icon>add</mat-icon>
                  adicionar
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>
        <div class="card-body">
          <crud-data-table
            #tableDocumentos
            [entityMap]="mapDocumentosEntity"
            [persistentParams]="paramsTables"
            tableEventId="GalpaoArquivoMotoristaEntity"
          >
          </crud-data-table>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="mapDocumentosEntity.configEntityTable.pagination"
              (changePage)="tableDocumentos.changePages($event)"
            ></dg-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
</page-footer>


<subcomponent-motorista-veiculo #modalVeiculos></subcomponent-motorista-veiculo>


<subComponent-motorista-documento
  #modalDocumentoMotorista
  [motoristaId]="entity?.id"
>
</subComponent-motorista-documento>


<ng-template #cardUploadImage>
  <card>
    <div class="card-header">
      <h4 class="card-title">Foto do Motorista/Catador</h4>
    </div>
    <div class="col-md-12 sm-12 xs-12 border-img">
      <div
        class="form-group"
        style="padding-top: 2.5em"
      >
        <img
          src="{{getImageToSrc(entity?.imagem)}}"
          alt
          class="w-100"
        >
      </div>
      <hr>
    </div>
    <div class="btn-img">
      <button
        type="button"
        [disabled]="!entity?.id"
        class="btn btn-success"
        (click)="imgMotorista.click()"
      >
        Escolher
      </button>
      <input
        #imgMotorista
        type="file"
        [(ngModel)]="file"
        (change)="uploadImg($event, true)"
        name="imgMotorista"
        id="imgMotorista"
        accept=".jpg"
        style="display: none;"
      >
    </div>
  </card>
</ng-template>


<input
  #cnhMotorista
  type="file"
  [(ngModel)]="fileCnh"
  (change)="uploadImg($event, false)"
  name="imgMotorista"
  id="cnhMotorista"
  accept=".jpg"
  style="display: none;"
>
