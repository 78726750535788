import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  DashboardColetasImpressaoComponent
} from "./dashboard-impressao/dashboard-coletas-impressao/dashboard-coletas-impressao.component";
import {RankingLixoZeroComponent} from "./dashboard-impressao/ranking-lixo-zero/ranking-lixo-zero.component";
import {
  RankingTaxaReciclagemComponent
} from "./dashboard-impressao/ranking-taxa-reciclagem/ranking-taxa-reciclagem.component";

const routes: Routes = [
  {path: 'dadhboard/coletas', component: DashboardColetasImpressaoComponent},
  {path: 'dadhboard/ranking/lixo-zero', component: RankingLixoZeroComponent},
  {path: 'dadhboard/ranking/taxa-reciclagem', component: RankingTaxaReciclagemComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintRoutingModule {
}
