import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroGravimetriaMainComponent } from './cadastro-gravimetria-main/cadastro-gravimetria-main.component';
import { CadastroGravimetriaInsertEditComponent } from './cadastro-gravimetria-insert-edit/cadastro-gravimetria-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";


@NgModule({
  declarations: [
    CadastroGravimetriaMainComponent,
    CadastroGravimetriaInsertEditComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        DgCrudModule,
        ReactiveFormsModule,
        FormsModule,
        AngularEditorModule,
        MatButtonModule,
        MatMenuModule,
        DirectivesModule,
        DgAutocompleteModule
    ]
})
export class CadastroGravimetriaModule { }
