<div id="produto-insert-edit">

  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Informações</h4>
              <hr>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <label for="nome" class="form-label">NOME <span class="fc-red">*</span></label>
                  <input type="text" formControlName="nome" class="form-control" id="nome">
                </div>


          </div>
          </div>


      </card>
     </div>
    </div>
 </div>

</form>
</div>


<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-deletar  class="btn btn-secondary">Excluir</button>
</page-footer>



