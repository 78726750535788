import {AbstractEntity, DataServer, DgFilter_autoComplete, DgTableColumn} from "@datagrupo/dg-crud";
import {GalpaoMotoristaEntity} from "../galpao-motorista/galpao-motorista.entity";
import {CategoriaArquivoEntity} from "../../categoria-arquivo/categoria-arquivo.entity";
import {environment} from "../../../../../environments/environment";
import {motoristaAutocompleteOutput} from "../../../../helpers/auto-complete/motorista-autocomplete.helper";
import {ARQUIVO_MOTORISTA} from "../../../../core/config/endpoint-list";

@DataServer({
  path: environment.apiUrl,
  context: ARQUIVO_MOTORISTA
})
export class GalpaoArquivoMotoristaEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    motorista?: GalpaoMotoristaEntity,
    arquivo?: string,
    categoria?: CategoriaArquivoEntity
  ) {
    super();
    this.id = id;
    this.motorista = motorista;
    this.arquivo = arquivo;
    this.categoria = categoria;
  }

  @DgFilter_autoComplete(
    {name: 'Nome do Motorista/Catador'},
    {
      getServerData: 'all',
      getUrl: environment.apiUrl+'motoristas',
      propertValue: 'nome',
      propertKey: 'id',
      nameParamFind: 'motoristaId',
      nameParamFilter: 'nome',
      outputFormat: motoristaAutocompleteOutput
    }

  )
  @DgTableColumn({
    columnName: 'MOTORISTA/CATADOR', resource: (moto: GalpaoMotoristaEntity) => {
      return !!moto.nome ? moto.nome : '--';
    },
    sort: true
  })
  public motorista: GalpaoMotoristaEntity | undefined;
  @DgTableColumn({
    columnName: 'NOME DO ARQUIVO', resource: (file: any) => {
      return file.fileNameOriginal;
    },
    sort: true
  })
  public arquivo: string | undefined;
  @DgTableColumn({
    columnName: 'CATEGORIA',
    resource: (categ: CategoriaArquivoEntity) => !!categ?.nome ? categ.nome : '--',
    sort: true
  })
  public categoria: CategoriaArquivoEntity | undefined;
}



