<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header w-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h4 class="card-title">Relação contrato</h4>
            </div>
            <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <button
                *ngIf="verify.permissionAddContainer()"
                (click)="modalRelacaoContrato.open()"
                class="btn btn-success btn-card-header"
              >
                <mat-icon>add</mat-icon>
                Cadastrar
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>

      <div class="card-subtitle">
        <div class="row">
          <filter-search2 dynamicGroupName="cliente-relacao-contrato">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <label class="form-label">Status</label>
                <select
                  class="form-select"
                  [ngModel]="filterInputs.relacaoContrato.isAtivo"
                  dg-dynamic-group="cliente-relacao-contrato"
                  paramName="isAtivo"
                >
                  <option [value]="true">Ativos</option>
                  <option [value]="false">Inativos</option>
                  <option value="">Ambos</option>
                </select>
              </div>
            </div>
          </filter-search2>
        </div>
      </div>

      <div class="card-body">
        <dg-table dg-dynamic-table [dynamicTable]="tableRelacaoContrato" classes="default-table"></dg-table>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableRelacaoContrato"></dg-paginator>
      </div>
    </card>
  </div>
</div>

<div class="row mt-3" *ngIf="!!entity">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header">
        <h4 class="card-title">Relação de Contrato Exclusiva</h4>
      </div>
      <form [formGroup]="form">
        <div class="card-body">
          <div
            class="row mb-4"
            *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
          >
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <label class="form-label">Transportador</label>
              <default-autocomplete-input
                label="nome"
                key="id"
                formControlName="galpao"
                [data]="listGalpao"
                [showSelectedList]="true"
                [showSelectedOption]="false"
              ></default-autocomplete-input>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
              style="padding-top: 2.1em;"
            >
              <input
                type="checkbox"
                class="form-check-input"
                name="exclusivo"
                id="exclusivo"
                formControlName="exclusivo"
              />
              <label class="form-label ms-2" for="exclusivo">
                O CLIENTE É EXCLUSÍVO DO TRANSPORTADOR?
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
              <label class="form-label w-100" [class.fc-red]="checkedDiasColetas && entity.diasColeta.length == 0">
                DIAS DE COLETA NO CLIENTE
                <span class="fc-red">*</span>
              </label>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('SEGUNDA')"
                  [checked]="verifyCheckBox('SEGUNDA')"
                  type="checkbox"
                  id="diaColeta-Segunda"
                />
                <label class="form-check-label" for="diaColeta-Segunda">
                  Segunda
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('TERCA')"
                  [checked]="verifyCheckBox('TERCA')"
                  type="checkbox"
                  id="diaColeta-Terça"
                />
                <label class="form-check-label" for="diaColeta-Terça">
                  Terça
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('QUARTA')"
                  [checked]="verifyCheckBox('QUARTA')"
                  type="checkbox"
                  id="diaColeta-Quarta"
                />
                <label class="form-check-label" for="diaColeta-Quarta">
                  Quarta
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('QUINTA')"
                  [checked]="verifyCheckBox('QUINTA')"
                  type="checkbox"
                  id="diaColeta-Quinta"
                />
                <label class="form-check-label" for="diaColeta-Quinta">
                  Quinta
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('SEXTA')"
                  [checked]="verifyCheckBox('SEXTA')"
                  type="checkbox"
                  id="diaColeta-Sexta"
                />
                <label class="form-check-label" for="diaColeta-Sexta">
                  Sexta
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('SABADO')"
                  [checked]="verifyCheckBox('SABADO')"
                  type="checkbox"
                  id="diaColeta-Sábado"
                />
                <label class="form-check-label" for="diaColeta-Sábado">
                  Sábado
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('PONTUAL')"
                  [checked]="verifyCheckBox('PONTUAL')"
                  type="checkbox"
                  id="diaColeta-Pontual"
                />
                <label class="form-check-label" for="diaColeta-Pontual">
                  Pontual
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  (click)="editDiasSemana('QUINZENAL')"
                  [checked]="verifyCheckBox('QUINZENAL')"
                  type="checkbox"
                  id="diaColeta-QUINZENAL"
                />
                <label class="form-check-label" for="diaColeta-QUINZENAL">
                  Quinzenal
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </card>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <planos-clientes-table [id]="clienteId"></planos-clientes-table>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <planos-pontual-clientes-table [id]="clienteId"></planos-pontual-clientes-table>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO']) "
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <h4 class="card-title">Missões aceitas pelo gerador</h4>
        </div>
      </div>
      <div class="card-body">
        <dg-table dg-dynamic-table [dynamicTable]="tableMissoesAceitas" classes="default-table"></dg-table>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableMissoesAceitas"></dg-paginator>
      </div>
    </card>
  </div>
</div>


<modal-cliente-relacao-contrato
  #modalRelacaoContrato
  [idCliente]="clienteId"
  (afterSave)="tableRelacaoContrato.find()"
></modal-cliente-relacao-contrato>

