import { environment } from '../../../../../../environments/environment';
import { ConfigDgCrudService } from '../../../../../core/config/config-dg-crud/config-dg-crud.service';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { GalpaoArquivoEntity } from '../galpao-arquivo.entity';

@Injectable({
  providedIn: 'root'
})
export class GalpaoArquivoService extends AbstractHttpService<GalpaoArquivoEntity> {

  constructor(
    public conf: ConfigDgCrudService
  ) {
   super(conf, environment.apiUrl, 'galpoes/documentogalpao')
  }

  download(id: number) {
    return this.findOne(Number(id), 'download');
  }
}
