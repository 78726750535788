import {InterfMenuList} from '../../../core/config/menu/admin.menuList';
import {Component, OnInit} from '@angular/core';
import {adminMenuList} from '../../../core/config/menu/admin.menuList';
import {Router} from '@angular/router';
import {
  CondominioMenuList,
  CondominioMorador2MenuList,
  CondominioMoradorMenuList
} from '../../../core/config/menu/condominio.menuList';
import {SessionService} from '../../../core/services/session-service/session.service';
import {TokenService} from '../../../core/services/token-service/token.service';
import {ClientesService} from '../../_user/_clientes/clientes/service/clientes.service';
import {galpaoMenuList} from 'src/app/core/config/menu/galpao.menuList';
import {GalpaoService} from '../../_user/_galpao/galpao/services/galpao-service/galpao.service';
import {MeuPerfilService} from '../../_user/meu-perfil/service/meu-perfil.service';
import {CasaMenuList} from 'src/app/core/config/menu/casa.menuList';
import {LogisticaReversaMenuList} from "../../../core/config/menu/logistica-reversa.menuList";
import {
  parceiroMenuCLR,
  parceiroMenuComunicacao,
  parceiroMenuFinanceiro, parceiroMenuJornadaAmbiental,
  parceiroMenuList
} from 'src/app/core/config/menu/parceiro.menu.list';
import {ThemeManageService} from "../../../services/theme-manage/theme-manage.service";
import {WhiteLabelEntity} from "../../_user/white-label/white-label.entity";
import {HttpService} from "../../../services/http-service/http.service";

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss'],
})
export class StartSessionComponent implements OnInit {

  textStatus: string = 'Buscando dados iniciais';

  constructor(
    private router: Router,
    private session: SessionService,
    private token: TokenService,
    private clientesService: ClientesService,
    private galpaoService: GalpaoService,
    public meuPerfilService: MeuPerfilService,
    private themeService: ThemeManageService,
    private http: HttpService
  ) {
    if (!this.token.abrirToken().perfil) {
      session.logout();
    }
  }

  ngOnInit(): void {
    if (!this.session.user.id) {
      this.session.logout();
      return;
    }

    this.meuPerfilService
      .findOne(this.session.user.id, 'username')
      .subscribe(
        resp => {
          if (resp.data?.whiteLabel) {
            const whiteLabel = <WhiteLabelEntity>resp.data.whiteLabel;

            if (whiteLabel?.properties) {
              this.textStatus = 'Importando estilos'
              this.themeService.saveProperties(whiteLabel.properties);
              this.themeService.setAllTheme()
            }

            if (whiteLabel?.arquivo) {
              this.themeService.saveLogo(whiteLabel.arquivo)
              window.localStorage.setItem('themeLogo', JSON.stringify(whiteLabel.arquivo))
            }
          }

          this.getUser()
        },
        () => {
          this.getUser()
        }
      )
  }

  oldVersion() {
    if (!this.session.user.id) {
      this.session.logout();
      return;
    }
    this.meuPerfilService
      .findOne(this.session.user.id, 'username')
      .subscribe(
        (resp) => {
          const menuList: InterfMenuList = {
            hrStart: true,
            hrEnd: true,
            menuList: [
              {
                //@ts-ignore
                nome: resp.data.nome,
                icon: 'settings',
                subMenu: [
                  {
                    nome: 'Meu Perfil',
                    icon: 'settings',
                    url: '/user/meu-perfil',
                  },
                  {nome: 'Alterar senha', icon: 'settings', event: 'open-alter-password'},
                ],
              },
            ],
          };
          this.session.hasRespostas = !!resp.data.hasResposta;
          window.localStorage.setItem('hasRespostas', String(!!resp.data.hasResposta))

          if (resp.data?.whiteLabel) {
            const whiteLabel = <WhiteLabelEntity>resp.data.whiteLabel;

            if (whiteLabel?.properties) {
              this.textStatus = 'Importando estilos'
              this.themeService.saveProperties(whiteLabel.properties);
              this.themeService.setAllTheme()
            }

            if (whiteLabel?.arquivo) {
              this.themeService.saveLogo(whiteLabel.arquivo)
              window.localStorage.setItem('themeLogo', JSON.stringify(whiteLabel.arquivo))
            }
          }

          //@ts-ignore
          this.startSession(this.token.abrirToken().perfil, menuList);
        },
        (error) => {
          const menuList: InterfMenuList = {
            menuList: [
              {
                nome: 'Usuário',
                icon: 'settings',
                subMenu: [
                  {
                    nome: 'Meu Perfil',
                    icon: 'settings',
                    url: '/user/meu-perfil',
                  },
                  {nome: 'Alterar senha', icon: 'settings', event: 'open-alter-password'},
                ],
              },
            ],
          };
          //@ts-ignore
          this.startSession(this.token.abrirToken().perfil, menuList);
        }
      );
  }

  startSession(
    perfil: 'CONDOMINIO' | 'ADMINISTRADOR' | 'GALPAO' | 'MOTORISTA' | 'ADMINISTRATIVO'
      | 'EMPRESA' | 'EVENTO' | 'CASA' | 'LOGISTICA-REVERSA' | 'PARCEIRO',
    menuList: InterfMenuList
  ) {
    this.session.setUser();
    this.textStatus = 'Montando o menu'

    switch (perfil) {
      case 'ADMINISTRADOR':
        this.session.menu.set([menuList, ...adminMenuList]);
        this.router.navigate(['/user']);
        break;

      case 'PARCEIRO':
        parceiroMenuList[0].menuList[2].url = parceiroMenuList[0].menuList[2].url + '/' + this.session.user.parceiro_id
        if (this.session.checkProfile('HOLDING')) {
          parceiroMenuList[0].menuList.push({url: '/user/white-label', nome: 'White Label', icon: 'panorama'})
        }

        const menu: InterfMenuList[] = [
          menuList,
          ...parceiroMenuList,
        ]

        if (this.session.checkModules('FINANCEIRO')) menu.push(parceiroMenuFinanceiro);
        if (this.session.checkModules('COMUNICACAO')) menu.push(parceiroMenuComunicacao);
        if (this.session.checkModules('EDUCACAO_AMBIENTAL')) menu.push(parceiroMenuJornadaAmbiental);
        if (this.session.checkModules('LOGISTICA_REVERSA')) menu.push(parceiroMenuCLR);

        this.session.menu.set(menu);
        this.router.navigate(['/user']).then();
        break;
      case 'ADMINISTRATIVO':
        this.session.menu.set([menuList, ...adminMenuList]);
        this.router.navigate(['/user']);
        break;
      case 'CONDOMINIO':
        //@ts-ignore
        CondominioMenuList[0].menuList[2].url = '/user/clientes/clientes/' + this.session.user.cliente_id;

        if (this.session.checkProfile('MORADOR')) {
          this.session.menu.set([menuList, ...CondominioMoradorMenuList]);
        } else if (this.session.checkProfile('USUARIO_CONSULTA')) {
          CondominioMorador2MenuList[0].menuList[1].url = '/user/clientes/clientes/' + this.session.user.cliente_id;
          this.session.menu.set([menuList, ...CondominioMorador2MenuList]);
        }else {
          this.session.menu.set([menuList, ...CondominioMenuList]);
        }


        this.clientesService.getPontuacao().subscribe((resp) => {
          //@ts-ignore
          const pontuacao = <number>resp.data;

          this.session.pontuacao = pontuacao;
          window.localStorage.setItem('pontuacao', String(pontuacao));

          this.router.navigate(['/user']).then();
        });
        break;
      case 'MOTORISTA':
        this.session.menu.set([menuList, ...[]]);
        //        this.startGalpao();
        this.router.navigate(['/user']);

        break;
      case 'GALPAO':
        galpaoMenuList[0].menuList[1].url = '/user/galpao/galpao/' + this.session.user.galpao_id;

        this.session.menu.set([menuList, ...galpaoMenuList]);
        this.startGalpao();

        break;
      case 'EMPRESA':
        this.session.menu.set([menuList, ...CondominioMenuList]);
        this.clientesService.getPontuacao().subscribe((resp) => {
          //@ts-ignore
          const pontuacao = <number>resp.data;

          this.session.pontuacao = pontuacao;
          window.localStorage.setItem('pontuacao', String(pontuacao));

          this.router.navigate(['/user']);
        })
        break;
      case 'EVENTO':
        this.session.menu.set([menuList, ...CondominioMenuList]);
        this.router.navigate(['/user']);
        break;
      case 'CASA':
        this.session.menu.set([menuList, ...CasaMenuList]);
        this.clientesService.getPontuacao().subscribe((resp) => {
          //@ts-ignore
          const pontuacao = <number>resp.data;

          this.session.pontuacao = pontuacao;
          window.localStorage.setItem('pontuacao', String(pontuacao));

          this.router.navigate(['/user']);
        })
        break
      case 'LOGISTICA-REVERSA':
        this.session.menu.set([menuList, ...LogisticaReversaMenuList]);
        this.router.navigate(['/user']).then();
        break
      default:
        console.error("Não fpoi possível encontrar o perfio " + perfil)
        // this.session.logout();
        break;
    }
  }

  /**
   * TODO versão antiga, revisar
   */
  startGalpao() {
    // this.router.navigate([`/user/galpao/galpao/${this.session.user.galpao_id}`]);
    // return;
    this.galpaoService
      .findOne(String(this.session.user.galpao_id), '')
      .subscribe((resp) => {
        window.localStorage.setItem('galpao_nome', resp.data.nome)
        this.session.setUser();
        this.router.navigate([`/user/galpao/galpao/${this.session.user.galpao_id}`]);
      });
  }

  startAdmin() {
  //   minha logica aqui
  }

  startGerador() {

  }

  getUser() {
    let menuList: InterfMenuList = {
      menuList: [
        {
          nome: 'Usuário',
          icon: 'settings',
          subMenu: [
            {
              nome: 'Meu Perfil',
              icon: 'settings',
              url: '/user/meu-perfil',
            },
            {nome: 'Alterar senha', icon: 'settings', event: 'open-alter-password'},
          ],
        },
      ],
    };

    this.http.get('users/' + this.session.user.id, { headers: {noteCallbackMessage: '1'} }).subscribe(
      (resp) => {
        menuList = {
          hrStart: true,
          hrEnd: true,
          menuList: [
            {
              //@ts-ignore
              nome: resp.data.nome,
              icon: 'settings',
              subMenu: [
                {
                  nome: 'Meu Perfil',
                  icon: 'settings',
                  url: '/user/meu-perfil',
                },
                {nome: 'Alterar senha', icon: 'settings', event: 'open-alter-password'},
              ],
            },
          ],
        };
        this.session.hasRespostas = !!resp.data.hasResposta;
        window.localStorage.setItem('hasRespostas', String(!!resp.data.hasResposta))
      },
      (error) => {
        //@ts-ignore
        this.startSession(this.token.abrirToken().perfil, menuList);
      },
      () => {
        //@ts-ignore
        this.startSession(this.token.abrirToken().perfil, menuList);
      }
    )
  }
}
