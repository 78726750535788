<old-dg-modal id="modal-cliente-container" #modal size="lg" [afterClose]="beforeClose" title="Relação Contrato">
  <div dg-content>

    <div>
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="tableVisible = 'relacao-contrato'">
          <h4
            class="nav-link card-title" [class.active]="tableVisible == 'relacao-contrato'"
            [class.fc-red]="(form.invalid && form.touched)"
          >Relação de contrato</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'integracao-mtr'">
          <h4
            class="nav-link card-title" [class.active]="tableVisible == 'integracao-mtr'"
            [class.fc-red]="(formMTR.invalid && formMTR.touched)"
          >Integração MTR</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'precificacao'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'precificacao'">Detalhes de precificação.</h4>
        </li>
      </ul>
    </div>


    <form [formGroup]="form">
      <div [class.d-none]="tableVisible != 'relacao-contrato'">
        <div class="row mt-2 mb-2">
          <div class="col-12 d-inline-flex">
            <span>Marcar armazenador temporário de resíduos?</span>
            <div class="form-check" style="margin-left: .4rem">
              <input
                class="form-check-input" formControlName="usarArmazenadorTemporario" type="radio"
                name="armazenadorTemporario" id="armazenadorTemporario_sim" [value]="true"
              >
              <label class="form-check-label" for="armazenadorTemporario_sim">
                Sim
              </label>
            </div>
            <div class="form-check" style="margin-left: .4rem">
              <input
                class="form-check-input" formControlName="usarArmazenadorTemporario" type="radio"
                name="armazenadorTemporario" id="armazenadorTemporario_nao" [value]="false"
              >
              <label class="form-check-label" for="armazenadorTemporario_nao">
                Não
              </label>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="!!form.value.usarArmazenadorTemporario">
            <label class="form-label">Indústria de destino (Destinador temporário) <span class="fc-red">*</span></label>
            <input-auto-complete
              propertValue="nome"
              controlName="armazenadorTemporario"
              urlServer="industria/nomes"
              [params]="{sort: 'nome,asc'}"
              [baseValue]="entity?.industria || undefined"
              (outValue)="entity.industria = $event"
            ></input-auto-complete>
          </div>
          <div class="col-12">
            <hr>
          </div>
        </div>

        <div class="row" *ngIf="modal.config.open">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Descrição do cliente (nome do resíduo) <span class="fc-red">*</span></label>
            <input class="form-control" formControlName="identificacao">
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Transportador <span class="fc-red">*</span></label>
            <input-auto-complete
              propertValue="nome"
              controlName="galpao"
              urlServer="galpoes/nomes"
              [baseValue]="entity?.galpao || undefined"
              (outValue)="buscarRelacoes($event)"
              (change)="buscarRelacoes($event)"
            ></input-auto-complete>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Acondicionamento <span class="fc-red">*</span></label>
            <select class="form-select" formControlName="container" *ngIf="!disabled">
              <option disabled value="">Selecione um Recipiente</option>
              <option *ngFor="let container of listContainers" value="{{container.id}}">{{container.nome}}</option>
            </select>
            <input class="form-control" formControlName="container" *ngIf="disabled">
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Tipo de Resíduo <span class="fc-red">*</span></label>
            <select class="form-select" formControlName="tipoLixo" *ngIf="!disabled">
              <option disabled value="">Selecione um Tipo de Resíduo</option>
              <option *ngFor="let lixo of listTipoLixo" value="{{lixo.id}}">Nome: {{lixo.nome}} |
                Categoria: {{lixo.categoria || '--'}}</option>
            </select>
            <input class="form-control" formControlName="tipoLixo" *ngIf="disabled">
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Tratamento <span class="fc-red">*</span></label>
            <select class="form-select" formControlName="tratamento" *ngIf="!disabled">
              <option disabled value="">Selecione um Tipo de tratamento</option>
              <option *ngFor="let trat of listTratamento" value="{{trat.id}}">{{trat.nome}}</option>
            </select>
            <input class="form-control" formControlName="tratamento" *ngIf="disabled">
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Unidade de Medida - padrão da coleta <span class="fc-red">*</span></label>
            <select class="form-select" formControlName="unidadeMedidaPadrao" *ngIf="!disabled">
              <option disabled value="">Selecione uma Unidade de Medida</option>
              <option *ngFor="let unidadeM of listUnidadeMedida" value="{{unidadeM.id}}">{{unidadeM.nome}}</option>
            </select>
            <input class="form-control" formControlName="unidadeMedidaPadrao" *ngIf="disabled">
          </div>
        </div>

        <div class="row">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Quantidade de Coletas por Mês <span class="fc-red">*</span></label>
            <input type="number" class="form-control" formControlName="quantidade">
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Indústria de destino (Destinador Final) <span class="fc-red">*</span></label>
            <input-auto-complete
              propertValue="nome"
              controlName="industria"
              urlServer="industria/nomes"
              [params]="{sort: 'nome,asc'}"
              [baseValue]="entity?.industria || undefined"
              (outValue)="entity.industria = $event"
            ></input-auto-complete>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label style="margin-bottom: .2rem" class="form-label">Unidade de Medida - possíveis da coleta <span
              class="fc-red">*</span></label>
            <div class="w-100" *ngIf="!disabled">
              <mat-form-field
                appearance="outline"
                class="w-100"
              >
                <mat-select formControlName="listUnidadeMedida" multiple style="width: 100%">
                  <mat-option disabled>Selecione um ou mais tipos de Resíduo</mat-option>
                  <mat-option *ngFor="let unidadeM of listUnidadeMedida"
                              [value]="unidadeM.id">{{unidadeM.nome}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <input class="form-control" formControlName="listUnidadeMedida" *ngIf="disabled">
          </div>
          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
            *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
          >
            <label class="form-label">Dono da relação</label>
            <select formControlName="dono" class="form-select">
              <option disabled value="">Selecione</option>
              <option value="CLIENTE">Gerador</option>
              <option value="GALPAO">Transportador</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="form-label w-100">
              DIAS DE COLETA NO CLIENTE <span class="fc-red">*</span>
            </label>

            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('SEGUNDA')"
                     [checked]="verifyCheckBox('SEGUNDA')" type="checkbox" id="diaColeta-modal-Segunda">
              <label class="form-check-label" for="diaColeta-modal-Segunda">
                Segunda
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('TERCA')"
                     [checked]="verifyCheckBox('TERCA')" type="checkbox" id="diaColeta-modal-Terça">
              <label class="form-check-label" for="diaColeta-modal-Terça">
                Terça
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('QUARTA')"
                     [checked]="verifyCheckBox('QUARTA')" type="checkbox" id="diaColeta-modal-Quarta">
              <label class="form-check-label" for="diaColeta-modal-Quarta">
                Quarta
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('QUINTA')"
                     [checked]="verifyCheckBox('QUINTA')" type="checkbox" id="diaColeta-modal-Quinta">
              <label class="form-check-label" for="diaColeta-modal-Quinta">
                Quinta
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('SEXTA')"
                     [checked]="verifyCheckBox('SEXTA')" type="checkbox" id="diaColeta-modal-Sexta">
              <label class="form-check-label" for="diaColeta-modal-Sexta">
                Sexta
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('SABADO')"
                     [checked]="verifyCheckBox('SABADO')" type="checkbox" id="diaColeta-modal-Sábado">
              <label class="form-check-label" for="diaColeta-modal-Sábado">
                Sábado
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input [disabled]="verifyDisable()" class="form-check-input" (click)="editDiasSemana('PONTUAL')"
                     [checked]="verifyCheckBox('PONTUAL')" type="checkbox" id="diaColeta-modal-Pontual">
              <label class="form-check-label" for="diaColeta-modal-Pontual">
                Pontual
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" (click)="editDiasSemana('QUINZENAL')"
                     [checked]="verifyCheckBox('QUINZENAL')" type="checkbox" id="diaColeta-modal-QUINZENAL">
              <label class="form-check-label" for="diaColeta-modal-QUINZENAL">
                Quinzenal
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="formMTR">
      <div [class.d-none]="tableVisible != 'integracao-mtr'">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Classe <span class="fc-red">*</span></label>
            <input-autocomplete
              classes="form-control"
              formControlName="classeResiduo"
              [list]="(listClasses || [])"
              key="id"
              id="classeResiduo"
              label="nome">
            </input-autocomplete>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Densidade (kg/m³)</label>
            <input
              min="0"
              type="text"
              formControlName="densidade"
              class="form-control"
              id="densidade"
            >
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Estado Fisico <span class="fc-red">*</span></label>
            <input-autocomplete
              classes="form-select"
              formControlName="estadoFisico"
              [list]="(listEstado || [])"
              key="id"
              id="estadoFisico"
              label="nome"
            >
            </input-autocomplete>
          </div>
        </div>
      </div>

      <div [class.d-none]="tableVisible != 'precificacao'">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Custo do Transporte</label>
            <input class="form-control">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Custo da tonelada para destinação</label>
            <input type="number" class="form-control">
          </div>

        </div>
      </div>
    </form>

  </div>

  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
