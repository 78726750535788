import { Injectable } from '@angular/core';
import {InterfMenuList} from "../../config/menu/admin.menuList";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public menuList: InterfMenuList[] = [];

  constructor() { }

  public valid(): boolean {
    if (this.menuList.length >= 0) {
      if (!!window.localStorage.getItem('sidebarMenu')) {
        try {
          this.menuList = JSON.parse(String(window.localStorage.getItem('sidebarMenu')));
        }
        catch (e) {
          return false;
        }
        return true;
      }

      return false;
    }

    return true;
  }

  public get(): InterfMenuList[] {
    if (this.valid()) {
      return this.menuList
    }

    return [];
  }

  public set(menu: InterfMenuList[]): void {
    this.menuList = menu;
    window.localStorage.setItem('sidebarMenu', JSON.stringify(menu));
  }
}
