<div id="certificado-logistica-reversa">

  <div class="container-data">
    <div class="row row-img">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-img logo-mc">
        <img src="assets/img/site/logo-Mc.png">
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-img logo-ods">
        <img src="assets/img/logo/ods/ods-12.png">
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-img selo-coleta">
        <img src="assets/img/selo-coleta-seletiva.png">
      </div>
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <h3 class="title">Certificado de Crédito de Logística Reversa</h3>
    </div>
  </div>

  <div class="container-data" *ngIf="!!data">
    <div class="row justify-content-center">
      <div class="col-12">
        <table class="crud-table">
          <tbody>
            <tr>
              <td class="f-bold">Cliente</td>
              <td>{{data.clienteCreditoLogisticaReversaNome}}</td>
            </tr>
            <tr>
              <td class="f-bold">Plano contratado</td>
              <td>{{data.planoNome}}</td>
            </tr>
            <tr>
              <td class="f-bold">Tipo de produto contratado</td>
              <td>{{data.produtoNome}}</td>
            </tr>
            <tr>
              <td class="f-bold">Quantidade</td>
              <td>{{data.quantidadeVendida}}</td>
            </tr>
            <tr>
              <td class="f-bold">% representado de suas vendas</td>
              <td>{{data.porcentagemProdutos}}</td>
            </tr>
            <tr>
              <td class="f-bold">Comprovado pelo cliente como</td>
              <td>{{comprovanteAjust(data.clienteCreditoLogisticaReversaComprovante)}}</td>
            </tr>
            <tr>
              <td class="f-bold">ID único de transação</td>
              <td>{{id}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container-data" *ngIf="!!data">
    <h5 class="title">Industria</h5>
    <div class="row justify-content-center">
      <div class="col-12">
        <table class="crud-table">
          <tbody>
            <tr>
              <td class="f-bold">Nome</td>
              <td>{{data.clienteCreditoLogisticaReversaNome}}</td>
            </tr>
            <tr>
              <td class="f-bold">CNPJ</td>
              <td>{{data.industriaCnpj}}</td>
            </tr>
            <tr>
              <td class="f-bold">Cidade</td>
              <td>{{data.industriaCidade}}</td>
            </tr>
            <tr>
              <td class="f-bold">Estado</td>
              <td>{{data.industriaEstado}}</td>
            </tr>
            <tr>
              <td class="f-bold">País</td>
              <td>Brasil</td>
            </tr>
            <tr>
              <td class="f-bold">Ação</td>
              <td>Compra dos resíduos recicláveis</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container-data" *ngIf="!!data">
    <h5 class="title">Transportador</h5>
    <div class="row justify-content-center">
      <div class="col-12">
        <table class="crud-table">
          <tbody>
            <tr>
              <td class="f-bold">Nome</td>
              <td>{{data.galpaoNome}}</td>
            </tr>
            <tr>
              <td class="f-bold">CNPJ</td>
              <td>{{data.galpaoCnpj}}</td>
            </tr>
            <tr>
              <td class="f-bold">Cidade</td>
              <td>{{data.galpaoCidade}}</td>
            </tr>
            <tr>
              <td class="f-bold">Estado</td>
              <td>{{data.galpaoEstado}}</td>
            </tr>
            <tr>
              <td class="f-bold">País</td>
              <td>Brasil</td>
            </tr>
            <tr>
              <td class="f-bold">Ação</td>
              <td>Recebimento dos Resíduos Ecoplat - Nível 1 de beneficiamento dos resíduos Ecoplat</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container-data" *ngIf="!data">
    <div class="row loader">
      <mat-spinner></mat-spinner>
    </div>
  </div>

</div>
