import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {FilesManageService} from "../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";
import {ColetasEntity} from "../../_coletas/coletas/coletas.entity";
import {environment} from "../../../../../environments/environment";
import {GALPAO_COLETAS, GALPAO_FIND_FILTER, GALPAO_FIND_FILTER_CNPJ} from "../../../../core/config/endpoint-list";
import {ModalControlePesagemComponent} from "../sub-components/modal-controle-pesagem/modal-controle-pesagem.component";
import {HttpService} from "../../../../services/http-service/http.service";
import {GalpaoEntity} from "../../_galpao/galpao/galpao.entity";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-controle-pesagem-main',
  templateUrl: './controle-pesagem-main.component.html',
  styleUrls: ['./controle-pesagem-main.component.scss']
})
export class ControlePesagemMainComponent implements OnInit, OnDestroy {

  table: CdkDynamicTable.tableClass;
  tableDadosColeta: CdkDynamicTable.tableClass;

  listFilterGalpao: Partial<GalpaoEntity>[] = [];
  listFilterGalpaoCNPJ: Partial<GalpaoEntity>[] = [];

  @ViewChild('modal') modal!: ModalControlePesagemComponent;

  public filters: {
    tipoPesquisa: 'CNPJ' | 'NOME',
    galpao: string | number,
    dataInicial: string,
    dataFinal: string,
  } = {
    tipoPesquisa: 'NOME',
    galpao: '',
    dataInicial: '',
    dataFinal: '',
  };
  public pesoTotalColetas = 0;

  constructor(
    private createTable: CdkDynamicTableService,
    public session: SessionService,
    private http: HttpService,
    public filesManage: FilesManageService,
  ) {
    this.table = this.createTable.create('request', {
      pagination: {
        sort: ['id,desc']
      },
      apiData: {
        path: environment.apiUrl,
        context: GALPAO_COLETAS,
        params: (!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? {idGalpao: this.session.user?.galpao_id} : {}),
        inputPipe: (resp: any) => {
          return resp['data'] || []
        },
        inputPaginationPipe: (p: any) => {
          return {
            page: p.page.number,
            totalPages: p.page.totalPages,
            totalElements: p.page.totalElements
          };
        },
        outputPaginationPipe: (pageable) => {
          const {totalElements, totalPages, ...rest} = pageable;
          return rest;
        }
      },
      columns: [
        {name: 'id', headerName: 'Rota ID'},
        {
          name: 'dataColeta', headerName: 'Data Coleta', resource: (cel: string | undefined) => {
            return !!cel ? cel.split('-').reverse().join('/') : '--'
          }
        },
        {
          name: 'motoristaNome', headerName: 'Motorista/Catador', resource: (cel: any) => {
            return !!cel?.nome ? cel.nome : '--'
          }
        },
        {
          name: 'pontosColeta', headerName: 'Estimativa de peso do Dia (Kg)', resource: (val: ColetasEntity) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            }) : '--'
          }
        },
        {
          name: 'pesoReal', headerName: 'Peso Real (Kg)', resource: (val: ColetasEntity) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            }) : '--'
          }
        },
      ],
      filters: {
        group: 'controle-pesagem', filters: {
          idGalpao: {data: ''}
        }
      },
      actions: {
        edit: {
          dbClick: true,
          name: 'Editar',
          action: (row) => {
            this.modal.open(row)
          },
        }
      },
      sort: ['id']
    })
    this.tableDadosColeta = this.createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: GALPAO_COLETAS,
        params: {idGalpao: this.session.user?.galpao_id || 1},
        inputPipe: (resp: any) => {
          return resp['data'] || []
        },
        inputPaginationPipe: (p: any) => {
          return {
            page: p.page.number,
            totalPages: p.page.totalPages,
            totalElements: p.page.totalElements
          };
        },
        outputPaginationPipe: (pageable) => {
          const {totalElements, totalPages, ...rest} = pageable;
          return rest;
        }
      },
      columns: [
        {name: 'id', headerName: 'Rota ID'},
        {
          name: 'unidadeGeradora', headerName: 'Gerador'
        },
        {
          name: 'pesoColeta', headerName: 'Peso da Coleta'
        },
        {
          name: 'pesoFinal', headerName: 'Peso Final'
        },
      ],
      filters: {
        group: 'dados-coleta', filters: {
          galpaoId: {data: 1}
        }
      },
      actions: {
        edit: {
          name: 'Editar',
          action: (row) => {
            alert('criar editar')
          },
        }
      }
    })
    this.table.controls.filters.patchValue({
      idGalpao: session.user.galpao_id || 1
    })
    this.filters.galpao = session.user.galpao_id || 1;

    this.getPesoTotalColetas()
    this.getGalpaoFilters()
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.table.destroy()
    this.tableDadosColeta.destroy()
  }

  format = (val: string | number) => {
    if (val) {
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    } else {
      return 0
    }
  }

  downloadExcel() {
    const params = {
      unpaged: true,
    };

    this.http.get('coletas/galpao/planilha', {params: {idGalpao: this.session.user?.galpao_id || 1}})
      .subscribe((resp) => {
        this.filesManage.downloadBase64(resp.data, 'Controle_de_Pesagem_de_Entrada.xlsx');
      });
  }

  getPesoTotalColetas(popup = false) {
    this.http.get('coletas/galpao/totallixo/', {params: {idGalpaoTotal: this.session.user?.galpao_id || 1}}).subscribe(
      resp => {
        //@ts-ignore
        this.pesoTotalColetas = resp.data.totalLixo;
        if (popup) {
          Swal.fire({
            icon: 'success',
            title: 'Peso total atualizado',
            timer: 3000
          }).then()
        }
      }
    )
  }

  getGalpaoFilters(nome?: string) {
    this.http.get(GALPAO_FIND_FILTER, { params: { nome: nome || '', unpaged: true } }).subscribe(
      resp => {
        this.listFilterGalpao = resp.data;
      }
    )
    this.http.get(GALPAO_FIND_FILTER_CNPJ, { params: { cnpj: nome || '', unpaged: true } }).subscribe(
      resp => {
        this.listFilterGalpaoCNPJ = (resp.data || []).map((item: any) => {
          if (!item?.cnpj) {
            item['cnpj'] = 'SEM CNPJ ('+(item?.nome || '--')+')';
          } else {
            item.cnpj =  item.cnpj.replace(/[./-]/g, "")
          }
          return item;
        });
      }
    )
  }

  findFilterGalpao(ev: string) {
    console.log(ev);
  }
}
