import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GalpaoMainComponent} from './galpao-main/galpao-main.component';
import {UiModule} from "../../../../shared/ui/ui.module";
import {RouterModule} from "@angular/router";
import {NgxMaskModule} from "ngx-mask";
import {MatIconModule} from "@angular/material/icon";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GalpaoInsertEditComponent} from "./galpao-insert-edit/galpao-insert-edit.component";
import { FuncionariosComponent } from './subComponents/funcionarios/funcionarios.component';
import { DocumentosComponent } from './subComponents/documentos/documentos.component';
import {MatButtonModule} from "@angular/material/button";
import { GalpaoTipoLixoComponent } from './subComponents/galpao-tipo-lixo/galpao-tipo-lixo.component';
import { RelacionarContainerGalpaoComponent } from './subComponents/relacionar-container-galpao/relacionar-container-galpao.component';
import { AddContainerGalpaoComponent } from './subComponents/add-container-galpao/add-container-galpao.component';
import {DirectivesModule} from "../../../../core/directives/directives.module";
import {DgAutocomplete2Module, DgAutocompleteModule} from '@datagrupo/dg-ng-util';
import {CustomAutocompleteModule} from "../../../../shared/custom-autocomplete/custom-autocomplete.module";


@NgModule({
  declarations: [
    GalpaoMainComponent,
    GalpaoInsertEditComponent,
    FuncionariosComponent,
    DocumentosComponent,
    GalpaoTipoLixoComponent,
    RelacionarContainerGalpaoComponent,
    AddContainerGalpaoComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    DirectivesModule,
    DgAutocompleteModule,
    DgAutocomplete2Module,
    CustomAutocompleteModule
  ]
})
export class GalpaoModule {
}
