import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-main',
  templateUrl: './faq-main.component.html',
  styleUrls: ['./faq-main.component.scss']
})
export class FaqMainComponent implements OnInit {

  public listFaq: {title: string, text: string}[] = [
    { title: 'Recicláveis Misturáveis', text: 'A coleta seletiva da Ecoplat mantém a simplicidade que normalmente já existe na separação dos resíduos em seu gerador. Você só precisa separar o orgânico dos recicláveis. Temos pessoas treinadas em todos os operadores logísticos parceiros que sabem exatamente como separar os resíduos sólidos.' },
    { title: 'Destinação de Resíduos', text: 'Os resíduos coletados são destinados para nossos operadores logísticos parceiros assim que a coleta é feita em seu gerador. Esses operadores logísticos precisam ter todas as licenças para se tornar parceiros da Ecoplat e são responsáveis por fazer toda separação e armazenamento temporário dos resíduos antes de irem para indústria.' },
    { title: 'Projeto para Cooperativas', text: 'Com o tempo queremos apoiar e capacitar catadores que estão em cooperativas para que possam ter seus direitos e empregos formais nesses operadores logísticos ou sendo motoristas/catadores parceiros, aumentando a renda de quem vive com resíduo no Brasil.' },
    { title: 'Materiais não recicláveis', text: 'Quase todos os resíduos sólidos tem algum tipo de reciclagem, mas parte deles perdem seus componentes quando recebem contato com resíduos orgânicos. Por isso que, papeis em cozinha, banheiro e plásticos em decomposição não voltam para cadeia produtiva da reciclagem. Depois de usados o papel toalha, o papel higiênico e o guardanapo. E os plásticos e metais descartados nos rios e mares, há muito tempo não recebem reciclagem.' },
    { title: 'Papéis recicláveis', text: 'Você sabia que uma árvore de eucalipto é transformada em 23 resmas de papel A4? A reciclagem do seu papel reduz o desmatamento e economiza energia no processo produtivo.' },
    { title: 'Vidros', text: 'O vidro não é biodegradável, podendo levar até 10 mil anos para se decompor. Com a reciclagem do vidro os gastos com a produção do mesmo podem reduzir em até 70% e por ter em sua composição a areia.' },
    { title: 'Metais', text: 'A economia de energia entre a produção de aço proveniente do minério de ferro e da sucata é de cerca de 5,06MWh/t. E sua composição é feita de minerais de fonte não renovável, cuja extração causa grande impacto ambiental.' },
    { title: 'Descarte de eletrodomésticos', text: 'Com a Ecoplat na seu gerador ou prédio você poderá colocar ao lado ou dentro do nosso contêiner que a gente leva para reciclagem.' },
    { title: 'Descarte de Óleo', text: 'Na seu gerador você pode colocar o óleo em uma garrafa pet fechada. Assim ela pode ir em nosso contêiner junto com os outros resíduos, nos operadores logísticos parceiros separamos e destinamos corretamente, tanto o óleo quanto a garrafa.' },
    { title: 'Isopor', text: 'O isopor é altamente prejudicial ao meio ambiente devido a sua característica impermeável e de baixíssima densidade, chegando a levar de 150 a 200 anos para se decompor no meio ambiente.' },
    { title: 'Plásticos', text: 'O plástico pode levar até 450 anos para se decompor na natureza, sua matéria-prima tem como origem material fóssil de fonte não renovável. Por isso deve ser utilizado o máximo possível e descartado corretamente.' },
    { title: 'Sucata eletrônica', text: 'O resíduo eletrônico não pode ser descartado em aterro sanitário por conter metais pesados altamente tóxicos e poluentes ao meio ambiente. Com a Ecoplat seu gerador pode descartar junto com os recicláveis.' },
    { title: 'Madeira', text: 'As sobras de madeira geradas na construção civil podem ser totalmente aproveitadas nos destinadores cerâmicas, cimenteiras e olarias devido ao alto poder calorífico produzido em sua queima.' },
    { title: 'Lâmpadas', text: 'As lâmpadas não podem ser descartadas como resíduo comum, pois são altamente tóxicas por conter mercúrio e outras substâncias nocivas ao meio ambiente. Nos planos pagos a Ecoplat envia para descontaminação e o descarte correto.' },
    { title: 'Entulho', text: 'A disposição irregular do entulho da construção civil causa obstrução da drenagem urbana, degrada os mananciais e polui os espaços públicos. A Ecoplat indica soluções para descarte correto e licenciado do entulho no sistema do gestor.' },
    { title: 'Gesso', text: 'O gesso pode acarretar sérios problemas ambientais devido a suas características física e química, por isso, deve contar com o descarte adequado.' },
    { title: 'Resíduo Extraordinário', text: 'O resíduo extraordinário é composto de matéria sólida e orgânica que em seu processo de decomposição produz o gás metano que é um dos agentes causadores do efeito estufa e o chorume altamente tóxico e contaminante do solo e lençol freático. Não podem ir junto com os reciclados.' },
    { title: 'Resíduos contaminados', text: 'As embalagens e os outros resíduos contaminados com óleo lubrificantes são extremamente prejudiciais ao meio ambiente, podem causar a contaminação do solo e do lençol freático.' },
    { title: 'Segurança', text: 'O Gestor e os funcionários da segurança ou portaria ficam sabendo antes da coleta quem é o motorista/catador e o ajudante que irão coletar os resíduos para trazer mais segurança e credibilidade ao processo.' },
    { title: 'Relatório de Impacto Ambiental', text: 'Mensalmente no seu gerador recebe os dados que mostram o impacto ambiental positivo que foi gerado graças a coleta seletiva. Você consegue ver o quanto foi economizado de água, energia, petróleo e minério retirado do meio ambiente, entre outras métricas importantes.' },
    { title: 'Ranking de Sustentabilidade', text: 'Após 100 unidades geradoras cadastrados será liberado o ranking da sustentabilidade que irá comprar usando as proporções corretas todos os unidades geradoras cadastrados premiando e estimulando os melhores unidades geradoras.' },
    { title: 'Acúmulo de pontos da Ecoplat', text: 'A cada coleta feita e a cada meta batida nas missões de engajamento o condomínio ganha pontos que podem ser trocados por ofertas nos parceiros. Esses pontos são trocados apenas por produtos e serviços para o condomínio.' },
    { title: 'Parceiros da Ecoplat', text: 'Temos dois tipos de parceiros Ecoplat, o primeiro são de produtos e serviços que servem para o condomínio. O segundo parceiro são parceiros com promoções, sorteios e descontos para usuários, funcionários das residências e do gerador cadastrado.' },
    { title: 'Coleta Extra', text: 'É uma coleta solicitada fora da programação combinada com o condomínio. De acordo com os planos o condomínio sabe quantas coletas ele têm direito.' },
    { title: 'Benefícios para usuários e funcionários', text: 'Os benefícios para usuários e funcionários são feitos pelos parceiros que adquirem as missões de engajamento e os créditos de logística reversa.' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
