import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpOldService} from "../../../../../../core/services/http/http-old.service";
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import Swal from "sweetalert2";
import {
  CLIENTE,
  CLIENTE_CONTAINER_PLANO_REGULAR,
  COLETA_PLANO_REGULAR
} from "../../../../../../core/config/endpoint-list";
import {formata_data_utc_br} from "../../../../../../helpers/helpers";
import {HttpService} from "../../../../../../services/http-service/http.service";

@Component({
  selector: 'modal-plano-coleta',
  templateUrl: './modal-plano-coleta.component.html',
  styleUrls: ['./modal-plano-coleta.component.scss']
})
export class ModalPlanoColetaComponent implements OnInit {
  @ViewChild('modal') modal!: OldModalComponent;
  @ViewChild('inputDate') inputDate!:ElementRef<HTMLInputElement>

  form = new FormGroup({
    mes:  new FormControl(''),
    grouped:  new FormControl(true),
    cargoMtr: new FormControl('', [Validators.required]),
    responsavelMtr: new FormControl('', [Validators.required]),
  })

  // public inputFormMesReferencia = new FormControl('');
  public listContainers: any[] = [];
  public mesReferencia = '';

  private dadosClienteMTR = {};

  public paramsDateSelectModal = {
    month: new Date().getMonth()+1,
    year: new Date().getFullYear(),
  }

  pesoMtr = '';

  constructor(
    private service: HttpOldService,
    private http: HttpService,
    private session: SessionService
  ) {
    // this.inputFormMesReferencia.valueChanges.subscribe((val: string) => {
    this.form.controls['mes'].valueChanges.subscribe((val: string) => {
      if (val.length != 6) return;

      const month = val.slice(0, 2);
      const year = val.slice(2);

      if (Number(year) < Number(this.paramsDateSelectModal.year)) {
        Swal.fire({
          icon: 'warning',
          title: "Não é possível selecionar um ano anterior"
        })
        this.form.patchValue({ mes: '' }, {emitEvent: false})
        return;
      }

      if (Number(year) == Number(this.paramsDateSelectModal.year)) {
        if (Number(month) < Number(this.paramsDateSelectModal.month)) {
          Swal.fire({
            icon: 'warning',
            title: "Não é possível selecionar um mês anterior"
          })
          this.form.patchValue({ mes: '' }, {emitEvent: false})
          return;
        }
      }

      this.mesReferencia = year+'-'+month;

      this.findColetas()
    })

    if (session.user.cliente_id) {
      this.http.get(CLIENTE + '/' + session.user.cliente_id).subscribe(resp => {

        this.dadosClienteMTR = {
          responsavelMtr: resp?.data?.responsavel,
          cargoMtr: resp?.data?.cargo
        }

        this.form.patchValue({
          ...this.dadosClienteMTR
        })
      })
    }
  }

  findColetas() {
    this.form.controls['mes'].disable({emitEvent: false})
    this.service.get(
      CLIENTE_CONTAINER_PLANO_REGULAR,
      {
        params: {
          clienteId: this.session.user.cliente_id,
          data: this.mesReferencia+'-01',
          unpaged: true
        }
      }
    ).subscribe(
      (resp: any) => {

        this.form.controls['mes'].enable({emitEvent: false})

        if ((resp.data || []).length <= 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Nenhum recipiente encontrado para realizar a relação'
          }).then()
        }

        console.log(resp.data || [])
        debugger

        this.listContainers = (resp.data || [])
          .map((resp: any) => {
            const date = new Date(resp.dataDaColeta)
            return {
              ...resp,
              selected: true,
              hasMtr: true,
              pesoMtr: '0',
              clienteContainer: {
                nome: resp.container.nome,
                id: resp.id
              },
              dataDaColeta: new FormControl(
                formata_data_utc_br(resp.dataDaColeta).split('/').reverse().join('-'),
                [Validators.required]
              ),
              openObs: false,
              observacoes: ''
            }

          })
          .filter((container: any) => {
              if ('dataDesativacao' in container) {
                // @ts-ignore
                return !container['dataDesativacao']
              }
              return true;
            }
          )

        if (this.listContainers.length <= 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Nenhum recipiente encontrado para realizar a relação'
          }).then()
        }
      }
    )
  }

  ngOnInit(): void {
  }

  open() {
    this.modal.open()
    setTimeout(() => document.getElementById('inputDate')?.focus(), 50)
  }

  close() {
    this.modal.close()
  }

  afterClose = () => {
    this.form.patchValue({ mes: '', grouped: true})
    this.listContainers = [];
    this.mesReferencia = '';
    this.form.controls['mes'].enable()
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.listContainers.map(valueForm => valueForm.dataDaColeta.invalid).filter(valueStatus => valueStatus).length > 0) {
      return;
    }

    if ((this.listContainers || []).filter(item => (!!item.selected && !!item.hasMtr && !item.pesoMtr)).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'O peso do MTR deve ser preenchido'
      }).then()
      return;
    }

    const checkinContainers = this.listContainers.filter(v => !!v.selected);

    if (checkinContainers.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione ao menos um dia para solicitar a coleta',
      }).then()
      return
    }

    checkinContainers.map(value => {
      const result = value;
      if (!value.invalid) {
        if (!!value.dataDaColeta?.value) {
          result.dataRota = value.dataDaColeta?.value
        }
      }

      return result;
    })

    const form = this.form.value;

    this.service.post(COLETA_PLANO_REGULAR, {
      checkinContainers,
      grouped: !!form.grouped
    }).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'Plano de coletas criado',
      }).then()
      this.close();
    })

  }

  getLastDayMonth() {
    const mesRef = this.mesReferencia.split('-')
    const date = new Date(Number(mesRef[0]), Number(mesRef[1]), 0);

    return date.toLocaleDateString().split('/').reverse().join('-');
  }

  verifyCheckedAllMtr(): boolean {
    return this.listContainers.filter(item => !item.hasMtr).length <= 0;
  }

  markAllMtr() {
    const value = this.verifyCheckedAllMtr();
    this.listContainers = this.listContainers.map(item => {
      item.hasMtr = !value;
      return item;
    })
  }

  setPesoMtr() {
    this.listContainers = this.listContainers.map(item => {
      if (!item.pesoMtr) {
        item.pesoMtr = this.pesoMtr;
      }
      return item;
    })
  }

  clearPesoMtr(index: number) {
    if (!this.listContainers[index]) return;
    const newValue = String(this.listContainers[index]?.pesoMtr || '').replace(/[a-zA-Z.]/g, '');

    this.listContainers[index].pesoMtr = newValue || '';
  }
}
