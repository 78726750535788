import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CategoriaArquivoService} from "../../../../categoria-arquivo/service/categoria-arquivo.service";
import {CategoriaArquivoEntity} from "../../../../categoria-arquivo/categoria-arquivo.entity";
import {FormControl, Validators} from "@angular/forms";
import {GalpaoDocumentosService} from "../../../galpao-documentos/service/galpao-documentos.service";
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";
import {GALPAO_DOCUMENTO} from "../../../../../../core/config/endpoint-list";
import {ajusteDateByPaste, verifySizeFiles} from "../../../../../../helpers/helpers";

@Component({
  selector: 'subComponente-galpao-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  @ViewChild('modalDocumentos') modal!: OldModalComponent;
  @ViewChild('docInput') docInput!: ElementRef;
  @Output() salve = new EventEmitter<any>();
  @Input() idGalpao: string | number | undefined;

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.vencimento.patchValue(text))

  public file: File | undefined;
  public docFile: File | undefined;
  public docName: string | undefined;

  public edit = false;
  public docId: number | undefined;

  public categoriaArquivo: FormControl = new FormControl('', [Validators.required])
  public vencimento: FormControl = new FormControl('', [Validators.required])

  public categoriasList: CategoriaArquivoEntity[] = [];

  constructor(
    private categArquiv: CategoriaArquivoService,
    private docService: GalpaoDocumentosService,
    public filesManage: FilesManageService
  ) {

  }

  ngOnInit(): void {
  }

  public async open(data?: any) {
    if (!this.idGalpao) {
      Swal.fire({
        icon: 'error',
        title: 'Transportador não encontrado'
      }).then();
      return;
    }

    await this.categArquiv.get('categoria-arquivos', {params: {unpaged: true}}).subscribe(
      resp => {
        this.categoriasList = resp.data
      }
    )

    if (!!data?.id) {
      await this.docService.get(GALPAO_DOCUMENTO + '/' + data.id).subscribe(
        resp => {
          this.categoriaArquivo.patchValue(resp.data.categoriaArquivoDTO.id);

          if (!!resp.data?.vencimento) {
            this.vencimento.patchValue(resp.data?.vencimento)
          }
          // this.vencimento.disable()
          this.docName = resp.data.nomeArquivo
          this.edit = true;
          this.docId = resp.data.id

          this.modal.open()
        }
      )
      return;
    }

    this.modal.open()
  }

  uploadDocumento_click() {
    const docInput = this.docInput.nativeElement;
    docInput.click()
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.docFile = undefined;
        return;
      }

      this.docName = file.name
      this.file = file
    }
  }

  saveOrUpdate() {
    if (!this.categoriaArquivo.valid) {
      this.categoriaArquivo.markAllAsTouched()
      return;
    }

    if (this.edit) {
      this.update();
    } else {
      if (!this.file) return;
      this.salvar()
    }
  }

  update() {
    const data = {
      // idGalpao: this.idGalpao,
      // idCategoria: this.categoriaArquivo.value,
      // vencimento: this.vencimento.value,

      categoriaArquivoId: this.categoriaArquivo.value,
      id: this.docId,
      vencimento: this.vencimento.value,
    }

    this.docService.put(
      GALPAO_DOCUMENTO,
      data
    ).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'GalpaoArquivoEntity'}));
        this.modal.close();
      }
    )
  }

  salvar() {

    var formData: any = {}

    if (!this.docName || !this.file || !this.idGalpao) {
      Swal.fire({
        icon: 'error',
        title: "Selecione um arquivo",
        timer: 3000
      }).then();
    }

    formData = new FormData();

    formData.append("idGalpao", this.idGalpao);
    // formData.set("file", this.file);
    formData.append("idCategoria", this.categoriaArquivo.value);
    formData.append("vencimento", this.vencimento.value);
    formData.set("arquivo", this.file);

    this.docService.save(formData).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'GalpaoArquivoEntity'}));
        this.modal.close();
      }
    )
  }

  download() {
    if (this.docId) {
      this.docService.get('galpoes/documentogalpao/download/' + this.docId).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
        }
      )
    }
  }

  close = (e?: any) => {
    this.categoriaArquivo.reset('')
    this.vencimento.reset('')
    this.vencimento.enable()
    this.docName = undefined;
    this.docFile = undefined;
    this.docId = undefined;
    this.edit = false;
    return true
  }

}
