<div id="galpao-arquivo-main" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Missão</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntity"></filter-search>
        </div>
        <div class="card-body">
          <crud-data-table
            #tableMissao
            [entityMap]="mapEntity"
            [server]="TableConfig"
            [showPagination]="false"
          >
          </crud-data-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-pagination [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="tableMissao.changePages($event)"></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>


<old-dg-modal #modalMissao size="md" title="Convidar Gerador" [afterClose]="closeModal">
  <div dg-content>
    <div class="row">
      <div>
        Nome da Missão:
        <p>{{mapEntity?.nome}}</p>
      </div>
      <card>
        <div>
          <div class="row">
            <pick-list titleList="Geradores" #pickList [listaDisponivel]="listaClientes"
              [listaSelecionados]="listaClienteSelecionados" uniClass="nome" propertItem="nome">
            </pick-list>
          </div>
        </div>
      </card>
    </div>
  </div>
  <div dg-footer>
    <button (click)="modalMissao.close()" class="btn btn-secondary m-1">Fechar</button>
    <button class="btn btn-success m-1" (click)="baixarPlanilha()">Baixar Planilha</button>
    <button class="btn btn-success m-1" (click)="convidar()">Convidar</button>
  </div>
</old-dg-modal>

<page-footer>
  <button crud-find-filters class="btn btn-success">Pesquisar</button>
  <button crud-table-clear class="btn btn-secondary">Limpar</button>
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
</page-footer>
