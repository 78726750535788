<div id="pedido-credito-logistica-reversa">
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Pedido de Crédito Logística Reversa</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label class="form-label">
                                    NOME CLIENTE
                                    <span class="fc-red">*</span>
                                </label>
                                <input-auto-complete
                                    [baseValue]="entity?.clienteCredito"
                                    property="id"
                                    propertValue="nome"
                                    controlName="clienteCredito"
                                    urlServer="cliente-credito-logistica-reversa/nome"
                                    (outValue)="this.entity.clienteCredito = $event"
                                >
                                </input-auto-complete>
                            </div>





                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label
                                    for="anoCompensacao"
                                    class="form-label"
                                >
                                    ANO DE REFERENCIA DA
                                    COMPENSAÇÃO
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="anoCompensacao"
                                    class="form-control"
                                    mask="0000"
                                    formControlName="dataCompensacao"
                                >
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label
                                    for="porcentagemEmbalagens"
                                    class="form-label"
                                >
                                    % DAS EMBALAGENS
                                    COLOCADAS NO MERCADO?
                                    <span
                                    (click)="info()"
                                    class="material-symbols-outlined"
                                    style="margin-right: .3rem; cursor: pointer; font-size: 13pt; padding-bottom: 0;"
                >
                                    info
                                </span>
                                    <span class="fc-red">*</span>
                                </label>
                               
                                <input
                                    min="22"
                                    type="number"
                                    class="form-control"
                                    id="porcentagemEmbalagens"
                                    formControlName="porcentagemEmbalagens"
                                >
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label
                                    for="estado"
                                    class="form-label"
                                >
                                    ESTADO DE ORIGEM DA COMPENSAÇÃO
                                </label>
                                <select
                                    name="estado"
                                    formControlName="estado"
                                    id="estado"
                                    class="form-select"
                                >
                                    <option value>Selecione</option>
                                    <option
                                        *ngFor="let item of listEstados"
                                        [value]="item?.uf"
                                    >
                                        {{item.estado}}
                                    </option>
                                </select>
                            </div>






                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label
                                    for="qtdProdutoGerado"
                                    class="form-label"
                                >
                                    QUANTIDADE DE MATERIAIS GERADO POR
                                    ANO
                                    (TON)
                                    <span class="fc-red">*</span>
                                </label>
                                <input
                                    min="0"
                                    class="form-control"
                                    formControlName="qtdProdutoGerado"
                                    id="qtdProdutoGerado"
                                    placeholder="0000"
                                >
                            </div>





                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <label class="form-label">
                                    TIPO DE MATERIAL COMPENSADO
                                    <span class="fc-red">*</span>
                                </label>
                                <select
                                    class="form-select"
                                    id="tipoMaterial"
                                    formControlName="tipoMaterial"
                                >
                                    <option
                                        value
                                        disabled
                                    >
                                        Selecione
                                    </option>
                                    <option
                                        *ngFor="let prod of listProdutoGerado"
                                        value="{{prod?.id}}"
                                    >
                                        {{prod.nome}}
                                    </option>
                                </select>
                            </div>






                        </div>
                    </form>
                </div>
            </card>
        </div>
    </div>
</div>





<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
