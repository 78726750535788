<div id="galpao-arquivo-motorista">
  <div class="container">
    <div class="row">
      <card>
        <div class="card-header">
          <h4 class="card-title">Dados</h4>
          <hr />
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <form [formGroup]="form">
                  <label class="form-label"
                    >NOME<small class="fc-red">*</small></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nome"
                  />
                </form>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-5">
            <pick-list
              #pickList
              [listaDisponivel]="listaSelect"
              [listaSelecionados]="listaSelecionados"
              propertItem="value"
              titleList="Geradores"
            ></pick-list>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button [hidden]="entityId" crud-salvar [backAfterSave]="false" class="btn btn-success">Salvar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button [hidden]="!entityId" crud-deletar class="btn btn-secondary">Excluir</button>
</page-footer>
