import { Component, OnInit } from '@angular/core';
import {ClientesEntity} from "../clientes.entity";
import {ClientesService} from "../service/clientes.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {ActivatedRoute} from "@angular/router";
import {crudDispatchEvent, InterfaceCustomActions} from "@datagrupo/dg-crud";
import Swal from "sweetalert2";

@Component({
  selector: 'app-clientes-main',
  templateUrl: './clientes-main.component.html',
  styleUrls: ['./clientes-main.component.scss']
})
export class ClientesMainComponent implements OnInit {

  public mapEntity = new ClientesEntity();

  public tableClientes: InterfaceCustomActions[] = [
    {
      name: 'Ativar',
      action: (val: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            let data = {
              id: val.id,
              ativo: true,
            }
            this.service.patch('clientes/ativo', data).subscribe(
              () => {
                crudDispatchEvent('clienteTable')
              }
            )
          }
        });
      },

      permission: (val: any)  => {
        return val.ativo == false ? true : false
      }
    },
    {
      name: 'Desativar',
      action: (val: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(confirm => {
            if (confirm.isConfirmed) {
              let data = {
                id: val.id,
                ativo: false,
              }
              this.service.patch('clientes/ativo', data).subscribe(
                () => {
                  crudDispatchEvent('clienteTable')
                }
              )
            }
        });
    },
      permission: (val: any)  => {
        return val.ativo == true ? true : false
      }

    },
    {
      name: 'Visualizar',
      action: (val: any) => {

      },
      permission: false
    },
    {
      name: 'excluir',
      action: (val: any) => {
      },
      permission: false
    }]

  constructor(
    public service: ClientesService,
    private files: FilesManageService,
    public session: SessionService,
    private route: ActivatedRoute
  ) {
    if (!session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])) {
      this.mapEntity.removeFilters(['parceiro'])
    }
  }

  ngOnInit(): void {
  }

  baixarPlanilha() {
    const paramsRoot = !!this.route.snapshot.queryParams['filters']
      ? JSON.parse(this.route.snapshot.queryParams['filters'])['clienteTable']
      : {};

    const params: {[key: string]: any} = {}

    if ('nome' in paramsRoot) {
      if (typeof paramsRoot['nome'] == 'string') {
        params['nomeCliente'] = paramsRoot['nome'];
      } else if (!!paramsRoot['nome']?.id) {
        params['clienteId'] = paramsRoot['nome'].id;
      }
    }

    if ('parceiro' in paramsRoot) {
      if (typeof paramsRoot['parceiro'] == 'string') {
        params['nomeParceiro'] = paramsRoot['parceiro'];
      } else if (!!paramsRoot['parceiro']?.id) {
        params['parceiroId'] = paramsRoot['parceiro'].id;
      }
    }

    if ('ativos' in paramsRoot) {
      params['ativos'] = paramsRoot['ativos'] == 'Sim'
    }

    if ('possuiSindico' in paramsRoot) {
      params['possuiSindico'] = paramsRoot['possuiSindico'] == 'Sim'
    }

    console.log('params', params)

    this.service.get('clientes/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'Planilha de clientes.xlsx')
      }
    )
  }

}
