import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: 'button[dg-desable-by-period] , *[crud-salvar]'
})
export class DgDesableByPeriodDirective {

  @Input() time: number | 'no' = 1500;
  @Input() eventKey: string | undefined;

  constructor(
    private elRef: ElementRef,
    private render: Renderer2,
  ) { }

  @HostListener('click')
  click() {
    this.render.setAttribute(this.elRef.nativeElement,'disabled', 'true')

    if (this.time != 'no') {
      setTimeout(() => {
        this.render.removeAttribute(this.elRef.nativeElement,'disabled')
      }, this.time)
    }
  }

  @HostListener('window:dg-desable-by-period-enable', ['$event'])
  getEventEnableButton(ev: CustomEvent) {
    if (!ev?.detail || !this.eventKey) return;
    if (ev.detail != this.eventKey) return;

    this.render.removeAttribute(this.elRef.nativeElement,'disabled')
  }

  @HostListener('window:dg-desable-by-period', ['$event'])
  getEventDisableButton(ev: CustomEvent) {
    if (!ev?.detail || !this.eventKey) return;
    if (ev.detail != this.eventKey) return;

    this.render.setAttribute(this.elRef.nativeElement,'disabled', 'true')
  }

}
