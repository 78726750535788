import {Injectable} from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {GalpaoFuncionariosEntity} from "../galpao-funcionarios.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";

@Injectable({
  providedIn: 'root'
})
export class GalpaoFuncionariosService extends AbstractHttpService<GalpaoFuncionariosEntity> {

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'funcionariogalpao')
  }

}
