<old-dg-modal
  size="md"
  title="Dados do Usuário"
  #modalCadastroHolding
  [afterClose]="afterClose"
>
  <form dg-content [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label class="form-label">Nome <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="nome" (focusout)="createLogin()"/>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <label class="form-label"
          >Sobrenome <span class="fc-red">*</span></label
        >
        <input type="text" class="form-control" formControlName="sobrenome" (focusout)="createLogin()"/>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label class="form-label">Login <span class="fc-red">*</span></label>
        <input type="text" class="form-control" formControlName="login" />
      </div>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label class="form-label">Email <span class="fc-red">*</span></label>
        <input type="email" class="form-control" formControlName="email" />
      </div>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label class="form-label">Telefone <span class="fc-red">*</span></label>
        <input
          placeholder="(00) 00000-0000"
          mask="(00) 0000-0000||(00) 00000-0000"
          type="text"
          class="form-control"
          formControlName="telefone"
        />
      </div>
    </div>
  </form>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
