<!-- BARRA DE PROGRESSO -->
<div id="loader-dashboard" *ngIf="controlles.loaders.showLoader">
  <mat-progress-bar mode="buffer" [value]="controlles.loaders.progress().percent"></mat-progress-bar>
</div>

<!-- CONTAINER DE SELEÇÃO DE FILTROS -->
<div class="container container-amostra" *ngIf="!verifyShow.hasAnyDados && !controlles.loaders.showLoader">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div style="text-align: center">
        <h4 class="card-title">Selecione os filtros</h4>
        <p *ngIf="verifyShow.hasAnyDados === false" class="card-subtitle">Filtros insuficientes para gerar dados</p>
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          (click)="canvas.toggleOffcanvas()"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>
      </div>
    </div>
  </div>
</div>

<!-- LINHA DE SELEÇÃO DE FILTROS -->
<div class="container-fluid"  *ngIf="verifyShow.hasAnyDados || controlles.loaders.showLoader">
  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <button
        class="btn btn-outline dashboard-buttons"
        type="button"
        (click)="canvas.toggleOffcanvas()"
      >
        <mat-icon>search</mat-icon>
        Filtros
      </button>

<!--      <div class="btn-group">-->
<!--        <button-->
<!--          class="btn btn-outline dashboard-buttons dropdown-toggle"-->
<!--          data-bs-toggle="dropdown"-->
<!--          data-bs-display="static"-->
<!--          aria-expanded="false"-->
<!--        >-->
<!--          <mat-icon>settings</mat-icon>-->
<!--          Opções-->
<!--        </button>-->

<!--        <ul class="dropdown-menu dropdown-menu-lg-end">-->
<!--          <li>-->
<!--            <button class="dropdown-item" type="button">-->
<!--              <mat-icon class="icon-pre">share</mat-icon>-->
<!--              <span>Compartilhar Relatório</span>-->
<!--              <mat-icon-->
<!--                class="icon-pos"-->
<!--                matTooltip="Não será necessário login para abrir esse link"-->
<!--                aria-label="Não será necessário login para abrir esse link"-->
<!--              >contact_support</mat-icon>-->
<!--            </button>-->
<!--          </li>-->
<!--          <li>-->
<!--            <button class="dropdown-item" type="button">-->
<!--              <mat-icon class="icon-pre">print</mat-icon>-->
<!--              <span>Imprimir</span>-->
<!--            </button>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr class="fc-grey">
    </div>
  </div>
</div>

<!-- CONTEUDO DA TELA -->
<div class="container-fluid">
  <div
    *ngIf="controlles.loaders.getItemLoader('impacto').finish && !controlles.loaders.getItemLoader('impacto').error"
    class="row mt-3"
  >
    <card-impacto-ambiental2 [loader]="!verifyShow.loaders.impacto" [data]="dashboardData.impacto"></card-impacto-ambiental2>
  </div>

  <div
    *ngIf="controlles.loaders.getItemLoader('ods').finish && !controlles.loaders.getItemLoader('ods').error"
    class="row mt-3"
  >
    <card-ods2 [data]="dashboardData.ods"></card-ods2>
  </div>

  <div
    *ngIf="controlles.loaders.getItemLoader('gri').finish && !controlles.loaders.getItemLoader('gri').error"
    class="row mt-3 table-gri"
  >
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <h4 class="card-title">Insumos para o relatório GRI/SASB (GRI 306)</h4>
                <h6 class="card-subtitle">
                  Este relatório foi elaborado em conformidade com
                  as Normas GRI: opção Abrangente
                </h6>
              </div>

              <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <button class="btn btn-success btn-card-header" (click)="downloadExcelGri()">
                  <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                  Download Excel
                </button>
              </card-header-buttons>
            </div>
          </div>
        </div>
<!--        <div class="card-header">-->
<!--          <h4 class="card-title">Insumos para o relatório GRI/SASB (GRI 306)</h4>-->
<!--          <h6 class="card-subtitle">-->
<!--            Este relatório foi elaborado em conformidade com-->
<!--            as Normas GRI: opção Abrangente-->
<!--          </h6>-->
<!--        </div>-->
        <div class="card-body body-table">
          <dg-table
            classes="default-table"
            dg-dynamic-table
            [dynamicTable]="tableGRI"
          ></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableGRI"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SIDE BAS FILTER -->
<canvas-side-filter
  #canvas
  (clear)="clearFilters()"
  (closed)="initLoaderData()"
  [visibleFavorite]="false"
>
  <form [formGroup]="formFilter">
    <div class="row">
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('unidades')">
        <label class="form-label form-label-sm">Geradores</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control form-control-sm" formControlName="unidades" [data]="controlles.filtersOptions['unidades']"
          dg-dynamic-group="estoque" paramName="unidades" typeComunication="change" (change)="changeFilters('unidades')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('transportadores')">
        <label class="form-label form-label-sm">Transportador</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control form-control-sm" formControlName="galpao" [data]="controlles.filtersOptions['transportador']"
          dg-dynamic-group="estoque" paramName="transportador" typeComunication="change" (change)="changeFilters('transportadores')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('motoristas')">
        <label class="form-label form-label-sm">Motorista/Catador</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('motoristas')"
          classes="form-control form-control-sm" formControlName="motorista" [data]="controlles.filtersOptions['motoristas']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataInicio')">
        <label class="form-label form-label-sm">Data Início</label>
        <input type="date" formControlName="dataInicio" class="form-control form-control-sm" (change)="changeFilters()">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataFim')">
        <label class="form-label form-label-sm">Data Fim</label>
        <input type="date" formControlName="dataFim" class="form-control form-control-sm" (change)="changeFilters()">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('tipoResiduos')">
        <label class="form-label form-label-sm">Tipos de Resíduo</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('tipoResiduos')"
          classes="form-control" formControlName="tipoResiduos" [data]="controlles.filtersOptions['tipoResiduos']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('ativo')">
        <label class="form-label form-label-sm">Ativo/Inativo</label>
        <select class="form-select form-select-sm" formControlName="ativo" (change)="changeFilters('ativo')">
          <option value="">Ambos</option>
          <option [value]="true">Ativos</option>
          <option [value]="false">Inativos</option>
        </select>
      </div>
    </div>
  </form>
</canvas-side-filter>
