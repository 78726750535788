import {Component, OnInit, ViewChild} from '@angular/core';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { filter } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { ClientesEntity } from '../../_clientes/clientes/clientes.entity';
import { PlanosClientesService } from '../../_clientes/planos-clientes/services/planos-clientes.service';
import { SelecionarPlanoEntity } from '../selecionar-plano.entity';
import { SelecionarPlanoService } from '../service/selecionar-plano.service';
import {OldModalComponent} from "../../../../shared/ui/modal/modal/modal.component";
import Swal from "sweetalert2";
import { SessionService } from 'src/app/core/services/session-service/session.service';

@Component({
  selector: 'app-selecionar-plano',
  templateUrl: './selecionar-plano.component.html',
  styleUrls: ['./selecionar-plano.component.scss']
})
export class SelecionarPlanoComponent  implements OnInit {

  listaPlano: any[] = [];
  listaPlanoSelecionados: any[] = [];
  meusPlanos: any;
  meusPlanosPontuais: any;
  metodoPagamento: string = '';
  valorSomado: number = 0;
  periodoSelecionado: 'PONTUAL' | 'ANUAL' | 'TRIMESTRAL' | 'SEMESTRAL' = 'ANUAL';

  planoSelecionadoEntity = new SelecionarPlanoEntity();

  @ViewChild('modalContratacao') modal!: OldModalComponent;

  constructor(
    public servic: SelecionarPlanoService,
    public serivcePlanos: PlanosClientesService,
    public http: HttpOldService,
    public session: SessionService
  ) {}

  ngOnInit(): void {
    this.getPlanosList();
    this.getPlanosPontualList();
    this.servic.get(
      'planos/periodicidade',
      {
        params: {
          periodicidadePlanoEnum: this.periodoSelecionado,
          unpaged: true
        }
      }
    ).subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data
    });

  }

  selecionarPlano(id: number, valor: number) {
    let plano = this.listaPlano[id];
    if (plano) {
      this.listaPlanoSelecionados.push(plano);
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSomado += valor
      }
    }
  }
  removerPlano(id: number, valor: number) {
    let plano = this.listaPlano[id];
    if (plano) {
      if (this.listaPlanoSelecionados.length > 0) {
        this.valorSomado -= valor
        this.listaPlanoSelecionados.splice(id, 1);
      }
    }
  }

  getTrimestrais() {
    this.servic.addWhere({periodicidadePlanoEnum: 'TRIMESTRAL', unpaged: true})
    this.servic.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data;
      this.periodoSelecionado = 'TRIMESTRAL'
    });
    this.listaPlanoSelecionados = [];
  }

  getSemestrais() {
    this.servic.addWhere({periodicidadePlanoEnum: 'SEMESTRAL', unpaged: true})
    this.servic.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data;
      this.periodoSelecionado = 'SEMESTRAL'
    });
    this.listaPlanoSelecionados = [];
  }

  getAnuais() {
    this.servic.addWhere({periodicidadePlanoEnum: 'ANUAL', unpaged: true})
    this.servic.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data;
      this.periodoSelecionado = 'ANUAL'
    });
    this.listaPlanoSelecionados = [];
  }

  getPontual() {
    this.servic.addWhere({periodicidadePlanoEnum: 'PONTUAL', unpaged: true})
    this.servic.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data;
      this.periodoSelecionado = 'PONTUAL'
    });
    this.listaPlanoSelecionados = [];
  }

  contratar(){
    let listId: number[] = [];
    let planoContratado: {metodoPagamentoEnum: string, periodicidadePlanoEnum: string, planosId: number[]};
    this.listaPlanoSelecionados.forEach(plano =>{
      listId.push(plano.id)
    })
    planoContratado = {metodoPagamentoEnum: this.metodoPagamento, periodicidadePlanoEnum: !!this.listaPlanoSelecionados[0].periodicidadePlanoEnum ? this.listaPlanoSelecionados[0].periodicidadePlanoEnum : 'PONTUAL' , planosId: listId};

    this.http.post('cliente-plano/contratar', planoContratado).subscribe(
      resp => {
        this.listaPlanoSelecionados = [];
        this.listaPlano = [];

        Swal.fire({
          icon: 'success',
          title: 'Os planos contratados',
          timer: 5000
        })

        this.modal.close()
        this.getPlanosList()
      }
    );
  }

  verificaContratado(id: number | string): boolean {

     const indexMeusPlanos = this.meusPlanos?.planosContratados.findIndex(
      (itemMeusPlanos: any) => {
        return itemMeusPlanos.id == id
      }

     );
     return indexMeusPlanos != -1
  }

  getPlanosList() {
    // @ts-ignore
    this.servic.get('cliente-plano/contratados', { params: { ...this.planoSelecionadoEntity.configEntityTable.paginatio} }).subscribe(
      resp => {
        //@ts-ignore
        this.meusPlanos = resp.data;

        this.planoSelecionadoEntity.configEntityTable.pagination = {
          ...this.planoSelecionadoEntity.configEntityTable.pagination,
          //@ts-ignore
          ...resp.page,
          //@ts-ignore
          page: resp?.page?.number
        }

        //@ts-ignore
        this.planoSelecionadoEntity.configEntityTable.pagination = { ...this.planoSelecionadoEntity.configEntityTable.pagination }

      }
    )
  }

  getPlanosPontualList() {
    // @ts-ignore
    this.servic.get(`cliente-plano/pontual/${this.session.user.cliente_id}`).subscribe(
      resp => {
        //@ts-ignore
        this.meusPlanosPontuais = resp.data;

        this.planoSelecionadoEntity.configEntityTable.pagination = {
          ...this.planoSelecionadoEntity.configEntityTable.pagination,
          //@ts-ignore
          ...resp.page,
          //@ts-ignore
          page: resp?.page?.number
        }

        //@ts-ignore
        this.planoSelecionadoEntity.configEntityTable.pagination = { ...this.planoSelecionadoEntity.configEntityTable.pagination }

      }
    )
  }

  changePage(dataPagination: any) {
    this.planoSelecionadoEntity.configEntityTable.pagination = {
      ...this.planoSelecionadoEntity.configEntityTable.pagination,
      ...dataPagination
    }

    this.getPlanosList();
  }

  disabledSelected(id?: number | string): boolean {
    if (!id) return false;

    const index = this.listaPlanoSelecionados.findIndex(val => val.id == id)

    return index != -1;
  }

  verify() {
    if (this.listaPlanoSelecionados.length <= 0) return

    if (!this.metodoPagamento) {
      Swal.fire({
        icon: 'error',
        title: 'Selecione uma forma de pagamento',
        timer: 5000
      })

      return;
    }

    this.modal.open()
  }

  fotmataDinheiro(valor: number | string) {
    if (!Number(valor)) {
      return '0,00';
    }
    return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
