import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { MaterialEntity } from '../material.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends AbstractHttpService<MaterialEntity> {
  constructor(public conf: ConfigDgCrudService) {
    super(conf, environment.apiUrl, 'material');
  }

  uploadImagem(data: any){
    return this.save(data, 'imagem')
  }

}
