import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { GalpaoMotoristaEntity } from '../galpao-motorista.entity';

@Injectable({
  providedIn: 'root'
})
export class GalpaoMotoristaService extends AbstractHttpService<GalpaoMotoristaEntity> {

  constructor(
    public conf: ConfigDgCrudService
  ) {
    super(conf, environment.apiUrl, 'motoristas');

    this.dictionary.findAll = {
      galpao: 'nomeGalpao'
    }
   }
}
