import {AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgFilter_select, DgTableColumn} from "@datagrupo/dg-crud";
import {CategoriaNegocioEntity} from "../categoria-negocio/categoria-negocio.entity";
import {Enderecos} from "../../../shared/entitys/enderecos";
import {ArquivoEntity} from "../../../shared/entitys/arquivo.entity";
import { environment } from "src/environments/environment";
import { autoCompleteCategoriaNegocioOutPut } from "src/app/helpers/auto-complete/categoria-negocio-autocomplete.helper";
import { autoCompleteParceiroOutPut } from "src/app/helpers/auto-complete/parceiro-autocomplete.helper";

export const categoriaNegocio = [
  'Administradoras dos Geradores',
  'Prestador de serviço para o Geradores',
  'Marketplace para o Geradores',
  'Empresa de logística reversa',
  'Marketplace para usuários',
  'Transportador Parceiro',
  'Ongs e instituições sem fins lucrativos',
  'Representante de vendas'
]

export class ParceiroEntity extends AbstractEntity {

  constructor(
    id?: number,
    nome?: string,
    email?: string,
    cnpj?: string,
    telefone?: string,
    nomeContato?: string,
    tipoUsuarioParceiro?: string,
    tipoDesconto?: string,
    tipoNegocio?: string,
    categoriaNegocio?: CategoriaNegocioEntity,
    linkParceiro?: string,
    endereco?: Enderecos,
    imagem?: ArquivoEntity,
    modulos?: ("FINANCEIRO" | "EDUCACAO_AMBIENTAL" | "COMUNICACAO")[]
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.email = email;
    this.cnpj = cnpj;
    this.telefone = telefone;
    this.nomeContato = nomeContato;
    this.tipoUsuarioParceiro = tipoUsuarioParceiro;
    this.tipoDesconto = tipoDesconto;
    this.tipoNegocio = tipoNegocio;
    this.categoriaNegocio = categoriaNegocio;
    this.linkParceiro = linkParceiro;
    this.endereco = endereco;
    this.imagem = imagem;
    this.modulos = modulos;
  }

  @DgFilter_autoComplete({ colum: '4' },
  {
    getUrl: environment.apiUrl + 'parceiros',
    getServerData: "all",
    propertValue: 'nome',
    nameParamFind: 'nome',
    propertKey: 'id',
    outputFormat: autoCompleteParceiroOutPut
  })
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined;

  @DgFilter_input('text', { colum: '4', mask: 'cnpj' })
  @DgTableColumn({ columnName: 'CNPJ', mask: 'cnpj', sort: true })
  public cnpj: string | undefined;

  @DgFilter_autoComplete({ colum: '4' },
    {
      getUrl: environment.apiUrl + 'categoriasnegocio/nomes',
      getServerData: "all",
      propertValue: 'nome',
      nameParamFind: 'nome',
      propertKey: 'id',
      outputFormat: autoCompleteCategoriaNegocioOutPut
    })
  @DgTableColumn({
    columnName: 'CATEGORIA NEGÓCIO', resource: (cat: CategoriaNegocioEntity) => {
      return !!cat.id ? cat.nome : '--';
    }, sort: true
  })
  public categoriaNegocio: CategoriaNegocioEntity | undefined;

  @DgTableColumn({ columnName: 'E-MAIL', sort: true })
  public email: string | undefined;

  public telefone: string | undefined;
  public nomeContato: string | undefined;
  public tipoUsuarioParceiro: string | undefined;
  public tipoDesconto: string | undefined;
  public tipoNegocio: string | undefined;
  public linkParceiro: string | undefined;
  public endereco: Enderecos | undefined
  public imagem: ArquivoEntity | undefined;
  public modulos: ("FINANCEIRO" | "EDUCACAO_AMBIENTAL" | "COMUNICACAO")[] | undefined;
}
