<div id="pergunta-empresa">
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <mat-stepper linear #stepper>
            <mat-step [stepControl]="formPerfilInicial" #stepPerfilInicial>
              <form [formGroup]="formPerfilInicial">
                <ng-template matStepLabel class="fs-5">PERFIL INICIAL</ng-template>
                <div class="row">
                  <div class="row d-flex align-items-end">
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">QUAL SOLUÇÃO MAIS SE ADEQUA A SUA EMPRESA:</label>
                      <select name="solucao" id="solucao" class="form-select"
                              formControlName="interessePrincipalEmpresa">
                        <option value="" selected disabled>Selecione</option>
                        <option value="GERENCIA">Sistema para gerenciar minha operação
                          atual
                        </option>
                        <option value="GESTAO_RESIDUO">Preciso de serviço de gestão dos resíduos
                        </option>
                        <option value="COLETA_SELETIVA">Preciso de coleta seletiva</option>
                        <option value="COLETA_PONTUAL">Preciso de coleta pontual de algum
                          resíduo
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">NOME DO RESPONSÁVEL POR EXECUTAR O
                        PAGAMENTO</label>
                      <input type="text" class="form-control" formControlName="nomeResponsavel">
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">E-MAIL DO RESPONSÁVEL POR EXECUTAR O
                        PAGAMENTO</label>
                      <input type="text" class="form-control" formControlName="emailResponsavel">
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                      <label class="form-label">DATA LIMITE PARA EMISSÃO DE NOTA
                        FISCAL</label>
                      <select name="dataLimite" id="dataLimite" class="form-select"
                              formControlName="dataLimite">
                        <option value="" selected disabled>Selecione</option>
                        <option *ngFor="let data of listDias" value="{{data}}">
                          {{data}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepPerfilInicial)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="formFuncionamentoEmpresa" #stepFuncionametoEmpresa>
              <form [formGroup]="formFuncionamentoEmpresa">
                <ng-template matStepLabel class="fs-5">FUNCIONAMENTO DA EMPRESA</ng-template>

                <div class="row d-flex align-items-end">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label" for="local">QUANTOS FUNCIONÁRIOS:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="qtdFuncionario">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">SUA EMPRESA FABRICA OU PRODUZ ALGUM MATERIAL
                      ESPECÍFICO:</label>
                    <select class="form-select" formControlName="fabricaResiduos"
                            (change)="setClearValidator()">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       *ngIf="checkBoolean(formFuncionamentoEmpresa.value.fabricaResiduos)">
                    <label class="form-label">QUAIS OS PRINCIPAIS RESÍDUOS DESSA
                      PRODUÇÃO:</label>
                    <input type="text" class="form-control" formControlName="residuoProducao">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUAL A QUANTIDADE DE RESÍDUOS QUE SÃO DESCARTADOS A
                      CADA COLETA: </label>
                    <input type="text" class="form-control" formControlName="qtdResiduoDescartado"
                           placeholder="3 SACOS DE 100L A CADA COLETA OU 5 CONTÊINERES DE 240L A
                                        CADA COLETA:">
                  </div>
                </div>

                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepFuncionametoEmpresa)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="formNecessidadeEmpresa" #stepNecessidadeEmpresa>
              <form [formGroup]="formNecessidadeEmpresa">
                <ng-template matStepLabel class="fs-5">NECESSIDADES DA EMPRESA</ng-template>

                <div class="row d-flex align-items-end">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUAL A FREQUÊNCIA DE COLETA:</label>
                    <select name="frequencia" id="frequencia" class="form-select"
                            formControlName="frequenciaColeta">
                      <option value="" selected disabled>Selecione</option>
                      <option value="DIARIO">Diário</option>
                      <option value="UMA_VEZ">1 vez na semana</option>
                      <option value="DUAS_VEZES">2 vezes na semana</option>
                      <option value="TRES_VEZES">3 vezes na semana</option>
                      <option value="QUATRO_VEZES">4 vezes na semana</option>
                      <option value="QUINZENAL">Quinzenal</option>
                      <option value="MENSAL">Mensal</option>
                    </select>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">O SERVIÇO CONTRATADO PRECISA DE DESCARACTERIZAÇÃO
                      DE ALGUM DOCUMENTO OU EQUIPAMENTO:</label>
                    <select name="descarecterizaDocumento" id="descarecterizaDocumento"
                            class="form-select" formControlName="descarecterizaDocumento">
                      <option value="" selected disabled>Selecione</option>
                      <option value="TRUE">Sim</option>
                      <option value="FALSE">Não</option>
                      <option value="ALGUNS_CASOS">Em alguns casos</option>
                    </select>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">VOCÊ GOSTARIA DE BUSCAR SOLUÇÕES LIXO ZERO:</label>
                    <select name="solucaoAterro" id="solucaoAterro" class="form-select"
                            formControlName="solucaoAterro">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepNecessidadeEmpresa)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="formEstruturaEmpresa" #stepEstruturaEmpresa>
              <form [formGroup]="formEstruturaEmpresa">
                <ng-template matStepLabel class="fs-5">ESTRUTURA DA EMPRESA</ng-template>
                <div class="row d-flex align-items-end">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUANTAS SALAS ADMINISTRATIVAS:</label>
                    <input type="number" min="0" class="form-control" formControlName="salasAdm">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUANTOS BANHEIROS, COZINHAS E/OU REFEITÓRIOS</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="banheirosCozinhasRefeitorios">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label" for="local">EXISTE UM LOCAL FECHADO (QUARTO)
                      ONDE OS
                      RESÍDUOS FICAM ARMAZENADOS:</label>
                    <select name="local" id="local" class="form-select"
                            formControlName="localArmazenamento">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"
                       *ngIf="!checkBoolean(formEstruturaEmpresa.value.localArmazenamento) ">
                    <label class="form-label">ONDE SERÁ CRIADA A LIXEIRA CENTRAL:</label>
                    <input type="text" class="form-control" formControlName="lixeiraCentral">
                  </div>
                </div>
                <div class="row d-flex align-items-end">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">É UMA ÁREA DE VISIBILIDADE DO CLIENTE?</label>
                    <select name="areaVisivel" id="areaVisivel" class="form-select"
                            formControlName="areaVisivel">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">É UMA ÁREA COBERTA:</label>
                    <select name="areaCoberta" id="areaCoberta" class="form-select"
                            formControlName="areaCoberta">
                      <option value="" selected disabled>Selecione</option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUAL A LARGURA DA PORTA DA
                      LIXEIRA:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="larguraPorta">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 pt-4">
                    <label class="form-label">QUAL A METRAGEM QUADRADA DA
                      LIXEIRA:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="metragemLixeira">
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">QUANTOS PASSOS DA LIXEIRA CENTRAL ATÉ O
                      PONTO DE
                      PARADA DO CAMINHÃO:</label>
                    <select class="form-select" name="passosLixeira" id="passosLixeira"
                            formControlName="passosLixeira">
                      <option value="" selected disabled>Selecione</option>
                      <option value="ATE_DEZ">até 10 passos</option>
                      <option value="ONZE_VINTE">entre 11 e 20 passos</option>
                      <option value="VINTE_UM_TRINTA">entre 21 e 30 passos</option>
                      <option value="TRINTA_UM_QUARENTA">entre 31 e 40 passos</option>
                      <option value="QUARENTA_UM_CINQUENTA">entre 41 e 50</option>
                      <option value="CINQUENTA_UM_SESSENTA">entre 51 e 60</option>
                      <option value="MAIS_SESSENTA">mais de 60</option>
                    </select>
                  </div>

                  <div *ngIf="formEstruturaEmpresa.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <label class="form-label">
                      QUANTOS PASSOS ATÉ A LIXEIRA:
                    </label>
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      formControlName="qtdPassos"
                    >
                  </div>
                  <div *ngIf="formEstruturaEmpresa.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                       style="padding-top: 1.1em">
                    <label class="form-label">ALGUM FUNCIONÁRIO SEU PODE
                      REGISTRAR O VOLUME DE RESÍDUO EXISTENTE NO CONTAINER NA PLATAFORMA DA
                      ECOPLAT E LEVAR O RESÍDUO ATÉ UMA DISTÂNCIA DE 15 PASSOS DO PONTO
                      DE PARADA DO CAMINHÃO:
                    </label>
                    <select formControlName="funcionarioPodeLevarLixo"
                            class="form-select" id="funcionarioPodeLevarLixo"
                            name="funcionarioPodeLevarLixo">
                      <option value="" selected disabled>Selecione
                      </option>
                      <option [value]="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                  <div *ngIf="formEstruturaEmpresa.value.passosLixeira === 'MAIS_SESSENTA'" class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                       style="padding-top: 1em">
                    <label class="form-label">
                      DESCREVA COMO ISSO PODE SER FEITO:
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="descricao" formControlName="descricao" rows="5"
                      cols="5"
                    ></textarea>
                    <span class="fs-10 text-muted">
                      Caso não tenha as condições ideais de distância os
                      coletadores poderão perder o interesse pela sua coleta.</span>
                  </div>

                  <hr class="mt-3 mb-3">

                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <h5>ANEXE UMA FOTO DA LIXEIRA CENTRAL:</h5>
                      <div class="div-image-containers">
                        <input #fileUploadfotoLixeiraCentral type="file"
                               (change)="uploadImg($event, entityId, 'fotoLixeiraCentral')"
                               style="display: none" accept="image/png, image/jpeg, image/jpg">
                        <img class="image-container"
                             src="{{ files.getImageToSrc(entity?.fotoLixeiraCentral)}}"
                             alt="Imagem do container">

                      </div>
                      <div
                        class="div-button-image-container justify-content-center">
                        <button (click)="fileUploadfotoLixeiraCentral.click()"
                                class="btn btn-success m-1">
                          Escolher
                        </button>
                        <button [disabled]="!entity?.fotoLixeiraCentral?.id"
                                (click)="removerImagem(entity?.fotoLixeiraCentral, 'fotoLixeiraCentral')"
                                class="btn btn-danger m-1">
                          Excluir
                        </button>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <h5>ANEXE UMA FOTO DA PORTA DA LIXEIRA CENTRAL:</h5>
                      <div class="div-image-containers">

                        <input #fileUploadfotoPortaCentral type="file"
                               (change)="uploadImg($event, entityId, 'fotoPortaCentral')"
                               style="display: none" accept="image/png, image/jpeg, image/jpg">
                        <img class="image-container"
                             src="{{files.getImageToSrc(entity?.fotoPortaCentral)}}"
                             alt="Imagem do container">

                      </div>
                      <div
                        class="div-button-image-container justify-content-center">
                        <button (click)="fileUploadfotoPortaCentral.click()"
                                class="btn btn-success m-1">
                          Escolher
                        </button>
                        <button [disabled]="!entity?.fotoPortaCentral?.id"
                                (click)="removerImagem(entity?.fotoPortaCentral, 'fotoPortaCentral')"
                                class="btn btn-danger m-1">
                          Excluir
                        </button>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-3 mb-3">
                </div>

                <div class="row d-flex align-items-end">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">QUAL HORÁRIO DE FUNCIONAMENTO DA PORTARIA:</label>
                    <input type="text" min="0" class="form-control"
                           formControlName="horarioFuncionamentoPortaria">
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">QUANTOS FUNCIONÁRIOS SÃO DA EQUIPE DE LIMPEZA:</label>
                    <input type="number" min="0" class="form-control"
                           formControlName="funcionariosLimpeza">
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">QUAL O HORÁRIO DE TRABALHO FUNCIONÁRIOS DA
                      LIMPEZA:</label>
                    <input type="text" min="0" class="form-control"
                           formControlName="horarioFuncionarioLimpeza">
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">SEMPRE TEM ALGUÉM PARA ACOMPANHAR A COLETA COBRINDO
                      HORÁRIOS DE ALMOÇO:</label>
                    <select class="form-select" formControlName="horarioAlmoco">
                      <option value="" selected disabled>Selecione</option>
                      <option value="true">Sim</option>
                      <option [value]="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="pt-3 d-flex justify-content-end container">
                  <button class="btn btn-secondary" style="margin-right: 1em"
                          matStepperPrevious>Anterior
                  </button>
                  <button class="btn btn-primary " matStepperNext
                          (click)="proximoStepper(stepEstruturaEmpresa)">Próximo
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel class="fs-5">ASSINATURA</ng-template>
              <form [formGroup]="formAssinatura">
                <ng-template matStepLabel>ASSINATURA</ng-template>
                <div class="row">
                  <label class="form-label">ESCOLHA SUA ASSINATURA:</label>
                  <mat-select class="form-select" formControlName="assinatura" multiple required>
                    <mat-option *ngFor="let topping of optAssinaturas"
                                [value]="topping.key">{{topping.value}}</mat-option>
                  </mat-select>
                </div>
              </form>
              <div class="pt-3 d-flex justify-content-end container">
                <button class="btn btn-success" (click)="salvarPerguntas()">Salvar</button>
              </div>
            </mat-step>
          </mat-stepper>
        </card>
      </div>
    </div>
  </div>

</div>
