import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import { MissaoEntity } from '../../missao/missao.entity';
import { MissaoService } from '../../missao/service/missao.service';
import {CupomEntity} from "../cupom.entity";
import {CupomService} from "../services/cupom/cupom.service";
import {MISSAO_CUPOM} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-cupom-main',
  templateUrl: './cupom-main.component.html',
  styleUrls: ['./cupom-main.component.scss']
})
export class CupomMainComponent implements OnInit {

  @ViewChild('modalCupom')modalCupom!: OldModalComponent;
  @ViewChild('pickList') pickList!: PickListComponent;

  public mapEntity = new CupomEntity();
  public entityId: string | number | undefined;

  public listaMissoes: any[] = [];
  public listaMissoesSelecionadas: any[] = [];

  constructor(
    public service: CupomService,
    public http: HttpOldService,
    public serviceMissao: MissaoService,
    public file: FilesManageService
    ) {
    this.mapEntity.addActions([
      {
        name: 'Convidar',
        action: (row: CupomEntity) => {this.open(row)}
      }
    ])
  }

  ngOnInit(): void {
  }

  open(data: CupomEntity | undefined){
    this.modalCupom.open()
    this.entityId = data?.id;

    this.service.get(MISSAO_CUPOM + this.entityId).subscribe(resp => {
      this.listaMissoes = resp.data
    })
  }

  convidar(){
    let listaMissoesId: any[] = [];
    listaMissoesId = this.pickList.getList().map(item => item?.id);
    this.http.post(`cupom/${this.entityId}/enviarcupom?id_missoes=${listaMissoesId}`, listaMissoesId).subscribe()
  }

  baixarPlanilha(){
    let listaMissoesId: any[] = [];
    listaMissoesId = this.pickList.getList().map(item => item?.id);
    this.http.get(`cupom/${this.entityId}/download?id_missoes=${listaMissoesId}&cupomId=${this.entityId}`).subscribe(resp => {
      if(resp){
        //@ts-ignore
        this.file.downloadBase64(resp.data, 'planilha-relação-cupom.xls')
      }
    })
  }

}
