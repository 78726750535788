import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from "../../services/session-service/session.service";
import {TokenService} from "../../services/token-service/token.service";

@Injectable({
  providedIn: 'root'
})
export class StartSessionGuard implements CanActivate {
  constructor(
    private session: SessionService,
    private token: TokenService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!window.localStorage.getItem('accessToken')) {
      this.session.logout();
    }

    if (!this.session.user?.id || !this.session.user?.perfil) {
      this.session.setUser();
    }

    return true;
  }

}
