import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractInsertEdit, HttpClient, InterfaceCustomActions, InterfPagination} from '@datagrupo/dg-crud';
import {VendasLogisticaReversaService} from '../service/vendas-logistica-reversa.service';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {VendasLogisticaReversaEntity} from '../vendas-logistica-reversa.entity';
import {ClientesComponent} from '../subComponentes/clientes/clientes.component';
import {ProdutosComponent} from '../subComponentes/produtos/produtos.component';

import {VendasLogisticaClientesEntity} from '../../vendas-clientes/vendas-logistica-clientes.entity';
import {VendasLogisticaProdutosEntity} from '../../vendas-produtos/vendas-logistica-produtos.entity';
import Swal from "sweetalert2";
import {PickListComponent} from 'src/app/shared/ui/pick-list/pick-list.component';

@Component({
  selector: 'app-vendas-logistica-reversa-insert-edit',
  templateUrl: './vendas-logistica-reversa-insert-edit.component.html',
  styleUrls: ['./vendas-logistica-reversa-insert-edit.component.scss']
})
export class VendasLogisticaReversaInsertEditComponent extends AbstractInsertEdit<VendasLogisticaReversaEntity> implements OnInit {

  @ViewChild('modalCliente') modalCliente!: ClientesComponent
  @ViewChild('modalProduto') modalProduto!: ProdutosComponent
  // @ViewChild('pickList') pickList!: PickListComponent;

  public entityVendasLogReversa = new VendasLogisticaReversaEntity();

  constructor(
    public servic: VendasLogisticaReversaService,
    public conf: ConfigDgCrudService,
  ) {
    super(conf, servic);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new VendasLogisticaReversaEntity()
  }

  override afterFetchEntity() {
    console.log(this.entity)
  }

  override afterSaveEntity() {
    this.fetchEntity(this.entity.id)
  }

  override beforeSaveEntity(): boolean {
    let data = new Date().toISOString().substring(0, 10)
    if (!this.entity) {
      this.entityId.markAllAsTouched();
      return false;
    }
    this.entity.dataInclusao = data
    return true;
  }

  salvarCliente(data: any) {
    this.entity.vendaClienteclrDTO = new VendasLogisticaClientesEntity(
      undefined,
      data.clientes.nome,
      data.quantidadeVendida,
      undefined,
      data.clientes
    )
  }

  salvarProdutos(data: any) {
    this.entity.vendaProdutosNotaFiscal.push(data)
  }

  getIdClienteParaModalProdutos(): number | string | undefined | any {
    if (!!this.entity?.vendaClienteclrDTO) {
      if (this.entity.vendaClienteclrDTO.clienteCreditoLogisticaReversa?.id) {
        return this.entity.vendaClienteclrDTO.clienteCreditoLogisticaReversa?.id;
      }
    }
    return undefined;
  }

  comprovanteReenvio() {
    const idVCLR = this.entity.vendaClienteclrDTO?.id
    Swal.fire({
      title: 'Deseja reenviar o comprovante?',
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.servic.post(`venda-logistica-reversa/reenviarcomprovante/${idVCLR}`, idVCLR).subscribe(resp => {
          if (resp) {
            Swal.fire({
              text: 'Reenvio feito com sucesso',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
      }
    })
  }

  removeProdutos(i: number) {
    Swal.fire({
      icon: 'question',
      title: 'Deseja remover esse registro?',
      showCancelButton: true,
      cancelButtonText: 'sair',
      confirmButtonText: "excluir"
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        if (this.entity.vendaProdutosNotaFiscal[i].id) {
          this.servic.delet(`venda-logistica-reversa/venda-produto-nota-fiscal/${this.entity.vendaProdutosNotaFiscal[i].id}`).subscribe(
            resp => {
              this.entity.vendaProdutosNotaFiscal.splice(i, 1)
              Swal.fire({
                icon: 'success',
                title: 'Registro removido com sucesso',
                confirmButtonText: "ok",
                timer: 3000
              })
            })
          return;
        }
        this.entity.vendaProdutosNotaFiscal.splice(i, 1);
        Swal.fire({
          icon: 'success',
          title: 'Registro removido com sucesso',
          confirmButtonText: "ok",
          timer: 3000
        })
      }
    })
  }

  EditarCliente(data?: VendasLogisticaClientesEntity) {
    if (!!this.entity.vendaClienteclrDTO) {
      this.modalCliente.open(this.entity.vendaClienteclrDTO)
    }

    this.modalCliente.open();
  }

  removeCliente() {
    Swal.fire({
      icon: 'question',
      title: 'Deseja remover esse registro?',
      showCancelButton: true,
      cancelButtonText: 'sair',
      confirmButtonText: "excluir"
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.entity.vendaClienteclrDTO = undefined
      }
    })
  }

  format = (val: string | number | undefined) => {
    if (val) {
      return (val).toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    } else {
      return 0
    }
  }

  sinalizarPago() {
    if (!this.entity.vendaClienteclrDTO?.id) return;

    this.servic.post('venda-logistica-reversa/confirmar-pagamento/' + this.entity.vendaClienteclrDTO.id, {}).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'Pagamento sinalizado',
        text: 'O paramento foi sinalizado como pago'
      }).then();

      this.fetchEntity(this.entity.id);
    })
  }
}
