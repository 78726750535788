import { AbstractEntity } from "@datagrupo/dg-crud";
import { ArquivoEntity } from "src/app/shared/entitys/arquivo.entity";
import { PlanoClienteEntity } from "../../planos-clientes/plano-cliente.entity";
import { ClientesEntity } from "../clientes.entity";

export class PerguntasEntity extends AbstractEntity {
    constructor(
        id?: string | number,
        cliente?: ClientesEntity,
        interessePrincipalEmpresa?: string,
        interessePrincipalEvento?: string,
        responsavelFinanceiro?: boolean,
        razaoSocial?: number,
        nomeResponsavel?: string,
        emailResponsavel?: string,
        dataLimite?: string,
        estimativaLogistica?: string,
        localArmazenamento?: boolean,
        localLixeiraCentral?: string,
        lixeiraCentral?: boolean,
        areaVisivel?: boolean,
        areaCoberta?: boolean,
        larguraPorta?: number,
        metragemLixeira?: number,
        passosLixeira?: string,
        qtdPassos?: number,
        funcionarioPodeLevarLixo?: boolean,
        descricao?: string,
        fotoLixeiraCentral?: ArquivoEntity,
        fotoPortaCentral?: ArquivoEntity,
        lixeiraAndar?: boolean,
        comprimentoLixeiraAndar?: string,
        fotoLixeiraAndar?: ArquivoEntity,
        descarteResiduo?: string,
        fotoDescarteResiduo?: ArquivoEntity,
        horarioFuncionamentoPortaria?: string,
        funcionariosLimpeza?: number,
        horarioFuncionarioLimpeza?: string,
        horarioPortaria?: boolean,
        horarioAlmoco?: boolean,
        canaisComunicacao?: string,
        tipoColeta?: string,
        funcionariosEmpresa?: number,
        fabricaResiduos?: boolean,
        residuoProducao?: string,
        qtdResiduoDescartado?: string,
        frequenciaColeta?: string,
        descarecterizacaoDocumento?: string,
        solucaoAterro?: string,
        salasAdm?: number,
        banheirosCozinhasRefeitorios?: number,
        cargoEvento?: string,
        diasHorarioEvento?: string,
        qtdPessoasLixeiraCentral?: number,
        coletaPosEvento?: string,
        cpf?: string,
        rg?: number,
        planoColetaDomiciliar?: PlanoClienteEntity,
        assinatura?: string,
        horarioColeta?: string
    ) {
        super()
        this.id = id;
        this.cliente = cliente;
        this.interessePrincipalEmpresa = interessePrincipalEmpresa;
        this.interessePrincipalEvento = interessePrincipalEvento;
        this.responsavelFinanceiro = responsavelFinanceiro;
        this.razaoSocial = razaoSocial;
        this.nomeResponsavel = nomeResponsavel;
        this.emailResponsavel = emailResponsavel;
        this.dataLimite = dataLimite;
        this.estimativaLogistica = estimativaLogistica;
        this.localArmazenamento = localArmazenamento;
        this.localLixeiraCentral = localLixeiraCentral
        this.lixeiraCentral = lixeiraCentral;
        this.areaVisivel = areaVisivel;
        this.areaCoberta = areaCoberta;
        this.larguraPorta = larguraPorta;
        this.metragemLixeira = metragemLixeira;
        this.passosLixeira = passosLixeira;
        this.qtdPassos = qtdPassos;
        this.funcionarioPodeLevarLixo = funcionarioPodeLevarLixo;
        this.descricao = descricao;
        this.fotoLixeiraCentral = fotoLixeiraCentral;
        this.fotoPortaCentral = fotoPortaCentral;
        this.lixeiraAndar = lixeiraAndar;
        this.comprimentoLixeiraAndar = comprimentoLixeiraAndar;
        this.fotoLixeiraAndar = fotoLixeiraAndar;
        this.descarteResiduo = descarteResiduo
        this.fotoDescarteResiduo = fotoDescarteResiduo;
        this.horarioFuncionamentoPortaria = horarioFuncionamentoPortaria;
        this.funcionariosLimpeza = funcionariosLimpeza;
        this.horarioFuncionarioLimpeza = horarioFuncionarioLimpeza;
        this.horarioPortaria = horarioPortaria;
        this.horarioAlmoco = horarioAlmoco;
        this.canaisComunicacao = canaisComunicacao;
        this.tipoColeta = tipoColeta;
        this.funcionariosEmpresa = funcionariosEmpresa;
        this.fabricaResiduos = fabricaResiduos;
        this.residuoProducao = residuoProducao;
        this.qtdResiduoDescartado = qtdResiduoDescartado;
        this.frequenciaColeta = frequenciaColeta;
        this.descarecterizacaoDocumento = descarecterizacaoDocumento;
        this.solucaoAterro = solucaoAterro;
        this.salasAdm = salasAdm;
        this.banheirosCozinhasRefeitorios = banheirosCozinhasRefeitorios;
        this.cargoEvento = cargoEvento;
        this.diasHorarioEvento = diasHorarioEvento;
        this.qtdPessoasLixeiraCentral = qtdPessoasLixeiraCentral;
        this.coletaPosEvento = coletaPosEvento;
        this.cpf = cpf;
        this.rg = rg;
        this.planoColetaDomiciliar = planoColetaDomiciliar;
        this.assinatura = assinatura;
        this.horarioColeta = horarioColeta
    }
    public cliente: ClientesEntity | undefined;
    public interessePrincipalEmpresa: string | undefined;
    public interessePrincipalEvento: string | undefined;
    public responsavelFinanceiro: boolean | undefined;
    public razaoSocial?: number | undefined;
    public nomeResponsavel: string | undefined;
    public emailResponsavel: string | undefined;
    public dataLimite: string | undefined;
    public estimativaLogistica: string | undefined;
    public localLixeiraCentral: string | undefined;
    public localArmazenamento: boolean | undefined;
    public lixeiraCentral: boolean | undefined;
    public areaVisivel: boolean | undefined;
    public areaCoberta: boolean | undefined;
    public larguraPorta: number | undefined;
    public metragemLixeira: number | undefined;
    public passosLixeira?: string | undefined;
    public qtdPassos: number | undefined;
    public funcionarioPodeLevarLixo?: boolean | undefined;
    public descricao: string | undefined;
    public fotoLixeiraCentral: ArquivoEntity | undefined;
    public fotoPortaCentral: ArquivoEntity | undefined;
    public lixeiraAndar: boolean | undefined;
    public comprimentoLixeiraAndar: string | undefined;
    public fotoLixeiraAndar: ArquivoEntity | undefined;
    public descarteResiduo: string | undefined;
    public fotoDescarteResiduo: ArquivoEntity | undefined;
    public horarioFuncionamentoPortaria: string | undefined;
    public funcionariosLimpeza: number | undefined;
    public horarioFuncionarioLimpeza: string | undefined;
    public horarioPortaria: boolean | undefined;
    public horarioAlmoco: boolean | undefined;
    public canaisComunicacao: string | undefined;
    public tipoColeta: string | undefined;
    public funcionariosEmpresa: number | undefined;
    public fabricaResiduos: boolean | undefined;
    public residuoProducao: string | undefined;
    public qtdResiduoDescartado: string | undefined;
    public frequenciaColeta: string | undefined;
    public descarecterizacaoDocumento: string | undefined;
    public solucaoAterro: string | undefined;
    public salasAdm: number | undefined;
    public banheirosCozinhasRefeitorios: number | undefined;
    public cargoEvento: string | undefined;
    public diasHorarioEvento: string | undefined;
    public qtdPessoasLixeiraCentral: number | undefined;
    public coletaPosEvento: string | undefined;
    public cpf: string | undefined;
    public rg: number | undefined;
    public planoColetaDomiciliar: PlanoClienteEntity | undefined;
    public assinatura: string | undefined;
    public horarioColeta: string | undefined;
}