import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialMainComponent } from './material-main/material-main.component';
import { MaterialInsertEditComponent } from './material-insert-edit/material-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from 'src/app/core/directives/directives.module';




@NgModule({
  declarations: [
    MaterialMainComponent,
    MaterialInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,
    AngularEditorModule,
    FormsModule,
    MatTooltipModule,
    DirectivesModule
  ]
})
export class MaterialModule { }
