import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ArquivosClientesEntity} from "../../../_entitys/arquivos-clientes.entity";
import {ConfigDgCrudService} from "../../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";

@Injectable({
  providedIn: 'root'
})
export class ArquivosClientesService extends AbstractHttpService<ArquivosClientesEntity> {

  constructor(
    public config: ConfigDgCrudService,
    private files: FilesManageService
  ) {
    super(config, environment.apiUrl, 'arquivo-cliente');
  }

  downloadFile(id: number | string | undefined) {
    if (!id) throw 'id de download não encontrado';

    this.findOne(id, 'download').subscribe(
      resp => {
        // @ts-ignore
        if (resp.data.file) {
          // @ts-ignore
          this.files.downloadBase64(resp.data.file)
        }
      }
    )
  }

  override update(data: any, endpoint: string | boolean): Observable<ArquivosClientesEntity> {

    if ('arquivo' in data) {
      delete data.arquivo;
    }

    //@ts-ignore
    return super.update(data, endpoint);
  }
}
