import { Component, OnInit, ViewChild } from '@angular/core';
import { GalpaoArquivoMotoristaEntity } from '../galpao-arquivo-motorista.entity';
import { GalpaoArquivoMotoristaService } from '../service/galpao-arquivo-motorista.service';
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import { CrudDataTableComponent, InterfaceCustomActions } from '@datagrupo/dg-crud';
import Swal from 'sweetalert2';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galpao-arquivo-motorista-main',
  templateUrl: './galpao-arquivo-motorista-main.component.html',
  styleUrls: ['./galpao-arquivo-motorista-main.component.scss']
})
export class GalpaoArquivoMotoristaMainComponent implements OnInit {

  public mapEntity = new GalpaoArquivoMotoristaEntity();

  @ViewChild('tableGalpaoArquivoMotorista')tableGalpaoArquivoMotorista!: CrudDataTableComponent

  constructor(
    public service: GalpaoArquivoMotoristaService,
    private filesManage: FilesManageService,
    public session: SessionService,
    public route: Router
  ) {
  }

  ngOnInit(): void {
  }

  download = (data:GalpaoArquivoMotoristaEntity) => {
    if (data.id) {
      this.service.download(Number(data.id)).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data, data.arquivo.fileNameOriginal)
        }
      )
    }
  }
}
