import { FormControl, FormGroup } from "@angular/forms";
import Swal from "sweetalert2";


export const formataData = (dataString?: string) => {
  var data = new Date(!!dataString ? dataString : '');

  var dia = data.getDate().toString(),
    diaF = (dia.length == 1) ? '0' + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length == 1) ? '0' + mes : mes,
    anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
}

export const formata_data_utc_br = (dataString: string) => {
  return new Date(dataString).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
}

//TODO implementar sort_array_by_atribute
// implementar um helper que leia um array e ordene de acordo com um atributo interno do array
export function sort_array_by_atribute(array: any[], attribute: string | string[]) {

}

export function fotmataDinheiro(valor: number | string) {
  if (!Number(valor)) {
    return '0,00';
  }
  return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function clearString(str: string): string {
  if (typeof str !== "string") {
    return 'error';
  }
  str = str.replace(/a|A|á|Á|â|Â|à|À|ä|Ä|ã|Ã/g, 'a')
  str = str.replace(/e|E|é|É|ê|Ê|è|È|ë|Ë/g, 'e')
  str = str.replace(/i|I|í|Í|î|Î|ì|Ì|ï|Ï/g, 'i')
  str = str.replace(/u|U|ú|Ú|û|Û|ù|Ù|ü|Ü/g, 'u')
  str = str.replace(/o|O|ó|Ó|ô|Ô|ò|Ò|ö|Ö|õ|Õ/g, 'o')
  str = str.replace(/ç|Ç/g, 'ç')
  str = str.replace(/c|C/g, 'c')

  return str.toUpperCase();
}

export function dispacherAlterTable(table: string): void {
  window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: table }));
}

export function defaultCallBackErrorGetCep(f: FormGroup, showMsgError: boolean = true) {
  if (showMsgError) {
    Swal.fire({
      title: 'CEP não encontrado',
      text: 'Por favor preencha as informações de endereço',
      icon: 'warning',
    })
    f.patchValue({
      "logradouro": "",
      "numero": "",
      "complemento": "",
      "bairro": "",
      "cidade": "",
      "uf": ""
    })
  }

  f.controls['logradouro'].enable();
  f.controls['bairro'].enable();
  f.controls['cidade'].enable();
  f.controls['uf'].enable()
}

export function defaultCallBackSuccessGetCep(f: FormGroup) {
  f.controls['logradouro'].disable();
  f.controls['bairro'].disable();
  f.controls['cidade'].disable();
  f.controls['uf'].disable()
}

// ajusta a data para possibilitar o ctrl+v nos inputs type=date
export function ajusteDateByPaste(ev: ClipboardEvent, callback: (text: string) => void) {
  let text = String(ev.clipboardData?.getData('text'))

  if (text.includes('/')) {
    text = text.split('/').reverse().join('-')
  }

  const date = new Date(text)

  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (!isNaN(<any>date)) {
      callback(text)
    }
  }


}

export function verifySizeFiles(file: File | undefined, size = 20971520) {
  if (!file) return false;
  if ((file?.size || 0) > size) {
    Swal.fire({
      icon: 'error',
      title: 'Tamanho do arquivo é maior que o permitido',
      text: 'O tamanho máximo permitido nos arquivos do sistema é de 20mb'
    })

    return false;
  }

  return true;
}
