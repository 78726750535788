import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroIndicadoresOdsMainComponent } from "./cadastro-indicadores-ods-main/cadastro-indicadores-ods-main.component";
import { CadastroIndicadoresOdsInsertEditComponent } from "./cadastro-indicadores-ods-insert-edit/cadastro-indicadores-ods-insert-edit.component";
import { UiModule } from "../../../shared/ui/ui.module";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from "@angular/material/button";
import { ModalMetaOdsComponent } from './subComponents/modal-meta-ods/modal-meta-ods.component';
import { ModalIndicadorMetaOdsComponent } from './subComponents/modal-indicador-meta-ods/modal-indicador-meta-ods.component';

@NgModule({
  declarations: [
    CadastroIndicadoresOdsMainComponent,
    CadastroIndicadoresOdsInsertEditComponent,
    ModalMetaOdsComponent,
    ModalIndicadorMetaOdsComponent,
  ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        RouterModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class CadastroIndicadoresOdsModule { }
