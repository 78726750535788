import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { CupomEntity } from '../cupom.entity';
import { ConfigDgCrudService } from '../../../../core/config/config-dg-crud/config-dg-crud.service';
import { CupomService } from '../services/cupom/cupom.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultKolkovTextEditor } from '../../../../core/config/default-kolkov-text-editor';

@Component({
  selector: 'app-cupom-insert-edit',
  templateUrl: './cupom-insert-edit.component.html',
  styleUrls: ['./cupom-insert-edit.component.scss'],
})
export class CupomInsertEditComponent
  extends AbstractInsertEdit<CupomEntity>
  implements OnInit
{
  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    enviarCupomTodos: new FormControl(false),
    descricao: new FormControl(''),
  });

  public mapEntity = new CupomEntity();

  editorConfig = DefaultKolkovTextEditor;

  public listCupom: CupomEntity[] = [];

  constructor(
    public config: ConfigDgCrudService,
    public service: CupomService
  ) {
    super(config, service);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity() {
    // @ts-ignore
    this.form.patchValue({
      ...this.entity,
    });
  }

  initNewEntity(): void {
    this.entity = new CupomEntity();
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    };

    return true;
  }
}
