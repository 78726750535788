import { InterfMenuList } from "./admin.menuList";

export const CasaMenuList: InterfMenuList[] = [
    {
        menuList: [
            { url: '/user', nome: 'Início', icon: 'desktop_windows' },
            { url: '/user/galpao/arquivos-motoristas', nome: 'Arquivos De Motorista/Catador', icon: 'assignment' },
            { url: '/user/galpao/arquivos', nome: 'Arquivos Do Transportador', icon: 'assignment' },
            { url: '/user/programa-logistica-reversa', nome: 'Programa Logística Reversa', icon: 'emoji_objects' },
            { url: '/user/selecionar-plano', nome: 'Selecionar Plano', icon: 'local_mall' },
          // { url: '/user/controle-pesagem', nome: 'Controle de Peso Final', icon: 'scale' },
        ]
    }
];
