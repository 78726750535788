import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-esteira-desenvolvimento',
  templateUrl: './esteira-desenvolvimento.component.html',
  styleUrls: ['./esteira-desenvolvimento.component.scss']
})
export class EsteiraDesenvolvimentoComponent implements OnInit {

  @Input('environment') environment: any = {};

  constructor() { }

  ngOnInit(): void {
  }

}
