<div id="renovar-senha">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
        <card>
          <div class="card-header">
            <h4 class="card-title">Esqueci minha senha</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-12">
                  <label class="fomr-label">Nova senha</label>
                  <div class="input-group">
                    <input [type]="typePassEqual" class="form-control" formControlName="newPassword">
                    <span class="input-group-text" (click)="typePassEqual === 'password' ? typePassEqual = 'text' : typePassEqual = 'password'">
                      <mat-icon class="icon-view">{{typePassEqual === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                  <label class="fomr-label">Repetir nova senha</label>
                  <div class="input-group">
                    <input [type]="typePass" class="form-control" formControlName="confirmNewPassword">
                    <span class="input-group-text" (click)="typePass === 'password' ? typePass = 'text' : typePass = 'password'">
                      <mat-icon class="icon-view">{{typePass === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="card-footer">
            <button class="btn btn-success" (click)="enviar()">Enviar</button>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>
