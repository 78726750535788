<form [formGroup]="form">
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9">
              <h4 class="card-title">Dados do Destinador</h4>
            </div>
            <div class="card-header-button col-xl-2 col-lg-2 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header" [disabled]="form.invalid" (click)="limparDados()">Limpar dados</button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <label for="cnpj" class="form-label">CNPJ <span class="fc-red">*</span></label>
                <input type="text" formControlName="cnpj" class="form-control" id="cnpj" mask="00.000.000/0000-00"
                  placeholder="00.000.000/0000-00" (focusout)="getCNPJ()">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <label for="nome" class="form-label">NOME<span class="fc-red">*</span></label>
                <input propertValue="nome" formControlName="nome" id="nome" class="form-control">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <label for="inscricaoEstadual" class="form-label">INSCRIÇÃO ESTADUAL <span class="fc-red">*</span></label>
                <input type="text" formControlName="inscricaoEstadual" placeholder="Ex: 00000000"
                  class="form-control" id="inscricaoEstadual">
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6">
                  <h4 class="card-title">Endereço</h4>
                </div>
                <div class="col-6 d-inline-flex justify-content-end">
                  <button class="btn btn-success btn-sm" (click)="addCepManual()">Adicionar dados manualmente</button>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label for="cep" class="form-label">CEP <span class="fc-red">*</span></label>
                <input type="text" formControlName="cep" maxlength="10" class="form-control" id="cep"
                  placeholder="00.000-000" mask="00.000-000">
              </div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <label for="logradouro" class="form-label">LOGRADOURO <span class="fc-red">*</span></label>
                <input type="text" formControlName="logradouro" class="form-control" id="logradouro">
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <label for="numero" class="form-label">NUMERO <span class="fc-red">*</span></label>
                <input type="text" formControlName="numero" class="form-control" id="numero">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <label for="complemento" class="form-label">COMPLEMENTO</label>
                <input type="text" formControlName="complemento" class="form-control" id="complemento">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <label for="bairro" class="form-label">BAIRRO <span class="font-color-red">*</span></label>
                <input formControlName="bairro" type="text" class="form-control" id="bairro">
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label for="cidade" class="form-label">CIDADE <span class="fc-red">*</span></label>
                <input formControlName="cidade" type="text" class="form-control" id="cidade">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <label for="uf" class="form-label">UF <span class="fc-red">*</span></label>
                <input formControlName="uf" type="text" class="form-control" id="uf">
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</form>

<div class="container-data" *ngIf="!!this.entity.id">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <h4 class="card-title">Arquivos do destinador</h4>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 card-header-buttons">
              <button class="btn btn-success" (click)="modalIndustriaArquivosComponent.open()">Adicionar</button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <dg-table dg-dynamic-table [dynamicTable]="tableArquivos" [initAutoLoader]="false"></dg-table>
        </div>
        <div class="card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableArquivos"></dg-paginator>
        </div>

      </div>
    </div>
  </div>
</div>


<modal-industria-arquivos
  #modalIndustriaArquivosComponent
  (afterSave)="tableArquivos.find()"
  [industriaId]="this.entity.id"
></modal-industria-arquivos>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button class="btn btn-success" (click)="salvarAssociar()">{{!!industriaAssociada && !session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? 'Associar Indústria/Receptor' : 'Salvar e Voltar'}}</button>
</page-footer>
