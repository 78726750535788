<old-dg-modal
  #modalPost
  size="xl"
  title="Posts"
  [beforeClose]="close"
>
  <div dg-content *ngIf="modalPost.config.open" class="dg-content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-3">
          <card>
            <div class="card-body">
              <ng-container *ngTemplateOutlet="cardUploadImage"></ng-container>
            </div>
          </card>
        </div>
        <div class="col-9">
          <card>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <label class="form-label">TÍTULO</label>
                  <input type="text" formControlName="titulo" class="form-control">
                </div>
                <div class="col-4 d-flex align-items-center">
                  <input class="form-check-input" formControlName="publica" style="margin-right: .5rem" type="checkbox"
                         id="publica">
                  <label class="form-check-label" for="publica">
                    POSTAGEM PÚBLICA?
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label class="form-label">DESCRIÇÃO</label>
                  <angular-editor formControlName="conteudo" [config]="editorConfig"></angular-editor>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-2">
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <pick-list
                    #pickListGrupos
                    titleList="Grupo de Comunicação"
                    [listaDisponivel]="listGrupos"
                    [listaSelecionados]="entity.grupos"
                    [showSearchInput]="true"
                    (changeSelect)="getListClientes($event)"
                    propertItem="nome"
                  ></pick-list>
                </div>
                <div>
                  <pick-list
                    #pickListClientes
                    titleList="Geradores"
                    [listaDisponivel]="listClientes"
                    [listaSelecionados]="entity.clientes"
                    [showSearchInput]="true"
                    (changeSelect)="getListClientes($event)"
                    propertItem="nome"
                  ></pick-list>
                </div>
              </div>

              <div class="col-3 mt-5">
                <div class="container">
                  <label class="form-label">ENVIAR PARA:</label>
                  <div class="form-check">
                    <input formControlName="destinatario" class="form-check-input" type="radio" value="AMBOS"
                           id="ambos">
                    <label class="form-check-label" for="ambos">
                      Ambos
                    </label>
                  </div>
                  <div class="form-check">
                    <input formControlName="destinatario" class="form-check-input" type="radio" value="BLOG" id="blog">
                    <label class="form-check-label" for="blog">
                      Blog
                    </label>
                  </div>
                  <div class="form-check">
                    <input formControlName="destinatario" class="form-check-input" type="radio" value="NEWS"
                           id="newsLetter">
                    <label class="form-check-label" for="newsLetter">
                      NewsLetter
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </form>
  </div>

  <div dg-footer>
    <button (click)="publicar()" class="btn btn-success m-1">Publicar</button>
    <button class="btn btn-secondary m-1" (click)="modalPost.close()">Sair</button>
  </div>
</old-dg-modal>


<ng-template class="container-fluid" #cardUploadImage>
  <img
    [src]="imageSrc || files.getImageToSrc(entity?.imagem)"
    class="w-100"
    alt="imagem do Posts"
  >
  <div class="d-inline-flex justify-content-center w-100">
<!--    <button [disabled]="!entity?.id" class="btn btn-success m-1" (click)="imgPostBlog.click()">Escolher</button>-->
    <button class="btn btn-success m-1" (click)="imgPostBlog.click()">Escolher</button>
  </div>
  <input
    #imgPostBlog
    type="file"
    [(ngModel)]="fileInput"
    (change)="changeInputFile($event)"
    accept="image/png, image/jpeg, image/jpg"
    style="display: none"
  >
</ng-template>
