import {AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import { autoCompleteCategoriaIndustriaOutPut } from "src/app/helpers/auto-complete/categoria-industria-autocomplete.helper";
import { environment } from "src/environments/environment";
import {Enderecos} from "../../../shared/entitys/enderecos";


export class IndustriaDestinoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    cnpj?: string,
    inscricaoEstadual?: string,
    endereco?: Enderecos[]
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.cnpj = cnpj;
    this.inscricaoEstadual = inscricaoEstadual
    this.endereco = Array.isArray(endereco) ? endereco : [];;
  }

  @DgFilter_autoComplete({colum: '4'}, {
    getUrl: environment.apiUrl + 'industria/nomes',
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'industriaId',
    nameParamFilter: 'nome',
    outputFormat: autoCompleteCategoriaIndustriaOutPut
  })
  @DgTableColumn({columnName: 'Nome', sort: true})
  public nome: string | undefined

  @DgFilter_input('text', {mask: '00.000.000/0000-00'})
  @DgTableColumn({columnName: 'CNPJ', mask: '00.000.000/0000-00', sort: true})
  public cnpj: string | undefined

  @DgTableColumn({columnName: 'Inscrição Estadual', mask: '00.000.00-0', sort: true})
  public inscricaoEstadual: string | undefined

  public endereco: Enderecos[] = []

}
