import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Observable } from 'rxjs';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { BeneficiosEntity } from '../beneficios.entity';

@Injectable({
  providedIn: 'root'
})
export class BeneficiosService extends AbstractHttpService<BeneficiosEntity> {
  public test = {
    data: <BeneficiosEntity[]>[]
  }
  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'beneficios')
    this.httpServ.get<{ data: BeneficiosEntity[] }>(this.resource).subscribe(
      resp => {
        //@ts-ignore
        this.test.data = resp.data
      }
    )
  }
  override findOne(id: number | string, endpoint: string): Observable<BeneficiosEntity> {
    return this.httpServ.get<BeneficiosEntity>(`${this.resource}?data?id=${id}`)
  }

  override update(data: any, endpoint: string | boolean): Observable<BeneficiosEntity> {
    this.test.data[data.id] = data;
    // @ts-ignore
    return super.update(this.test, '');
  }

  override save(data: any, endpoint: string | boolean): Observable<BeneficiosEntity> {
    this.test.data.push({
      ...data,
      id: this.test.data.length
    });
    // @ts-ignore
    return super.save(this.test, '');
  }

  override delete(id?: number | string, ids?: number[] | string[], endpoint?: string): Observable<BeneficiosEntity> {
    this.test.data.splice(Number(id), 1)

    // @ts-ignore
    return super.save(this.test, '');
  }
}

