<div id="tipo-lixo-insert-edit">


  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Cadastro de Tipo de Resíduo</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label class="form-label">Código IBAMA <span class="fc-red">*</span></label>
                <input
                  type="text"
                  formControlName="codigo"
                  class="form-control"
                  id="codigo"
                >
              </div>

              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label class="form-label">Descrição do tipo de resíduo <span class="fc-red">*</span></label>
                <input
                  type="text"
                  formControlName="nome"
                  class="form-control"
                  id="nome"
                >
              </div>

              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              </div>
              <label class="form-label">ABNT 10.004 <span class="fc-red">*</span></label>
              <input-autocomplete
              classes="form-control"
              formControlName="classesResiduos"
              [multiple]="true"
              key="id"
              id="classesResiduos"
              label="nome"
              [list]="(listClasse || [])"
              (close)="selectClassesResiduos($event)"
              >
            </input-autocomplete>
            </div>

            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Categoria Ecoplat <span class="fc-red">*</span></label>
                  <input
                    type="text"
                    formControlName="categoria"
                    class="form-control"
                    id="categoria"
                  >
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Densidade (kg/m³)</label>
                  <input
                    min="0"
                    formControlName="densidade"
                    class="form-control"
                    id="densidade"
                  >
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Unidades de medida</label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="unidadesMedida" multiple>
                      <mat-option *ngFor="let item of listUnidadesMedidas" [value]="item.id">{{item.nome || '--'}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Conama 357/2005</label>
                  <mat-form-field  appearance="outline">
                    <mat-select class="select-conama" formControlName="codigosConama" multiple>
                      <mat-option *ngFor="let item of listCodConama" [value]="item.id">
                        <div
                          style="width: 100%; height: 100%; padding-left: .2rem"
                          [style.background]="item?.codigoHexadecimal || 'white'"
                          [style.color]="'white'"
                        >
                          {{getNomeCoresConama(item?.codigoHexadecimal || '--')}}
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>

              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Código da ONU</label>
                  <input
                    type="text"
                    formControlName="codigoOnu"
                    class="form-control"
                    id="codigoOnu"
                  >
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Referência</label>
                  <input
                    type="text"
                    formControlName="referencia"
                    class="form-control"
                    id="referencia"
                  >
                </div>
              </div>

                <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Grupo de Mercado</label>
                  <input-autocomplete
                  classes="form-control"
                  formControlName="grupoMercado"
                  [multiple]="true"
                  key="id"
                  id="grupo"
                  label="nome"
                  [preSelectedList]="preSelectedGrupMercado"
                  [list]="(listGruposMercado || [])"
                  (close)="selecctedGrupoMercado($event)"
                  >
                </input-autocomplete>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <label class="form-label">Segmento de Mercado</label>
                <input-autocomplete
                  classes="form-control"
                  [multiple]="true"
                  formControlName="segmentoMercado"
                  id="segmentoMercado"
                  label="nome"
                  [preSelectedList]="preSelectedSegmentoMercado"
                  key="id"
                  [list]="(listSegmentosMercado || [])"
                  (close)="segmentoMercado($event)"
                  >
                  </input-autocomplete>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Segmento Operacional</label>
                  <input-autocomplete
                  classes="form-control"
                  formControlName="segmentosOperacionais"
                  [multiple]="true"
                  key="id"
                  id="segmentosOperacionais"
                  label="nome"
                  [preSelectedList]="preSelectedSegmentosOperacionais"
                  [list]="(listSegmentosOperacionais || [])"
                  (close)="selectSegmentosOperacionais($event)"

                  >
                  </input-autocomplete>
                </div>
              </div>

                  <div class=" col-12">
                  <label class="form-label">Observações adicionais</label>
                  <textarea
                    type="text"
                    formControlName="observacoes"
                    class="form-control"
                    id="observacoes"
                  >
                  </textarea>
                </div>

                  <div class="col-12">
                    <label class="form-label">Detalhamento Descritivo</label>
                    <textarea  id="detalhamentoDescritivo" class="form-control" formControlName="detalhamentoDescritivo">
                    </textarea>
                  </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>


  <div
    class="container-data"
    *ngIf="!!entity?.id"
  >
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Resíduo Composto</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="!!entity?.id"
                    class="btn btn-success btn-card-header"
                    (click)="modal.open()"
                  >
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #crudDataTable
              tableEventId="tipoLixoComposto"
              [entityMap]="entityTipoLixo"
              [data]="entity.listTipoLixoComposto"
              [customActions]="customActionsLixoComposto"
            >
            </crud-data-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
</page-footer>


<modal-tipo-lixo-filho
  #modal
  title="Preencha o tipo de resíduo"
  [listTipoLixo]="entity?.listTipoLixoComposto"
  [tipoLixoId]="entity?.id"
></modal-tipo-lixo-filho>
