
<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">Home</h1>
</header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Sobre essa documentação</h4>
        <p>
          Essa é uma documentação apenas para facilitar o desenvolvimento e compreensão. Sua implementação
          ocorrerá conforme os componentes forem mapeados, por isso se encontrar necessidade, cadastre novos fluxos
          com componentes a essa documentação
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-warning" role="alert">
        <h4 class="alert-heading">Atenção</h4>
        <p>
          Muitos dos componentes e estruturas foram melhorando ao longo do caminho, por isso algumas telas
          porem apresentar <span class="alert-link">componentes diferentes fazendo a mesma coisa</span>.
        </p>
        <p>
          Não exclusivo a componentes algumas <span class="alert-link">directives também podem encontrar esse cenário</span>.
        </p>
        <hr>
        <p>
          Sempre que identificar um componente/directive/fluxo repetitivo que esteja em processo de migração
          <span class="alert-link">será sinalizado dentro da tela de informações do mesmo</span>.
        </p>
      </div>
    </div>
  </div>
</div>

