import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicadoresMainComponent } from './indicadores-main/indicadores-main.component';
import { IndicadoresInsertEditComponent } from './indicadores-insert-edit/indicadores-insert-edit.component';
import { UiModule } from "../../../shared/ui/ui.module";
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/core/directives/directives.module';

@NgModule({
    declarations: [
        IndicadoresMainComponent,
        IndicadoresInsertEditComponent
    ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        RouterModule,
        DirectivesModule

    ]
})
export class IndicadoresModule { }
