import {ProdutoEntity} from "../../pages/_user/produto/produto.entity";
import {environment} from "../../../environments/environment";


export const AutoCompleteGalpaoOutPut = (value: string | number, row: ProdutoEntity) => {
  if (!row) {
    return { nomeGalpao: value }
  }
  return { idGalpao: row.id }
}


export const autoComplete_galpao = {
  getServerData: 'all',
  getUrl: environment.apiUrl + 'galpoes/nomes',
  propertValue: 'nome',
  nameParamFilter: 'nome',
  propertKey: 'id',
  outputFormat: AutoCompleteGalpaoOutPut
}
