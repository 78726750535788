import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { VendasLogisticaReversaEntity } from '../vendas-logistica-reversa.entity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendasLogisticaReversaService extends AbstractHttpService<VendasLogisticaReversaEntity>  {

  constructor(
    public conf: ConfigDgCrudService
  ) {
    super(conf, environment.apiUrl, 'venda-logistica-reversa');

    this.dictionary.findAll = {
      vendaClienteclrDTO: 'clientes',
      vendaProdutosNotaFiscal: 'produtos'
    }
  }
}
