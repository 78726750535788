<div
    id="cadastre-plano-insert"
    class="container"
>
    <div class="row">
        <div class="container-data">
            <div class="card-body">
                <div class="card-header">
                    <h4>Dados do Plano Pontual</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                                <label>
                                    Nome
                                    <small class="fc-red">*</small>
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="nome"
                                >
                            </div>
                        </div>
                        <div class="row mt-4">


                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                <label
                                    for="ativo"
                                    class="pe-2"
                                >
                                    Ativo
                                </label>
                                <input
                                    type="checkbox"
                                    name="ativo"
                                    id="ativo"
                                    formControlName="ativo"
                                >
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                <label
                                    for="pago"
                                    class="pe-2"
                                >
                                    Pago
                                </label>
                                <input
                                    type="checkbox"
                                    name="pago"
                                    id="pago"
                                    formControlName="pago"
                                >
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12">
                                <label
                                    for="exclusivo"
                                    class="pe-2"
                                >
                                    Quem Pode Contratar
                                </label>
                                <input
                                    type="checkbox"
                                    name="exclusivo"
                                    id="exclusivo"
                                    formControlName="exclusivo"
                                >
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-xl-3 col-lg-7 col-md-9 col-sm-12">
                                <label>Periodicidade: Pontual</label>
                                <div class="pt-3">
                                    <label>
                                        Valor
                                        <small class="fc-red">*</small>
                                    </label>
                                    <div class="input-group w-100">
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                R$:
                                            </span>
                                        </div>
                                        <input
                                            class="form-control"
                                            formControlName="pontual"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                    <div
                        class="container-fluid mt-5"
                        *ngIf="!!form.value.exclusivo"
                    >
                        <pick-list
                            #pickList
                            [listaDisponivel]="listaSelect"
                            [listaSelecionados]="listaSelecionados"
                            propertItem="value"
                            titleList="Geradores"
                        ></pick-list>
                    </div>


                    <div class="mt-4">
                        <button
                            class="btn btn-success me-2"
                            (click)="addItem()"
                        >
                            Adicionar item a descrição
                        </button>
                    </div>
                    <div class="row">
                        <div
                            class="col-xl-6 col-lg-7 col-md-9 col-sm-12 mt-3"
                            *ngFor="let item of listItems; let i = index"
                        >
                            <label class="form-label">Item {{i + 1}}</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="item.value"
                                >
                                <span
                                    class="input-group-text"
                                    style="cursor: pointer"
                                    (click)="removerItem(i)"
                                >
                                    <mat-icon>close</mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
