import { MeuPerfilModule } from './meu-perfil/meu-perfil.module';
import { RankingColetasModule } from './_coletas/ranking-coletas/ranking-coletas.module';
import { VendasLogisticaReversaModule } from './vendas-logistica-reversa/vendas-logistica-reversa.module';
import { HistoricoColetasModule } from './_coletas/historico-coletas/historico-coletas.module';
import { InformacoesGeraisModule } from './informacoes-gerais/informacoes-gerais.module';
import { LogisticaReversaModule } from './logistica-reversa/logistica-reversa.module';
import { GalpaoArquivoModule } from './_galpao/galpao-arquivo/galpao-arquivo.module';
import { RotasModule } from './rotas/rotas.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardModule } from "./dashboard/dashboard.module";
import { UserRoutingModule } from "./user-routing.module";
import { GalpaoModule } from "./_galpao/galpao/galpao.module";
import { ClientesModule } from "./_clientes/clientes/clientes.module";
import { ColetasModule } from "./_coletas/coletas/coletas.module";
import { ContainerModule } from "./container/container.module";
import { GalpaoMotoristaModule } from './_galpao/galpao-motorista/galpao-motorista.module';
import { ProdutoModule } from './produto/produto.module';
import { GalpaoArquivoMotoristaModule } from './_galpao/galpao-arquivo-motorista/galpao-arquivo-motorista.module';
import { IndustriaDestinoModule } from './industria-destino/industria-destino.module';
import { FuncionarioModule } from './funcionario/funcionario.module';
import { MaterialModule } from './material/material.module';
import { CategoriaArquivoModule } from './categoria-arquivo/categoria-arquivo.module';
import { MissaoModule } from './missao/missao.module';
import { CupomModule } from "./cupom/cupom.module";
import { RelatorioMissoesModule } from './relatorio-missoes/relatorio-missoes.module';
import { ClientesFuncionariosModule } from "./_clientes/clientes-funcionarios/clientes-funcionarios.module";
import { CategoriaNegocioModule } from './categoria-negocio/categoria-negocio.module';
import { CadastroGravimetriaModule } from './cadastro-gravimetria/cadastro-gravimetria.module';
import { NotaFiscalModule } from "./_nota-fiscal/nota-fiscal/nota-fiscal.module";
import { SelecionarPlanoModule } from './selecionar-plano/selecionar-plano.module';
import { MissoesModule } from './missoes/missoes.module';
import { ParceiroModule } from './parceiro/parceiro.module';
import { CobrancasModule } from "./cobrancas/cobrancas.module";
import { CupomRelatorioModule } from "./cupom-relatorio/cupom-relatorio.module";
import { NewslettersModule } from './newsletters/newsletters.module';
import { BeneficiosModule } from "./beneficios/beneficios.module";
import { CadastrePlanoModule } from './cadastre-plano/cadastre-plano.module';
import { FluxoCobrancaModule } from './fluxo-cobranca/fluxo-cobranca.module';
import { FaqPerguntasFrequentesModule } from "./faq-perguntas-frequentes/faq-perguntas-frequentes.module";
import { TutorialModule } from "./tutorial/tutorial.module";
import { AdministracaoModule } from './administracao/administracao.module';
import { SelecionarPlanoPontualModule } from './selecionar-plano-pontual/selecionar-plano-pontual.module';
import { UnidadeMedidaModule } from "./unidade-medida/unidade-medida.module";
import { GrupoComunicacaoModule } from './grupo-comunicacao/grupo-comunicacao.module';
import {TratamentoModule} from "./tratamento/tratamento.module";
import { JornadaEducacaoAmbientalModule } from './jornada-educacao-ambiental/jornada-educacao-ambiental.module';
import { GestaoJornadaEducacaoAmbientalModule } from './gestao-jornada-educacao-ambiental/gestao-jornada-educacao-ambiental.module';
import { GestaoProgramaLogisticaReversaModule } from './gestao-programa-logistica-reversa/gestao-programa-logistica-reversa.module';
import { ProgramaLogisticaReversaModule } from './programa-logistica-reversa/programa-logistica-reversa.module';
import { PedidoCreditoLogisticaReversaModule } from './pedido-credito-logistica-reversa/pedido-credito-logistica-reversa.module';
import { IndicadoresModule } from './indicadores/indicadores.module';
import { IndicadoresOdsModule } from './indicadores-ods/indicadores-ods.module';
import {CadastroIndicadoresOdsModule} from "./cadastro-indicadores-ods/cadastro-indicadores-ods.module";
import {WhiteLabelModule} from "./white-label/white-label.module";
import {ControlePesagemModule} from "./controle-pesagem/controle-pesagem.module";
import {Dashboard2Module} from "./dashboard2/dashboard2.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserRoutingModule,
    DashboardModule,
    GalpaoModule,
    ClientesModule,
    ColetasModule,
    ContainerModule,
    GalpaoMotoristaModule,
    CategoriaArquivoModule,
    GalpaoMotoristaModule,
    GalpaoArquivoModule,
    GalpaoArquivoMotoristaModule,
    ProdutoModule,
    ProdutoModule,
    IndustriaDestinoModule,
    FuncionarioModule,
    CupomModule,
    ClientesFuncionariosModule,
    CupomModule,
    FuncionarioModule,
    MaterialModule,
    RelatorioMissoesModule,
    IndustriaDestinoModule,
    CategoriaNegocioModule,
    CupomRelatorioModule,
    RotasModule,
    FuncionarioModule,
    MissaoModule,
    RelatorioMissoesModule,
    CadastroGravimetriaModule,
    NotaFiscalModule,
    MissaoModule,
    MissoesModule,
    SelecionarPlanoModule,
    ParceiroModule,
    RotasModule,
    CobrancasModule,
    NewslettersModule,
    LogisticaReversaModule,
    InformacoesGeraisModule,
    HistoricoColetasModule,
    ParceiroModule,
    BeneficiosModule,
    CadastrePlanoModule,
    FluxoCobrancaModule,
    BeneficiosModule,
    FaqPerguntasFrequentesModule,
    TutorialModule,
    AdministracaoModule,
    RankingColetasModule,
    VendasLogisticaReversaModule,
    SelecionarPlanoPontualModule,
    MeuPerfilModule,
    UnidadeMedidaModule,
    GrupoComunicacaoModule,
    TratamentoModule,
    JornadaEducacaoAmbientalModule,
    GestaoJornadaEducacaoAmbientalModule,
    GestaoProgramaLogisticaReversaModule,
    ProgramaLogisticaReversaModule,
    TratamentoModule,
    PedidoCreditoLogisticaReversaModule,
    IndicadoresModule,
    IndicadoresOdsModule,
    CadastroIndicadoresOdsModule,
    WhiteLabelModule,
    ControlePesagemModule,
    Dashboard2Module
  ]
})
export class UserModule { }
