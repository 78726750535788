<div class="container-data">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Container</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label for="nome" class="form-label">NOME<span class="fc-red">*</span></label>
                <input type="text" formControlName="nome" class="form-control" id="nome">
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label for="capacidadeLitros" class="form-label">CAPACIDADE (LITROS) <span class="fc-red">*</span></label>
                <input type="text" formControlName="capacidadeLitros" class="form-control" id="capacidadeLitros" mask="separator" thousandSeparator="." suffix="L" >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <label for="capacidadeQuilos" class="form-label">CAPACIDADE (KG) <span class="fc-red">*</span></label>
              <input type="text" formControlName="capacidadeQuilos" class="form-control" id="capacidadeQuilos" mask="separator" thousandSeparator="." suffix="Kg">
            </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <label class="form-label" for="inea">CONTAINER MTR INEA</label>
                <input-autocomplete
                #autoCompleteInea
                formControlName="inea"
                classes="form-control"
                key="id"
                id="inea"
                [list]="(containersMtrInea || [])"
                [showSelectedList]="true"
                label="descricao">
               </input-autocomplete>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <label class="form-label" for="sinir">CONTAINER MTR SINIR</label>
                <input-autocomplete
                #autoCompleteSinir
                formControlName="sinir"
                classes="form-control"
                key="id"
                id="sinir"
                [list]="(containersMtrSinir || [])"
                [showSelectedList]="true"
                label="descricao">
               </input-autocomplete>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <label class="form-label" for="sigor">CONTAINER MTR SIGOR</label>
                <input-autocomplete
                #autoCompleteSigor
                formControlName="sigor"
                classes="form-control"
                key="id"
                id="sigor"
                [list]="(containersMtrSigor || [])"
                [showSelectedList]="true"
                label="descricao">
               </input-autocomplete>
            </div>
            </div>




            <div class="row mt-5">
              <div class="col-12">
                <label for="descricao" class="form-label">DESCRIÇÃO <span class="fc-red">*</span></label>
                <textarea
                  class="form-control"
                  formControlName="descricao"
                  id="descricao"
                  rows="7"
                  maxlength="2000"
                  ></textarea>
                <label class="text-muted fs-9">{{2000 - form.value.descricao.length}} caracteres restantes</label>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>
</div>



<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-deletar class="btn btn-secondary">Excluir</button>
  <!-- <button *ngIf="entityId && !entity?.dataDesativacao" class="btn btn-danger" (click)="ativarDesativar()" >Desativar Container</button>
  <button *ngIf="entityId && entity?.dataDesativacao" class="btn btn-success" (click)="ativarDesativar()" >Ativar Container</button> -->
  <button
  *ngIf="entityId"
  class="btn"
  (click)="ativarDesativar()"
  [class.btn-success]="entity?.dataDesativacao"
  [class.btn-danger]="!entity?.dataDesativacao"
>
  {{!entity?.dataDesativacao ? 'desativar' : 'ativar'}} container
</button>

</page-footer>
