<print-loading
  #printLoading
  [list]="list"
  [visible]="showModal"
  (completeList)="print()"
>
</print-loading>

<div class="folhas-dashboard-print">

  <div class="container-data">
    <card>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12">
            <div class="row justify-content-between">
              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold">Número de clientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{cardInfo.numeroClientes}}</span>
                    </div>
                  </div>
                </card>
              </div>

              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span matTooltip="Número de apartamentos/departamento/lojas"
                            class="destaque-claro f-bold">Ambientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{cardInfo.numeroApartmentos}}</span>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>

  <div class="container-data">
    <div class="row d-flex align-items-stretch">
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Ano</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.anos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.anos" id="id-chart-anos"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Meses</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.meses" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.meses" id="id-chart-meses"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Totais</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.totais" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.totais" id="id-chart-totais"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Resíduos</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.residuos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.residuos" id="id-chart-residuos"></div>
          </div>
        </card>
      </div>
      <div
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <card>
          <div class="card-header">
            <h4>Motorista/Catador</h4>
          </div>
          <div class="card-body">
            <div *ngIf="loaders.residuos" class="h-100 w-100 d-flex justify-content-center align-items-center">
              <mat-spinner></mat-spinner>
            </div>
            <div [hidden]="loaders.residuos" id="id-chart-motoristas"></div>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="!session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card-impacto-ambiental [loader]="loaders.impacto" [data]="impacto"></card-impacto-ambiental>
    </div>
  </div>

  <div *ngIf="!session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card>
        <div class="card-header">
          <h4 class="card-title">Gráfico de aterro zero</h4>
        </div>
        <div class="card-body">
          <div *ngIf="loaders.aterroZero" class="h-100 w-100 d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
          </div>
          <div [hidden]="loaders.aterroZero" id="id-chart-aterro-zero"></div>
        </div>
      </card>
    </div>
  </div>

  <div *ngIf="!session.checkPerfil('GALPAO')" class="container-data">
    <div class="row">
      <card-ods [data]="ods"></card-ods>
    </div>
  </div>

  <div class="container-data">
    <card>
      <div class="card-header">
        <h4 class="card-title">Detalhamento por Gerador</h4>
      </div>
      <div class="card-body">
        <table class="w-100">
          <thead>
          <tr>
            <th>
              Data Coleta
            </th>
            <th>
              Gerador
            </th>
            <th>
              Peso da coleta (TON)
            </th>
            <th>
              Peso recebido
            </th>
            <th>
              Número de Coletas
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of tabelaUnidades">
            <td>{{!!row.dataColeta ? row.dataColeta.split('-').reverse().join('/') : '--'}}</td>
            <td>{{row.unidadeGeradora}}</td>
            <td>{{row.estimativaPeso}}</td>
            <td>{{row.pesoDia}}</td>
            <td>{{row.numeroColetas}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>

  <div *ngIf="!session.checkPerfil('GALPAO')" class="container-data">
    <card>
      <div class="card-header">
        <h4 class="card-title">Detalhamento por Container do Gerador</h4>
      </div>
      <div class="card-body">
        <table>
          <thead>
            <tr>
              <th>Data da Coleta</th>
              <th>Gerador</th>
              <th>Nome dado pelo cliente</th>
              <th>Categoria Ecoplat </th>
              <th>Nome Ibama</th>
              <th>Código Ibama</th>
              <th>Classe</th>
              <th>Peso da coleta estimativa (TON)</th>
              <th>Peso da coletas (TON)</th>
              <th>Peso recebido</th>
              <th>Tratamento </th>
              <th>Código MTR</th>
              <th>Empresa que fez a coleta</th>
              <th>CNPJ da empresa que fez a coleta</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let gri of tabelaGRI">
            <td>{{dateToLocaleString(gri.dataColeta)}}</td>
            <td>{{gri.unidadeGeradora}}</td>
            <td>{{gri.identificacaoClienteContainer}}</td>
            <td>{{gri.categoriaTipoResiduo}}</td>
            <td>{{gri.nomeTipoResiduo}}</td>
            <td>{{gri.codigoTipoResiduo}}</td>
            <td>{{gri.classeTipoResiduo}}</td>
            <td>{{!!gri.estimativa ? numberToLocaleString(gri.estimativa) : '--'}}</td>
            <td>{{gri.peso}}</td>
            <td>{{gri?.pesoRecebido || '--'}}</td>
            <td>{{gri.tratamento}}</td>
            <td>{{gri.codigoMtr || '--'}}</td>
            <td>{{gri.nomeEmpresa}}</td>
            <td>{{gri.cnpjEmpresa}}</td>
          </tr>
          <tr *ngIf="(tabelaGRI || []).length == 0">
            <td colspan="14" style="text-align: center">Nenhum registro encontrado</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
      </div>
    </card>
  </div>
</div>
