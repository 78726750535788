import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { CupomEntity } from '../../cupom.entity';
import { ConfigDgCrudService } from '../../../../../core/config/config-dg-crud/config-dg-crud.service';
import { environment } from '../../../../../../environments/environment';
import { HttpOldService } from '../../../../../core/services/http/http-old.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CupomService extends AbstractHttpService<CupomEntity> {

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'cupom');
  }
}
