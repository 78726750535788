<div id="public-home">
    <nav class="navbar navbar-expand-md navbar-light bg-light nav-home">
        <div class="container">
            <a class="navbar-brand" href="#" style="max-height: 100%">
                <img src="/assets/img/site/logo-Mc.png" alt="" class="h-100">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <mat-icon>menu</mat-icon>
      </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" href="#o-que-fazemos">O QUE FAZEMOS</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#como-funciona">COMO FUNCIONA</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#nossos-parceiros">NOSSOS PARCEIROS</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <public-slider></public-slider>

    <div class="container" id="o-que-fazemos">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 cards-section-1" *ngFor="let card of listCardsSection1">
                        <card class="card-chamada">
                            <div class="card-header">
                                <mat-icon>recycling</mat-icon>
                            </div>
                            <div class="card-body">
                                <h5 class="font-fredoka fc-green">
                                    {{card.text}}
                                </h5>
                            </div>
                            <div class="card-footer">
                                <a [routerLink]="[card.link]" [queryParams]="card?.queryParams || {}">
                                    <button class="btn {{card.classButton}}">{{card.textButton}}</button>
                                </a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <h2 class="fc-green font-fredoka">
                    Somos o elo que faltava para
                    <span class="fc-black">Coleta seletiva</span> e a <span class="fc-black">Logística Reversa</span> funcionar.
                </h2>
                <p>
                    Somos o elo que faltava para Coleta seletiva e a Logística Reversa funcionar. A ECOPLAT é a Plataforma tecnológica que faltava para integrar a cadeia da coleta seletiva e logística reversa de forma democrática e rastreável.
                </p>
            </div>
        </div>
    </div>

    <div class="container section-3">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                <div class="container h-100 d-flex align-items-center justify-content-center" style="background-color: #1C774D">
                    <div class="row h-100 align-items-center justify-content-center box-text">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <h3 style="color: #55A346">Integramos toda <br> cadeia de resíduos.</h3>
                            <p class="fc-white">Do gerador até a indústria garantindo a coleta seletiva, gestão de resíduos e logística reversa.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 d-inline-flex justify-content-center">
                <img src="/assets/img/site/caminho-04.png" style="max-height: 100%; z-index: 100" alt="">
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row card-section-4">
            <div class="card">
                <div class="card-body">
                    <div class="row h-100">
                        <div class="h-100 col-4 bg-1">
                        </div>
                        <div class="h-100 col-4 bg-2 d-block">
                        </div>
                        <div class="h-100 col-4 bg-3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-cards-como_funciona" id="como-funciona">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h3 class="fc-green">COMO A <span class="fc-black">ECOPLAT</span> FUNCIONA</h3>
            </div>
        </div>

        <div class="row row-cards-como_funciona">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" *ngFor="let card of listCardsSection5">
                <div class="card">
                    <img [src]="card.img" class="card-img-top" alt="...">
                    <div class="card-header">
                        <h6 class="card-title">
                            {{card.title}}
                        </h6>
                    </div>
                    <div class="card-body">
                        <p>
                            {{card.text}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <subComponent-container-section direct="left" img="/assets/img/site/section-6/img-section-05.jpg">
        <div class="row justify-content-end" body-section>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <h4 class="font-fredoka fc-title-home" style="text-align: end">Selo ECOPLAT</h4>
                <p style="text-align: end">
                    Sua marca entregando produtos para as unidades geradoras com a garantia de
                    <span class="f-bold fc-yellow font-fredoka fs-14">logística reversa</span> com melhor custo benefício.
                </p>
            </div>
        </div>
        <div class="row" buttons-section>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: right">
                <button class="btn btn-success" routerLink="compre-credito-logistica-reversa">COMPRE CRÉDITOS</button>
            </div>
        </div>
    </subComponent-container-section>

    <subComponent-container-section img="/assets/img/site/section-7/img-section-07.jpg" direct="right">
        <div class="row" body-section>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <h4 class="font-fredoka fc-title-home" style="text-align: start">
                    Unidades geradoras ganhando descontos e vantagens!
                </h4>
                <p style="text-align: start">
                    Com parceiros da sustentabilidade
                    <span class="f-bold fc-yellow font-fredoka fs-15">ECOPLAT</span>.
                </p>
            </div>
        </div>
        <div class="row" buttons-section>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: left">
                <button class="btn btn-success" routerLink="/solicitacao-cadastro" [queryParams]="{perfil: 'unidade_geradora'}">CADASTRE-SE AQUI</button>
            </div>
        </div>
    </subComponent-container-section>

    <subComponent-container-section direct="left" img="/assets/img/site/section-8/img-section-08jpg.jpg">
        <div class="row justify-content-end" body-section>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <p style="text-align: end">
                    Aumento de resíduos para os
                    <span class="font-fredoka fs-14 fc-yellow">Operadores logísticos parceiros</span> com simplicidade e suporte na gestão e comercialização dos resíduos para <span class="font-fredoka fs-14 fc-yellow">Indústria</span>.
                </p>
            </div>
        </div>
        <div class="row" buttons-section>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: right">
                <button class="btn btn-success" routerLink="/solicitacao-cadastro"  [queryParams]="{perfil: 'operador_logistico'}">CADASTRE-SE AQUI</button>
            </div>
        </div>
    </subComponent-container-section>

    <subComponent-container-section img="/assets/img/site/section-9/img-section-09.jpg" direct="right">
        <div class="row" body-section>
            <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12">
                <h4 class="font-fredoka fc-title-home" style="text-align: start">
                    Tudo de forma transparente e todo processo rastreado.
                </h4>
                <p style="text-align: start">
                    Engajamento através de gamificação para usuários e funcionários das unidades geradoras.
                </p>
            </div>
        </div>
    </subComponent-container-section>

    <div class="container-data">
        <div class="row">
            <h3 class="fc-green f-bold">
                QUEM
                <span class="fc-black">
          CONFIA
        </span> NA GENTE!</h3>
        </div>
    </div>

    <div class="container" id="nossos-parceiros">
        <div class="row w-100">
            <subComponent-carousel-cards></subComponent-carousel-cards>
        </div>
    </div>
</div>
