<div class="container-data">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Arquivos</h4>
        </div>
        <div class="card-body">
          <div class="crud-table">
            <table class="crud-table">
             <tr>
               <th>Arquivo</th>
               <th>Data Pagamento</th>
               <th>Pago</th>
               <th>Ações</th>
             </tr>
            <tbody *ngFor="let arquivo of arquivos">
            <td>{{ arquivo.arquivo?.fileName }}</td>
            <td>{{ arquivo.dataPagamento | date: "dd-MM-YYYY" }}</td>
            <td>{{ arquivo.pago }}</td>
            <td>
              <button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  (click)="arquivoService.downloadFile(arquivo?.id)"
                  mat-menu-item
                >
                  Download
                </button>
              </mat-menu>
            </td>
            </tbody>
          </table>
        </div>
       </div>

        <div class="card-footer">
          <dg-pagination [dataPagination]="entity.configEntityTable.pagination"></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>
