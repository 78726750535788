import { Injectable } from '@angular/core';
import {AbstractHttpService, HttpClient, interfFindAll} from "@datagrupo/dg-crud";
import {GalpaoDocumentosEntity} from "../galpao-documentos.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {Observable, Subscribable} from "rxjs";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';

@Injectable({
  providedIn: 'root'
})
export class GalpaoDocumentosService extends AbstractHttpService<GalpaoDocumentosEntity>{

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService,
    private http2: HttpClient,
    private files: FilesManageService
  ) {
    super(conf, environment.apiUrl, 'galpoes/documentogalpao');
  }

  override findAll(data?: interfFindAll): Observable<GalpaoDocumentosEntity> {
    // @ts-ignore
    return super.findAll(data);
  }

  override save(data: any, endpoint?: string | boolean): Observable<GalpaoDocumentosEntity> {
    //@ts-ignore
    return super.save(data, 'criaarquivo')
    // return this.httpServ.post()
    // return this.httpServ.post(`galpoes/documentogalpao/criarArquivo/${data.get('idGalpao')}/${data.get('idCategoria')}`, data);
  }


  override delete(id?: number | string, ids?: number[] | string[], endpoint?: string): Observable<GalpaoDocumentosEntity> {
    //@ts-ignore
    return super.delete(id, ids, 'excluir');
  }

  download(id: number) {
    return this.findOne(Number(id), 'download');
  }
}
