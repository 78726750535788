<div class="filter-search" style="max-width: 100% !important; position: relative">
  <div class="container-fluid">
    <div class="col-12 p-1">
      <p class="destaque-claro f-bold">Pesquisar</p>
      <hr>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col-12 p-1">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="container-fluid" *ngIf="!!dynamicGroupName">
    <div class="col-12 d-inline-flex justify-content-center">
      <button [dynamic-group-control]="dynamicGroupName" filter class="btn btn-sm btn-success m-1">Pesquisar</button>
      <button [dynamic-group-control]="dynamicGroupName" clear class="btn btn-sm btn-secondary m-1">Limpar</button>
    </div>
  </div>

  <div class="container-fluid" *ngIf="!dynamicGroupName">
    <div class="col-12 d-inline-flex justify-content-center">
      <button (click)="sendFilter.emit()" class="btn btn-sm btn-success m-1">Pesquisar</button>
      <button (click)="clearFilter.emit()" class="btn btn-sm btn-secondary m-1">Limpar</button>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col-12 p-1">
      <hr>
    </div>
  </div>

</div>
