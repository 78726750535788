import { RankingColetasPrintPdfComponent } from './subComponents/ranking-coletas-print-pdf/ranking-coletas-print-pdf.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RankingColetasMainComponent } from './ranking-coletas-main/ranking-coletas-main.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';



@NgModule({
  declarations: [
    RankingColetasMainComponent,
    RankingColetasPrintPdfComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule,


  ]
})
export class RankingColetasModule { }
