import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestaoProgramaLogisticaReversaMainComponent } from './gestao-programa-logistica-reversa-main/gestao-programa-logistica-reversa-main.component';
import { GestaoProgramaLogisticaReversaInsertEditComponent } from './gestao-programa-logistica-reversa-insert-edit/gestao-programa-logistica-reversa-insert-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import {DirectivesModule} from "../../../core/directives/directives.module";
import { DgAutocompleteModule } from '@datagrupo/dg-ng-util';



@NgModule({
  declarations: [
    GestaoProgramaLogisticaReversaMainComponent,
    GestaoProgramaLogisticaReversaInsertEditComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AngularEditorModule,
        DirectivesModule,
        DgAutocompleteModule
    ]
})
export class GestaoProgramaLogisticaReversaModule { }
