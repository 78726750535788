<div id="clientes-insert-edit">
  <div
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs default-tabs">
          <li class="nav-item" (click)="viewPage = 'INFO'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'INFO'">Informações</h4>
          </li>
          <li hidden class="nav-item" (click)="viewPage = 'MTR'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'MTR'">MTR</h4>
          </li>
          <li class="nav-item" *ngIf="entity?.id" (click)="viewPage = 'CONTRATO'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'CONTRATO'">Contratos</h4>
          </li>
          <li *ngIf="!session.checkPerfil('PARCEIRO')" class="nav-item" (click)="viewPage = 'COLETA'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'COLETA'">Estrutura de Coleta</h4>
          </li>
        </ul>
      </div>
    </div>

  </div>

<!-- INFORMAÇÕES -->
  <div class="container-data" [hidden]="viewPage != 'INFO'">
    <div class="row mt-2">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Informações de Contato</h4>
          </div>
          <div class="card-body">
            <table class="w-100 crud-table">
              <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Tipo de usuário</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let cont of contatos">
                <td>{{cont?.nome}}</td>
                <td>{{cont?.email}}</td>
                <td>{{cont?.telefone}}</td>
                <td>{{ajusteTipoUsuario(cont?.tipoUsuario)}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="paginationContato"
              (changePage)="changePaginationContato($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>

    <div
      *ngIf="entity?.id && entity?.info"
      class="row mt-3"
    >
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Informações gerais</h4>
          </div>
          <div class="card-body">
            <div *ngIf="!session.checkPerfil(['GALPAO'])" class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label
                  class="form-label"
                  *ngIf="form.getRawValue()?.tipoCadastro != 'EMPRESA'"
                >
                  Quantidade de usuários:
                </label>
                <label
                  class="form-label"
                  *ngIf="form.getRawValue()?.tipoCadastro == 'EMPRESA'"
                >
                  Quantidade de
                  funcionários:
                </label>
                {{entity.info?.quantidadeDeMoradores || ''}}
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Quantidade de coletas:</label>
                {{entity.info?.quantidadeDeColetas || ''}}
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Peso total (ton):</label>
                {{ajustePesoTotal(entity.info?.pontos)}}
              </div>
            </div>


            <div *ngIf="!session.checkPerfil(['GALPAO'])" class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Quantidade de missões:</label>
                {{entity.info?.quantidadeDeMissoes || ''}}
              </div>
<!--              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">-->
<!--                <label class="form-label">PONTOS NO RANKING:</label>-->
<!--                {{entity.info?.quantidadePontosDoRanking || ''}}-->
<!--              </div>-->
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label class="form-label">Meses com a ECOPLAT:</label>
                {{entity.info?.mesesComMinhaColeta || ''}}
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <label class="form-label">Link para auto cadastro de usuários</label>
              </div>
              <div class="col-12">
                <a
                  target="_blank"
                  [href]="getUrlLinkMorador()"
                >
                  {{getUrlLinkMorador()}}
                </a>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <form [formGroup]="form">
      <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Dados do Gerador</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="nome" class="form-label">
                    NOME
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="nome"
                    class="form-control"
                    id="nome"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="tipoCadastro" class="form-label">
                    TIPO DE GERADOR
                    <small class="fc-red">*</small>
                  </label>
                  <select
                    id="tipoCadastro"
                    class="form-select"
                    formControlName="tipoCadastro"
                  >
                    <option value selected disabled>Selecione</option>
                    <option value="CASA">Casa</option>
                    <option value="CONDOMINIO">Condomínio</option>
                    <option value="EVENTO">Evento</option>
                    <option value="EMPRESA">Empresa</option>
                  </select>
                </div>
                <div
                  *ngIf="form.getRawValue()?.tipoCadastro != 'CASA'"
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <label for="cnpj" class="form-label">
                    CNPJ
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cnpj"
                    class="form-control"
                    id="cnpj"
                    mask="00.000.000/0000-00"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="telefone" class="form-label">
                    TELEFONE
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="telefone"
                    class="form-control"
                    id="telefone"
                    mask="(00) 0000-0000||(00) 00000-0000"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="interesseCliente" class="form-label">
                    INTERESSE PRINCIPAL
                    <span class="fc-red">*</span>
                  </label>
                  <select
                    class="form-select"
                    id="interesseCliente"
                    formControlName="interesseCliente"
                    aria-label="Selecione um interesse"
                  >
                    <!-- <option selected>selecione</option> -->
                    <option value="MELHORAR">
                      Melhorar e conscientizar sobre coleta seletiva
                    </option>
                    <option value="VANTAGENS">
                      Ter vantagens através da Coleta Seletiva
                    </option>
                    <option value="IMPLANTARCOLETA">Implantar a coleta seletiva</option>
                  </select>
                </div>
                <div
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >
                  <label class="form-label">UNIDADE INDICADORA</label>
                  <input-auto-complete
                    [baseValue]="entity?.condominioIndicador"
                    propertValue="nome"
                    controlName="condominioIndicador"
                    urlServer="clientes/nomes"
                    (outValue)="setCondominioIndicador($event)"
                    [disabled]="!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
                  ></input-auto-complete>
                </div>
                <div
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >
                  <label class="form-label">Master</label>
                  <input-autocomplete
                    #autocompleteParceiro
                    formControlName="parceiro"
                    key="id"
                    classes="form-control"
                    label="nome"
                    [multiple]="true"
                    [disabled]="!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
                    (outValue)="setParceiro($event)"
                    [list]="listParceiro"
                    [preSelectedList]="preSelectedParceiros"
                  >
                    <ng-template #button_end>
                      <mat-icon (click)="autocompleteParceiro.clear()">close</mat-icon>
                    </ng-template>
                  </input-autocomplete>
                </div>

              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Grupo de Mercado <span class="fc-red">*</span></label>
                  <input-autocomplete
                    #grupoMercado
                    classes="form-control"
                    formControlName="grupoMercado"
                    [multiple]="true"
                    key="id"
                    id="grupo"
                    label="nome"
                    [showSelectedList]="true"
                    [preSelectedList]="preSelectedGrupMercado"
                    [list]="(listGruposMercado || [])"
                    (close)="selecctedGrupoMercado($event)"
                  >
                  </input-autocomplete>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Segmento de Mercado <span class="fc-red">*</span></label>
                  <input-autocomplete
                    #autoCompleteSegMer
                    classes="form-control"
                    [multiple]="true"
                    formControlName="segmentoMercado"
                    id="segmentoMercado"
                    label="nome"
                    key="id"
                    [showSelectedList]="true"
                    [preSelectedList]="preSelectedSegmentoMercado"
                    [list]="(listSegmentosMercado || [])"
                    (close)="segmentoMercado($event)"
                  >
                  </input-autocomplete>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">Segmento Operacional <span class="fc-red">*</span></label>
                  <input-autocomplete
                    #autoSeguimentoOperacional
                    classes="form-control"
                    formControlName="segmentosOperacionais"
                    [multiple]="true"
                    key="id"
                    id="segmentosOperacionais"
                    label="nome"
                    [showSelectedList]="true"
                    [preSelectedList]="preSelectedSegmentosOperacionais"
                    [list]="(listSegmentosOperacionais || [])"
                    (close)="selectSegmentosOperacionais($event)"

                  >
                  </input-autocomplete>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <card>
            <div class="card-header w-100">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title">Endereço</h4>
                  </div>
                  <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                                       *ngIf="!session.checkPerfil(['GALPAO'])">
                    <button
                      *ngIf="checkPermission()"
                      class="btn btn-success btn-sm"
                      (click)="addCepManual()"
                    >
                      Adicionar dados manualmente
                    </button>
                  </card-header-buttons>
                  <!--                  <div class=" d-inline-flex justify-content-end"-->
                  <!--                       *ngIf="!session.checkPerfil(['GALPAO'])">-->
                  <!--                    <button-->
                  <!--                      *ngIf="checkPermission()"-->
                  <!--                      class="btn btn-success btn-sm"-->
                  <!--                      (click)="addCepManual()"-->
                  <!--                    >-->
                  <!--                      Adicionar dados manualmente-->
                  <!--                    </button>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label">
                    CEP
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="cep"
                    class="form-control"
                    id="cep"
                    placeholder="00.000-000"
                    mask="00.000-000"
                  >
                </div>

                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                  <label for="logradouro" class="form-label">
                    LOGRADOURO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="logradouro"
                    class="form-control"
                    id="logradouro"
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label for="numero" class="form-label">
                    NUMERO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    type="number"
                    formControlName="numero"
                    class="form-control"
                    id="numero"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label for="complemento" class="form-label">
                    COMPLEMENTO
                  </label>
                  <input
                    type="text"
                    formControlName="complemento"
                    class="form-control"
                    id="complemento"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="bairro" class="form-label">
                    BAIRRO
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    formControlName="bairro"
                    type="text"
                    class="form-control"
                    id="bairro"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label for="cidade" class="form-label">
                    CIDADE
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    formControlName="cidade"
                    type="text"
                    class="form-control"
                    id="cidade"
                  >
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label for="uf" class="form-label">
                    UF
                    <span class="font-color-red">*</span>
                  </label>
                  <input
                    formControlName="uf"
                    type="text"
                    class="form-control"
                    id="uf"
                  >
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <card>
            <!--            <div class="card-header">-->
            <!--              <h4 class="card-title">Dados de MTR</h4>-->
            <!--            </div>-->
            <div class="card-header w-100">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title">Dados de MTR</h4>
                  </div>
                  <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <button
                      *ngIf="checkPermission()"
                      (click)="editMtr.patchValue(!editMtr.value)"
                      class="btn btn-success btn-card-header"
                    >
                      Editar
                    </button>
                  </card-header-buttons>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> INSTITUTO </label>
                  <select class="form-select" formControlName="instituto">
                    <option disabled value="">Selecione um instituto</option>
                    <option value="SIGOR">Sigor</option>
                    <option value="SINIR">Sinir</option>
                    <option value="INEA">Inea</option>
                  </select>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> CNPJ </label>
                  <input
                    class="form-control"
                    formControlName="cnpjMtr"
                    mask="00.000.000/0000-00"
                  >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label"> EMAIL </label>
                  <input type="email" class="form-control" formControlName="emailMtr"/>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label">
                    SITE DO MANIFESTO
                  </label>
                  <input class="form-control" formControlName="siteManifesto"/>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label">
                    CÓDIGO DO USUÁRIO (CPF)
                  </label>
                  <input
                    class="form-control"
                    formControlName="cpfUsuario"
                    mask="000.000.000-00"
                  >
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> RESPONSÁVEL </label>
                  <input class="form-control" formControlName="responsavel"/>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> CARGO </label>
                  <input class="form-control" formControlName="cargo"/>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> CÓDIGO </label>
                  <input class="form-control" formControlName="codigo"/>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label for="cep" class="form-label"> SENHA </label>
                  <input class="form-control" formControlName="senha"/>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Dados da coleta</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  *ngIf="showQtdQuartos()"
                  class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                >
                  <label class="form-label col-xl-12 col-lg-12 col-md-6 col-sm-12 d-inline-flex justify-content-center">
                    QUANTIDADE DE {{form.getRawValue()?.tipoCadastro == 'CASA' ? 'CÔMODOS' : 'QUARTOS POR APARTAMENTO'}}
                    <span class="fc-red">*</span>
                  </label>
                  <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 d-inline-flex justify-content-center">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        (click)="editDiasSemana('UM', 'qtdQuartosPorApartamento')"
                        [checked]="verifyCheckBox('UM', 'qtdQuartosPorApartamento')"
                        type="checkbox"
                        id="quantQuartos1"
                      >
                      <label class="form-check-label" for="quantQuartos1">
                        1
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        (click)="editDiasSemana('DOIS', 'qtdQuartosPorApartamento')"
                        type="checkbox"
                        [checked]="verifyCheckBox('DOIS', 'qtdQuartosPorApartamento')"
                        id="quantQuartos2"
                      >
                      <label class="form-check-label" for="quantQuartos2">
                        2
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        (click)="editDiasSemana('TRES', 'qtdQuartosPorApartamento')"
                        [checked]="verifyCheckBox('TRES', 'qtdQuartosPorApartamento')"
                        type="checkbox"
                        id="quantQuartos3"
                      />
                      <label class="form-check-label" for="quantQuartos3">
                        3
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        (click)="editDiasSemana('QUATRO', 'qtdQuartosPorApartamento')"
                        [checked]="verifyCheckBox('QUATRO', 'qtdQuartosPorApartamento')"
                        type="checkbox"
                        id="quantQuartos4"
                      />
                      <label class="form-check-label" for="quantQuartos4">
                        4
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        (click)="editDiasSemana('CINCO_OU_MAIS', 'qtdQuartosPorApartamento')"
                        [checked]="verifyCheckBox('CINCO_OU_MAIS', 'qtdQuartosPorApartamento')"
                        type="checkbox"
                        id="quantQuartos5"
                      />
                      <label class="form-check-label" for="quantQuartos5">
                        5+
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  *ngIf="form.getRawValue()?.tipoCadastro != 'CASA'"
                  class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                >
                  <label for="qtdApartamentos" class="form-label">
                    QUANTIDADE DE
                    {{ !showQtdQuartos() ? "SETORES" : "APARTAMENTOS" }}
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    mask="0*"
                    formControlName="qtdApartamentos"
                    class="form-control"
                    id="qtdApartamentos"
                  >
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                  *ngIf="form.getRawValue()?.tipoCadastro != 'EVENTO'"
                >
                  <label for="qtdDeMoradores" class="form-label">
                    QUANTIDADE DE
                    {{
                    form.getRawValue()?.tipoCadastro != "EMPRESA"
                      ? "USUÁRIOS"
                      : "FUNCIONÁRIOS"
                    }}
                  </label>
                  <input
                    type="text"
                    mask="0*"
                    formControlName="qtdDeMoradores"
                    class="form-control"
                    id="qtdDeMoradores"
                  >
                </div>

                <div
                  class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                  *ngIf="!showQtdQuartos()"
                >
                  <label for="qtdDeMoradores" class="form-label">
                    ESTIMATIVA DE COLETA
                    {{
                    form.getRawValue()?.tipoCadastro != "EVENTO"
                      ? "MENSAL"
                      : ""
                    }}
                    (TON)
                  </label>
                  <input
                    type="text"
                    formControlName="estimativaColetaMensal"
                    class="form-control"
                    id="estimativa_coleta_mensal"
                  />
                </div>
              </div>

              <div *ngIf="showQtdQuartos()">
                <div
                  class="pt-5 recal-estimativas"
                  *ngIf="
                    session.checkPerfil([
                      'CONDOMINIO',
                      'ADMINISTRATIVO',
                      'ADMINISTRADOR'
                    ])
                  "
                >
                  <button
                    class="btn btn-success fs-10"
                    (click)="recalcularEstimativas()"
                  >
                    recalcular estimativas
                  </button>
                </div>

                <hr
                  class=" mb-5"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >


                <div
                  class="row"
                  *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
                >
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h5>Estimativa para o cliente</h5>
                    <table class="crud-table">
                      <tbody>
                      <tr>
                        <td>TOTAL DE QUARTOS:</td>
                        <td>
                          {{ entity?.estimativas?.estimativaCliente?.totalQuartos || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL DE RDO GERADO NO CONDOMÍO DIA:</td>
                        <td>
                          {{ entity?.estimativas?.estimativaCliente?.totalRDODia || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL DE RDO GERADO NO CONDOMÍO MÊS:</td>
                        <td>
                          {{ entity?.estimativas?.estimativaCliente?.totalRDOMes || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>VALOR ESTIMADO DOS RECICLAVEIS(KG):</td>
                        <td>
                          {{ entity?.estimativas?.estimativaCliente?.valorEstimadoReciclaveis || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>METROS CÚBICOS GERADOS:</td>
                        <td>
                          {{ entity?.estimativas?.estimativaCliente?.metrosCubicosGerados || 0 }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h5>Estimativa para logística</h5>
                    <table class="crud-table">
                      <tbody>
                      <tr>
                        <td>RESÍDUOS POR QUARTO(KG):</td>
                        <td>
                          {{ entity?.estimativas?.estimativaLogistica?.residuosPorQuarto || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL DE APARTAMENTOS:</td>
                        <td>
                          {{ entity?.estimativas?.estimativaLogistica?.totalApartamentos || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL ESTIMADO DE RECICLÁVEIS(KG):</td>
                        <td>
                          {{ entity?.estimativas?.estimativaLogistica?.totalEstimadoReciclaveis || 0 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TOTAL DE RECICLÁVEIS NO MÊS(KG):</td>
                        <td>
                          {{ entity?.estimativas?.estimativaLogistica?.totalReciclaveisMes || 0 }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Relação de Contrato Exclusiva</h4>
            </div>
            <div class="card-body">
              <div
                class="row mb-4"
                *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"
              >
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <label class="form-label">Transportador</label>
                  <input-autocomplete
                    #autocomplete
                    formControlName="galpao"
                    key="id"
                    classes="form-control"
                    label="nome"
                    [list]="listGalpao"
                  >
                    <ng-template #button_end>
                      <mat-icon (click)="autocomplete.clear()">close</mat-icon>
                    </ng-template>
                  </input-autocomplete>
                </div>
                <div
                  class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                  style="padding-top: 2.1em;"
                >
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="exclusivo"
                    id="exclusivo"
                    formControlName="exclusivo"
                  />
                  <label class="form-label ms-2" for="exclusivo">
                    O CLIENTE É EXCLUSÍVO DO TRANSPORTADOR?
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                  <label class="form-label w-100" [class.fc-red]="checkedDiasColetas && entity.diasColeta.length == 0">
                    DIAS DE COLETA NO CLIENTE
                    <span class="fc-red">*</span>
                  </label>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('SEGUNDA', 'diasColeta')"
                      [checked]="verifyCheckBox('SEGUNDA', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Segunda"
                    />
                    <label class="form-check-label" for="diaColeta-Segunda">
                      Segunda
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('TERCA', 'diasColeta')"
                      [checked]="verifyCheckBox('TERCA', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Terça"
                    />
                    <label class="form-check-label" for="diaColeta-Terça">
                      Terça
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('QUARTA', 'diasColeta')"
                      [checked]="verifyCheckBox('QUARTA', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Quarta"
                    />
                    <label class="form-check-label" for="diaColeta-Quarta">
                      Quarta
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('QUINTA', 'diasColeta')"
                      [checked]="verifyCheckBox('QUINTA', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Quinta"
                    />
                    <label class="form-check-label" for="diaColeta-Quinta">
                      Quinta
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('SEXTA', 'diasColeta')"
                      [checked]="verifyCheckBox('SEXTA', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Sexta"
                    />
                    <label class="form-check-label" for="diaColeta-Sexta">
                      Sexta
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('SABADO', 'diasColeta')"
                      [checked]="verifyCheckBox('SABADO', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Sábado"
                    />
                    <label class="form-check-label" for="diaColeta-Sábado">
                      Sábado
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('PONTUAL', 'diasColeta')"
                      [checked]="verifyCheckBox('PONTUAL', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-Pontual"
                    />
                    <label class="form-check-label" for="diaColeta-Pontual">
                      Pontual
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="editDiasSemana('QUINZENAL', 'diasColeta')"
                      [checked]="verifyCheckBox('QUINZENAL', 'diasColeta')"
                      type="checkbox"
                      id="diaColeta-QUINZENAL"
                    />
                    <label class="form-check-label" for="diaColeta-QUINZENAL">
                      Quinzenal
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </form>

    <div
      *ngIf="!!tableParam?.clienteId && session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
      class="row mt-3"
    >
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Documentos do Gerador</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="checkPermission()"
                    (click)="modalArquivo.open()"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    Cadastrar
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableArquivo
              [entityMap]="arquivosEntity"
              [persistentParams]="tableParam"
            ></crud-data-table>
          </div>
          <div class="card-footer">
            <dg-pagination
              [dataPagination]="arquivosEntity.configEntityTable.pagination"
              (changePage)="tableArquivo.changePages($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

<!-- MTR -->
  <div class="container-data" [hidden]="viewPage != 'MTR'">
    mtr
  </div>

<!-- CONTRATO -->
  <div class="container-data" [hidden]="viewPage != 'CONTRATO'">

    <subPage-cliente-sub-page-contrato
      *ngIf="!!entityId"
      [clienteId]="entityId"
      [entity]="entity"
    ></subPage-cliente-sub-page-contrato>

  </div>

<!-- ESTRUTURA COLETAS -->
  <div class="container-data mt-3" [hidden]="viewPage != 'COLETA'">
    <div
      id="perguntas-unidade-geradora"
      *ngIf="entity?.id && !session.checkPerfil(['GALPAO'])"
    >
      <subComponent-pergunta-casa
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('CASA')"
      ></subComponent-pergunta-casa>
      <subComponent-pergunta-condominio
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('CONDOMINIO')"
      ></subComponent-pergunta-condominio>
      <subComponent-pergunta-empresa
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('EMPRESA')"
      ></subComponent-pergunta-empresa>
      <subComponent-pergunta-evento
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('EVENTO')"
      ></subComponent-pergunta-evento>
    </div>
  </div>

</div>




<page-footer *ngIf="!session.checkProfile('USUARIO_CONSULTA')">
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    *ngIf="checkPermission()"
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    *ngIf="checkPermission()"
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
    *ngIf="(checkPerfil() && checkPermission())"
    crud-deletar
    class="btn btn-danger"
  >
    Excluir
  </button>
  <button
    *ngIf="(checkPerfil() && checkPermission())"
    class="btn"
    (click)="ativarDesativarCliente()"
    [class.btn-danger]="entity?.ativo"
    [class.btn-success]="!entity?.ativo"
  >
    {{entity?.ativo ? 'desativar' : 'ativar'}} cliente
  </button>
</page-footer>

<subComponent-arquivos-cliente-modal
  #modalArquivo
  [idCliente]="entity?.id"
  (atualizarTabela)="tableArquivo?._pesquisar()"
></subComponent-arquivos-cliente-modal>
