import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractInsertEdit, HttpClient } from "@datagrupo/dg-crud";
import { ContainerEntity } from "../container.entity";
import { ConfigDgCrudService } from "../../../../core/config/config-dg-crud/config-dg-crud.service";
import { ContainerService } from "../service/container.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InputHelpers } from "../../../../helpers/input.helpers";
import Swal from 'sweetalert2';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { environment } from 'src/environments/environment';
import { InputAutocompleteComponent } from '@datagrupo/dg-ng-util';

@Component({
  selector: 'app-container-insert-edit',
  templateUrl: './container-insert-edit.component.html',
  styleUrls: ['./container-insert-edit.component.scss']
})
export class ContainerInsertEditComponent extends AbstractInsertEdit<ContainerEntity> implements OnInit {

  @ViewChild('modalAtivaDesativa') modalAtivaDesativa!: OldModalComponent;
  @ViewChild('autoCompleteInea') autoCompleteInea!: InputAutocompleteComponent;
  @ViewChild('autoCompleteSinir') autoCompleteSinir!: InputAutocompleteComponent;
  @ViewChild('autoCompleteSigor') autoCompleteSigor!: InputAutocompleteComponent;

  public containersMtrInea: any[] = []
  public containersMtrSinir: any[] = []
  public containersMtrSigor: any[] = []

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    inea: new FormControl('', [Validators.required]),
    sigor: new FormControl('', [Validators.required]),
    sinir: new FormControl('', [Validators.required]),
    capacidadeLitros: new FormControl('', [Validators.required]),
    capacidadeQuilos: new FormControl('', [Validators.required]),
    descricao: new FormControl('', [Validators.required, Validators.maxLength(2000)])
  })

  constructor(
    public conf: ConfigDgCrudService,
    public servic: ContainerService,
    public http: HttpClient
  ) {
    super(conf, servic)
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['capacidadeQuilos', 'capacidadeLitros'])
    )

    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'INEA' } }).subscribe((resp: any) => {
      this.containersMtrInea = resp.data
    })

    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'SINIR' } }).subscribe((resp: any) => {
      this.containersMtrSinir = resp.data
    })

    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'SIGOR' } }).subscribe((resp: any) => {
      this.containersMtrSigor = resp.data
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override afterFetchEntity() {
    this.form.controls['capacidadeQuilos'].disable();
    this.form.controls['capacidadeLitros'].disable();
    this.entity.capacidadeLitros = InputHelpers.initInputDecimal(this.entity.capacidadeLitros || '') || this.entity.capacidadeLitros
    this.entity.capacidadeQuilos = InputHelpers.initInputDecimal(this.entity.capacidadeQuilos || '') || this.entity.capacidadeQuilos;

    const extractCategoriaMTR = (type: 'SINIR' | 'SIGOR' | 'INEA'):string | number => {
      const categoriaContainers =  this.entity.categoriaContainers || [];
      const result =  categoriaContainers.find(item => item.fonteMtr == type)

      return result?.containerMtr?.id || '';
    }

    this.form.patchValue({
      ...this.entity,
      inea: extractCategoriaMTR('INEA'),
      sigor: extractCategoriaMTR('SIGOR'),
      sinir: extractCategoriaMTR('SINIR'),
    })
  }

  initNewEntity(): void {
    this.entity = new ContainerEntity()
    this.form.patchValue({
      capacidadeQuilos: '',
      capacidadeLitros: ''
    })
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false
    }

    let categoriaContainers: any[] = []

    if (this.autoCompleteInea.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteInea.selectedList[0],
        container: {
          nome: this.entity.nome ? this.entity.nome : this.form.controls['nome'].value,
          id: this.entity.id
        }
      })
    }

    if (this.autoCompleteSinir.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteSinir.selectedList[0],
        container: {
          nome: this.entity.nome ? this.entity.nome : this.form.controls['nome'].value,
          id: this.entity.id
        }
      })
    }

    if (this.autoCompleteSigor.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteSigor.selectedList[0],
        container: {
          nome: this.entity.nome ? this.entity.nome : this.form.controls['nome'].value,
          id: this.entity.id
        }
      })
    }

    const { capacidadeQuilos, capacidadeLitros, ...form } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      categoriaContainers,
      capacidadeQuilos: InputHelpers.ajusteMoneyBrToEua(capacidadeQuilos),
      capacidadeLitros: InputHelpers.ajusteMoneyBrToEua(capacidadeLitros),

    }

    return true
  }

  ativarDesativar() {
    Swal.fire({
      icon: 'question',
      title: 'Você deseja ' + (this.entity?.dataDesativacao ? 'ativar' : 'desativar') + ' o container?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        let data = {
          id: this.entityId,
          ativado: !!this.entity.dataDesativacao
        }
        this.servic.patch('/containers/status-ativacao', data).subscribe(resp => {
          if (resp) {
            this.entity.dataDesativacao = !this.entity.dataDesativacao
            Swal.fire({
              icon: 'success',
              title: 'Container ' + (this.entity.dataDesativacao ? 'desativado' : 'ativado') + ' com sucesso',
              timer: 5000,
              showConfirmButton: false
            })
            this.modalAtivaDesativa.close()
          }
        })
      }
    })
  }
}
