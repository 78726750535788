import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public environment = environment

  constructor() {
    document.title = this.prefixTitle() + document.title;

    this.alterIconProject()
  }

  ngOnInit(): void {}

  prefixTitle() {
    switch (environment.estado_esteira_desenvolvimento.nome) {
      case 'DESENVOLVIMENTO':
        return "DEV - "
      case 'TESTE':
        return "test - "
      case 'HOMOLOG':
        return "homolog - "
      default:
        return ''
    }
  }

  alterIconProject() {
    const icon: any = document.querySelector("link[rel*='icon']");

    if (!icon) return;
    if (!('href' in icon)) return;

    switch (environment.estado_esteira_desenvolvimento.nome) {
      case 'DESENVOLVIMENTO':
        icon['href'] = 'assets/icons/ico/dev-icon.ico';
        break
      case 'TESTE':
        icon['href'] = 'assets/icons/ico/test-icon.ico';
        break
      case 'HOMOLOG':
        icon['href'] = 'assets/icons/ico/homolog-icon.ico';
        break
      default:
        return
    }
  }
}
