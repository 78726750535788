import {Component, HostListener, OnInit} from '@angular/core';
import {DetalhamentoGriEntity} from "../../entitys/detalhamento-gri.entity";
import {DetalhamentoUnidadeGeradoraEntity} from "../../entitys/detalhamento-unidade-geradora.entity";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {Router} from "@angular/router";
import * as Highcharts from "highcharts";
import {crudDispatchEvent} from "@datagrupo/dg-crud";
import {ajusteFunctionHighChartsByInsert} from "../../helpers/highcharts.helper";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'sub-component-dashboard-estoque',
  templateUrl: './sub-component-dashboard-estoque.component.html',
  styleUrls: ['./sub-component-dashboard-estoque.component.scss']
})
export class SubComponentDashboardEstoqueComponent implements OnInit {

  showPages = false;
  liberarTabelas = false;
  existDataColetas = true
  persistParams = {}
  registerParams = {}
  graph: {
    anos: any,
    meses: any,
    totais: any,
    residuos: any,
    ods: any,
  } = {
    anos: undefined,
    meses: undefined,
    totais: undefined,
    residuos: undefined,
    ods: undefined,
  }
  impacto: {
    minerio: number | string,
    areia: number | string,
    arvores: number | string,
    petroleo: number | string,
    agua: number | string,
    energia: number | string,
  } = {
    minerio: 0,
    areia: 0,
    arvores: 0,
    petroleo: 0,
    agua: 0,
    energia: 0,
  }
  ods = {
    taxaReciclagem: 0,
    valorIndicadorBrasil: 0,
    anoIndicadorBrasil: '--'
  }

  loader: number = 100;

  loaders: {
    all: number,
    estoqueBarras: boolean,
    estoqueRosca: boolean,
    ods: boolean,
    comparativo: boolean,
    impacto: boolean,
  } = {
    all: 100,
    estoqueBarras: true,
    estoqueRosca: true,
    ods: true,
    comparativo: true,
    impacto: true,
  }

  public contAtualizacao = 0;
  public listGraph: any[] = [];

  public entity = new DetalhamentoUnidadeGeradoraEntity()
  public entityGri = new DetalhamentoGriEntity()

  constructor(
    public service: GenericCrudService,
    public session: SessionService
  ) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: '.',
        decimalPoint: ','
      }
    });
  }

  ngOnInit(): void {
  }

  /* METODOS DE GESTÃO DE DADOS DE MONTAGEM DA TELA */

  @HostListener('window:atualizar-pagina-filtrada-estoque', ['$event'])
  getEventValues(ev: CustomEvent) {
    console.log(ev.detail)
    if (this.verifyDiffParams(ev.detail)) {
      this.reset();
      this.persistParams = ev.detail;
      this.registerParams = this.persistParams;
      setTimeout(() => {
        this.getValues()
      }, 100)
    }
  }

  reset() {
    this.graph = {
      anos: undefined,
      meses: undefined,
      totais: undefined,
      residuos: undefined,
      ods: undefined,
    }
    this.contAtualizacao++
    this.loaders = {
      all: 100,
      estoqueBarras: true,
      estoqueRosca: true,
      ods: true,
      comparativo: true,
      impacto: true,
    }
  }

  verifyDiffParams(newParams: any): boolean {
    return JSON.stringify(this.registerParams) != JSON.stringify(newParams);
  }

  async getValues() {
    const params = <any>this.persistParams;

    setTimeout(() => {
      crudDispatchEvent('tabelaGRI-estoque')
    }, 10)

    this.impacto = {
      minerio: 0,
      areia: 0,
      arvores: 0,
      petroleo: 0,
      agua: 0,
      energia: 0,
    }

    await this.service.get('dashboard/estoque', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.estoqueBarras = false;
        this.loaders.estoqueRosca = false;


        this.setGraph('id-chart-estoque-rosca', resp.data.charts[0])
        this.setGraph('id-chart-estoque-barra', resp.data.charts[1])
      }
    )

    const comparativoParams: any = {}

    if (!!params.dataFim) comparativoParams['dataFim'] = params.dataFim;
    if (!!params.dataInicio) comparativoParams['dataInicio'] = params.dataInicio;

    if (this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
      if (!!params.estados) comparativoParams['estados'] = params.estados;
    }

    await this.service.get('dashboard/estoque-comparativo', {params: comparativoParams}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.comparativo = false;

        this.setGraph('id-chart-estoque-comparativo', resp.data.charts[0])
      }
    )

    await this.service.get('dashboard/indicador-ods', {params}).subscribe(
      resp => {
        this.addLoader()
        this.loaders.ods = false;

        this.ods = {
          taxaReciclagem: resp.data.taxaReciclagem || 0,
          valorIndicadorBrasil: resp.data.valorIndicadorBrasil || 0,
          anoIndicadorBrasil: resp.data.anoIndicadorBrasil || '--'
        }
      }
    )

    await this.service.get('dashboard/relatorio-impacto', {params}).subscribe(
      resp => {
        this.addLoader();
        this.loaders.impacto = false;

        (resp.data || []).map((impacto: any) => {
          if (String(impacto.indicador.nome).toLowerCase() == 'areia') {
            this.impacto.areia = Number(1550.05).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'arvores') {
            this.impacto.arvores = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'petroleo') {
            this.impacto.petroleo = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'agua') {
            this.impacto.agua = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'energia eletrica') {
            this.impacto.energia = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'minerio') {
            this.impacto.minerio = Number(impacto.valor).toLocaleString('BR')
          }
        })
      }
    )

  }

  addLoader() {
    const numberRequests = 5;
    this.loader = this.loader + (100 / numberRequests);
  }

  criarId(i: number): string {
    return 'chart-id-' + String(i) + '-' + this.contAtualizacao;
  }

  ajustParams() {
    return {}
  }

  setGraph(id: string, data: any) {
    if (window.document.getElementById(id)) {
      setTimeout(() => {
        Highcharts.chart(id, ajusteFunctionHighChartsByInsert(data));
      }, 100)
    }
  }
}
