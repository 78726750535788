<div id="historicoColetas" *ngIf="!!entity">
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Informações da Coleta</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <table class="crud-table">
                  <tbody>
                  <tr>
                    <td>DATA DA COLETA:</td>
                    <td>{{ maskData(entity?.rota?.dataRota) }}</td>
                  </tr>
                  <tr>
                    <td>MOTORISTA/CATADOR DA COLETA:</td>
                    <td>{{ entity?.rota?.motorista?.nome }}</td>
                  </tr>
                  <tr>
                    <td>AVALIAÇÃO DA COLETA</td>
                    <td>
                      <ratio-star [editar]="true" [pontos]="entity?.avaliacaoClienteMotorista?.estrelas"></ratio-star>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <table class="crud-table">
                  <tbody>
                  <tr>
                    <td>PONTUAÇÃO:</td>
                    <td>{{ entity?.pontuacao?.valor }}</td>
                  </tr>
                  <tr>
                    <td>Transportador:</td>
                    <td>{{ entity?.rota?.galpao?.nome}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div class="container-data" *ngFor="let container of entity.checkin.checkinContainer;">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Container</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <table class="crud-table">
                  <tbody>
                    <tr>
                      <td>IDENTIFICAÇÃO DO CONTAINER:</td>
                      <td>{{ container.container?.identificacao}}</td>
                    </tr>
                    <tr>
                      <td>CAPACIDADE DO CONTAINER:</td>
                      <td>{{ container.container?.container?.capacidadeLitros }}</td>
                    </tr>
                    <tr>
                      <td>TIPO DO RESÍDUO:</td>
                      <td>{{ container.container?.tipoLixoContainer?.nome }}</td>
                    </tr>
                    <tr>
                      <td>CARGA:</td>
                      <td>{{ container.carga }}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <div class="col-6">
                <div class="row">
                  <h6>Foto do Recipiente</h6>
                </div>
                <div class="row">
                  <div class="col-12">
                    <img style="width: 100%" src="{{files.getImageToSrc(container.imagem)}}" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Arquivos da coleta</h4>
          </div>
          <div class="card-body">
            <crud-data-table
              #tableArquivoColetasEntity
              [showPagination]="false"
              [entityMap]="arquivoColetasEntity"
              [service]="servicArquivos"
              [customDblclick]="true"
            >
            </crud-data-table>
          </div>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="arquivoColetasEntity.configEntityTable.pagination"
              (changePage)="tableArquivoColetasEntity.changePages($event)"
            ></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
</page-footer>
