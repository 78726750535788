import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {InterfaceMenuList} from "../navigation/navigation.component";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public listMenuAdm: InterfaceMenuList[] = [
    {url: '/admin', nome: 'Admin', icon: 'settings', subMenu: [
        { url: '/admin', nome: 'config', icon: 'admin_panel_settings' }
    ]}
  ];

  @Input('preview') preview: boolean = false;

  public static open = true;
  public _open = true;
  public showBackground = true;
  public closeClickBackground = true;

  public halfCollapse = 'md';
  public collapse = 'sm';

  constructor() {
    if (!!window.localStorage.getItem('dg-sidebar-state')) {
      SidebarComponent.open = Boolean(Number(window.localStorage.getItem('dg-sidebar-state')))
      this._open = SidebarComponent.open;
    }
  }

  ngOnInit() {}

  changeState() {
    if (this.preview) return;
    if (window.innerWidth > 991) {
      window.localStorage.setItem('dg-sidebar-state', !SidebarComponent.open ? '1' : '0');
    }

    SidebarComponent.open = !SidebarComponent.open;
    this._open = SidebarComponent.open;
  }

  bgChangeState(){
    if (this.closeClickBackground) {
      this.changeState();
    }
  }

}
