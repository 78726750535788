import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import {environment} from "../../../../../environments/environment";
import {Enderecos} from "../../../../shared/entitys/enderecos";

@DataServer({
  path: environment.apiUrl,
  context: 'coletas-pendentes'
})
export class ColetaPendenteEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    coletaPendenteId?: string | number | undefined,
    clienteId?: string | number | undefined,
    clienteNome?: string | undefined,
    clienteEnderecos?: Enderecos | undefined,
    checkinContainerPendente?: any
  ) {
    super();
    this.id = id;
    this.coletaPendenteId = coletaPendenteId;
    this.clienteId = clienteId;
    this.clienteNome = clienteNome;
    this.clienteEnderecos = clienteEnderecos;
    this.checkinContainerPendente = checkinContainerPendente;
  }

  @DgTableColumn({
    columnName: 'Data da Coleta', resource: (val: any, row: ColetaPendenteEntity) => {
      return !!val ? val.split('-').reverse().join('/') : '--';
    }
  })
  public dataColeta: any;

  @DgTableColumn({
    columnName: 'Nome do Recipiente', resource: (val: any, row: ColetaPendenteEntity) => {
      const result = row?.checkinContainerPendente || [];
      return result.map((c: any) => {
        if (c?.clienteContainer) {
          if (c.clienteContainer?.containerNome) return c.clienteContainer.containerNome
        }

        return null;
      }).filter((c: string) => !!c).join(', ')
    }
  })
  public checkinContainerPendente: any

  @DgTableColumn({
    columnName: 'Identificação', resource: (val: any, row: ColetaPendenteEntity) => {
      const result = row?.checkinContainerPendente || [];
      return result.map((c: any) => {
        if (c?.clienteContainer?.identificacao) return c.clienteContainer.identificacao;

        return null;
      }).filter((c: string) => !!c).join(', ')
    }
  })
  public identificacao: any

  @DgTableColumn({
    columnName: 'Tipo do resíduo', resource: (val: any, row: ColetaPendenteEntity) => {
      const result = row?.checkinContainerPendente || [];
      return result.map((c: any) => {
        if (c?.clienteContainer?.tipoLixoNome) return c.clienteContainer.tipoLixoNome;

        return null;
      }).filter((c: string) => !!c).join(', ')
    }
  })
  public tipoLixo: any

  @DgTableColumn({
    columnName: 'Cliente', resource: (val: any) => {
      return val || '--'
    }
  })
  public clienteNome: string | undefined;

  @DgTableColumn({
    columnName: 'Coleta pendente id', resource: (val: any, row: ColetaPendenteEntity) => {
      return !!val ? val : '--';
    }
  })
  public coletaPendenteId: string | number | undefined;

  public clienteId: string | number | undefined;
  public clienteEnderecos: Enderecos | undefined;
}
