import {Component, HostListener, OnInit} from '@angular/core';
import {BlogEntity} from "../blog.entity";
import {BlogService} from "../services/blog.service";
import Swal from "sweetalert2";
import {BLOG} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'blog-main',
  templateUrl: './blog-main.component.html',
  styleUrls: ['./blog-main.component.scss']
})
export class BlogMainComponent implements OnInit {

  public entity = new BlogEntity();

  public listBlog: BlogEntity[] = [];
  public singlePost = false;

  constructor(public blogService: BlogService,) {
    this.getBlogList();
  }

  ngOnInit(): void {
  }

  @HostListener('window:event-home-get-blog-list')
  getBlogList() {
    this.singlePost = false;
    this.blogService.get(BLOG, { params: {...this.entity.configEntityTable.pagination} }).subscribe(
      resp => {
        //@ts-ignore
        this.listBlog = resp.data;

        this.entity.configEntityTable.pagination = {
          ...this.entity.configEntityTable.pagination,
          //@ts-ignore
          ...resp.page,
          //@ts-ignore
          page: resp.page.number
        }

        //@ts-ignore
        this.entity.configEntityTable.pagination = {...this.entity.configEntityTable.pagination}

      }
    )
  }

  @HostListener('window:event-home-get-blog-post-dyId')
  getPostById(id: number ) {
    this.singlePost = true;
    this.blogService.findOne(id, '').subscribe(
      resp => {
        //@ts-ignore
        this.listBlog = [resp.data];
      }
    )
  }

  excluirPost(id: number | string) {
    Swal.fire({
      icon: 'error',
      title: 'Deseja Excluir esse post?',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Excluir'
    }).then(
      result => {
        if (result.isConfirmed) {
          this.blogService.delete(id).subscribe(
            () => {
              this.getBlogList();
            }
          )
        }
      }
    )
  }

  changePage(dataPagination: any) {
    console.log(dataPagination);
    this.entity.configEntityTable.pagination = {
      ...this.entity.configEntityTable.pagination,
      ...dataPagination
    }

    this.getBlogList();
  }
}
