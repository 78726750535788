import { Component, OnInit } from '@angular/core';
import {DetalhamentoUnidadeGeradoraEntity} from "../../entitys/detalhamento-unidade-geradora.entity";
import {ActivatedRoute} from "@angular/router";
import {DashboardColetaService} from "../dashboard-graph/service/dashboard-coleta.service";

@Component({
  selector: 'app-dashboard-print-estoque',
  templateUrl: './dashboard-print-estoque.component.html',
  styleUrls: ['./dashboard-print-estoque.component.scss']
})
export class DashboardPrintEstoqueComponent implements OnInit {

  public load: boolean = true;
  public dataFilter: {
    unidades: string[] | number[],
    ano: string[]
  } = {
    unidades: [],
    ano: []
  };
  public entity = new DetalhamentoUnidadeGeradoraEntity()
  public listGraph: any[] = [];

  public contAtualizacao = 0;
  private awaitGet = {
    table: false,
    graph: false
  }

  constructor(
    private route: ActivatedRoute,
    public service: DashboardColetaService
  ) {
    if ('matchMedia' in window) {
      // Chrome, Firefox, and IE 10 support mediaMatch listeners
      window.matchMedia('print').addListener((media) => {
        if (!media.matches) {
          // window.close()
        }
      });
    }
  }

  ngOnInit(): void {
    this.service.addWhere({unpaged: true})
    this.route.queryParams.subscribe(params => {

      if (!!params['unidadeGeradora']) {
        // this.unidadeGeradora = params['unidadeGeradora'].split(',');
        this.dataFilter['unidades'] = params['unidadeGeradora'].split(',');
        this.service.addWhere({unidadesGeradoras: params['unidadeGeradora'].split(',')});
        // dataFilter.unidades = params['unidadeGeradora'].split(',');
      }

      if (!!params['ano']) {
        // this.ano = params['ano']?.split(',');
        this.dataFilter['ano'] = params['ano']?.split(',');
        this.service.addWhere({unidadesGeradoras: params['ano']?.split(',')});
        // dataFilter.ano = params['ano']?.split(',');

      }

      this.getGraph();
      this.dataFilter = {...this.dataFilter}

    }).unsubscribe()

  }

  getGraph() {
    if (this.dataFilter.ano.length > 0) {
      this.service.addWhere({ano: this.dataFilter.ano})
    } else {
      this.service.removeWhere(['ano'])
    }

    if (this.dataFilter.unidades.length > 0) {
      this.service.addWhere({unidadesGeradoras: this.dataFilter.unidades});
    } else {
      this.service.removeWhere(['unidadesGeradoras'])
    }

    this.service.findAll().subscribe(
      resp => {
        this.contAtualizacao++;
        //@ts-ignore
        this.listGraph = resp.data;

        this.verifyPrint('graph')
      }
    )
  }

  criarId(i: number): string {
    return 'chartid-' + String(i) + '-' + this.contAtualizacao;
  }

  teste = (e: any[]) => {
    this.verifyPrint('table')
    return e
  }

  verifyPrint(type: 'table' | 'graph') {
    if (type == 'table') {
      this.awaitGet.table = true;

      if (this.awaitGet.graph) {
        this.startPrint();
      }
    } else if (type == "graph") {
      this.awaitGet.graph = true;

      if (this.awaitGet.table) {
        this.startPrint();
      }
    }
  }

  startPrint() {
    this.load = false

    setTimeout(() => {
      if (window.location.pathname == '/user/dashboard-print') {
        window.print()
      }

    }, 1500)
  }
}
