import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ConfigDgCrudService} from "../../config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GenericCrudService extends AbstractHttpService<any> {

  constructor(
    public conf: ConfigDgCrudService
  ) {
    super(conf, environment.apiUrl, '');
  }

  override alterResource(resource: string) {
    this.resource = resource;
    super.alterResource(resource);
  }
}
