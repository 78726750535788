import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilesManageService } from "../../../../../../services/files-manage/files-manage.service";
import { ColetasService } from "../../services/coletas.service";
import { CHECKPOINTS, CHECKPOINTS_CONTAINER } from "../../../../../../core/config/endpoint-list";
import { ColetasCheckinContainerEntity } from "../../coletas-checkin-container.entity";
import { CheckpointEntity } from "../../checkpoint.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UnidadeMedidaEntity } from "../../../../unidade-medida/unidade-medida.entity";
import { OldModalComponent } from "../../../../../../shared/ui/modal/modal/modal.component";
import { CrudDataTableComponent, InterfaceCustomActions } from "@datagrupo/dg-crud";
import Swal from "sweetalert2";
import { ArquivoEntity } from "../../../../../../shared/entitys/arquivo.entity";
import { verifySizeFiles } from "../../../../../../helpers/helpers";
import { InputHelpers } from 'src/app/helpers/input.helpers';
import {SessionService} from "../../../../../../core/services/session-service/session.service";

@Component({
  selector: 'card-checkin-container',
  templateUrl: './checkin-container.component.html',
  styleUrls: ['./checkin-container.component.scss']
})
export class CheckinContainerComponent implements OnInit {

  @Input('checkinContainer') checkinContainer!: ColetasCheckinContainerEntity;
  @Input('coletaId') coletaId: number | string | undefined;
  @ViewChild('modal') modal!: OldModalComponent;
  @ViewChild('table') table!: CrudDataTableComponent;

  @Output('atualizarPontuacao') atualizarPontuacao = new EventEmitter();

  public listCheckpoints: CheckpointEntity[] = [];
  public listUnidadeMedida: UnidadeMedidaEntity[] = [];
  public listFaixas: any[] = [];
  public checkpointEntity!: CheckpointEntity;
  public tableCheckpint = new CheckpointEntity();
  public contextCheckpointTable: string | undefined;
  public customActionsCheckpointTable: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: CheckpointEntity) => {
        // this.openModal(val.id)
      },

      permission: false
    },
    {
      permission: this.session.user.perfil == 'PARCEIRO' ? false : true,
      name: 'Excluir',
      action: (val: CheckpointEntity) => {
        Swal.fire({
          icon: 'warning',
          title: "Deseja remover esse Checkpoint?",
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.service.delet('checkpoints/' + val.id).subscribe(() => {
              this.table._pesquisar();
              this.atualizarPontuacao.emit()
            })
          }
        })
      }
    }
  ]
  public showSelectFaixas = false;

  public formModal = new FormGroup({
    unidadeMedidaSelecionada: new FormControl('', [Validators.required]),
    cargaContainer: new FormControl('', [Validators.required]),
    qtdContainersColetados: new FormControl('', [Validators.required])
  })


  constructor(
    public files: FilesManageService,
    public service: ColetasService,
    public session: SessionService
  ) {
    this.formModal.controls['unidadeMedidaSelecionada'].valueChanges.subscribe(val => {
      if (!val) return;
      const index = this.listUnidadeMedida.findIndex(unidade => unidade.id == val);

      if (index == -1) {
        this.formModal.patchValue({ unidadeMedidaSelecionada: '' });
        return;
      }

      this.formModal.controls['cargaContainer'].enable();

      if (this.listUnidadeMedida[index].categoriaUnidadeMedida == "PORCENTAGEM") {
        this.listFaixas = this.listUnidadeMedida[index].faixas;
        this.showSelectFaixas = true;
        this.formModal.patchValue({ cargaContainer: '' });
        return;
      } else if (this.listUnidadeMedida[index].categoriaUnidadeMedida == "QUANTIDADE") {
        this.formModal.controls['cargaContainer'].disable();
      }
      this.formModal.patchValue({ cargaContainer: '' });
      this.showSelectFaixas = false;
    })
    this.formModal.controls['cargaContainer'].valueChanges.subscribe(val => {

      const indexUnidadeMedidaSelecionada = this.listUnidadeMedida.findIndex(uni => uni.id == this.formModal.value?.unidadeMedidaSelecionada)

      if (indexUnidadeMedidaSelecionada != -1) {
        if (typeof this.listUnidadeMedida[indexUnidadeMedidaSelecionada]?.fatorConversao != 'undefined') {
          this.formModal.patchValue({ cargaContainer: InputHelpers._ajusteInputDecimal(val) }, { emitEvent: false })
        }
      }
    })
  }

  ngOnInit(): void {
    this.contextCheckpointTable = CHECKPOINTS_CONTAINER + this.checkinContainer?.id;
    this.service.get(CHECKPOINTS_CONTAINER + this.checkinContainer?.id).subscribe(
      resp => {
        this.listCheckpoints = resp.data;
      }
    )
    this.service.get('unidade-medida/cliente-container/' + this.checkinContainer.container?.id).subscribe(
      resp => {
        this.listUnidadeMedida = resp.data;
      }
    )
  }

  uploadImgContainer(event: Event, id: number | string | undefined) {
    //@ts-ignore
    if (!id || !event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        return;
      }

      const data = new FormData()
      data.append('arquivo', file);
      data.append('checkinContainerId', String(id));

      // @ts-ignore
      this.service.uploadImage(data).subscribe(
        resp => {
          this.checkinContainer.imagem = resp.data;
        }
      )
    }
  }

  addCheckpoint() {
    this.listCheckpoints.push(new CheckpointEntity())
  }

  salvarCheckpoint() {
    if (this.formModal.invalid) {
      this.formModal.markAllAsTouched();
      return;
    }

    let {cargaContainer, unidadeMedidaSelecionada, ...form} = this.formModal.value;

    const indexUnidadeMedidaSelecionada = this.listUnidadeMedida.findIndex(uni => uni.id == unidadeMedidaSelecionada)

    if (indexUnidadeMedidaSelecionada != -1) {
      if (typeof this.listUnidadeMedida[indexUnidadeMedidaSelecionada]?.fatorConversao != 'undefined') {
        cargaContainer = InputHelpers.ajusteMoneyBrToEua(cargaContainer)
      }
    }

    this.checkpointEntity = {
      ...this.checkpointEntity,
      ...form,
      cargaContainer,
      checkinContainer: <ColetasCheckinContainerEntity>{ id: this.checkinContainer?.id },
      unidadeMedidaSelecionada: <UnidadeMedidaEntity>{ id: unidadeMedidaSelecionada }
    }

    this.saveOrUpdate(this.checkpointEntity).subscribe(
      resp => {
        this.table._pesquisar();
        this.modal.close();
        this.atualizarPontuacao.emit()
      }
    )
  }

  saveOrUpdate(data: CheckpointEntity) {
    if (!!this.checkpointEntity?.id) {
      return this.service.put(CHECKPOINTS, data)
    }
    return this.service.post(CHECKPOINTS, data)
  }

  afterClose = () => {
    this.formModal.reset();
  }

  removerImagem() {
    this.service.delet('checkin-containers/imagem-container/' + this.checkinContainer.id).subscribe(
      resp => {
        this.checkinContainer.imagem = <ArquivoEntity>{}
      }
    )
  }

  downloadImagem() {
    this.service.get('checkin-containers/imagem-container/' + this.checkinContainer.id).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, this.checkinContainer.imagem?.fileName)
      }
    )
  }

  // esse metodo deve parar de existir pois não há mais necessidade de enviar a unidade de medida
  // a esse endpoint. isso pq a unidade de medida deve ser enviada junto ao checkpoint do galoão
  alterarUnidadeMedida(val: any) {
    const data = {
      id: this.checkinContainer.id,
      unidadeMedidaSelecionada: {
        id: val
      }
    }

    this.service.put('checkin-containers', data).subscribe(
      resp => {
        alert('ok')
      },
      () => {
        // if (!!this.checkinContainer?.unidadeMedidaSelecionada?.id) {
        //   this.unidadeMedidaSelecionada = this.checkinContainer.unidadeMedidaSelecionada?.id;
        // } else {
        //   this.unidadeMedidaSelecionada = '';
        // }
      }
    )
  }

  openModal(id?: number | string) {
    if (!!id) {
      this.service.get(CHECKPOINTS + '/' + id).subscribe(
        resp => {
          this.checkpointEntity = resp.data;
          this.formModal.patchValue({ ...this.checkpointEntity })
          this.modal.open();
        }
      )
    } else {
      this.modal.open()
    }
  }
}
