import { SessionService } from './../../../../core/services/session-service/session.service';
import { EmailValidator } from '@angular/forms';
import { HttpOldService } from './../../../../core/services/http/http-old.service';
import { Component, OnInit } from '@angular/core';
import { ChildActivationStart } from '@angular/router';

@Component({
  selector: 'app-administracao-main',
  templateUrl: './administracao-main.component.html',
  styleUrls: ['./administracao-main.component.scss'],
})
export class AdministracaoMainComponent implements OnInit {
  public status: {
    habiliteEnvioEmailAvalieColeta?: boolean;
    habiliteEnvioEmailBoasVindas?: boolean;
    habiliteEnvioEmailAvisoEstoqueBaixo?: boolean;
    habiliteEnvioEmailAvisoMCPlanoCliente?: boolean;
    habiliteEnvioEmailAvisoMCClienteContratouPlano?: boolean;
    habiliteEnvioEmailAvisoMCClienteContratouPlanoPontual?: boolean;
    habiliteEnvioEmailSolicitacaoCadastroUsuario?: boolean;
    habiliteEnvioEmailAvisoUsuarioSindicoPlanosContratados?: boolean;
    habiliteEnvioEmailContratacaoPlanoPontual?: boolean;
    habiliteEnvioEmailComCupom?: boolean;
    habiliteEnvioEmailConviteMissao?: boolean;
    habiliteEnvioEmailCobranca?: boolean;
    linkModuloComunicacao?: boolean;
  } = {};

  constructor(private service: HttpOldService, private session: SessionService) {
    this.service.get('administrativo').subscribe((resp: any) => {
      this.status = resp.data;
      console.log('get', this.status);
    });
  }
  ngOnInit(): void {

  }

  atualizar() {
    this.service
    .put('administrativo', { id: this.session.user.id, ...this.status})
    .subscribe((resp: any) => {
      console.log('put', this.status);
      this.status = resp.data;
      });
  }
}

