import { MatMenuModule } from '@angular/material/menu';
import { MeuPerfilInsertEditComponent } from './meu-perfil-insert-edit/meu-perfil-insert-edit.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MatButtonModule } from '@angular/material/button';
import { UserArquivosComponent } from './subComponents/user-arquivos/user-arquivos.component';
import { UserContainerComponent } from './subComponents/user-container/user-container.component';
import { UserHistoricoPlanosComponent } from './subComponents/user-historico-planos/user-historico-planos.component';
import { UserHistoricoCobrancasComponent } from './subComponents/user-historico-cobrancas/user-historico-cobrancas.component';
import { MatStepperModule } from '@angular/material/stepper';
import {DirectivesModule} from "../../../core/directives/directives.module";

@NgModule({
  declarations: [
    MeuPerfilInsertEditComponent,
    UserArquivosComponent,
    UserContainerComponent,
    UserHistoricoPlanosComponent,
    UserHistoricoCobrancasComponent,
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        DgCrudModule,
        ReactiveFormsModule,
        AngularEditorModule,
        FormsModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatStepperModule,
        DirectivesModule
    ],
})
export class MeuPerfilModule { }
