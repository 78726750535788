import {Injectable, OnInit} from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ClientesFuncionariosEntity} from "../clientes-funcionarios.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientesFuncionariosService extends AbstractHttpService<ClientesFuncionariosEntity>{
  constructor(
    public config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, 'users')
  }
}
