export const listaTutoriais = [
  {
    title: 'Tela de login',
    list: [
      {
        img: 'assets/img-mock/tutorial/tutorial01.jpg',
        passos: [
          'Na tela de login basta digitar www.minhacoleta.com e colocar seu login e sua senha recebida em seu e-mail cadastrado no ato do cadastro.'
        ]
      }
    ]
  },
  {
    title: 'Aba Inicial - Meu Perfil/Alterar Senha',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial02.png',
      passos: [
        'Na aba inicial do sistema, logo embaixo do logotipo, terão dois botões importantes.',
        'O primeiro contém todas as informações do seu perfil, podendo alterá-las sempre que necessário.',
        'Na segunda pode-se alterar a senha predefinida pelo sistema.'
      ]
    }]
  },
  {
    title: 'Botões na lateral',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial03.png',
      passos: [
        'O botão "Funcionário" é feito o cadastro das pessoas do conselho e funcionários que irão receber informações sobre o dia da coleta e ajudar o gestor no acompanhamento do serviço.',
        'O botão "Selecionar o plano" dá a possibilidade de escolha dos melhores planos para a gerador. É muito importante que escolha esse plano logo no início para que algumas ações do sistemas sejam liberadas.',
        'O botão "Benefícios para suas unidades geradoras" mostra todas as empresas parceiras e os descontos que podem ser trocados pelos pontos acumulados a cada coleta.'
      ]
    }]
  },
  {
    title: 'Botões no topo do site',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial04.png',
      passos: [
        'O botão "Solicitar coleta extra" - serve para os casos que o contêiner tenha enchido antes do tempo marcado da coleta acontecer. De acordo com o plano escolhido a unidades geradoras tem direito a coletas extras mensais.',
        'O botão "Sobre a sua Coleta" tem duas informações.\n' +
        '\n' +
        '- A primeira é o acesso ao histórico de coletas do gerador.\n' +
        '\n' +
        '- A segunda é o acesso ao ranking da sustentabilidade entre as unidades geradoras da plataforma.',
        'Na aba verde escrita "Meus pontos" - é possível ver os pontos acumulados a cada coleta. Eles podem subir ou diminuir de acordo com o uso nos parceiros.',
        'No botão em formato de "Balão de conversa" é possível enviar o link exclusivo do seu gerador para cadastro dos usuários, assim eles poderão ter acesso ao sistema de acompanhamento e engajamento das coletas. Eles veem o relatório de impacto ambiental, o histórico e destinação da coleta,ranking de sustentabilidade,mensagens do blog e os benefícios dos parceiros. Existe também o botão de report de erros e dúvidas ao nosso time de suporte.',
        'O último botão desta aba é o de saída do sistema.'
      ]
    }]
  },
  {
    title: 'Botões de atalho rápido (centro do site)',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial05.png',
      passos: [
        'O botão "Solicitar Coleta Extra" leva você para a área de solicitação de coleta extra.',
        'O botão "Relatório de impacto ambiental" levará para o relatório mensal de coleta e o impacto positivo ao meio ambiente. (Ainda fora de funcionamento)>',
        'O botão "Conscientize seus usuários" gera um link exclusivo do seu gerador para cadastrar seus usuários. Assim eles poderão ter acesso ao sistema de acompanhamento e engajamento das coletas. Eles veem o relatório de impacto ambiental,o histórico e destinação da coleta,ranking da sustentabilidade,mensagens do blog e benefícios dos parceiros.',
        'O botão "Histórico da Coleta" leva para as informações de todas as coletas do seu gerador.',
        'O botão "Solicitar Contêiner" não está funcionando e será substituído pelo benefícios para o seu gerador.',
        'O botão "Ver Ranking" dá acesso ráido ao ranking das unidades geradoras.',
        'O botão "Acesso rápido ao Faq" dá acesso a diversas informações sobre o que é reciclável e o que não é,dúvidas sobre o serviço de coleta , entre outras informações.'
      ]
    }]
  },
  {
    title: 'Banners',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial06.png',
      passos: [
        'Na parte dos banners é possível ser direcionado para partes e momentos importantes do site, como saber novos planos cadastrados,ver as missões disponíveis e tirar dúvidas com nosso time de suporte.'
      ]
    }]
  },
  {
    title: 'Relatório de Impacto',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial07.png',
      passos: [
        'Ainda na aba inicial pode-se acessar o “Relatório de Impacto Ambiental”, ver o “motorista/catador” definido para sua rota” e ter acesso às informações compartilhadas no “blog da coleta seletiva”.'
      ]
    }]
  },
  {
    title: 'Aba Meu Perfil',
    list: [
      {
        img: 'assets/img-mock/tutorial/tutorial08.png',
        passos: [
          'Meus Dados: São os dados cadastrais do gestor.',
          'Dados do gerador: São os dados do gerador.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial09.png',
        passos: [
          'Endereço: Dá o acesso ao endereço do gerador.',
          'Contêineres: Dá a informação dos tipos de contêineres que estão no seu gerador.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial10.png',
        passos: [
          'Arquivos: Nesta parte ficam odos os boletos gerados para o seu gerador.',
          'Histórico de planos: Caso exista a necessidade da mudança do plano o histórico fica salvo ali.',
          'Botões Inferiores: Nos botões pode-se baixar o termo de adesão, salvar qualquer alteração feita e também pode alterar a senha.'
        ]
      }
    ]
  },
  {
    title: 'Aba Funcionários',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial11.png',
      passos: [
        'Nesta aba você pode pesquisar de forma rápida todos os funcionários cadastrados no sistema.',
        'Caso queira cadastrar, basta clicar em novo que uma nova aba será aberta para que o cadastro seja feito.'
      ]
    }]
  },
  {
    title: 'Aba Selecionar Planos',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial12.png',
      passos: [
        'A aba planos mostra todos os planos disponíveis para as unidades geradoras. Nela pode-se escolher um novo plano como mudar o período do plano escolhido.'
      ]
    }]
  },
  {
    title: 'Aba Missões e Benefícios',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial13.png',
      passos: [
        'Nesta área você tem acesso a todos os parceiros que estão se cadastrando como a Ecoplat e que dão benefícios para o seu gerador. Também temos benefícios estendidos aos usuários no caso de algumas empresas específicas. Nesta área você também tem acesso as missões de engajamento da coleta seletiva. Aqui algumas empresas lançam desafios e caso eles sejam cumpridos todos os usuários e funcionários cadastrados no sistema ganham.'
      ]
    }]
  },
  {
    title: 'Aba Solicitar Coleta Extra',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial14.png',
      passos: [
        'Nesta aba é possível fazer a solicitação de coleta extra ou antecipar a coleta da sua semana caso tenha enchido antes do tempo.',
        'Basta marcar a data e informar o motivo da coleta na justificativa.'
      ]
    }]
  },
  {
    title: 'Aba histórico de coletas',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial15.png',
      passos: [
        'Nesta aba estão todas as coletas realizadas no seu gerador desde que iniciou a coleta seletiva da Ecoplat. Dentro de cada coleta é possível ver o motorista/catador que fez a coleta, verificar o volume de resíduo retirado em cada coleta, ver a avaliação do motorista/catador sobre a recepção dele no seu prédio, o Transportador para onde o resíduo foi, o tamanho e capacidade do seu contêiner e os pontos acumulados nesta coleta.'
      ]
    }]
  },
  {
    title: 'Aba Ranking',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial16.png',
      passos: [
        'A aba ranking mostra os níveis de coleta de cada condomínio e cria uma ordem do seguida pelo condomínio que tem o maior volume de resíduos descartados corretamente. Ele aparece com o volume mensal, mas pode ser feita uma busca pelo ano, basta colocar o ano que deseja e clicar em pesquisar.'
      ]
    }]
  },
  {
    title: 'Missões do seu gerador',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial17.png',
      passos: [
        'Logo quando você faz login na plataforma,na página home,ao lado do relatório de impacto ambiental e abaixo do agendamento de coleta, você consegue ver todas as missões que seu gerador e você aceitaram e o progresso de todos. Além de saber se a meta foi batida antes do fechamento da missão no sistema.'
      ]
    }]
  },

  {
    title: 'Aceitando as missões do gerador',
    list: [
      {
        img: 'assets/img-mock/tutorial/tutorial18.png',
        passos: [
          'Logado no sistema da Ecoplat você clica em Missões e Benefícios. (Não sabe como fazer login em nossa plataforma? Siga os passos do nosso tutorial na aba " Tela de login").\n' +
          '\n' +
          '- Depois de logado você verá todas as Missões e Desafios disponíveis para o seu gerador ou para você como usuário, sempre com os seguintes status - Aceitar Missão - Missão Aceita - Missão Aceita Resgatar Cupom - Missão Esgotada - Missão para condomínio.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial19.png',
        passos: [
          'Aceitar Missão: Se esse botão estiver aparecendo você pode aceitar a missão que está disponível. Se você for o gestor esse botão aparecerá tanto nas missões para o condomínio quanto nas missões para o usuário.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial20.png',
        passos: [
          'Missão Aceita: Se for uma missão sustentável para o condomínio o seu gestor já o aceitou, se for uma missão para usuário quer dizer que você já aceitou em algum momento.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial21.png',
        passos: [
          'Resgatar Cupom: Esse botão aparece sempre que seu gestor aceitou a missão sustentável para o condomínio e a mesma foi cumprida ou você aceitou a missão e a mesma foi cumprida. Depois que o sistema identifica essa conclusão esse botão aparece e você pode ter acesso ao desconto.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial22.jpg',
        passos: [
          'Missão Esgotada: Quer dizer que o número de unidades geradoras ou usuários cadastrados ali já atingiu a cota disponível para empresa e não é permitido que ninguém mais aceite, até que possamos abri-la novamente.',
          "Missão para o Condomínio: Esse tipo de missão só o gestor pode aceitar e se você tiver com o login de usuário aparecerá a mensagem que está na imagem abaixo. Como gestor você poderá aceitar essa missão sustentável, caso ainda não tenha feito. Depois do aceite da missão sustentável pelo gestor as mensagens que irão aparecer serão as dos status citados acima de acordo com a condição do gerador com aquela missão sustentável."
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial24.png',
        passos: [
          'Quando seu gerador alcançar a meta da missão irá aparecer o botão Concluir Missão.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial25.png',
        passos: [
          'Resgatar os Descontos dos Desafios e Missões sustentável: Assim que o desafio e missão é cumprida pelo usuário ou condomínio o sistema envia um e-mail para resgatar o cupom, mas no sistema também fica disponível o resgate como você viu no tópico (Resgatar o cupom).\n' +
          '\n' +
          '- Você só precisa clicar em resgatar cupom no sistema ou no e-mail que chegou.'
        ]
      },
      {
        img: 'assets/img-mock/tutorial/tutorial26.png',
        passos: [
          'Vai aparecer uma tela da Ecoplat com o nome do cupom para você copiar e um botão que vai levar para o canal de vendas do parceiro. A partir daí é tudo com nosso parceiro e você consegue comprar normalmente com ele. No final da venda você informa o código promocional e o desconto será dado.'
        ]
      }
    ]
  },

  {
    title: 'Conscientize seus usuários',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial27.png',
      passos: [
        'Quando se acessa o balão de conversa ou o botão de atalho conscientize seus usuários, abre uma tela sobreposta que mostra um link onde o gestor pode compartilhar com os usuários o acesso ao sistema. Eles terão acesso ao histórico de coleta, ranking da sustentabilidade, ao blog da coleta seletiva, à falar com suporte, FAQ, as missões de engajamento e benefícios aceitas pelo condomínio.'
      ]
    }]
  },
  {
    title: 'Fale com a Lix',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial28.png',
      passos: [
        'Tela sobreposta onde todos podem mandar mensagens diretamente para nosso time de suporte.'
      ]
    }]
  },
  {
    title: 'FAQ',
    list: [{
      img: 'assets/img-mock/tutorial/tutorial29.png',
      passos: [
        'Aqui estão adicionadas as principais perguntas e respostas sobre resíduos e sobre a coleta seletiva da Ecoplat.'
      ]
    }]
  }
]
