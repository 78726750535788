<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">Nav headers</h1>
</header>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Sobre essa tela</h4>
        <p>
          Agruparei todas as navs nessa tela para facilitar a leitura
        </p>
      </div>
    </div>
  </div>
</div>

<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h3>Nav-tabs</h3>
</header>

<ul class="nav nav-tabs default-tabs">
  <li class="nav-item" (click)="tableVisible = 'opcao-1'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-1'">Opção 1</h4>
  </li>
  <li class="nav-item" >
    <h4 class="nav-link card-title disabled" [class.active]="tableVisible == 'opcao-2'">Opção disabled</h4>
  </li>
  <li class="nav-item" (click)="tableVisible = 'opcao-3'">
    <h4 class="nav-link card-title error" [class.active]="tableVisible == 'opcao-3'">Opção error</h4>
  </li>
  <li class="nav-item" (click)="tableVisible = 'opcao-4'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-4'">Opção 4</h4>
  </li>
</ul>

<div class="container">
  <div class="row mt-2">
    <div class="col-12">
      <h4 class="alert-heading">Início rápido</h4>
      <p>
        Apenas inclua a classe <b>default-tabs</b> junto a classe <b>nav-tabs</b> em sua lista padrão do bootstrap
      </p>
      <p>Adicione a classe <b class="fc-red">error</b> ou <b>disabled</b> para customizar a tab</p>
      <pre><textarea disabled class="w-100" rows="16">
<ul class="nav nav-tabs default-tabs">
  <li class="nav-item" (click)="tableVisible = 'opcao-1'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-1'">Opção 1</h4>
  </li>
  <li class="nav-item" (click)="tableVisible = 'opcao-2'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-2'">Opção 2</h4>
  </li>
  <li class="nav-item" (click)="tableVisible = 'opcao-3'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-3'">Opção 3</h4>
  </li>
  <li class="nav-item" (click)="tableVisible = 'opcao-4'">
    <h4 class="nav-link card-title" [class.active]="tableVisible == 'opcao-4'">Opção 4</h4>
  </li>
</ul>
</textarea></pre>
    </div>
  </div>
</div>

<hr>
