<div class="container">
  <div class="row">
    <div class="col-12">
      <card class="bbb">
        <div class="card-header" style="font-size: x-large">
          Informações Gerais
        </div>
        <div>
          <div class="row">
            <div class="col-sm-4">
              <card-item
                class="card"
                size="lg"
                icon="assignment_ind"
                text="Usuários Cadastrados"
                [subText]="data.totalUsuarios"
              >
              </card-item>
            </div>
            <div class="col-sm-4">
              <card-item
                class="card"
                size="lg"
                icon="emoji_objects"
                text="Missões Cadastradas"
                [subText]="data.totalMissao"
              >
              </card-item>
            </div>
            <div class="col-sm-4">
              <div>
                <card-item
                  class="card"
                  size="lg"
                  icon="home"
                  text="Geradores Cadastrados"
                  [subText]="data.totalCondominios"
                ></card-item>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <card-item
                icon="group"
                size="lg"
                text="Masters Cadastrados"
                [subText]="data.totalParceiros"
              >
              </card-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <card-item
                size="lg"
                icon="badge"
                text="Geradores Da Logística Reversa Cadastrados"
                [subText]="data.totalEmpresaClienteCreditoLogisticaReversa"
              >
              </card-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="card">
              <card-item
                icon="near_me"
                size="lg"
                text="Total Rotas"
                [subText]="data.totalRotas"
              >
              </card-item>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <card-item
                icon="feed"
                size="lg"
                text="Total Pesagem Notas Fiscais"
                [subText]="data.totalToneladasCadastrosNotaFiscal"
              >
              </card-item>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <card-item
                icon="diamond"
                size="lg"
                text="Total Cupons Resgatados  "
                [subText]="data.totalCuponsResgatados"
              >
              </card-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <card-item
                icon="view_in_ar"
                size="lg"
                text="Total Transportadores"
                [subText]="data.totalGalpoes"
              >
              </card-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <card-item
                size="lg"
                icon="portrait"
                text="Total Toneladas Venda Logistica Reversa"
                [subText]="data.totalToneladasVendaLogisticaReversa"
              >
              </card-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <card-item
                icon="water_drop"
                size="lg"
                text="Total Litros Coletados"
                [subText]="data.totalLixoColetadoLitros"
              >
              </card-item>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <card-item
                icon="delete_forever"
                size="lg"
                text="Total Resíduos Coletados"
                [subText]="data.totalLixoColetadoToneladas"
              >
              </card-item>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
