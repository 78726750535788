<div
  id="vendas-logistica-reversa-insert-edit"
  *ngIf="!!entity"
>
  <div class="container-data">
    <div class="row">
      <div class="col-12">


        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Clientes</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    (click)="modalCliente.open()"
                    [disabled]="!!entity.vendaClienteclrDTO"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
            <hr>
          </div>
          <div class="card-body">
            <table class="crud-table">
              <thead>
              <tr>
                <th>Nome</th>
                <th>Quantidade (TON)</th>
                <th style="max-width: 4rem">ID Pagamento</th>
                <th [hidden]="entity.vendaClienteclrDTO?.identificadorPagamento">Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="!entity.vendaClienteclrDTO">
                <td colspan="12">Nenhum registro encontrado</td>
              </tr>
              <tr *ngIf="!!entity.vendaClienteclrDTO">
                <td>
                  {{entity.vendaClienteclrDTO.clienteCreditoLogisticaReversa?.nome || '--'}}
                </td>
                <td>
                  {{format(entity.vendaClienteclrDTO.quantidadeVendida) || '--'}}
                </td>
                <td style="max-width: 8rem">
                  {{entity.vendaClienteclrDTO.identificadorPagamento || '--'}}
                </td>
                <td [hidden]="entity.vendaClienteclrDTO?.identificadorPagamento">
                  <button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu
                    xPosition="before"
                    #menu="matMenu"
                  >
                    <button
                      (click)="EditarCliente()"
                      mat-menu-item
                    >
                      Editar
                    </button>
                    <button
                      [hidden]="entity.id"
                      (click)="removeCliente()"
                      mat-menu-item
                    >
                      Excluir
                    </button>
                  </mat-menu>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </card>


      </div>
    </div>
  </div>
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Produtos</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    (click)="modalProduto.open(entity.vendaProdutosNotaFiscal)"
                    [disabled]="entity.vendaClienteclrDTO?.identificadorPagamento"
                    class="btn btn-success btn-card-header"
                  >
                    <mat-icon>add</mat-icon>
                    adicionar
                  </button>
                </card-header-buttons>
              </div>
            </div>
            <hr>
          </div>
          <div class="card-body">
            <div
              class="card-body"
              style="padding: 0"
            >


              <table class="crud-table">
                <thead>
                <tr>
                  <th>Nome</th>
                  <th>Produto Nota Fiscal ID</th>
                  <th>Quantidade Disponível (TON)</th>
                  <th>Quantidade consumida (TON)</th>
                  <th>Chave nota fiscal</th>
                  <th [hidden]="entity.vendaClienteclrDTO?.identificadorPagamento">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="entity.vendaProdutosNotaFiscal?.length == 0">
                  <td colspan="12">Nenhum registro encontrado</td>
                </tr>
                <tr *ngFor="let produto of entity.vendaProdutosNotaFiscal; let i = index">
                  <td>
                    {{produto?.produtoNotaFiscal.produto.nome || '--'}}
                  </td>
                  <td>{{produto.produtoNotaFiscal.id}}</td>
                  <td>
                    {{format(produto.quantidadeDisponivel)}}
                  </td>
                  <td>{{format(produto.quantidadeVendida)}}</td>
                  <td>{{format(produto?.notaFiscalChaveAcesso || '--')}}</td>
                  <td [hidden]="entity.vendaClienteclrDTO?.identificadorPagamento">
                    <button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu
                      xPosition="before"
                      #menu="matMenu"
                    >
                      <button
                        (click)="removeProdutos(i) "
                        mat-menu-item
                      >
                        Excluir
                      </button>
                    </mat-menu>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>
<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
    [backAfterSave]="false"
    [hidden]="entity?.vendaClienteclrDTO?.identificadorPagamento"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
    [hidden]="entity?.vendaClienteclrDTO?.identificadorPagamento"
  >
    Salvar e
    Voltar
  </button>
  <button
    [hidden]="!entity?.id"
    crud-deletar
    class="btn btn-secondary"
  >
    Excluir
  </button>
  <button
    [hidden]="!entity?.id || !entity?.vendaClienteclrDTO?.identificadorPagamento"
    (click)="comprovanteReenvio()"
    class="btn btn-success"
  >
    Reenvio comprovante
  </button>
  <button
    [hidden]="!entity?.id || !!entity?.vendaClienteclrDTO?.identificadorPagamento"
    (click)="sinalizarPago()"
    class="btn btn-success"
  >
    Sinalizar como pago
  </button>
</page-footer>


<subComponentes-vendas-logistica-clientes
  #modalCliente
  (salve)="salvarCliente($event)"
>
</subComponentes-vendas-logistica-clientes>


<subComponente-Vendas-Logistica-Produtos
  #modalProduto
  [idVendaLogisticaReversa]="entity?.id"
  [idCliente]="getIdClienteParaModalProdutos()"
  (salve)="salvarProdutos($event)"
>
</subComponente-Vendas-Logistica-Produtos>
