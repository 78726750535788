import { Component, OnInit } from '@angular/core';
import { HistoricoColetasEntity } from '../historico-coletas.entity';
import { HistoricoColetasService } from '../service/historico-coletas.service';

@Component({
  selector: 'app-historico-coletas-main',
  templateUrl: './historico-coletas-main.component.html',
  styleUrls: ['./historico-coletas-main.component.scss']
})
export class HistoricoColetasMainComponent implements OnInit {


  public mapEntity = new HistoricoColetasEntity()

  constructor(
    public service: HistoricoColetasService

  ) { }

  ngOnInit(): void {
  }

}
