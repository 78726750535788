import {AbstractEntity, DataServer, DgFilter_autoComplete, DgTableColumn} from "@datagrupo/dg-crud";
import { GalpaoEntity } from "../galpao/galpao.entity";
import { CategoriaArquivoEntity } from "../../categoria-arquivo/categoria-arquivo.entity";
import { environment } from "../../../../../environments/environment";
import {AutoCompleteGalpaoOutPut} from "../../../../helpers/auto-complete/galpao-autocomplete.helper";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { formataData, formata_data_utc_br } from "src/app/helpers/helpers";

function verifyVendimento(data?: string): boolean {
  if (!data) return false
  const date = new Date(data);
  return date < new Date();
}

@DataServer({
  path: environment.apiUrl,
  context: 'galpoes/documentogalpao'
})
export class GalpaoArquivoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    galpaoNome?: GalpaoEntity,
    nomeArquivo?: string,
    nomeCategoria?: CategoriaArquivoEntity,
    vencimento?: string
  ) {
    super();
    this.id = id;
    this.galpaoNome = galpaoNome;
    this.nomeArquivo = nomeArquivo;
    this.nomeCategoria = nomeCategoria;
    this.vencimento = vencimento;
  }

  @DgFilter_autoComplete({ name: 'Transportador' }, {
     getServerData: 'all',
     getUrl: environment.apiUrl + 'galpoes',
     propertValue: 'nome',
     nameParamFind: 'idGalpao',
     propertKey: 'id',
    outputFormat: AutoCompleteGalpaoOutPut
    })
  @DgTableColumn({ columnName: 'TRANSPORTADOR', resource: (value: GalpaoEntity) => !!value?.nome ? value.nome : '--' })
  public galpaoNome: GalpaoEntity | undefined;

  @DgTableColumn({columnName: 'NOME DO ARQUIVO'})
  public nomeArquivo: string | undefined;

  @DgTableColumn({ columnName: 'CATEGORIA', resource: (val: any, row: GalpaoArquivoEntity) => {
    //@ts-ignore
    return !!row.categoriaArquivoDTO?.nome ? row.categoriaArquivoDTO.nome : '--';
    } })
  public nomeCategoria: CategoriaArquivoEntity | undefined;

  @DgTableColumn({columnName: 'VENCIMENTO', sort: true, resource: (data: string) => {
      if (!!data) {
        if (verifyVendimento(data)) {
          return '<p class="fc-red" style="margin-bottom: 0">Vencido em</p><p>'+ formata_data_utc_br(data) +'</p>'
        } else {
          return formata_data_utc_br(data);
        }
      }

      return '--'
  }})
  public vencimento: string | undefined;

}



