import { AbstractEntity, DataServer, DgFilter_input, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";


const statusList = [
  { key: 'all', value: 'Ambos' },
  { key: 'true', value: 'Ativo' },
  { key: 'false', value: 'Inativo' }
]
const filterFunc = (val: string, nameParam: string) => {
  if (val == 'all') {
    return
  }

  return {
    [nameParam]: val
  }
}
@DataServer({
  path: environment.apiUrl,
  context: 'containers'
})
export class GalpaoContainerEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    dataDesativacao?: string,
    capacidadeLitros?: number,
    descricao?: string,
    nome?: string,
    modelo?: boolean,
    ativado?: boolean,
    categoriaContainers?: any
  ) {
    super();
    this.id = id;
    this.dataDesativacao = dataDesativacao;
    this.capacidadeLitros = capacidadeLitros;
    this.descricao = descricao;
    this.nome = nome;
    this.modelo = !!modelo;
    this.ativado = ativado;
    this.categoriaContainers;
  }

  @DgFilter_input('text', { colum: '6' })
  @DgTableColumn({
    columnName: 'Nome', sort: true, resource: (val: string, row: GalpaoContainerEntity) => {
      if (!!row?.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? val : '--'}</span>`
      }
      return val
    }
  })
  public nome: string | undefined

  @DgFilter_select({
    name: 'STATUS',
    data: statusList,
    propertKey: 'key',
    propertValue: 'value',
    nameParamFilter: 'ativo',
    outputFormat: (val: string) => filterFunc(val, 'ativo')
  })
  public ativo: boolean | undefined;
  public ativado: boolean | undefined;

  @DgTableColumn({
    columnName: 'Capacidade (LITROS)', sort: true, resource: (val: number, row: GalpaoContainerEntity) => {
      if (!!row?.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? Number(val).toLocaleString('pt-BR') : '--'}</span>`
      }
      return Number(val).toLocaleString('pt-BR')
    }
  })
  public capacidadeLitros: number | string | undefined

  public modelo: boolean = true
  public descricao: string | undefined
  public categoriaContainers: any | undefined
  public dataDesativacao: string | boolean | undefined

}
