import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    PrintLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class PrintLayoutModule { }
