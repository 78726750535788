import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColetasMainComponent} from './coletas-main/coletas-main.component';
import {ColetasInsertEditComponent} from './coletas-insert-edit/coletas-insert-edit.component';
import {UiModule} from "../../../../shared/ui/ui.module";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {AlterarRotaComponent} from './subComponents/alterar-rota/alterar-rota.component';
import {CheckinContainerComponent} from './subComponents/checkin-container/checkin-container.component';
import {DirectivesModule} from "../../../../core/directives/directives.module";
import {NgxMaskModule} from "ngx-mask";
import {ModalColetaPontualComponent} from './subComponents/modal-coleta-pontual/modal-coleta-pontual.component';
import {ModalPlanoColetaComponent} from './subComponents/modal-plano-coleta/modal-plano-coleta.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {DgTableModule, CdkDynamicTableModule, CdkDynamicGroupModule, DgPaginatorModule} from "@datagrupo/dg-ng-util";
import {CustomAutocompleteModule} from "../../../../shared/custom-autocomplete/custom-autocomplete.module";
import { ColetasMain2Component } from './coletas-main2/coletas-main2.component';


@NgModule({
  declarations: [
    ColetasMainComponent,
    ColetasInsertEditComponent,
    AlterarRotaComponent,
    CheckinContainerComponent,
    ModalColetaPontualComponent,
    ModalPlanoColetaComponent,
    ColetasMain2Component
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgxMaskModule,
    MatTooltipModule,
    DgTableModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule,
    CustomAutocompleteModule
  ]
})
export class ColetasModule {
}
