import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { CategoriaArquivoEntity } from '../categoria-arquivo.entity';
import { CategoriaArquivoService } from '../service/categoria-arquivo.service';
import { PickListComponent } from '../../../../shared/ui/pick-list/pick-list.component';
import { ClientesEntity } from '../../_clientes/clientes/clientes.entity';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';

@Component({
  selector: 'app-galpao-categoria-arquivo-insert',
  templateUrl: './categoria-arquivo-insert.component.html',
  styleUrls: ['./categoria-arquivo-insert.component.scss'],
})
export class CategoriaArquivoInsertComponent
  extends AbstractInsertEdit<CategoriaArquivoEntity>
  implements OnInit {
  public listaSelect: { key: any, value: any }[] = [
    { key: 'SINDICO', value: "Gestor" },
    { key: 'CONSELHO', value: "Conselho/Administrativo" },
    { key: 'MORADOR', value: "Usuário" },
    { key: 'PORTEIRO', value: "Operador" },
    { key: 'FINANCEIRO', value: "Financeiro" },
  ];

  listaSelecionados: any[] | undefined = [];
  @ViewChild('pickList') pickList!: PickListComponent;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
  });
  constructor(
    public servic: CategoriaArquivoService,
    public conf: ConfigDgCrudService,
    public serviceCliente: ClientesService
  ) {
    super(conf, servic);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new CategoriaArquivoEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
    });
    this.listaSelecionados = this.entity?.acesso?.map(item => {
      const index = this.listaSelect.findIndex(val => item == val.key);
      if(index != -1){
        return { key: item, value: this.listaSelect[index].value}
      }
      return null;
    }).filter(item => !!item)
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }
    let listaSelected = this.pickList.getList().map(item => item.key)
    const formV = this.form.value;
    this.entity = {
      ...this.entity,
      ...formV,
      acesso: listaSelected
    };
    return true;
  }


}
