import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {TIPO_LIXO} from "../../../../../core/config/endpoint-list";
import {TipoLixoCompostoEntity} from "../../tipo-lixo-composto.entity";
import {crudDispatchEvent} from "@datagrupo/dg-crud";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'modal-tipo-lixo-filho',
  templateUrl: './tipo-lixo-filho.component.html',
  styleUrls: ['./tipo-lixo-filho.component.scss']
})
export class TipoLixoFilhoComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;

  @Input('listTipoLixo') listTipoLixo: TipoLixoCompostoEntity[] | undefined;
  @Input('tipoLixoId') tipoLixoId: number | string | undefined;

  listaTipoLixo: { id: number, nome: string, categoria: string }[] = [];
  edit = false;
  private entity: TipoLixoCompostoEntity | undefined;

  public form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    porcentagemFilho: new FormControl('', [Validators.required])
  })

  constructor(private service: GenericCrudService) {
    service.get(TIPO_LIXO, { params: { unpaged: true } }).subscribe(resp => {
      this.listaTipoLixo = resp.data
    })

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.form, v, ['porcentagemFilho'])
    )
  }

  ngOnInit(): void {}

  open(data?: any) {
    if (!!data) {
      this.entity = data;
      this.edit = true;
      this.form.controls['nome'].disable();
      this.form.patchValue({
        id: data?.id,
        nome: data.tipoLixoFilho.id,
        porcentagemFilho:  String(InputHelpers.initInputMoney(data.porcentagemFilho))
      })
    }
    this.modal.open()
  }

  close() {
    this.modal.close();
  }

  salvar(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.listTipoLixo) {
      this.listTipoLixo = [];
    }

    if (this.edit) {
      this.editTipoLixo()
    } else {
      this.addTipoLixo();
    }
  }

  verifyDisabled(id: number) {
    if (id == this.tipoLixoId) {
      return true;
    }

    const index = this.listTipoLixo?.findIndex(tipo => tipo.tipoLixoFilho?.id == id)

    return index != -1;
  }

  private addTipoLixo() {
    const form = this.form.value
    const indexLixo = this.listaTipoLixo.findIndex(lixo => lixo.id == form.nome)

    if(indexLixo == -1){
      return;
    }


    const dataTipoLixo = <TipoLixoCompostoEntity>{
      porcentagemFilho:  Number(InputHelpers.ajusteMoneyBrToEua(this.form.value.porcentagemFilho)),
      tipoLixoFilho: { id: this.listaTipoLixo[indexLixo].id, nome: this.listaTipoLixo[indexLixo].nome }
    }

    // @ts-ignore
    this.listTipoLixo.push(dataTipoLixo);

    crudDispatchEvent('tipoLixoComposto');
    this.modal.close()
    return;
  }

  private editTipoLixo() {
    const form = this.form.value

    if (!!form.id) {
      const index = this.listTipoLixo?.findIndex(tipoLixo => tipoLixo.id == form.id)

      if (index != -1) {
        // @ts-ignore
        this.listTipoLixo[index] = {
          // @ts-ignore
          ...this.listTipoLixo[index],
          ...form,
          porcentagemFilho:  Number(InputHelpers.ajusteMoneyBrToEua(this.form.value.porcentagemFilho)),
        }
      }
    } else {
      const index = this.listTipoLixo?.findIndex(tipoLixo => tipoLixo.tipoLixoFilho?.id == this.entity?.tipoLixoFilho?.id);

      if (index != -1) {
        // @ts-ignore
        this.listTipoLixo[index] = {
          // @ts-ignore
          ...this.listTipoLixo[index],
          ...form,
          porcentagemFilho:  Number(InputHelpers.ajusteMoneyBrToEua(this.form.value.porcentagemFilho)),
        }
      }
    }

    crudDispatchEvent('tipoLixoComposto');
    this.modal.close();
    return;
  }

  public afterClose = () => {
    this.form.reset();
    this.form.controls['nome'].enable();
    this.edit = false;
    this.entity = undefined;
  }
}
