import { AbstractEntity, DataServer, DgTableColumn } from '@datagrupo/dg-crud';
import { formata_data_utc_br } from 'src/app/helpers/helpers';
import { ProdutoEntity } from './../produto/produto.entity';
import { environment } from "../../../../environments/environment";
import { CLIENTE_CREDITO_LOGISTICA_REVERSA_PRODUTOS } from "../../../core/config/endpoint-list";

const formataData = (dataString: string) => {
  if (!dataString) return "--"
  return formata_data_utc_br(dataString)
}

const objMaskPercentualEmbalabens = {
  VINTE_DOIS: '22%',
  VINTE_TRES_A_CINQUENTA: '23% a 50%',
  CINQUENTA_UM_A_CEM: '51% a 100%',
  CENTO_UM_A_DUZENTOS: '101% a 200%',
}

@DataServer({
  path: environment.apiUrl,
  context: CLIENTE_CREDITO_LOGISTICA_REVERSA_PRODUTOS
})
export class LogisticaProdutosEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    clienteCreditoId?: number | string,
    nome?: string,
    dataInclusao?: string,
    qtdProdutoGerado?: string,
    porcentagemEmbalagens?: string,
    produto?: ProdutoEntity,
    valorDoProduto?: string,
    estado?: string,
    dataCompensacao?: string,
    //produto?: string,
    valorAnualDaCobrancaDaLogistica?: string,
    valorMensalDaCobrancaDaLogistica?: string,

  ) {
    super();
    this.id = id;
    this.clienteCreditoId = clienteCreditoId;
    this.nome = nome;
    this.dataInclusao = dataInclusao;
    this.qtdProdutoGerado = qtdProdutoGerado;
    this.porcentagemEmbalagens = porcentagemEmbalagens;
    this.produto = produto;
    this.estado = estado;
    this.dataCompensacao = dataCompensacao;
    this.valorDoProduto = valorDoProduto;
    this.valorAnualDaCobrancaDaLogistica = valorAnualDaCobrancaDaLogistica;
    this.valorMensalDaCobrancaDaLogistica = valorMensalDaCobrancaDaLogistica;

  }

  // public id: number | string | undefined;
  public clienteCredito: string | number | undefined;
  public clienteCreditoId: string | number | undefined;

  @DgTableColumn({
    columnName: 'Nome', resource: (prod: ProdutoEntity) => {
      return !!prod.nome ? prod.nome : '--';
    }, sort: true
  })
  public produto: ProdutoEntity | undefined;

  @DgTableColumn({ columnName: 'Data de Inclusão', resource: formataData, sort: true })
  public dataInclusao: string | undefined;


  @DgTableColumn({ columnName: 'percentual a compensar', sort: true })
  public porcentagemEmbalagens: string | undefined;

  @DgTableColumn({
    columnName: 'percentual representado em toneladas', resource: (val: string) => {
      return !!val ? Number(val).toLocaleString('pt-BR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      }) : '--';
    }, sort: true
  })
  public qtdProdutoGerado: string | undefined;

  @DgTableColumn({ columnName: 'estado', sort: true })
  public estado: string | undefined;

  @DgTableColumn({ columnName: 'ano', sort: true })
  public dataCompensacao: string | undefined;

  public nome: string | undefined;
  public valorDoProduto: string | undefined;


  public valorAnualDaCobrancaDaLogistica: string | undefined;
  public valorMensalDaCobrancaDaLogistica: string | undefined;

}
// @DgTableColumn({ columnName: 'Nome', resource: (prod: ProdutoEntity) => {
//   if (Array.isArray(prod)) {
//     if (prod[0]) {
//       return prod[0]?.nome; }
// }
// return 'produto';
// }})
