import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { BeneficiosEntity } from '../beneficios.entity';
import { BeneficiosService } from '../service/beneficios.service';

@Component({
  selector: 'app-beneficios-main',
  templateUrl: './beneficios-main.component.html',
  styleUrls: ['./beneficios-main.component.scss']
})
export class BeneficiosMainComponent implements OnInit {

  listaBeneficios: BeneficiosEntity[] = [];
  listaPedidosSelecionados: any[] = [];
  valorSomado: number = 0;
  pedidoMin: any[] = [];

  @ViewChild('modalBeneficio') modalBeneficio!: OldModalComponent;

  constructor(
    public servic: BeneficiosService,
    public conf: ConfigDgCrudService
  ) {
    servic.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaBeneficios = resp.data
    })
  }

  ngOnInit(): void {
  }


  initNewEntity(): void {
  }

  solicitarBeneficio() {
    for (let i = 0; i < this.listaBeneficios.length; i++) {
      if (this.listaBeneficios[i].quantidadePedido != undefined) {
        this.listaPedidosSelecionados = this.listaBeneficios.filter(item => item.quantidadePedido)
      }
    }
    if (this.listaPedidosSelecionados.length <= 0) {
      alert('selecione o beneficio');
      return false
    }
    this.confirmarBeneficio();
    return true
  }

  confirmarBeneficio() {
    let valor: number[] = [];
    if (this.listaPedidosSelecionados.length > 0) {
      for (let i = 0; i < this.listaPedidosSelecionados.length; i++) {
        const element = this.listaPedidosSelecionados[i];
        valor.push(element.valor * element.quantidadePedido)
      }
    }
    for (let i = 0; i < valor.length; i++) {
      this.valorSomado += valor[i];
    }
    this.modalBeneficio.open();
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };

}
