<div
    id="clientes-funcionarios-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Usuários</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search [entityMap]="mapEntity"></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableClienteFuncionario
                        #tableFuncionario
                        [entityMap]="mapEntity"
                        [service]="service"
                        [persistentParams]="TableParams"
                        [customActions]="customAction"
                        [customDblclick]="mapEntity.tipoUsuario == 'SINDICO' ? true : false || session.user.profile == 'HOLDING'"
                        [register]="true"
                    ></crud-data-table>
                    <div class="d-flex justify-content-center card-footer">
                        <dg-pagination
                            [dataPagination]="mapEntity.configEntityTable.pagination"
                            (changePage)="tableFuncionario.changePages($event)"
                        ></dg-pagination>
                    </div>
                </div>
            </card>
        </div>
    </div>
</div>




<page-footer>
    <a routerLink="novo">
        <button
            *ngIf="this.session.user.profile == 'SINDICO' && mapEntity.tipoUsuario != 'MORADOR' || this.session.user.perfil == 'ADMINISTRADOR' ? true : false"
            crud-salvar
            class="btn btn-success"
        >
            Novo
        </button>
    </a>
</page-footer>
