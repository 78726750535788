import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {filtersInterface} from "../../../pages/_user/dashboard/dashboard-main/dashboard-main.component";
import {ajusteDateByPaste} from "../../../helpers/helpers";
import {ClientesEntity} from "../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../pages/_user/_galpao/galpao/galpao.entity";
import {GenericCrudService} from "../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../core/services/session-service/session.service";
import {CloseAutocomplete} from "@datagrupo/dg-ng-util";
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_OPERADOR_LOGISTICO,
  DASHBOARD_FILTER_UNIDADE_GERADORA, TIPO_LIXO_FIND_FILTER
} from "../../../core/config/endpoint-list";
import {TipoLixoEntity} from "../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {SidebarDataManage} from "../cdk/sidebar-data-manage";

@Component({
  selector: 'side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.scss']
})
export class SideFilterComponent implements OnInit, OnChanges {

  @Output('sidebarChange') sidebarChange = new EventEmitter<filtersInterface>();
  @Output('visible') visible = new EventEmitter<boolean>();
  @Input() tipoDeTela: 'dashboard' | 'rankingAterroZero' | 'rankingTaxaReciclagem' = 'dashboard';

  // comporta a lista que iniciará os itens selecionados mesmo que esses
  // não estejam na lista de disponíveis para seleção.
  listsPreSelected: {
    unidades: any[],
    estados: any[],
    galpao: any[],
    tipoResiduo: any[],
    perfilUnidade: string,
  } = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    perfilUnidade: ''
  }

  // lista de itens disponíveis para serem selecionados
  listItensDisponiveis: {
    unidades: ClientesEntity[],
    estados: {name: string, uf: string}[],
    galpao: GalpaoEntity[],
    tipoResiduo: TipoLixoEntity[],
  } = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: []
  }

  // lista de itens selecionados para alimentar os params da url
  listSelected: {
    unidades: any[],
    estados: any[],
    galpao: any[],
    tipoResiduo: any[],
    perfilUnidade: string,
  } = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    perfilUnidade: ''
  }

  public _dataFilter: filtersInterface = {
    unidades: [],
    estados: [],
    galpao: [],
    tipoResiduo: [],
    perfilUnidade: '',
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };

  public ajustePasteDate = (ev: any, varName: 'dataInicio' | 'dataFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)

  public maxDate = '';

  constructor(
    public _service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    let today = new Date();
    let dd: string | number = today.getDate();
    let mm: string | number = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    this.maxDate = yyyy + '-' + mm + '-' + dd;
  }

  dataManage = new SidebarDataManage(this)

  ngOnInit(): void {}

  setAutoComplete(data: CloseAutocomplete, identificador: 'galpao' | 'unidades' | 'estados' | 'tipoResiduo') {
    if(this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this.setQueryParams();
    } else {
      this.listSelected[identificador] = [...data.selectedList];
    }
  }

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  setQueryParams() {
    let params: any = {};

    if (this._dataFilter.unidades?.length > 0) {
      params['unidadeGeradora'] = JSON.stringify(this.listSelected.unidades);
    }

    if (this._dataFilter.estados?.length > 0) {
      params['estados'] = JSON.stringify(this.listSelected.estados);
    }

    if (this._dataFilter.galpao?.length > 0) {
      params['galpao'] = JSON.stringify(this.listSelected.galpao);
    }

    if (this._dataFilter.tipoResiduo?.length > 0) {
      params['tipoResiduo'] = JSON.stringify(this.listSelected.tipoResiduo);
    }

    if (!!this._dataFilter.perfilUnidade) {
      // @ts-ignore
      params['perfilUnidade'] = this._dataFilter.perfilUnidade.id || this._dataFilter.perfilUnidade;
    }

    if (!!this._dataFilter.ativo) {
      params['ativo'] = this._dataFilter.ativo;
    }

    if (!!this._dataFilter.dataInicio) {
      params['dataInicio'] = this._dataFilter.dataInicio;
    }

    if (!!this._dataFilter.dataFim) {
      params['dataFim'] = this._dataFilter.dataFim;
    }

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        ...params
      }
    }).then(() => {
      this.emitChange();
    })
  }

  @HostListener('window:setDataSidebar', ['$event'])
  getValues(data: any) {
    if (!!data.detail) {
      if (!!data.detail['prelistUnidades']) {
        this.listsPreSelected.unidades = data.detail['prelistUnidades']
        this.listSelected.unidades = [...this.listsPreSelected.unidades]
      }

      if (!!data.detail['prelistOperadores']) {
        this.listsPreSelected.galpao = data.detail['prelistOperadores']
        this.listSelected.galpao = [...this.listsPreSelected.galpao]
      }

      if (!!data.detail['prelistTipoResiduo']) {
        this.listsPreSelected.tipoResiduo = data.detail['prelistTipoResiduo']
        this.listSelected.tipoResiduo = [...this.listsPreSelected.tipoResiduo]
      }

      if (!!data.detail['prelistEstados']) {
        this.listsPreSelected.estados = data.detail['prelistEstados']
        this.listSelected.estados = [...this.listsPreSelected.estados]
      }

      this._dataFilter = data.detail;

      this.dataManage.getDataAutocompletes();
      this.setQueryParams();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['dataFilter']) {
      this._dataFilter = changes['dataFilter'].currentValue;
      // @ts-ignore
      this._dataFilter.unidades = this._dataFilter.unidades?.map((value: string) => Number(value));
      // @ts-ignore
      this._dataFilter.galpao = this._dataFilter.galpao?.map((value: string) => Number(value))
    }
  }

  emitChange() {
    this.sidebarChange.emit(this._dataFilter);

    this.verifyEmitVisible()
    const detail = this.dataManage.ajustDataByEventDispacher()
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('atualizar-pagina-filtrada', { detail }))
    }, 10)
  }

  verifyEmitVisible() {
    const result = !!this._dataFilter.dataInicio || !!this._dataFilter.dataFim || (this._dataFilter.estados || []).length > 0 ||
      (this._dataFilter.unidades || []).length > 0 ||(this._dataFilter.galpao || []).length > 0 || !!this._dataFilter.ativo ||
      (this._dataFilter.tipoResiduo || []).length > 0;

    this.visible.emit(result);
  }
}
