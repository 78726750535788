<div class="container-data">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title">Arquivos do destinador</h4>
        </div>
        <div class="card-body">
          <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
        </div>
        <div class="card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>

      </div>
    </div>
  </div>
</div>


<page-footer>
  <button class="btn btn-success" (click)="modalIndustriaArquivosComponent.open()">Adicionar</button>
</page-footer>


<modal-industria-arquivos
  #modalIndustriaArquivosComponent
  (afterSave)="table.find()"
></modal-industria-arquivos>
