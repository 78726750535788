<div id="page-start-session">
  <div class="container">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="container mt-2">
    <div>
      <p style="text-align: center; font-size: 18pt">
        {{textStatus}}
      </p>
    </div>
  </div>
</div>
