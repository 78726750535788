import { Injectable } from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from "@datagrupo/dg-crud";

@Injectable({
  providedIn: 'root'
})
export class ActionTableCupomService extends AbstractActionsTableService{

  constructor() {
    super();
  }
}
