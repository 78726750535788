import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CadastrePlanoEntity } from "../cadastre-plano.entity";
import { fotmataDinheiro } from "../../../../helpers/helpers";
import { clearEntity } from "@datagrupo/dg-crud";
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import Swal from 'sweetalert2';
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'plano-periodico',
  templateUrl: './plano-periodico.component.html',
  styleUrls: ['./plano-periodico.component.scss']
})
export class PlanoPeriodicoComponent implements OnInit {

  @Input() entity!: CadastrePlanoEntity;
  @Output('salvar') salvar = new EventEmitter<boolean>();
  @ViewChild('pickList') pickList!: PickListComponent;

  perfil: string | undefined

  public listaSelect: { key: any, value: any }[] = [
    { key: 'SINDICO', value: "Gestor" },
    { key: 'CONSELHO', value: "Conselho/Administrativo" },
    { key: 'MORADOR', value: "Usuário" },
    { key: 'PORTEIRO', value: "Operador" },
    { key: 'FINANCEIRO', value: "Financeiro" },
  ];

  listaSelecionados: any[] | undefined = [];

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    gratuidade: new FormControl('', [Validators.required]),
    ativo: new FormControl(false),
    pago: new FormControl(false),
    padrao: new FormControl(false),
    exclusivo: new FormControl(false),
    trimestral: new FormControl({ value: '', disabled: true }, [Validators.required]),
    semestral: new FormControl({ value: '', disabled: true }, [Validators.required]),
    anual: new FormControl({ value: '', disabled: true }, [Validators.required])
  });

  listItems: { value: any }[] = [];

  constructor(
    private session: SessionService
  ) {
    this.form.controls['pago'].valueChanges.subscribe((pago) => {
      this.changeDisabledValuesForms(!!pago ? 'enable' : 'disabled')
    });
    this.perfil = session.user.perfil

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputMoney(this.form, v, ['trimestral', 'semestral', 'anual'])
    )
  }

  ngOnInit(): void {
    const valoresPeriodos: { [key: string]: any } = {};

    const indexTrimestral = this.entity.periodicidadesPlano?.findIndex(
      periodo => periodo?.periodicidadePlano?.toUpperCase() == 'TRIMESTRAL'
    )
    const indexSemestral = this.entity.periodicidadesPlano?.findIndex(
      periodo => periodo?.periodicidadePlano?.toUpperCase() == 'SEMESTRAL'
    )
    const indexAnual = this.entity.periodicidadesPlano?.findIndex(
      periodo => periodo?.periodicidadePlano?.toUpperCase() == 'ANUAL'
    )

    if (indexTrimestral != -1) {
      // @ts-ignore
      valoresPeriodos['trimestral'] = this.entity.periodicidadesPlano[indexTrimestral].valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    if (indexSemestral != -1) {
      // @ts-ignore
      valoresPeriodos['semestral'] = this.entity.periodicidadesPlano[indexSemestral].valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    if (indexAnual != -1) {
      // @ts-ignore
      valoresPeriodos['anual'] = this.entity.periodicidadesPlano[indexAnual].valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    this.listItems = this.entity?.listItems?.map((value: string) => {
      return { value: value }
    });

    this.listaSelect.filter(value => {
      this.entity.acesso.forEach(item => {
        if (item != value.key) return

        this.listaSelecionados?.push({ key: value.key, value: (value.value[0].toUpperCase() + value.value.substring(1).toLowerCase()).replace('_', ' de ') })
      })
    })
    this.form.patchValue({ ...this.entity, ...valoresPeriodos });
  }

  @HostListener('window:salvar-plano', ['$event'])
  eventSave(ev: CustomEvent) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!!this.form.value.exclusivo && this.pickList.getList().length <= 0) {
      Swal.fire({
        title: 'Por favor escolha quem poderá contratar os planos',
        icon: 'error',
        timer: 3000,
        confirmButtonColor: 'green'
      })
      return;
    }

    let { trimestral, semestral, anual, ...form } = this.form.value;

    const indexTrimestral = this.entity.periodicidadesPlano?.findIndex(periodo => 'TRIMESTRAL' == periodo?.periodicidadePlano?.toUpperCase())
    const indexSemestral = this.entity.periodicidadesPlano?.findIndex(periodo => 'SEMESTRAL' == periodo?.periodicidadePlano?.toUpperCase())
    const indexAnual = this.entity.periodicidadesPlano?.findIndex(periodo => 'ANUAL' == periodo?.periodicidadePlano?.toUpperCase())

    trimestral = this.ajustaValores(trimestral)
    semestral = this.ajustaValores(semestral)
    anual = this.ajustaValores(anual)

    if (indexTrimestral != -1) {
      // @ts-ignore
      this.entity.periodicidadesPlano[indexTrimestral].valor = trimestral
    } else {
      this.entity.periodicidadesPlano?.push({ periodicidadePlano: 'TRIMESTRAL', valor: trimestral })
    }
    if (indexSemestral != -1) {
      // @ts-ignore
      this.entity.periodicidadesPlano[indexSemestral].valor = semestral
    } else {
      this.entity.periodicidadesPlano?.push({ periodicidadePlano: 'SEMESTRAL', valor: semestral })
    }
    if (indexAnual != -1) {
      // @ts-ignore
      this.entity.periodicidadesPlano[indexAnual].valor = anual
    } else {
      this.entity.periodicidadesPlano?.push({ periodicidadePlano: 'ANUAL', valor: anual })
    }
    this.entity['listItems'] = this.listItems.map(item => item.value);
    if (!!this.form.value.exclusivo) {
      this.entity['acesso'] = this.pickList.getList().map(item => item.key);
    } else {
      this.entity['acesso'] = [];
      this.atualiazaPickList();
    }

    this.entity.nome = form.nome;
    this.entity.gratuidade = form.gratuidade;
    this.entity.ativo = form.ativo;
    this.entity.pago = form.pago;
    this.entity.exclusivo = form.exclusivo
    if (this.perfil === "GALPAO") {
      this.entity.padrao = false;
    } else {
      this.entity.padrao = form.padrao;
    }

    this.salvar.emit(!!ev.detail['voltar']);
  }

  changeDisabledValuesForms(status: "enable" | 'disabled') {
    if (status == 'enable') {
      this.form.controls['trimestral'].reset('');
      this.form.controls['semestral'].reset('');
      this.form.controls['anual'].reset('');

      this.form.controls['trimestral'].enable();
      this.form.controls['semestral'].enable();
      this.form.controls['anual'].enable();
    } else {
      this.form.controls['trimestral'].disable();
      this.form.controls['semestral'].disable();
      this.form.controls['anual'].disable();
    }
  }

  public addItem() {
    this.listItems?.push({ value: '' })
  }

  public removerItem(index?: number) {
    if (index != undefined) {
      this.listItems.splice(index, 1)
    } else {
      this.listItems.splice(-1, 1)
    }
  }

  atualiazaPickList() {
    this.listaSelecionados = this.entity.acesso.map(item => {
      return { key: item, value: (item[0].toUpperCase() + item.substring(1).toLowerCase()).replace('_', ' de ') }
    })
  }

  ajustaValores(val: number | string): string {
    if (String(val).includes(',') || String(val).includes('.')) {
      //@ts-ignore
      val = String(val).replaceAll('.', '')
      val = String(val).replace(',', '.')
    } else {
      if (String(val).length > 2) {
        val = String(val).slice(0, String(val).length - 2) + '.' + String(val).slice(String(val).length - 2)
      } else {
        val = val + '.' + '00'
      }
    }

    return String(val)
  }

}
