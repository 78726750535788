<div
  class="card dg-card-item {{size}} {{classes}}" style="width: 100%;"
  [routerLink]="disable ? null : href"
  [class.isLink]="href"
  [class.disabled]="disable"
  [fragment]="fragment"
>
  <mat-icon *ngIf="!!icon">{{icon}}</mat-icon>
  <p class="text" *ngIf="!!text">{{ text }}</p>
  <p class="subText" *ngIf="!!subText">{{ subText }}</p>
</div>
