<div
    id="clientes-funcionarios-main"
    class="container"
>
    <div class="row">
        <div class="col-12">
            <card>
                <div class="card-header">
                    <h4 class="card-title">Cobranças</h4>
                </div>
                <div class="card-subtitle">
                    <filter-search
                        [entityMap]="mapEntity"
                        [table]="tableCobranca"
                        tableEventId="table"
                    ></filter-search>
                </div>
                <div
                    class="card-body"
                    style="padding: 0"
                >
                    <crud-data-table
                        #tableCobranca
                        [entityMap]="mapEntity"
                        [dictionary]="{ cliente: 'nomeCliente' }"
                        tableEventId="table"
                        [register]="true"
                    ></crud-data-table>
                </div>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination
                        [dataPagination]="mapEntity.configEntityTable.pagination"
                        (changePage)="tableCobranca.changePages($event)"
                    ></dg-pagination>
                </div>
            </card>
        </div>
    </div>
</div>


<swal
    #swallEstenderPesquisa
    titleText="Alterar data de vencimento"
    [showConfirmButton]="false"
    [allowOutsideClick]="false"
    (didClose)="afterClose()"
>
    <ng-container *swalPortal="swalTargets.content">
        <form [formGroup]="form">
            <label class="form-label f-bold">
                Adicione uma data de vencimento que seja posterior cinco dias do dia atual
            </label>
            <input
                type="date"
                (paste)="ajustePasteDate($event)"
                formControlName="vencimento"
                min="{{minimoDataAcrescimo}}"
                class="form-control"
            >
        </form>
    </ng-container>
    <ng-container *swalPortal="swalTargets.footer">
        <button
            (click)="swallEstenderPesquisa.close()"
            class="btn dg-btn btn-secondary m-1"
        >
            Fechar
        </button>
        <button
            (click)="salvaNovoPrazo()"
            class="btn dg-btn btn-success m-1"
        >
            Alterar
        </button>
    </ng-container>
</swal>
