import { Component, OnInit } from '@angular/core';
import { GrupoComunicacaoEntity } from '../grupo-comunicacao.entity';
import { GrupoComunicacaoService } from '../service/grupo-comunicacao.service';

@Component({
  selector: 'app-grupo-comunicacao-main',
  templateUrl: './grupo-comunicacao-main.component.html',
  styleUrls: ['./grupo-comunicacao-main.component.scss']
})
export class GrupoComunicacaoMainComponent implements OnInit {

  public mapEntity = new GrupoComunicacaoEntity();

  constructor(
    public service: GrupoComunicacaoService
  ) { }

  ngOnInit(): void {
  }

}
