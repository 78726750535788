import { ConfigDgCrudService } from '../../../../core/config/config-dg-crud/config-dg-crud.service';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { ProdutoEntity } from '../produto.entity';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService  extends AbstractHttpService<ProdutoEntity>{

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, 'produtos')
  }
}
