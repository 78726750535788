import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteLabelEditComponent } from './white-label-edit/white-label-edit.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserLayoutModule} from "../../../layouts/user/user-layout.module";
import { FragmentSideMenuComponent } from './sub-components/fragment-side-menu/fragment-side-menu.component';
import {DgAutocompleteModule} from "@datagrupo/dg-ng-util";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ModalReplicarComponent } from './sub-components/modal-replicar/modal-replicar.component';



@NgModule({
  declarations: [
    WhiteLabelEditComponent,
    FragmentSideMenuComponent,
    ModalReplicarComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        FormsModule,
        UserLayoutModule,
        ReactiveFormsModule,
        DgAutocompleteModule,
        MatProgressSpinnerModule
    ]
})
export class WhiteLabelModule { }
