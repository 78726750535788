import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {OldModalComponent} from "../../../../shared/ui/modal/modal/modal.component";
import {ProdutoService} from "../../../_user/produto/service/produto.service";
import {ProdutoEntity} from "../../../_user/produto/produto.entity";

@Component({
  selector: 'app-public-credito-logistica-reversa',
  templateUrl: './public-credito-logistica-reversa.component.html',
  styleUrls: ['./public-credito-logistica-reversa.component.scss']
})
export class PublicCreditoLogisticaReversaComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;

  estados: any[] = [];

  public form = new FormGroup(({
    empresa: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    contato: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  }))

  public formModal = new FormGroup({
    produto: new FormControl('', [Validators.required]),
    percentual: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    anoReferente: new FormControl('', [Validators.required]),
    uf: new FormControl('', [Validators.required]),
  })

  public listProdSelected: {
    produto: ProdutoEntity,
    quantidade: string,
    percentual: string
    uf: string
    anoReferente: string
  }[] = [];

  public listProdutos: ProdutoEntity[] = [];

  constructor(private http: HttpOldService, private produtosService: ProdutoService) {
    http.get('missao/localizacoes').subscribe(
      (resp: any) => {
        this.estados = (resp.data || []).map((estado: any) => estado.estado)
      }
    )
    produtosService.findAll().subscribe(
      resp => {
        //  @ts-ignore
        this.listProdutos = resp.data;
      }
    )
  }

  ngOnInit(): void {
  }

  pedirOrcamento() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;

    alert('pedir');
  }

  saveModal() {
    if (this.formModal.invalid) {
      this.formModal.markAllAsTouched();
      return;
    }

    const form = this.formModal.value;
    const indexProd = this.listProdutos.findIndex(prod => prod.id == form.produto);
    const indexProdSelected = this.listProdSelected.findIndex(prod => prod.produto.id == form.produto);

    if (indexProd == -1) {
      return;
    }

    if (indexProdSelected == -1) {
      this.listProdSelected.push({
        produto: this.listProdutos[indexProd],
        percentual: form.percentual,
        quantidade: form.quantidade,
        uf: form.uf,
        anoReferente: form.anoReferente
      });
    } else {
      this.listProdSelected.splice(indexProdSelected,1, {
        produto: this.listProdutos[indexProd],
        percentual: form.percentual,
        quantidade: form.quantidade,
        uf: form.uf,
        anoReferente: form.anoReferente
      });
    }


    this.modal.close();
  }

  editarProd(index: number){
    this.formModal.patchValue({
      ...this.listProdSelected[index],
      produto: this.listProdSelected[index].produto.id
    })

    this.modal.open();
  }

  removeProd(index: number){
    if (!this.listProdSelected[index]) {
      return;
    }

    this.listProdSelected.splice(index, 1)
  }

  public close = () => {
    this.formModal.reset('')
  }

  ajustaPercentual(value: string): string {
    switch (value) {
      case '22':
        return '22%';
      case '23-50':
        return '23% a 50%'
      case '51-100':
        return '50% a 100%'
      case '1001-200':
        return '101% a 200%'
      default:
        return  '--';
    }
  }

  checkNumber = (e: any) => {
    if (e.target.type === "number" && e.key.match(/^[a-z]+$/)) {
      e.preventDefault();
    }
  };
}
