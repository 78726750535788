<div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Histórico de Cobranças</h4>
          </div>
          <div class="card-body">
            <div class="crud-table">
              <table class="crud-table">
                <tr>
                  <th>VENCIMENTO DA COBRANCA</th>
                  <th>DATA DO PAGAMENTO</th>
                  <th>STATUS</th>
                  <th>VALOR</th>
                </tr>
                <tbody>
                  <tr *ngIf="historicos?.length <= 0">
                    <td style="text-align: center" colspan="4">Nenhum registro encontrado</td>
                  </tr>
                  <tr *ngFor="let historico of historicos">
                    <td>{{ historico.vencimento }}</td>
                    <td>{{ historico.pagamento || '--' }}</td>
                    <td>{{ historico?.status == "PAID" ? 'PAGO' : 'EM ABERTO' || '--' }}</td>
                    <td>{{ historico.valor }}</td>
                  </tr>
                </tbody>
              </table>
  
            </div>
          </div>
          <div class="card-footer">
            <dg-pagination (changePage)="changePage($event)" [dataPagination]="pagination"></dg-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>