import { GalpaoEntity } from '../../galpao.entity';
import { Injectable } from '@angular/core';
import {ConfigDgCrudService} from "../../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {environment} from "../../../../../../../environments/environment";
import {GALPAO} from "../../../../../../core/config/endpoint-list";


@Injectable({
  providedIn: 'root'
})
export class GalpaoService extends AbstractHttpService<GalpaoEntity>{

  constructor(
    public conf: ConfigDgCrudService,
  ) {
    super(conf, environment.apiUrl, GALPAO)
  }

}
