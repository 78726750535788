import {
  AbstractEntity,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn
} from "@datagrupo/dg-crud";
import { PRODUTOS } from "src/app/core/config/endpoint-list";
import { AutoCompletePedidoCLRProdutosPut } from "src/app/helpers/auto-complete/pedido-clr-autocomplete";
import { AutoCompleteProdutosUotPut } from "src/app/helpers/auto-complete/produtos-autocomplete.helper";
import { formata_data_utc_br } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";
import { ProdutoEntity } from "../produto/produto.entity";
import { ClientesEntity } from "../_clientes/clientes/clientes.entity";

export class PedidoCreditoLogisticaReversaEntity extends AbstractEntity {
  constructor(
    id?: number,
    dataInclusao?: string,
    dataCompensacao?: string,
    clienteCredito?: ClientesEntity,
    porcentagemEmbalagensMercado?: any,
    produto?: ProdutoEntity,
    qtdProdutoGerado?: number,
    valorAnualDaCobrancaDaLogistica?: number,
    valorMensalDaCobrancaDaLogistica?: number,
    porcentagemEmbalagens?: number,
    estado?: string
  ) {
    super();
    this.id = id;
    this.dataInclusao = dataInclusao;
    this.dataCompensacao = dataCompensacao;
    this.clienteCredito = clienteCredito;
    this.porcentagemEmbalagensMercado = porcentagemEmbalagensMercado;
    this.produto = produto;
    this.qtdProdutoGerado = qtdProdutoGerado;
    this.valorAnualDaCobrancaDaLogistica = valorAnualDaCobrancaDaLogistica;
    this.valorMensalDaCobrancaDaLogistica = valorMensalDaCobrancaDaLogistica;
    this.porcentagemEmbalagens = porcentagemEmbalagens;
    this.estado = estado;
  }

  @DgFilter_autoComplete({}, {
    getUrl: environment.apiUrl + 'cliente-credito-logistica-reversa/nome',
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'clienteId',
    nameParamFilter: 'nome',
    outputFormat: AutoCompletePedidoCLRProdutosPut
  })
  @DgTableColumn({
    columnName: "CLIENTE", resource: (val: ClientesEntity) => {
      return !!val ? val.nome : '-'
    }
  })
  public clienteCredito: ClientesEntity | undefined;

  @DgFilter_autoComplete({}, {
    getUrl: environment.apiUrl + 'produtos/nomes',
    getServerData: "mount",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'produtoId',
    nameParamFilter: 'nome',
    outputFormat: AutoCompleteProdutosUotPut
  })
  @DgTableColumn({
    columnName: "TIPO DE MATERIAL COMPENSADO", resource: (val: ProdutoEntity) => {
      return !!val ? val.nome : '-'
    }, sort: true
  })
  public produto: ProdutoEntity | undefined;

  @DgFilter_input('date')
  @DgTableColumn({
    columnName: "DATA DE INCLUSÃO", resource: (data: string) => {
      return !!data ? formata_data_utc_br(data) : '--'
    },
    sort: true
  })
  public dataInclusao: string | undefined;

  public dataCompensacao: string | undefined;

  @DgTableColumn({
    columnName: "QUANTIDADE DO PRODUTO GERADA POR ANO (TON)", resource: (val: string) => {
      return !!val ? Number(val).toLocaleString('pt-BR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      }) : '--';
    }, sort: true
  })
  public qtdProdutoGerado: number | string | undefined;


  public porcentagemEmbalagensMercado: string | undefined;

  @DgTableColumn({
    columnName: "% DAS EMBALAGENS QUE O CLIENTE COLOCA NO MERCADO", resource: (val: number) => {
      return !!val ? val : '--'
    },
    sort: true
  })
  public porcentagemEmbalagens: number | undefined;

  public valorAnualDaCobrancaDaLogistica: number | undefined;

  public valorMensalDaCobrancaDaLogistica: number | undefined;

  public estado: string | undefined

}
