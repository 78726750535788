<!-- LINHA DE SELEÇÃO DE FILTROS -->
<div class="container-fluid" *ngIf="verifyShow.showPages && !controlles.loaders.showLoader">
  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <button
        class="btn btn-outline dashboard-buttons"
        type="button"
        (click)="canvas.toggleOffcanvas()"
      >
        <mat-icon>search</mat-icon>
        Filtros
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr class="fc-grey">
    </div>
  </div>
</div>
<!-- CONTAINER DE SELEÇÃO DE FILTROS -->
<div class="container container-amostra" *ngIf="!verifyShow.showPages && !controlles.loaders.showLoader">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div style="text-align: center">
        <h4 class="card-title">Selecione os filtros</h4>
        <p class="card-subtitle">Filtros insuficientes para gerar dados</p>
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          (click)="canvas.toggleOffcanvas()"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="verifyShow.showPages">
  <div class="row d-flex align-items-stretch">
    <div
      *ngIf="controlles.loaders.getItemLoader('estoque').finish && !controlles.loaders.getItemLoader('estoque').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Estoque</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['estoqueRosca'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['estoqueRosca'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['estoqueRosca'] = 'table'"
                    [class.active]="verifyShow.viewGraph['estoqueRosca'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card-body" [hidden]="verifyShow.viewGraph['estoqueRosca'] != 'graph'">
          <div id="id-chart-estoque-barra"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['estoqueRosca'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
             <th>Nome</th>
              <th>Percentual</th>
              <th>Peso em (ton)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.estoqueRosca || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.percentage}}</td>
              <td>{{item?.y |json}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      *ngIf="controlles.loaders.getItemLoader('estoque').finish && !controlles.loaders.getItemLoader('estoque').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Estoque</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['estoqueBarra'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['estoqueBarra'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['estoqueBarra'] = 'table'"
                    [class.active]="verifyShow.viewGraph['estoqueBarra'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['estoqueBarra'] != 'graph'">
          <div id="id-chart-estoque-rosca"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['estoqueBarra'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Estoque (ton)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.estoqueBarra || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.estoque}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      *ngIf="controlles.loaders.getItemLoader('comparativo').finish && !controlles.loaders.getItemLoader('comparativo').error"
      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-1 mb-1"
    >
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <h4>Taxa de reciclagem</h4>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['comparativo'] = 'graph'"
                    [class.active]="verifyShow.viewGraph['comparativo'] == 'graph'"
                  >
                    Gráfico
                  </h4>
                </li>
                <li class="nav-item">
                  <h4
                    class="nav-link card-title"
                    (click)="verifyShow.viewGraph['comparativo'] = 'table'"
                    [class.active]="verifyShow.viewGraph['comparativo'] == 'table'"
                  >
                    Tabela de dados
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['comparativo'] != 'graph'">
          <div id="id-chart-estoque-comparativo"></div>
        </div>
        <div class="card-body" [hidden]="verifyShow.viewGraph['comparativo'] != 'table'">
          <table class="dg-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Total operador</th>
              <th>Total estado</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of (dataDashboard?.dataGraphs?.comparativo || [])">
              <td>{{item?.name}}</td>
              <td>{{item?.seriesData?.totalOperador || 0}}</td>
              <td>{{item?.seriesData?.totalEstados || 0}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<canvas-side-filter
  #canvas
  (clear)="clearFilters()"
  (closed)="initLoaderData()"
  (uploadeFavoritoList)="$event == 'estoque' ? loaderFavoritos() : null"
>
  <form [formGroup]="formFilter">
    <div class="row">
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('favoritos')">
        <label class="form-label form-label-sm">Favoritos</label>
        <dg-autocomplete3
          #favoritos
          key="id" label="nome"
          classes="form-control" formControlName="favoritos" [data]="controlles.filtersOptions['favoritos']"
          dg-dynamic-group="estoque" paramName="favoritos" typeComunication="change" (change)="propagarFavorito()"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('transportadores')">
        <label class="form-label form-label-sm">Operadores</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true"
          classes="form-control" formControlName="operadores" [data]="controlles.filtersOptions['operadores']"
          dg-dynamic-group="estoque" paramName="operadores" typeComunication="change" (change)="changeFilters('transportadores')"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataInicio')">
        <label class="form-label form-label-sm">Data Início</label>
        <input type="date" formControlName="dataInicio" class="form-control form-control-sm">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('dataFim')">
        <label class="form-label form-label-sm">Data Fim</label>
        <input type="date" formControlName="dataFim" class="form-control form-control-sm">
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('tipoResiduos')">
        <label class="form-label form-label-sm">Tipos de Resíduo</label>
        <dg-autocomplete3
          key="id" label="nome" [multiple]="true" (change)="changeFilters('tipoResiduos')"
          classes="form-control" formControlName="residuos" [data]="controlles.filtersOptions['tipoResiduos']"
        ></dg-autocomplete3>
      </div>
      <div class="col-12" *ngIf="controlles.permissionFilters.includes('estados')">
        <label class="form-label form-label-sm">Estados</label>
        <dg-autocomplete3
          key="uf" label="name" [multiple]="true"
          classes="form-control" formControlName="estados" [data]="controlles.filtersOptions['estados']"
          dg-dynamic-group="estoque" paramName="estados" typeComunication="change" (change)="changeFilters('estados')"
        ></dg-autocomplete3>
      </div>
    </div>
  </form>
</canvas-side-filter>
