<div class="container" *ngIf="entity">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Usuários dos Geradores</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="nome" class="form-label">NOME <span class="fc-red">*</span></label>
                <input type="text" formControlName="nome" (focusout)="createLogin()" class="form-control" id="nome">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="sobrenome" class="form-label">SOBRENOME <span class="fc-red">*</span></label>
                <input type="text" formControlName="sobrenome" (focusout)="createLogin()" class="form-control" id="sobrenome">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">CPF</label>
                <input type="text" class="form-control"
                         mask="000.000.000-00" formControlName="cpf">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="login" class="form-label">LOGIN <span class="fc-red">*</span></label>
                <input type="text" formControlName="login" class="form-control" id="login">
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="email" class="form-label">EMAIL <span class="fc-red">*</span></label>
                <input type="text" formControlName="email" class="form-control" id="email">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="telefone" class="form-label">TELEFONE (DDD)<span class="fc-red">*</span></label>
                <input type="text" formControlName="telefone" class="form-control" id="telefone" mask="(00) 0000-0000||(00) 00000-0000">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label for="tipoUsuario" class="form-label">TIPO DE USUÁRIO <span class="fc-red">*</span></label>
                <select class="form-select" formControlName="tipoUsuario" id="tipoUsuario">
                  <option disabled>Selecionar</option>
                  <option *ngFor="let tipoUser of tiposUsuarios" value="{{tipoUser.key}}">{{tipoUser.value}}</option>
                </select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])">
                <label class="form-label">GERADOR <span class="fc-red">*</span></label>
                <input-auto-complete
                  [baseValue]="entity?.cliente"
                  property="id"
                  propertValue="nome"
                  controlName="cliente"
                  urlServer="clientes/nomes"
                  [service]="clientesServic"
                  (outValue)="this.entity.cliente = $event"
                ></input-auto-complete>
              </div>
              <div class="col-xl-3 col-lg-3 com-md-6 col-sm-12">
                <label for="nascimento" class="form-label">Data nascimento<span class="fc-red">*</span></label>
                <input type="date" (change)="verifyDate($event)" (paste)="ajustePasteDate($event)" formControlName="nascimento" class="form-control" id="nascimento">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <label class="form-label">Complemento</label>
                <input
                  class="form-control"
                  formControlName="complemento"
                  placeholder="Ex. casa 1, apto 203"
                >
              </div>
              <div
              class="col-xl-6 col-lg-6 col-md-8 col-sm-8"
              style="padding-top: 2.1em;"
            >
              <input
                type="checkbox"
                class="form-check-input"
                name="contato"
                id="contato"
                formControlName="contato"
              >
              <label
                class="form-label ms-2"
                for="contato"
              >
                 FUNCIONÁRIO DE CONTATO DO GERADOR
              </label>
            </div>
            <div *ngIf="!entity?.id" class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <br>
              <label
                class="conteudo"
                [class.row-invalid]="form.controls['receberEmailsInformativos'].invalid && form.controls['receberEmailsInformativos'].touched"
              >
                Você aceita receber conteúdo sobre práticas sustentáveis e dicas para melhorar sua gestão de resíduos?
              </label>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <input class="ml"  type="radio" name="receberEmailsInformativos" id="sim" formControlName="receberEmailsInformativos"
                      value=true />
                    <label for="sim">Sim</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <input class="ml" type="radio" name="receberEmailsInformativos" id="nao" formControlName="receberEmailsInformativos"
                      value="false" />
                    <label for="nao">Não</label>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>
</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
  <button crud-salvar class="btn btn-success">Salvar e Voltar</button>
  <button crud-deletar class="btn btn-secondary">Excluir</button>
</page-footer>
