<div
  id="tipo-lixo-main"
  class="container"
>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Tipo de Resíduo</h4>
        </div>
        <div class="card-subtitle">
          <filter-search
            [entityMap]="mapEntity"
            [table]="tableTipoLixo"
            tableEventId="table"
          ></filter-search>
        </div>
        <div
          class="card-body"
          style="padding: 0"
        >
          <crud-data-table
            #tableTipoLixo
            [entityMap]="mapEntity"
            tableEventId="table"
            [register]="true"
          >
          </crud-data-table>
        </div>
        <div class="card-footer">
          <dg-pagination
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="tableTipoLixo.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
</page-footer>
