import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {TokenService} from "../../services/token-service/token.service";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {SessionService} from "../../services/session-service/session.service";

@Injectable()
export class AddTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private tokenService: TokenService, private session: SessionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const token = this.tokenService.getToken();

    const urlPesquisaAnonima = window.location.pathname.split('/');
    urlPesquisaAnonima.pop();

    if (this.exceptUrl('/login')) {
      if (token != null) {
        request = request.clone({
          setHeaders: {'Authorization': 'Bearer ' + token}
        });
      }
    }

    return next.handle(request).pipe(catchError( error => {

      if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
        if (!this.tokenService.checkExp()) {
          return this.handle401Error(authReq, next);
        }
      }

      return throwError(error);
    }));

    // return next.handle(request);
  }

  exceptUrl(exceptUrl: string) {
    const currentUrl = window.location.pathname.split('/');
    // currentUrl.pop();

    return currentUrl.join('/') !== exceptUrl;
  }

  private static addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      // iniciando processo de renovação de token
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // buscando token atual
      const token = this.tokenService.getToken();

      // renovar token somente caso o mesmo existe
      if (token)
        return this.tokenService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.tokenService.saveToken(token.data.accessToken);

            this.refreshTokenSubject.next(token.data.accessToken);

            return next.handle(AddTokenInterceptor.addTokenHeader(request, token.data.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.session.logout();
            return throwError(err);
          })
        );
    }

    // após renovação do token, é retornado o requeste para uma nova tentativa
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(AddTokenInterceptor.addTokenHeader(request, token)))
    );
  }
}
