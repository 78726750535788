import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultAutocompleteInputComponent } from './default-autocomplete-input/default-autocomplete-input.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    DefaultAutocompleteInputComponent
  ],
  exports: [
    DefaultAutocompleteInputComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule
    ]
})
export class CustomAutocompleteModule { }
