import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import { InterIndicadores } from '../../cadastro-indicadores-ods.entity';

@Component({
  selector: 'modal-indicador-meta-ods',
  templateUrl: './modal-indicador-meta-ods.component.html',
  styleUrls: ['./modal-indicador-meta-ods.component.scss']
})
export class ModalIndicadorMetaOdsComponent implements OnInit {

  public index: number | undefined;
  public indexInd: number | undefined;

  @ViewChild('modal') modal!: OldModalComponent;
  @Output('salvar') save = new EventEmitter< {index: number, data: InterIndicadores, indexInd: number | undefined }>()

  public form = new FormGroup({
    nomeIndicador: new FormControl('', [Validators.required]),
    descricaoIndicador: new FormControl('', [Validators.required])
  })

  constructor() { }

  ngOnInit(): void {
  }

  public open(index: number, indexInd?: number, ind?: InterIndicadores) {
    if(indexInd != undefined && !!ind){
      this.form.patchValue({...ind})
      this.indexInd = indexInd
    }
    this.index = index
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  afterClose = () => {
    this.index = undefined
    this.indexInd = undefined
    this.form.reset();
  }

  salvar() {
    if (this.form.invalid || this.index == undefined) {
      this.form.markAllAsTouched();
      return
    }

    this.save.emit({data: this.form.value, index: this.index, indexInd: this.indexInd })
    this.modal.close()
  }

}


