<card>
<!--  <div class="card-header">-->
<!--    <div class="col-12" style="text-align: center;">-->
<!--      <h4 class="card-title">Anos</h4>-->
<!--    </div>-->
<!--  </div>-->
  <div class="card-body">
    <div *ngIf="!dataGraphic" class="h-100 w-100 d-flex justify-content-center align-items-center">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!!dataGraphic" [id]="idChard" class="graph-card">
    </div>
  </div>
</card>
