<div id="esqueci-senha">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                <card>
                    <div class="card-header">
                        <h4 class="card-title">Esqueci minha senha</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <label class="fomr-label">Login</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    [formControl]="login"
                                    placeholder="Ex: login.login"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button
                            class="btn btn-success"
                            (click)="enviar()"
                        >
                            Enviar
                        </button>
                    </div>
                </card>
            </div>
        </div>
    </div>
</div>
