import { Component, OnInit } from '@angular/core';
import {ArquivoEntity} from "../../../../shared/entitys/arquivo.entity";
import {ThemeManageService} from "../../../../services/theme-manage/theme-manage.service";

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss']
})
export class SidebarLogoComponent implements OnInit {

  logoUrl: string = 'assets/img/logo/logo-minha-coleta-OLD.png'

  constructor(public themeService: ThemeManageService) {
    // let imagem: any = window.localStorage.getItem('themeLogo');
    //
    // if (!!imagem) {
    //   imagem = <ArquivoEntity>JSON.parse(imagem)
    //   if (!!imagem) {
    //     if (!!imagem?.file) {
    //       this.logoUrl = 'data:image/' + imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1] + ';base64,' + imagem.file
    //     }
    //   }
    // }
    this.logoUrl = themeService.getLogo();
  }

  ngOnInit(): void {
  }

  getImageToSrc(imagem: ArquivoEntity | undefined): string {
    if (!!imagem) {
      if (!!imagem?.file) {
        return 'data:image/' + imagem.fileNameOriginal.split('.')[imagem.fileNameOriginal.split('.').length - 1] + ';base64,' + imagem.file
      }
    }
    return 'assets/img/logo/logo-minha-coleta-OLD.png'
  }

}
