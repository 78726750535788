import { ClientesComponent } from './../vendas-logistica-reversa/subComponentes/clientes/clientes.component';
import { ClientesEntity } from '../_clientes/clientes/clientes.entity';
import { AbstractEntity, DgTableColumn } from '@datagrupo/dg-crud';
import { ParceiroEntity } from '../parceiro/parceiro.entity';
import {Enderecos} from "../../../shared/entitys/enderecos";

export class MeuPerfilEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    nome?: string,
    sobrenome?: string,
    email?: string | number,
    login?: string | number,
    cpf?: string | number,
    telefone?: string | number,
    tipoUsuario?: string,
    nascimento?: string,
    // cliente?: string,
    // meuPefilCliente?: {
    //   qtdQuartosPorApartamento: string[],
    //   enderecos: Enderecos[],
    //   nome: string
    // }
    ) {

    super();
    this.id = id;
    this.nome = nome;
    this.sobrenome = sobrenome;
    this.email = email;
    this.login = login;
    this.cpf = cpf;
    this.telefone = telefone;
    this.tipoUsuario = tipoUsuario;
    this.nascimento = nascimento;
    // this.cliente = cliente;
    // this.meuPefilCliente = meuPefilCliente;

  }

  @DgTableColumn({ columnName: 'ARQUIVO', sort: true })
  public nome: string | undefined;

  @DgTableColumn({ columnName: '	DATA PAGAMENTO' })
  public dataPagamento: string | undefined;

  @DgTableColumn({ columnName: 'PAGO', sort: true })
  public pago: string | undefined;

  @DgTableColumn({ columnName: 'DOWNLOAD' })
  public parceiro: ParceiroEntity | undefined;


  public sobrenome: string | undefined;
  public email: string | number | undefined;
  public login: string | number | undefined;
  public cpf: string | number | undefined;
  public telefone: string | number | undefined;
  public tipoUsuario: string  | undefined;
  public nascimento: string  | undefined;
  public clienteId: number | string  | undefined;
  // public cliente: string  | undefined;
  // public meuPefilCliente: {
  //   qtdQuartosPorApartamento: string[],
  //   enderecos: Enderecos[],
  //   nome: string
  // } | undefined
  public nomeCond: string | undefined;
  public cargo: string | undefined;
 // public cnpj: {qtdQuartosPorApartamento: string[]} | undefined

}


