import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-card-graph',
  templateUrl: './card-graph.component.html',
  styleUrls: ['./card-graph.component.scss']
})
export class CardGraphComponent implements OnInit, AfterViewInit {

  @Input() dataGraphic: any;

  @Input() idChard: string = 'chart-pie';

  constructor() {}

  ngAfterViewInit(): void {
    if (!!this.dataGraphic) {
      if (window.document.getElementById(this.idChard)) {
        setTimeout(() => {
          Highcharts.chart(this.idChard, this.ajustaData(this.dataGraphic));
        }, 100)
      }
    }
  }

  ngOnInit(): void {
  }

  ajustaData(d: any): object {
    let data = JSON.stringify(d);

    return JSON.parse(data, function (key, value) {
      if (
        typeof value === "string" &&
        value.startsWith("REMOVE_QUOTE") &&
        value.endsWith("REMOVE_QUOTE")
      ) {
        value = value.replace(/REMOVE_QUOTE/g, '')
        return (0, eval)("(" + value + ")");
      }
      return value;
    });
  }
}
