<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">filter-search2</h1>
</header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Atenção</h4>
        <p>
          Existe um componente <b>filter-search</b> que está sendo utilizado em boa parte do sistema e deve ser
          descontinuado.
          <br>
          Esse componente servida a um componente de tabela <b>(crud-data-table)</b> também descontinuado.
          <br>
          O correto é usar o componente <b>filter-search2</b>
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Sobre esse componente</h4>
        <p>
          Esse componente serve apenas como layout base para que seja postos os filtros. O mecanismo de filtro em si
          não está incluso nesse componente. Isso será feito pelo <b>DgDynamicGroup</b> que deve ser importado no seu módulo.
          Ele será responsável por coletar os dados e realizar o disparo quando clicado no button "filtrar"
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h4 class="alert-heading">Início rápido</h4>
      <p>
        Importe o módulo <b>CdkDynamicGroupModule</b>. Em <b>dynamicGroupName</b> informe o nome do grupo que deseja
        se conectar. Lembrando que esse sempre deve ser igual ao informado em <b>dg-dynamic-group</b> no input. Use o
        <b>paramName</b> para informar qual o nome será usado para salvar essa informação
      </p>
      <pre><textarea class="w-100" rows="20">
<filter-search2 dynamicGroupName="cliente-relacao-contrato">
  <div class="row">
    <div class="col-3">
      <label class="form-label">Status</label>
      <select
        class="form-select"
        [ngModel]="filterInputs.relacaoContrato.isAtivo"
        dg-dynamic-group="cliente-relacao-contrato"
        paramName="isAtivo"
      >
        <option [value]="true">Ativos</option>
        <option [value]="false">Inativos</option>
        <option value="">Ambos</option>
      </select>
    </div>
  </div>
</filter-search2>
</textarea></pre>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h4 class="alert-heading">Conexão automática com DynamicTable</h4>
      <p>
        Ao utilizar a <b>DynamicTable</b> para gerenciar as tabelas, apenas informe o mesmo nome no <b>dynamicGroupName</b>
        que informou no <b>filter->group</b>
      </p>
    </div>
  </div>
</div>
