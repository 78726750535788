import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { environment } from 'src/environments/environment';
import { CategoriaNegocioEntity } from '../categoria-negocio.entity';

@Injectable({
  providedIn: 'root'
})
export class CategoriaNegocioService  extends AbstractHttpService<CategoriaNegocioEntity> {

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'categoriasnegocio')
   }
}
