import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { ClienteCreditoLogisticaReversaEntity } from '../cliente-credito-logistica-reversa.entity';
import { PedidoCreditoLogisticaReversaEntity } from '../pedido-credito-logistica-revers.entity';
import { PedidoCreditoLogisticaReversaService } from '../service/pedido-credito-logistica-reversa.service';

@Component({
  selector: 'app-pedido-credito-logistica-reversa-main',
  templateUrl: './pedido-credito-logistica-reversa-main.component.html',
  styleUrls: ['./pedido-credito-logistica-reversa-main.component.scss']
})
export class PedidoCreditoLogisticaReversaMainComponent implements OnInit {

  public mapEntity = new PedidoCreditoLogisticaReversaEntity();
  
  constructor(
    public service: PedidoCreditoLogisticaReversaService,
    public session: SessionService
    ) 
    {}

  ngOnInit(): void {
  }

  resourceDictionary = (data: any) => {
    return data.clienteCreditoLogisticaReversaProduto
  }
}
