import {Injectable} from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from "@datagrupo/dg-crud";
import {CobrancasEntity} from "../../cobrancas.entity";
import Swal from "sweetalert2";
import {CobrancasService} from "../cobrancas-service/cobrancas.service";

@Injectable({
  providedIn: 'root'
})
export class CobrancaActionsTableService extends AbstractActionsTableService {

  constructor(private service: CobrancasService) {
    super()
  }

  override list(): InterfaceCustomActions[] {
    return [
      {
        name: 'Pagar',
        action: (cob: CobrancasEntity) => this.pagar(cob),
        permission: (cob: CobrancasEntity) => {
          return cob?.status != 'PAID';
        }
      },
      {
        name: 'Alterar data de vencimento',
        action: (cob: CobrancasEntity) => this.alterarVencimento(cob),
        permission: (cob: CobrancasEntity) => {
          return cob?.status != 'PAID';
        }
      },
      {
        name: 'Enviar cobrança',
        action: (cob: CobrancasEntity) => this.enviarCobranca(cob),
        permission: (cob: CobrancasEntity) => {
          return cob?.status != 'PAID';
        }
      },
    ];
  }

  pagar(cob: CobrancasEntity) {
    if (!cob?.id) {
      throw 'id da cobrança não encontrado';
    }

    Swal.fire({
      icon: 'question',
      title: "Você deseja alterar o status da cobrança para pago?",
      showCancelButton: true,
      confirmButtonText: "Pagar",
      cancelButtonText: "Sair"
    }).then((auth) => {
      if (auth.isConfirmed) {
        //@ts-ignore
        this.service.pagar('PAID', cob.id).subscribe(
          () => {
            window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'CobrancasEntity'}));
            Swal.fire({
              icon: "success",
              title: "Cobrança paga"
            })
          }
        )
      }
    })
  }

  alterarVencimento(cob: CobrancasEntity) {
    if (!cob?.id) {
      throw 'id da cobrança não encontrado';
    }

    window.dispatchEvent(new CustomEvent('altera-data-pagamento-cobranca', { detail: cob }))

    // let flatpickrInstance: any

    // Swal.fire({
    //   title: 'Please enter departure date',
    //   html: '<input class="swal2-input" id="expiry-date" mask="00/00/00000">',
    //   stopKeydownPropagation: false,
    // })

    // Swal.fire({
    //   icon: 'question',
    //   title: "Você deseja alterar o status da cobrança para pago?",
    //   showCancelButton: true,
    //   confirmButtonText: "Pagar",
    //   cancelButtonText: "Sair"
    // }).then((auth) => {
    //   if (auth.isConfirmed) {
    //     //@ts-ignore
    //     this.service.pagar('PAID', cob.id).subscribe(
    //       () => {
    //         window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'CobrancasEntity'}));
    //         Swal.fire({
    //           icon: "success",
    //           title: "Cobrança paga"
    //         })
    //       }
    //     )
    //   }
    // })
  }

  enviarCobranca(cob: CobrancasEntity) {
    if (!cob?.id) {
      throw 'id da cobrança não encontrado';
    }

    Swal.fire({
      icon: 'question',
      title: "Você deseja enviar a cobrança para o gerador ?",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Sair"
    }).then((auth) => {
      if (auth.isConfirmed) {
        //@ts-ignore
        this.service.enviarCobranca(cob.id).subscribe(
          () => {
            Swal.fire({
              icon: "success",
              title: "Cobrança enviada"
            })
          }
        )
      }
    })
  }
}
