<div class="folhas">
  <div class="card-body" style="padding: 0">
    <crud-data-table
      [entityMap]="mapEntity"
      [showPagination]="false"
      [showDefaultAction]="false"
      [customDblclick]="true"
      [persistentParams]="tableParams"
      [resourceDictionary]="startPrint"
      sort="clienteNome"
    ></crud-data-table>
  </div>
</div>
