import {setConfigDynamicTable} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../environments/environment";

export const configGlobalDynamicTable: setConfigDynamicTable = {
  apiData: {
    path: environment.apiUrl,
    context: '',
    inputPipe: (resp: any) => {
      return resp['data'] || []
    },
    inputPaginationPipe: (p:any) => {
      return {
        page: p.page.number,
        totalPages: p.page.totalPages,
        totalElements: p.page.totalElements
      };
    },
    outputPaginationPipe: (pageable) => {
      const {totalElements, totalPages, typePagination, ...rest} = pageable;
      return rest;
    }
  },
}

export function addSelectInputinArray(list: any[]): any[] {
  return list.map((item: any) => {
    if (!('selecteInput' in item)) {
      item['selecteInput'] = false;
    }
    return item;
  })
}

export const defaultInputPipeFilterTable = (resp: any) => {
  console.log(resp['content']['content'])
  return resp['content']['content'] || [];
}
