import { ProdutoEntity } from './../produto/produto.entity';
import { DgTableColumn } from "@datagrupo/dg-crud";

export class VendasLogisticaProdutosEntity extends ProdutoEntity {

  constructor(
    id?: number | string,
    produto?: string,
    vendaParcial?: boolean,
    quantidadeDisponivel?: string,
    validade?: string,
    quantidadeVendida?: string

  ) {
    super();
    this.id = id;
    this.produto = produto;
    this.vendaParcial = vendaParcial;
    this.quantidadeDisponivel = quantidadeDisponivel;
    this.validade = validade,
    this.quantidadeVendida = quantidadeVendida


  }

  // @DgTableColumn({ columnName: 'Produto' , sort: true })
  public produto: string | undefined;
  // @DgTableColumn({ columnName: 'Venda Parcial' })
  public vendaParcial: boolean | undefined;
  // @DgTableColumn({ columnName: 'Qtd.Disponivel (TON)' })
  public quantidadeDisponivel: string | undefined;
  // @DgTableColumn({ columnName: 'Validade' })
  public validade: string | undefined;
  public quantidadeVendida: string | undefined;
}

