import { AbstractEntity, DgFilter_input, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";

export interface PeriodicidadesPlano {
  id?: number,
  periodicidadePlano?: string,
  valor?: number | string
}
const filtros = [
  { key: 'all', value: 'Todos' },
  { key: 'true', value: 'Sim' },
  { key: 'false', value: 'Não' },
]

const filterFunc = (val: string, nameParam: string) => {
  if (val == 'all') {
    return
  }

  return {
    [nameParam]: val
  }
}

export class CadastrePlanoEntity extends AbstractEntity {
  constructor(
    id?: number,
    tipoPlanoEnum?: 'PERIODICO' | 'PONTUAL',
    nome?: string,
    gratuidade?: number,
    ativo?: boolean,
    pago?: boolean,
    padrao?: boolean,
    exclusivo?: boolean,
    statusPlano?: string,
    periodicidadesPlano?: PeriodicidadesPlano[],
    listItems?: string[],
    acesso?: string[]
  ) {
    super();
    this.id = id;
    this.tipoPlanoEnum = !!tipoPlanoEnum ? tipoPlanoEnum : 'PERIODICO';
    this.nome = nome;
    this.gratuidade = gratuidade == undefined ? '' : gratuidade;
    this.ativo = !!ativo;
    this.pago = !!pago;
    this.padrao = !!padrao;
    this.exclusivo = !!exclusivo;
    this.statusPlano = statusPlano;
    this.periodicidadesPlano = Array.isArray(periodicidadesPlano) ? periodicidadesPlano : [];
    this.listItems = Array.isArray(listItems) ? listItems : [];
    this.acesso = Array.isArray(acesso) ? acesso : [];
  }

  @DgFilter_input('text', { colum: '4' })
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined;

  @DgFilter_select({
    data: filtros,
    propertKey: 'key',
    propertValue: 'value',
    outputFormat: (val: string) => filterFunc(val, 'pago')
  }
  )
  @DgTableColumn({ columnName: 'PAGO', sort: true, resource: (pag: boolean) => !!pag ? 'Sim' : 'Não' })
  public pago: boolean = false;

  @DgFilter_select({
    data: filtros,
    propertKey: 'key',
    propertValue: 'value',
    outputFormat: (val: string) => filterFunc(val, 'ativo')
  }
  )
  @DgTableColumn({ columnName: 'ATIVO', sort: true, resource: (atv: boolean) => !!atv ? 'Sim' : 'Não' })
  public ativo: boolean = false;

  @DgTableColumn({ columnName: 'PERIÓDICO/PONTUAL' })
  public statusPlano: string | undefined;

  public exclusivo: boolean = false;
  public tipoPlanoEnum: 'PERIODICO' | 'PONTUAL' = 'PERIODICO';
  public periodicidadesPlano: PeriodicidadesPlano[] | undefined;
  public gratuidade: number | string = '';
  public padrao: boolean = false;
  public listItems: string[] = []
  public acesso: string[] = [];
}
