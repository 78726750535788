import { Component, OnInit } from '@angular/core';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import Swal from 'sweetalert2';
import { SelecionarPlanoPontualService } from '../service/selecionar-plano-pontual.service';

@Component({
  selector: 'app-selecionar-plano-pontual',
  templateUrl: './selecionar-plano-pontual.component.html',
  styleUrls: ['./selecionar-plano-pontual.component.scss']
})
export class SelecionarPlanoPontualComponent implements OnInit {

  listaPlano: any[] = [];
  listaPlanoSelecionado: { plano: any, id: number, quantidade?: number }[] = [];
  visualizarPlano: boolean = false;
  constructor(
    public servicePlanos: SelecionarPlanoPontualService,
    public http: HttpOldService
  ) {
    servicePlanos.addWhere({ unpaged: true })
    servicePlanos.findAll().subscribe(resp => {
      //@ts-ignore
      this.listaPlano = resp.data;
    })
  }

  ngOnInit(): void {
  }

  selecionarPlano(index: number) {
    let valorLista = this.listaPlanoSelecionado.find(item => item.id == index);
    if (valorLista) {
      valorLista = {
        ...valorLista,
        quantidade: valorLista.quantidade! += 1
      }
    } else {
      this.listaPlanoSelecionado.push({ plano: this.listaPlano[index], id: index, quantidade: 1, })
    }
  }

  getTotal() {
    let result: any = 0;
    this.listaPlanoSelecionado.map(item => {
      if (!!item.plano.valor && !!item.quantidade) {
        result += item.plano.valor * item.quantidade
      }
    })
    return (result == 0 ? '0,00' : result);
  }

  getQuantidade(index: number) {
    let valor: any;
    if (this.listaPlanoSelecionado.length > 0) {
      valor = this.listaPlanoSelecionado.find(item => item.id == index)
      if (!!valor) {
        return valor.quantidade
      }
    }
    return;
  }

  removerPlano(index: number) {
    let valorSelecionado = this.listaPlanoSelecionado.find(item => item.id == index)
    if (valorSelecionado && valorSelecionado?.quantidade! >= 1) {
      valorSelecionado = {
        ...valorSelecionado,
        quantidade: valorSelecionado.quantidade! -= 1
      }
    }
    if (valorSelecionado || valorSelecionado == 0) {
      this.listaPlanoSelecionado.splice(this.listaPlanoSelecionado[index]?.id, 1)

      console.log(valorSelecionado.id)
    }
  }

  visualizarPlanos() {
    if (this.listaPlanoSelecionado.length <= 0) {
      Swal.fire({
        title: 'Selecione um plano',
        showConfirmButton: true,
        confirmButtonColor: 'green',
        icon: 'warning'
      })
      return;
    }
    this.visualizarPlano = true;
  }

  voltar() {
    this.visualizarPlano = false
  }

  contratar() {
    let planosAContratar: { idPlano?: number, quantidade?: number }[] = [];
    if (this.listaPlanoSelecionado.length > 0) {
      this.listaPlanoSelecionado.forEach(item => {
        planosAContratar.push({ idPlano: item.plano.id, quantidade: item.quantidade })
      })
      this.http.post('planopontual', planosAContratar).subscribe(resp => {
        if (resp) {
          this.listaPlanoSelecionado = [];
          this.voltar()
        }
      })
    }

  }
}
