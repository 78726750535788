import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../../../core/services/session-service/session.service';
import { ArquivosClientesEntity } from '../../../_clientes/_entitys/arquivos-clientes.entity';
import { ArquivosClientesService } from '../../../_clientes/arquivo-clientes/service/clientes-service/arquivos-clientes.service';

@Component({
  selector: 'subComponent-user-arquivos',
  templateUrl: './user-arquivos.component.html',
  styleUrls: ['./user-arquivos.component.scss'],
})
export class UserArquivosComponent implements OnInit {
  public arquivos: ArquivosClientesEntity[] = [];
  public entity = new ArquivosClientesEntity();

  constructor(
    public session: SessionService,
    public arquivoService: ArquivosClientesService
  ) {
    if (session.user.perfil == 'CONDOMINIO') {
      this.getCondominio();
    } else {
      this.getAdministrador();
    }
  }

  ngOnInit(): void { }

  getAdministrador() {
    // ARQUIVO CLIENTE
    this.arquivoService.addWhere({ clienteId: this.session.user.id });
    this.arquivoService.findAll().subscribe((resp) => {
      if (resp) {
        //@ts-ignore
        this.arquivos = resp.data;
        this.arquivoService.removeWhere(['userId']);
      }
    });
    console.log(this.session.user.id)
    console.log(this.session.user.cliente_id)
  }

  getCondominio() {
    // ARQUIVO CLIENTE
    this.arquivoService.addWhere({

      clienteId: this.session.user.cliente_id,
    });
    this.arquivoService
      .findAll()
      .subscribe((resp) => {
        if (resp) {
          //@ts-ignore
          this.arquivos = resp.data;
          this.arquivoService.removeWhere(['userId']);
        }
      });
    console.log(this.session.user.id)
    console.log(this.session.user.cliente_id)
  }
}
