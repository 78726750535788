import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'print-loading',
  templateUrl: './print-loading.component.html',
  styleUrls: ['./print-loading.component.scss']
})
export class PrintLoadingComponent implements OnInit {

  @Input() list: {chave: string, nome: string}[] = [];
  @Input() visible: boolean = true;
  @Output() completeList = new EventEmitter()

  show: boolean = true;
  loadingList: { chave: string,nome: string, complete: boolean }[] = [];
  msgMount = false

  constructor() { }

  ngOnInit(): void {
    this.registerListLoader(this.list)
  }

  registerListLoader(list: {chave: string, nome: string}[]) {
    this.loadingList = list.map(item => {
      return { ...item, complete: false }
    });
  }

  updateRegister(chave: string, complete: boolean) {
    const index = this.loadingList.findIndex(item => item.chave == chave);

    if (index != -1) {
      this.loadingList[index].complete = complete;
      this.verifyCompleteList()
    }
  }

  verifyCompleteList() {
    const completes = this.loadingList.filter(item => item.complete);

    if (completes.length == this.loadingList.length) {


      setTimeout(() => {
        this.msgMount = true;
        setTimeout(() => {
          this.msgMount = true;
          this.show = false;
          this.completeList.emit()
        }, 2000)
      }, 1000)
    }
  }
}
