import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { MissaoEntity } from '../../missao/missao.entity';
import { MissoesService } from '../service/missoes.service';

@Component({
  selector: 'app-missoes-cupom',
  templateUrl: './missoes-cupom.component.html',
  styleUrls: ['./missoes-cupom.component.scss']
})
export class MissoesCupomComponent implements OnInit {

  data: any;

  constructor(
    public route: ActivatedRoute,
    public http: HttpOldService,
    public router: Router
  ) {
    // super(conf, servic);
  }

  ngOnInit(): void {
    let cupomId: number;
    cupomId = this.route.snapshot.params["id"];
    this.http.get(`missoes/cupom/${cupomId}`).subscribe(resp => {
      //@ts-ignore
      this.data = resp.data;
    })
  }

  resgatarCupom() {
    this.http.post(`missoes/conquistas/resgatar-cupom/${this.data.missaoId}`, this.data.missaoId).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        window.open(resp.data, "_blank")
      }
    });
  }

}
