import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import {ArquivosClientesEntity} from "../../../_entitys/arquivos-clientes.entity";
import {CrudDataTableComponent} from "@datagrupo/dg-crud";
import {ClientesEntity} from "../../clientes.entity";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'subPage-cliente-sub-page-mtr',
  templateUrl: './cliente-sub-page-mtr.component.html',
  styleUrls: ['./cliente-sub-page-mtr.component.scss']
})
export class ClienteSubPageMtrComponent implements OnInit {

  @ViewChild('tableArquivo') tableArquivo!: CrudDataTableComponent;

  // @Input() tableParam: { clienteId?: number | string } = {};
  @Input() entity?: ClientesEntity;
  editMtr = new FormControl(false)

  public arquivosEntity = new ArquivosClientesEntity();

  public form = new FormGroup({
    cargo: new FormControl({value:'', disabled: true}),
    cpfUsuario: new FormControl({value:'', disabled: true}),
    instituto: new FormControl({value:'', disabled: true}),
    responsavel: new FormControl({value:'', disabled: true}),
    senha: new FormControl({value:'', disabled: true}),
    siteManifesto: new FormControl({value:'', disabled: true}),
    cnpjMtr: new FormControl({value:'', disabled: true}),
    codigo: new FormControl({value:'', disabled: true}),
    emailMtr: new FormControl({value:'', disabled: true}),
  })

  constructor(
    public session: SessionService
  ) {
    if (!this.checkPermission()) {
      this.arquivosEntity.addActions([
        { name: 'Download', permission: false, action: () => {} },
        { name: 'Editar', permission: false, action: () => {} },
        { name: 'excluir', permission: false, action: () => {} },
      ]);
    }

    this.editMtr.valueChanges.subscribe(resp => {
      this.form.controls['instituto'][!!resp ? 'enable' : 'disable']()
      this.form.controls['responsavel'][!!resp ? 'enable' : 'disable']()
      this.form.controls['senha'][!!resp ? 'enable' : 'disable']()
      this.form.controls['siteManifesto'][!!resp ? 'enable' : 'disable']()
      this.form.controls['cnpjMtr'][!!resp ? 'enable' : 'disable']()
      this.form.controls['codigo'][!!resp ? 'enable' : 'disable']()
      this.form.controls['emailMtr'][!!resp ? 'enable' : 'disable']()
      this.form.controls['cargo'][!!resp ? 'enable' : 'disable']()
      this.form.controls['cpfUsuario'][!!resp ? 'enable' : 'disable']()
    })
  }

  ngOnInit(): void {

  }

  checkPermission(aceptGalpao: boolean = false) {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
      return true;
    }

    if (!this.entity?.id) return true

    if (this.session.checkPerfil('PARCEIRO')) {
      return !!this.entity?.isCreated;
    }

    if (this.session.checkPerfil('GALPAO')) {
      return !!this.entity?.exclusivo;
    }

    if (aceptGalpao) {
      return this.session.checkPerfil('GALPAO');
    }

    return false;
  }

  getData() {
    return this.form.value;
  }

  setData(data: {[key:string]:any}) {
    this.form.patchValue(data);
  }

}
