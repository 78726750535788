import { Component, OnInit } from '@angular/core';
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {ContainerClienteService} from "../../../_clientes/container-cliente/service/container-cliente.service";
import {ContainerClienteEntity} from "../../../_clientes/_entitys/container-cliente.entity";

@Component({
  selector: 'subComponent-user-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss']
})
export class UserContainerComponent implements OnInit {

  public entity = new ContainerClienteEntity();

  constructor(
    public session: SessionService,
    public containerServic: ContainerClienteService
  ) {
    // CONTAINER
    this.containerServic.addWhere({ clienteId: this.session.user.cliente_id});
  }

  ngOnInit(): void {
  }

}
