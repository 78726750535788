import {Component, OnInit, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../ui/modal/modal/modal.component";
import {FavoritosInsertEditComponent} from "../../favoritos-insert-edit/favoritos-insert-edit.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {HttpOldService} from "../../../../core/services/http/http-old.service";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: 'modal-favoritos-main',
  templateUrl: './modal-favoritos-main.component.html',
  styleUrls: ['./modal-favoritos-main.component.scss']
})
export class ModalFavoritosMainComponent implements OnInit {

  @ViewChild('modalFavoritos') modal!: OldModalComponent;
  @ViewChild('modalFavoritosInsertEdit') modalIsetEdit!: FavoritosInsertEditComponent;

  public tableColetas: CdkDynamicTable.tableClass

  public loader = {
    DASHBOARD_COLETAS: true,
    DASHBOARD_ESTOQUE: true,
    RANKING_ATERRO_ZERO: true,
    RANKING_TAXA_RECICLAGEM: true,
  }

  private buscas = {
    DASHBOARD_COLETAS: () => {
      this.loader.DASHBOARD_COLETAS = true;
      this.service.get<any>('favoritos', {params: {tipoFavorito: 'DASHBOARD_COLETAS'}})
        .subscribe(resp => {
          this.loader.DASHBOARD_COLETAS = false;
          this.listFavoritos.coletas = resp.data
        })
    },
    DASHBOARD_ESTOQUE: () => {
      this.loader.DASHBOARD_ESTOQUE = true;
      this.service.get<any>('favoritos', {params: {tipoFavorito: 'DASHBOARD_ESTOQUE'}})
        .subscribe(resp => {
          this.loader.DASHBOARD_ESTOQUE = false;
          this.listFavoritos.estoque = resp.data
        })
    },
    RANKING_ATERRO_ZERO: () => {
      this.loader.RANKING_ATERRO_ZERO = true;
      this.service.get<any>('favoritos', {params: {tipoFavorito: 'RANKING_ATERRO_ZERO'}})
        .subscribe(resp => {
          this.loader.RANKING_ATERRO_ZERO = false;
          this.listFavoritos.rankingAterro = resp.data
        })
    },
    RANKING_TAXA_RECICLAGEM: () => {
      this.loader.RANKING_TAXA_RECICLAGEM = true;
      this.service.get<any>('favoritos', {params: {tipoFavorito: 'RANKING_TAXA_RECICLAGEM'}})
        .subscribe(resp => {
          this.loader.RANKING_TAXA_RECICLAGEM = false;
          this.listFavoritos.rankingTaxa = resp.data
        })
    },
  }

  listFavoritos: {
    coletas: any[],
    estoque: any[],
    rankingAterro: any[],
    rankingTaxa: any[],
  } = {
    coletas: [],
    estoque: [],
    rankingAterro: [],
    rankingTaxa: []
  };

  tableVisible: 'DASHBOARD_COLETAS' | 'DASHBOARD_ESTOQUE' | 'RANKING_ATERRO_ZERO' | 'RANKING_TAXA_RECICLAGEM' = 'DASHBOARD_COLETAS'

  public listPreselect: {
    coletas: any
  } = {
    coletas: undefined
  }

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    unidade: new FormControl(''),
    galpao: new FormControl(''),
  })

  constructor(
    private route: ActivatedRoute,
    public session: SessionService,
    private service: HttpOldService,
    private dynamicTable: CdkDynamicTableService
  ) {
    this.tableColetas = dynamicTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'favoritos',
      },
    })
  }

  ngOnInit(): void {
  }

  open() {
    this.buscas.DASHBOARD_COLETAS()
    this.buscas.DASHBOARD_ESTOQUE()
    this.buscas.RANKING_ATERRO_ZERO()
    this.buscas.RANKING_TAXA_RECICLAGEM()
    this.modal.open();
  }

  getTables() {
    const p1 = this.service.get<any>('favoritos', {params: { unpaged: true, tipoFavorito: 'DASHBOARD_COLETAS'}});
    const p2 = this.service.get<any>('favoritos', {params: { unpaged: true, tipoFavorito: 'DASHBOARD_ESTOQUE'}});
    const p3 = this.service.get<any>('favoritos', {params: { unpaged: true, tipoFavorito: 'RANKING_ATERRO_ZERO'}});
    const p4 = this.service.get<any>('favoritos', {params: { unpaged: true, tipoFavorito: 'RANKING_TAXA_RECICLAGEM'}});

    return Promise.all([p1, p2, p3, p4])
  }

  save(type: "DASHBOARD_COLETAS" | "DASHBOARD_ESTOQUE" | "RANKING_ATERRO_ZERO" | "RANKING_TAXA_RECICLAGEM") {
    this.buscas[type]();
    window.dispatchEvent(new CustomEvent('update-'+type))
  }

  createFavorite() {
    if (!!this.route.snapshot.queryParams['coletas']) {
      const params = JSON.parse(this.route.snapshot.queryParams['coletas']) || {};

      if (!!window.localStorage.getItem('listFavorites')) {
        this.listFavoritos.coletas = <any[]>(JSON.parse(window.localStorage.getItem('listFavorites') || '') || [])
      }

      this.listFavoritos.coletas.push({id: this.listFavoritos.coletas.length + 1, ...params})

      window.localStorage.setItem('listFavorites', '--')
      window.localStorage.setItem('listFavorites', JSON.stringify(this.listFavoritos.coletas));
    }
  }

  removeFav(tableVisible: 'DASHBOARD_COLETAS' | 'DASHBOARD_ESTOQUE' | 'RANKING_ATERRO_ZERO' | 'RANKING_TAXA_RECICLAGEM', fav: any) {
    Swal.fire({
      icon: 'warning',
      title: 'Tem certeza que deseja excluir esse favorito?'
    }).then(confirm => {
      if (!confirm.isConfirmed) return;
      this.service.delete('favoritos', fav.id).subscribe(resp => {
        this.buscas[tableVisible]();
      })
    })
  }

  getAtivoInativo(d: string | undefined) {
    if (!d) return 'Ambos';
    return d == 'true' ? 'Ativos' : 'Inativos';
  }

  getListPropert(
    fav: any,
    propert: 'clientes' | 'estados' | 'galpoes' | 'motoristas' | 'tipoLixos' | 'dataInicio'
  ): string {
    const result = Array.isArray(fav[propert]) ? fav[propert] : [];

    if (result.length <= 0) return '--';

    return result.map((item: any) => {
      if (propert == 'estados') {
        return item['name']
      }
      return item['nome']
    }).join(', ')
  }

  afterClose = () => {
    this.listPreselect = {
      coletas: undefined
    }
  }
}
