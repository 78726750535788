import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {ContainerEntity} from "../../../../container/container.entity";
import {GenericCrudService} from "../../../../../../core/services/generic-crud-service/generic-crud.service";
import {GALPAO_CONTAINER} from "../../../../../../core/config/endpoint-list";
import {crudDispatchEvent} from "@datagrupo/dg-crud";

@Component({
  selector: 'model-relacionar-container-galpao',
  templateUrl: './relacionar-container-galpao.component.html',
  styleUrls: ['./relacionar-container-galpao.component.scss']
})
export class RelacionarContainerGalpaoComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent
  @Input() idGalpao: string | number | undefined;

  public listContainer: ContainerEntity[] = [];
  container = new FormControl('', [Validators.required])

  constructor(private service: GenericCrudService) {}

  ngOnInit(): void {}

  open() {
    this.service.get('containers', { params: { ativo: true, unpaged: true, modelo: true } }).subscribe(
      resp => {
        this.listContainer = resp.data;
        this.modal.open()
      }
    )
  }

  save() {
    if (this.container.invalid) {
      this.container.markAllAsTouched();
      return;
    }

    const data = {
      containerId: this.container.value,
      galpaoId: this.idGalpao
    };
    this.service.post(GALPAO_CONTAINER + '/modelo', data).subscribe(
      () => {
        crudDispatchEvent('GalpaoContainerEntity');
        this.modal.close()
      }
    )
  }

  afterClose = () => {
    this.container.reset('')
  }
}
