import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit, HttpClient } from '@datagrupo/dg-crud';
import { IndicadoresOdsEntity } from '../indicadores-ods.entity';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';
import { IndicadoresOdsService } from '../service/indicadores-ods.service';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
@Component({
  selector: 'app-indicadores-ods-insert-edit',
  templateUrl: './indicadores-ods-insert-edit.component.html',
  styleUrls: ['./indicadores-ods-insert-edit.component.scss']
})
export class IndicadoresOdsInsertEditComponent extends AbstractInsertEdit<IndicadoresOdsEntity> implements OnInit {

  public listIndOds: IndicadoresOdsEntity[] = [];

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    unidadeGeradora: new FormControl('', [Validators.required]),
    ano: new FormControl('', [Validators.required]),
    verificarTaxas: new FormControl('', [Validators.required])
  });

  constructor(
    public clientesServic: ClientesService,
    public conf: ConfigDgCrudService, 
    public servic: IndicadoresOdsService, 
    public http: HttpClient) {
    super(conf);

    http.get(`${environment.apiUrlMock}indicadores-ods`).subscribe((resp) => {
      //@ts-ignore
      this.listIndOds = resp.data
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new IndicadoresOdsEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({

    });
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { ...form } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    };

    return true;
  }
}
