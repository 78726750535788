import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ClientesEntity} from "../../../../pages/_user/_clientes/clientes/clientes.entity";
import {TipoLixoEntity} from "../../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {GenericCrudService} from "../../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ajusteDateByPaste, formata_data_utc_br} from "../../../../helpers/helpers";
import {AbstractSideFilter, dataSeideFilter} from "../../cdk/abstract-side-filter";
import {
  CLIENTE_ESTADOS, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
  DASHBOARD_FILTER_UNIDADE_GERADORA,
} from "../../../../core/config/endpoint-list";
import {CloseAutocomplete, InputAutocompleteComponent} from "@datagrupo/dg-ng-util";

@Component({
  selector: 'sidefilter-dashboard-ranking-aterro-zero',
  templateUrl: './sidefilter-dashboard-ranking-aterro-zero.component.html',
  styleUrls: ['./sidefilter-dashboard-ranking-aterro-zero.component.scss']
})
export class SidefilterDashboardRankingAterroZeroComponent extends AbstractSideFilter implements OnInit {

  objParamName = 'ranking-aterro';

  override listsPreSelected: {
    favoritos: any[],
    unidades: any[],
    estados: any[],
    tipoResiduo: any[]
  } = {
    favoritos: [],
    unidades: [],
    estados: [],
    tipoResiduo: []
  }
  listSelected: {
    favoritos: any[],
    unidades: any[],
    estados: any[],
    tipoResiduo: any[]
  } = {
    favoritos: [],
    unidades: [],
    estados: [],
    tipoResiduo: []
  }
  public _dataFilter = {
    favoritos: <any>[],
    unidades: <any[]>[],
    estados: <any[]>[],
    tipoResiduo: <any[]>[],
    dataInicio: '',
    dataFim: '',
    ativo: '',
    perfilUnidade: ''
  };
  listItensDisponiveis: {
    favoritos: any[],
    unidades: ClientesEntity[],
    estados: {name: string, uf: string}[],
    tipoResiduo: TipoLixoEntity[],
  } = {
    favoritos: [],
    unidades: [],
    estados: [],
    tipoResiduo: [],
  }

  timeout: any = null;
  public maxDate = '';

  constructor(
    public service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    super(service, router, route);
    this.maxDate = formata_data_utc_br(new Date().toDateString()).split('/').reverse().join('-')
  }

  ngOnInit(): void {
    this.verifyParamsStart()
  }

  //*************************** METODOS PARA O ABSTRACT ***************************//

  setInitParams(params?: any, preValues?: any): void {
    if (!!params) {
      this.listsPreSelected = {
        ...this.listsPreSelected,
        ...preValues.listsPreSelected
      };
      this.listSelected = {
        ...this.listSelected,
        ...preValues.listSelected
      };
      this._dataFilter = {
        ...this._dataFilter,
        ...preValues._dataFilter,
        estados: (params.estados || []).map((m: any) => m.uf)
      };
    }

    this.ajustDataByPerfil(true)
    this.getDataAutocompletes();
    setTimeout(() => this.setQueryParams(), 10)
  }

  ajustDataByPerfil(initFilter = false) {
    if (this.session.checkPerfil(['CONDOMINIO', 'CASA', 'EVENTO', 'EMPRESA'])) {

      const unidade = {id: this.session.user.cliente_id, nome: this.session.user.cliente_nome || '--'};

      if (initFilter) {
        this.listsPreSelected.unidades = [unidade]
      }
      this.listSelected.unidades = [unidade]
      this._dataFilter.unidades = [unidade.id]
    }
  }

  ajsutDataSetParams(preValues: object) {
    const { ativo } = this._dataFilter;
    const result: any = preValues;
    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;

    return result;
  }

  clearFilter(preValues: dataSeideFilter) {
    this._dataFilter = {
      ...this._dataFilter,
      ...preValues._dataFilter
    }
    this.listSelected = {
      ...this.listSelected,
      ...preValues.listSelected
    }
    this.listsPreSelected = {
      ...this.listsPreSelected,
      ...preValues.listsPreSelected
    }

    this.ajustDataByPerfil()
    this.updateDataAutocompletes('all')
  }

  ajsutDataEmiter(preValues: object) {
    return preValues;
  }

  //*************************** METODOS LOCAIS ***************************//

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  verifyChangeData(tipo: 'dataFim' | 'dataInicio' | 'perfilUnidade'):void {
    if (tipo == 'perfilUnidade') {
      this.setQueryParams();
      return
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const date = new Date(tipo == 'dataFim' ? this._dataFilter.dataFim : this._dataFilter.dataInicio);

      if (!isNaN(date.valueOf())) {
        if (date >= new Date('1995-12-17T03:24:00')) {
          this.setQueryParams()
        }
      } else if (this._dataFilter[tipo] == '') {
        this.setQueryParams();
      }
    }, 300);
  }

  getDataAutocompletes() {

    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
      this.updateFavoritos()
    }

    let {estados, unidades, tipoResiduo} = <{ unidades: string, estados: string, tipoResiduo: string }>this.preAjustDataEmiter();

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA'])) {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;


          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this.service.get(CLIENTE_ESTADOS,
      {
        params: {
          unpaged: true,
          ...(!!unidades ? {clienteId: unidades} : {}),
          ...(!!tipoResiduo ? {tipoLixoId: tipoResiduo} : {})
        }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.estados = resp.data
      }
    )

    this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
      {
        params: {
          unpaged: true,
          ...(!!unidades ? {clienteId: unidades} : {}),
          ...(!!estados ? {estados: estados} : {})
        }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )
  }

  updateDataAutocompletes(origin: 'unidades' | 'estados' | 'all') {
    let {estados, unidades} = <{ unidades: string, estados: string, tipoResiduo: string }>this.preAjustDataEmiter();

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA']) && origin != 'unidades') {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;

          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this._service.get(DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
      {
        params:
          {
            unpaged: true,
            ...(!!unidades ? {clienteId: unidades} : {}),
            ...(!!estados ? {estados: estados} : {})
          }
      }).subscribe(
      resp => {
        this.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )
  }

  public ajustePasteDate = (ev: any, varName: 'dataInicio' | 'dataFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)

  setAutoComplete(data: CloseAutocomplete, identificador: | 'unidades' | 'tipoResiduo' | 'estados') {
    if(this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this.setQueryParams();
    } else {
      this.listSelected[identificador] = [...data.selectedList];
    }

    if (identificador == 'estados' || identificador == 'unidades') {
      this.updateDataAutocompletes(identificador)
    }
  }

  @ViewChild('autoEstados') autoEstados!: InputAutocompleteComponent;
  @ViewChild('autoResiduo') autoResiduo!: InputAutocompleteComponent;
  @ViewChild('autoUnidade') autoUnidade!: InputAutocompleteComponent;


  setFavoritos(d: any) {
    const data = d.selectedList[0] || {}

    this._dataFilter = {
      ...this._dataFilter,
      unidades: <any[]>[],
      estados: <any[]>[],
      tipoResiduo: <any[]>[],
      dataInicio: '',
      dataFim: '',
      ativo: '',
      perfilUnidade: ''
    }

    const mapAutocompletes: {[key: string]: 'autoEstados' | 'autoResiduo' | 'autoUnidade'} = {
      tipoLixos: 'autoResiduo',
      estados: 'autoEstados',
      clientes: 'autoUnidade',
    }

    Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        if (data[key].length > 0) {
          if (key in mapAutocompletes) {
            if (!this[mapAutocompletes[key]]) return;
            this[mapAutocompletes[key]].selectedList = data[key] || [];
            const value = this[mapAutocompletes[key]].selectedList.map(item => this[mapAutocompletes[key]].dataManage.getKey(item))
            this[mapAutocompletes[key]].dataManage.setValue(value)

            this[mapAutocompletes[key]].eventManage.close()
          }
        }
      } else if (['dataInicio', 'dataFim'].includes(key)){
        this._dataFilter[<'dataInicio' | 'dataFim' | 'ativo'>key] = data[key]
      }
    })

    this.setQueryParams().then();
  }

  @HostListener('window:update-RANKING_ATERRO_ZERO')
  updateFavoritos() {
    this._service.get('favoritos', { params: { unpaged: true, tipoFavorito: 'RANKING_ATERRO_ZERO' } }).subscribe(
      resp => {
        this.listItensDisponiveis.favoritos = resp.data || [];
      }
    )
  }

}
