import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, Validators} from "@angular/forms";
import {CategoriaArquivoEntity} from "../../../../categoria-arquivo/categoria-arquivo.entity";
import {GalpaoDocumentosService} from "../../../galpao-documentos/service/galpao-documentos.service";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";
import {GenericCrudService} from "../../../../../../core/services/generic-crud-service/generic-crud.service";
import {verifySizeFiles} from "../../../../../../helpers/helpers";

@Component({
  selector: 'subComponent-motorista-documento',
  templateUrl: './motorista-documento.component.html',
  styleUrls: ['./motorista-documento.component.scss']
})
export class MotoristaDocumentoComponent implements OnInit {

  @Input() motoristaId: string | number | undefined;

  @ViewChild('modalDocumentosMotorista') modal!: OldModalComponent;
  @ViewChild('docInput') docInput!: ElementRef;

  @Output() salve = new EventEmitter<any>();

  public file: File | undefined;
  public docFile: File | undefined;
  public docName: string | undefined;

  public edit = false;
  public docId: number | undefined;

  public categoriaArquivo: FormControl = new FormControl('', [Validators.required])

  public categoriasList: CategoriaArquivoEntity[] = [];

  constructor(
    private service: GenericCrudService,
    public filesManage: FilesManageService
  ) {
    service.get('categoria-arquivos', {params: {unpaged: true}}).subscribe(
      resp => {
        this.categoriasList = resp.data
      }
    )
  }

  ngOnInit(): void {
  }

  public open(data?: any) {
    if (!!data) {
      if (!!data?.id) {
        this.edit = true;
        this.docId = data.id;
        this.modal.open()

        if (!!data?.categoria) {
          this.categoriaArquivo.patchValue(data.categoria?.id)
        }

        if (!!data?.arquivo) {
          this.docName = data.arquivo?.fileNameOriginal
        }
      }
    } else {
      this.modal.open()
    }
  }

  uploadDocumento_click() {
    const docInput = this.docInput.nativeElement;
    docInput.click()
  }

  uploadDocumento(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.docFile = undefined
        return;
      }

      this.docName = file.name
      this.file = file
    }
  }

  saveOrUpdate() {
    if (!this.categoriaArquivo.valid) {
      this.categoriaArquivo.markAllAsTouched()
      return;
    }

    if (this.edit) {
      this.update();
    } else {
      this.salvar()
    }
  }

  update() {
    this.service.patch(
      'arquivo-motorista',
      {},
      {
        endpoint: [this.docId, this.categoriaArquivo.value]
      }
    ).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'GalpaoArquivoMotoristaEntity'}));
        this.modal.close();
      }
    )
  }

  salvar() {
    var formData: any = {}

    if (!this.docName || !this.file || !this.motoristaId) {
      Swal.fire({
        icon: 'error',
        title: "Selecione um arquivo",
        timer: 3000
      }).then();
    }

    formData = new FormData();

    formData.append("motoristaId", this.motoristaId);
    formData.append("categoriaId", this.categoriaArquivo.value);
    formData.set("arquivo", this.file);

    this.service.save(formData, { context: 'arquivo-motorista' }).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'GalpaoArquivoMotoristaEntity'}));
        this.modal.close();
      }
    )
  }

  download() {
    if (this.docId) {
      this.service.get('arquivo-motorista/' + this.docId).subscribe(
        resp => {
          //@ts-ignore
          this.filesManage.downloadBase64(resp.data, this.docName)
        }
      )
    }
  }

  close = (e?: any) => {
    this.categoriaArquivo.reset('')
    this.docName = undefined;
    this.docFile = undefined;
    this.docId = undefined;
    this.edit = false;
    return true
  }

}
