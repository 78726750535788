<div
  #canvasDashboardColetas
  class="offcanvas offcanvas-end sidebar-filter"
  tabindex="-1"
>
  <div class="offcanvas-header">
    <h5>Filtros</h5>

    <div>
      <span (click)="clear.emit()" class="material-symbols-outlined pointer" matTooltip="Limpar filtros">delete</span>
      <span
        *ngIf="visibleFavorite"
        class="material-symbols-outlined pointer"
        matTooltip="Salvar filtros como favorito"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        (click)="modal.open()"
      >star</span>
    </div>

    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ng-content></ng-content>
  </div>
</div>

<modal-favoritos-main #modal (uploadeFavoritoList)="uploadeFavoritoList.emit($event)"></modal-favoritos-main>
