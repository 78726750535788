import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";

@DataServer({
  path: environment.apiUrl,
  context: 'usuarios-parceiros'
})
export class HoldingCadastroEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    holdingId?: number | string,
    nome?: string,
    login?: string,
    sobrenome?: string,
    email?: string,
    telefone?: string,
  ) {
    super();
    this.id = id;
    this.holdingId = holdingId;
    this.nome = nome;
    this.login = login;
    this.sobrenome = sobrenome;
    this.email = email;
    this.telefone = telefone;
  }

  @DgTableColumn({columnName: 'NOME', sort: true})
  public nome: string | undefined
  @DgTableColumn({columnName: 'LOGIN', sort: true})
  public login: string | undefined

  public holdingId: string | number | undefined
  public sobrenome: string | undefined
  public email: string | undefined
  public telefone: string | undefined
}
