import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route} from "@angular/router";
import {HttpOldService} from "../../../core/services/http/http-old.service";

@Component({
  selector: 'app-certificado-credito-logistica-reversa',
  templateUrl: './certificado-credito-logistica-reversa.component.html',
  styleUrls: ['./certificado-credito-logistica-reversa.component.scss']
})
export class CertificadoCreditoLogisticaReversaComponent implements OnInit {

  public data: {
    vendaClienteCreditoLogisticaReversaId: number,
    clienteCreditoLogisticaReversaNome: string,
    planoNome: string,
    produtoNome: string,
    quantidadeVendida: number,
    porcentagemProdutos: string,
    clienteCreditoLogisticaReversaComprovante: string,
    identificadorPagamento: string,
    galpaoNome: string,
    galpaoCnpj: string,
    galpaoCidade: string,
    galpaoEstado: string,
    industriaNome: string,
    industriaCnpj: string,
    industriaCidade: string,
    industriaEstado: string,
    vlrQuantidadeClientes: number,
    vlrQuantidadeCaminhoes: number,
  } | undefined;

  public id: string | undefined;

  comprovanteEnum: {[key: string]: any} = {
    INFORMADO_ATO_COMPRA: "Informado no ato da compra",
    DOCUMENTACAO_VENDA: "Comprovado por documentação de venda"
  }

  comprovanteAjust(val: string) {
    if (!val) return '--'
    if (val in this.comprovanteEnum) return this.comprovanteEnum[val] || ''
  }

  constructor(
    private route: ActivatedRoute,
    private service: HttpOldService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!params['id']) return

      this.id = params['id'];

      this.getData(params['id']);
    })
  }

  getData(id: string) {
    this.service.get('venda-logistica-reversa/certificado/' + id).subscribe((resp: any) => {
      this.data = resp.data
    })
  }
}
