<div id="dashboard" style="margin-top: -2rem;">

  <div class="container-fluid">
    <div class="row">
      <h2>Dashboard</h2>
    </div>

    <div class="row">
      <div class="col-12 d-inline-flex justify-content-center">
        <ul class="nav nav-pills mb-3">
          <li class="nav-item" role="presentation">
            <button
              class="dashboard-buttons btn btn-light" type="button"
              (click)="selectView('coletas')" [class.active]="view == 'coletas'"
            >
              Coletas
            </button>
          </li>
          <li *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'GALPAO'])" class="nav-item" role="presentation">
            <button
              class="dashboard-buttons btn btn-light" type="button"
              (click)="selectView('estoque')" [class.active]="view == 'estoque'"
            >
              Estoque
            </button>
          </li>
          <li *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])" class="nav-item" role="presentation">
            <button
              class="dashboard-buttons btn btn-light" type="button"
              (click)="selectView('rankings')" [class.active]="view == 'rankings'"
            >
              Rankings
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="dashboard-buttons btn btn-light" type="button"
              (click)="selectView('impacto')" [class.active]="view == 'impacto'"
            >
              Impacto
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container-fluid">

    <div
      *ngIf="loadedView['coletas']"
      class="row"
      [hidden]="view != 'coletas'">
      <div class="col-12">
        <page-dashboard2-coletas></page-dashboard2-coletas>
      </div>
    </div>

    <div
      *ngIf="loadedView['estoque']"
      class="row"
      [hidden]="view != 'estoque'"
    >
      <div class="col-12">
        <page-dashboard2-estoque></page-dashboard2-estoque>
      </div>
    </div>

    <div
      *ngIf="loadedView['rankings']"
      class="row"
      [hidden]="view != 'rankings'"
    >
      <div class="col-12">
        <dashboard2-rankings></dashboard2-rankings>
      </div>
    </div>

    <div
      *ngIf="loadedView['impacto']"
      class="row"
      [hidden]="view != 'impacto'"
    >
      <div class="col-12">
        <dashboard2-impacto></dashboard2-impacto>
      </div>
    </div>
  </div>
</div>

