import { ArquivoEntity } from './../../../shared/entitys/arquivo.entity';
import {AbstractEntity, DataServer, DgTableColumn} from '@datagrupo/dg-crud';
import { ProdutoEntity } from '../produto/produto.entity';
import {environment} from "../../../../environments/environment";
import {CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS} from "../../../core/config/endpoint-list";

@DataServer({
  path: environment.apiUrl,
  context: CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS
})
export class LogisticaDocumentoEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    clienteCreditoId?: string,
    nome?: string,
    produto?: ProdutoEntity,
    quantidade?: string,
    arquivo?: ArquivoEntity
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.produto = produto;
    this.quantidade = quantidade;
    this.clienteCreditoId = clienteCreditoId
  }

  //public id: string | number | undefined;
  @DgTableColumn({ columnName: 'Nome Documento', resource: (doc: ArquivoEntity ) => {
    return  !!doc?.fileNameOriginal ? doc.fileNameOriginal : "--" ;
  }, sort: true
  })
  public arquivo: ArquivoEntity | undefined

  @DgTableColumn({ columnName: 'Produto', sort: true, resource: (prod: ProdutoEntity) => !!prod.nome ? prod.nome : '' })
  public produto : ProdutoEntity | undefined;

  @DgTableColumn({ columnName: 'Quantidade', sort: true})
  public quantidade : string | undefined;

  public nome: string | undefined;
  public clienteCreditoId: string | undefined;
}
