<div id="white-label">

  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Selecione um ou mais Geradores/Transportador</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <div class="row">
                <div
                  class="col-md-12 col-sm-12"
                  [class.col-xl-6]="form.value.type != 'PARCEIRO'"
                  [class.col-lg-6]="form.value.type != 'PARCEIRO'"
                  [class.col-xl-12]="form.value.type == 'PARCEIRO'"
                  [class.col-lg-12]="form.value.type == 'PARCEIRO'"
                >
                  <label class="form-label">Tipo</label>
                  <select class="form-select" formControlName="type">
                    <option value="">Selecione o tipo</option>
                    <option value="OPERADOR">Transportador</option>
                    <option value="UNIDADE">Gerador</option>
                    <option value="PARCEIRO">Master</option>
                  </select>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  [hidden]="form.value.type != 'UNIDADE'"
                >
                  <label class="form-label">Geradores</label>
                  <input-autocomplete
                    #autoCompUnidade
                    formControlName="cliente"
                    key="id"
                    label="nome"
                    classes="form-control"
                    [list]="listaUnidades"
                    (close)="find($event, 'cliente')"
                  ></input-autocomplete>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  [hidden]="form.value.type != 'OPERADOR'"
                >
                  <label class="form-label">Transportador</label>
                  <input-autocomplete
                    #autoCompOperador
                    formControlName="galpao"
                    key="id"
                    label="nome"
                    classes="form-control"
                    [list]="listaGalpao"
                    (close)="find($event, 'galpao')"
                  ></input-autocomplete>
                </div>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'selected'" class="container-fluid container-preview">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Cores dos botões</h4>
          </div>
          <div class="card-body" style="padding: 0">
            <table class="crud-table">
              <thead>
              <tr>
                <th>nome</th>
                <th>Cor de fundo dos botões</th>
                <th>Cor dos botões destacados</th>
                <th>Cor do texto dos botões</th>
                <th>botões normais</th>
                <th>botões destacado</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let color of (properties.colorButtons || []); let index = index">
                <td>
                  <p class="f-bold">{{color.nome}}</p>
                </td>
                <td>
                  <div class="row">
                    <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12">
                      <label for="input-color-{{color.nome}}" class="form-label">Cor</label>
                      <input type="color" (change)="themeService.setPreviewButtons(properties.colorButtons)" [(ngModel)]="color.backgroungColor"
                             class="form-control form-control-color" id="input-color-{{color.nome}}"
                             title="Selecione sua cor">
                    </div>
                    <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12">
                      <label class="form-label">Código hex</label>
                      <input class="form-control" (change)="themeService.setPreviewButtons(properties.colorButtons)" [(ngModel)]="color.backgroungColor"
                             title="Choose your color">
                    </div>
                  </div>
                </td>
                <td>
                  <div class="row">
                    <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12">
                      <label for="input-color-{{color.nome}}" class="form-label">Cor</label>
                      <input type="color" (change)="themeService.setPreviewButtons(properties.colorButtons)" [(ngModel)]="color.backgroungColorHover"
                             class="form-control form-control-color" id="input-color-{{color.nome}}"
                             title="Selecione sua cor">
                    </div>
                    <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12">
                      <label class="form-label">Código hex</label>
                      <input class="form-control" (change)="themeService.setPreviewButtons(properties.colorButtons)"
                             [(ngModel)]="color.backgroungColorHover" title="Choose your color">
                    </div>
                  </div>
                </td>
                <td>
                  <div class="row">
                    <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12">
                      <label for="input-color-{{color.nome}}" class="form-label">Cor</label>
                      <input type="color" (change)="themeService.setPreviewButtons(properties.colorButtons)" [(ngModel)]="color.textColor"
                             class="form-control form-control-color" id="input-color-{{color.nome}}"
                             title="Selecione sua cor">
                    </div>
                    <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12">
                      <label class="form-label">Código hex</label>
                      <input class="form-control" (change)="themeService.setPreviewButtons(properties.colorButtons)" [(ngModel)]="color.textColor"
                             title="Choose your color">
                    </div>
                  </div>
                </td>
                <!--               COTÕES-->
                <td>
                  <button class="btn preview btn-{{color.classe}}">Exemplo</button>
                </td>
                <td>
                  <button class="btn preview btn-{{color.classe}} hover">Exemplo</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning m-1" (click)="reseteTheme('colorButtons')">Restaurar cores</button><button
            class="btn btn-secondary m-1"
            *ngIf="verifyDiffNotSaved('colorButtons')"
            (click)="desfazerAlteracoes('colorButtons')"
          >Desfazer alterações</button>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'selected'" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Botões atualmente no sistema Ecoplat</h4>
          </div>
          <div class="card-body d-inline-flex justify-content-between">
            <button class="btn btn-primary">Primário</button>
            <button class="btn btn-secondary">Secundário</button>
            <button class="btn btn-success">Afirmativo</button>
            <button class="btn btn-info">Informação</button>
            <button class="btn btn-warning">Alerta</button>
            <button class="btn btn-danger">Risco</button>
            <button class="btn btn-light">Light</button>
            <button class="btn btn-dark">Dark</button>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'selected'" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Menu lateral</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <subcomponent-fragment-side-menu [image]="entity.arquivo">
                </subcomponent-fragment-side-menu>
              </div>

              <div class="col-8">
                <div class="row">
                  <div class="col-5">
                    <div class="row">
                      <div class="col-12">
                        <p class="card-title f-bold">Cor de fundo do menu</p>
                      </div>
                      <div class="col-3">
                        <label class="form-label">Cor</label>
                        <input type="color" (change)="themeService.setPreviewMenu(properties.sideBarColors)" [(ngModel)]="properties.sideBarColors.background" class="form-control form-control-color" title="Selecione sua cor">
                      </div>
                      <div class="col-9">
                        <label class="form-label">Código hex</label>
                        <input class="form-control" (change)="themeService.setPreviewMenu(properties.sideBarColors)" [(ngModel)]="properties.sideBarColors.background" title="Choose your color">
                      </div>

                      <hr class="mt-1">
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <p class="card-title f-bold">Cor de texto do menu</p>
                      </div>
                      <div class="col-3">
                        <label class="form-label">Cor</label>
                        <input type="color" (change)="themeService.setPreviewMenu(properties.sideBarColors)" [(ngModel)]="properties.sideBarColors.colorText" class="form-control form-control-color" title="Selecione sua cor">
                      </div>
                      <div class="col-9">
                        <label class="form-label">Código hex</label>
                        <input class="form-control" (change)="themeService.setPreviewMenu(properties.sideBarColors)" [(ngModel)]="properties.sideBarColors.colorText" title="Choose your color">
                      </div>

                      <hr class="mt-1">
                    </div>

<!--                    <div class="row">-->
<!--                      <div class="col-12">-->
<!--                        <p class="card-title f-bold">Cor de fundo do item destacado</p>-->
<!--                      </div>-->
<!--                      <div class="col-3">-->
<!--                        <label class="form-label">Cor</label>-->
<!--                        <input type="color" (change)="setPreviewButtonColor()" [(ngModel)]="sideBarColors.backgroundItemSelected" class="form-control form-control-color" title="Selecione sua cor">-->
<!--                      </div>-->
<!--                      <div class="col-9">-->
<!--                        <label class="form-label">Código hex</label>-->
<!--                        <input class="form-control" (change)="setPreviewButtonColor()" [(ngModel)]="sideBarColors.backgroundItemSelected" title="Choose your color">-->
<!--                      </div>-->

<!--                      <hr class="mt-1">-->
<!--                    </div>-->
                  </div>

                  <div class="col-7">
                    <card>
                      <div class="card-header">
                        <h4 class="card-title">Logo</h4>
                      </div>
                      <div class="col-md-12 sm-12 xs-12 border-img">
                        <div
                          class="form-group"
                          style="padding-top: 2.5em"
                        >
                          <img
                            src="{{themeService.getLogo(entity.arquivo)}}"
                            alt
                            class="w-100"
                          >
                        </div>
                        <hr>
                      </div>
                      <div class="btn-img" [hidden]="!entity.id">
                        <button
                          type="button"
                          class="btn btn-success m-1"
                          (click)="imgMotorista.click()"
                        >
                          Escolher
                        </button>
                        <button
                          [disabled]="!this.entity.arquivo?.id"
                          type="button"
                          class="btn btn-danger m-1"
                          (click)="removeImage()"
                        >
                          Remover
                        </button>
                        <input
                          #imgMotorista
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          [(ngModel)]="file"
                          (change)="uploadImg($event)"
                          name="imgMotorista"
                          id="imgMotorista"
                          style="display: none;"
                        >
                      </div>

                      <div class="btn-img" [hidden]="entity.id">
                        <p>Salve para poder escolher a imagem</p>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning m-1" (click)="reseteTheme('sideBarColors')">Restaurar cores</button><button
            class="btn btn-secondary m-1"
            *ngIf="verifyDiffNotSaved('sideBarColors')"
            (click)="desfazerAlteracoes('sideBarColors')"
          >Desfazer alterações</button>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'selected'" class="container-fluid">
    <div class="row">
      <div class="col-6">
        <card>
          <div class="card-header">
            <h4 class="card-title">Paginação das tabelas</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <dg-pagination className="preview"></dg-pagination>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor do texto</p>
                  </div>
                  <div class="col-3">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.colorText" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-9">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.colorText" title="Choose your color">
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor de texto selecionado</p>
                  </div>
                  <div class="col-3">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.colorTextItemSelected" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-9">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.colorTextItemSelected" title="Choose your color">
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor de fundo</p>
                  </div>
                  <div class="col-3">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.background" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-9">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.background" title="Choose your color">
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor de fundo da página selecionada</p>
                  </div>
                  <div class="col-3">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.backgroundItemSelected" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-9">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewPagination(properties.paginationColors)" [(ngModel)]="properties.paginationColors.backgroundItemSelected" title="Choose your color">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning m-1" (click)="reseteTheme('paginationColors')">Restaurar cores</button><button
            class="btn btn-secondary m-1"
            *ngIf="verifyDiffNotSaved('paginationColors')"
            (click)="desfazerAlteracoes('paginationColors')"
          >Desfazer alterações</button>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card>
          <div class="card-header">
            <h4 class="card-title">Tipografia</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p>Esse grupo de estilos se refere aos textos e titulos.</p>
                <p>
                  Os campos "<b class="destaque-claro-preview">destaque claro</b>" e "<b class="destaque-escuro-preview">
                  destaque escuro</b>" são utilizados nos textos para ressaltar informações, links ou
                  destaques de qualquer natureza.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Titulos</p>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.colortitle" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.colortitle" title="Choose your color">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Textos</p>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.colorText" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.colorText" title="Choose your color">
                  </div>
                </div>
              </div>
            </div>

            <hr class="mt-1 mt-2">

            <div class="row">
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor de destaque claro</p>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.destaqueClaro" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.destaqueClaro" title="Choose your color">
                  </div>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="row">
                  <div class="col-12">
                    <p class="card-title f-bold">Cor de destaque escuro</p>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Cor</label>
                    <input type="color" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.destaqueEscuro" class="form-control form-control-color" title="Selecione sua cor">
                  </div>
                  <div class="col-6">
                    <label class="form-label">Código hex</label>
                    <input class="form-control" (change)="themeService.setPreviewTypography(properties.thypograghy)" [(ngModel)]="properties.thypograghy.destaqueEscuro" title="Choose your color">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-warning m-1" (click)="reseteTheme('thypograghy')">Restaurar cores</button>
            <button
              class="btn btn-secondary m-1"
              *ngIf="verifyDiffNotSaved('thypograghy')"
              (click)="desfazerAlteracoes('thypograghy')"
            >Desfazer alterações</button>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'notSelected'" class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Selecione um{{ form.value.type == 'UNIDADE' ? 'O GERADOR' : ' TRANSPORTADOR' }}</h4>
          </div>
        </card>
      </div>
    </div>
  </div>

  <div *ngIf="statusPage == 'loader'" class="container-data">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Carregando</h4>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>


<page-footer>
  <button (click)="voltar()" class="btn btn-secondary">Voltar</button>
  <button (click)="salvar()" class="btn btn-success">Salvar</button>
<!--  <button *ngIf="!!entity?.id" (click)="remove()" class="btn btn-danger">Excluir</button>-->
  <button (click)="replicar()" *ngIf="entity?.id" class="btn btn-info m-1">Replicar</button>
</page-footer>


<modal-replicar #modalReplicar [id]="entity?.id"></modal-replicar>
