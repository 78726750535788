import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ContainerClienteEntity} from "../../_entitys/container-cliente.entity";
import {ConfigDgCrudService} from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../environments/environment";
import {CLIENTE_CONTAINER} from "../../../../../core/config/endpoint-list";

@Injectable({
  providedIn: 'root'
})
export class ContainerClienteService extends AbstractHttpService<ContainerClienteEntity> {

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, CLIENTE_CONTAINER)
  }
}
