import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { DefaultKolkovTextEditor } from "../../../../core/config/default-kolkov-text-editor";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OldModalComponent } from "../../../../shared/ui/modal/modal/modal.component";
import { ClientesEntity } from "../../_clientes/clientes/clientes.entity";
import { BlogService } from "../services/blog.service";
import { BlogEntity } from "../blog.entity";
import { PickListComponent } from "../../../../shared/ui/pick-list/pick-list.component";
import Swal from "sweetalert2";
import { FilesManageService } from "../../../../services/files-manage/files-manage.service";
import { CLIENTE_FIND_FILTER, GRUPO_COMUNICACAO } from "../../../../core/config/endpoint-list";
import { GrupoComunicacaoEntity } from '../../grupo-comunicacao/grupo-comunicacao.entity';
import {verifySizeFiles} from "../../../../helpers/helpers";

@Component({
  selector: 'modal-blog-insert-edit',
  templateUrl: './modal-blog-insert-edit.component.html',
  styleUrls: ['./modal-blog-insert-edit.component.scss']
})
export class ModalBlogInsertEditComponent implements OnInit {

  editorConfig = DefaultKolkovTextEditor;

  @ViewChild('fileUploadBlog') fileUploadBlog!: ElementRef;

  @Output('afterSave') afterSave = new EventEmitter()

  public form = new FormGroup(({
    titulo: new FormControl('', [Validators.required]),
    conteudo: new FormControl('', [Validators.required]),
    publica: new FormControl(''),
    destinatario: new FormControl('', [Validators.required])
  }))
  public listClientes: ClientesEntity[] = [];
  public listGrupos: GrupoComunicacaoEntity[] = [];
  public entity = new BlogEntity()
  public modalTchouted = false;
  public fileInput: File | undefined;
  public fileData: File | undefined;
  public imageSrc: any = '';

  @ViewChild('modalPost') modal!: OldModalComponent
  @ViewChild('pickListClientes') pickListClientes!: PickListComponent
  @ViewChild('pickListGrupos') pickListGrupos!: PickListComponent

  constructor(
    private service: BlogService,
    public files: FilesManageService
  ) {
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(
      () => {
        this.modalTchouted = true;
      }
    )

  }

  selectImg() {
    const fileImge = this.fileUploadBlog.nativeElement;
    fileImge.click()
  }

  changeInputFile(event: Event): void {
    debugger
    if (event?.target) {
      //@ts-ignore
      const file = event?.target.files[0];

      if (file) {
        if (!verifySizeFiles(file)) {
          this.fileInput = undefined;
          return;
        }
        this.fileData = file

        if (!!this.entity.id) {
          this.uploadImg()
          this.afterSave.emit()

        } else {

          const reader = new FileReader();
          reader.onload = e => this.imageSrc = reader.result;

          //@ts-ignore
          reader.readAsDataURL(event?.target.files[0]);
        }
      }
    }
  }

  // uploadImg(event: Event) {
  uploadImg(closeModal = false) {
    if (!this.fileData) return;

    const file: File = this.fileData;

    let data = new FormData();

    data.append('arquivo', file);
    data.append('postId', String(this.entity.id));

    this.service.uploadImage(data).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.entity.imagem = resp.data

        if (closeModal) {
          this._clear();
          Swal.fire({
            icon: 'success',
            title: `${this.entity.id ? "Editado" : "Publicado"} com sucesso`,
            timer: 2000
          }).then()
          this.afterSave.emit()
          this.modal.close()
        }
      }
    })
  }

  ngAfterViewInit(): void {}

  async open(dataPost?: BlogEntity) {
    this.getListClientes()
    await this.service.get(GRUPO_COMUNICACAO, { params: { unpaged: true } }).subscribe(resp => {
      this.listGrupos = resp.data
    })
    if (!!dataPost) {
      // @ts-ignore
      await this.service.findOne(dataPost.id).subscribe(
        resp => {
          //@ts-ignore
          this.entity = resp.data;

          this.form.patchValue({
            ...this.entity
          });

          this.modalTchouted = false;

          if (!!dataPost) this.modal.open();
        }
      )
    }

    if (!dataPost) this.modal.open();
  }

  publicar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      clientes: this.pickListClientes.getList().map((cli: ClientesEntity) => {
        return { id: cli.id, nome: cli.nome }
      }),
      grupos: this.pickListGrupos.getList().map((cli: GrupoComunicacaoEntity) => {
        return { id: cli.id }
      }),
      imagem: (!!this.entity.imagem?.fileName ? this.entity.imagem : undefined)
    }

    this.service.saveOrUpdate(this.entity).subscribe(
      resp => {
        this.entity.id = resp.data.id;

        if (!!this.fileData) {
          this.uploadImg(true);
        } else {
          window.dispatchEvent(new CustomEvent('event-home-get-blog-list', { detail: "BlogEntity" }));
          this._clear();
          Swal.fire({
            icon: 'success',
            title: `${this.entity.id ? "Editado" : "Publicado"} com sucesso`,
            timer: 2000
          }).then()
          this.modal.close()
        }
      }
    )
  }

  close = () => {
    if (this.modalTchouted) {
      Swal.fire({
        icon: 'error',
        title: 'Sair sem salvar?',
        text: 'Todas as alterações feitas serão perdidas',
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this._clear();
          this.modal.close()
        }
      });
      return false;
    }
    this._clear();

    return true;
  }

  private _clear() {
    this.form.reset();
    this.entity = new BlogEntity()
    this.modalTchouted = false;
    this.fileData = undefined;
    this.fileInput = undefined;
    this.imageSrc = undefined;
  }

  getListClientes(data?: any) {
    let endpoint = 'nomes';
    if (!!data?.valueSearch) {
      endpoint = endpoint + '?nome=' + data.valueSearch
    }
    this.service.get(CLIENTE_FIND_FILTER,
      { params: { nome: data?.valueSearch || '', unpaged: true } }
    ).subscribe(
      resp => {
        //@ts-ignore
        this.listClientes = resp.data;
      }
    )
  }
}
