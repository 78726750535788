<nav id="user-header-principal" class="navbar navbar-expand-md">
  <div class="container-fluid">

    <button style="border: none; background: none" class="mr-auto" (click)="sidebarChange()">
      <mat-icon [hidden]="sidebaOpened()">menu</mat-icon>
      <mat-icon [hidden]="!sidebaOpened()">menu_open</mat-icon>
    </button>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-user-minha-coleta" aria-controls="header-user-minha-coleta" aria-expanded="false" aria-label="Toggle navigation">
      <mat-icon>menu</mat-icon>
    </button>

    <ul *ngIf="!production" class="navbar-nav me-auto mb-2 mb-lg-0 h-100">
      <li class="nav-item">
          <a href="/doc" target="_blank" class="nav-link" aria-current="page" style="cursor: pointer;">
            Doc UI/UX
          </a>
      </li>
    </ul>

    <div class="collapse navbar-collapse h-100" id="header-user-minha-coleta">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 h-100">
        <li class="nav-item" check-perfil [perfil]="['CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO']" *ngIf="!session.hasRespostas">
          <span class="nav-link" aria-current="page" style="cursor: pointer;" routerLink="/user/clientes/clientes/{{session.user.cliente_id}}"
            fragment="perguntas-unidade-geradora" matTooltip="clique aqui para finalizar seu cadatro">
            <mat-icon class="material-symbols-outlined fc-red">warning</mat-icon>
            Finalize seu cadastro
          </span>
        </li>
        <li class="nav-item" (click)="openModalSearch()">
          <span class="nav-link" aria-current="page" style="cursor: pointer;">
            <mat-icon>manage_search</mat-icon>
          </span>
        </li>

        <li class="nav-item dropdown" *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO'])">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-target="#dropdown-sobre-as-coletas"
            href="#" role="button" aria-expanded="false">
            <mat-icon>recycling</mat-icon>
            Sobre sua coleta
          </a>
          <ul id="dropdown-sobre-as-coletas" class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" routerLink="/user/historico-coletas">
              <mat-icon>history_toggle_off</mat-icon>
              Histórico das Coletas
            </a></li>
            <li><a class="dropdown-item" routerLink="/user/ranking-taxa-reciclagem">
              <mat-icon>rocket</mat-icon>Ranking taxa de Reciclagem
            </a></li>
            <li><a class="dropdown-item" routerLink="/user/ranking-aterro-zero">
              <mat-icon>rocket</mat-icon>Ranking de Lixo Zero
            </a></li>
          </ul>
        </li>

<!--        <li class="nav-item" check-perfil perfil="CONDOMINIO">-->
<!--          <div class="nav-link meus-pontos">-->
<!--            <div>-->
<!--              <p style="text-align: center; margin: 0">Taxa de Reciclagem</p>-->
<!--              <p style="text-align: center" class="f-bold" >{{!!session.pontuacao || session.pontuacao == 0 ? format(session.pontuacao) : '-&#45;&#45;'}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          >
            <mat-icon>quiz</mat-icon>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li *ngIf="!session.checkPerfil('GALPAO')">
              <span style="cursor:pointer;" class="dropdown-item" (click)="openNewsletter()">
                <mat-icon>receipt_long</mat-icon>
                Link para newsletter
              </span>
            </li>
            <li *ngIf="!session.checkPerfil('GALPAO')">
              <hr class="dropdown-divider">
            </li>
            <li>
              <a style="text-decoration: none" href="https://app.pipefy.com/portals/minhacoletaajuda" target="_blank">
                <span style="cursor:pointer;" class="dropdown-item">
                  <mat-icon>warning</mat-icon>
                  <span>Ajuda</span>
                </span>
              </a>
            </li>
          </ul>

        </li>

        <li class="nav-item">
          <span style="cursor:pointer" (click)="sair()" class="nav-link" aria-current="page">Sair</span>
        </li>
      </ul>
    </div>

  </div>
</nav>
