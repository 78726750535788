import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissoesMainComponent } from './missoes-main/missoes-main.component';
import { MissoesCupomComponent } from './missoes-cupom/missoes-cupom.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RelatorioMissoesComponent } from './subComponents/relatorio-missoes/relatorio-missoes.component';



@NgModule({
  declarations: [
    MissoesMainComponent,
    MissoesCupomComponent,
    RelatorioMissoesComponent
  ],
  exports: [
    RelatorioMissoesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
  ]
})
export class MissoesModule { }
