import {AbstractEntity, DgFilter_autoComplete, DgTableColumn} from "@datagrupo/dg-crud";
import {environment} from 'src/environments/environment';
import {VendasLogisticaClientesEntity} from '../vendas-clientes/vendas-logistica-clientes.entity';
import {VendasLogisticaProdutosEntity} from '../vendas-produtos/vendas-logistica-produtos.entity';
import {autoCompleteClientesOutPut} from "../../../helpers/auto-complete/clientes-autocomplete.helper";
import {AutoCompleteProdutosUotPut} from "../../../helpers/auto-complete/produtos-autocomplete.helper";

const formataData = (dataString: string) => {
  var data = new Date(dataString),
  dia = data.getDate().toString(),
  diaF = (dia.length == 1) ? '0' + dia : dia,
  mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  mesF = (mes.length == 1) ? '0' + mes : mes,
  anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
}

export class VendasLogisticaReversaEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    dataInclusao?: string,
    vendaConfirmada?: boolean,
    vendaClienteclrDTO?: VendasLogisticaClientesEntity,
    vendaProdutosNotaFiscal?: VendasLogisticaProdutosEntity[],
  ) {
    super();
    this.id = id;
    this.dataInclusao = dataInclusao;
    this.vendaConfirmada = vendaConfirmada;
    this.vendaClienteclrDTO = vendaClienteclrDTO;
    this.vendaProdutosNotaFiscal = Array.isArray(vendaProdutosNotaFiscal) ? vendaProdutosNotaFiscal : [];
  }

  @DgTableColumn({columnName: 'DATA DA INCLUSÃO', sort: true, resource: formataData})
  public dataInclusao: number | string | undefined

  @DgFilter_autoComplete({colum: '6'}, {
    getUrl: environment.apiUrl + 'produtos',
    getServerData: "all",
    propertValue: 'nome',
    nameParamFind: 'produtoId',
    propertKey: 'id',
    outputFormat: AutoCompleteProdutosUotPut
  })
  @DgTableColumn({
    columnName: 'PRODUTOS', resource: (prod: string[]) => {
      if (prod.length > 1) {
        return prod.join(', ')
      }
      if(prod[0] == undefined){
        return '--'
      }
      return prod[0];
    }
  })
  public vendaProdutosNotaFiscal: any[] = []


  @DgFilter_autoComplete({colum: '6'}, {
    getUrl: environment.apiUrl + 'venda-logistica-reversa/cliente-credito-logistica-reversa/nomes',
    getServerData: "all",
    propertValue: 'nome',
    nameParamFind: 'clienteId',
    outputFormat: autoCompleteClientesOutPut
  })

  @DgTableColumn({
    columnName: 'CLIENTES', resource: (client: string[]) => {
      if (client.length > 1) {
        return client.join(', ')
      }
      return client[0];
    }
  })
  public vendaClienteclrDTO: VendasLogisticaClientesEntity | undefined;

  @DgTableColumn({columnName: 'CONFIRMADA', resource: (confirmada: boolean, row: any) => {
    return !!row.confirmada ? 'Sim' : 'Não'
  }, sort: true})
  public vendaConfirmada: boolean | undefined

}
