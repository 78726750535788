import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericCrudService} from "../../../../core/services/generic-crud-service/generic-crud.service";
import Swal from "sweetalert2";
import {HttpHelpersService} from "../../../../services/http-helpers/http-helpers.service";
import {SessionService} from "../../../../core/services/session-service/session.service";

@Component({
  selector: 'app-cadastro-morador',
  templateUrl: './cadastro-morador.component.html',
  styleUrls: ['./cadastro-morador.component.scss']
})
export class CadastroMoradorComponent implements OnInit {

  id: number | string | undefined;

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    interesse: new FormControl(''),
    complemento: new FormControl(''),
    contato: new FormControl('')
  })

  constructor(
    public route: ActivatedRoute,
    private service: GenericCrudService,
    private router: Router,
    private helper: HttpHelpersService,
    private session: SessionService
  ) {
    route.params.subscribe(val => {
      if (!val['id']) {
        window.history.back();
        return;
      }

      this.id = val['id'];
    });

    this.form.controls['login'].valueChanges.subscribe(d => {
      this.helper.verifyLoginExist(d, (rest) => {
        this.form.patchValue({ login: rest })
      })
    });
  }

  ngOnInit(): void {
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      Swal.fire({
        icon: 'error',
        title: 'Preencha todos os campos obrigatórios'
      }).then()
      return;
    }

    let form = this.form.value;
    form = {
      ...form,
      interesse: !!form.interesse,
      cliente: {id: this.id}
    }


    this.service.post('users/externo', form).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'Cadastro realizado com sucesso'
      }).then()

      if (!window.localStorage.getItem('accessToken')) {
        window.localStorage.setItem('accessToken', resp.data.accessToken);
        window.localStorage.setItem('refreshToken', resp.data.refreshToken);
        this.router.navigate(['/auth/start-session']).then();
      } else {
        window.location.reload();
      }
    })
  }

  createLogin() {
    const { nome, sobrenome } = this.form.value;

    const callback = (login: string) => {
      this.form.patchValue({ login })
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }
}
