import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { IndicadoresOdsEntity } from '../indicadores-ods.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresOdsService extends AbstractHttpService<IndicadoresOdsEntity> {

  constructor(public conf: ConfigDgCrudService) {
    super(conf, environment.apiUrlMock, 'indicadores-ods');
  }
}


