import { Component, OnInit } from '@angular/core';
import { NewslettersEntity } from '../newsletters.entity';
import { NewslettersService } from '../service/newsletters.service';

@Component({
  selector: 'app-newsletters-main',
  templateUrl: './newsletters-main.component.html',
  styleUrls: ['./newsletters-main.component.scss']
})
export class NewslettersMainComponent implements OnInit {

  public mapEntity = new NewslettersEntity()

  constructor() { }

  ngOnInit(): void {
  }

}
