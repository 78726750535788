<div id="coletas-insert-edit" *ngIf="!!entity">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs default-tabs">
          <li class="nav-item" (click)="view = 'coletas'">
            <h4 class="nav-link card-title" [class.active]="view == 'coletas'">Coletas</h4>
          </li>
          <li class="nav-item" (click)="view = 'mtr'">
            <h4 class="nav-link card-title" [class.active]="view == 'mtr'">MTR</h4>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div [hidden]="view != 'coletas'">
    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <h4 class="card-title">Informações da Rota</h4>
                  </div>
                  <card-header-buttons *ngIf="!session.checkPerfil(['PARCEIRO'])" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <button check-perfil
                            [perfil]="['ADMINISTRADOR', 'CONDOMINIO', 'PARCEIRO', 'CASA', 'EMPRESA', 'EVENTO']"
                            (click)="modal.open()" class="btn btn-success btn-card-header">
                      Alterar data da rota
                    </button>
                  </card-header-buttons>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="row">
                    <div class="col-6 fc-grey f-bold"> Data da Rota:</div>
                    <div class="col-6 fc-grey">{{ ajusteData(entity?.rota?.dataRota) }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="row">
                    <div class="col-6 fc-grey f-bold">Motorista/Catador da coleta:</div>
                    <div class="col-6 fc-grey">{{ entity?.rota?.motorista?.nome }}</div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Informações da Coleta</h4>
            </div>
            <div class="card-body fc-grey">

              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-sm-2">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">Gerador da coleta:</div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">{{ entity?.cliente?.nome }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">Transportador:</div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">{{ entity?.rota?.galpao?.nome}}</div>
                  </div>
                </div>
              </div>

              <div class="row mt-xl-4 mt-lg-3 mt-md-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-sm-2 mt-xs-4">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">Peso total da coleta(ton):</div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">{{entity?.pontuacao?.valor}}</div>
                  </div>
                </div>
                <div class="{{(!!mtr.value || session.checkPerfil('PARCEIRO')) ? 'col-xl-6 col-lg-6 col-md-6' : 'col-xl-3 col-lg-3 col-md-3'}} col-sm-12 mt-sm-2 mt-xs-4">
                  <label class="form-label">Código MTR</label>
                  <input class="form-control" [formControl]="mtr" (change)="changeMtr($event)">
                </div>
                <div *ngIf="(!mtr.value && !session.checkPerfil('PARCEIRO'))" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mt-sm-2">
                  <label class="form-label">Gerar novo MTR</label>
                  <div class="w-100">
                    <button (click)="gerarMTR()" class="btn btn-success">Emitir MTR</button>
                  </div>
                </div>
              </div>

              <div class="row mt-xl-4 mt-lg-3 mt-md-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-sm-2">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">Avaliação do motorista/catador ao cliente:</div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <ratio-star
                        [editar]="!session.checkPerfil('PARCEIRO')"
                        [pontos]="entity?.avaliacaoClienteMotorista?.estrelas"
                        (salve)="alterStar($event, 'cliente')"
                      ></ratio-star>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-sm-2">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">Avaliação do gerador ao
                      motorista/catador:
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <ratio-star
                        [editar]="!session.checkPerfil('PARCEIRO')"
                        [pontos]="entity?.avaliacaoMotoristaCliente?.estrelas"
                        (salve)="alterStar($event, 'motorista')"
                      ></ratio-star>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="container-data">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <h4 class="card-title">Arquivos da Coleta</h4>
                  </div>
                  <card-header-buttons *ngIf="!session.checkPerfil(['PARCEIRO'])" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <input hidden type="file" #inputFileArquivoColeta (change)="uploadFileArquivoColeta($event)"
                           [(ngModel)]="fileArquivoColeta">
                    <button (click)="inputFileArquivoColeta.click()" class="btn btn-success btn-card-header">
                      <mat-icon>add</mat-icon>
                      adicionar
                    </button>
                  </card-header-buttons>
                  <hr>
                </div>
              </div>
            </div>

            <div class="card-body">
              <crud-data-table
                #tableArquivos
                [showDefaultAction]="!session.checkPerfil('PARCEIRO')"
                tableEventId="ColetaArquivosEntity"
                [entityMap]="coletaArquivosEntity"
                [persistentParams]="{coletaId : entity.id}"
              ></crud-data-table>
            </div>

            <div class="d-flex justify-content-center card-footer">
              <dg-pagination
                [dataPagination]="coletaArquivosEntity.configEntityTable.pagination"
                (changePage)="tableArquivos.changePages($event)"
              ></dg-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="container-data" *ngIf="!!entity?.id">
      <card>
        <div class="card-header">
          <h4 class="card-title">Checkin de coletas</h4>
        </div>
        <div class="card-body" style="padding-top: 0">
          <div class="row" style="margin-top: 1rem; margin-bottom: 1rem">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label f-bold">Data da Coleta</label>
              <p>{{formatData()}}</p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="status" class="form-label">STATUS DO CHECK-IN</label>
              <select
                class="form-select"
                id="status"
                [(ngModel)]="statusCheckin"
                [disabled]="session.checkPerfil('PARCEIRO')"
                (ngModelChange)="alteraStatusCheckin($event)"
              >
                <option disabled value="" selected>Selecione</option>
                <option value="ABERTO">Em Aberto</option>
                <option value="FINALIZADO_PENDENCIA">Finalizado com pendência</option>
                <option value="FINALIZADO">Finalizado</option>
              </select>
            </div>
          </div>

          <div
            class="row"
            *ngFor="let container of listCheckin; let i = index"
            [class.pt-5]="(i > 0)"
          >
            <div class="col-12">
              <card-checkin-container
                [checkinContainer]="container"
                [coletaId]="entity?.id"
                (atualizarPontuacao)="getPontuacao()"
              ></card-checkin-container>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>

  <div [hidden]="view != 'mtr'">
    <div class="container-data">
      <div class="row">
        <card>
          <div class="card-header">
            <h4 class="card-title">Logs de emissão do MTR</h4>
          </div>
          <div class="card-body card-mtr" style="padding: 0">
            <table class="mt-3 crud-table">
              <thead>
              <tr>
                <th>Código MTR</th>
                <th>Id da coleta</th>
                <th>Cliente</th>
                <th>Retorno</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of tableMTR.controls.data.dataSource">
                <td>{{item.mtrCodigo}}</td>
                <td>{{item.coletaId}}</td>
                <td>{{item.clienteId}}</td>
                <td>
                  <span
                    [style.color]="item?.status == 'ERRO' ? 'red' : 'inhiet'"
                  >
                    {{helpersTableMTR.tdResponse(item)}}
                  </span>
                </td>
                <td>{{item.status}}</td>
                <td class="table-colum-fixed-end mat-column-actions">
                  <div class="dropdown">
                    <button class="btn btn-success button-menu" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li
                        *ngIf="helpersTableMTR.motivoErro.permission(item)"
                        (click)="helpersTableMTR.motivoErro.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.motivoErro.name}}</span>
                      </li>
                      <li
                        (click)="helpersTableMTR.cancelar.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.cancelar.name}}</span>
                      </li>
                      <li
                        *ngIf="helpersTableMTR.reemitir.permission(item)"
                        (click)="helpersTableMTR.reemitir.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.reemitir.name}}</span>
                      </li>
                      <li
                        *ngIf="helpersTableMTR.anexar.permission(item)"
                        (click)="helpersTableMTR.anexar.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.anexar.name}}</span>
                      </li>
                      <li>
                        <a href="/user/operacional/coletas/coletas/{{item.coletaId}}" target="_blank" class="dropdown-item">{{helpersTableMTR.verColeta.name}}</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr *ngIf="tableMTR.controls.data.dataSource.length == 0">
                <td colspan="100%">
                  Nenhum registro encontrado
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center card-footer">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="tableMTR"></dg-paginator>
          </div>
        </card>
      </div>
    </div>
  </div>

</div>

<page-footer>
  <button crud-voltar class="btn btn-secondary">Voltar</button>
</page-footer>

<modal-alterar-rota
  #modal
  [galpaoId]="entity?.rota?.galpao?.id"
  [rotaId]="entity?.id"
  [clienteId]="entity?.cliente?.id"
  (alterarRotas)="alterarRota($event)"
>
</modal-alterar-rota>
