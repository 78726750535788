import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit, HttpClient } from '@datagrupo/dg-crud';
import { FuncionarioEntity } from '../funcionario.entity';
import { FuncionarioService } from '../service/funcionario.service';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {HttpHelpersService} from "../../../../services/http-helpers/http-helpers.service";

@Component({
  selector: 'app-funcionario-insert-edit',
  templateUrl: './funcionario-insert-edit.component.html',
  styleUrls: ['./funcionario-insert-edit.component.scss'],
})
export class FuncionarioInsertEditComponent
  extends AbstractInsertEdit<FuncionarioEntity>
  implements OnInit
{
  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
  });

  public entityFuncionario = new FuncionarioEntity();

  constructor(
    public servic: FuncionarioService,
    public conf: ConfigDgCrudService,
    public http: HttpClient,
    private helper: HttpHelpersService
  ) {
    super(conf, servic);
    // this.entityFuncionario.setPagination(null);

    this.crudConfig.subscribeEntityAfterSave = false;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.form.controls['login'].valueChanges.subscribe(d => {
      if (d == this.entity?.login) return

      this.helper.verifyLoginExist(d, (rest) => {
        this.form.patchValue({login: rest})
      })
    });
  }

  initNewEntity(): void {
    this.entity = new FuncionarioEntity();
  }

  createLogin() {
    if (!!this.entity.login) return
    const {nome, sobrenome} = this.form.value;

    const callback = (login: string) => {
      this.form.patchValue({login})
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }

  override afterFetchEntity(): void {

    this.form.patchValue({
      // @ts-ignore
      ...this.entity,
    });
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
    };

    return true;
  }
}
