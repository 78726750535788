import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OldModalComponent } from "../../../../../../shared/ui/modal/modal/modal.component";
import { ContainerClienteService } from "../../../container-cliente/service/container-cliente.service";
import { ContainerService } from "../../../../container/service/container.service";
import { ContainerEntity } from "../../../../container/container.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContainerClienteEntity } from "../../../_entitys/container-cliente.entity";
import { GalpaoTipoLixoEntity } from "../../../../_galpao/galpao/galpao-tipo-lixo.entity";
import { UnidadeMedidaEntity } from "../../../../unidade-medida/unidade-medida.entity";
import { GalpaoEntity } from "../../../../_galpao/galpao/galpao.entity";
import { HttpClient, clearEntity } from "@datagrupo/dg-crud";
import { CLIENTE_CONTAINER } from "../../../../../../core/config/endpoint-list";
import { TratamentoEntity } from "../../../../tratamento/tratamento.entity";
import { TipoLixoEntity } from "../../../../tipo-lixo/tipo-lixo.entity";
import { SessionService } from "../../../../../../core/services/session-service/session.service";
import { environment } from 'src/environments/environment';
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { IntegracaoMtrEntity } from '../../integracao-mtr.entity';

@Component({
  selector: 'subComponent-container-cliente-modal',
  templateUrl: './container-cliente-modal.component.html',
  styleUrls: ['./container-cliente-modal.component.scss']
})
export class ContainerClienteModalComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent

  @Input() idCliente: number | string | undefined;

  public entity = new ContainerClienteEntity();

  // public diasColeta: string[] = [];
  public disabled = false;
  public tableVisible: string = 'relacao-contrato';


  public form = new FormGroup({
    id: new FormControl(''),
    identificacao: new FormControl('', [Validators.required]),
    galpao: new FormControl('', [Validators.required]),
    container: new FormControl({ value: '', disabled: true }, [Validators.required]),
    tipoLixo: new FormControl({ value: '', disabled: true }, [Validators.required]),
    tratamento: new FormControl('', [Validators.required]),
    unidadeMedidaPadrao: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    industria: new FormControl('', [Validators.required]),
    listUnidadeMedida: new FormControl('', [Validators.required]),
    dono: new FormControl(''),
    // classeResiduo: new FormControl('', [Validators.required]),
    // densidade: new FormControl(''),
    // estadoFisico: new FormControl('', [Validators.required]),
    usarArmazenadorTemporario: new FormControl(false),
    armazenadorTemporario: new FormControl('', [Validators.required]),
  })
  public formMTR = new FormGroup({
    classeResiduo: new FormControl('', [Validators.required]),
    densidade: new FormControl('', [Validators.required]),
    estadoFisico: new FormControl('', [Validators.required]),
  })

  public listEstado: any[] = [];
  public listClasses: any[] = [];
  public listContainers: ContainerEntity[] = [];
  public listTipoLixo: TipoLixoEntity[] = [];
  public listUnidadeMedida: UnidadeMedidaEntity[] = [];
  public listTratamento: UnidadeMedidaEntity[] = [];

  constructor(
    public service: ContainerClienteService,
    public session: SessionService,
    public http: HttpClient
  ) {
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['densidade'])
    )
    this.form.controls['usarArmazenadorTemporario'].valueChanges.subscribe(val => {
      this.form.controls['armazenadorTemporario'][!!val ? 'enable' : 'disable']()
    })
  }

  ngOnInit(): void {
  }

  async buscarRelacoes(ev: GalpaoEntity) {
    if (!!ev?.id) {
      await this.service.get('galpao-containers/', { params: { galpaoId: ev.id, ativo: true, unpaged: true } }).subscribe(
        resp => {
          this.listContainers = resp.data;
        }
      )
      await this.service.get('tipo-lixo-galpao/' + ev.id, { params: { unpaged: true } }).subscribe(
        resp => {
          this.listTipoLixo = resp.data
        }
      )

      this.entity['galpao'] = ev;
      if (!this.disabled) {
        this.form.controls['container'].enable();
        this.form.controls['tipoLixo'].enable();
      }

    } else {
      this.listContainers = [];
      this.listTipoLixo = [];
      this.form.controls['container'].disable();
      this.form.controls['tipoLixo'].disable();
    }
  }

  async open(val?: ContainerClienteEntity) {

    if (!!val) {
      this.disabled = true;
      await this.service.get(CLIENTE_CONTAINER + '/' + val?.id, { params: { unpaged: true } }).subscribe(
        resp => {
          this.entity = {
            ...this.entity,
            ...resp.data
          }

          this.buscarRelacoes(resp.data.galpao);

          let listUnidadeMedida = '';
          this.disabled = true;

          if (Array.isArray(resp.data.listUnidadeMedida)) {
            listUnidadeMedida = resp.data.listUnidadeMedida.map((unidade: UnidadeMedidaEntity) => unidade.nome).join(', ')
          }

          this.form.patchValue({
            id: resp.data.id,
            container: resp.data.container?.nome,
            identificacao: resp.data.identificacao,
            galpao: resp.data.galpao?.nome,
            tipoLixo: resp.data.tipoLixoContainer?.nome,
            tratamento: resp.data.tratamento?.nome,
            unidadeMedidaPadrao: resp.data.unidadeMedidaPadrao?.nome,
            listUnidadeMedida: listUnidadeMedida,
            quantidade: resp.data.quantidade,
            industria: resp.data.industria?.nome,
            dono: resp.data.dono,
            densidade: InputHelpers.initInputDecimal(String(this.entity.integracaoMtr?.densidade)),
            classeResiduo: this.entity.integracaoMtr?.classeResiduo?.id,
            estadoFisico: this.entity.integracaoMtr?.estadoFisico?.id
          })

          if (this.entity.integracaoMtr?.densidade) {
            this.form.controls['densidade'].disable()
          }
          this.form.controls['container'].disable()
          this.form.controls['galpao'].disable()
          this.form.controls['tipoLixo'].disable()
          this.form.controls['tratamento'].disable()
          this.form.controls['unidadeMedidaPadrao'].disable()
          this.form.controls['listUnidadeMedida'].disable()
          this.form.controls['quantidade'].disable()
          this.form.controls['classeResiduo'].disable()
          this.form.controls['estadoFisico'].disable()
          // this.form.controls['industria'].disable()

          if (!this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])) {
            this.form.controls['industria'].disable()
          }
        }
      )

    } else {
      if (this.session.checkPerfil(['EVENTO', 'CONDOMINIO', 'CASA', 'EVENTO'])) {
        this.form.patchValue({ dono: 'CLIENTE' })
      } else if (this.session.checkPerfil(['GALPAO'])) {
        this.form.patchValue({ dono: 'GALPAO' })
      }

      await this.service.get('tratamento', { params: { unpaged: true, sort: 'nome,asc' } }).subscribe(
        resp => {
          if (!this.disabled) {
            this.listTratamento = (resp.data || []).sort((a: TratamentoEntity, b: TratamentoEntity) => {
              if ((a.nome || '').toUpperCase() > (b.nome || '').toUpperCase()) {
                return 1;
              }
              if ((a.nome || '').toUpperCase() < (b.nome || '').toUpperCase()) {
                return -1;
              }
              // a must be equal to b
              return 0;
            })
          }
        }
      )

      await this.service.get('unidade-medida', { params: { unpaged: true } }).subscribe(
        resp => {
          if (!this.disabled) {
            this.listUnidadeMedida = resp.data
          }
        }
      )
    }

    this.http.get(`${environment.apiUrl}estados-fisicos`).subscribe(
      (resp: any) => {
        this.listEstado = resp.data;
      }
    )

    this.http.get(`${environment.apiUrl}classes-residuos`).subscribe(
      (resp: any) => {
        this.listClasses = resp.data;
      }
    )

    this.modal.open()
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.idCliente) return;

    if (!!this.form.value.id) {
      this.update();
    }

    const {
      id,
      galpao,
      industria,
      container,
      tipoLixo,
      unidadeMedidaPadrao,
      listUnidadeMedida,
      tratamento,
      classeResiduo,
      estadoFisico,
      densidade,
      ...formV
    } = this.form.value;

    this.entity['clienteId'] = this.idCliente;

    // relacionando container
    const indexContainer = this.listContainers.findIndex(val => val.id == container);
    if (indexContainer == -1) {
      return
    }
    this.entity['container'] = <ContainerEntity>{
      id: this.listContainers[indexContainer].id,
      nome: this.listContainers[indexContainer].nome
    }

    // relacionando tipoLixoContainer
    const indexTipoLixo = this.listTipoLixo.findIndex(val => val.id == tipoLixo);
    if (indexTipoLixo == -1) {
      return
    }
    // @ts-ignore
    this.entity['tipoLixoContainer'] = <GalpaoTipoLixoEntity>{
      id: this.listTipoLixo[indexTipoLixo].id
    }

    this.entity.listUnidadeMedida = <UnidadeMedidaEntity[]>listUnidadeMedida?.map(
      (unidade: number) => {
        return { id: unidade }
      })

    this.entity.unidadeMedidaPadrao = <UnidadeMedidaEntity>{ id: unidadeMedidaPadrao }

    this.entity.tratamento = <TratamentoEntity>{ id: tratamento }

    this.entity = {
      ...this.entity,
      integracaoMtr: {
        classeResiduo: {
          id: classeResiduo,
        },
        estadoFisico: {
          id: estadoFisico
        },
        densidade: Number(InputHelpers.ajusteMoneyBrToEua(densidade))
      },
      ...formV
    }

    this.saveOrUpdate();
  }

  update() {
    const {
      id,
      identificacao,
      industria,
      dono,
      estadoFisico,
      classeResiduo,
      densidade,
      ...formV
    } = this.form.value;

    this.entity = {
      ...this.entity,
      identificacao,
      diasColeta: this.entity.diasColeta,
      dono,
      integracaoMtr: <IntegracaoMtrEntity>({
        ...this.entity.integracaoMtr,
        densidade: !this.form.controls['densidade'].disabled ? Number(InputHelpers.ajusteMoneyBrToEua(densidade)) : (this.entity.integracaoMtr?.densidade)
      }),
    }

    this.saveOrUpdate();
  }

  saveOrUpdate() {
    this.service.saveOrUpdate(clearEntity(this.entity)).subscribe(
      resp => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'ContainerClienteEntity' }));
        this.modal.close();
      }
    )
  }

  beforeClose = () => {
    this.disabled = false;
    this.form.reset({
      id: '',
      galpao: '',
      container: '',
      identificacao: '',
      tipoLixo: '',
      tratamento: '',
      unidadeMedidaPadrao: '',
      dono: '',
      classeResiduo: [],
      estadoFisico: [],
      densidade: ''
    });
    this.formMTR.reset();
    this.form.controls['container'].enable()
    this.form.controls['identificacao'].enable()
    this.form.controls['galpao'].enable()
    this.form.controls['tipoLixo'].enable()
    this.form.controls['tratamento'].enable()
    this.form.controls['unidadeMedidaPadrao'].enable()
    this.form.controls['listUnidadeMedida'].enable()
    this.form.controls['quantidade'].enable()
    this.form.controls['industria'].enable()
    this.form.controls['dono'].enable()
    this.form.controls['classeResiduo'].enable()
    this.form.controls['estadoFisico'].enable()
    this.form.controls['densidade'].enable()
    this.listContainers = [];
    this.listTipoLixo = [];
    this.entity = new ContainerClienteEntity();
    this.tableVisible = 'relacao-contrato';
  }

  verifyCheckBox(valor: string): boolean {
    const index = (this.entity?.diasColeta || []).findIndex(arrayDia => arrayDia == valor);

    return index != -1;
  }

  verifyDisable(): boolean {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      return false;
    }

    // if (this.disabled) return true;

    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      return this.form.value.dono != 'CLIENTE'
    }

    if (this.session.checkPerfil(['GALPAO'])) {
      return this.form.value.dono != 'GALPAO'
    }

    return false
  }

  editDiasSemana(valor: string) {
    const index = (this.entity?.diasColeta || []).findIndex(arrayDia => arrayDia == valor);
    if (index == -1) {
      this.entity.diasColeta.push(valor)
      return;
    }
    this.entity.diasColeta.splice(index, 1);
  }
}
