import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JornadaEducacaoAmbientalMainComponent } from './jornada-educacao-ambiental-main/jornada-educacao-ambiental-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { JornadaCupomComponent } from './jornada-cupom/jornada-cupom.component';



@NgModule({
  declarations: [
    JornadaEducacaoAmbientalMainComponent,
    JornadaCupomComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    UiModule,
    DgCrudModule,
    ReactiveFormsModule,
    MatTooltipModule,
    AngularEditorModule
  ]
})
export class JornadaEducacaoAmbientalModule { }
