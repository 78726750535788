import {ColetasContainerEntity} from "./coletas-container.entity";
import {ColetasCheckinContainerEntity} from "./coletas-checkin-container.entity";

export class CheckinEntity {

  constructor(
    id?: number,
    status?: string,
    dataColetada?: string,
    checkinContainer?: ColetasCheckinContainerEntity[],
  ) {
    this.id = id;
    this.status = !!status ? status : '';
    this.dataColetada = dataColetada;
    this.checkinContainer = Array.isArray(checkinContainer) ? checkinContainer : [];
  }

  id: number | undefined;
  status: string = '';
  dataColetada: string | undefined;
  checkinContainer: ColetasCheckinContainerEntity[] | undefined;
}
