import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {ColetasService} from "../services/coletas.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {Router} from "@angular/router";
import {HttpService} from "../../../../../services/http-service/http.service";
import {environment} from "../../../../../../environments/environment";
import {RotasEntity} from "../../../rotas/rotas.entity";
import {ColetasEntity} from "../coletas.entity";
import {ClientesEntity} from "../../../_clientes/clientes/clientes.entity";
import Swal from "sweetalert2";
import {Enderecos} from "../../../../../shared/entitys/enderecos";


interface ColetaPendenteEntity {
  id?: number | string,
  coletaPendenteId?: string | number | undefined,
  clienteId?: string | number | undefined,
  clienteNome?: string | undefined,
  clienteEnderecos?: Enderecos | undefined,
  checkinContainerPendente?: any
  dataColeta?: any
}

@Component({
  selector: 'app-coletas-main2',
  templateUrl: './coletas-main2.component.html',
  styleUrls: ['./coletas-main2.component.scss']
})
export class ColetasMain2Component implements OnInit, OnDestroy {

  public tableVisible: 'coletas' | 'coletas-pendentes' | 'coletas-canceladas' | 'mtr' = 'coletas';

  filters = {
    coletas: {
      dataInicial: new FormControl(''),
      dataFinal: new FormControl(''),
      cliente: new FormControl(''),
      motorista: new FormControl(''),
    }
  }

  filtersList = {
    coletas: {
      clientes: [],
      motorista: [],
    }
  }

  tableColetas: CdkDynamicTable.tableClass;
  tableColetasPendentes!: CdkDynamicTable.tableClass;
  tableMTR!: CdkDynamicTable.tableClass;
  tableColetasCanceladas!: CdkDynamicTable.tableClass;

  constructor(
    public service: ColetasService,
    public session: SessionService,
    public router: Router,
    private createTable: CdkDynamicTableService,
    private http: HttpService
  ) {
    this.tableColetas = this.createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'coletas',
        params: {
          isCancelada: false
        }
      },
      columns: [
        {name: 'id', headerName: 'ID rota'},
        {
          name: 'dataRota', headerName: 'Data Coleta', resource: (cel: RotasEntity, row: ColetasEntity) => {
            return !!row.rota?.dataRota ? row.rota.dataRota.split('-').reverse().join('/') : '--'
          }
        },
        {name: 'cliente', headerName: 'Cliente', resource: (cel: ClientesEntity) => !!cel?.nome ? cel.nome : '--'},
        {
          name: 'rotaMotorista', headerName: 'Motorista/Catador', resource: (cel: any, row: ColetasEntity) => {
            return !!row?.rota?.motorista?.nome ? row.rota.motorista.nome : '--'
          }
        },
        {
          name: 'rotaGalpao', headerName: 'Transportador', resource: (cel: any, row: ColetasEntity) => {
            return !!row?.rota?.galpao?.nome ? row.rota.galpao.nome : '--'
          }
        },
        {
          name: 'status', headerName: 'Status', resource: (cel: string, row: ColetasEntity) => {
            return !!row?.status ? row.status : '--'
          }
        },
      ],
      actions: {
        adit: {
          name: 'Editar',
          action: (val: ColetasEntity) => {
            this.router.navigate(['user/operacional/coletas/coletas/' + val.id]).then()
          },
          permission: this.session.checkProfile(['SINDICO', 'CONSELHO']) || this.session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'PARCEIRO'])
        },
        remove: {
          name: 'Excluir',
          action: (val: ColetasEntity) => {
            console.log('val', val)
            Swal.fire({
              icon: 'warning',
              title: "Excluir registro",
              text: 'Deseja excluir este registro? Esta ação é permanente.',
              showCancelButton: true,
              confirmButtonText: 'Excluir',
              confirmButtonColor: 'red',
              cancelButtonColor: 'blue',
              cancelButtonText: 'Cancelar'
            }).then(confirm => {
              if (confirm.isConfirmed) {
                this.tableColetas.find()
                Swal.fire({
                  title: 'Deletado com sucesso',
                  text: 'Esse registro foi deletado com sucesso',
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false
                }).then()
              }
            })
          },
          permission: (val: ColetasEntity) => {
            return (val.status == 'ABERTO' || !val.status) && !this.session.checkPerfil(['GALPAO', 'PARCEIRO']);
          }
        },
      },
      filters: {
        reactive: false,
        group: 'coletas'
      }
    })

    if (session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])) {
      this.tableColetasPendentes = this.createTable.create('request', {
        apiData: {
          path: environment.apiUrl,
          context: 'coletas-pendentes',
          params: {
            isCancelada: true
          }
        },
        columns: [
          {
            name: 'dataColeta', headerName: 'Data da Coleta', resource: (val: any, row: ColetaPendenteEntity) => {
              return !!val ? val.split('-').reverse().join('/') : '--';
            }
          },
          {
            name: 'checkinContainerPendente',
            headerName: 'Nome do Recipiente',
            resource: (val: any, row: ColetaPendenteEntity) => {
              const result = row?.checkinContainerPendente || [];
              return result.map((c: any) => {
                if (c?.clienteContainer) {
                  if (c.clienteContainer?.containerNome) return c.clienteContainer.containerNome
                }

                return null;
              }).filter((c: string) => !!c).join(', ')
            }
          },
          {
            name: 'identificacao', headerName: 'Identificação', resource: (val: any, row: ColetaPendenteEntity) => {
              const result = row?.checkinContainerPendente || [];
              return result.map((c: any) => {
                if (c?.clienteContainer?.identificacao) return c.clienteContainer.identificacao;

                return null;
              }).filter((c: string) => !!c).join(', ')
            }
          },
          {
            name: 'tipoLixo', headerName: 'Tipo do resíduo', resource: (val: any, row: ColetaPendenteEntity) => {
              const result = row?.checkinContainerPendente || [];
              return result.map((c: any) => {
                if (c?.clienteContainer?.tipoLixoNome) return c.clienteContainer.tipoLixoNome;

                return null;
              }).filter((c: string) => !!c).join(', ')
            }
          },
          {
            name: 'clienteNome', headerName: 'Cliente', resource: (val: any) => {
              return val || '--'
            }
          },
          {
            name: 'coletaPendenteId',
            headerName: 'Coleta pendente id',
            resource: (val: any, row: ColetaPendenteEntity) => {
              return !!val ? val : '--';
            }
          },
        ],
        actions: {
          criarColeta: {
            name: 'Criar rota para esse dia',
            action: (val: ColetaPendenteEntity) => {
              this.router.navigate(
                ['/user/galpao/rotas/novo_pendente'], {queryParams: {data: val.dataColeta}}
              ).then()
            }
          }
        },
        filters: {
          reactive: false,
          group: 'coletas-pendentes'
        }
      })
      this.tableColetasCanceladas = this.createTable.create('request', {
        apiData: {
          path: environment.apiUrl,
          context: 'coletas',
          params: {
            isCancelada: true
          }
        },
        columns: [
          {name: 'id', headerName: 'ID rota'},
          {
            name: 'dataRota', headerName: 'Data Coleta', resource: (cel: RotasEntity, row: ColetasEntity) => {
              return !!row.rota?.dataRota ? row.rota.dataRota.split('-').reverse().join('/') : '--'
            }
          },
          {name: 'cliente', headerName: 'Cliente', resource: (cel: ClientesEntity) => !!cel?.nome ? cel.nome : '--'},
          {
            name: 'rotaMotorista', headerName: 'Motorista/Catador', resource: (cel: any, row: ColetasEntity) => {
              return !!row?.rota?.motorista?.nome ? row.rota.motorista.nome : '--'
            }
          },
          {
            name: 'rotaGalpao', headerName: 'Transportador', resource: (cel: any, row: ColetasEntity) => {
              return !!row?.rota?.galpao?.nome ? row.rota.galpao.nome : '--'
            }
          },
          {
            name: 'status', headerName: 'Status', resource: (cel: string, row: ColetasEntity) => {
              return !!row?.status ? row.status : '--'
            }
          },
        ],
        actions: {
          adit: {
            name: 'Editar',
            action: (val: ColetasEntity) => {
              this.router.navigate(['user/operacional/coletas/coletas/' + val.id]).then()
            },
            permission: this.session.checkProfile(['SINDICO', 'CONSELHO']) || this.session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'PARCEIRO'])
          },
          remove: {
            name: 'Excluir',
            action: (val: ColetasEntity) => {
              console.log('val', val)
              Swal.fire({
                icon: 'warning',
                title: "Excluir registro",
                text: 'Deseja excluir este registro? Esta ação é permanente.',
                showCancelButton: true,
                confirmButtonText: 'Excluir',
                confirmButtonColor: 'red',
                cancelButtonColor: 'blue',
                cancelButtonText: 'Cancelar'
              }).then(confirm => {
                if (confirm.isConfirmed) {
                  this.tableColetas.find()
                  Swal.fire({
                    title: 'Deletado com sucesso',
                    text: 'Esse registro foi deletado com sucesso',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                  }).then()
                }
              })
            },
            permission: (val: ColetasEntity) => {
              return (val.status == 'ABERTO' || !val.status) && !this.session.checkPerfil(['GALPAO', 'PARCEIRO']);
            }
          },
        },
        filters: {
          reactive: false,
          group: 'coletas-canceladas'
        }
      })
      this.tableMTR = this.createTable.create('request', {
        apiData: {
          path: environment.apiMTR,
          context: 'mtr-manager/mtr/history'
        },
        columns: [
          {name: 'mtrCodigo', headerName: 'Código MTR'},
          {name: 'coletaId', headerName: 'Id da coleta'},
          {name: 'clienteId', headerName: 'Cliente'},
          {name: 'responseBody', headerName: 'Retorno'},
          {name: 'descricaoErro', headerName: 'Erro'},
          {name: 'status', headerName: 'Status'},
        ]
      })
    }

    if (!session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])) {
      this.http.get('clientes/nomes').subscribe(resp => this.filtersList.coletas.clientes = resp?.data || [])
    }
    this.http.get('motoristas').subscribe(resp => this.filtersList.coletas.motorista = resp?.data || [])
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tableColetas?.destroy()
    this.tableColetasPendentes?.destroy()
    this.tableMTR?.destroy()
    this.tableColetasCanceladas?.destroy()
  }


}
