import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlContainer, FormGroupDirective} from "@angular/forms";
import {HttpOldService} from "../../../core/services/http/http-old.service";
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ElementHtmlAutocomplete} from "./helpers/element-html.autocomplete";
import { debug } from 'console';

@Component({
  selector: 'input-auto-complete',
  templateUrl: './input-auto-complete.component.html',
  styleUrls: ['./input-auto-complete.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputAutoCompleteComponent implements OnInit, OnChanges {

  public elementsClass = new ElementHtmlAutocomplete(this);

  @Input() controlName: string = '';
  @Input() placeholder: string = '';
  @Input() propertValue: string | string[] = '';
  // @Input() propertKey: string | string[] = '';
  @Input() dataList: string | undefined = '';
  @Input() baseValue: any;
  @Input() service?: AbstractHttpService<any> | undefined;
  @Input() urlServer: string = '';
  @Input() multSelect: boolean = false;
  @Input() ignoreThrow: boolean = true;
  @Input() zIndex: number | string = '1001';
  @Input() positionY: 'top' | 'bottom' | undefined;
  @Input() params: { [key: string]: any } | undefined;
  @Input() disabled: boolean = false;

  public showList = false;
  public uniqId = '';

  @Output('outValue') outValu = new EventEmitter<any>();
  @Output('change') inputChange = new EventEmitter<any>();

  public list: any = [];

  public selectedValue: any;

  constructor(private controlContainer: ControlContainer, private http: HttpOldService) {
    this.uniqId = this.elementsClass.createUniqId();
  }

  ngOnInit(): void {
    setTimeout(() => {
    }, 10);
    if (!!this.baseValue) {
      const index = this.list.push(this.baseValue) - 1;
      this.selectEvent(index)
      this.onChangeSearch(this._getPropertValue(index))
    }

    addEventListener("scroll", () => {
      if (this.showList) {
        this.elementsClass.closeSelectList();
      }
    });
    addEventListener("resize", () => {
      if (this.showList) {
        this.elementsClass.closeSelectList();
      }
    });

    this.disableInput(this.disabled);
  }

  _autoComleteKeyUp() {}

  selectEvent(index: number) {
    // this.fakeValue.patchValue(this._getPropertValue(index));
    this.controlContainer.control?.patchValue({
      [this.controlName]: this._getPropertValue(index)
    });
    this.selectedValue = this.list[index]
    this.outValu.emit(this.list[index])
  }

  onChangeSearch(val?: string) {
    const nome = !!val ? val : ''
    this.http.get(this.urlServer, { params: { nome, ...(this.params || {}) } }).subscribe(
      resp => {
        // @ts-ignore
        this.list = resp.data
      }
    );
    this.inputChange.emit(val)
  }

  focusIn(val?: string) {
    const nome = !!val ? val : ''
    if (this.list.length <= 0) {
      this.http.get(this.urlServer, { params: { nome, ...(this.params || {}) } }).subscribe(
        resp => {
          // @ts-ignore
          this.list = resp.data
        }
      )
    }
  }

  _getPropertValue(i: number): string {
    if (typeof this.list[i] === 'string') {
      return this.list[i]
    }


    if (!!this.propertValue) {
      if (typeof this.propertValue === 'string') {
        if (this.propertValue in this.list[i]) {
          return this.list[i][this.propertValue]
        } else {
          // console.log(this.propertValue, this.list[i])
          if (!this.ignoreThrow) throw 'propert de autocompleteComponent não existe dentro do objeto de ' + this.controlName + ' _getPropertValue';
        }
      } else if (Array.isArray(this.propertValue)) {
        return this._getValueForArrayLevel(this.list[i], 'propertValue')
      }


    }


    return this.list[i]
  }

  // _getPropertKey(i: number): string{
  //   if (typeof this.list[i] === 'string') {
  //     return this.list[i]
  //   }
  //
  //
  //   if (!!this.propertKey) {
  //     if (typeof this.propertKey === 'string') {
  //       if (this.propertKey in this.list[i]) {
  //         return this.list[i][this.propertKey]
  //       } else {
  //         throw 'propert de autocompleteComponent não existe dentro do objeto' + this.controlName;
  //       }
  //     }
  //     else if (Array.isArray(this.propertKey)) {
  //       return this._getValueForArrayLevel(this.list[i], 'propertKey')
  //     }
  //   }
  //
  //   return this.list[i]
  // }

  _getValueForArrayLevel(objSelected: object, typeData: 'propertValue' | 'propertKey'): string {
    let propertArray: string[] = [];

    // if (typeData === 'propertValue') {
    if (typeof this.propertValue === 'string') {
      throw 'propert de autocompleteComponent não é um array' + this.controlName + ' _getValueForArrayLevel';
    }
    propertArray = this.propertValue;
    // } else {
    //   if (typeof this.propertKey === 'string') throw 'propert de autocompleteComponent não é um array' + this.controlName;
    //   propertArray = this.propertKey;
    // }

    let result: any = objSelected;

    propertArray.map((nivelArray: string) => {
      if (nivelArray in objSelected) {
        // @ts-ignore
        result = nivelArray[nivelArray];
      }
    })

    return result;
  }

  verify(): boolean {
    // @ts-ignore
    if (this.controlContainer.control.controls[this.controlName]?.touched) {
      // @ts-ignore
      return this.controlContainer.control.controls[this.controlName]?.invalid
    }
    return false
  }

  click() {
    if (this.showList) return;
    this.elementsClass.openSelectList();
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('disable' in changes) {
      this.disableInput(changes['disable'].currentValue);
    }
  }

  disableInput(disable: boolean){
    if (!!disable) {
      // @ts-ignore
      this.controlContainer.control.controls[this.controlName].disable()
    } else {
      // @ts-ignore
      this.controlContainer.control.controls[this.controlName].enable()
    }
  }
}

