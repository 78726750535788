import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { HoldingCadastroEntity } from '../../cadastro-holding.entity';
import Swal from 'sweetalert2';
import { HttpClient } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';
import { HttpHelpersService } from 'src/app/services/http-helpers/http-helpers.service';

@Component({
  selector: 'app-cadastro-holding',
  templateUrl: './cadastro-holding.component.html',
  styleUrls: ['./cadastro-holding.component.scss']
})
export class CadastroHoldingComponent implements OnInit {

  public idHolding: number | undefined;

  public form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
  })

  @Input('parceiroId') parceiroId: number | string | undefined
  @Input('parceiroNome') parceiroNome: string | undefined

  @ViewChild('modalCadastroHolding') modal!: OldModalComponent

  @Output() salve = new EventEmitter<any>()

  constructor(
    public http: HttpClient,
    private servic: GenericCrudService,
    private helper: HttpHelpersService
  ) { }

  createLogin() {
    if (this.form.value?.id) return
    const {id, nome, sobrenome} = this.form.value;

    if (!!id) return

    const callback = (login: string) => {
      this.form.patchValue({login})
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }

  public open(data?: HoldingCadastroEntity | any) {
    this.idHolding = undefined
    if (!this.parceiroId) {
      Swal.fire({
        icon: 'error',
        title: 'Parceiro não encontrado'
      }).then();
      return;
    }

    if (!!data) {
      this.idHolding = data.id
      this.form.patchValue({
        ...data,
      });
    }
    this.modal.open()
    return
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;
    const data = {
      ...form,
      parceiro: {
        id: this.parceiroId,
        nome: this.parceiroNome
      }
    }

    this.servic.saveOrUpdate(data, { path: environment.apiUrl, context: 'usuarios-parceiros' }).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'HoldingCadastroEntity' }));
        this.modal.close();
      }
    )
  }

  close() {
    this.modal.close()
  }

  afterClose = () => {
    this.form.reset('');
    return true
  }

  ngOnInit() {
  }

}
