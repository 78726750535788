import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit} from "@datagrupo/dg-crud";
import {CobrancasEntity, objPago} from "../cobrancas.entity";
import {ConfigDgCrudService} from "../../../../core/config/config-dg-crud/config-dg-crud.service";
import {CobrancasService} from "../services/cobrancas-service/cobrancas.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ajusteDateByPaste} from "../../../../helpers/helpers";

@Component({
  selector: 'app-cobrancas-edit',
  templateUrl: './cobrancas-edit.component.html',
  styleUrls: ['./cobrancas-edit.component.scss']
})
export class CobrancasEditComponent extends AbstractInsertEdit<CobrancasEntity> implements OnInit {

  public listStatus: { key: string, value: string }[] = []

  public form = new FormGroup(( {
    status: new FormControl('', [Validators.required]),
    cliente: new FormControl({value: '', disabled: true}),
    vencimento: new FormControl({value: '', disabled: true}),
    pagamento: new FormControl('',)
  }))

  public ajustePasteDate = (ev: any, formName: 'vencimento' | 'pagamento') => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ [formName]: text }))

  constructor(
    public service: CobrancasService,
    public config: ConfigDgCrudService
  ) {
    super(config, service)
    Object.keys(objPago).map(status => {
      // @ts-ignore
      this.listStatus.push({key: status, value: objPago[status]})
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  initNewEntity(): void {
  }

  override afterFetchEntity() {
    this.form.patchValue({
      ...this.entity,
      cliente: this.entity.cliente?.nome
    })
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,

    }

    return true;
  }

}
