import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { PRODUTOS } from 'src/app/core/config/endpoint-list';
import Swal from 'sweetalert2';
import { LogisticaProdutosEntity } from '../../logistica-reversa/logistica-produtos.entity';
import { PedidoCreditoLogisticaReversaEntity } from '../pedido-credito-logistica-revers.entity';
import { PedidoCreditoLogisticaReversaService } from '../service/pedido-credito-logistica-reversa.service';
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'app-pedido-credito-logistica-reversa-insert-edit',
  templateUrl: './pedido-credito-logistica-reversa-insert-edit.component.html',
  styleUrls: ['./pedido-credito-logistica-reversa-insert-edit.component.scss']
})
export class PedidoCreditoLogisticaReversaInsertEditComponent extends AbstractInsertEdit<PedidoCreditoLogisticaReversaEntity> implements OnInit {

  public form = new FormGroup({
    clienteCredito: new FormControl('', [Validators.required]),
    tipoMaterial: new FormControl('', [Validators.required]),
    qtdProdutoGerado: new FormControl('', [Validators.required]),
    porcentagemEmbalagens: new FormControl('', [Validators.required]),
    dataCompensacao: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required])
  })

  listProdutoGerado: LogisticaProdutosEntity[] = [];
  listEstados: { uf: string, estado: string }[] = [
    { uf: "AC", estado: "Acre" }, { uf: "AL", estado: "Alagoas" }, { uf: "AP", estado: "Amapá" }, { uf: "AM", estado: "Amazonas" },
    { uf: "BA", estado: "Bahia" }, { uf: "CE", estado: "Ceará" }, { uf: "DF", estado: "Distrito Federal" },
    { uf: "ES", estado: "Espírito Santo" }, { uf: "GO", estado: "Goiás" }, { uf: "MA", estado: "Maranhão" }, { uf: "MT", estado: "Mato Grosso" },
    { uf: "MS", estado: "Mato Grosso do Sul" }, { uf: "MG", estado: "Minas Gerais" }, { uf: "PA", estado: "Pará" }, { uf: "PB", estado: "Paraíba" },
    { uf: "PR", estado: "Paraná" }, { uf: "PE", estado: "Pernambuco" }, { uf: "PI", estado: "Piauí" }, { uf: "RJ", estado: "Rio de Janeiro" },
    { uf: "RN", estado: "Rio Grande do Norte" }, { uf: "RS", estado: "Rio Grande do Sul" }, { uf: "RO", estado: "Rondônia" },
    { uf: "RR", estado: "Roraima" }, { uf: "SC", estado: "Santa Catarina" }, { uf: "SP", estado: "São Paulo" }, { uf: "SE", estado: "Sergipe" },
    { uf: "TO", estado: "Tocantins" }
  ]
  constructor(
    public service: PedidoCreditoLogisticaReversaService,
    public conf: ConfigDgCrudService,
  ) {
    super(conf, service);
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['qtdProdutoGerado'])
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service.get(PRODUTOS).subscribe(resp => {
      this.listProdutoGerado = resp.data
    });
  }

  initNewEntity(): void {
    this.entity = new PedidoCreditoLogisticaReversaEntity();
  }

  override afterFetchEntity(): void {
    this.entity.qtdProdutoGerado = InputHelpers.initInputDecimal(this.entity.qtdProdutoGerado || '') || this.entity.qtdProdutoGerado

    this.form.patchValue({
      ...this.entity,
      tipoMaterial: this.entity.produto?.id,
      clienteCredito: this.entity.clienteCredito?.nome
    })
  }

  override afterSaveEntity() {
    this.fetchEntity(this.entity.id)
  }

  checkValorMinimo(): boolean {
    const formV = this.form.value;
    let numPorcentagemEmbalagens = parseInt(formV.porcentagemEmbalagens);
    if (numPorcentagemEmbalagens >= 22) {
      return true
    }
    Swal.fire({
      title: 'Erro',
      text: 'O valor da porcentagem é inferior a 22%',
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Ok',
      icon: 'error'
    }).then(() => {
      this.form.get('porcentagemEmbalagens')?.reset('');
      this.form.markAllAsTouched();
    })
    return false
  }

  checkAnoCompacao(): boolean {
    let dataCompensacao = this.form.value.dataCompensacao;
    if (dataCompensacao.length >= 4) {
      return true
    }
    Swal.fire({
      title: 'Erro',
      text: 'Por favor digite digite os 4 digitos referente ao ano de compensação',
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Ok',
      icon: 'error'
    }).then(() => {
      this.form.get('dataCompensacao')?.reset('');
      this.form.markAllAsTouched();
    })
    return false
  }

  override beforeSaveEntity(): boolean {

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    // this.checkValorMinimo();
    // this.checkAnoCompacao();

    // if (!this.checkValorMinimo() || !this.checkAnoCompacao()) {
    //   return false
    // }

    const { qtdProdutoGerado, clienteCredito, tipoMaterial, ...form } = this.form.value;
    const indexProduto = this.listProdutoGerado.findIndex((produt) => {
      return produt.id == tipoMaterial
    });

    if (indexProduto == -1) {
      return false;
    };

    this.entity = {
      ...this.entity,
      ...form,
      qtdProdutoGerado: InputHelpers.ajusteMoneyBrToEua(qtdProdutoGerado),
      produto: {
        id: this.listProdutoGerado[indexProduto].id,
        nome: this.listProdutoGerado[indexProduto].nome,
        valorDoProduto: this.listProdutoGerado[indexProduto].valorDoProduto
      }
    }

    return true;
  }

  info(){
    Swal.fire({
      icon: 'info',
      title: 'O valor da porcentagem precisa ser maior que 21%',
      showCancelButton: false,
    })
  }
}
