import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicCreditoLogisticaReversaComponent } from './public-credito-logistica-reversa/public-credito-logistica-reversa.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {MatMenuModule} from "@angular/material/menu";



@NgModule({
  declarations: [
    PublicCreditoLogisticaReversaComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatIconModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatMenuModule
  ]
})
export class PublicCreditoLogisticaReversaModule { }
