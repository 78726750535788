<header
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <div class="container-fluid">
    <a class="navbar-brand f-bold" routerLink="/doc">Documentação UI</a>
  </div>
</header>

<div class="container-fluid">
  <div class="row">
    <!-- Sidebar -->
    <nav id="sidebar" class="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <div class="position-sticky">
        <ul class="nav flex-column">
          <li
            *ngFor="let item of docMenuList; let index = index"
            class="nav-item"
          >
            <a *ngIf="item.url" class="nav-link" aria-current="page" [routerLink]="[item.url]">
              {{item.name}}
            </a>
            <span *ngIf="!item.url" class="nav-link" aria-current="page" style="color: grey">
              {{item.name}}
            </span>

            <ul *ngIf="!!item.subMenu" class="nav flex-column">
              <li
                *ngFor="let subItem of item.subMenu; let index2 = index"
                class="nav-item"
              >
                <a *ngIf="subItem.url" class="nav-link" aria-current="page" [routerLink]="[subItem.url]">
                  {{subItem.name}}
                </a>
                <span *ngIf="!subItem.url" class="nav-link" aria-current="page" style="color: grey">
                  {{subItem.name}}
                </span>

                <ul *ngIf="!!subItem.subMenu" class="nav flex-column">
                  <li
                    *ngFor="let subItem2 of subItem.subMenu; let index3 = index"
                    class="nav-item"
                  >
                    <a *ngIf="subItem2.url" class="nav-link" aria-current="page" [routerLink]="subItem2.url ? [subItem2.url] : null">
                      {{subItem2.name}}
                    </a>
                    <span *ngIf="!subItem2.url" class="nav-link" aria-current="page" style="color: grey">
                      {{subItem2.name}}
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Main Content Area -->
    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
<!--      <header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">-->
<!--        <h1 class="h2">Inputs > Autocomplete</h1>-->
<!--      </header>-->

      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>


<div class="mt-5"></div>


<!--<div class="container-fluid">-->
<!--  <div class="row">-->
<!--&lt;!&ndash;    <div class="col-2">&ndash;&gt;-->
<!--&lt;!&ndash;      <ul class="list-group">&ndash;&gt;-->
<!--&lt;!&ndash;        <li>&ndash;&gt;-->
<!--&lt;!&ndash;          <a class="list-group-item f-bold" data-bs-toggle="collapse" href="#collapseExample0" role="button" aria-expanded="false" aria-controls="collapseExample0">&ndash;&gt;-->
<!--&lt;!&ndash;            Input&ndash;&gt;-->
<!--&lt;!&ndash;          </a>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="collapse" id="collapseExample0">&ndash;&gt;-->
<!--&lt;!&ndash;            <ul class="list-group">&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="list-group-item">Autocomplete</li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="list-group-item">A third item</li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="list-group-item">A fourth item</li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="list-group-item">And a fifth one</li>&ndash;&gt;-->
<!--&lt;!&ndash;            </ul>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--&lt;!&ndash;        <li class="list-group-item">A second item</li>&ndash;&gt;-->
<!--&lt;!&ndash;        <li class="list-group-item">A third item</li>&ndash;&gt;-->
<!--&lt;!&ndash;        <li class="list-group-item">A fourth item</li>&ndash;&gt;-->
<!--&lt;!&ndash;        <li class="list-group-item">And a fifth one</li>&ndash;&gt;-->
<!--&lt;!&ndash;      </ul>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--&lt;!&ndash;    <div class="col-10">&ndash;&gt;-->
<!--&lt;!&ndash;      <router-outlet>&ndash;&gt;-->
<!--&lt;!&ndash;      </router-outlet>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-2">-->
<!--      <ul class="list-group">-->
<!--        <li *ngFor="let item of docMenuList; let index = index">-->
<!--          <a *ngIf="!item.subMenu" class="list-group-item">-->
<!--            {{item.name}}-->
<!--          </a>-->
<!--          <a-->
<!--            *ngIf="item.subMenu"-->
<!--            class="list-group-item f-bold"-->
<!--            data-bs-toggle="collapse"-->
<!--            [href]="'#'+item.name+'-'+index"-->
<!--            role="button"-->
<!--            aria-expanded="false"-->
<!--            [attr.aria-controls]="item.name+'-'+index"-->
<!--          >-->
<!--            {{item.name}}-->
<!--          </a>-->
<!--          <div-->
<!--            *ngIf="!!item.subMenu"-->
<!--            class="collapse"-->
<!--            [id]="item.name+'-'+index"-->
<!--          >-->

<!--            <ul class="list-group">-->
<!--              <li *ngFor="let subItem of item.subMenu; let index2 = index">-->
<!--                <a *ngIf="!subItem.subMenu" class="list-group-item">-->
<!--                  {{subItem.name}}-->
<!--                </a>-->
<!--                <a-->
<!--                  *ngIf="subItem.subMenu"-->
<!--                  class="list-group-item f-bold"-->
<!--                  data-bs-toggle="collapse"-->
<!--                  [href]="'#'+subItem.name+'-'+index"-->
<!--                  role="button"-->
<!--                  aria-expanded="false"-->
<!--                  [attr.aria-controls]="subItem.name+'-'+index"-->
<!--                >-->
<!--                  {{subItem.name}}-->
<!--                </a>-->
<!--                <div-->
<!--                  *ngIf="!!subItem.subMenu"-->
<!--                  class="collapse"-->
<!--                  [id]="subItem.name+'-'+index"-->
<!--                >-->
<!--                  <ul class="list-group">-->
<!--                    <li *ngFor="let subItem2 of subItem.subMenu; let index2 = index">-->
<!--                      <a class="list-group-item">-->
<!--                        {{subItem2.name}}-->
<!--                      </a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->

<!--    <div class="col-10">-->
<!--      <nav id="nav-doc" class="navbar navbar-expand-lg">-->
<!--        <div class="container-fluid">-->
<!--          <a class="navbar-brand" routerLink="/doc">Documentação UI</a>-->
<!--        </div>-->
<!--      </nav>-->

<!--      <router-outlet>-->
<!--      </router-outlet>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
