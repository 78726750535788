import { ParceiroEntity } from './../../parceiro/parceiro.entity';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';
import { ClientesEntity } from '../../_clientes/clientes/clientes.entity';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
import { GalpaoArquivoEntity } from '../../_galpao/galpao-arquivo/galpao-arquivo.entity';
import { GalpaoEntity } from '../../_galpao/galpao/galpao.entity';
import { GalpaoService } from '../../_galpao/galpao/services/galpao-service/galpao.service';
import { MissaoEntity } from '../missao.entity';
import { MissaoService } from '../service/missao.service';
import { ParceiroService } from '../../parceiro/service/parceiro.service';
import { CupomEntity } from '../../cupom/cupom.entity';
import { ContainerService } from '../../container/service/container.service';
import { CupomService } from '../../cupom/services/cupom/cupom.service';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import Swal from "sweetalert2";
import { HttpParams } from '@angular/common/http';
import {verifySizeFiles} from "../../../../helpers/helpers";

interface interfLocalizacoes {
  "estado": {
    "name": string,
    "uf": string
  },
  "cidades": {
    cidade: string
    id: number | undefined,
    estado?: string
  }[]
}

interface interfLocalizacoesGet {
  "estado": {
    "name": string,
    "uf": string
  },
  "cidades": string[]
}

@Component({
  selector: 'app-missao-insert',
  templateUrl: './missao-insert-edit.component.html',
  styleUrls: ['./missao-insert-edit.component.scss']
})
export class MissaoInsertEditComponent extends AbstractInsertEdit<MissaoEntity> implements OnInit {

  @ViewChild('fileUploadMissao') fileUploadMissao!: ElementRef;
  @ViewChild('fileUploadSelo') fileUploadSelo!: ElementRef;

  @ViewChild('pickListEstado') pickListEstado!: PickListComponent;
  @ViewChild('pickListCidade') pickListCidade!: PickListComponent;

  listaTipoMissao: string[] = ['Missão da Gerador', 'Missão do Usuário'];
  listaGalpaoParceiro: ParceiroEntity[] = [];
  listaCupom: CupomEntity[] = [];
  listaCompleta: any[] = [];

  listaCondominiosConfirmados: { id: number, edit: boolean, cliente: ClientesEntity, meta: number, nome: ClientesEntity }[] = [];

  showInput: boolean = false;
  editorConfig = DefaultKolkovTextEditor;
  selo: boolean = true;

  metaCondominio: number | string | undefined;
  fileMissao: File | undefined;
  fileSelo: File | undefined;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    limiteClientesAceitarMissao: new FormControl('', Validators.required),
    tipoMissao: new FormControl('', Validators.required),
    numeroDeColetas: new FormControl(''),
    qtdMoradores: new FormControl(''),
    percentualDoContainer: new FormControl(undefined),
    tipoLixoEnum: new FormControl(''),
    metaComunicacao: new FormControl(''),
    metaEconomia: new FormControl(''),
    cupom: new FormControl(''),
    parceiro: new FormControl(''),
    link: new FormControl(''),
    pontosMissao: new FormControl(''),
    descricao: new FormControl(''),

  })

  checkbox = {
    estado: false,
    cidade: false
  }

  constructor(
    public servic: MissaoService,
    public confi: ConfigDgCrudService,
    public serviceCupom: CupomService,
    public serviceParceiro: ParceiroService,
    public serviceContainer: ContainerService,
    public serviceClientes: ClientesService,
    public http: HttpOldService,
    public files: FilesManageService
  ) {
    super(confi, servic);
    serviceParceiro.get('parceiros', { params: { unpaged: true } }).subscribe(resp => {
      //@ts-ignore
      this.listaGalpaoParceiro = resp.data
    });
    serviceCupom.get('cupom', { params: { unpaged: true } }).subscribe(resp => {
      //@ts-ignore
      this.listaCupom = resp.data
    });
    this.getLocalizacoes();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.entityId) {
      this.servic.get('missao/solicitacoes', { params: { missaoId: this.entityId, unpaged: true } }).subscribe(resp => {
        //@ts-ignore
        this.listaCondominiosConfirmados = resp.data
      });
    }
  }

  initNewEntity(): void {
    this.entity = new MissaoEntity();
  }

  override afterFetchEntity(): void {
    //@ts-ignore
    this.form.patchValue({
      ...this.entity,
      cupom: this.entity.cupom?.id,
      parceiro: this.entity.parceiro?.id,
      cliente: this.entity.cliente?.id
    });


    this.selecionaEstados()
  }

  editInput(index: number) {
    this.listaCondominiosConfirmados[index].edit = true;
  }

  addMeta(index: number | any) {
    const idCliente = this.listaCondominiosConfirmados[index].id;
    const meta = this.listaCondominiosConfirmados[index].meta;
    const dadosCliente = this.listaCondominiosConfirmados[index];

    let data = {
      id: idCliente,
      meta: meta,
      cliente: { id: dadosCliente.id, nome: dadosCliente.cliente.nome }

    }


    this.http.patch('missao/solicitacoes', data).subscribe(resp => {
      if (resp) {
        this.listaCondominiosConfirmados[index].edit = false;
      }
    })

  }

  cancelarAcao(index: number) {
    this.listaCondominiosConfirmados[index].edit = false;
  }

  selectImg() {
    const fileImge = this.fileUploadMissao.nativeElement;
    fileImge.click()
  }

  uploadMissao(event: Event) {
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.fileMissao = undefined;
        return;
      }

      let data = new FormData();

      data.append('arquivo', file);
      data.append('missaoId', String(this.entity.id));
      data.append('selo', String(!this.selo));

      this.http.post('missao/imagem', data).subscribe(resp => {
        if (resp) {
          //@ts-ignore
          this.entity.imagem = resp.data
        }
      })
    }
  }

  selectImgSelo() {
    const fileImge = this.fileUploadSelo.nativeElement;
    fileImge.click()
  }

  uploadSelo(event: Event) {
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.fileSelo = undefined;
        return;
      }

      let data = new FormData();

      data.append('arquivo', file);
      data.append('missaoId', String(this.entity.id));
      data.append('selo', String(this.selo));

      this.http.post('missao/imagem', data).subscribe(resp => {
        if (resp) {
          //@ts-ignore
          this.entity.selo = resp.data
        }
      })
    }
  }

  override beforeSaveEntity(): boolean {

    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return false;
    }

    const { cupom, parceiro, cliente, ...formV } = this.form.value;
    const arrayCidades: interfLocalizacoes[] = [];

    if(!this.entity.restringiuMissao){
      this.pickListCidade.listaSelected = []
    }else if (!!this.pickListCidade) {
      const listEstado = <interfLocalizacoes[]>this.pickListEstado.getList()
      const listCidades = this.pickListCidade.getList()

      listEstado.map(iteEstado => {
        arrayCidades.push({
          //@ts-ignore
          estado: iteEstado.estado.uf,
          cidades: listCidades.map(
            (cidade) => {
              if (cidade?.estado?.name.toUpperCase() == iteEstado.estado.name.toUpperCase()) {
                const { estado, ...dadosCidade } = cidade
                return dadosCidade
              }
              return undefined
            }
          ).filter(itemFilter => !!itemFilter)
        })
      })
    }



    this.entity = {

      ...this.entity,
      ...formV,
      cupomId: cupom,
      localizacoes: arrayCidades,
      parceiro: { id: parceiro},

    }

    return true;
  }

  //***********************************

  listaEstado: any[] = [];
  listaCidade: any[] = [];
  listaEstadoSelecionados: any[] = [];
  listaCidadeSelecionadas: any[] = [];

  getLocalizacoes() {
    this.http.get('missao/localizacoes').subscribe(resp => {
      //@ts-ignore
      this.listaEstado = <interfLocalizacoesGet[]>resp.data.map((item) => {
        return <interfLocalizacoes>{
          estado: item.estado,
          cidades: item.cidades.map((cid: string) => {
            return {
              id: undefined,
              cidade: cid,
              estado: item.estado
            }
          })
        }
      });
    });
  }

  public selectEstados(listSelect: { type: string, all_selected: any[] }) {
    let listaCidade: any[] = [];

    listSelect.all_selected.map(itemList => {
      // @ts-ignore
      listaCidade = [
        ...listaCidade,
        // @ts-ignore
        ...itemList.value.cidades
      ];
    });

    this.listaCidade = listaCidade.slice();

    if (listSelect.type == 'reload') {
      setTimeout(() => {
        this.pickListCidade.reLoader();
      }, 50)
    }
  }

  selecionaEstados() {
    if (!!Array.isArray(this.entity.localizacoes)) {

      let listaCidadesSelecionadas: any = [];

      // verificando se vai exibir o segundo pickList
      let checkboxCidade: boolean = false;
      this.entity.localizacoes?.map((item: any) => {
        if (item.cidades.length > 0) checkboxCidade = true;


        listaCidadesSelecionadas = [
          ...listaCidadesSelecionadas,
          ...item.cidades
        ]
      })
      this.checkbox.cidade = true;

      if (
        this.pickListEstado.selectItem(this.entity.localizacoes)
      ) {

        setTimeout(() => {
          this.pickListCidade.selectItem(listaCidadesSelecionadas, (newValue: any, currentValue: object) => {

            return {
              // ...newValue
              ...currentValue,
              id: newValue.id
            }
          })
        }, 50)
      }

    }
  }

  selectCidades(listCidades: any) {
    // console.log(listCidades)
  }

  verifyRemoveEstado = (listRemove: any[]) => {

    if (!this?.pickListCidade) {
      return true;
    }

    const listAtual = this.pickListCidade.getList();
    const listaExclusaoSecundaria: any[] = [];

    listRemove.map((itemRemove, index) => {
      // inicia verificação das cidades mascaras como selecionadar
      // dentro do estado
      itemRemove.value.cidades.map(
        (itemCidade: { id: number, cidade: string }, indexItemCidade: number) => {

          // verifica se a cidade do loop extá selecionada
          if (listAtual.findIndex(itemListAtual => itemListAtual.cidade == itemCidade.cidade) != -1) {

            // verifica se o estado da cidade selecionada já exista na lista de espera
            //para exclusão
            const indexListaExclusaoSecundaria = listaExclusaoSecundaria.findIndex(
              itemListaExclusaoSecundaria => itemListaExclusaoSecundaria.text == itemRemove.text
            )

            // inclui item na lista de expera para exclusão
            if (indexListaExclusaoSecundaria == -1) {
              listaExclusaoSecundaria.push(listRemove[index])
              listRemove[index].check = false
            }
          }
        }
      )
    })

    if (listaExclusaoSecundaria.length > 0) {
      Swal.fire({
        icon: 'question',
        title: 'item selecionado',
        showCancelButton: true,
      }).then((conf) => {
        if (conf.isConfirmed) {
          if (this.pickListEstado.unSelectItem(listaExclusaoSecundaria.map(item => item.value))) {
            setTimeout(() => {
              let arrayCidades: any[] = [];

              listaExclusaoSecundaria.map(item => {
                arrayCidades = [
                  ...arrayCidades,
                  ...item.value.cidades
                ]
              })

              this.removeCidades(arrayCidades)
            }, 50)
          }
        }
      })
    }


    // console.log(this.pickListCidade.getList())
    // console.log(listRemove)

    return listRemove;
  }

  removeCidades(list: any[]) {
    this.pickListCidade.unSelectItem(list)
  }
}
