import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import {CardPromo1Component} from "./subComponent/card-promo1/card-promo1.component";
import {CardPromoLixComponent} from "./subComponent/card-promo-lix/card-promo-lix.component";
import {CardPromo2Component} from "./subComponent/card-promo2/card-promo2.component";
import {UiModule} from "../../../shared/ui/ui.module";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ReactiveFormsModule} from "@angular/forms";
import {BlogModule} from "../blog/blog.module";
import {MissoesModule} from "../missoes/missoes.module";



@NgModule({
  declarations: [
    HomeComponent,
    CardPromo1Component,
    CardPromoLixComponent,
    CardPromo2Component
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    AngularEditorModule,
    ReactiveFormsModule,
    BlogModule,
    MissoesModule
  ]
})
export class HomeModule { }
