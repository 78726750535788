import { Injectable } from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

declare type options_xhr = {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

/**
 * @deprecated
 * Essa classe contem fluxos desnecessários, existe hoje uma estrutura melhor para lidar com isso.
 * use o HTTPService encontrado em service/http-service/http.service
 */
@Injectable({
  providedIn: 'root'
})
export class HttpOldService {

  private Authorization: string | null;
  private path: string;

  protected constructor(
    public http: HttpClient
  ) {
    this.Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));
    this.path = environment.apiUrl;
  }

  public post<T>(path: string, data: object): Observable<T> {

    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.post<T>(newUrl, data, {headers: { Authorization }});
  }

  public patch<T>(path: string, data: object, options?: options_xhr): Observable<T> {

    const newUrl = this.addEndpoint(path);


    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.patch<T>(newUrl, data, options);
  }

  public put<T>(path: string, data: object): Observable<T> {

    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.put<T>(newUrl, data, {headers: { Authorization }});
  }

  public get<T>(path: string, params?: { headers?: object, params?: object }): Observable<T> {

    const newUrl = this.addEndpoint(path);

    return this.http.get<T>(newUrl, {
      headers: !!params?.headers ? {...params.headers} :{},
      params: !!params?.params ? {...params.params} :{}
    });
  }

  public getById<T>(path: string, id: number | string): Observable<T> {
    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.get<T>(newUrl + '/' + id);
  }

  public delete<T>(path: string, id: number | string): Observable<T> {
    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.delete<T>(newUrl + '/' + id);
  }

  addEndpoint(endpoint: string | any): string {
    if (this.path.substr(-1) == '/') {
      return endpoint ? `${this.path}${endpoint}` : this.path;
    }

    return endpoint ? `${this.path}/${endpoint}` : this.path;
  }

  public alterPath(path: string){
    this.path = path;
  }
}
