import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlePesagemMainComponent } from './controle-pesagem-main/controle-pesagem-main.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {RouterModule} from "@angular/router";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {
    CdkDynamicGroupModule,
    CdkDynamicTableModule,
    DgAutocomplete2Module, DgPaginatorModule,
    DgTableModule
} from "@datagrupo/dg-ng-util";
import { ModalControlePesagemComponent } from './sub-components/modal-controle-pesagem/modal-controle-pesagem.component';
import {DirectivesModule} from "../../../core/directives/directives.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomAutocompleteModule} from "../../../shared/custom-autocomplete/custom-autocomplete.module";



@NgModule({
  declarations: [
    ControlePesagemMainComponent,
    ModalControlePesagemComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        DgCrudModule,
        DirectivesModule,
        ReactiveFormsModule,
        FormsModule,
        DgTableModule,
        DgAutocomplete2Module,
        CdkDynamicTableModule,
        CdkDynamicGroupModule,
        DgPaginatorModule,
        CustomAutocompleteModule
    ]
})
export class ControlePesagemModule { }
