<div
  id="cadastro-gravimetria-insert-edit"
  *ngIf="!!entity"
>
  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="card-header">
              <h4 class="card-title">Estoque</h4>
              <hr>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label
                    for="galpao"
                    class="form-label"
                  >
                    SELECIONE O TRANSPORTADOR
                    <span class="fc-red">*</span>
                  </label>
                  <input-auto-complete
                    propertyKey="id"
                    [baseValue]="entity.galpao"
                    propertValue="nome"
                    controlName="galpao"
                    urlServer="galpoes/nomes"
                    id="galpao"
                    (outValue)="entity.galpao = $event"
                  >
                  </input-auto-complete>
                </div>


                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">
                    FORMATO
                    <span class="fc-red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formato"
                    formControlName="formato"
                  >
                </div>


                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <label class="form-label">DATA DE REFERENCIA</label>
                  <input
                    type="date"
                    (paste)="ajustePasteDate($event)"
                    class="form-control"
                    id="dataReferencia"
                    formControlName="dataReferencia"
                  >
                </div>
              </div>
            </div>
          </card>

          <div style="margin-top: 1.5em;">
            <card>
              <div class="card-header">

                <div class="container-fluid">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                      <h4 class="card-title">Materiais</h4>
                    </div>
                    <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                      <button
                        (click)="modalMaterial.open()"
                        class="btn btn-success btn-card-header"
                      >
                        <mat-icon>add</mat-icon>
                        adicionar
                      </button>
                    </card-header-buttons>
                  </div>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">

                </div>
              </div>


              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div>
                      <table class="crud-table">
                        <thead>
                        <th>Material</th>
                        <th>Nome do Material (secundário)</th>
                        <th>Quantidade (Kg)</th>
                        </thead>
                        <tbody *ngIf="!!entity">
                        <tr *ngIf="entity.materiais.length <= 0">
                          <span>Nenhum produto cadastrado</span>
                        </tr>
                        <tr
                          *ngFor="let mat of entity.materiais; let i = index"
                          [class.riscar-texto]="mat.vendido"
                        >
                          <td>{{ mat.tipoLixo?.categoria}}</td>
                          <td>{{ mat.nome }}</td>
                          <td>{{ format(mat.quantidade) }}</td>
                          <td class="mat-column-actions">
                            <button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu
                              #menu="matMenu"
                              xPosition="before"
                            >
                              <button
                                mat-menu-item
                                (click)="openModal(i, entity.materiais[i])"
                              >
                                <span>Editar</span>
                              </button>
                              <button
                                mat-menu-item
                                (click)="excluirMat(i, entity.materiais[i])"
                              >
                                <span>Excluir</span>
                              </button>
                            </mat-menu>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
    crud-deletar
    class="btn btn-secondary"
  >
    Excluir
  </button>
</page-footer>


<old-dg-modal
  title="Materiais"
  size="md"
  #modalMaterial
  [afterClose]="closeModal"
>
  <form
    dg-content
    [formGroup]="formModal"
  >
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label class="form-label">
          Tipo de Resíduo
          <span class="fc-red">*</span>
        </label>
        <select
          class="form-control"
          formControlName="tipoLixo"
        >
          <option
            disabled
            value
          >
            Selecione
          </option>
          <option
            *ngFor="let lixo of listLixo"
            value="{{ lixo.id }}"
          >
          NOME: {{lixo.nome || '--'}} | CATEGORIA: {{lixo.categoria || '--'}}
          </option>
        </select>
      </div>


      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label class="form-label">
          Quantidade (Kg)
          <span class="fc-red">*</span>
        </label>
        <input
          class="form-control"
          formControlName="quantidade"
        >
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label class="form-label">OUTRO NOME PARA O RESÍDUO (opcional)</label>
        <input
          type="text"
          class="form-control"
          formControlName="nome"
        >
      </div>
      <div
        class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
        *ngIf="indexModal == undefined"
      >
        <label class="form-label">QUANTIDADE</label>
        <input
          type="number"
          min="1"
          class="form-control"
          formControlName="multiplicador"
        >
      </div>
      <div
        class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
        style="padding-top: 2.1em;"
      >
        <input
          type="checkbox"
          class="form-check-input"
          name="vendido"
          id="vendido"
          formControlName="vendido"
        >
        <label
          class="form-label ms-2"
          for="vendido"
        >
          VENDIDO
        </label>
      </div>
      <div
        class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
        *ngIf="indexModal == undefined"
      >
        <label class="form-label">QUANTIDADE VENDIDA</label>
        <input
          type="number"
          min="1"
          [max]="formModal.value.multiplicador"
          class="form-control"
          formControlName="quantidadeVendida"
        >
      </div>
    </div>
  </form>


  <div dg-footer>
    <button
      (click)="modalMaterial.close()"
      class="btn btn-secondary btn m-1"
    >
      Sair
    </button>
    <button
      (click)="salvarMaterial()"
      class="btn btn-success btn m-1"
    >
      adicionar
    </button>
  </div>
</old-dg-modal>
