import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DetalhamentoUnidadeGeradoraEntity} from "../../../entitys/detalhamento-unidade-geradora.entity";
import {DashboardColetaService} from "../service/dashboard-coleta.service";
import {filtersInterface} from "../../../dashboard-main/dashboard-main.component";
import {DASHBOARD} from "../../../../../../core/config/endpoint-list";
import {DetalhamentoGriEntity} from "../../../entitys/detalhamento-gri.entity";
import {HttpOldService} from "../../../../../../core/services/http/http-old.service";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";
import {crudDispatchEvent} from "@datagrupo/dg-crud";

@Component({
  selector: 'subComponent-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss']
})
export class DashboardGraphComponent implements OnInit, OnChanges {

  @Input() dataFilter: filtersInterface = {
    unidades: [],
    estados: [],
    tipoResiduo: [],
    galpao: [],
    dataInicio: '',
    dataFim: '',
    ativo: ''
  };

  public contAtualizacao = 0;
  public print = false;

  public listGraph: any[] = [];

  public numeroClientes = 0;
  public numeroApartmentos = 0;

  public entity = new DetalhamentoUnidadeGeradoraEntity()
  public entityGri = new DetalhamentoGriEntity()

  public persistParams: {[key: string]: any} = {}

  constructor(
    public service: DashboardColetaService,
    private http: HttpOldService,
    private files: FilesManageService,
  ) {
    if ('matchMedia' in window) {
      // Chrome, Firefox, and IE 10 support mediaMatch listeners
      window.matchMedia('print').addListener((media) => {
        if (media.matches) {
          this.generate_pdf_target()
        } else {
          // Fires immediately, so wait for the first mouse movement
        }
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes['dataFilter'].currentValue)

    if (!!changes['dataFilter'].currentValue) {
      this.dataFilter = changes['dataFilter'].currentValue;
    }

    this.persistParams = this.ajustParams()

    setTimeout(() => {
      this.getGraph();
      crudDispatchEvent('tabelaDetalhamento')
      crudDispatchEvent('tabelaGRI')
    }, 10)
  }

  getGraph() {
    const params = this.ajustParams();

    this.service.get(DASHBOARD, { params }).subscribe(
      resp => {
        this.contAtualizacao++;
        //@ts-ignore
        this.listGraph = resp.data?.charts || [];

        if(resp.data?.info) {
          this.numeroApartmentos = resp.data.info.numeroDeApartamentos || 0;
          this.numeroClientes = resp.data.info.numeroDeUnidades || 0;
        }
      }
    )
  }

  public generate_pdf_target(): void {
    //alert(window.location.href);
    const newWindow = window.open('/user/dashboard-print?unidadeGeradora=409', '_blank');
    newWindow?.focus();
    return;
  }

  getParamsFind() {
    const params: {[key: string]: any} = {};

    if (this.dataFilter.dataInicio) params['dataInicio'] = this.dataFilter.dataInicio

    if (this.dataFilter.dataFim) params['dataFim'] = this.dataFilter.dataFim

    if (this.dataFilter.unidades.length > 0) params['unidades'] = this.dataFilter.unidades

    if (this.dataFilter.estados.length > 0) params['estados'] = this.dataFilter.estados

    if (this.dataFilter.galpao.length > 0) params['galpao'] = this.dataFilter.galpao

    if (this.dataFilter.tipoResiduo.length > 0) params['tipoResiduo'] = this.dataFilter.tipoResiduo

    if (!!this.dataFilter.ativo) params['ativo'] = this.dataFilter.ativo

    return params;
  }

  criarId(i: number): string {
    return 'chartid-' + String(i) + '-' + this.contAtualizacao;
  }

  @HostListener('window:print-coletas')
  dispacherPrint() {
    const params = window.location.search.slice(1);
    const paginationTableClientes = JSON.stringify(this.entity.configEntityTable.pagination)
    const paginationTableGRI = JSON.stringify(this.entityGri.configEntityTable.pagination)
    window.open(
      '/user/dashboard-print-coletas?paginationTableClientes=' +
      paginationTableClientes +'&'+ paginationTableGRI +'&'+ params, '_blank')?.focus();
  }

  downloadExcelDetalhamento() {
    const params = {
      unpaged: true,
      ...this.ajustParams()
    };

    this.http.get<{ data: string }>('dashboard/datatable/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta.xlsx')
      }
    )
  }

  downloadExcelGri() {
    const params = {
      unpaged: true,
      ...this.ajustParams()
    };

    this.http.get<{ data: string }>('dashboard/gri-datatable/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta-planilha-GRI.xlsx')
      }
    )
  }

  public ajustParams() {
    const params: any = {};
    const {dataInicio, dataFim, unidades, estados, galpao, tipoResiduo, ativo} = this.dataFilter

    if (!!dataInicio) {
      params['dataInicio'] = dataInicio
    }

    if (!!dataFim) {
      params['dataFim'] = dataFim
    }

    if (unidades.length > 0) {
      params['unidades'] = unidades.join(',')
    }

    if (estados.length > 0) {
      params['estados'] = estados.join(',')
    }

    if (galpao.length > 0) {
      params['galpao'] = galpao.join(',')
    }

    if (tipoResiduo.length > 0) {
      params['tipoResiduo'] = tipoResiduo.join(',')
    }

    if (!!ativo) {
      params['ativo'] = ativo
    }

    return params;
  }
}
