import { HttpOldService } from './../../../../core/services/http/http-old.service';
import { ConfigDgCrudService } from './../../../../core/config/config-dg-crud/config-dg-crud.service';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { FuncionarioEntity } from '../funcionario.entity';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioService extends AbstractHttpService<FuncionarioEntity> {

  public test = {
    data: <FuncionarioEntity[]>[]
  }

  constructor(
    public conf: ConfigDgCrudService,
    public httpServ: HttpOldService
  ) {
    super(conf, environment.apiUrl, 'funcionarios')
   }
}
