import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidoCreditoLogisticaReversaMainComponent } from './pedido-credito-logistica-reversa-main/pedido-credito-logistica-reversa-main.component';
import { PedidoCreditoLogisticaReversaInsertEditComponent } from './pedido-credito-logistica-reversa-insert-edit/pedido-credito-logistica-reversa-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { DirectivesModule } from 'src/app/core/directives/directives.module';



@NgModule({
  declarations: [
    PedidoCreditoLogisticaReversaMainComponent,
    PedidoCreditoLogisticaReversaInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    DgCrudModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule,
    DirectivesModule
  ]
})
export class PedidoCreditoLogisticaReversaModule { }
