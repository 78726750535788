import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './cards/card/card.component';
import {CardItemComponent} from './cards/card-item/card-item.component';
import {MatIconModule} from "@angular/material/icon";
import {CardHeaderButtonsComponent} from './cards/card-header-buttons/card-header-buttons.component';
import {OldModalComponent} from "./modal/modal/modal.component";
import {PageFooterComponent} from './page-footer/page-footer.component';
import {FilterSearchComponent} from './filter-search/filter-search.component';
import {DgCrudModule} from "@datagrupo/dg-crud";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {CardPromoComponent} from './cards/card-promo/card-promo.component';
import {PaginationComponent} from "./pagination/pagination.component";
import {UploadFilesModalComponent} from './modal/custom/upload-files-modal/upload-files-modal.component';
import {RatioStarComponent} from './ratio-star/ratio-star.component';
import {InputAutoCompleteComponent} from './input-auto-complete/input-auto-complete.component';
import {DgPaginationComponent} from './dg-pagination/dg-pagination.component';
import {CardGraphComponent} from './cards/card-graph/card-graph.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {PickListComponent} from "./pick-list/pick-list.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { FilterSearch2Component } from './filter-search2/filter-search2.component';
import {CdkDynamicGroupModule} from "@datagrupo/dg-ng-util";


@NgModule({
  declarations: [
    CardComponent,
    CardItemComponent,
    CardHeaderButtonsComponent,
    OldModalComponent,
    PageFooterComponent,
    FilterSearchComponent,
    CardPromoComponent,
    PaginationComponent,
    UploadFilesModalComponent,
    RatioStarComponent,
    InputAutoCompleteComponent,
    DgPaginationComponent,
    CardGraphComponent,
    PickListComponent,
    FilterSearch2Component,
  ],
    exports: [
        CardComponent,
        CardItemComponent,
        CardHeaderButtonsComponent,
        OldModalComponent,
        PageFooterComponent,
        FilterSearchComponent,
        CardPromoComponent,
        PaginationComponent,
        UploadFilesModalComponent,
        RatioStarComponent,
        InputAutoCompleteComponent,
        DgPaginationComponent,
        CardGraphComponent,
        PickListComponent,
        FilterSearch2Component,
    ],
  imports: [
    CommonModule,
    MatIconModule,
    DgCrudModule,
    MatTooltipModule,
    FormsModule,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    MatProgressSpinnerModule,
    CdkDynamicGroupModule
  ]
})
export class UiModule {
}
