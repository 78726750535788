<div id="produto-main" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Rotas</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [table]="tableRotas" [entityMap]="mapEntity" tableEventId="RotasEntity" (dispatchFindFilters)="getFilterValue($event)"></filter-search>
        </div>
        <div class="card-body" style="padding: 0">
          <crud-data-table
            #tableRotas
            tableEventId="RotasEntity"
            [entityMap]="mapEntity"
            [service]="service"
            [showPagination]="false"
            [customDblclick]="true"
            (dblclick)="ajustaLinkEditarRota($event)"
            [register]="true"
          >
          </crud-data-table>
        </div>
        <div class="card-footer">
          <dg-pagination
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="tableRotas.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>

<page-footer>
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
  <button (click)="open()" class="btn btn-success">
    Enviar relatório
  </button>
</page-footer>


<old-dg-modal
  title="Relatorio De Galpoẽs"
  size="md"
  #modalRelatorio
  [afterClose]="beforeClose"
>
  <div dg-content>
    <pick-list
      #pickList
      titleList="Geradores"
      [listaDisponivel]="listClientesRelatorio"
      propertItem="nome"
      [showSearchInput]="true"
      (changeSelect)="getClientesRelatorio($event)"
    >
    </pick-list>
  </div>

  <div dg-footer>
    <button (click)="modalRelatorio.close()" class="btn btn-secondary btn m-1">Sair</button>
    <button (click)="enviarRelatorio()" class="btn btn-success btn m-1">Enviar relatório</button>
  </div>
</old-dg-modal>
