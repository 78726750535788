import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractInsertEdit, InsertEditConfig} from "@datagrupo/dg-crud";
import {TratamentoEntity} from "../tratamento.entity";
import {environment} from "../../../../../environments/environment";
import {TRATAMENTO} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-tratamento-insert-edit',
  templateUrl: './tratamento-insert-edit.component.html',
  styleUrls: ['./tratamento-insert-edit.component.scss']
})
export class TratamentoInsertEditComponent extends AbstractInsertEdit<TratamentoEntity> implements OnInit {

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    descricao: new FormControl(''),
    aterroZero: new FormControl(false),
    reciclagem: new FormControl(false),
  })

  constructor(
    public config: InsertEditConfig
  ) {
    super(config, { path: environment.apiUrl, context: TRATAMENTO })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new TratamentoEntity();
  }

  override afterFetchEntity() {
    this.form.patchValue({...this.entity})
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }

    return true;
  }
}
