import { TipoLixoEntity } from '../tipo-lixo/tipo-lixo.entity';
import { GalpaoEntity } from '../_galpao/galpao/galpao.entity';
import {
  AbstractEntity,
  DgFilter_autoComplete,
  DgFilter_input,
  DgTableColumn,
} from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import {formata_data_utc_br, formataData} from 'src/app/helpers/helpers';
import {AutoCompleteGalpaoOutPut} from "../../../helpers/auto-complete/galpao-autocomplete.helper";

export class CadastroGravimetriaEntity extends AbstractEntity {
  constructor(
     id?: number,
     galpao?: GalpaoEntity,
     formato?: string,
     dataReferencia?: string,
     tipoLixo?: TipoLixoEntity,
     materiais?: any[],
     vendido?: boolean
      ) {
    super();
    this.id = id
    this.galpao = galpao;
    this.formato = formato;
    this.dataReferencia = dataReferencia;
    this.tipoLixo = tipoLixo;
    this.materiais = Array.isArray(materiais) ? materiais : [];
    this.vendido = vendido

  }

  @DgFilter_autoComplete(
    { colum: '6' },
    {
      getUrl: environment.apiUrl + 'galpoes/nomes',
      getServerData: 'all',
      propertKey: 'id',
      propertValue: 'nome',
      nameParamFilter: 'nome',
      outputFormat: AutoCompleteGalpaoOutPut
    }
  )
  @DgTableColumn({
    columnName: 'TRANSPORTADOR',
    resource: (galpao: CadastroGravimetriaEntity) => galpao?.nome,
    sort: true
  })
  public galpao: GalpaoEntity | undefined;

  @DgTableColumn({columnName: 'DATA DE REFERENCIA', resource: (data: string) => {
    return !!data ? formata_data_utc_br(data) : '--'
  }, sort: true})
  public dataReferencia: string | undefined;

  @DgTableColumn({columnName: 'FORMATO', sort: true})
  @DgFilter_input('text', {name:'FORMATO', colum: '6' })
  public formato: string | undefined;

  @DgTableColumn({columnName: 'CÓDIGO', sort: true})
  public codigo: string | undefined;

  public nome: string | undefined;
  public tipoLixo: TipoLixoEntity | undefined;
  public quantidade: string | undefined;
  public materiais: any[] = [];
  public vendido: boolean | undefined;

}


