<old-dg-modal #modalProdutos size="md" title="Produtos" [afterClose]="afterClose">
  <form dg-content [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <label for="produto" class="form-label">TIPO DE MATERIAL COMPENSADO <span class="fc-red">*</span></label>
        <select formControlName="produto" class="form-select" id="produto">
          <option value="" disabled>Selecione</option>
          <option *ngFor="let prod of listProduto" value="{{ prod?.id }}">
            {{ prod.nome }}
          </option>
        </select>
      </div>

      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <label for="porcentagemEmbalagens" class="form-label">
          QUAL PERCENTUAL VOCÊ DESEJA COMPENSAR NA ECOPLAT?
          <span class="fc-red">*</span></label>
        <input type="number" class="form-control" min="22" id="porcentagemEmbalagens"
          formControlName="porcentagemEmbalagens" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <label for="qtdProdutoGerado" class="form-label">
          O PERCENTUAL ESCRITO ACIMA REPRESENTA QUANTAS TONELADAS DE MATERIAIS?
          (TON) <span class="fc-red">*</span></label>
        <input  placeholder="exemplo: 100(TON)" class="form-control" formControlName="qtdProdutoGerado"
          id="qtdProdutoGerado" />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
        <label for="dataCompensacao" class="form-label">
          ESSA COMPENSAÇÃO É REFERENTE A QUAL ANO?
          <span class="fc-red">*</span></label>
        <input min="2000" max="2100" type="date" (paste)="ajustePasteDate($event)" class="form-control"
          id="dataCompensacao" formControlName="dataCompensacao" />
      </div>

      <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12">
        <label for="estado" class="form-label">UF <span class="fc-red">*</span></label>
        <select class="form-select" id="estado" formControlName="estado">
          <option disabled selected>Selecione</option>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </select>
      </div>
    </div>

  </form>

  <div dg-footer>
    <button (click)="salvarProduto()" class="btn btn-success m-1" dg-desable-by-period>
      Salvar
    </button>
    <button (click)="modalProdutos.close()" class="btn btn-secondary m-1">
      Sair
    </button>
  </div>
</old-dg-modal>
