<old-dg-modal #modal [afterClose]="afterClose">
  <div dg-content>
    <div class="row">
      <div class="col-12">
        <label>Nome de identificação do filtro</label>
        <input [formControl]="nomeFavorito" name="nomeFavorito" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="typeFavorito == 'RANKING_TAXA_RECICLAGEM'">
        <label class="form-label">Tipo de Perfil (Gerador)</label>
        <select
          class="form-select"
          [(ngModel)]="_dataFilter.perfilUnidade"
          (ngModelChange)="verifyChangeData('perfilUnidade')"
        >
          <option disabled value="">Selecione um perfil</option>
          <option value="EMPRESA">Empresa</option>
          <option value="CONDOMINIO">Condomínio</option>
          <option value="EVENTO">Evento</option>
          <option value="CASA">Casa</option>
        </select>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="!verifyVisible(['DASHBOARD_ESTOQUE'])">
        <label>Unidades Geradoras</label>
        <input-autocomplete
          #autoUnidade
          key="id"
          label="nome"
          placeholder="Selecione um gerador"
          classes="form-control"
          [multiple]="true"
          [showSelectedList]="true"
          [(ngModel)]="_dataFilter.clientes"
          [list]="(listItensDisponiveis.clientes || [])"
          [preSelectedList]="listsPreSelected.clientes"
          (close)="setAutoComplete($event, 'clientes')"
        >
        </input-autocomplete>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="verifyVisible(['DASHBOARD_COLETAS', 'DASHBOARD_ESTOQUE'])">
        <label>Operadores Logísticos</label>
        <input-autocomplete
          #autoGalpao
          key="id"
          label="nome"
          placeholder="Selecione um operador logístico"
          [multiple]="true"
          [disabled]="session.checkPerfil(['GALPAO'])"
          [showSelectedList]="true"
          [(ngModel)]="_dataFilter.galpoes"
          [list]="(listItensDisponiveis.galpao || [])"
          [preSelectedList]="listsPreSelected.galpoes"
          (close)="setAutoComplete($event, 'galpoes')"
          classes="form-control"
        >
        </input-autocomplete>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="verifyVisible(['DASHBOARD_COLETAS'])">
        <label>Motorista/Catador</label>
        <input-autocomplete
          #autoMotorista
          key="id"
          label="nome"
          placeholder="Selecione um motorista"
          [multiple]="true"
          [showSelectedList]="true"
          [(ngModel)]="_dataFilter.motoristas"
          [list]="(listItensDisponiveis.motoristas || [])"
          [preSelectedList]="listsPreSelected.motoristas"
          (close)="setAutoComplete($event, 'motoristas')"
          classes="form-control"
        >
        </input-autocomplete>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label>Tipos de Resíduo</label>
        <input-autocomplete
          #autoResiduo
          key="id"
          label="nome"
          [showSelectedList]="true"
          [multiple]="true"
          [(ngModel)]="_dataFilter.tipoLixos"
          [list]="(listItensDisponiveis.tipoLixos || [])"
          [preSelectedList]="listsPreSelected.tipoLixos"
          (close)="setAutoComplete($event, 'tipoLixos')"
          classes="form-control"
        >
        </input-autocomplete>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label>Data Início</label>
        <input
          class="form-control"
          (paste)="ajustePasteDate($event, 'dataInicio')"
          type="date"
          [(ngModel)]="_dataFilter.dataInicio"
          (change)="verifyChangeData('dataInicio')"
        >
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label>Data Fim</label>
        <input
          class="form-control"
          type="date"
          [(ngModel)]="_dataFilter.dataFim"
          (paste)="ajustePasteDate($event, 'dataFim')"
          (ngModelChange)="verifyChangeData('dataFim')"
        >
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="!verifyVisible('DASHBOARD_ESTOQUE')">
        <label>Ativo/Inativo</label>
        <label class="form-label">Ativo/Inativo</label>
        <select [(ngModel)]="_dataFilter.ativo" class="form-select">
          <option [value]="''">Ambos</option>
          <option [value]="'true'">Ativo</option>
          <option [value]="'false'">Inativo</option>
        </select>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" *ngIf="!verifyVisible('DASHBOARD_COLETAS')">
        <label class="form-label">Estados</label>
        <input-autocomplete
          #autoEstados
          key="uf"
          label="name"
          placeholder="Selecion um estado"
          positionX="end"
          [showSelectedList]="true"
          [multiple]="true"
          [(ngModel)]="_dataFilter.estados"
          [list]="(listItensDisponiveis.estados || [])"
          [preSelectedList]="listsPreSelected.estados"
          (close)="setAutoComplete($event, 'estados')"
          classes="form-control"
        >
        </input-autocomplete>
      </div>
    </div>
  </div>

  <div dg-footer>
    <button (click)="salvar()" [disabled]="saveDisabled" class="btn btn-success m-1">Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
