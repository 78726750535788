import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicHomeComponent } from './public-home/public-home.component';
import {UiModule} from "../../../shared/ui/ui.module";
import {MatIconModule} from "@angular/material/icon";
import {ContainerSectionComponent} from "./subComponents/container-section/container-section.component";
import { SliderComponent } from './subComponents/slider/slider.component';
import { CarouselCardsComponent } from './subComponents/carousel-cards/carousel-cards.component';



@NgModule({
  declarations: [
    PublicHomeComponent,
    ContainerSectionComponent,
    SliderComponent,
    CarouselCardsComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatIconModule,
    RouterModule
  ]
})
export class PublicHomeModule { }
