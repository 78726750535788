import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DashboardFilterHelperService} from "../../services/dashboard-filter-helper/dashboard-filter-helper.service";
import {HttpService} from "../../../../../services/http-service/http.service";
import {CdkDynamicTable, CdkDynamicTableService, DgAutocomplete3Component} from "@datagrupo/dg-ng-util";
import {ProgressLoader} from "../../../../../helpers/progress-loader";
import {ajusteFunctionHighChartsByInsert, setGraph} from "../../../dashboard/helpers/highcharts.helper";
import {Subscription} from "rxjs";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {addMessageCallbackColumn, removeMessageCallbackColumn} from "../../../../../helpers/events.helpers";
import {ActivatedRoute, Router} from "@angular/router";
import {
  DASHBOARD_ATERRO_ZERO,
  DASHBOARD_GRUPOS_RESIDUOS,
  DASHBOARD_TRATAMENTO
} from "../../../../../core/config/endpoint-list";
import {environment} from "../../../../../../environments/environment";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";
import {TokenService} from "../../../../../core/services/token-service/token.service";


@Component({
  selector: 'page-dashboard2-coletas',
  templateUrl: './dashboard2-coletas.component.html',
  styleUrls: ['./dashboard2-coletas.component.scss']
})
export class Dashboard2ColetasComponent implements OnInit, OnDestroy {

  /**
   * Esse input é para verificar quando o dashboard está sendo compartilhado,
   * isso muda a leitura inicial da queryParams e impede a seleção de novos filtros.
   */
  @Input('isPublic') isPublic: boolean = false;

  @ViewChild('favoritos') favoritos!: DgAutocomplete3Component

  /**
   * formulário dos filtros
   */
  formFilter = new FormGroup({
    token: new FormControl(''),
    favoritos: new FormControl(''),
    unidades: new FormControl([]),
    galpao: new FormControl([]),
    tipoResiduos: new FormControl([]),
    motorista: new FormControl([]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    ativo: new FormControl(''),
    gruposTipoResiduo: new FormControl([]),
    tratamentos: new FormControl([]),
  })

  controlles: {
    // lidando com requests
    handleHequests: { [key: string]: Subscription | undefined },
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de graficos/requests que devem ser executados
    listLoaderPermission: string[],
    // lista de filtros que pode aparecer
    permissionFilters: string[],
    // controller de loader dos dados
    loaders: ProgressLoader.classe,
    // ultimos filtros buscados
    lastFilter: any
  } = {
    filtersOptions: {
      favoritos: [],
      unidades: [],
      transportador: [],
      tipoResiduos: [],
      motoristas: [],
      gruposResiduos: [],
      tratamento: [],
    },
    handleHequests: {
      info: undefined,
      anos: undefined,
      meses: undefined,
      totais: undefined,
      tipoResiduos: undefined,
      motoristas: undefined,
    },
    handleHequestsFilters: {},
    listLoaderPermission: [],
    permissionFilters: [],
    loaders: ProgressLoader.createLoader(),
    lastFilter: undefined
  }

  dataDashboard: {
    queryPrint: {[key:string]: any},
    cardInfo: {
      numeroClientes: number,
      numeroApartmentos: number,
    },
    dataGraphs: {
      totais: any[],
      ano: any[],
      meses: any[],
      motoristas: any[],
      residuos: any[],
      tratamento: any[],
      gruposResiduos: any[],
    }
  } = {
    queryPrint: {},
    cardInfo: {
      numeroClientes: 0,
      numeroApartmentos: 0,
    },
    dataGraphs: {
      totais: [],
      ano: [],
      meses: [],
      motoristas: [],
      residuos: [],
      tratamento: [],
      gruposResiduos: [],
    }
  }

  verifyShow: {
    hasAnyDados: boolean | undefined,
    loaderInfo: boolean,
    viewGraph: {[key: string]: 'graph' | 'table'}
  } = {
    hasAnyDados: undefined,
    loaderInfo: false,
    viewGraph: {
      total: 'graph',
      ano: 'graph',
      residuos: 'graph',
      meses: 'graph',
      motoristas: 'graph',
      gruposResiduos: 'graph',
      tratamento: 'graph',
    }
  }

  tableGerador: CdkDynamicTable.tableClass

  constructor(
    public filterHelpers: DashboardFilterHelperService,
    private http: HttpService,
    public session: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private createTable: CdkDynamicTableService,
    private files: FilesManageService,
    private token: TokenService,
  ) {
    this.tableGerador = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'dashboard/datatable'
      },
      pagination: {
        sort: ['id,asc']
      },
      columns: [
        {
          name: 'dataColeta', headerName: 'Data Coleta', resource: (cel: string) => {
            return cel.split('-').reverse().join('/');
          }
        },
        {name: 'unidadeGeradora', headerName: 'Gerador'},
        {name: 'codigoDestinacaoFinal', headerName: 'Código CDF', resource: (val, row) => row?.codigoDestinacaoFinal || '--'},
        {
          name: 'estimativaPeso', headerName: 'Peso da coleta (TON)', resource: (val: string | number) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {maximumFractionDigits: 2}) : '--'
          }
        },
        {name: 'pesoDia', headerName: 'Peso recebido'},
        {name: 'numeroColetas', headerName: 'Numero de coletas'},
        {name: 'totalContainersColetados', headerName: 'Total de recipientes coletados'},
        {name: 'custoTransporte', headerName: 'Custo de transporte'},
        {name: 'custoToneladaDestinacao', headerName: 'Custo da tonelada'},
        {name: 'tipoLixoNome', headerName: 'Tipo de residuo'},
        {name: 'containerNome', headerName: 'Nome do container'},
        {name: 'custoTotal', headerName: 'Custo total da coleta'},
      ],
      filters: {
        reactive: false,
        group: 'tableGerador'
      }
    })

    this.tableGerador.observables.dataSource.subscribe(resp => {
      if (resp.type == 'end') {
        this.controlles.loaders.finish('tableGerador')
      }
    })

    // if (activatedRoute.snapshot.queryParams['coletas'] || this.isPublic) {
    //   const filtersParams = this.getQueryParams()
    //   this.formFilter.patchValue(filtersParams);
    //   this.initPermissions()
    //   this.loaderFilterList()
    //   this.initLoaderData()
    // } else {
    //   alert(this.isPublic)
    //   this.initPermissions()
    //   this.loaderFilterList()
    // }

  }

  /**#################################################
   * ## INICIALIZAÇÃO E PERMISSAO
   #################################################*/

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['coletas'] || this.isPublic) {
      const filtersParams = this.getQueryParams()

      this.formFilter.patchValue(filtersParams);

      if (this.isPublic) {
        this.controlles.listLoaderPermission = filtersParams.listLoaderPermission || []
        this.initLoaderData()
      } else {
        this.initPermissions()
        this.loaderFilterList()
        this.initLoaderData()
      }
    } else {
      this.initPermissions()
      this.loaderFilterList()
    }
  }

  ngOnDestroy() {
    this.tableGerador.destroy()
  }

  initPermissions() {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.controlles.permissionFilters = [
        'favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.controlles.listLoaderPermission = [
        'anos', 'meses', 'totais', 'tipoResiduos', 'motoristas', 'lixoZero', 'tableGerador', 'gruposResiduos',
        'tratamento'
      ]

    } else if (this.session.checkPerfil(['PARCEIRO'])) {
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.controlles.permissionFilters = [
        'favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]

    } else if (this.session.checkPerfil(['CASA', 'EMPRESA', 'EVENTO', 'CONDOMINIO'])) {
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.controlles.permissionFilters = [
        'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.formFilter.patchValue({
        unidades: [this.session.user.cliente_id]
      })
      this.changeFilters()
      this.initLoaderData()

    } else if (this.session.checkPerfil(['GALPAO'])) {
      this.controlles.permissionFilters = [
        'unidades', 'tipoResiduos', 'motoristas', 'dataInicio',
        'dataFim', 'ativo', 'gruposResiduos', 'tratamento'
      ]
      this.controlles.listLoaderPermission = ['anos', 'meses', 'totais', 'tipoResiduos', 'motoristas', 'lixoZero', 'tableGerador', 'gruposResiduos', 'tratamento']
      this.formFilter.patchValue({
        galpao: [this.session.user.galpao_id]
      })
      this.changeFilters()
      this.initLoaderData()
    }

    if (this.controlles.permissionFilters.includes('favoritos')) {
      this.loaderFavoritos()
    }
  }

  /**#################################################
   * ## FILTROS
   #################################################*/
    // requests dos filtros, foram criados dessa forma, pois são chamados em duas
    // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
    // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    geradores: () => {
      if (!!this.controlles.handleHequestsFilters['geradores']) {
        this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
        this.controlles.handleHequestsFilters['geradores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['operadores'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
    },
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    motoristas: () => {
      if (!!this.controlles.handleHequestsFilters['motoristas']) {
        this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
        this.controlles.handleHequestsFilters['motoristas'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['transportador'] = list)
    },
  }

  loaderFilterList(filterList?: string) {
    const rootParams = this.filterHelpers.getFormFilterValues(this.formFilter, true)
    //TODO mudar isso para o this.requestsFilters
    const requests = {
      geradores: () => {
        if (!!this.controlles.handleHequestsFilters['geradores']) {
          this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
          this.controlles.handleHequestsFilters['geradores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['operadores'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
      },
      residuos: () => {
        if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
          this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
          this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['unidades']) {
          params['clienteId'] = params['unidades'];
          delete params['unidades'];
        }
        if (params['tipoResiduos']) {
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
      },
      motoristas: () => {
        if (!!this.controlles.handleHequestsFilters['motoristas']) {
          this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
          this.controlles.handleHequestsFilters['motoristas'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
      },
      transportadores: () => {
        if (!!this.controlles.handleHequestsFilters['transportadores']) {
          this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
          this.controlles.handleHequestsFilters['transportadores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', rootParams, list => this.controlles.filtersOptions['transportador'] = list)
      },
      gruposResiduos: () => {
        if (!!this.controlles.handleHequestsFilters['gruposResiduos']) {
          this.controlles.handleHequestsFilters['gruposResiduos']?.unsubscribe();
          this.controlles.handleHequestsFilters['gruposResiduos'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['gruposResiduos'] = this.filterHelpers
          .loaderList2(
            'gruposResiduos',
            rootParams,
              (list) => this.controlles.filtersOptions['gruposResiduos'] = list
          )
      },
      tratamento: () => {
        if (!!this.controlles.handleHequestsFilters['tratamento']) {
          this.controlles.handleHequestsFilters['tratamento']?.unsubscribe();
          this.controlles.handleHequestsFilters['tratamento'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['tratamento'] = this.filterHelpers
          .loaderList2(
            'tratamento',
            rootParams,
              (list) => this.controlles.filtersOptions['tratamento'] = list
          )
      },
    }

    if (!filterList) {
      if (this.controlles.permissionFilters.includes('unidades')) requests.geradores()
      if (this.controlles.permissionFilters.includes('tipoResiduos')) requests.residuos()
      if (this.controlles.permissionFilters.includes('motoristas')) requests.motoristas()
      if (this.controlles.permissionFilters.includes('transportadores')) requests.transportadores()
      if (this.controlles.permissionFilters.includes('gruposResiduos')) requests.gruposResiduos()
      if (this.controlles.permissionFilters.includes('tratamento')) requests.tratamento()

      return
    }


    const mapAtualisacoes: { [key: string]: string[] } = {
      unidades: ['tipoResiduos', 'transportadores', 'tratamento'],
      transportadores: ['unidadesunidades', 'motoristas', 'tipoResiduos'],
      tipoResiduos: ['transportadores', 'unidades', 'motoristas'],
    }

    if (!this.controlles.permissionFilters.includes(filterList)) return;

    if (!(filterList in mapAtualisacoes)) return;

    if (mapAtualisacoes[filterList].includes('unidades')) {
      requests.geradores()
    }

    if (mapAtualisacoes[filterList].includes('transportadores')) {
      requests.transportadores()
    }

    if (mapAtualisacoes[filterList].includes('tipoResiduos')) {
      requests.residuos()
    }

    if (mapAtualisacoes[filterList].includes('motoristas')) {
      requests.motoristas()
    }

    if (mapAtualisacoes[filterList].includes('gruposResiduos')) {
      requests.gruposResiduos()
    }

    if (mapAtualisacoes[filterList].includes('tratamento')) {
      requests.tratamento()
    }
  }

  loaderFavoritos() {
    const paramsLoaderFavoritos:{[key:string]: any} = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    this.filterHelpers.loaderListFavoritos('coletas', paramsLoaderFavoritos,list => this.controlles.filtersOptions['favoritos'] = list)
  }

  changeFilters(nameFilter?: string) {
    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {coletas: Object.keys(queryParams).length > 0 ? JSON.stringify(queryParams) : null},
        queryParamsHandling: 'merge',
      }
    ).then();
    // if (!!nameFilter) this.loaderFilterList(nameFilter)
    this.loaderFilterList(nameFilter)
  }

  clearFilters() {
    this.formFilter.patchValue({
      favoritos: '',
      unidades: [],
      transportador: [],
      galpao: [],
      tipoResiduos: [],
      motorista: [],
      dataInicio: '',
      dataFim: '',
      ativo: '',
      gruposTipoResiduo: [],
      tratamentos: [],
    })
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    this.changeFilters()
  }

  propagarFavorito() {
    const favoritoValues = this.favoritos.selectedList[0]

    if (!favoritoValues) return;

    this.formFilter.patchValue({
      unidades: (favoritoValues?.clientes || []).map((item: any) => item.id),
      galpao: (favoritoValues?.galpoes || []).map((item: any) => item.id),
      motoristas: (favoritoValues?.motoristas || []).map((item: any) => item.id),
      tipoResiduos: (favoritoValues?.tipoLixos || []).map((item: any) => item.id),
      dataInicio: favoritoValues?.dataInicio,
      dataFim: favoritoValues?.dataFim,
    })

    this.changeFilters()
  }

  /**#################################################
   * ## DADOS
   #################################################*/

  initLoaderData() {
    if (JSON.stringify(this.controlles.lastFilter) == JSON.stringify(this.filterHelpers.getFormFilterValues(this.formFilter))) {
      return;
    }

    this.clearData()
    this.controlles.lastFilter = this.filterHelpers.getFormFilterValues(this.formFilter);

    if (Object.keys(this.filterHelpers.getFormFilterValues(this.formFilter)).length <= 0) {
      this.controlles.loaders.register([])
      return
    }

    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    this.verifyShow.loaderInfo = true;
    this.controlles.loaders.register(['info'])
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    removeMessageCallbackColumn(['dashboard-coletas'])

    this.controlles.handleHequests['info'] = this.http.get('dashboard/info', {params: queryParams}).subscribe(
      resp => {
        this.verifyShow.loaderInfo = false;
        this.dataDashboard.cardInfo.numeroApartmentos = resp.data.numeroDeApartamentos || 0;
        this.dataDashboard.cardInfo.numeroClientes = resp.data.numeroDeUnidades || 0;
        this.verifyShow.hasAnyDados = resp.data.hasAnyDados;

        if (!!resp.data.hasAnyDados) {
          this.controlles.loaders.register(this.controlles.listLoaderPermission)
          this.loaderData()
        } else {
          this.controlles.loaders.register([])
        }
      }
    )
  }

  loaderData() {
    const params = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    if (params['tipoResiduos']) {
      params['tipoResiduo'] = params['tipoResiduos'];
      // delete params['tipoResiduos'];
    }

    if (this.controlles.listLoaderPermission.includes('anos')) {
      this.controlles.handleHequests['anos'] = this.http.get('dashboard/anos', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('anos')
          setGraph('id-chart-anos', resp.data.charts[0])
          this.dataDashboard.dataGraphs.ano = this.filterHelpers.extractChartData(resp.data.charts[0]);
        },
        () => {
          this.controlles.loaders.finish('anos', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório do Ano',
            keys: ['dashboard-coletas', 'anos'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('meses')) {
      this.controlles.handleHequests['meses'] = this.http.get('dashboard/meses', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('meses')
          setGraph('id-chart-meses', resp.data.charts[0])
          this.dataDashboard.dataGraphs.meses = this.filterHelpers.extractChartData(resp.data.charts[0])
        },
        () => {
          this.controlles.loaders.finish('meses', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar relatório de Meses',
            keys: ['dashboard-coletas', 'meses'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('totais')) {
      this.controlles.handleHequests['totais'] = this.http.get('dashboard/totais', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('totais')
          setGraph('id-chart-totais', resp.data.charts[0])
          this.dataDashboard.dataGraphs.totais = this.filterHelpers.extractChartData(resp.data.charts[0])
        },
        () => {
          this.controlles.loaders.finish('totais', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de totais',
            keys: ['dashboard-coletas', 'totais'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('tipoResiduos')) {
      this.controlles.handleHequests['tipoResiduos'] = this.http.get('dashboard/residuos', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('tipoResiduos');
          setGraph('id-chart-residuos', resp.data.charts[0])
          this.dataDashboard.dataGraphs.residuos = this.filterHelpers.extractChartPie(resp.data);
        },
        () => {
          this.controlles.loaders.finish('tipoResiduos', true);
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de resíduos',
            keys: ['dashboard-coletas', 'tipoResiduos'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('motoristas')) {
      if (this.controlles.listLoaderPermission.includes('motoristas')) this.controlles.handleHequests['motoristas'] = this.http.get('dashboard/motoristas', {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('motoristas')
          setGraph('id-chart-motoristas', resp.data.charts[0])
          this.dataDashboard.dataGraphs.motoristas = this.filterHelpers.extractChartData(resp.data.charts[0]).map((item) => {

            // item.seriesData['estimativa'] = item.seriesData['Estimativa (ton)']
            // item.seriesData['peso'] = item.seriesData['Peso (ton)']
            // delete item.seriesData['Estimativa (ton)'];
            // delete item.seriesData['Peso (ton)'];

            return item
          })
        },
        () => {
          this.controlles.loaders.finish('motoristas', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o relatório de motoristas',
            keys: ['dashboard-coletas', 'motoristas'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('lixoZero')) {
      this.controlles.handleHequests['lixoZero'] = this.http.get(DASHBOARD_ATERRO_ZERO, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('lixoZero')
          setGraph('id-chart-aterro-zero', resp.data[0])
        },
        () => {
          this.controlles.loaders.finish('lixoZero', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Gráfico de lixo zero"',
            keys: ['dashboard-coletas', 'lixoZero'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('tableGerador')) {
      this.tableGerador.controls.filters.patchValue(params)
      this.tableGerador.find()
    }
    if (this.controlles.listLoaderPermission.includes('tratamento')) {
      this.controlles.handleHequests['tratamento'] = this.http.get(DASHBOARD_TRATAMENTO, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('tratamento')
          setGraph('id-chart-tratamento', resp.data.charts[0])
          this.dataDashboard.dataGraphs.tratamento = this.filterHelpers.extractChartData(resp.data.charts[0]).map((item) => {
            return item
          })
        },
        () => {
          this.controlles.loaders.finish('tratamento', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Gráfico de lixo zero"',
            keys: ['dashboard-coletas', 'tratamento'],
          })
        }
      )
    }
    if (this.controlles.listLoaderPermission.includes('gruposResiduos')) {
      this.controlles.handleHequests['gruposResiduos'] = this.http.get(DASHBOARD_GRUPOS_RESIDUOS, {params}, true).subscribe(
        resp => {
          this.controlles.loaders.finish('gruposResiduos')
          setGraph('id-chart-gruposResiduos', resp.data.charts[0])
          this.dataDashboard.dataGraphs.gruposResiduos = this.filterHelpers.extractChartPie(resp.data).map((item:any) => {

            item['percentual'] = item?.percentage || '--'
            item['peso'] = item?.y || '--'

            return item;
          })
        },
        () => {
          this.controlles.loaders.finish('gruposResiduos', true)
          addMessageCallbackColumn({
            status: 'warning',
            title: 'Coletas: Erro ao gerar relatórios',
            message: 'Erro ao gerar o "Grupo de resíduos"',
            keys: ['dashboard-coletas', 'gruposResiduos'],
          })
        }
      )
    }
  }

  clearData() {
    this.verifyShow.hasAnyDados = undefined;
    this.dataDashboard.cardInfo = {
      numeroClientes: 0,
      numeroApartmentos: 0
    }
  }

  /**#################################################
   * ## FUNÇÕES ADICIONAIS
   #################################################*/

  getQueryParams() {
    if (this.isPublic) {
      if (
        !this.activatedRoute.snapshot.params['id'] ||
        !this.activatedRoute.snapshot.queryParams['listLoaders']
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Dados de busca não encontrados'
        }).then()
        return
      }

      const {
        data_fim, data_inicio, tipo_residuo, estados, galpao_id, cliente_id, ativo,
      } = <any>this.token.abrirToken((this.activatedRoute.snapshot.params['id']));


      const result: any = {}

      if (this.activatedRoute.snapshot.params['id']) result['token'] = this.activatedRoute.snapshot.params['id'];
      if (!!data_fim) result['dataFim'] = data_fim;
      if (!!data_inicio) result['dataInicio'] = data_inicio;
      if (typeof ativo == 'boolean') result['ativo'] = ativo;
      if ((tipo_residuo || []).length > 0) result['tipoResiduo'] = tipo_residuo.length > 1 ? tipo_residuo.join(',') : tipo_residuo;
      if ((galpao_id || []).length > 0) result['galpao'] = galpao_id.length > 1 ? galpao_id.join(',') : galpao_id;
      if ((cliente_id || []).length > 0) result['unidades'] = cliente_id.length > 1 ? cliente_id.join(',') : cliente_id;

      return {
        ...result,
        listLoaderPermission: JSON.parse(this.activatedRoute.snapshot.queryParams['listLoaders'])
      };
    }

    if (!this.activatedRoute.snapshot.queryParams['coletas']) return {}
    return JSON.parse(this.activatedRoute.snapshot.queryParams['coletas']);
  }

  private ajustDataByEventDispacher(data: any) {
    const { data_fim, data_inicio, tipo_residuo, estados, galpao_id, cliente_id, ativo } = data;

    const result: any = {}

    if (this.activatedRoute.snapshot.params['id']) result['token'] = this.activatedRoute.snapshot.params['id'];
    if (!!data_fim) result['dataFim'] = data_fim;
    if (!!data_inicio) result['dataInicio'] = data_inicio;
    if (typeof ativo == 'boolean') result['ativo'] = ativo;
    if ((tipo_residuo || []).length > 0) result['tipoResiduo'] = tipo_residuo.length > 1 ? tipo_residuo.join(',') : tipo_residuo;
    if ((galpao_id || []).length > 0) result['galpao'] = galpao_id.length > 1 ? galpao_id.join(',') : galpao_id;
    if ((cliente_id || []).length > 0) result['unidades'] = cliente_id.length > 1 ? cliente_id.join(',') : cliente_id;

    return result;
  }

  getPrintParams() {
    let coletas = this.getQueryParams();
    const result = {
      coletas: ''
    }

    result.coletas = JSON.stringify({
      ...coletas,
      pagination: this.tableGerador.controls.pagination.get()
    })

    return result;
  }

  downloadExcelDetalhamento() {
    let coletas = this.getQueryParams()
    const params = {
      unpaged: true,
      ...coletas
    };

    this.http.get('dashboard/datatable/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta.xlsx')
      }
    )
  }

  print() {
    this.dataDashboard.queryPrint = this.getPrintParams();

    setTimeout(() => document.getElementById('link-print-coletas')?.click(), 100)
  }

  compartilharRelatorio() {
    const params = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.http.get('dashboard/share', {params: {
      ...params,
      }}).subscribe(
      resp => {
        const url = this.router.createUrlTree(['dashboard2-coletas', resp.data], {
          queryParams: {
            listLoaders: JSON.stringify(this.controlles.listLoaderPermission)
          }
        })
        window.open(url.toString(), '_blank')
      }
    )
  }
}
