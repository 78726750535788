<div id="page-faq" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">FAQ - Perguntas frequentes</h4>
        </div>

        <div class="card-body">
          <ul class="faq-list">
            <li
              *ngFor="let faq of listFaq; let i = index"
            >
              <p
                class="faq-title"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-ano-'+i"
                aria-expanded="false"
                [attr.aria-controls]="'collapse-ano-'+i"
              >
                {{faq.title}}
              </p>

              <div class="collapse container" id="collapse-ano-{{i}}">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <p>
                      {{faq.text}}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </card>
    </div>
  </div>
</div>
