import {AbstractEntity, DgTableColumn} from "@datagrupo/dg-crud";
import {InputHelpers} from "../../../helpers/input.helpers";

export interface interfTipoLixoFilho {
  id: number | string;
  nome: string
}

export class TipoLixoCompostoEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    porcentagemFilho?: number,
    tipoLixoFilho?: interfTipoLixoFilho,
    dataExclusao?: string
  ) {
    super();
    this.id = id;
    this.porcentagemFilho = porcentagemFilho;
    this.tipoLixoFilho = tipoLixoFilho;
    this.dataExclusao = dataExclusao
  }

  @DgTableColumn({
    columnName: 'nome',
    resource: (val: interfTipoLixoFilho, row: TipoLixoCompostoEntity) => {
      return `<p ${!!row.dataExclusao ? 'class="fc-red" style="text-decoration: line-through"' : ''}>${val.nome}</p>`
    }
  })
  public tipoLixoFilho: interfTipoLixoFilho | undefined;

  @DgTableColumn({
    columnName: 'Porcentagem',
    resource: (val: number) => {
      return !!val ? InputHelpers.initInputMoney(val) + '%' : '--';
    }
  })
  public porcentagemFilho: number | undefined;
  public dataExclusao: string | undefined;

}
