<old-dg-modal
    #modal
    size="md"
    title="Dados do Produto da Nota Fiscal"
    [afterClose]="reset"
>
    <div dg-content>
        <form [formGroup]="formModalProd">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">
                        Produto
                        <span class="fc-red">*</span>
                    </label>
                    <select
                        class="form-select"
                        formControlName="produtos"
                    >
                        <option
                            disabled
                            value
                        >
                            Selecione
                        </option>
                        <option
                            *ngFor="let prod of listProdutos"
                            value="{{prod.id}}"
                        >
                            {{ prod.nome }}
                        </option>
                    </select>
                </div>



                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">
                        Toneladas
                        <span class="fc-red">*</span>
                    </label>
                    <input
                        min="0"
                        class="form-control"
                        formControlName="quantidade"
                    >
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">
                        Preço por Tonelada
                        <span class="fc-red">*</span>
                    </label>
                    <div class="input-group w-100">
                        <div class="input-group-prepend">
                            <span
                                class="input-group-text"
                                id="basic-addon1"
                            >
                                R$:
                            </span>
                        </div>
                        <input
                            class="form-control"
                            formControlName="precoPorTonelada"
                        >
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div dg-footer>
        <button
            (click)="salvarProdutos()"
            class="btn btn-success m-1"
            dg-desable-by-period
        >
            Salvar
        </button>
        <button
            (click)="modal.close()"
            class="btn btn-secondary m-1"
        >
            Sair
        </button>
    </div>
</old-dg-modal>
