<div class="filter-search" style="max-width: 100% !important; position: relative">
  <div class="container-fluid">
    <div class="col-12 p-1">
      <p class="destaque-claro f-bold">Pesquisar</p>
      <hr>
    </div>
  </div>
  <crud-filters
    [http]="http.http"
    [entityMap]="entityMap"
    [tableEventId]="tableEventId"
    (dispatchFindFilters)="dispatchFindFilters.emit($event)"
    [table]="table"
  ></crud-filters>

  <div class="container-fluid">
    <div class="col-12 p-1">
      <hr>
    </div>
  </div>

</div>
