import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientesFuncionariosEntity } from "../clientes-funcionarios.entity";
import { ClientesFuncionariosService } from "../services/clientes-funcionarios.service";
import { SessionService } from "../../../../../core/services/session-service/session.service";
import { CrudDataTableComponent, InterfaceCustomActions } from '@datagrupo/dg-crud';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Session } from 'inspector';

@Component({
  selector: 'app-clientes-funcionarios-main',
  templateUrl: './clientes-funcionarios-main.component.html',
  styleUrls: ['./clientes-funcionarios-main.component.scss']
})
export class ClientesFuncionariosMainComponent implements OnInit {

  public mapEntity = new ClientesFuncionariosEntity()
  public TableParams = {};

  @ViewChild('tableClienteFuncionario') tableClienteFuncionario!: CrudDataTableComponent

  public customAction: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: ClientesFuncionariosEntity) => {
        this.route.navigate(['user/clientes/funcionarios/' + val.id])
      },
      permission: (val: ClientesFuncionariosEntity) => this.session.user.profile == 'SINDICO' && val.tipoUsuario != 'MORADOR' || this.session.user.perfil == 'ADMINISTRADOR'  ? true : false 
    },
    {
      name: 'Excluir',
      action: (val: ClientesFuncionariosEntity) => {
        Swal.fire({
          icon: 'warning',
          title: "Excluir registro",
          text: 'Deseja excluir este registro? Esta ação é permanente.',
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          confirmButtonColor: 'red',
          cancelButtonColor: 'blue',
          cancelButtonText: 'Cancelar'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.service.delet('users/' + val.id).subscribe(() => this.tableClienteFuncionario._pesquisar())
            Swal.fire({
              title: 'Deletado com sucesso',
              text: 'Esse registro foi deletado com sucesso',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            })
          }
        })
      },
      permission: (val: ClientesFuncionariosEntity) => this.session.user.profile == 'SINDICO' && val.tipoUsuario != 'MORADOR' || this.session.user.perfil == 'ADMINISTRADOR' ? true : false
    }
  ]

  constructor(
    public service: ClientesFuncionariosService,
    public session: SessionService,
    public route: Router
  ) {
    if (session.checkPerfil('CONDOMINIO')) {
      this.TableParams = { clienteId:  session.user.cliente_id ? session.user.cliente_id : null }
      this.mapEntity.removeFilters(['cliente'])
    }
    if (this.session.user.profile =='HOLDING') {
      this.TableParams = { parceiroId: session.user.parceiro_id }
      this.mapEntity.removeFilters(['cliente'])
    }
    
  }

  ngOnInit(): void {
    console.log(this.session.user.profile)
  }

}
