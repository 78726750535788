import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SidebarComponent} from "../sidebar/sidebar.component";
import {SessionService} from "../../../../core/services/session-service/session.service";
import { Router } from '@angular/router';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss']
})
export class NavbarHeaderComponent implements OnInit {

  @Output() changeSidebar = new EventEmitter<any>()

  public production = environment.production;

  constructor(
    public session: SessionService,
    public route: Router
    ) { }

  ngOnInit(): void {
  }

  sidebarChange() {
    this.changeSidebar.emit();
  }

  sidebaOpened() {
    return SidebarComponent.open
  }

  sair() {
    if (confirm('sair do sistema?')) {
      this.session.logout()
    }
  }

  openNewsletter() {
    window.dispatchEvent(new CustomEvent('event-open-newsletter'));
  }

  openLix() {
    window.dispatchEvent(new CustomEvent('event-open-lix'));
  }

  openModalSearch() {
    window.dispatchEvent(new CustomEvent('open-modal-search-sistem'))
  }

  redirecionar(){
    this.route.navigate(['user/meu-perfil'])
  }

  format = (val: string | number) => {
    return Number(val).toLocaleString("pt-BR", {minimumFractionDigits: 0,
      maximumFractionDigits: 2});
  }

}
