import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {HttpOldService} from "../../../../../../core/services/http/http-old.service";
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import {CLIENTE, CLIENTE_CONTAINER} from "../../../../../../core/config/endpoint-list";
import {ContainerClienteEntity} from "../../../../_clientes/_entitys/container-cliente.entity";
import {HttpService} from "../../../../../../services/http-service/http.service";

class ContainerColetaMensal extends ContainerClienteEntity {
  selected: boolean = false;
  hasMtr: boolean = false;
  pesoMtr: string = '';
}

@Component({
  selector: 'modal-coleta-pontual',
  templateUrl: './modal-coleta-pontual.component.html',
  styleUrls: ['./modal-coleta-pontual.component.scss']
})
export class ModalColetaPontualComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;

  public form: FormGroup = new FormGroup({
    data: new FormControl('', [Validators.required]),
    grouped: new FormControl(true, [Validators.required]),
    cargoMtr: new FormControl('', [Validators.required]),
    responsavelMtr: new FormControl('', [Validators.required]),
  })

  public listContainers: any[] = [];
  pesoMtr = '';
  private dadosClienteMTR = {};


  constructor(
    // private service: HttpOldService,
    private session: SessionService,
    private http: HttpService,
  ) {
    if (session.user.cliente_id) {
      this.http.get(CLIENTE + '/' + session.user.cliente_id).subscribe(resp => {

        this.dadosClienteMTR = {
          responsavelMtr: resp?.data?.responsavel || '',
          cargoMtr: resp?.data?.cargo || ''
        }

        this.form.patchValue({
          ...this.dadosClienteMTR
        })
      })
    }
  }

  ngOnInit(): void {
  }

  setPesoMtr() {
    this.listContainers = this.listContainers.map(item => {
      if (!item.pesoMtr) {
        item.pesoMtr = this.pesoMtr;
      }
      return item;
    })
  }

  select(val: number | string | undefined, tipo: 'mtr' | 'container' = 'container') {
    if (!val) return;
    debugger
    const index = this.listContainers.findIndex((container) => container.id == val);

    if (tipo == 'mtr') {
      if (index != -1) {
        this.listContainers[index].hasMtr = !this.listContainers[index].hasMtr;
      }
    } else {
      if (index != -1) {
        if (this.listContainers[index].selected) {
          this.listContainers[index].selected = false;
          this.listContainers[index].hasMtr = false;
        } else {
          this.listContainers[index].selected = true;
          this.listContainers[index].hasMtr = true;
        }
      }
    }

  }

  verify(val: any): boolean {
    const index = this.listContainers.findIndex((container) => container.id == val);

    if (index != -1) {
      return this.listContainers[index].selected
    }

    return false;
  }

  enviarPontual() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if ((this.listContainers || []).length <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'selecione um recipiente'
      }).then()
      return;
    }

    if ((this.listContainers || []).filter(item => (!!item.selected && !!item.hasMtr && !item.pesoMtr)).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'O peso do MTR deve ser preenchido'
      }).then()
      return;
    }

    const form = this.form.value;

    const data = {
      ...form,
      cliente: { id: this.session.user.cliente_id },
      listClienteContainer: this.listContainers.map(cont => {
        if (cont.selected) {
          return {
            ...cont,
            dataRota: this.form.value.data,
          }
        }
        return null
      }).filter(cont => !!cont)
    }

    this.http.post(
      'coletas/plano-pontual',
      data
    ).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: "Enviado",
          text: 'Planejamento realizado, você receberá um e-mail quando puder checar o resultado.'
        }).then()
        this.close()
      }
    )
  }

  open() {
    this.http.get(CLIENTE_CONTAINER, {params: {clienteId: this.session.user.cliente_id, unpaged: true}}).subscribe(
      (resp: any) => {
        console.log(resp.data)
        this.listContainers = <ContainerColetaMensal[]>(resp.data || [])
          .map((resp: ContainerColetaMensal) => {
            return {
              ...resp,
              selected: false,
              hasMtr: false,
              pesoMtr: '',
              openObs: false,
            }
          })
          .filter((container: ContainerColetaMensal) => {
              if ('dataDesativacao' in container) {
                // @ts-ignore
                return !container['dataDesativacao']
              }
              return true;
            }
          )
        this.modal.open();
      }
    )
  }

  close() {
    this.modal.close()
  }

  clear = () => {
    this.form.reset({
      data: '',
      grouped: true,
      ...this.dadosClienteMTR
    })
  }

  clearPesoMtr(index: number) {
    if (!this.listContainers[index]) return;
    const newValue = String(this.listContainers[index]?.pesoMtr || '').replace(/[a-zA-Z.]/g, '');

    this.listContainers[index].pesoMtr = newValue || '';
  }
}
