<div id="dashboard-coleta" style="max-width: calc(100% - 5rem) !important;">

  <div class="container-data">
    <card>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12">
            <div class="row justify-content-between">
              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold">Número de clientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{numeroClientes}}</span>
                    </div>
                  </div>
                </card>
              </div>

              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <card>
                  <div class="card-body">
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span matTooltip="Número de apartamentos/departamento/lojas"
                            class="destaque-claro f-bold">Ambientes</span>
                    </div>
                    <div class="w-100 d-inline-flex justify-content-center">
                      <span class="destaque-claro f-bold fs-15">{{numeroApartmentos}}</span>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>

  <div class="container-data">
    <div class="row d-flex align-items-stretch">
      <div
        *ngFor="let grapg of listGraph; let i = index"
        class="col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1"
      >
        <app-card-graph [id]="criarId(i)" [dataGraphic]="grapg">
        </app-card-graph>

      </div>

      <!--      <div class="col-card-gravimetria col-xl-6 col-lg-3 col-md-12 col-sm-12 mt-1 mb-1">-->
      <!--        <card>-->
      <!--          <div class="card-header">-->
      <!--            <h4>Relatório de Gravimetría</h4>-->
      <!--          </div>-->
      <!--          <div class="card-body">-->
      <!--            Calculando...-->
      <!--          </div>-->
      <!--          <div class="card-footer"></div>-->
      <!--        </card>-->
      <!--      </div>-->
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <card-impacto-ambiental></card-impacto-ambiental>
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <card-grafico-aterro-zero></card-grafico-aterro-zero>
    </div>
  </div>

  <div class="container-data">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Detalhamento por Gerador</h4>
            </div>
            <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header" (click)="downloadExcelDetalhamento()">
                <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                Download Excel
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body">
        <crud-data-table
          #table
          [tableEventId]="'tabelaDetalhamento'"
          [service]="service"
          [entityMap]="entity"
          [persistentParams]="persistParams"
          [showDefaultAction]="false"
          [customDblclick]="true"
          [endpoint]="'datatable'"
        ></crud-data-table>
      </div>
      <div class="card-footer">
        <dg-pagination
          [dataPagination]="entity.configEntityTable.pagination"
          (changePage)="table.changePages($event)"
        ></dg-pagination>
      </div>
    </card>
  </div>

  <div class="container-fluid">
    <card>
      <div class="card-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h4 class="card-title">Insumos para o relatório GRI/SASB (GRI 306)</h4>
              <h6 class="card-subtitle">
                Este relatório foi elaborado em conformidade com
                as Normas GRI: opção Abrangente
              </h6>
            </div>

            <card-header-buttons class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <button class="btn btn-success btn-card-header" (click)="downloadExcelGri()">
                <mat-icon style="margin-right: .5rem !important;">download</mat-icon>
                Download Excel
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>
      <div class="card-body table-gri">
<!--        <crud-data-table-->
<!--          #tabelaGri-->
<!--          [tableEventId]="'tabelaGRI'"-->
<!--          [entityMap]="entityGri"-->
<!--          [showDefaultAction]="false"-->
<!--          [customDblclick]="true"-->
<!--          [persistentParams]="persistParams"-->
<!--        ></crud-data-table>-->
      </div>
      <div class="card-footer">
<!--        <dg-pagination-->
<!--          [dataPagination]="entityGri.configEntityTable.pagination"-->
<!--          (changePage)="tabelaGri.changePages($event)"-->
<!--        ></dg-pagination>-->
      </div>
    </card>
  </div>

</div>
