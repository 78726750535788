import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientesEntity} from "../../../_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../_galpao/galpao/galpao.entity";
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {CLIENTE_FIND_FILTER, GALPAO_FIND_FILTER} from "../../../../../core/config/endpoint-list";
import {FormControl, FormGroup} from "@angular/forms";
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {HttpParams} from "@angular/common/http";
import Swal from "sweetalert2";
import {InputAutocompleteComponent} from "@datagrupo/dg-ng-util";

@Component({
  selector: 'modal-replicar',
  templateUrl: './modal-replicar.component.html',
  styleUrls: ['./modal-replicar.component.scss']
})
export class ModalReplicarComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;
  @ViewChild('autoCompUnidade') autoCompUnidade!: InputAutocompleteComponent;
  @ViewChild('autoCompOperador') autoCompOperador!: InputAutocompleteComponent;

  @Input() id: string | number | undefined;

  public listaUnidades: ClientesEntity[] = [];
  public listaGalpao: GalpaoEntity[] = [];

  form = new FormGroup({
    cliente: new FormControl([]),
    galpao: new FormControl([]),
  });

  constructor(
    public service: HttpOldService,
    public session: SessionService
  ) {
    service.get(CLIENTE_FIND_FILTER, {params: {unpaged: true}}).subscribe((resp: any) => {
      this.listaUnidades = resp.data;
    });
    service.get(GALPAO_FIND_FILTER, {params: {unpaged: true}}).subscribe((resp: any) => {
      this.listaGalpao = resp.data;
    });
  }

  ngOnInit(): void {
  }

  open() {
    if (!this.id || !this.session.user.parceiro_id) return;
    this.modal.open();
  }

  replicar(){
    const form = this.form.value;

    if ((form.galpao || []).length == 0 && (form.cliente || []).length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Selecione as unidades geradoras e/ou operadores logísticos'
      }).then();
      return;
    }

    let params = new HttpParams()
      .set('galpaoId', String(form.galpao))
      .set('clienteId', String(form.cliente))
      .set('id', String(this.id));

    this.service.patch('white-labels/'+this.id+'/replicate?'+params.toString(), {}).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'White label replicado com sucesso'
      }).then();

      this.modal.close();
    })
  }

  afterClose = () => {
    this.form.reset('')
    this.autoCompUnidade.clear()
    this.autoCompOperador.clear()
  }
}
