import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestaoJornadaEducacaoAmbientalMainComponent } from './gestao-jornada-educacao-ambiental-main/gestao-jornada-educacao-ambiental-main.component';
import { GestaoJornadaEducacaoAmbientalInsertEditComponent } from './gestao-jornada-educacao-ambiental-insert-edit/gestao-jornada-educacao-ambiental-insert-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from 'ngx-mask';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { VisualizaJornadaComponent } from './subComponents/visualiza-jornada/visualiza-jornada.component';
import {DirectivesModule} from "../../../core/directives/directives.module";
import { DgAutocompleteModule } from '@datagrupo/dg-ng-util';



@NgModule({
  declarations: [
    GestaoJornadaEducacaoAmbientalMainComponent,
    GestaoJornadaEducacaoAmbientalInsertEditComponent,
    VisualizaJornadaComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AngularEditorModule,
        DirectivesModule,
        DgAutocompleteModule
    ]
})
export class GestaoJornadaEducacaoAmbientalModule { }
