import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

export declare type callbackMessageColumn = {
  title: string,
  status?: 'success' | 'warning' | 'danger'
  message?: string,
  keys?: string[],
  closeChangeRouter?: boolean
}

@Component({
  selector: 'system-return-message-column',
  templateUrl: './system-return-message-column.component.html',
  styleUrls: ['./system-return-message-column.component.scss']
})
export class SystemReturnMessageColumnComponent implements OnInit {

  listMessages: (callbackMessageColumn & {open: boolean})[] = [];

  private currentRoute: string;

  constructor(private router: Router) {
    this.currentRoute = this.router.url;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const newRoute = this.router.url.split('?')[0];
        if (newRoute !== this.currentRoute) {
          this.listMessages = this.listMessages.filter(item => !item?.closeChangeRouter);
          this.currentRoute = newRoute;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  @HostListener('window:system-return-message-column-add', ['$event'])
  addMessage(ev: CustomEvent<callbackMessageColumn>) {
    const newMessage = {
      closeChangeRouter: true,
      ...ev.detail,
      open: true
    };

    // Adiciona a nova mensagem no início da lista
    this.listMessages = [newMessage, ...this.listMessages];

    // Configura o fechamento automático após 3 segundos (3000ms)
    setTimeout(() => {
      const index = this.listMessages.indexOf(newMessage);
      if (index > -1) {
        this.close(index);
      }
    }, 5000);
  }

  @HostListener('window:system-return-message-column-remove', ['$event'])
  removeMessage(ev: CustomEvent<string[]>) {
    this.listMessages = this.listMessages.filter(item => {
      if (!item.keys) return true;

      return !item.keys.some(key => ev.detail.includes(key));
    })
  }

  close(index: number) {
    this.listMessages.splice(index, 1)
  }
}
