import { Component, OnInit } from '@angular/core';
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'subComponent-card-promo1',
  templateUrl: './card-promo1.component.html',
  styleUrls: ['./card-promo1.component.scss']
})
export class CardPromo1Component implements OnInit {

  data = {
    size: '110% 102%',
    repeat: 'no-repeat',
    position: '50% 80%',
  }

  constructor(public session: SessionService) { }

  ngOnInit(): void {
  }

}
