import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ProgressLoader} from "../../../../../helpers/progress-loader";
import {DashboardFilterHelperService} from "../../services/dashboard-filter-helper/dashboard-filter-helper.service";
import {HttpService} from "../../../../../services/http-service/http.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {addMessageCallbackColumn, removeMessageCallbackColumn} from "../../../../../helpers/events.helpers";
import {setGraph} from "../../../dashboard/helpers/highcharts.helper";
import {CdkDynamicTable, CdkDynamicTableService, DgAutocomplete3Component} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../environments/environment";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";

@Component({
  selector: 'dashboard2-impacto',
  templateUrl: './dashboard2-impacto.component.html',
  styleUrls: ['./dashboard2-impacto.component.scss']
})
export class Dashboard2ImpactoComponent implements OnInit, OnDestroy {
  @ViewChild('favoritos') favoritos!: DgAutocomplete3Component

  /**
   * formulário dos filtros
   */
  formFilter = new FormGroup({
    favoritos: new FormControl(''),
    unidades: new FormControl([]),
    galpao: new FormControl([]),
    tipoResiduos: new FormControl([]),
    motorista: new FormControl([]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    ativo: new FormControl(''),
  })

  controlles: {
    // lidando com requests
    handleHequests: { [key: string]: Subscription | undefined },
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de graficos/requests que devem ser executados
    listLoaderPermission: string[],
    // lista de filtros que pode aparecer
    permissionFilters: string[],
    // controller de loader dos dados
    loaders: ProgressLoader.classe,
    // ultimos filtros buscados
    lastFilter: any
  } = {
    filtersOptions: {
      favoritos: [],
      unidades: [],
      transportador: [],
      tipoResiduos: [],
      motoristas: [],
    },
    handleHequests: {
      info: undefined,
      anos: undefined,
      meses: undefined,
      totais: undefined,
      tipoResiduos: undefined,
      motoristas: undefined,
    },
    handleHequestsFilters: {},
    listLoaderPermission: [],
    permissionFilters: [],
    loaders: ProgressLoader.createLoader(),
    lastFilter: undefined
  }

  verifyShow: {
    hasAnyDados: boolean | undefined,
    loaderInfo: boolean,
    loaders: {
      impacto: boolean
      ods: boolean
    },
  } = {
    hasAnyDados: false,
    loaderInfo: false,
    loaders: {
      impacto: true,
      ods: true,
    }
  }

  dashboardData: {
    impacto: {
      minerio: number | string,
      areia: number | string,
      arvores: number | string,
      petroleo: number | string,
      agua: number | string,
      energia: number | string,
    },
    ods: {
      taxaReciclagem: number,
      valorIndicadorBrasil: number,
      anoIndicadorBrasil: string,
    }
  } = {
    impacto: {
      minerio: 0,
      areia: 0,
      arvores: 0,
      petroleo: 0,
      agua: 0,
      energia: 0,
    },
    ods: {
      taxaReciclagem: 0,
      valorIndicadorBrasil: 0,
      anoIndicadorBrasil: '  '
    }
  }

  tableGRI: CdkDynamicTable.tableClass

  constructor(
    public filterHelpers: DashboardFilterHelperService,
    private http: HttpService,
    public session: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private createTable: CdkDynamicTableService,
    private files: FilesManageService,
  ) {
    this.tableGRI = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'dashboard/gri-datatable',
      },
      columns: [
        {
          name: 'dataColeta', headerName: 'Data da Coleta', resource: val => {
            return !!val ? val.split('-').reverse().join('/') : '--';
          }
        },
        {name: 'unidadeGeradora', headerName: 'Gerador'},
        {name: 'identificacaoClienteContainer', headerName: 'Nome do resíduo (cliente)'},
        {name: 'categoriaTipoResiduo', headerName: 'Categoria Ecoplat '},
        {name: 'nomeTipoResiduo', headerName: 'Nome Ibama'},
        {
          name: 'codigoTipoResiduo', headerName: 'Código Ibama', resource: val => {
            return !!val ? val.split(',', 1) : '--';
          }
        },
        {name: 'classeTipoResiduo', headerName: 'Classe'},
        {
          name: 'estimativa', headerName: 'Peso da coleta estimativa (TON)', resource: (val: string | number) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {maximumFractionDigits: 2}) : '--';
          }
        },
        {
          name: 'peso', headerName: 'Peso da coletas (TON)', resource: (val: string | number) => {
            return !!val ? Number(val).toLocaleString("pt-BR", {maximumFractionDigits: 2}) : '--';
          }
        },
        {name: 'pesoRecebido', headerName: 'Peso recebido', resource: (val: string) => val || '--'},
        {name: 'tratamento', headerName: 'Tratamento'},
        {name: 'codigoMtr', headerName: 'Código MTR', resource: (val: string) => !!val ? val : '--'},
        {name: 'nomeEmpresa', headerName: 'Empresa que fez a coleta'},
        {name: 'cnpjEmpresa', headerName: 'CNPJ da empresa que fez a coleta', mask: 'cnpj'},
        {name: 'nomeIndustria', headerName: 'Empresa de Destinação', resource: (val: string) => val || '--'},
        {name: 'cnpjIndustria', headerName: 'CNPJ da empresa de destinação', mask: 'cnpj'},
        {name: 'cdf', headerName: 'N° CDF', resource: (val: string) => val || '--'},
        {name: 'codigoDestinacaoFinal', headerName: 'codigoDestinacaoFinal', resource: (val: string) => val || '--'},
      ],
      filters: {
        reactive: false,
        group: 'tableGRI'
      }
    })

    this.initPermissions()

    this.tableGRI.observables.dataSource.subscribe(resp => {
      if (resp.type == 'end') {
        this.controlles.loaders.finish('gri')
      }
    })

    if (activatedRoute.snapshot.queryParams['impacto']) {
      const filtersParams = JSON.parse(activatedRoute.snapshot.queryParams['impacto'])
      this.formFilter.patchValue(filtersParams);
      this.loaderFilterList()
    } else {
      this.loaderFilterList()
    }
    this.initLoaderData()
  }

  /**#################################################
   * ## INICIALIZAÇÃO E PERMISSAO
   #################################################*/

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tableGRI.destroy()
  }

  initPermissions() {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
      this.controlles.permissionFilters = ['favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio', 'dataFim', 'ativo']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
    }
    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      this.controlles.permissionFilters = ['favoritos', 'transportadores', 'tipoResiduos', 'motorista', 'dataInicio', 'dataFim']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
      this.formFilter.patchValue({
        unidades: [this.session.user.cliente_id]
      })
      this.changeFilters()
      this.initLoaderData()
    }
    if (this.session.checkPerfil(['GALPAO'])) {
      this.controlles.permissionFilters = ['favoritos', 'unidades', 'tipoResiduos', 'motoristas', 'dataInicio', 'dataFim', 'ativo']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
    }
  }

  /**#################################################
   * ## FILTROS
   #################################################*/
    // requests dos filtros, foram criados dessa forma, pois são chamados em duas
    // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
    // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    geradores: () => {
      if (!!this.controlles.handleHequestsFilters['geradores']) {
        this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
        this.controlles.handleHequestsFilters['geradores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['operadores'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
    },
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    motoristas: () => {
      if (!!this.controlles.handleHequestsFilters['motoristas']) {
        this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
        this.controlles.handleHequestsFilters['motoristas'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['transportador'] = list)
    },
  }

  loaderFilterList(filterList?: string) {
    const rootParams = this.filterHelpers.getFormFilterValues(this.formFilter, true)
    //TODO mudar isso para o this.requestsFilters
    const requests = {
      geradores: () => {
        if (!!this.controlles.handleHequestsFilters['geradores']) {
          this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
          this.controlles.handleHequestsFilters['geradores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['operadores'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['unidades'] = list)
      },
      residuos: () => {
        if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
          this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
          this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['unidades']) {
          params['clienteId'] = params['unidades'];
          delete params['unidades'];
        }
        if (params['tipoResiduos']) {
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
      },
      motoristas: () => {
        if (!!this.controlles.handleHequestsFilters['motoristas']) {
          this.controlles.handleHequestsFilters['motoristas']?.unsubscribe();
          this.controlles.handleHequestsFilters['motoristas'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['motoristas'] = this.filterHelpers.loaderList2('motoristas', params, list => this.controlles.filtersOptions['motoristas'] = list)
      },
      transportadores: () => {
        if (!!this.controlles.handleHequestsFilters['transportadores']) {
          this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
          this.controlles.handleHequestsFilters['transportadores'] = undefined;
        }
        const {...params} = rootParams;
        if (params['galpao']) {
          params['galpaoId'] = params['galpao'];
          delete params['galpao'];
        }
        if (params['tipoResiduos']) {
          params['tipoLixoId'] = params['tipoResiduos'];
          delete params['tipoResiduos'];
        }
        this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', rootParams, list => this.controlles.filtersOptions['transportador'] = list)
      },
    }

    if (!filterList) {
      if (this.controlles.permissionFilters.includes('unidades')) requests.geradores()
      if (this.controlles.permissionFilters.includes('tipoResiduos')) requests.residuos()
      if (this.controlles.permissionFilters.includes('motoristas')) requests.motoristas()
      if (this.controlles.permissionFilters.includes('transportadores')) requests.transportadores()

      return
    }

    const mapAtualisacoes: { [key: string]: string[] } = {
      unidades: ['tipoResiduos', 'transportadores'],
      transportadores: ['unidades', 'motoristas', 'tipoResiduos'],
      tipoResiduos: ['transportadores', 'unidades', 'motoristas']
    }

    if (!this.controlles.permissionFilters.includes(filterList)) return;
    if (!(filterList in mapAtualisacoes)) return;

    if (mapAtualisacoes[filterList].includes('unidades')) {
      requests.geradores()
    }

    if (mapAtualisacoes[filterList].includes('transportadores')) {
      requests.transportadores()
    }

    if (mapAtualisacoes[filterList].includes('tipoResiduos')) {
      requests.residuos()
    }

    if (mapAtualisacoes[filterList].includes('motoristas')) {
      requests.motoristas()
    }
  }

  changeFilters(nameFilter?: string) {
    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {impacto: Object.keys(queryParams).length > 0 ? JSON.stringify(queryParams) : null},
        queryParamsHandling: 'merge',
      }
    ).then();
    // if (!!nameFilter) this.loaderFilterList(nameFilter)
    this.loaderFilterList(nameFilter)
  }

  clearFilters() {
    this.formFilter.patchValue({
      favoritos: '',
      unidades: [],
      transportador: [],
      galpao: [],
      tipoResiduos: [],
      motorista: [],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    })
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    this.changeFilters()
  }

  propagarFavorito() {
    const favoritoValues = this.favoritos.selectedList[0]

    if (!favoritoValues) return;

    this.formFilter.patchValue({
      unidades: (favoritoValues?.clientes || []).map((item: any) => item.id),
      galpao: (favoritoValues?.galpoes || []).map((item: any) => item.id),
      motoristas: (favoritoValues?.motoristas || []).map((item: any) => item.id),
      tipoResiduos: (favoritoValues?.tipoLixos || []).map((item: any) => item.id),
      dataInicio: favoritoValues?.dataInicio,
      dataFim: favoritoValues?.dataFim,
    })

    this.changeFilters()
  }

  /**#################################################
   * ## DADOS
   #################################################*/

  initLoaderData() {
    if (JSON.stringify(this.controlles.lastFilter) == JSON.stringify(this.filterHelpers.getFormFilterValues(this.formFilter))) {
      return;
    }

    this.clearData()

    if (Object.keys(this.filterHelpers.getFormFilterValues(this.formFilter)).length <= 0) {
      this.controlles.loaders.register([])
      return
    }

    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter, true);
    this.controlles.lastFilter = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.verifyShow.hasAnyDados = false;
    this.controlles.loaders.register(this.controlles.listLoaderPermission)
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    removeMessageCallbackColumn(['dashboard-impacto'])

    if (queryParams['tipoResiduos']) {
      queryParams['tipoResiduo'] = queryParams['tipoResiduos'];
      delete queryParams['tipoResiduos'];
    }

    this.http.get('dashboard/relatorio-impacto', {params: queryParams}).subscribe(
      resp => {
        this.verifyShow.hasAnyDados = true;
        this.controlles.loaders.finish('impacto');

        (resp.data || []).map((impacto: any) => {
          if (String(impacto.indicador.nome).toLowerCase() == 'areia') {
            this.dashboardData.impacto.areia = Number(1550.05).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'arvores') {
            this.dashboardData.impacto.arvores = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'petroleo') {
            this.dashboardData.impacto.petroleo = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'agua') {
            this.dashboardData.impacto.agua = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'energia eletrica') {
            this.dashboardData.impacto.energia = Number(impacto.valor).toLocaleString('BR')
          }
          if (String(impacto.indicador.nome).toLowerCase() == 'minerio') {
            this.dashboardData.impacto.minerio = Number(impacto.valor).toLocaleString('BR')
          }
        })
      },
      () => {
        this.controlles.loaders.finish('impacto', true)
        addMessageCallbackColumn({
          status: 'warning',
          title: 'Impacto: Erro ao gerar relatórios',
          message: 'Erro ao gerar o relatório de Impacto',
          keys: ['dashboard-impacto', 'impacto'],
        })
      }
    )

    this.http.get('dashboard/indicador-ods', {params: queryParams}).subscribe(
      resp => {
        this.controlles.loaders.finish('ods')
        this.verifyShow.hasAnyDados = true;

        this.dashboardData.ods = {
          taxaReciclagem: resp.data.taxaReciclagem || 0,
          valorIndicadorBrasil: resp.data.valorIndicadorBrasil || 0,
          anoIndicadorBrasil: resp.data.anoIndicadorBrasil || '--'
        }
      },
      () => {
        this.controlles.loaders.finish('ods', true)
        addMessageCallbackColumn({
          status: 'warning',
          title: 'Impacto: Erro ao gerar relatórios',
          message: 'Erro ao gerar o relatório de ODS',
          keys: ['dashboard-impacto', 'ods'],
        })
      }
    )

    console.log('queryParams', queryParams)

    this.tableGRI.controls.filters.patchValue(queryParams)
    this.tableGRI.find();
  }

  clearData() {
    this.verifyShow = {
      hasAnyDados: false,
      loaderInfo: false,
      loaders: {
        impacto: true,
        ods: true,
      }
    }
    this.dashboardData = {
      impacto: {
        minerio: 0,
        areia: 0,
        arvores: 0,
        petroleo: 0,
        agua: 0,
        energia: 0,
      },
      ods: {
        taxaReciclagem: 0,
        valorIndicadorBrasil: 0,
        anoIndicadorBrasil: '  '
      }
    }
  }

  downloadExcelGri() {
    let coletas = this.getQueryParams()
    const params = {
      unpaged: true,
      ...coletas
    };

    this.http.get('dashboard/gri-datatable/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'dashboarda-minha_coleta-planilha-GRI.xlsx')
      }
    )
  }

  getQueryParams() {
    if (!this.activatedRoute.snapshot.queryParams['impacto']) return {}
    return JSON.parse(this.activatedRoute.snapshot.queryParams['impacto']);
  }
}
