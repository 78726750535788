import {Component, OnInit, ViewChild} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../environments/environment";
import {
  ModalIndustriaArquivosComponent
} from "../../sub-components/modal-industria-arquivos/modal-industria-arquivos.component";
import {HttpService} from "../../../../../services/http-service/http.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-industria-arquivos-main',
  templateUrl: './industria-arquivos-main.component.html',
  styleUrls: ['./industria-arquivos-main.component.scss']
})
export class IndustriaArquivosMainComponent implements OnInit {

  public table: CdkDynamicTable.tableClass;

  @ViewChild('modalIndustriaArquivosComponent') modalArquivo!: ModalIndustriaArquivosComponent;

  constructor(
    private createTable: CdkDynamicTableService,
    private http: HttpService,
    public filesManage: FilesManageService,
    private session: SessionService
  ) {
    this.table = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'industria/documentodestinador',
      },
      columns: [
        {
          headerName: 'Nome arquivo', name: 'nomeArquivo', resource: (val, row) => {
            if (!!row.vencimento) {
              const venvimento = new Date(row.vencimento);
              const currentDay = new Date();

              if (currentDay > venvimento) {
                return `<span style="text-decoration: line-through;" class="fc-red">${!!val ? val : '--'}</span>`
              }
            }

            return !!val ? val : '--';
          }
        },
        {
          headerName: 'Categoria', name: 'categoriaArquivo', resource: (val, row) => {
            if (!!row.vencimento) {
              const venvimento = new Date(row.vencimento);
              const currentDay = new Date();

              if (currentDay > venvimento) {
                return `<span style="text-decoration: line-through;" class="fc-red">${!!val?.nome ? val?.nome : '--'}</span>`
              }
            }
            return val?.nome || '--';
          }
        },
        {
          headerName: 'Vencimento',
          name: 'vencimento',
          resource: (val: string, row) => {
            const resultVal = !val ? '--' : val.split('-').reverse().join('/');
            if (!!row.vencimento) {
              const venvimento = new Date(row.vencimento);
              const currentDay = new Date();

              if (currentDay > venvimento) {
                return `<span style="text-decoration: line-through;" class="fc-red">${!!resultVal ? resultVal : '--'}</span>`
              }
            }

            return resultVal;
          }
        },
      ],
      actions: {
        edit: {name: 'Editar', dbClick: true, action: row => this.modalArquivo.open(row)},
        download: {
          name: 'Download', action: row => {
            this.http.get('industria/documentodestinador/download/' + row.id).subscribe(
              resp => {
                //@ts-ignore
                this.filesManage.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
              }
            )
          }
        },
        remove: {
          name: 'Excluir arquivo', action: row => {
            Swal.fire({
              icon: 'warning',
              title: 'Excluído arquivo',
              text: 'Essa ação não terá retorno',
              showCancelButton: true,
              cancelButtonText: 'Sair',
              confirmButtonText: 'Excluir arquivo'
            }).then(conf => {
              if (conf.isConfirmed) {
                this.http.delete('industria/documentodestinador/' + row.id).subscribe(
                  resp => {
                    this.table.find();
                    Swal.fire({
                      icon: 'success',
                      title: 'Arquivo excluído',
                      text: 'O arquivo foi excluído',
                      timer: 1500
                    }).then()
                  }
                )
              }
            })
          }, permission: row => row?.usuarioId == this.session.user.id
        },
      }
    })
  }

  ngOnInit(): void {
  }

}
