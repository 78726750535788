import {
  AbstractEntity, DgFilter_input,
  DgTableColumn,
} from '@datagrupo/dg-crud';
import {ColetasEntity, inferfAvaliacao, interfPontuacao} from "../coletas/coletas.entity";
import {RotasEntity} from "../../rotas/rotas.entity";
import {CheckinEntity} from "../coletas/checkin.entity";

const ratio = (celula: any) => {
  return (
    `<span class="material-icons" style="color: ${
      1 <= celula.estrelas ? 'yellow' : 'grey'
    }">star</span>` +
    `<span class="material-icons" style="color: ${
      2 <= celula.estrelas ? 'yellow' : 'grey'
    }">star</span>` +
    `<span class="material-icons" style="color: ${
      3 <= celula.estrelas ? 'yellow' : 'grey'
    }">star</span>` +
    `<span class="material-icons" style="color: ${
      4 <= celula.estrelas ? 'yellow' : 'grey'
    }">star</span>` +
    `<span class="material-icons" style="color: ${
      5 <= celula.estrelas ? 'yellow' : 'grey'
    }">star</span>`
  );
};

export class HistoricoColetasEntity extends AbstractEntity {

  constructor(
    checkin?: CheckinEntity,
    rota?: RotasEntity,
    pontuacao?: interfPontuacao
  ) {
    super();
    this.checkin = !!checkin ? checkin : new CheckinEntity();
    this.rota = rota;
    this.pontuacao = pontuacao;
  }
  @DgFilter_input('date', { colum: '3', name: 'DATA INICIAL' })
  public dataBuscaInicio: any;

  @DgFilter_input('date',{ colum: '3', name: 'DATA FINAL' })
  public dataBuscaFim: any;

  @DgTableColumn({ columnName: 'Data Coleta', sort: true, resource: (cel: RotasEntity) => {
      return !!cel?.dataRota ? cel.dataRota.split('-').reverse().join('/') : '--'
    }
  })
  public rota: RotasEntity | undefined;

  @DgTableColumn({columnName: 'Pontos', resource: (pontos: interfPontuacao) => !!pontos?.valor ? pontos.valor : '--'})
  public pontuacao: interfPontuacao | undefined

  @DgTableColumn({columnName: 'AVALIAÇÃO', sort: true, resource: ratio})
  public avaliacaoClienteMotorista: inferfAvaliacao | undefined;

  public checkin: CheckinEntity = new CheckinEntity();

}
