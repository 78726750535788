import {Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AbstractInsertEdit, HttpClient, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {ConfigDgCrudService} from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import {defaultCallBackSuccessGetCep, defaultCallBackErrorGetCep, verifySizeFiles} from 'src/app/helpers/helpers';
import {FilesManageService} from 'src/app/services/files-manage/files-manage.service';
import {HttpHelpersService} from 'src/app/services/http-helpers/http-helpers.service';
import {CategoriaNegocioEntity} from '../../categoria-negocio/categoria-negocio.entity';
import {CategoriaNegocioService} from '../../categoria-negocio/service/categoria-negocio.service';
import {ParceiroEntity} from '../parceiro.entity';
import {ParceiroService} from '../service/parceiro.service';
import {HoldingCadastroEntity} from '../cadastro-holding.entity';
import {CadastroHoldingComponent} from '../subComponent/cadastro-holding/cadastro-holding.component';
import {SessionService} from "../../../../core/services/session-service/session.service";
import {HttpService} from "../../../../services/http-service/http.service";

@Component({
  selector: 'app-parceiro-insert-edit',
  templateUrl: './parceiro-insert-edit.component.html',
  styleUrls: ['./parceiro-insert-edit.component.scss']
})
export class ParceiroInsertEditComponent extends AbstractInsertEdit<ParceiroEntity> implements OnInit {

  listaCategoriaNegocio: CategoriaNegocioEntity[] = [];
  listaTipoParceiro: { key: string, value: string }[] =
    [
      {key: 'PARCEIRO', value: 'Gerenciador'},
      {key: 'HOLDING', value: 'Master'},
      // { key: 'PARCEIRO_MOBILIZACAO_MORADORES', value: 'Parcerias de mobilização de usuários' },
      // { key: 'PARCEIRO_VENDA_CONDOMINIOS', value: 'Parcerias de venda da Ecoplat para as unidadeS geradoras' },
      // { key: 'PARCEIRO_PRODUTOS_SERVICOS', value: 'Empresas de produtos e serviços ligados à limpeza, higiene, coleta e saneamento básico' },
      // { key: 'ONG_PARCEIRA_ENGAJAMENTO', value: 'ONGs parceiras para missão de engajamento' },
    ]
  @ViewChild('fileUploadParceiro') fileUploadParceiro!: ElementRef;

  file: File | undefined;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cnpj: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    nomeContato: new FormControl('', [Validators.required]),
    tipoParceiro: new FormControl('', [Validators.required]),
    tipoDesconto: new FormControl(''),
    tipoNegocio: new FormControl(''),
    categoriaNegocio: new FormControl(''),
    cep: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    logradouro: new FormControl({value: '', disabled: true}, [Validators.required]),
    bairro: new FormControl({value: '', disabled: true}, [Validators.required]),
    cidade: new FormControl({value: '', disabled: true}, [Validators.required]),
    uf: new FormControl({value: '', disabled: true}, [Validators.required]),
    modulos: new FormControl([]),
  })

  public entityCadastroHolding = new HoldingCadastroEntity();

  public tableParams: { parceiroId?: number | string } = {}

  @ViewChild('modalCadastroHolding') modalCadastroHolding!: CadastroHoldingComponent

  constructor(
    public conf: ConfigDgCrudService,
    public servic: ParceiroService,
    public serviceCategoriaNegocio: CategoriaNegocioService,
    public http: HttpService,
    public router: Router,
    public files: FilesManageService,
    public httpHelp: HttpHelpersService,
    public session: SessionService
  ) {
    super(conf, servic);
    this._customEntitys();
    http.get('categoriasnegocio', {
      params: {
        unpaged: true,
      }
    }).subscribe(resp => {
      //@ts-ignore
      this.listaCategoriaNegocio = resp.data
    })

    if (!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.form.controls['modulos'].disable()
      this.form.controls['tipoParceiro'].disable()
    }
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  private _customEntitys() {

    this.entityCadastroHolding.addActions(<InterfaceCustomActions[]>[
      {
        name: 'Editar',
        action: (row: HoldingCadastroEntity) => {
          this.modalCadastroHolding.open(row)
        },
      }
    ])
  }

  initNewEntity(): void {
    this.entity = new ParceiroEntity();
    this.getCep();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      ...(this.entity.endereco),
      //@ts-ignore
      uf: this.entity.endereco.estado.uf,
      categoriaNegocio: this.entity?.categoriaNegocio?.id
    })
    this.tableParams = {parceiroId: this.entity.id};
    this.getCep();
  }

  override afterSaveEntity() {
    this.tableParams = {parceiroId: this.entity.id};
    console.log("para", this.tableParams)

  }

  getCep() {
    this.form.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        this.httpHelp.getCep(cep,
          data => {
            this.form.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "uf": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )

      }
    )
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const {
      bairro,
      cep,
      cidade,
      numero,
      complemento,
      logradouro,
      uf,
      categoriaNegocio,
      ...form
    } = this.form.getRawValue();

    const indexCategoria = this.listaCategoriaNegocio.findIndex((cat) => {
      return cat.id == categoriaNegocio;
    })

    this.entity = {
      ...this.entity,
      ...form,
      categoriaNegocio: !!this.listaCategoriaNegocio[indexCategoria] ? this.listaCategoriaNegocio[indexCategoria] : undefined,
      endereco: {
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: (uf || '').toUpperCase()
      }
    }

    return true;
  }

  linkParceiro(id: number) {
    window.open('/solicitacao-cadastro/' + id, '_blank')
    // this.router.navigate(['/solicitacao-cadastro/' + id])
  }

  selectImg() {
    const fileImge = this.fileUploadParceiro.nativeElement;
    fileImge.click()
  }

  uploadParceiro(event: Event) {
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        this.file = undefined;
        return;
      }

      let data = new FormData();

      data.append('arquivo', file);
      data.append('parceiroId', String(this.entity.id));


      this.servic.uploadImagem(data).subscribe(resp => {
        if (resp) {
          //@ts-ignore
          this.entity.imagem = resp.data
        }
      })
    }
  }
}
