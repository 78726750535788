import { Component, OnInit } from '@angular/core';
import { CategoriaArquivoEntity } from '../categoria-arquivo.entity';
import { CategoriaArquivoService } from '../service/categoria-arquivo.service';

@Component({
  selector: 'app-galpao-categoria-arquivo-main',
  templateUrl: './categoria-arquivo-main.component.html',
  styleUrls: ['./categoria-arquivo-main.component.scss']
})
export class CategoriaArquivoMainComponent implements OnInit {

  public mapEntity = new CategoriaArquivoEntity();

  constructor(
    public service: CategoriaArquivoService
  ) {
  }

  ngOnInit(): void {
  }

}
