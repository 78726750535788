<div
  id="missao-insert-edit"
  class="container"
  *ngIf="!!entity"
>
    <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12">
            <div>
                <card>
                    <div class="card-header">
                        <h4 class="card-title col-9">Foto do Programa</h4>
                        <div class="col-3">
                            <button
                                class="btn btn-success fs-10"
                                matTooltipPosition="above"
                                matTooltip="A largura é 25% maior que altura. Exemplos de resolução: 500x400,375x300,250x200. O recomendável é 500x400"
                            >
                                Ajuda
                            </button>
                        </div>
                    </div>
                    <div class="card-body div-image-container">
                        <img
                            class="image-container"
                            src="{{files.getImageToSrc(entity?.imagem)}}"
                            alt="Imagem do container"
                        >
                    </div>
                    <div class="div-button-image-container  pb-2">
                        <button
                            class="btn btn-success"
                            (click)="selectImg()"
                            [disabled]="!entity?.id"
                        >
                            Escolher
                        </button>
                        <input
                            #fileUploadMissao
                            type="file"
                            [(ngModel)]="fileMissao"
                            (change)="uploadMissao($event)"
                            name="uploadMissao"
                            id="uploadMissao"
                            accept=".jpg"
                            style="display: none;"
                        >
                    </div>
                </card>
            </div>
            <div class="mt-4">
                <card>
                    <div class="card-header">
                        <h4 class="card-title col-9">Selo da Programa</h4>
                        <div class="col-3">
                            <button
                                class="btn btn-success fs-10"
                                matTooltipPosition="above"
                                matTooltip="A largura é 25% maior que altura. Exemplos de resolução: 500x400,375x300,250x200. O recomendável é 500x400"
                            >
                                Ajuda
                            </button>
                        </div>
                    </div>
                    <div class="card-body div-image-container pb-2">
                        <img
                            class="image-container"
                            src="{{files.getImageToSrc(entity?.selo)}}"
                            alt="Imagem do container"
                        >
                    </div>
                    <div class="div-button-image-container pb-2">
                        <button
                            class="btn btn-success"
                            (click)="selectImgSelo()"
                            [disabled]="!entity?.id"
                        >
                            Escolher
                        </button>
                        <input
                            #fileUploadSelo
                            type="file"
                            [(ngModel)]="fileSelo"
                            (change)="uploadSelo($event)"
                            name="uploadSelo"
                            id="uploadSelo"
                            accept=".jpg"
                            style="display: none;"
                        >
                    </div>
                </card>
            </div>
        </div>
        <div class="col-md-8 col-sm-12 col-xs-12">
            <card>
                <div class="card-header">
                    <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12">
                        <h4 class="card-title">Programa de Logística Reversa</h4>
                    </div>
                    <card-header-buttons class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <button
                            class="btn btn-success btn-card-header"
                            [disabled]="!entity?.id"
                            (click)="modalProgramaLogisticaReversa.open()"
                        >
                            Visualizar
                        </button>
                    </card-header-buttons>
                </div>
                <form [formGroup]="form">
                    <div class="card-body">
                        <div class="content-form">
                            <div class="row">
                                <div
                                    class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                                    style="padding-top: 1.2em"
                                >
                                    <label>
                                        Nome
                                        <small class="fc-red">*</small>
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="nome"
                                    >
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>
                                        Quantidade de UG participantes
                                        <small class="fc-red">*</small>
                                    </label>
                                    <input
                                        type="text"
                                        mask="0*"
                                        class="form-control"
                                        formControlName="limiteClientesAceitarMissao"
                                    >
                                </div>
                                <div
                                    class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                                    style="padding-top: 1.2em"
                                >
                                    <label>Pontos do Programa</label>
                                    <input
                                        type="text"
                                        mask="0*"
                                        class="form-control"
                                        formControlName="pontosMissao"
                                    >
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>Numero de Coletas</label>
                                    <input
                                        type="text"
                                        mask="0*"
                                        class="form-control"
                                        formControlName="numeroDeColetas"
                                    >
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>Quantidade de Usuários</label>
                                    <input
                                        type="text"
                                        mask="0*"
                                        class="form-control"
                                        formControlName="qtdMoradores"
                                    >
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>Meta em tonelada</label>
                                    <select
                                        class="form-select"
                                        name="percentualDoContainer"
                                        id="percentualDoContainer"
                                        formControlName="percentualDoContainer"
                                    >
                                        <option
                                            value
                                            disabled
                                        >
                                            selecione
                                        </option>
                                        <option value="ABAIXO">< 50%</option>
                                        <option value="ACEITAVEL">50%</option>
                                        <option value="OTIMO">75%</option>
                                        <option value="PERFEITO">100%</option>
                                        <option value="EXCELENTE">150%</option>
                                    </select>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>Tipo de Resíduo</label>
                                    <input-autocomplete
                                        #autoResiduo
                                        key="id"
                                        label="nome"
                                        formControlName="tipoLixo"
                                        classes="form-select"
                                        [list]="listTipoLixo"
                                    >
                                    <ng-template #row_option let-option> Nome: {{option.row.nome}} | Categoria: {{option.row.categoria}}</ng-template>
                                    </input-autocomplete>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                    <label>Cliente Logistica Reversa</label>
                                    <input-auto-complete
                                        [baseValue]="entity?.clienteCreditoLogisticaReversa "
                                        propertValue="nome"
                                        controlName="clienteCreditoLogisticaReversa"
                                        urlServer="cliente-credito-logistica-reversa/nome"
                                        (outValue)="setClientes($event)"
                                    ></input-auto-complete>
                                </div>
                            </div>
                        </div>


            <div class="row pt-2">
              <div>
                <label
                  for="checkboxEstado"
                  class="fs-13"
                >
                  <input
                    type="checkbox"
                    name="checkboxEstado"
                    id="checkboxEstado"
                    [checked]="entity?.restringiuMissao"
                    (change)="entity.restringiuMissao = !entity.restringiuMissao"
                  >
                  Deseja restringir a missão para alguns estados?
                </label>
              </div>
            </div>


            <div [hidden]="!entity?.restringiuMissao">


              <div class="row">
                <pick-list
                  #pickListEstado
                  [listaDisponivel]="listaEstado"
                  [listaSelecionados]="listaEstadoSelecionados"
                  (changeSelect)="selectEstados($event)"
                  titleList="Estados"
                  uniClass="name"
                  [propertItem]="['estado', 'name']"
                  [verifyRemoveItem]="verifyRemoveEstado"
                >
                </pick-list>
              </div>
            </div>


            <div
              class="row"
              [hidden]="pickListEstado.getList().length <= 0 || !entity.restringiuMissao"
            >
              <div>
                <label
                  for="checkboxCidade"
                  class="fs-13"
                >
                  <input
                    type="checkbox"
                    name="checkboxCidade"
                    id="checkboxCidade"
                    [checked]="checkbox.cidade"
                    (change)="checkbox.cidade = !checkbox.cidade"
                  >
                  Deseja restringir a missão para algumas cidades?
                </label>
              </div>
            </div>


            <div [hidden]="!checkbox.cidade || pickListEstado.getList().length <= 0 || !entity?.restringiuMissao">
              <div class="row">
                <pick-list
                  #pickListCidade
                  titleList="Cidades"
                  [listaDisponivel]="listaCidade"
                  [listaSelecionados]="listaCidadeSelecionadas"
                  (changeSelect)="selectCidades($event)"
                  uniClass="cidade"
                  propertItem="cidade"
                >
                </pick-list>
              </div>
            </div>


            <div class="row mt-5">
              <div class="col-12">
                <label class="form-label">
                  DESCRIÇÃO
                  <span class="fc-red">*</span>
                </label>
                <angular-editor
                  formControlName="descricao"
                  [config]="editorConfig"
                ></angular-editor>
              </div>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>


  <div
    class="row pt-2"
    *ngIf="entity?.id"
  >
    <card style="max-height: 600px;">
      <div class="card-header">
        <h4>Geradores Confirmados</h4>
      </div>
      <div class="card-body">
        <table class="crud-table">
          <thead>
          <tr>
            <th style="width: 50%;">Nome</th>
            <th style="width: 40%;">Meta</th>
            <th style="width: 10%;"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="listaCondominiosConfirmados.length == 0">
            <td>
                                <span>
                                     Nenhum gerador cadastrada na Missão
                                </span>
            </td>
            <td></td>
          </tr>


          <tr *ngFor="let item of listaCondominiosConfirmados; let i = index">
            <td>{{item.cliente?.nome}}</td>


            <td>
              <input
                id="metaCondominio"
                [hidden]="!item.edit"
                [(ngModel)]="item.meta"
                type="text"
                class="form-control"
              >
              <span [hidden]="item.edit">{{item.meta}}</span>
            </td>


            <td>
              <div *ngIf="!item.edit">
                <button
                  style="font-size: 1em;"
                  class="btn"
                  (click)="editInput(i)"
                >
                  <mat-icon style="font-size: 1em;">edit</mat-icon>
                </button>
              </div>


              <div
                *ngIf="item.edit"
                class="row"
              >
                <button
                  class="btn col-3"
                  (click)="addMeta(i)"
                >
                  <mat-icon class="fc-green">done</mat-icon>
                </button>
                <button
                  class="btn col-3"
                  (click)="cancelarAcao(i)"
                >
                  <mat-icon class="fc-red">close</mat-icon>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>
</div>


<page-footer>
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
</page-footer>


<old-dg-modal
  size="full"
  title="Desafio Programa Logistica Reversa"
  #modalProgramaLogisticaReversa
>


  <div dg-content>
    <div
      class="row"
      style="justify-content: center;"
    >
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <card class="d-flex programa">
          <div class="programaHeader">
            <h1 class="titleMiss">{{entity?.nome}}</h1>
          </div>
          <input
            type="file"
            style="display: none"
            accept="image/png, image/jpeg, image/jpg"
          >
          <img
            class="image-material"
            src="assets/img/default.jpg"
            alt="Imagem logo"
          >
          <div
            class="card-body "
            style="background-color: rgb(221, 221, 221);"
          >
            <span [innerHTML]="ignorSanitaze() || ''"></span>
          </div>
          <div
            class="d-flex"
            style="justify-content: center;"
          >
            <button
              class="btn btn-success"
              style="margin: 1em;"
            >
              RESGATAR CUPOM
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button
      (click)="modalProgramaLogisticaReversa.close()"
      class="btn btn-secondary m-1"
    >
      Sair
    </button>
  </div>
</old-dg-modal>
