import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {COLETA_PESO} from "../../../../../core/config/endpoint-list";
import {dispacherAlterTable} from "../../../../../helpers/helpers";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import Swal from "sweetalert2";

@Component({
  selector: 'modal-controle-pesagem',
  templateUrl: './modal-controle-pesagem.component.html',
  styleUrls: ['./modal-controle-pesagem.component.scss']
})
export class ModalControlePesagemComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;
  @Output('afterSate') afterSate = new EventEmitter();

  public dataColeta = '';
  public motorista = '';
  public table: CdkDynamicTable.tableClass;

  public pesoTotal = 0;
  public pesoTotalDistribuido = 0;
  public pesoTotal_verificador = 0; // isso esse valor for diferente de "0" então a distribuíção está errada


  form = new FormGroup({
    id: new FormControl(''),
    codigoDestinacaoFinal: new FormControl(''),
    pesoCaminhaoSaida: new FormControl('', [Validators.required]),
    pesoCaminhaoChegada: new FormControl('', [Validators.required]),
  })

  constructor(
    public service: GenericCrudService,
    public createTable: CdkDynamicTableService,
    public session: SessionService
  ) {
    this.form.controls['pesoCaminhaoSaida'].valueChanges.subscribe(() => {
      setTimeout(() => this.updatePesoTotal());
    });
    this.form.controls['pesoCaminhaoChegada'].valueChanges.subscribe(() => {
      setTimeout(() => this.updatePesoTotal());
    });

    this.table = createTable.create('array', {
      columns: [
        {name: 'clienteNome', headerName: 'Gerador'},
        {name: 'pesoTotalEstimado', headerName: 'Peso da Coleta'},
        {name: 'pesoTotalFinal', headerName: 'Peso Final'},
      ]
    })
  }

  ngOnInit(): void {
  }

  open(val: any) {
    this.motorista = val.motoristaNome.nome;
    this.dataColeta = val.dataColeta;
    this.service.get(COLETA_PESO + (val.rotaId || val.id)).subscribe(
      resp => {
        this.form.patchValue({
          ...resp.data,
          pesoCaminhaoSaida: resp.data.pesoCaminhaoSaida || '0',
          pesoCaminhaoChegada: resp.data.pesoCaminhaoChegada || '0',
        })

        const coletas = resp.data.coletas.map((item:any) => {
          if(!item['pesoTotalFinal']) item['pesoTotalFinal'] = 0;

          return item;
        })

        this.table.controls.data.set(coletas, true)
        this.updatePesoTotal();
        this.updatePesoTotalDistribuido();
        this.updatePesoTotal_verificador()
        this.modal.open();
        if (this.pesoTotal_verificador != 0) {
          this.distribuirPeso();
        }
      }
    )
  }

  public close = () => {
    this.form.reset('')
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.pesoTotal_verificador != 0) {
      Swal.fire({
        icon: 'error',
        title: 'Peso não confere',
        text: 'O peso distribuído é diferente do peso total'
      }).then();
      return;
    }

    const { pesoCaminhaoSaida, pesoCaminhaoChegada, ...form } = this.form.value

    const data = {
      ...form,
      coletas: this.table.controls.data.get(),
      pesoCaminhaoSaida: Number(pesoCaminhaoSaida),
      pesoCaminhaoChegada: Number(pesoCaminhaoChegada),
    }

    this.service.put(COLETA_PESO, data).subscribe(() => {
      dispacherAlterTable('tableColetas')
      this.afterSate.emit();
      this.modal.close();
    })
  }

  updatePesoTotal() {
    const {pesoCaminhaoChegada, pesoCaminhaoSaida} = this.form.value
    this.pesoTotal = pesoCaminhaoChegada - pesoCaminhaoSaida;
    this.updatePesoTotal_verificador();
  }

  updatePesoTotalDistribuido() {
    let totalColetasEstimado = 0;
    this.table.controls.data.get().map(coleta => (totalColetasEstimado = totalColetasEstimado + coleta.pesoTotalFinal || 0));
    this.pesoTotalDistribuido = totalColetasEstimado;
    this.updatePesoTotal_verificador()
  }

  updatePesoTotal_verificador() {
    this.pesoTotal_verificador = this.pesoTotal - this.pesoTotalDistribuido;
  }

  format = (val: string | number) => {
    return Number(val).toLocaleString("pt-BR", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    });
  }

  distribuirPeso() {
    const {pesoCaminhaoChegada} = this.form.value;

    if (pesoCaminhaoChegada != 0 && this.pesoTotal_verificador != 0) {
      Swal.fire({
        icon: 'question',
        title: 'Distribuir Peso?',
        text: 'Distribua entre as coletas que estão sem valor ou entre todas as coletas',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Entre todos',
        denyButtonText: 'Entre os sem valor',
        cancelButtonText: 'Sair'
      }).then(conf => {
        if (conf.isDismissed) return;

        const distribuirParaTodos: boolean = conf.isConfirmed;
        let dataTable = this.table.controls.data.get();

        let divisor: number;

        if (distribuirParaTodos) {
          divisor = dataTable.length;
        } else {
          divisor = dataTable.filter(val => !val.pesoTotalFinal).length;
        }

        const pesoDistribuido = this.pesoTotal / divisor;
        dataTable = dataTable.map(coleta => {
          if (distribuirParaTodos) {
            coleta.pesoTotalFinal = pesoDistribuido;
          } else {
            if (!coleta.pesoTotalFinal) {
              coleta.pesoTotalFinal = pesoDistribuido
            }
          }

          return coleta;
        })

        this.table.controls.data.set(dataTable, true, { loader: false });
        this.updatePesoTotalDistribuido();
      })
    }
  }
}
