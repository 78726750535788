<div class="container-data">

  <div class="container-data">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <h4>Central de comunicação</h4>
        <span>
          Últimas notícias!
        </span>
      </div>
      <div check-perfil perfil="ADMINISTRADOR" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <button class="btn btn-success" (click)="modalPost.open()">Adicionar Novo Post</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 blog-list">
      <div *ngIf="singlePost" style="margin: 1rem auto" class="d-inline-flex justify-content-end w-100">
        <button
          style="padding: .5rem 1.5rem"
          (click)="getBlogList()"
          class="btn btn-secondary d-inline-flex"
        >
          <mat-icon style="margin-right: .3rem">settings_backup_restore</mat-icon>
          Ver Todas
        </button>
      </div>

      <div style="margin: 1rem auto" *ngFor="let dataPost of listBlog">
        <card-post
          [dataPost]="dataPost"
          (editar)="modalPost.open($event)"
          (excluir)="excluirPost($event)"
        ></card-post>

      </div>

      <div *ngIf="!singlePost" class="col-12 d-inline-flex justify-content-center">
        <dg-pagination
          [dataPagination]="entity.configEntityTable.pagination"
          (changePage)="changePage($event)"
        ></dg-pagination>
      </div>
    </div>


    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
      <filter-blog (findOnePost)="getPostById($event)"></filter-blog>
    </div>
  </div>

</div>

<modal-blog-insert-edit
  #modalPost
  (afterSave)="getBlogList()"
></modal-blog-insert-edit>
