<div class="container" id="avaliacao-coleta">
    <div >
        <img class="imagem-avaliacao" src="assets/img/default.jpg" alt="Imagem logo">
    </div>
    <div class="row">
        <h2>Avalie o {{!!motorista ? 'motorista/catador' : 'gerador' }} da Coleta</h2>
        <div class="row mt-4">
            <hr style="background-color: rgba(0,0,0,0.33);">
            <table>
                <tbody>
                    <tr>
                        <th><strong>Nome do Motorista/Catador</strong></th>
                        <td class="d-flex justify-content-end">{{entity?.nome}}{{entity?.sobrenome}}</td>
                    </tr>
                    <tr>
                        <th><strong>Data da rota</strong></th>
                        <td class="d-flex justify-content-end">{{entity?.dataRota}}</td>
                    </tr>
                    <tr>
                        <th><strong>Avaliação</strong></th>
                        <td class="d-flex justify-content-end">
                            <ratio-star [editar]="true" [pontos]="estrelas" (salve)="alterStar($event)"></ratio-star>
                        </td>
                    </tr>
                </tbody>
            </table>
            <footer class="d-flex justify-content-center">
                <button class="btn btn-secondary" (click)="avaliar()">Avaliar</button>
            </footer>
        </div>
    </div>
</div>
