import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from "../../services/session-service/session.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private session: SessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!!route.data['perfil']) {
      if (!this.session.checkPerfil(route.data['perfil'])) {
        Swal.fire({
          icon: 'warning',
          title: 'Credenciais Insuficientes',
          text: 'A página não pode ser aberta por falta de credenciais do seu usuário'
        });

        return false
      }
    }

    return true;
  }

}
