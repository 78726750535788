import { RankingColetasService } from './../../service/ranking-coletas.service';
import { RankingColetasEntity } from './../../ranking-coletas.entity';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ranking-coletas-print-pdf',
  templateUrl: './ranking-coletas-print-pdf.component.html',
  styleUrls: ['./ranking-coletas-print-pdf.component.scss'],
})
export class RankingColetasPrintPdfComponent implements OnInit {
  public mapEntity = new RankingColetasEntity();

  constructor(
    public service: RankingColetasService,
    private route: ActivatedRoute,
  ) { }

  public load: boolean = true;

  ngOnInit() {
  }

  startPrint = (data: RankingColetasEntity[]) => {
    this.load = false;

    setTimeout(() => {
      if (window.location.pathname == '/user/ranking-coletas-print') {
        window.print();
        window.close();
      }
    }, 1000);
    return data;
  }
}
