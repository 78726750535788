import {NgModule} from "@angular/core";
import {ExtraOptions, RouterModule, Routes} from "@angular/router";
import {UserLayoutComponent} from "./layouts/user/user-layout/user-layout.component";
import {PublicLayoutComponent} from "./layouts/public/public-layout/public-layout.component";
import {PageComponentsComponent} from "./pages/page-components/page-components.component";
import {SessionGuard} from "./core/guards/guard-session/session.guard";
import {StartSessionComponent} from "./pages/_auth/start-session/start-session.component";
import {StartSessionGuard} from "./core/guards/guard-start-session/start-session.guard";
import {
  DashboardPrintColetasComponent
} from "./pages/_user/dashboard/subComponents/dashboard-print-coletas/dashboard-print-coletas.component";
import {
  DashboardPrintRankingAterroZeroComponent
} from "./pages/_user/dashboard/subComponents/dashboard-print-ranking-aterro-zero/dashboard-print-ranking-aterro-zero.component";
import {
  DashboardPrintRankingTaxaReciclagemComponent
} from "./pages/_user/dashboard/subComponents/dashboard-print-ranking-taxa-reciclagem/dashboard-print-ranking-taxa-reciclagem.component";
import {
  DashboardPrintEstoqueComponent
} from "./pages/_user/dashboard/subComponents/dashboard-print-estoque/dashboard-print-estoque.component";
import {FragmentSideMenuComponent} from "./pages/_user/white-label/sub-components/fragment-side-menu/fragment-side-menu.component";
import {PrintLayoutComponent} from "./layouts/print-layout/print-layout/print-layout.component";
import {DocLayoutComponent} from "./layouts/doc-layout/doc-layout.component";

const routes: Routes = [
  {
    path: 'user/holding/white-label/fragment-side-menu',
    component: FragmentSideMenuComponent,
    // canActivate: [SessionGuard]
  },

  {
    path: 'private/impressao',
    component: PrintLayoutComponent,
    canActivate: [SessionGuard],
    loadChildren: () => import('./pages/impressao/impressao.module').then((m) => m.ImpressaoModule),
  },

  {
    path: 'user',
    component: UserLayoutComponent,
    loadChildren: () =>
      import('./pages/_user/user.module').then((m) => m.UserModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'user/dashboard-print',
    component: DashboardPrintEstoqueComponent
  },
  {
    path: 'user/dashboard-print-coletas',
    component: DashboardPrintColetasComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'user/ranking-taxa-reciclagem-print',
    component: DashboardPrintRankingTaxaReciclagemComponent
  },
  {
    path: 'user/ranking-aterro-zero-print',
    component: DashboardPrintRankingAterroZeroComponent
  },
  {
    path: 'auth/start-session',
    component: StartSessionComponent,
    canActivate: [StartSessionGuard]
  },
  {
    path: 'components',
    component: UserLayoutComponent,
    children: [
      { path: '', component: PageComponentsComponent }
    ]
  },
  {
    path: 'doc',
    component: DocLayoutComponent,
    canActivate: [SessionGuard],
    loadChildren: () => import('./pages/doc/doc.module').then((m) => m.DocModule),
  },

  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () =>
      import('./pages/_public/public.module').then((m) => m.PublicModule),
  },

  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

// if (!environment.production) {
//   routes.push({
//     path: 'doc',
//     component: DocLayoutComponent,
//     canActivate: [SessionGuard],
//     loadChildren: () => import('./pages/doc/doc.module').then((m) => m.DocModule),
//   })
// }


const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
