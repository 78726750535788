import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import { PickListComponent } from 'src/app/shared/ui/pick-list/pick-list.component';
import Swal from 'sweetalert2';
import { ClientesService } from '../../_clientes/clientes/service/clientes.service';
import { MissaoEntity } from '../missao.entity';
import { MissaoService } from '../service/missao.service';
import {environment} from "../../../../../environments/environment";
import {CLIENTE} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-missao-main',
  templateUrl: './missao-main.component.html',
  styleUrls: ['./missao-main.component.scss']
})
export class MissaoMainComponent implements OnInit {

  @ViewChild('modalMissao')modalMissao!: OldModalComponent;
  @ViewChild('pickList')pickList!: PickListComponent;

  public TableConfig = {
    path: environment.apiUrl,
    context: 'missao'
  }

  public mapEntity = new MissaoEntity();
  listaClientes: any[] = [];
  listaClienteSelecionados: any[] = [];
  entityId: string| number | undefined;

  constructor(
    public service: MissaoService,
    public http: HttpOldService,
    public fileService: FilesManageService
  ) {
    this.mapEntity.addActions([
      {
        name: 'Convidar',
        action: (row: MissaoEntity) => {this.open(row)}
      }
    ])
  }

  ngOnInit(): void {
  }

  open(data: MissaoEntity | undefined){
    this.entityId = data?.id;
    this.mapEntity.nome = data?.nome

    this.service.get(CLIENTE, { params: { unpaged: true } }).subscribe(resp => {
      //@ts-ignore
      this.listaClientes = resp.data

      this.modalMissao.open()
    })
  }

  public closeModal = () => {
    this.listaClientes = [];
    this.listaClienteSelecionados = [];
    this.pickList.clear();
  }

  convidar() {
    let listaClientesId: any[] = [];
    listaClientesId = this.pickList.getList().map(item => item.id);
    this.http.get(`missao/clientes/convidar?clientes=${listaClientesId}&missaoId=${this.entityId}`).subscribe(resp => {
      if(resp){
        Swal.fire({
          title: 'E-mail enviado com sucesso',
          icon: 'success',
          timer: 5000,
          showConfirmButton: false
        });
        this.modalMissao.close()
      }
    })
  }

  baixarPlanilha(){
    let listaClientesId: any[] = [];
    listaClientesId = this.pickList.getList().map(item => item.id);
    this.http.get(`missao/clientes/planilha?clientes=${listaClientesId}&missaoId=${this.entityId}`).subscribe(resp => {
      if(resp){
        //@ts-ignore
        this.fileService.downloadBase64(resp.data, 'planilha_unidades_geradoras.xls');
        this.modalMissao.close()
      }
    })
  }

}
