import {Component, OnInit} from '@angular/core';
import {HttpOldService} from "../../../../../core/services/http/http-old.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {InterfPagination} from "@datagrupo/dg-crud";
import {PlanosClientesService} from "../../../_clientes/planos-clientes/services/planos-clientes.service";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {HISTORICOS} from "../../../../../core/config/endpoint-list";

@Component({
  selector: 'subComponent-user-historico-planos',
  templateUrl: './user-historico-planos.component.html',
  styleUrls: ['./user-historico-planos.component.scss']
})
export class UserHistoricoPlanosComponent implements OnInit {

  public historicos: any;

  pagination: InterfPagination = {
    page: 0,
    size: 10,
    sort: 'id,desc'
  }

  constructor(
    private http: HttpOldService,
    private session: SessionService,
    private service: GenericCrudService,
  ) {
  }

  ngOnInit(): void {
    // this.service.alterResource('historicos')
    this.getData();
  }

  getData() {
    this.service.get(
      HISTORICOS,
      {
        params: {
          ...this.pagination,
          id: this.session.user.id
        }
      }
    ).subscribe((resp: any) => {
      //@ts-ignore
      this.historicos = resp.data;

      //  @ts-ignore
      this.pagination = {
        ...resp.page,
        //  @ts-ignore
        page: resp.page.number
      }
    });
  }

  changePage(pagination: any) {
    console.log(pagination);
    this.pagination = {
      ...this.pagination,
      ...pagination
    }

    this.getData()
  }

  formataData(dateString: string): string {
    var data = new Date(dateString),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

}
