<div id="public-compre-credito-logistica-reversa">
  <div class="banner" style="margin-bottom: 5rem">
    <div class="banner-borda-inclinada">
    </div>
  </div>

  <h2 style="text-align: center; margin-bottom: 4rem">CRÉDITOS DE LOGÍSTICA REVERSA</h2>

  <div class="container-data">
    <div class="row">
      <div style="text-align: end">
        <button class="btn btn-success d-inline-flex" (click)="modal.open()">
          <mat-icon>add</mat-icon>
          adicionar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="w-100 crud-table">
          <thead>
          <tr>
            <th>TIPO DE MATERIAL COMPENSADO</th>
            <th>PERCENTUAL A COMPENSAR</th>
            <th>PERCENTUAL REPRESENTADO EM TONELADAS</th>
            <th>ESTADO</th>
            <th>ANO</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="listProdSelected.length <= 0">
            <td colspan="3" style="text-align: center">Nenhum produto selecionado</td>
          </tr>
          <tr *ngFor="let prod of listProdSelected; let index = index">
            <td>{{prod.produto.nome}}</td>
            <td>{{prod.percentual}}</td>
            <td>{{prod.quantidade}}</td>
            <td>{{prod.uf}}</td>
            <td>{{prod.anoReferente}}</td>
            <td>
              <button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="editarProd(index)" mat-menu-item>Editar</button>
                <button (click)="removeProd(index)" mat-menu-item>Excluir</button>
              </mat-menu></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <hr>
      </div>
    </div>
  </div>

  <div class="container-data">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Nome da empresa</label>
          <input formControlName="empresa" type="text" class="form-control form-control-lg">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Digite o telefone com DDD</label>
          <input mask="(00) 0000-0000||(00) 00000-0000" formControlName="telefone" type="text" class="form-control form-control-lg">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Estado</label>
          <select formControlName="estado" class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
            <option disabled value="">Selecione um estado</option>
            <option *ngFor="let estado of estados" [value]="estado.uf">{{estado.name || '--'}}</option>
          </select>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Cidade</label>
          <input formControlName="cidade" type="text" class="form-control form-control-lg">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Nome do contato</label>
          <input formControlName="contato" type="text" class="form-control form-control-lg">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">email do contato</label>
          <input formControlName="email" type="email" class="form-control form-control-lg">
        </div>
      </div>
    </form>
    <div class="row">
    </div>
  </div>

  <div class="container-data">
    <div class="row">
      <div class="col-12" style="text-align: end">
        <button (click)="pedirOrcamento()" class="btn btn-success btn-lg">Solicitar Orçamento</button>
      </div>
    </div>
  </div>

</div>
<old-dg-modal title="Produtos" #modal [afterClose]="close">
  <div dg-content>
    <form [formGroup]="formModal">
      <div class="row">
        <div class="col-4">
          <label class="form-label">TIPO DE MATERIAL COMPENSADO <span class="fc-red">*</span></label>
          <select formControlName="produto" class="form-select">
            <option value="" selected>Selecione um produto</option>
            <option *ngFor="let prod of listProdutos" [value]="prod.id">{{prod.nome}}</option>
          </select>
        </div>
        <div class="col-8">
          <label for="percentual" class="form-label">
            QUAL PERCENTUAL VOCÊ DESEJA COMPENSAR NA ECOPLAT?
            <span class="fc-red">*</span></label
          >
          <input
            type="number"
            class="form-control"
            min="22"
            id="percentual"
            formControlName="percentual"
          />
        </div>

        <div class="row">

        <div class="col-8">
          <label for="quantidade" class="form-label">
            O PERCENTUAL ESCRITO ACIMA REPRESENTA QUANTAS TONELADAS DE MATERIAIS?
            (TON) <span class="fc-red">*</span></label
          >
          <input
            type="number"
            placeholder="exemplo: 100(TON)"
            class="form-control"
            formControlName="quantidade"
            id="quantidade"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label for="anoReferente" class="form-label">
            ESSA COMPENSAÇÃO É REFERENTE A QUAL ANO?
            <span class="fc-red">*</span></label
            >
            <input
            min="2000"
            max="2100"
            type="number"
            class="form-control"
            id="anoReferente"
            formControlName="anoReferente"
            />
          </div>

          <div class="col-6">
            <label for="uf" class="form-label"
            >UF <span class="fc-red">*</span></label
            >
            <select class="form-select" id="uf" formControlName="uf">
              <option disabled selected>Selecione</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button (click)="saveModal()" class="btn btn-success btn-lg m-1">Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary btn-lg m-1">Sair</button>
  </div>
</old-dg-modal>
