import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OldModalComponent } from "../../../../../../shared/ui/modal/modal/modal.component";
import { GALPAO_CONTAINER } from "../../../../../../core/config/endpoint-list";
import {crudDispatchEvent, HttpClient} from "@datagrupo/dg-crud";
import { GenericCrudService } from "../../../../../../core/services/generic-crud-service/generic-crud.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { ContainerEntity } from 'src/app/pages/_user/container/container.entity';
import {environment} from "../../../../../../../environments/environment";
import {InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {GrupoMercadoEntity} from "../../../../tipo-lixo/grupo-merccado.entity";
import {GalpaoContainerEntity} from "../../galpao-containers.entity";

@Component({
  selector: 'model-add-container-galpao',
  templateUrl: './add-container-galpao.component.html',
  styleUrls: ['./add-container-galpao.component.scss']
})
export class AddContainerGalpaoComponent implements OnInit {

  public container: ContainerEntity[]=[]

  // public idContainer: string |number | undefined

  public containersMtrInea: any[] = []
  public containersMtrSinir: any[] = []
  public containersMtrSigor: any[] = []

  @ViewChild('modal') modal!: OldModalComponent
  @Input() idGalpao: string | number | undefined;
  @ViewChild('autoCompleteInea') autoCompleteInea!: InputAutocompleteComponent;
  @ViewChild('autoCompleteSinir') autoCompleteSinir!: InputAutocompleteComponent;
  @ViewChild('autoCompleteSigor') autoCompleteSigor!: InputAutocompleteComponent;

  form = new FormGroup({
    id: new FormControl(''),
    capacidadeLitros: new FormControl('', [Validators.required]),
    descricao: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required]),
    inea: new FormControl('', [Validators.required]),
    sigor: new FormControl('', [Validators.required]),
    sinir: new FormControl('', [Validators.required]),
  })

  constructor(
    private service: GenericCrudService,
    public http: HttpClient
  ) {
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['capacidadeLitros'])
    )

    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'INEA' } }).subscribe((resp: any) => {
      this.containersMtrInea = resp.data
    })
    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'SINIR' } }).subscribe((resp: any) => {
      this.containersMtrSinir = resp.data
    })
    this.http.get(environment.apiUrl + 'containers-mtr', { params: { fonteMtr: 'SIGOR' } }).subscribe((resp: any) => {
      this.containersMtrSigor = resp.data
    })
  }

  ngOnInit(): void {
  }

  preSelectedInea: any[] = []
  preSelectedSinir: any[] = []
  preSelectedSigor: any[] = []

  open(val?: GalpaoContainerEntity) {
    this.preSelectedInea = val?.categoriaContainers[0]?.containerMtr || []
    this.preSelectedSinir = val?.categoriaContainers[1]?.containerMtr || []
    this.preSelectedSigor = val?.categoriaContainers[2]?.containerMtr || []
    if(!!val){
      this.form.patchValue({
        id: val?.id,
        capacidadeLitros: InputHelpers.initInputDecimal(val.capacidadeLitros || ''),
        descricao: val?.descricao,
        nome: val.nome,
      })

      this.service.get(GALPAO_CONTAINER,  { params: { galpaoId: this.idGalpao, unpaged: true}} ).subscribe((res)=>{
        this.container = res.data
        this.modal.open()
      })
      return;
    }
    this.modal.open()
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let categoriaContainers: any[] = []
    if (this.autoCompleteInea.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteInea.selectedList[0],
        container: {
          nome: this.form.controls['nome'].value,
          id: this.form.controls['id'].value
        }
      })
    }
    if (this.autoCompleteSinir.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteSinir.selectedList[0],
        container: {
          nome:this.form.controls['nome'].value,
          id: this.form.controls['id'].value
        }
      })
    }
    if (this.autoCompleteSigor.selectedList.length > 0) {
      categoriaContainers.push({
        containerMtr: this.autoCompleteSigor.selectedList[0],
        container: {
          nome:this.form.controls['nome'].value,
          id: this.form.controls['id'].value
        }
      })
    }

    const { capacidadeLitros, ...form } = this.form.value;

    const data = {
      capacidadeLitros: InputHelpers.ajusteMoneyBrToEua(capacidadeLitros),
      galpaoId: this.idGalpao,
      categoriaContainers,
      ...form
    };

    this.service[!!data?.id ? 'put' : 'post'](GALPAO_CONTAINER, data).subscribe(
      () => {
        crudDispatchEvent('GalpaoContainerEntity');
        this.modal.close()
      }
    )
  }

  afterClose = () => {
    this.form.reset('');
    this.form.reset({inea: [], sinir: [], sigor: []});
  }

  protected readonly JSON = JSON;
}
