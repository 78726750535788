import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {InterfMenuList} from "../../../core/config/menu/admin.menuList";
import {SessionService} from "../../../core/services/session-service/session.service";
import {Router} from "@angular/router";
import {OldModalComponent} from "../../../shared/ui/modal/modal/modal.component";
import {FormControl, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {InterfaceMenuList} from "../components/navigation/navigation.component";
import {ThemeManageService} from "../../../services/theme-manage/theme-manage.service";

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {

  public navigateMenu: InterfMenuList[] = []
  public inputSearch = '';

  @ViewChild('modalNewsletter') modalNewsletter!: OldModalComponent
  @ViewChild('modalLix') modalLix!: OldModalComponent

  public messageLix = new FormControl('', [Validators.required])

  constructor(
    private session: SessionService,
    private router: Router,
    private themeService: ThemeManageService
  ) {
    this._menu();
  }

  private _menu() {
    let navigationMenu = this.session.menu.get();
    if (!navigationMenu) {
      this.router.navigate(['/auth/start-session'])
      return;
    }
    if (navigationMenu.length <= 0) {
      this.router.navigate(['/auth/start-session'])
    }

    this.navigateMenu = navigationMenu;
  }

  ngOnInit(): void {}

  @HostListener('window:event-open-newsletter')
  openNewsletter() {
    this.modalNewsletter.open()
  }

  @HostListener('window:event-open-lix')
  openLix() {
    this.messageLix.reset('')
    this.modalLix.open()
  }

  enviarLix() {
    if (this.messageLix.invalid) {
      this.messageLix.markAllAsTouched()
      Swal.fire({
        icon: 'error',
        title: 'Informa o seu erro antes de enviar'
      }).then();
      return;
    }

    this.modalLix.close()
  }

  findInputMenu(input: string) {

    if (!input) {
      this.navigateMenu[1] = this.session.menu.get()[1];
    }

    const newData: InterfaceMenuList[] = [];

    this.session.menu.get()[1].menuList.map(d => {

      if (this._clearString(d.nome).match(new RegExp(`${this._clearString(input)}.*$`, 'g'))) {
        newData.push(d)
      }
    })

    this.navigateMenu[1].menuList = [...new Set(newData)];
  }

  _clearString(str: string): string {
    if (typeof str !== "string") {
      return 'error';
    }
    str = str.replace(/a|A|á|Á|â|Â|à|À|ä|Ä|ã|Ã/g, 'a')
    str = str.replace(/e|E|é|É|ê|Ê|è|È|ë|Ë/g, 'e')
    str = str.replace(/i|I|í|Í|î|Î|ì|Ì|ï|Ï/g, 'i')
    str = str.replace(/u|U|ú|Ú|û|Û|ù|Ù|ü|Ü/g, 'u')
    str = str.replace(/o|O|ó|Ó|ô|Ô|ò|Ò|ö|Ö|õ|Õ/g, 'o')
    str = str.replace(/c|C|ç|Ç/g, 'ç')

    return str.toUpperCase();
  }

  clearSearch() {
    this.inputSearch = '';
    this.findInputMenu('')
  }

}
