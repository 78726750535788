import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import {environment} from "../../../../../environments/environment";

const tipoCargo = {
  ADMIN: 'Administrativo',
  OPERADOR_LOGITISCO: 'Transportador',
  FINANCEIRO: 'Financeiro',
  PESAGEM: 'Pesagem',
  COMERCIAL: 'Comercial'
}

@DataServer({
  path: environment.apiUrl,
  context: 'funcionariogalpao'
})
export class GalpaoFuncionariosEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    galpaoId?: number | string,
    nome?: string,
    login?: string,
    sobrenome?: string,
    email?: string,
    cargo?: string,
    cpf?: string,
    nascimento?: string | number
  ) {
    super();
    this.id = id;
    this.galpaoId = galpaoId;
    this.nome = nome;
    this.login = login;
    this.sobrenome = sobrenome;
    this.email = email;
    this.cargo = cargo;
    this.cpf = cpf;
    this.nascimento = nascimento;
  }

  @DgTableColumn({columnName: 'NOME', sort: true})
  public nome: string | undefined
  @DgTableColumn({columnName: 'LOGIN', sort: true})
  public login: string | undefined
  @DgTableColumn({columnName: 'CARGO', sort: true, resource: tipoCargo})
  public cargo: string | undefined

  public galpaoId: string | number | undefined
  public sobrenome: string | undefined
  public email: string | undefined
  public cpf: string | undefined
  public nascimento: string | number | undefined
}
