<layout-side-filter idOpen="ranking-aterro" (clearFilter)="clear()">

  <div
    class="row"
    *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])"
  >
    <div class="col-12">
      <label class="form-label">Favoritos</label>
      <input-autocomplete
        key="id"
        label="nome"
        placeholder="Selecione um favorito"
        [(ngModel)]="_dataFilter.favoritos"
        [list]="(listItensDisponiveis.favoritos || [])"
        [preSelectedList]="listsPreSelected.favoritos"
        (close)="setFavoritos($event)"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div
    class="row"
  >
    <div class="col-12" *ngIf="session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])">
      <label class="form-label">Unidades Geradoras</label>
      <input-autocomplete
        #autoUnidade
        key="id"
        label="nome"
        placeholder="Selecione um gerador"
        [multiple]="true"
        [showSelectedList]="true"
        [(ngModel)]="_dataFilter.unidades"
        [list]="(listItensDisponiveis.unidades || [])"
        [preSelectedList]="listsPreSelected.unidades"
        (close)="setAutoComplete($event, 'unidades')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Início</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'dataInicio')"
        type="date"
        [max]='maxDate'
        [(ngModel)]="_dataFilter.dataInicio"
        (change)="verifyChangeData('dataInicio')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Data Fim</label>
      <input
        class="form-control"
        (paste)="ajustePasteDate($event, 'dataFim')"
        type="date"
        [(ngModel)]="_dataFilter.dataFim"
        (ngModelChange)="verifyChangeData('dataFim')"
      >
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Estados</label>
      <input-autocomplete
        #autoEstados
        key="uf"
        label="name"
        placeholder="Selecion um estado"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.estados"
        [list]="(listItensDisponiveis.estados || [])"
        [preSelectedList]="listsPreSelected.estados"
        (close)="setAutoComplete($event, 'estados')"
        classes="form-control"
      >
      </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Tipos de Resíduo</label>
      <input-autocomplete
        #autoResiduo
        key="id"
        label="nome"
        placeholder="Selecione um tipo de resíduo"
        [showSelectedList]="true"
        [multiple]="true"
        [(ngModel)]="_dataFilter.tipoResiduo"
        [list]="(listItensDisponiveis.tipoResiduo || [])"
        [preSelectedList]="listsPreSelected.tipoResiduo"
        (close)="setAutoComplete($event, 'tipoResiduo')"
        classes="form-control"
      >
      <ng-template #row_option let-option ><input class="checkBox" type="checkbox" [checked]="option.selected">{{option.row.nome}}</ng-template>
    </input-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="form-label">Ativo/Inativo</label>
      <select [(ngModel)]="_dataFilter.ativo" (change)="setQueryParams()" class="form-select">
        <option [value]="''">Ambos</option>
        <option [value]="'true'">Ativo</option>
        <option [value]="'false'">Inativo</option>
      </select>
    </div>
  </div>

</layout-side-filter>
