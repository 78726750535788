
import {AbstractEntity, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";

export class FuncionarioEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    email?: string,
    login?: string,
    nome?: string,
    sobrenome?: string,
  ) {
    super();
    this.id = id;
    this.email = email
    this.login = login;
    this.nome = nome;
    this.sobrenome = sobrenome;
  }

  @DgFilter_input()
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined

  public sobrenome: string | undefined

  @DgFilter_input()
  @DgTableColumn({ columnName: 'LOGIN', sort: true })
  public login: string | undefined

  @DgFilter_input()
  @DgTableColumn({ columnName: 'E-MAIL', sort: true })
  public email: string | undefined


 }
