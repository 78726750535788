import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {DetalhamentoUnidadeGeradoraEntity} from "../../../entitys/detalhamento-unidade-geradora.entity";
import {ConfigDgCrudService} from "../../../../../../core/config/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DashboardColetaService extends AbstractHttpService<DetalhamentoUnidadeGeradoraEntity> {

  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'dashboard')
  }
}
