<old-dg-modal
  #modal
  title="Relacionar recipiente modelo"
  size="md"
  [afterClose]="afterClose"
>
  <div dg-content>
    <div class="row">
      <label class="form-label">Selecione um recipiente</label>
      <select class="form-select" [formControl]="container">
        <option disabled value="">Selecione um recipiente</option>
        <option *ngFor="let cont of listContainer" [value]="cont.id">{{cont.nome || '--'}}</option>
      </select>
    </div>
  </div>
  <div dg-footer>
    <button (click)="save()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
