<div id="selecionar-coleta-pontual" class="container">
  <div class="container-data">
    <div class="row">
      <div class="col-12">
        <card class="card">
          <div class="card-header">
            <h4 class="card-title">{{!visualizarPlano ? "Selecionar Coleta Pontual" : "Adquira Agora"}}</h4>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="row" *ngIf="!visualizarPlano">
                <div *ngFor="let plano of listaPlano; let i = index"
                     class="col-xs-4 col-lg-4 col-md-12 col-sm-12">
                  <div class="card cardPlanos contemText">
                    <input hidden type="checkbox" [value]="false" #checkBtnCard>
                    <div [hidden]="checkBtnCard.checked">
                      <div class="card-body contemText">
                        <div class="center">
                          <h1 class="tittle" style="margin-top: 1em;">Plano</h1>
                        </div>
                        <h1 class="center titleCardColeta fc-green">{{plano.nome}}</h1>
                        <h1 class="center quantidadeCardColeta">Quantidade: {{getQuantidade(i) ?
                          getQuantidade(i) : 0 }} </h1>
                        <h1 class="center fc-green">
                          <span class="valor">R$</span>
                          {{plano.valor}}
<!--                          <span class="valor">,00</span>-->
                        </h1>
                        <button class="btn btn-fill btn-wd btn-success btn-menor mb-3"
                                (click)="selecionarPlano(i)">
                          {{getQuantidade(i) ? 'adicionar' : 'selecionar' }}
                        </button>
                        <button *ngIf="getQuantidade(i)"
                                class="btn btn-fill btn-wd btn-danger ms-2 btn-menor mb-3"
                                (click)="removerPlano(i)">remover
                        </button>

                        <card>
                          <table class="crud-table">
                            <tr *ngFor="let item of listaPlano[i].items; let indexItem = index"
                                [hidden]="indexItem > 2" class="card-itens">
                              <td>{{item}}</td>
                            </tr>
                          </table>
                        </card>

                        <div class="d-flex" style="justify-content: center; margin: 1em;">
                          <button class="btn btn-success d-flex"
                                  style="width: 5%; justify-content: center;"
                                  (click)="checkBtnCard.click()">
                            <mat-icon>question_mark</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div [hidden]="!checkBtnCard.checked">
                        <div class="card-body card-description-plano">
                          <div>
                            <div class="d-flex" style="justify-content: end;">
                              <button class="btn btn-success d-flex"
                                      style="width: 10%; justify-content: center; margin: 1em;"
                                      (click)="checkBtnCard.click()">
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                            <div class="center">
                              <h1 class="tittle fc-green" style="margin-top: 1em;">ESTE
                                PLANO
                                PERMITE
                              </h1>
                            </div>
                            <card>
                              <table class="crud-table">
                                <tr *ngFor="let item of listaPlano[i].items; let indexItem = index"
                                    [hidden]="indexItem <= 2"
                                    class="card-itens-reverse">
                                  <td>{{item}}</td>
                                </tr>
                              </table>
                            </card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table class="crud-table" *ngIf="visualizarPlano">
              <thead>
              <th>NOME</th>
              <th>VALOR</th>
              <th>QUANTIDADE</th>
              </thead>
              <tbody>
              <tr *ngFor="let item of listaPlanoSelecionado; let i = index">
                <td>
                  {{ item.plano.nome }}
                </td>
                <td>
                  {{ item.plano.valor }}
                </td>
                <td>
                  {{ item.quantidade }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>

<page-footer>
  <button *ngIf="!visualizarPlano" class="btn btn-success" (click)="visualizarPlanos()">Prosseguir</button>
  <div *ngIf="!!visualizarPlano">
    <button class="btn btn-secondary" (click)="voltar()">Voltar</button>
    <button class="btn btn-success" (click)="contratar()">Contratar</button>
  </div>
  <span class="ms-4">Valor: R$ {{getTotal()}} </span>
</page-footer>
