import {AbstractEntity, DataServer, DgTableColumn} from "@datagrupo/dg-crud";
import { ClientesEntity } from "../../_clientes/clientes/clientes.entity";
import {environment} from "../../../../../environments/environment";

@DataServer({
  path: environment.apiUrl,
  context: 'dashboard/datatable'
})
export class DetalhamentoUnidadeGeradoraEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    unidadeGeradora?: string,
    dataColeta?: string,
    estimativaPeso?: string,
    numeroColetas?: string,
    pesoDia?: string
  ) {
    super();
    this.dataColeta = dataColeta;
    this.unidadeGeradora = unidadeGeradora;
    this.estimativaPeso = estimativaPeso;
    this.numeroColetas = numeroColetas;
    this.pesoDia = pesoDia;
  }

  @DgTableColumn({
    columnName: 'Data Coleta', resource: (cel: string) => {
      return cel.split('-').reverse().join('/');
    }
  })
  public dataColeta: string | undefined

  @DgTableColumn({ columnName: 'Gerador' })
  public unidadeGeradora: string | undefined

  @DgTableColumn({
    columnName: 'Peso da coleta (TON)', resource: (val: string | number) => {
      return !!val ? Number(val).toLocaleString("pt-BR", {maximumFractionDigits: 2}) : '--'
    }
  })
  public estimativaPeso: string | undefined

  @DgTableColumn({ columnName: 'Peso recebido' })
  public pesoDia: string | undefined

  @DgTableColumn({ columnName: 'totalContainersColetados' })
  public numeroColetas: number | string | undefined

  @DgTableColumn({ columnName: 'Total de recipientes coletados' })
  public totalContainersColetados: number | string | undefined

  @DgTableColumn({ columnName: 'Tipo de residuo' })
  public tipoLixoNome: number | string | undefined

  @DgTableColumn({ columnName: 'Nome do container' })
  public containerNome: number | string | undefined

  @DgTableColumn({ columnName: 'Custo total tonelada' })
  public custoTotalTonelada: number | string | undefined
}
