import {SideFilterComponent} from "../side-filter/side-filter.component";
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_OPERADOR_LOGISTICO,
  DASHBOARD_FILTER_UNIDADE_GERADORA, TIPO_LIXO_FIND_FILTER
} from "../../../core/config/endpoint-list";
import {ClientesEntity} from "../../../pages/_user/_clientes/clientes/clientes.entity";
import {GalpaoEntity} from "../../../pages/_user/_galpao/galpao/galpao.entity";
import {
  SidefilterDashboardColetasComponent
} from "../custom-side-filter/sidefilter-dashboard-coletas/sidefilter-dashboard-coletas.component";
import {AbstractSideFilter} from "./abstract-side-filter";


export class SidebarDataManage {
  constructor(public root: AbstractSideFilter | SideFilterComponent) {}

  ajustDataByEventDispacher() {
    const { dataFim, dataInicio, tipoResiduo, estados, galpao, unidades, ativo } = this.root._dataFilter;

    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    const result: any = {}

    if (!!dataFim) result['dataFim'] = dataFim;
    if (!!dataInicio) result['dataInicio'] = dataInicio;
    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;
    if ((tipoResiduo || []).length > 0) result['tipoResiduo'] = tipoResiduo;
    if ((estados || []).length > 0) result['estados'] = estados;
    if ((galpao || []).length > 0) result['galpao'] = galpao;
    if ((unidades || []).length > 0) result['unidades'] = unidades;

    return result;
  }

  clearFilter() {
    let unidades: string[] | number [] = [];

    // if (this.root.tipoDeTela == 'dashboard') {
    //   if (this.root.session.checkPerfil(['CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
    //     unidades = this.root._dataFilter.unidades
    //   }
    // }

    this.root._dataFilter = {
      perfilUnidade: 'CONDOMINIO',
      estados: [],
      unidades,
      galpao: [],
      tipoResiduo: [],
      dataInicio: '',
      dataFim: '',
      ativo: ''
    }
    this.root.listSelected = {
      unidades: [],
      estados: [],
      galpao: [],
      tipoResiduo: [],
      perfilUnidade: ''
    }

    // this.root.setQueryParams();
    this.getDataAutocompletes();
  }

  getDataAutocompletes() {
    const unidades = {}//(this.root._dataFilter.unidades || []).length > 0 ? (this.root._dataFilter.unidades).join(',') : undefined
    const operadores = {}//(this.root._dataFilter.galpao || []).length > 0 ? (this.root._dataFilter.galpao).join(',') : undefined

    this.root._service.get(
      DASHBOARD_FILTER_UNIDADE_GERADORA,
      {
        params: {
          unpaged: true,
          // ...(!!operadores ? {operadores} : {})
        }
      }).subscribe(
      resp => {
        this.root.listItensDisponiveis.unidades = resp.data;


        this.root.listItensDisponiveis.unidades = this.root.listItensDisponiveis.unidades.sort(
          (a: ClientesEntity, b: ClientesEntity) => {
            //@ts-ignore
            return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
          }
        )
      }
    );
    this.root._service.get(
      DASHBOARD_FILTER_OPERADOR_LOGISTICO,
      {
        params: {
          unpaged: true,
          // ...(!!unidades ? {unidades} : {})
        }
      }).subscribe(
      resp => {
        this.root.listItensDisponiveis.galpao = resp.data;

        this.root.listItensDisponiveis.galpao = this.root.listItensDisponiveis.galpao.sort(
          (a: GalpaoEntity, b: GalpaoEntity) => {
            //@ts-ignore
            return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
          }
        )
      }
    );
    this.root._service.get(CLIENTE_ESTADOS, { params: { unpaged: true } }).subscribe(
      resp => {
        this.root.listItensDisponiveis.estados = resp.data
      }
    )
    this.root._service.get(TIPO_LIXO_FIND_FILTER, { params: { unpaged: true } }).subscribe(
      resp => {
        this.root.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )
  }

  verifyChangeData(tipo: 'dataFim' | 'dataInicio' | 'perfilUnidade'):void {
    if (tipo == 'perfilUnidade') {
      this.root.setQueryParams();
      return
    }
    //
    // const date = new Date(tipo == 'dataFim' ? this.root._dataFilter.dataFim : this.root._dataFilter.dataInicio);
    //
    // if (!isNaN(date.valueOf())) {
    //   if (date > new Date('01-01-2000')) {
    //     this.root.setQueryParams()
    //   }
    // }
  }

}
