import {AbstractEntity, DgActionsTable, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import {ActionTableCupomService} from "./services/action-table-cupom/action-table-cupom.service";

@DgActionsTable(ActionTableCupomService)
export class CupomEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    link?: string,
    enviarCupomTodos?: boolean,
    descricao?: string
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.link = link;
    this.enviarCupomTodos = !!enviarCupomTodos;
    this.descricao = descricao;
  }

  @DgFilter_input('text',{ colum: '4' })
  @DgTableColumn({ columnName: 'NOME', sort: true})
  public nome: string | undefined;

  @DgTableColumn({ columnName: 'LINK', sort: true})
  public link: string | undefined;
  public enviarCupomTodos: boolean | undefined;
  public descricao: string | undefined;
}
