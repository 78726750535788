import { LogisticaProdutosEntity } from './logistica-produtos.entity';
import { AbstractEntity, DgFilter_autoComplete, DgFilter_input, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { LogisticaDocumentoEntity } from "./logistica-documento.entity";
import { Enderecos } from 'src/app/shared/entitys/enderecos';
import { ClientesEntity } from '../_clientes/clientes/clientes.entity';
import {ParceiroEntity} from "../parceiro/parceiro.entity";


const covidSelect = [
  {k: 'todos', v: 'Todos'},
  {k: 'true', v: 'Sim'},
  {k: 'false', v: 'Não'}
]


export class LogisticaReversaEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    endereco?: Enderecos,
    nome?: string,
    nomeContato?: string,
    cnpj?: string,
    comprovante?: string,
    email?: string,
    dataInclusao?: string,
    telefone?: string,
    documento?: LogisticaDocumentoEntity[],
    produto?: LogisticaProdutosEntity[],
    parceiro?: ParceiroEntity
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.nomeContato = nomeContato;
    this.cnpj = cnpj;
    this.comprovante = comprovante;
    this.email = email;
    this.dataInclusao = dataInclusao;
    this.telefone = telefone;
    this.documento = Array.isArray(documento) ? documento : [];
    this.produto = Array.isArray(produto) ? produto : [];
    this.endereco = endereco;
    this.parceiro = parceiro;
  }
  @DgFilter_input('text',{colum: '3'})
  @DgTableColumn({ columnName: 'NOME', sort: true })
  public nome: string | undefined

  @DgFilter_input('text',{colum: '3', mask: 'cnpj'})
  @DgTableColumn({ columnName: 'CNPJ',  mask: 'cnpj', sort: true })
  public cnpj: string | undefined

  @DgTableColumn({
     columnName: 'CONTATO',
     resource: (val: string ) => !!val ? val : '--',
     sort: true })
  public nomeContato: string | undefined

  @DgTableColumn({ columnName: 'E-MAIL', sort: true })
  public email: string | undefined

  public dataInclusao: number | string | undefined
  public documento: LogisticaDocumentoEntity[] = [];
  public produto: LogisticaProdutosEntity[] = [];
  public endereco: Enderecos | undefined;
  public telefone: string | undefined;
  //public nomeContato : string | undefined;
  public comprovante: string | undefined;
  parceiro: ParceiroEntity | undefined;

}
