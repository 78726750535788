import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHelpersService } from 'src/app/services/http-helpers/http-helpers.service';
import { SolicitacaoCadastroEntity } from '../solicitacao-cadastro.entity';
import Swal from 'sweetalert2';
import { GenericCrudService } from 'src/app/core/services/generic-crud-service/generic-crud.service';
import {ajusteDateByPaste} from "../../../../helpers/helpers";
import {CADASTRAR_WEBSITE} from "../../../../core/config/endpoint-list";

@Component({
  selector: 'app-solicitacao-cadastro-insert',
  templateUrl: './solicitacao-cadastro-insert.component.html',
  styleUrls: ['./solicitacao-cadastro-insert.component.scss'],
})
export class SolicitacaoCadastroInsertComponent implements OnInit {

  entity = new SolicitacaoCadastroEntity()

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ dataEvento: text }))

  perfilCadastro:
    | 'OPERADOR_LOGISTICO'
    | 'CONDOMINIO'
    | 'CASA'
    | 'EMPRESA'
    | 'EVENTO'
    | string
    | undefined;

  public form!: FormGroup;

  constructor(
    public httpHelper: HttpHelpersService,
    public service: GenericCrudService,
    public router: Router,
    public route: ActivatedRoute

    ) {
      this.form = this.createForm();
      this.observarCep();
    }

    ngOnInit(): void {
      this.route.queryParams.subscribe(params =>{
        let perfil = params['perfil']
       if(perfil != null && perfil){
          perfil = perfil.toUpperCase();

          if(perfil == 'unidade_geradora') {
            this.escolhePerfil('CONDOMINIO')
          }else{
            this.escolhePerfil(perfil);
          }
        }
      })
    }

    private createForm(): FormGroup{
      return new FormGroup({
        nome: new FormControl('', [Validators.required]),
        sobrenome: new FormControl('', [Validators.required]),
        telefone: new FormControl('', [Validators.required]),
        login: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),

        tipoDeCadastro: new FormControl('', [Validators.required]),
        nomeCondominio: new FormControl('', [Validators.required]),
        nomeEvento: new FormControl('', [Validators.required]),
        diasEvento: new FormControl('', [Validators.required]),
        dataEvento: new FormControl('', [Validators.required]),
        qtdApartamentos: new FormControl('', [Validators.required]),
        quartosCondominio: new FormArray([]),
        infoColeta: new FormControl('', [Validators.required]),
        complemento: new FormControl(''),
        logradouro: new FormControl('', [Validators.required]),
        cep: new FormControl('', [Validators.required]),
        numero: new FormControl('', [Validators.required]),
        bairro: new FormControl('', [Validators.required]),
        cidade: new FormControl('', [Validators.required]),
        estado: new FormControl('', [Validators.required]),
        nomeEmpresa: new FormControl('', [Validators.required]),
        tipoNegocio: new FormControl('', [Validators.required]),
        cargo: new FormControl('', [Validators.required])
      });
    }

    public changeFormValidators(perfil:string): void{
      this.form.controls['nome'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['sobrenome'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['telefone'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['login'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['email'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();

      this.form.controls['tipoDeCadastro'][["condominio"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['nomeCondominio'][["condominio"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['nomeEvento'][["evento"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['diasEvento'][["evento"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['dataEvento'][["evento"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['qtdApartamentos'][["condominio"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['quartosCondominio'][["condominio"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['infoColeta'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['complemento'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['logradouro'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['cep'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['numero'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['bairro'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['cidade'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['estado'][["condominio", "casa", "evento", "empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['nomeEmpresa'][["empresa", "operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['tipoNegocio'][["empresa"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
      this.form.controls['cargo'][["operador_logistico"].findIndex(p=> p == perfil.toLowerCase()) >= 0 ? "enable" : "disable"]();
    }


 /** ##### CRIANDO FORMS ##### */
  escolhePerfil(perfil: string) {
    this.perfilCadastro = perfil;
    this.changeFormValidators(perfil);
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {perfil}})
  }

/** ##### METODOS DE VERIFICAÇÃO ##### */

  verifyCheked(valor: string) {
  if (!Array.isArray(this.entity?.qtdQuartosPorApartamento))
    this.entity['qtdQuartosPorApartamento'] = [];

  const index = this.entity.qtdQuartosPorApartamento.findIndex(
    (arrayDia) => arrayDia == valor
  );

  return index != -1;
}

  observarCep() {
    this.form.controls['cep'].valueChanges.subscribe((cep) => {
      if (cep == this.form.value.cep) return

      if (!!cep) {
        this.httpHelper.getCep(cep, (data: any) => {
          this.form.patchValue({
            logradouro: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
          });
        });
      }
    });
  }

  checkedQuartosApartamentos(valor: string) {
    if (!Array.isArray(this.entity.qtdQuartosPorApartamento))
      this.entity.qtdQuartosPorApartamento = [];
    const index = this.entity.qtdQuartosPorApartamento.findIndex(
      (arrayDia) => arrayDia == valor
    );

    if (index == -1) {
      this.entity.qtdQuartosPorApartamento.push(valor);
      return;
    }

    this.entity.qtdQuartosPorApartamento.splice(index, 1);
  }

  salvarInfo() {
    if (!this.form.valid) {
      if (this.form.value.infoColeta == '') {
        Swal.fire({
          icon: 'warning',
          title: "Preencha o campo sobre receber informações"
        })
      }
      this.form.markAllAsTouched();
      return false;
    }

    const {
      bairro,
      cep,
      cidade,
      complemento,
      logradouro,
      numero,
      estado,
      qtdApartamentos,
      predio,
      infoColeta,
      ...form
    } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      perfilCadastro: this.perfilCadastro,
      infoColeta: infoColeta,
      predio: {
        qtdQuartosPorApartamento: this.entity.qtdQuartosPorApartamento,
        qtdApartamentos,
      },
      enderecos: [
        {
          bairro,
          cep,
          cidade,
          complemento,
          logradouro,
          numero,
          estado,

        },
      ],
    };

    this.service.post(CADASTRAR_WEBSITE, this.entity).subscribe(r =>{
      localStorage.clear();
      this.cadastradoOk()
    });
    return true;
  }

  cadastradoOk() {
    Swal.fire({
      icon: 'success',
      title: 'Cadastro realizado com sucesso, cheque seu e-mail para verificar a senha.',
      showCancelButton: false,

    }).then(r => {
      if(r.isConfirmed == true){
        this.router.navigate(['login']);
      }
    });
  }

  voltarLogin() {
    this.router.navigate(['login']);
    localStorage.clear();
  }
}
