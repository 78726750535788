<div id="fluxo-cobranca-insert">
    <div class="container-data">
        <div class="row">
            <div class="col-12">
                <div>
                    <form [formGroup]="form">
                        <card>
                            <div class="card-header">
                                <h4 class="card-title">Fluxo da Cobrança</h4>
                                <hr>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <label class="form-label">Cliente</label>
                                        <input-auto-complete
                                            [baseValue]="entity?.nomeCliente"
                                            propertValue="nome"
                                            controlName="nomeCliente"
                                            urlServer="clientes/nomes"
                                            (outValue)="this.entity.nomeCliente = $event"
                                        >
                                        </input-auto-complete>
                                    </div>
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <label class="form-label">Plano</label>
                                        <input-auto-complete
                                            #autocompletePlano
                                            propertValue="nome"
                                            controlName="planoValor"
                                            urlServer="planos/nomes"
                                            (outValue)="entity.planoValor = $event"
                                        >
                                        </input-auto-complete>
                                    </div>
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <label class="form-label">VALOR DO PLANO CONTRATADO</label>
                                        <div class="input-group w-100">
                                            <div class="input-group-prepend">
                                                <span
                                                    class="input-group-text"
                                                    id="basic-addon1"
                                                >
                                                    R$:
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                class="form-control"
                                                formControlName="valorPlanoContratado"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                        <label
                                            class="form-label"
                                            for="metodoPagameto"
                                        >
                                            MÉTODO DE PAGAMENTO
                                        </label>
                                        <select
                                            name="metodoPagameto"
                                            id="metodoPagameto"
                                            class="form-select"
                                            formControlName="metodoPagamento"
                                        >
                                            <option value>Selecione</option>
                                            <option *ngFor="let item of listaMetodoPagamento">{{item}}</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-md-4 col-sm-12">
                                        <label class="form-label">DIA DO VENCIMENTO DA FATURA</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="diaVencimentoFatura"
                                        >
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-md-5 col-sm-12">
                                        <label class="form-label">DATA DE CONTRATAÇÃO DO PLANO</label>
                                        <input
                                            type="date"
                                            (paste)="ajustePasteDate($event)"
                                            class="form-control"
                                            formControlName="dataContratacaoPlano"
                                        >
                                    </div>
                                    <div
                                        class="col-xl-3 col-lg-4 col-md-3 col-sm-12 d-flex mt-4"
                                        style="align-items: center;"
                                    >
                                        <label
                                            class="form-label"
                                            for="aprovado"
                                        >
                                            APROVADO
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="aprovado"
                                            class="ms-2"
                                            id="aprovado"
                                            formControlName="aprovado"
                                        >
                                    </div>
                                </div>
                            </div>
                        </card>




                        <div class="mt-4">
                            <card>
                                <div class="card-header">
                                    <h4 class="card-title">
                                        Fluxo Relacionado ao
                    {{this.entity?.nomeCliente?.nome ? this.entity?.nomeCliente?.nome : ''}}
                                    </h4>
                                    <hr>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <table class="crud-table">
                                                <thead>
                                                    <tr>
                                                        <th>CLIENTE</th>
                                                        <th>DIA VENCIMENTO</th>
                                                        <th>MÉTODO DE PAGAMENTO</th>
                                                        <th>APROVAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of listaAprovados; let i = index">
                                                        <td>{{item.nomeCliente.nome}}</td>
                                                        <td>{{item.diaVencimentoFatura}}</td>
                                                        <td>{{item.metodoPagamento}}</td>
                                                        <td>{{!!item.aprovado ? 'Sim' : 'Não'}}</td>
                                                    </tr>
                                                    <tr *ngIf="listaAprovados.length <= 0">
                                                        <td colspan="4">Nenhum registro encontrado</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </form>




                </div>
                <div class="mt-4">
                    <card>
                        <div class="card-header">
                            <h4 class="card-title">Receptores Venda</h4>
                            <button
                                class="btn btn-success ms-2 fs-10"
                                style="min-height: 0.5em; min-width: 11em;"
                                (click)="addReceptor()"
                            >
                                adicionar
                            </button>
                            <hr>
                        </div>
                        <div class="card-body">
                            <div class="col-12">
                                <card>
                                    <div class="row">
                                        <div class="card-body">
                                            <div class="container">
                                                <div
                                                    class="row container-fluid"
                                                    style=" padding: 1em;"
                                                    *ngFor="let item of listaReceptores; let i = index"
                                                >
                                                    <div class="col-xl-5 col-lg-5 col-md-4 col-sm-12">
                                                        <label>
                                                            Transportador
                                                            <small class="fs-red">*</small>
                                                        </label>
                                                        <select
                                                            name="galpao"
                                                            id="galpao"
                                                            class="form-select"
                                                            [formControl]="item.idGalpao"
                                                        >
                                                            <option value>Selecione</option>
                                                            <option
                                                                *ngFor="let galpao of listaGalpao"
                                                                [value]="galpao.id"
                                                            >
                                                                {{galpao.nome}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-xl-5 col-lg-5 col-md-4 col-sm-12">
                                                        <label>Percentual(%)</label>
                                                        <input
                                                            type="number"
                                                            id="percentual"
                                                            class="form-control"
                                                            min="0"
                                                            [formControl]="item.percentual"
                                                            (keydown)="checkNumber($event)"
                                                        >
                                                    </div>
                                                    <div
                                                        class="col-xl-2 col-lg-2 col-md-4 col-sm-4 d-flex mt-4"
                                                        style="justify-content: center; align-items: center;"
                                                    >
                                                        <button
                                                            class="btn btn-success"
                                                            style="min-width: 8em;"
                                                            (click)="removerReceptor(i)"
                                                        >
                                                            excluir
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</div>




<page-footer>
    <button
        crud-voltar
        class="btn btn-secondary"
    >
        Voltar
    </button>
    <button
        crud-salvar
        dg-desable-by-period
        [backAfterSave]="false"
        class="btn btn-success"
    >
        Salvar
    </button>
    <button
        crud-salvar
        class="btn btn-success"
    >
        Salvar e Voltar
    </button>
</page-footer>
