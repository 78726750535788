import {Component, Input, OnInit} from '@angular/core';
import {AbstractSideFilter, dataSeideFilter} from "../../cdk/abstract-side-filter";
import {ClientesEntity} from "../../../../pages/_user/_clientes/clientes/clientes.entity";
import {TipoLixoEntity} from "../../../../pages/_user/tipo-lixo/tipo-lixo.entity";
import {GenericCrudService} from "../../../../core/services/generic-crud-service/generic-crud.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../core/services/session-service/session.service";
import {ajusteDateByPaste, formata_data_utc_br} from "../../../../helpers/helpers";
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_UNIDADE_GERADORA, TIPO_LIXO_FIND_FILTER
} from "../../../../core/config/endpoint-list";
import {CloseAutocomplete} from "@datagrupo/dg-ng-util";

@Component({
  selector: 'sidefilter-ranking',
  templateUrl: './sidefilter-ranking.component.html',
  styleUrls: ['./sidefilter-ranking.component.scss']
})
export class SidefilterRankingComponent extends AbstractSideFilter implements OnInit {

  @Input('tipoRanking') tipoRanking : 'aterroZero' | 'taxaReciclagem' = "aterroZero";
  objParamName = 'ranking';

  override listsPreSelected: {
    unidades: any[],
    estados: any[],
    tipoResiduo: any[]
  } = {
    unidades: [],
    estados: [],
    tipoResiduo: []
  }
  listSelected: {
    unidades: any[],
    estados: any[],
    tipoResiduo: any[]
  } = {
    unidades: [],
    estados: [],
    tipoResiduo: []
  }
  public _dataFilter = {
    unidades: <any[]>[],
    estados: <any[]>[],
    tipoResiduo: <any[]>[],
    periodoInicio: '',
    periodoFim: '',
    ativo: '',
    perfilUnidade: ''
  };
  listItensDisponiveis: {
    unidades: ClientesEntity[],
    estados: {name: string, uf: string}[],
    tipoResiduo: TipoLixoEntity[],
  } = {
    unidades: [],
    estados: [],
    tipoResiduo: [],
  }

  timeout: any = null;
  public maxDate = '';

  constructor(
    public service: GenericCrudService,
    private router: Router,
    private route: ActivatedRoute,
    public session: SessionService
  ) {
    super(service, router, route);
    this.maxDate = formata_data_utc_br(new Date().toDateString()).split('/').reverse().join('-')
  }

  ngOnInit(): void {
    this.verifyParamsStart()
  }

  //*************************** METODOS PARA O ABSTRACT ***************************//

  setInitParams(params?: any, preValues?: any): void {
    if (!!params) {
      this.listsPreSelected = {
        ...this.listsPreSelected,
        ...preValues.listsPreSelected
      };
      this.listSelected = {
        ...this.listSelected,
        ...preValues.listSelected
      };
      this._dataFilter = {
        ...this._dataFilter,
        ...preValues._dataFilter
      };
    }

    if (this.tipoRanking == 'taxaReciclagem') {
      this._dataFilter.perfilUnidade = 'CONDOMINIO'
    }

    this.getDataAutocompletes();
    this.setQueryParams();
  }

  ajsutDataSetParams(preValues: object) {
    const { ativo } = this._dataFilter;
    const result: any = preValues;
    const newAtivo = !!ativo ? (ativo == 'true') : undefined;

    if (typeof newAtivo == 'boolean') result['ativo'] = newAtivo;

    return result;
  }

  clearFilter(preValues: dataSeideFilter) {
    this._dataFilter = {
      ...this._dataFilter,
      ...preValues._dataFilter
    }
    this.listSelected = {
      ...this.listSelected,
      ...preValues.listSelected
    }
    this.listsPreSelected = {
      ...this.listsPreSelected,
      ...preValues.listsPreSelected
    }

    this.updateDataAutocompletes('all')
  }

  ajsutDataEmiter(preValues: object) {
    return preValues;
  }

  //*************************** METODOS LOCAIS ***************************//

  verifyUpdateParams(list: any[], selectedList: any[], identificador: 'estados' | string = ''): boolean {
    if (list.length != selectedList.length) {
      return true;
    }

    let result = false;

    selectedList.map(item => {
      if (result) return;

      let index: number = -1;

      if (identificador == "estados") {
        index = list.findIndex(it => it?.uf == item?.uf)
      } else {
        index = list.findIndex(it => it?.id == item?.id)
      }
      result = index == -1;
    })

    return result;
  }

  verifyChangeData(tipo: 'periodoFim' | 'periodoInicio' | 'perfilUnidade'):void {
    if (tipo == 'perfilUnidade') {
      this.setQueryParams()
      return
    };

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const date = new Date(tipo == 'periodoFim' ? this._dataFilter.periodoFim : this._dataFilter.periodoInicio);

      if (!isNaN(date.valueOf())) {
        if (date >= new Date('1995-12-17T03:24:00')) {
          this.setQueryParams()
        }
      } else if (this._dataFilter[tipo] == '') {
        this.setQueryParams();
      }
    }, 300);
  }

  getDataAutocompletes() {
    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA'])) {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true,
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;


          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }

    this.service.get(CLIENTE_ESTADOS, { params: { unpaged: true } }).subscribe(
      resp => {
        this.listItensDisponiveis.estados = resp.data
      }
    )

    this._service.get(TIPO_LIXO_FIND_FILTER, { params: { unpaged: true } }).subscribe(
      resp => {
        this.listItensDisponiveis.tipoResiduo = resp.data;
      }
    )
  }

  updateDataAutocompletes(origin: 'unidades' | 'all') {

    if (!this.session.checkPerfil(['CONDOMINIO', 'EVENTO', 'CASA', 'EMPRESA']) && origin != 'unidades') {
      this._service.get(
        DASHBOARD_FILTER_UNIDADE_GERADORA,
        {
          params: {
            unpaged: true
          }
        }).subscribe(
        resp => {
          this.listItensDisponiveis.unidades = resp.data;


          this.listItensDisponiveis.unidades = this.listItensDisponiveis.unidades.sort(
            (a: ClientesEntity, b: ClientesEntity) => {
              //@ts-ignore
              return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
            }
          )
        }
      );
    }
  }

  public ajustePasteDate = (ev: any, varName: 'periodoInicio' | 'periodoFim') => ajusteDateByPaste(ev, (text: string) => this._dataFilter[varName] = text)

  setAutoComplete(data: CloseAutocomplete, identificador: | 'unidades' | 'tipoResiduo' | 'estados') {
    if(this.verifyUpdateParams(this.listSelected[identificador], data.selectedList, identificador)) {
      this.listSelected[identificador] = [...data.selectedList];
      this.setQueryParams();
    } else {
      this.listSelected[identificador] = [...data.selectedList];
    }
  }
}
