<div id="relatorio-missoes-main" class="container">

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-inline-flex justify-content-end">
        <button
          class="btn btn-outline dashboard-buttons"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sideFilter-ranking-aterro"
          aria-controls="sideFilter-ranking-aterro"
        >
          <mat-icon>search</mat-icon>
          Filtros
        </button>

        <div class="btn-group">
          <button
            class="btn btn-outline dashboard-buttons dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <mat-icon>settings</mat-icon>
            Opções
          </button>

          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li>
              <button class="dropdown-item" type="button" (click)="generate_pdf_target()">
                <mat-icon class="icon-pre">print</mat-icon>
                <span>Imprimir</span>
              </button>
            </li>
            <li>
              <button class="dropdown-item" type="button" (click)="downloadExcel()">
                <mat-icon class="icon-pre">download</mat-icon>
                <span>Excel</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr class="fc-grey">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <div class="col-12">
            <h4 class="card-title mb-0">Taxa De Lixo Zero</h4>
            <p class="card-subtitle fs-11 text-muted fc-grey">Caso nenhum período seja selecionado, serão apresentados
              os registros dos ultimos 30 dias</p>
          </div>
        </div>

        <div class="card-body" style="padding: 0">
          <crud-data-table
            #table
            tableEventId="RankingAterroZero"
            [persistentParams]="tableParams"
            [entityMap]="mapEntity"
            [showDefaultAction]="false"
            [customDblclick]="true"
            [fetchStart]="false"
            sort="clienteNome"
          ></crud-data-table>
        </div>
        <div class="card-footer">
          <dg-pagination
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="table.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>

<sidefilter-dashboard-ranking-aterro-zero></sidefilter-dashboard-ranking-aterro-zero>
