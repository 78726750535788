import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { CategoriaNegocioEntity } from '../categoria-negocio.entity';
import { CategoriaNegocioService } from '../service/categoria-negocio.service';

@Component({
  selector: 'app-categoria-negocio-insert-edit',
  templateUrl: './categoria-negocio-insert-edit.component.html',
  styleUrls: ['./categoria-negocio-insert-edit.component.scss']
})
export class CategoriaNegocioInsertEditComponent extends AbstractInsertEdit<CategoriaNegocioEntity> implements OnInit {

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
  })

  constructor(
    public servic: CategoriaNegocioService,
    public conf: ConfigDgCrudService,
  ) {
    super(conf, servic);

    this.crudConfig.subscribeEntityAfterSave = false;
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  initNewEntity(): void {
    this.entity = new CategoriaNegocioEntity()
  }

  override afterFetchEntity(): void {
    //@ts-ignore
    //this.entity = this.entity[this.entityId]
  //  this.form.patchValue({
      //@ts-ignore
    //  ...this.entity

      this.form.patchValue({
        ...this.entity
      })
 //   })
  }

  override beforeSaveEntity(): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }
    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }
    return true;
  }

}
