import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OldModalComponent } from "../../../../../../shared/ui/modal/modal/modal.component";
import { GalpaoMotoristaVeiculoEntity } from "../../../galpao-motorista-veiculo/galpao-motorista-veiculo.entity";
import {
  GalpaoMotoristaVeiculoService
} from "../../../galpao-motorista-veiculo/service/galpao-motorista-veiculo.service";
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'subcomponent-motorista-veiculo',
  templateUrl: './motorista-veiculo.component.html',
  styleUrls: ['./motorista-veiculo.component.scss']
})
export class MotoristaVeiculoComponent implements OnInit {

  public form = new FormGroup({
    id: new FormControl(''),
    idMotorista: new FormControl('', [Validators.required]),
    marca: new FormControl('', [Validators.required]),
    tipo: new FormControl('', [Validators.required]),
    capacidade: new FormControl('', [Validators.required]),
    anoFabricacao: new FormControl('', [Validators.required]),
    renavam: new FormControl('', [Validators.required]),
    numeroPlaca: new FormControl('', [Validators.required])
  })

  @ViewChild('modalVeiculo') modal!: OldModalComponent;

  constructor(private service: GalpaoMotoristaVeiculoService) {
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['capacidade'])
    )
  }

  ngOnInit(): void {
  }

  public open(data?: GalpaoMotoristaVeiculoEntity | any) {
    if (!!data) {
      console.log('open', data)
      this.form.patchValue({
        ...data,
        capacidade: InputHelpers.initInputDecimal(data.capacidade || '') || data.capacidade,
        idMotorista: data.motorista.id
      })
    }
    this.modal.open()
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { capacidade, idMotorista, ...form } = this.form.value;

    const data = <GalpaoMotoristaVeiculoEntity>{
      ...form,
      capacidade: InputHelpers.ajusteMoneyBrToEua(capacidade),
      motorista: { id: idMotorista }
    }
    console.log('salavar', data)

    this.service.saveOrUpdate(data).subscribe(
      () => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', { detail: 'GalpaoMotoristaVeiculoEntity' }));
        this.modal.close();
      }
    )
  }

  afterClose = () => {
    this.form.reset()
  }

}
