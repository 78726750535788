
import {
  AbstractEntity,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn
} from "@datagrupo/dg-crud";
import { ArquivoEntity } from "src/app/shared/entitys/arquivo.entity";
import { environment } from "src/environments/environment";
import { ParceiroEntity } from "../parceiro/parceiro.entity";



const dispSelec = [
  {k: 'todos', v: 'Todos'},
  {k: 'true', v: 'Sim'},
  {k: 'false', v: 'Não'}
]
export class MaterialEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    descricao?: string,
    // disponiveis?: string,
    valor?: string,
    disponivel?: boolean,
    minimoPedido?: number,
    pontos?: string,
    precoPorPontos?: number,
    parceiro?: ParceiroEntity,
    imagem?: ArquivoEntity
  ) {

    super();
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
    // this.disponiveis = disponiveis;
    this.valor = valor;
    this.disponivel = disponivel;
    this.minimoPedido = minimoPedido;
    this.pontos = pontos;
    this.precoPorPontos = precoPorPontos
    this.parceiro = parceiro;
    this.imagem = imagem;
  }

  @DgFilter_input()
  @DgTableColumn({ columnName: 'NOME', sort: true, width: '40%' })
  public nome: string | undefined;

  public descricao: string | undefined;
  //@DgFilter_select({data: ['todos', 'sim', 'não'], name: 'DISPONIVEIS?'})
  //  public disponiveis: string | undefined

  @DgTableColumn({ columnName: 'VALOR', resource: (val: number) => {
    return !!val ? val.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) : '--';
  } })
  public valor: string | undefined;

  public minimoPedido: number | undefined;

  @DgTableColumn({ columnName: 'PONTOS', sort: true })
  public pontos: string | undefined;

  public precoPorPontos: number | undefined;


  @DgFilter_select({
    data: dispSelec,
    name: "DISPONIVEIS?",
    defaultData: 'todos',
    propertKey: 'k',
    propertValue: 'v',
    outputFormat: (val: any) => {
      console.log(val)
      if (val == 'todos') return {};

      return { disponivel: val }
    }
  })
  @DgTableColumn({ columnName: 'DISPONIVEL', sort: true, resource: (disp: boolean) => !!disp ? 'Sim' : 'Não'})
  public disponivel: boolean | undefined

  @DgTableColumn({ columnName: 'PARCEIRO', resource: (parceiro: ParceiroEntity) => !!parceiro?.nome ? parceiro?.nome : 'Não Possui' })
  public parceiro: ParceiroEntity | undefined

  public imagem: ArquivoEntity | undefined

}
