import { Enderecos } from 'src/app/shared/entitys/enderecos';
import { AbstractEntity } from '@datagrupo/dg-crud';

export class SolicitacaoCadastroEntity extends AbstractEntity {
  constructor(
    id?: number,
    perfil?: string,
    tipoDeCadastro?: string,
    nome?: string,
    sobrenome?: string,
    login?: string,
    telefone?: string,
    // nomeCondominio?: string,
    email?: string,
    numApartamentoCondominio?: number,
    qtdQuartosPorApartamento?: string[],
    endereco?: Enderecos[],
    cep?: number,
    predio?: string[],
    cargo?: string
  ) {
    super();
    this.id = id;
    this.tipoDeCadastro = tipoDeCadastro;
    this.perfil = perfil;
    this.nome = nome;
    this.sobrenome = sobrenome;
    this.login = login;
    this.telefone = telefone;
    // this.nomeCondominio = nomeCondominio;
    this.email = email;
    this.numApartamentoCondominio = numApartamentoCondominio;
    this.qtdQuartosPorApartamento = Array.isArray(qtdQuartosPorApartamento) ? qtdQuartosPorApartamento : [];
    this.endereco = endereco;
    this.cep = cep;
    this.predio = predio;
    this.cargo = cargo;
  }

  public tipoDeCadastro: string | undefined;
  public perfil: string | undefined;
  public nome: string | undefined;
  public sobrenome: string | undefined;
  public login: string | undefined;
  public telefone: string | undefined;
  // public nomeCondominio: string | undefined;
  public email: string | undefined;
  public numApartamentoCondominio: number | undefined;
  public qtdQuartosPorApartamento: string[] = [];
  public endereco: Enderecos[] | undefined;
  public cep: number | undefined;
  public predio: string[] | undefined;
  public cargo: string | undefined
}
