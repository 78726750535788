import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { MISSOES } from 'src/app/core/config/endpoint-list';
import { HttpOldService } from 'src/app/core/services/http/http-old.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import Swal from 'sweetalert2';
import { MissaoEntity } from '../../missao/missao.entity';
import { MissoesEntity } from '../missoes.entity';
import { MissoesService } from '../service/missoes.service';

@Component({
  selector: 'app-missoes-main',
  templateUrl: './missoes-main.component.html',
  styleUrls: ['./missoes-main.component.scss']
})
export class MissoesMainComponent implements OnInit {

  listaParceiros: MissoesEntity[] = [];
  listaMissoes: MissoesEntity[] = [];
  listaConquistas: MissoesEntity[] = [];

  missaoAceita: any;

  imagemSelecionada: any;

  showParceiro: boolean = true;
  showListaConquistas: boolean = false;
  showMissoes: boolean = false;
  statusDasMissoes: 'MISSOES' | 'CONQUISTAS' = 'MISSOES'


  @ViewChild('modalMissao') modalMissao!: OldModalComponent;

  constructor(
    public conf: ConfigDgCrudService,
    public servic: MissoesService,
    public router: Router,
    public http: HttpOldService,
    public files: FilesManageService
  ) {
    http.get('missoes/parceiros').subscribe(resp => {
      //@ts-ignore
      this.listaParceiros = resp.data
    })
  }

  ngOnInit(): void {
  }

  showList(data: any) {
    this.servic.get(MISSOES, { params: { parceiroId: data.id } }).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaMissoes = resp.data.filter(item => item.podeResgatarCupom == false);
        this.showMissoes = true;
        this.showParceiro = false;
        this.showListaConquistas = false;
      }
    })
  }
  showConquistas() {
    this.http.get('missoes/conquistas').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaConquistas = resp.data;
        this.showListaConquistas = true;
        this.showParceiro = false;
        this.showMissoes = false;
        this.statusDasMissoes = 'CONQUISTAS'
      }
    })
  }

  showParceiros() {
    this.http.get('missoes/parceiros').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaParceiros = resp.data;

        this.showParceiro = true;
        this.showListaConquistas = false;
        this.showMissoes = false
      }
    })
  }

  missoesInconcluidas() {
    this.http.get('missoes').subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.listaMissoes = resp.data.filter(item => item.podeResgatarCupom == false)
        this.showMissoes = true;
        this.showListaConquistas = false;
        this.showParceiro = false;
        this.statusDasMissoes = 'MISSOES'
      }
    })
  }

  abrirModalMissao(index: number) {
    this.modalMissao.open()
    this.missaoAceita = this.listaMissoes[index];
  }

  aceitarMissao(data: any) {
    this.http.post(`missoes/aceitar/${data.id}`, data.id).subscribe();
    this.modalMissao.close();
    this.missoesInconcluidas();
  }

  resgatarCupom(index: number) {
    const id = this.listaConquistas[index].id
    console.log(this.listaConquistas[index])
    console.log(id)
    this.router.navigate(['/cupom/' + id])
  }

  cumpraDesafio(index: number) {
    if (index) {
      if (this.listaMissoes[index].link) {
        window.location.href = this.listaMissoes[index].link!;
      } else {
        Swal.fire({
          title: 'Ops!',
          icon: 'warning',
          text: 'Missão sem link de desafio, peça para o gestor cadastrar o link',
          timer: 4000,
          showConfirmButton: false
        })
      }
    }
  }

  concluirMissao(index: number) {
    const id = this.listaMissoes[index].id;
    this.http.post(`/missoes/concluir/${id}`, this.listaMissoes[index]).subscribe()
  }

}
