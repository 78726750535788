import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroMoradorComponent } from './cadastro-morador/cadastro-morador.component';
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    CadastroMoradorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule
  ]
})
export class CadastroMoradorModule { }
