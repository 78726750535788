import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit, clearEntity, InsertEditConfig } from "@datagrupo/dg-crud";
import { interfFaixa, UnidadeMedidaEntity } from "../unidade-medida.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import Swal from 'sweetalert2';
import { InputHelpers } from 'src/app/helpers/input.helpers';

@Component({
  selector: 'app-unidade-medida-insert-edit',
  templateUrl: './unidade-medida-insert-edit.component.html',
  styleUrls: ['./unidade-medida-insert-edit.component.scss']
})
export class UnidadeMedidaInsertEditComponent extends AbstractInsertEdit<UnidadeMedidaEntity> implements OnInit {

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    fatorConversao: new FormControl('', [Validators.required]),
    categoriaUnidadeMedida: new FormControl('QUANTIDADE', [Validators.required]),
  })

  listUnidadeMedida: string[] = [];
  sigla: string = 'M³'
  listaFaixaPorcentagem: interfFaixa[] = []

  constructor(
    public config: InsertEditConfig
  ) {
    super(config, { path: environment.apiUrl, context: 'unidade-medida' })

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['fatorConversao'])
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.validaCampos();
  }

  override afterFetchEntity() {

    this.form.patchValue({
      ...this.entity,
      fatorConversao: InputHelpers.initInputDecimal(this.entity.fatorConversao || '') || this.entity.fatorConversao

    })
    this.listaFaixaPorcentagem = this.entity.faixas;
    this.validaCampos();
  }

  initNewEntity(): void {
    this.entity = new UnidadeMedidaEntity();
  }

  override afterSaveEntity() {
    this.form.patchValue({ ...this.entity })
  }

  validaCampos() {
    const formV = this.form.value;
    if (formV.categoriaUnidadeMedida != "PORCENTAGEM") {
      this.listaFaixaPorcentagem = []
    } else if (this.entity.faixas.length == 0) {
      //this.addFaixa();
    }
    if (formV.categoriaUnidadeMedida == "PESO" || formV.categoriaUnidadeMedida == "VOLUME") {
      this.form.controls['fatorConversao'].enable();
      this.form.controls['fatorConversao'].setValidators(Validators.required);
      this.form.controls['fatorConversao'].updateValueAndValidity();
    } else {
      this.form.controls['fatorConversao'].disable();
      this.form.controls['fatorConversao'].clearValidators();
      this.form.controls['fatorConversao'].updateValueAndValidity();
      this.form.controls['fatorConversao'].reset('');
      this.form.value.fatorConversao = ''
    }
  }

  verificaFatorConversao() {
    const form = this.form.value
    if (form.categoriaUnidadeMedida == "PESO" || form.categoriaUnidadeMedida == "VOLUME") {
      form.fatorConversao = String(form.fatorConversao).replace(',', '.')
    }
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    const { fatorConversao, ...form } = this.form.value;

    this.verificaFatorConversao();

    this.entity = {
      ...this.entity,
      ...form,
      fatorConversao: !!fatorConversao ? InputHelpers.ajusteMoneyBrToEua(fatorConversao) : null,
      // faixas: this.listaFaixaPorcentagem
    }
    return true;
  }

  addFaixa() {
    if (this.form.value.categoriaUnidadeMedida == "PORCENTAGEM") {
      console.log(this.entity.faixas)
      this.entity.faixas.push({ valor: '' });
    }
  }

  removerFaixa(index: number, faixa: interfFaixa) {
    Swal.fire({
      title: 'Deseja excluir esse registro?',
      text: 'Esse registro será excluido permanentemente',
      icon: 'warning',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      cancelButtonColor: '#dc3545',
      showCancelButton: true
    }).then(confirm => {
      if (confirm.isConfirmed) {
        if (this.entity.id) {
          if (this.entity.faixas[index].id) {
            this.crudService.deleteOne('faixa/' + faixa.id).subscribe(() => {
              if (this.entity.faixas.length > 1) {
                this.entity.faixas.splice(index, 1)
              } else {
                this.entity.faixas.splice(index, 1)
                this.entity.faixas.push({ valor: '' })
              }
            })
          } else {
            if (this.entity.faixas.length >= 1) {
              this.entity.faixas.splice(index, 1)
            } else {
              this.entity.faixas.splice(index, 1)
              this.entity.faixas.push({ valor: '' })
            }
          }
        } else {
          if (this.entity.faixas.length >= 1) {
            this.entity.faixas.splice(index, 1)
          } else {
            this.entity.faixas.splice(index, 1)
            this.entity.faixas.push({ valor: '' })
          }
        }
      }
    })
  }

}
