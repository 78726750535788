<header class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <h1 class="h2">Tabelas</h1>
</header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Atenção</h4>
        <p>
          Existe um componente <b>crud-data-table</b> que está sendo utilizado em boa parte do sistema e deve ser
          descontinuado.
          <br>
          Esse componente era servido pela DG-CRUD que por sua vez não pretende mais servir componentes, esses ainda existindo
          apenas para compatibilidades como essas.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title">Tabela</h4>
        </div>
        <div class="card-header">
          filtros
        </div>
        <div class="card-body" style="max-width: 100%; overflow: auto; border: solid 1px red">
          <dg-table dg-dynamic-table [dynamicTable]="table"></dg-table>
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
