import { AbstractEntity, DgFilter_input, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
export class GrupoComunicacaoEntity extends AbstractEntity{

    constructor(
        id?: number,
        nome?: string,
        clientes?: any[]
    ){
        super()
        this.id = id;
        this.nome = nome;
        this.clientes = Array.isArray(clientes) ? clientes : [] ;
    }

    @DgFilter_input('text', {name: 'NOME DO GRUPO'})
    @DgTableColumn({columnName: 'NOME DO GRUPO', sort: true})
    public nome: string | undefined;
    public clientes: any[] = [];
}
