import {InterfMenuList} from "./admin.menuList";

export const LogisticaReversaMenuList: InterfMenuList[] = [
  {
    menuList: [
      { url: '/user/pedidoCreditoLogisticaReversa', searchName: "Pedido Crédito Logistica Reversa", nome: "Pedido CLR", icon: 'attach_money' },
      { url: '/user/vendasLogisticaReversa', nome: 'Venda Logística Reversa', icon: 'autorenew'},
    ]
  }
]
