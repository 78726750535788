import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { NgxMaskModule } from "ngx-mask";
import { UiModule } from "../../../shared/ui/ui.module";
import { MatTooltipModule } from '@angular/material/tooltip';
import { CategoriaArquivoMainComponent } from './categoria-arquivo-main/categoria-arquivo-motorista-main.component';
import { CategoriaArquivoInsertComponent } from './categoria-arquivo-insert/categoria-arquivo-insert.component';
import {DirectivesModule} from "../../../core/directives/directives.module";



@NgModule({
  declarations: [CategoriaArquivoMainComponent, CategoriaArquivoInsertComponent],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskModule,
        MatIconModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        MatTooltipModule,
        FormsModule,
        DirectivesModule
    ]
})
export class CategoriaArquivoModule { }
