<card class="card-blog">

  <input type="checkbox" hidden #checkExpandText>

  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <img src="{{file.getImageToSrc(dataPost?.imagem)}}" class="w-100" alt="">
      </div>
      <div class="col-9">
        <div class="row" style="color: gray">
          <div class="col-12 d-flex align-items-center">
            <mat-icon style="font-size: 16pt" class="d-flex justify-content-center align-items-center">settings_backup_restore</mat-icon>
            <span>{{formatData(dataPost.dataInclusao)}}</span>
          </div>
          <div class="col-12">
            <p class="card-title-blog f-bold">{{dataPost.titulo}}</p>
          </div>
          <div class="col-12">
            <p
              class="post-text"
              [innerHTML]="recortTextBlog(dataPost.conteudo, checkExpandText.checked)"
            >
            </p>
          </div>
          <div class="col-12" *ngIf="(dataPost.conteudo.length > 300)" style="cursor: pointer">
            <p class="expandText" *ngIf="!checkExpandText.checked" (click)="checkExpandText.click()">Ver Mais</p>
            <p class="expandText" *ngIf="checkExpandText.checked" (click)="checkExpandText.click()">Ver Menos</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer" check-perfil perfil="ADMINISTRADOR">
    <button (click)="editarPost()" mat-icon-button>
      <mat-icon class="fc-green">edit</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon (click)="excluirPost()" class="fc-red">close</mat-icon>
    </button>
  </div>
</card>
