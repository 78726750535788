<div
  id="galpao-main"
  class="container"
>
  <div class="row">
    <div class="col-12">
      <card>
        <div>
          <div class="container">
            <div class="row">
              <div class="col-12 d-inline-flex justify-content-end">
                <h6 class="ms-auto">
                  Peso total: {{format(pesoTotalColetas)}} (KG)
                </h6>
              </div>
            </div>
          </div>

          <div class="card-subtitle">
            <filter-search2 dynamicGroupName="controle-pesagem">
              <div class="row">
                <div *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])" class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Pesquisar transportador por</label>
                  <select class="form-select" [(ngModel)]="filters.tipoPesquisa">
                    <option value="NOME">Nome</option>
                    <option value="CNPJ">CNPJ</option>
                  </select>
                </div>
                <div *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR']) && filters.tipoPesquisa == 'CNPJ'" class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Transportador por CNPJ</label>
                  <default-autocomplete-input
                    label="cnpj"
                    key="id"
                    [(ngModel)]="filters.galpao"
                    [data]="listFilterGalpaoCNPJ"
                    dg-dynamic-group="controle-pesagem"
                    paramName="idGalpao"
                    typeFilter="outside"
                    (searchFilterValue)="getGalpaoFilters('cnpj', $event)"
                    (clearParams)="filters.galpao = ''"
                  ></default-autocomplete-input>
                </div>
                <div *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR']) && filters.tipoPesquisa == 'NOME'" class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Transportador</label>
                  <default-autocomplete-input
                    label="nome"
                    key="id"
                    [(ngModel)]="filters.galpao"
                    [data]="listFilterGalpao"
                    dg-dynamic-group="controle-pesagem"
                    paramName="idGalpao"
                    typeFilter="outside"
                    (searchFilterValue)="getGalpaoFilters('nome', $event)"
                  ></default-autocomplete-input>
                </div>
                <div class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Data inicial</label>
                  <input
                    class="form-control" [(ngModel)]="filters.dataInicial" type="date"
                    dg-dynamic-group="controle-pesagem" paramName="dataInicial"
                  >
                </div>
                <div class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Data final</label>
                  <input
                    class="form-control" [(ngModel)]="filters.dataFinal" type="date"
                    dg-dynamic-group="controle-pesagem" paramName="dataFinal"
                  >
                </div>
              </div>
            </filter-search2>
          </div>

          <div class="card-body">
            <dg-table classes="crud-table" dg-dynamic-table [dynamicTable]="table"></dg-table>
          </div>
          <div class="card-footer d-inline-flex justify-content-center">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>

<modal-controle-pesagem #modal (afterSate)="table.find()"></modal-controle-pesagem>



<page-footer>
  <button
    class="btn btn-secondary"
    (click)="downloadExcel()"
  >
    Download excel
  </button>
</page-footer>
