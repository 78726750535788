import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { FilesManageService } from 'src/app/services/files-manage/files-manage.service';
import { ArquivoEntity } from 'src/app/shared/entitys/arquivo.entity';
import Swal from 'sweetalert2';
import { PerguntasEntity } from '../perguntas.entity';
import { ClientesPerguntasService } from '../service/clientes-perguntas.service';
import {verifySizeFiles} from "../../../../../../helpers/helpers";

@Component({
  selector: 'subComponent-pergunta-evento',
  templateUrl: './pergunta-evento.component.html',
  styleUrls: ['./pergunta-evento.component.scss']
})
export class PerguntaEventoComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper

  @Input('clienteId') clienteId!: number | string | undefined;

  optAssinaturas: { key: string, value: string }[] = [
    { key: 'MODULO_GERENCIAL', value: 'Módulo gerencial' },
    { key: 'MODULO_MTR', value: 'Módulo MTR' },
    { key: 'MODULO_WHITELABEL', value: 'Módulo Whitelabel' },
    { key: 'MODULO_GESTAO_COMUNICAO_OPERADORES', value: 'Módulo gestão e comunicação de operadores' },
    { key: 'MODULO_GESTAO_FINANCEIRA', value: 'Módulo gestão financeira' },
    { key: 'MODULO_COTACAO', value: 'Módulo de cotação' },
    { key: 'MODULO_EDUCACAO_AMBIENTAL', value: 'Módulo de educação ambiental' },
  ]

  entity!: PerguntasEntity;
  entityId: any;

  public formPerfilInicial = new FormGroup({
    cargoEvento: new FormControl('', Validators.required),
    interessePrincipalEvento: new FormControl('', Validators.required),
    nomeResponsavel: new FormControl('', Validators.required),
    emailResponsavel: new FormControl('', Validators.required),
  })


  public formFuncionamentoEvento = new FormGroup({
    diasHorarioEvento: new FormControl('', Validators.required),
    qtdPessoasLixeiraCentral: new FormControl('', Validators.required),
    coletaPosEvento: new FormControl('', Validators.required),
    localArmazenamento: new FormControl(true, Validators.required),
    areaVisivel: new FormControl('', Validators.required),
    areaCoberta: new FormControl('', Validators.required),
    localLixeiraCentral: new FormControl('', Validators.required),
    larguraPorta: new FormControl('', Validators.required),
    metragemLixeira: new FormControl('', Validators.required),
    passosLixeira: new FormControl('', Validators.required),
    qtdPassos: new FormControl('', Validators.required),
    funcionarioPodeLevarLixo: new FormControl(true, Validators.required),
    descricao: new FormControl('', Validators.required),
  })

  public formAssinatura = new FormGroup({
    assinatura: new FormControl('', Validators.required)
  })

  constructor(
    public servic: ClientesPerguntasService,
    public conf: ConfigDgCrudService,
    public files: FilesManageService,
    public session: SessionService,
    public route: ActivatedRoute
  ) {
  }


  initNewEntity(): void {
    this.entity = new PerguntasEntity()
  }

  ngOnInit(): void {
    this.getRouteId();
    if (this.entityId != 'novo') {
      this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
        this.entity = resp.data;
        if(this.entity != undefined){
          this.afterFetchEntity();
        }else{
          this.initNewEntity()
        }
      })
    }
  }

  getRouteId() {
    this.route.paramMap.subscribe(params => {
      if (!params.get('novo')) {
        this.entityId = params.get('id')
      }
      this.entityId = this.clienteId
    })
  }

  afterFetchEntity(): void {
    this.formPerfilInicial.patchValue({
      ...this.entity
    })
    this.formFuncionamentoEvento.patchValue({
      ...this.entity
    })
    this.formAssinatura.controls['assinatura'].setValue(this.entity.assinatura);
  }

  proximoStepper(stepp: MatStep) {
    const index = this.stepper.selectedIndex;
    if (stepp.stepControl.status === 'VALID') {
      this.stepper.next();
    } else {
      this.checkFormStepps(index);
      this.stepper.next();
    }
  }

  checkFormStepps(index: number | null) {
    if (index == 0) {
      this.formPerfilInicial.markAllAsTouched();
      return
    }

    if (index == 1) {
      this.formFuncionamentoEvento.markAllAsTouched();

      if (this.formFuncionamentoEvento.value.passosLixeira == "MAIS_SESSENTA") {
        this.formFuncionamentoEvento.get('qtdPassos')?.setValidators(Validators.required);
        this.formFuncionamentoEvento.get('funcionarioPodeLevarLixo')?.setValidators(Validators.required);
        this.formFuncionamentoEvento.get('descricao')?.setValidators(Validators.required);

        this.formFuncionamentoEvento.get('qtdPassos')?.updateValueAndValidity();
        this.formFuncionamentoEvento.get('funcionarioPodeLevarLixo')?.updateValueAndValidity();
        this.formFuncionamentoEvento.get('descricao')?.updateValueAndValidity();

      } else {
        this.formFuncionamentoEvento.get('qtdPassos')?.clearValidators();
        this.formFuncionamentoEvento.get('funcionarioPodeLevarLixo')?.clearValidators();
        this.formFuncionamentoEvento.get('descricao')?.clearValidators();

        this.formFuncionamentoEvento.get('qtdPassos')?.updateValueAndValidity();
        this.formFuncionamentoEvento.get('funcionarioPodeLevarLixo')?.updateValueAndValidity();
        this.formFuncionamentoEvento.get('descricao')?.updateValueAndValidity();

      }

      if (this.formFuncionamentoEvento.value.localArmazenamento === "FALSE") {
        this.formFuncionamentoEvento.get('localLixeiraCentral')?.setValidators(Validators.required);
        this.formFuncionamentoEvento.get('localLixeiraCentral')?.updateValueAndValidity();
      } else {
        this.formFuncionamentoEvento.get('localLixeiraCentral')?.clearValidators();
        this.formFuncionamentoEvento.get('localLixeiraCentral')?.updateValueAndValidity();
      }
      return
    }
    if (index == 3) {
      this.formAssinatura.markAllAsTouched();
    }


  }

  salvarPerguntas() {
    const formPerfilIncialV = this.formPerfilInicial.value;
    const formFuncionamentoEventoV = this.formFuncionamentoEvento.value;


    if (this.formAssinatura.invalid || this.formFuncionamentoEvento.invalid || this.formPerfilInicial.invalid) {
      this.formPerfilInicial.markAllAsTouched();
      this.formFuncionamentoEvento.markAllAsTouched();
      this.formAssinatura.markAllAsTouched();
      return;
    }

    this.entity = {
      ...this.entity,
      ...formPerfilIncialV,
      ...formFuncionamentoEventoV,
      assinatura: this.formAssinatura.value.assinatura,
      cliente: { id: this.entityId }
    }

    this.servic.saveOrUpdate(this.entity).subscribe(resp => {
      if(resp){
        this.returnSaveEntity();
      }
      if (this.entity.id) {
        Swal.fire({
          title: 'Dados Atualizado',
          text: 'Informações atualizadas com sucesso',
          icon: 'success',
          timer: 3000
        })
      } else {
        Swal.fire({
          title: 'Registro Enviado',
          text: 'Obrigado por finalizar seu cadastro',
          icon: 'success',
          timer: 3000
        })
      }
      this.session.hasRespostas = true
    })


  }

  returnSaveEntity(){
    this.servic.get(`pergunta-respostas/cliente/${this.entityId}`).subscribe(resp => {
      this.entity = resp.data
    })
  }

  checkBoolean(value: boolean | string | undefined) {
    if (!value) return false;
    return value == "true" || value == true
  }

  uploadImg(event: Event, id: number | string | undefined, identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    //@ts-ignore
    if (!id || !event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      if (!verifySizeFiles(file)) {
        return;
      }

      const data = new FormData()
      data.append('arquivo', file);
      data.append('clienteId', String(id));

      const identificadorObject = {
        fotoLixeiraCentral: 'LIXEIRA_CENTRAL',
        fotoPortaCentral: 'PORTA_CENTRAL',
        fotoLixeiraAndar: 'LIXEIRA_ANDAR',
        fotoDescarteResiduo: 'DESCARTE_RESIDUO',
      }
      data.append('tipoFotoPerguntaEnum', identificadorObject[identificador]);

      this.servic.uploadImage(data).subscribe(
        // @ts-ignore
        resp => {
          this.entity[identificador] = resp.data;
          const reader = new FileReader();

          //RETORNO DA IMAGEM
          reader.onload = e => {

            if (!this.entity[identificador]) this.entity[identificador] = <ArquivoEntity>{}

            //CONVERSÃO DA URL COM TIPAGEM, PRA STRING SEM TIPAGEM
            const srcBase64 = (String(reader.result) || '').split('base64,');

            if (!!srcBase64[1]) {
              //@ts-ignore
              this.entity[identificador]['file'] = srcBase64[1]
            }

          }
          reader.readAsDataURL(file)
        }
      )

    }
  }


  removerImagem(foto: any, identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    this.servic.delet('pergunta-respostas/foto/', { params: { idCliente: this.entityId, idFoto: foto.id } }).subscribe(
      resp => {
        this.entity[identificador] = resp.data
        const reader = new FileReader();
        reader.onload = e => {

          if (!this.entity[identificador]) this.entity[identificador] = <ArquivoEntity>{}

          //CONVERSÃO DA URL COM TIPAGEM, PRA STRING SEM TIPAGEM
          const srcBase64 = (String(reader.result) || '').split('base64,');

          if (!!srcBase64[1]) {
            //@ts-ignore
            this.entity[identificador]['file'] = srcBase64[1]
          }
        }
      }
    )
  }

  getImg(identificador: 'fotoLixeiraCentral' | 'fotoPortaCentral' | 'fotoLixeiraAndar' | 'fotoDescarteResiduo') {
    if (this.entity) {
      if (this.entity[identificador]?.id) {
        return this.files.getImageToSrc(this.entity[identificador])
      }
    }
    return '/assets/img/default.jpg'
  }

}
