import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { NewslettersEntity } from '../newsletters.entity';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';

@Injectable({
  providedIn: 'root',
})
export class NewslettersService extends AbstractHttpService<NewslettersEntity> {
  constructor(public config: ConfigDgCrudService) {
    super(config, environment.apiUrl, 'newsletter');
  }
}
