import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit } from "@datagrupo/dg-crud";
import { ClientesFuncionariosEntity, objTiposUsuarios, tiposUsuarios } from "../clientes-funcionarios.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfigDgCrudService } from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import { ClientesFuncionariosService } from "../services/clientes-funcionarios.service";
import { ClientesService } from "../../clientes/service/clientes.service";
import { ClientesEntity } from "../../clientes/clientes.entity";
import { ajusteDateByPaste } from "../../../../../helpers/helpers";
import { HttpHelpersService } from "../../../../../services/http-helpers/http-helpers.service";
import { SessionService } from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clientes-funcionarios-insert-edit',
  templateUrl: './clientes-funcionarios-insert-edit.component.html',
  styleUrls: ['./clientes-funcionarios-insert-edit.component.scss']
})
export class ClientesFuncionariosInsertEditComponent extends AbstractInsertEdit<ClientesFuncionariosEntity> implements OnInit {

  public tiposUsuarios = objTiposUsuarios;

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    cpf: new FormControl(''),
    login: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    telefone: new FormControl('', [Validators.required]),
    tipoUsuario: new FormControl('', [Validators.required]),
    cliente: new FormControl('', [Validators.required]),
    nascimento: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    contato: new FormControl(''),
    receberEmailsInformativos: new FormControl('', [Validators.required])
  });

  public listClientes: ClientesEntity[] = [];

  constructor(
    public config: ConfigDgCrudService,
    public service: ClientesFuncionariosService,
    public clientesServic: ClientesService,
    private helper: HttpHelpersService,
    public session: SessionService
  ) {
    super(config, service)
    this.callbackMessages.errorFetchEntity.showMessage = false;
    // this.form.controls['cliente'].valueChanges.subscribe((nome) => {
    // alert(nome);
    // clientesServic.findAll({options: {endpoint: 'nomes'}}).subscribe((resp: any) => {
    //   this.listClientes = resp.data;
    // })
    // })
  }

  override ngOnInit(): void {
    super.ngOnInit()

    this.form.controls['login'].valueChanges.subscribe(d => {
      if (d == this.entity?.login) return

      this.helper.verifyLoginExist(d, (rest) => {
        this.form.patchValue({ login: rest })
      })
    });
    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA'])) {
      this.form.controls['cliente'].disable()
    }

    if (this.session.user.profile == 'SINDICO') {
      let index = this.tiposUsuarios.find(val => val.key == 'MORADOR');
      this.tiposUsuarios = this.tiposUsuarios.filter(item => item != index)
    }

  }

  createLogin() {
    if (!!this.entity.login) return
    const { nome, sobrenome } = this.form.value;

    const callback = (login: string) => {
      this.form.patchValue({ login })
    }

    this.helper.autoCreateLogin(nome, sobrenome, callback)
  }

  override afterFetchEntity() {
    if (this.entity.nascimento) {
      const date = new Date(this.entity.nascimento)
    }
    this.form.patchValue({
      ...this.entity,
      cliente: this.entity.cliente?.nome,
      nascimento: !!this.entity.nascimento ? this._dataFormatada(this.entity.nascimento) : undefined,
      contato: this.entity.contato
    });
    if (this.session.user.profile == 'SINDICO') {
      let index = this.tiposUsuarios.find(val => val.key == 'MORADOR');
      this.tiposUsuarios = this.tiposUsuarios.filter(item => item != index)
    }
  }

  initNewEntity(): void {
    this.entity = new ClientesFuncionariosEntity()
  }

  verifyDate(ev: any) {
    let ano = ev.target.value.slice(0, 4);
    let anoAtual = new Date

    if ((anoAtual.getFullYear() - ano) < 18) {
      Swal.fire({
        title: 'Funcionário menor de idade, por favor altere a data de nascimento. ',
        icon: 'error',
        confirmButtonColor: 'green'
      }).then(confirm => {
        if(confirm.isConfirmed){
          this.form.controls['nascimento'].reset();
        }
      })
    }
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false
    }

    const { contato, cliente, ...form } = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      contato: contato

    }
    return true;
  }

  private _dataFormatada(dataString: string): string {
    var data = new Date(dataString),
      dia = (data.getDate() + 1).toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();

    return anoF + "-" + mesF + "-" + diaF;
  }

  public ajustePasteDate = (ev: any) => ajusteDateByPaste(ev, (text: string) => this.form.patchValue({ nascimento: text }))

}
