<old-dg-modal
  #modal
  size="md"
  title="Tipo de Resíduo"
  [afterClose]="afterClose"
>
  <div dg-content>
    <label class="form-label">Tipo de Resíduo</label>
    <default-autocomplete-input
      [formControl]="tipoLixo"
      label="label"
      key="id"
      [data]="listTipoLixo"
    >
    </default-autocomplete-input>
  </div>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
