import { Component, OnInit } from '@angular/core';
import {RankingAterroZeroEntity} from "../../entitys/aterro-zero.entity";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dashboard-print-ranking-aterro-zero',
  templateUrl: './dashboard-print-ranking-aterro-zero.component.html',
  styleUrls: ['./dashboard-print-ranking-aterro-zero.component.scss']
})
export class DashboardPrintRankingAterroZeroComponent implements OnInit {

  public mapEntity = new RankingAterroZeroEntity();
  public load: boolean = true;
  public tableParams: {[key: string]: any} = {};

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(p => {
      if (!p['params']) return;

      const params: {[key: string]: any} = JSON.parse(p['params'])

      if ('pagination' in params) {
        this.mapEntity.configEntityTable.pagination = {
          ...this.mapEntity.configEntityTable.pagination,
          ...params['pagination']
        }
        delete params['pagination'];
      }

      if (Object.keys(params).length > 0) {
        this.tableParams = params;
      }
    }).unsubscribe()
  }


  ngOnInit() {
  }

  startPrint = (data: RankingAterroZeroEntity[]) => {
    this.load = false;

    setTimeout(() => {
      if (window.location.pathname == '/user/ranking-aterro-zero-print') {
        window.print();
        window.close();
      }
    }, 1000);
    return data;
  }
}
