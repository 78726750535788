<div class="w-100 relatorio-missoes" *ngIf="listMissao.length > 0">
  <card>
    <div class="card-body">
      <table class="crud-table">
        <thead>
          <tr>
            <th style="width: 50%; text-align: center">Nome do desafio</th>
            <th style="width: 50%; text-align: center">Progresso</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let missao of listMissao">
            <td style="font-size: 10pt">{{missao?.nome || '--'}}</td>
            <td>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar"
                  [style.width]="!!missao.progresso ? realiza_calculo(missao.meta, missao.progresso) + '%' : 0"
                  [attr.aria-valuenow]="!!missao.progresso ? realiza_calculo(missao.meta, missao.progresso) : 0"
                  aria-valuemin="0" aria-valuemax="100">{{!!missao.progresso ? realiza_calculo(missao.meta,
                  missao.progresso) : 0}}%</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <dg-pagination [dataPagination]="entityMissoes.configEntityTable.pagination"
        (changePage)="changePage($event)"></dg-pagination>
    </div>
  </card>
</div>
